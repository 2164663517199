import React, { useMemo } from "react";
import { HeaderB } from "components/headers";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import lang from "translations";
import { getLocation, getLocationProducts, deleteLocation } from "apis/location.api";
import { mapObject } from "services";
import { location, locationProducts } from "mappers";
import { Fragment, ModuleWrapper } from "components/fragments";
import { Table, Text, Title, Hightlight, ActionButton, Toast } from "components/commons";
import { prettifyProductType } from "services";
import { ProductType, StyleType } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";

const DeleteLocation = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const {
    request,
    loading,
    error,
    mappedData: data,
  } = useApi({
    api: getLocation,
    mapper: location,
  });

  const {
    request: requestLocationProducts,
    loading: loadingProducts,
    result: resultProducts,
    error: errorProducts,
  } = useApi({
    api: getLocationProducts,
  });

  const { request: requestDeleteLocation, loading: deleting } = useApi({
    api: () => deleteLocation({ locationId: id }),
  });

  useMount(() => {
    request({ locationId: id });
    requestLocationProducts({ locationId: id });
    mixpanel.track(TrackEvent.ClickedButton, {
      Button: lang.deleteLocation,
      Page: lang.editLocation,
    });
  });

  const dataProducts = useMemo(() => {
    const products = mapObject(resultProducts, locationProducts);
    const mapProducts = (p) => {
      return p.map((pro) => {
        const productType = `${
          pro.type === ProductType.Variant ? pro.productCount : ""
        } ${prettifyProductType(pro.type, pro.productCount)}`.trim();
        return {
          ...pro,
          product: (
            <div>
              <Text>{pro.name}</Text>
              <Text description>{productType}</Text>
            </div>
          ),
        };
      });
    };
    products.products = mapProducts(products.products);
    products.sharedProducts = mapProducts(products.sharedProducts);
    return products;
  }, [resultProducts]);

  const returnToEdit = () => {
    history.push(Path.LOCATION_ID(id));
  };

  return (
    <ModuleWrapper
      width="medium"
      error={error || errorProducts}
      header={
        <HeaderB
          title={
            loading
              ? lang.deletingLoading
              : lang.populate(lang.deletingName, [data.name || `#${id}`])
          }
          returnText={loading || error ? lang.back : data.name}
          onClick={returnToEdit}
        />
      }
    >
      <Fragment loading={loading || loadingProducts}>
        {dataProducts.productsToBeDeletedCount ? (
          <>
            <Text className="mb-md">
              <lang.Translate
                text={lang.productsForDeletion}
                items={[
                  <b className="text-red">{dataProducts.productsToBeDeletedCount}</b>,
                  "1",
                  "pro",
                ]}
              />
            </Text>
            <Table
              data={dataProducts.products}
              columns={[
                {
                  text: lang.productsToBeDeleted,
                  key: "product",
                },
              ]}
            />
          </>
        ) : null}
        {!dataProducts.productsToBeDeletedCount && !dataProducts.sharedProductsCount ? (
          <Text color="text-gray">{lang.allSafeToDeleteLocation}</Text>
        ) : null}
        {dataProducts.sharedProductsCount ? (
          <Table
            className="mt-md"
            columns={[
              {
                text: (
                  <Title sm>
                    <lang.Translate
                      className="text-sm"
                      text={lang.theseNthOtherProducts}
                      items={[
                        <b className="text-green text-sm">{lang.dontWorry}</b>,
                        <b className="text-sm">{dataProducts.sharedProductsCount}</b>,
                      ]}
                    />
                  </Title>
                ),
                key: "product",
              },
            ]}
            data={dataProducts.sharedProducts}
          />
        ) : null}

        {dataProducts.sharedProductsCount ? (
          <Hightlight>{lang.deleteLocationNote}</Hightlight>
        ) : null}
        <ActionButton
          showLine
          loading={deleting}
          primary={{
            type: StyleType.Danger,
            text: lang.deleteLocation,
            onClick: async () => {
              await requestDeleteLocation();
              Toast({
                content: lang.populate(lang.locationNameDeleted, [data.name]),
                success: true,
                icon: "check",
              }).open();
              history.push(Path.LOCATION);
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: returnToEdit,
          }}
        />
      </Fragment>
    </ModuleWrapper>
  );
};

export default DeleteLocation;
