export const environment = {
  id: "dev",
  production: false,
  API_V3: "https://api.dev.services.pouchnation.com",
  FIREBASE: {
    apiKey: "AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo",
    authDomain: "ms-communication-ae098.firebaseapp.com",
    databaseURL: "https://ms-communication-ae098.firebaseio.com",
    projectId: "ms-communication-ae098",
    storageBucket: "ms-communication-ae098.appspot.com",
    messagingSenderId: "139918626078",
    appId: "1:139918626078:web:c8900ad0dffdb794538d0f",
    measurementId: "G-BG2Z7LL2BK",
  },
  MIXPANEL_TOKEN: "d8dce737577e847b5008f7996ae12f0d",
  FEATURE_FLAG: {
    clientSideID: "6143dd4da31a22242e7e04eb",
  },
  SENTRY_DSN: "https://136c174163aa48e5865678da5ada8253@o431184.ingest.sentry.io/6147379",
  ACTIVE_MQ_HOST:
    "wss://b-a30e323a-f690-49be-90b9-9b7fd45c7052-1.mq.ap-southeast-1.amazonaws.com:61619",
  ACTIVE_MQ_USER: "pndev",
  ACTIVE_MQ_PW: "pouchnationdev123",
  POUCH_VENUES: "https://dev.venue.pouchnation.com/",
  POUCH_ACCOUNTS: "https://dev.accounts.pouchnation.com/",
  PABBLY_SUBSCRIPTION_WITH_FREE_TRIAL:
    "https://payments.pabbly.com/subscribe/61f946c21244fe74b63de28c/standart-test-plan",
  PABBLY_SUBSCRIPTION_WITHOUT_FREE_TRIAL:
    "https://payments.pabbly.com/subscribe/61f946c21244fe74b63de28c/standart-test-plan",
  MEWS_URL: "https://demo.mews.li",
  CONNECTOR_URL: "https://dev.connector.pouchnation.com",
  CLOUDBEDS_CONNECT_URL:
    "https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=pouch_nation&redirect_uri=https%3A%2F%2Fdev.api.pouchnation.com%2Fintegrations%2Fcloudbeds%2Fcustomers%2Fcallback&response_type=code&scope=read%3AcustomFields+write%3AcustomFields+read%3Aguest+write%3Aguest+read%3Ahotel+read%3Areservation+write%3Areservation+read%3Aroom+read%3Auser",
};
