import React, { useCallback, useContext, useMemo, useState } from "react";
import { searchProduct, bulkProductPos, editPos, deleteProduct } from "apis";
import { DataTable, Toast, Text, Icon, Pill, ButtonLink } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useModal, useMount, useRouter, useSelectItems } from "hooks";
import { productListFilterRequest, productListResponse } from "mappers";
import { mapObject, pluralize } from "services";
import columns from "./columns";
import ProductFilter from "./product-filter.module";
import { productFilterState } from "./filters";
import lang from "translations";
import { CreateProductModal, PosModal } from "components/modals";
// import DeleteProductModal from "../delete-product-modal/delete-product-modal";
import DeleteSingleProductModal from "../delete-single-product-modal/delete-single-product-modal";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { PillType, ProductCreationType, ProductStockLevel, ProductType } from "enums";
import { Path } from "paths";
import ProductNamePopover from "components/popovers/product-name-popover/product-name-popover";
import { prettifyProductStockLevel } from "services";
import { mixpanel, TrackEvent } from "mixpanel";
import { editOnlineOrder } from "apis/product.api";
import { useFlags } from "launchdarkly-react-client-sdk";

const ProductList = () => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { onlineOrder } = useFlags();
  const posModal = useModal();
  const deleteProductModal = useModal();
  const createProductModal = useModal();

  const {
    request: searchProductRequest,
    loading: loadingProduct,
    result: searchProductResult = { data: [], metadata: { total: 0 } },
    error,
    mappedData,
  } = useApi({
    api: searchProduct,
    isArray: true,
    mapper: productListResponse,
    _keys: ["categoryId", "categoryName", "locationId", "locationName"],
    handleOwnError: true,
  });

  const { request: bulkProductPosRequest, loading: loadingBulkPos } = useApi({
    api: bulkProductPos,
  });

  // const { request: bulkProductRequest, loading: loadingBulk } = useApi({
  //   api: bulkProduct,
  // });

  const { request: editPosRequest, loading: loadingPos } = useApi({
    api: editPos,
  });

  const { request: editOrderRequest, loading: loadingOrder } = useApi({
    api: editOnlineOrder,
  });

  const { request: deleteProductRequest } = useApi({
    api: deleteProduct,
  });

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState, isFilterDirty } =
    useFilter(productFilterState(venue.venueId));

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  const {
    selected,
    selectedCount,
    setSelected,
    isAllSelected,
    setSelectAll,
    denormalizeItems,
    clearSelected,
  } = useSelectItems({
    items: mappedData,
    indeterminate: true,
  });

  useMount(() => {
    fetchProducts(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.productList,
    });
  });

  const fetchProducts = useCallback(
    (requestState) => {
      searchProductRequest(mapObject(requestState, productListFilterRequest));
      clearSelected();
    },
    [searchProductRequest, clearSelected]
  );

  const changePosCb = useCallback(
    async (posStatus, id) => {
      await editPosRequest({ productId: id, posStatus });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      fetchProducts(requestState);
    },
    [editPosRequest, fetchProducts, requestState]
  );

  const changeOrderCb = useCallback(
    async (isActiveMobileOrdering, id) => {
      await editOrderRequest({ productId: id, isActiveMobileOrdering });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      fetchProducts(requestState);
    },
    [editOrderRequest, fetchProducts, requestState]
  );

  // const goToProduct = useCallback(
  //   (product) => {
  //     console.log(product.name);
  //     if (product.creationType === ProductCreationType.ProductSet) {
  //       history.push(Path.EDIT_PRODUCT_SET_ID(product.id));
  //     } else {
  //       history.push(Path.EDIT_PRODUCT_ID(product.id));
  //     }
  //   },
  //   [history]
  // );

  const goToProduct = useCallback((product) => {
    return product.creationType === ProductCreationType.ProductSet ? (
      <ButtonLink path={Path.EDIT_PRODUCT_SET_ID(product.id)}>
        <Text className="text-sm text-black font-semibold text-left"> {product.name} </Text>
      </ButtonLink>
    ) : (
      <ButtonLink path={Path.EDIT_PRODUCT_ID(product.id)}>
        <Text className="text-sm text-black font-semibold text-left"> {product.name} </Text>
      </ButtonLink>
    );
  }, []);

  const products = useMemo(() => {
    return prepareProductList(mappedData, goToProduct, loadingProduct, onlineOrder);
  }, [mappedData, goToProduct, loadingProduct, onlineOrder]);

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({
        page: 1,
        searchKey,
      });
      fetchProducts(requestState);
      setHasAppliedFilter(true);
    },
    [fetchProducts, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState } = clearFilter();
    fetchProducts(requestState);
    setHasAppliedFilter(false);
  }, [clearFilter, fetchProducts]);

  const productResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${searchProductResult.metadata.total} ${pluralize(
        searchProductResult.metadata.total,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
  }, [searchProductResult.metadata.total, hasAppliedFilter]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState } = modifyFilters({ sort: { key, value } });
      fetchProducts(requestState);
    },
    [fetchProducts, modifyFilters]
  );

  const bulkProductPosCb = useCallback(
    async (posStatus) => {
      await bulkProductPosRequest({ posStatus, productIds: denormalizeItems });
      Toast({
        content:
          selectedCount > 1
            ? lang.populate(lang.productUpdated, [selectedCount])
            : lang.populate(lang.productsUpdated, [selectedCount]),
        success: true,
        icon: "check",
      }).open();
      posModal.close();
      fetchProducts(requestState);
    },
    [bulkProductPosRequest, selectedCount, fetchProducts, posModal, requestState, denormalizeItems]
  );

  // const bulkProductDeleteCb = useCallback(
  //   async (pIds) => {
  // const productIds = pIds || denormalizeItems;
  //     await bulkProductRequest({ venueId: venue.venueId, productIds });
  //     Toast({
  //       content: lang.populate(lang.productsDeleted, [productIds.length]),
  //       success: true,
  //       icon: "check",
  //     }).open();
  //     deleteProductModal.close();
  //     fetchProducts(requestState);
  //   },
  //   [
  //     denormalizeItems,
  //     fetchProducts,
  //     requestState,
  //     venue.venueId,
  //     bulkProductRequest,
  //     deleteProductModal,
  //   ]
  // );

  const submitDeleteProduct = useCallback(
    async (params, productName) => {
      try {
        await deleteProductRequest(params);
        Toast({
          content: lang.populate(lang.productDeleted, [productName]),
          success: true,
          icon: "check",
        }).open();
        deleteProductModal.close();
        fetchProducts(requestState);
      } catch (error) {
        deleteProductModal.close();
        fetchProducts(requestState);
        throw error;
      }
    },
    [deleteProductRequest, fetchProducts, requestState, deleteProductModal]
  );

  const actionSelectContent = useMemo(() => {
    if (selectedCount > 0) {
      return {
        text: `${selectedCount} ${pluralize(selectedCount, lang.item, lang.items)}`,
        options: [
          {
            text: lang.enableSellingOnPos,
            value: "0",
            onClick: () => {
              posModal.show({
                posStatus: true,
                ok: () => bulkProductPosCb(true),
              });
            },
          },
          {
            text: lang.disableSellingOnPos,
            value: "1",
            onClick: () => {
              posModal.show({
                posStatus: false,
                ok: () => bulkProductPosCb(false),
              });
            },
            // divider: true,
          },
          // {
          //   text: selectedCount > 1 ? lang.deleteSelectedProducts : lang.deleteSelectedProduct,
          //   value: "2",
          //   onClick: () => {
          //     deleteProductModal.show();
          //   },
          // },
        ],
      };
    }
  }, [selectedCount, posModal, bulkProductPosCb]);

  return (
    <ModuleWrapper>
      <HeaderA
        className="mb-md"
        title={lang.products}
        description={lang.createAndManageProducts}
        button={{
          text: lang.createNewProduct,
          onClick: () => {
            createProductModal.show();
          },
        }}
      />
      <ProductFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        modifyFilters={modifyFilters}
        fetchProducts={fetchProducts}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />
      <DataTable
        actionSelectContent={actionSelectContent}
        resultContent={productResultContent}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchProducts}
        total={searchProductResult.metadata.total}
        loading={loadingProduct || loadingPos || loadingOrder}
        columns={[
          ...columns,
          {
            key: "action",
            actions: true,
            align: "right",
            actionOptions: [
              {
                text: lang.editProduct,
                onClick: async (column) => {
                  if (column.creationType === "PRODUCT_SET") {
                    history.push(Path.EDIT_PRODUCT_SET_ID(column.id), {
                      page: requestState.page,
                      categories: requestState.categories,
                    });
                  } else {
                    history.push(Path.EDIT_PRODUCT_ID(column.id), {
                      page: requestState.page,
                      categories: requestState.categories,
                    });
                  }
                },
              },
              {
                render: ({ sellableInPos }) => {
                  if (sellableInPos) {
                    return lang.hideInPos;
                  }
                  return lang.activateInPos;
                },
                onClick: ({ id, sellableInPos }) => {
                  changePosCb(!sellableInPos, id);
                },
              },
              onlineOrder && {
                render: ({ isActiveMobileOrdering }) => {
                  if (isActiveMobileOrdering) {
                    return lang.hideOnlineMenu;
                  }
                  return lang.showOnlineMenu;
                },
                onClick: ({ id, isActiveMobileOrdering }) => {
                  changeOrderCb(!isActiveMobileOrdering, id);
                },
              },
              // {
              //   text: lang.manageInventory,
              //   onClick: (column) => {
              //     // TODO: Manage Directory
              //   },
              // },
              {
                text: <Text danger>{lang.deleteAProduct}</Text>,
                onClick: ({ id }) => {
                  deleteProductModal.show({
                    productId: [id],
                  });
                },
              },
            ],
          },
        ]}
        data={products}
        error={error}
        selected={selected}
        setSelected={setSelected}
        isAllSelected={isAllSelected}
        setSelectAll={setSelectAll}
        sort={filterState.sort}
        setSort={sortCb}
        hasAppliedFilter={isFilterDirty && hasAppliedFilter}
      />
      <PosModal {...posModal} toggling={loadingBulkPos} count={selectedCount} />
      {/* <DeleteProductModal
        // deleting={loadingBulk}
        ok={submitDeleteProduct}
        product={denormalizeItems}
        {...deleteProductModal}
      /> */}
      <DeleteSingleProductModal ok={submitDeleteProduct} {...deleteProductModal} />
      <CreateProductModal requestState={requestState} {...createProductModal} />
    </ModuleWrapper>
  );
};

const prepareProductList = (mappedData, goToProduct, loading, onlineOrderFlag) => {
  return mappedData.map((product) => {
    const {
      id,
      productType,
      sellableInPos,
      retailPriceRange,
      supplyPriceRange,
      markUpRange,
      productSkus,
      stockLevel,
      creationType,
      activeLocation,
      isActiveMobileOrdering,
    } = product;

    return {
      id,
      product: ({ toggleRowItems, active, parent }) => {
        return (
          <div className="flex items-center">
            <ProductNamePopover productId={product.id}>
              <div>
                {goToProduct(product)}
              </div>
            </ProductNamePopover>
            {parent && productType === ProductType.Variant && productSkus.length && (
              <Icon
                color="text-gray-lighter"
                name={active ? "arrow-down" : "arrow-right"}
                onClick={toggleRowItems}
              />
            )}
          </div>
        );
      },
      stockLevel: <StockStatus stockLevel={stockLevel}>{stockLevel}</StockStatus>,
      retailPriceRange: <span className="whitespace-nowrap text-sm">{retailPriceRange}</span>,
      supplyPriceRange: <span className="whitespace-nowrap text-sm">{supplyPriceRange}</span>,
      markUpRange: <span className="whitespace-nowrap text-sm">{markUpRange}</span>,
      sellableInPos,
      creationType,
      opaque: !sellableInPos && !isActiveMobileOrdering,
      _rowItems: productSkus.map((sku) => {
        return {
          product: () => (
            <div className="pl-xl ">
              <Text fontWeight="font-bold">{sku.product}</Text>
            </div>
          ),
          retailPriceRange: <span className="whitespace-nowrap text-sm">{sku.retailPrice}</span>,
          supplyPriceRange: <span className="whitespace-nowrap text-sm">{sku.supplyPrice}</span>,
          markUpRange: <span className="whitespace-nowrap text-sm">{sku.markUp} </span>,
        };
      }),
      activeLocation: (
        <span className="whitespace-nowrap text-sm">
          {onlineOrderFlag ? activeLocation : sellableInPos ? lang.posOnly : lang.disabled}
        </span>
      ),
      isActiveMobileOrdering,
    };
  });
};

const StockStatus = ({ stockLevel }) => {
  if (stockLevel === ProductStockLevel.InStock) {
    return (
      <Pill type={PillType.Greenish} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else if (stockLevel === ProductStockLevel.CheckStock) {
    return (
      <Pill type={PillType.Yellow} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else if (stockLevel === ProductStockLevel.ReorderStock) {
    return (
      <Pill type={PillType.Reddish} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else if (stockLevel === ProductStockLevel.NoStock) {
    return (
      <Pill type={PillType.Opaque} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else {
    return <div></div>;
  }
};

export default ProductList;
