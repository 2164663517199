export const receiptSettingResponse = {
  id: {
    key: "receiptSettingId",
  },
  venueId: {
    key: "venueId",
  },
  name: {
    key: "name",
  },
  placement: {
    key: "placement",
  },
  isIncluded: {
    key: "checked",
  },
};

export const receiptUpdateRequest = {
  receiptSettingId: {
    key: "id",
  },
  placement: {
    key: "placement",
  },
  checked: {
    key: "isIncluded",
  },
};
