import React from "react";
import { Route } from "react-router-dom";
import { RegisterStaff } from "modules";
import { Path } from "paths";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { getProfile } from "apis";
import { registerStaffValidateToken } from "apis/staff.api";
import { LayoutA } from "components/layouts";
import { ActionButton, Button, Modal, Skeleton, Text } from "components/commons";
import lang from "translations";

const RegisterPage = () => {
  const { history, query } = useRouter();
  const { token } = query || {};
  const continueModal = useModal();
  const messageModal = useModal();

  const { request: getProfileRequest, loading: fetchingProfile } = useApi({
    api: getProfile,
  });

  const { request, loading: validatingToken } = useApi({
    api: registerStaffValidateToken,
    params: {
      token,
    },
    handleOwnError: true,
  });

  useMount(async () => {
    if (!token) {
      history.push("/");
    }
    try {
      await request();
      if (localStorage.getItem("accessToken")) {
        const d = await getProfileRequest();
        const name = d.firstName || d.email || `#${d.userId}`;
        continueModal.show({
          title: lang.populate(lang.youAreLoggedInAs, [name]),
          name,
        });
      }
    } catch {
      messageModal.show({
        title: lang.invalidToken,
        content: lang.checkYourEmailOrAdmin,
      });
    }
  });

  return (
    <LayoutA>
      {fetchingProfile || validatingToken ? (
        <Skeleton />
      ) : (
        <div>
          <Route exact path={Path.ACTIVATE_STAFF} component={RegisterStaff} />
        </div>
      )}
      <Modal
        noCloseButton
        {...messageModal}
        actionContent={
          <div className="px-lg pb-md text-right">
            <Button
              onClick={() => {
                messageModal.close();
                history.push("/");
              }}
            >
              {lang.ok}
            </Button>
          </div>
        }
      >
        <Text>{messageModal.content}</Text>
      </Modal>
      <Modal
        noCloseButton
        {...continueModal}
        actionContent={
          <ActionButton
            className="pb-md px-md"
            primary={{
              text: lang.continue,
              onClick: () => {
                localStorage.removeItem("accessToken");
                continueModal.close();
              },
            }}
            secondary={{
              text: lang.populate(lang.continueAs, [continueModal.name]),
              onClick: () => {
                history.push("/");
                continueModal.close();
              },
            }}
          />
        }
      >
        <Text>{lang.continuingOnThisPageWillLogout}</Text>
      </Modal>
    </LayoutA>
  );
};

export default RegisterPage;
