import React from "react";
import { Container } from "components/commons";
import classnames from "classnames";
import styles from "./fragment.module.scss";

const FragmentB = ({ children, sideContent, className }) => {
  return (
    <Container className={classnames("md:flex px-0", className)} paddingless>
      <div className={classnames("w-full md:w-3/4 md:pr-md", styles.titleContainer)}>
        {children}
      </div>
      <div className={classnames("w-full mt-md md:mt-0 md:w-1/4", styles.content)}>
        {sideContent}
      </div>
    </Container>
  );
};

export default FragmentB;
