const transactionPaths = {
  SEARCH_TRANSACTION: "search",
  GRAPH_SALES_LOCATION: (venueId) => `graph/sales/venue/${venueId}/locations`,
  GRAPH_SALES_TOTAL: (venueId) => `graph/sales/total/venue/${venueId}`,
  TOP_LOCATION_SALES: (venueId) => `location-sales/venue/${venueId}`,
  GRAPH_TOP_UP: (venueId) => `graph/top-up/venue/${venueId}`,
  GRAPH_RETURN_CREDITS: (venueId) => `graph/return/venue/${venueId}`,
  TOP_PRODUCTS_BY_SALES: (venueId) => `top-product/venue/${venueId}`,
  AVERANGE_ORDER_VALUE: (venueId) => `average-order-value/venue/${venueId}`,
  TOTAL_TRANSACTIONS_VALUE: (venueId) => `total-transaction/venue/${venueId}`,
  TOP_SPENDING_GUESTS: (venueId) => `guest-spending/venue/${venueId}`,
  SEARCH_PRODUCT_OUT_OF_STOCK: "location-stock/out-of-stock",
  TOP_CATEGORIES_BY_SALES: (venueId) => `top-category/venue/${venueId}`,
  LOCATION_WITH_TRANSACTION: (venueId) => `location/search/venue/${venueId}`,
  STAFF_WITH_TRANSACTION: (venueId) => `staff/search/venue/${venueId}`,
  DEVICE_WITH_TRANSACTION: (venueId) => `device/search/venue/${venueId}`,
  CATEGORY_WITH_TRANSACTION: (venueId) => `category/search/venue/${venueId}`,
  DISCOUNT_WITH_TRANSACTION: (venueId) => `discount/search/venue/${venueId}`,
  VOUCHER_WITH_TRANSACTION: (venueId) => `voucher/search/venue/${venueId}`,
  SEARCH_SALES_REPORT_EXPORT: "sales/search/report/export",
  SEARCH_SALES_REPORT: "sales/search/report",
  GRAPH_SALES_REPORT: (venueId) => `graph/sales/total/venue/${venueId}/breakdown`,
  TRANSACTION_ID: (id) => id,
  TRANSACTION_EXPORT: () => "export",
};

export default transactionPaths;
