import classNames from "classnames";
import { Panel, Title } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import { useMount } from "hooks";
import { mixpanel, TrackEvent } from "mixpanel";
import { Path } from "paths";
import React from "react";
import lang from "translations";
import IntegrationList from "./integration-list/integration-list.module";
import style from "./integration-setting.module.scss";

const IntegrationSetting = () => {
  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.integrationSettings,
    });
  });

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.integrations}
          description={lang.integrationSettingDesc}
          returnPath={Path.SETTING}
        />
      }
    >
      <Panel className={classNames(style.integrationBanner)}>
        <Title
          color="text-white"
          className="break-word w-full text-lg lg:w-3/4 xl:w-3/6 md:text-xl mt-xs"
        >
          {lang.extendThePower}
        </Title>
      </Panel>
      <IntegrationList />
    </ModuleWrapper>
  );
};

export default IntegrationSetting;
