import React from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { LineChart, Title } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

const TotalReturnCreditsChart = ({ ...props }) => {
  const data = props.mappedData;
  return (
    <ChartContainer
      title={lang.totalReturnCredits}
      {...props}
      empty={!data.total?.value}
      emptyText={lang.noSalesInDateRange}
    >
      <Title xl className="my-sm" fontWeight="font-normal">
        {data.total?.text}
      </Title>
      <LineChart
        height={300}
        {...data.data}
        // formatYAxis={(label) => {
        //   return formatNumberToMoneyCurrency(parseInt(label));
        // }}
        formatToolTipValue={(value) => {
          return formatNumberToMoneyWithCurrencySymbol(value.value);
        }}
      />
    </ChartContainer>
  );
};

export default TotalReturnCreditsChart;
