import React from "react";
import { Path } from "paths";
import {
  SupplyItemList,
  CreateSupplyItem,
  SupplyItemSummary,
  SupplyItemMovement,
  EditSupplyItem,
  StocktakeList,
  CreateStocktake,
  StocktakeDetails,
} from "modules";
import { RouteRender } from "components/commons";

const InventoryPage = () => {
  return (
    <RouteRender
      defaultRedirect={Path.INVENTORY_SUPPLY_ITEM}
      renders={{
        [Path.CREATE_STOCKTAKE]: {
          exact: true,
          component: CreateStocktake,
        },
        [Path.CREATE_SUPPLY_ITEM]: {
          exact: true,
          component: CreateSupplyItem,
        },
        [Path.EDIT_SUPPLY_ITEM_PAGE]: {
          exact: true,
          component: EditSupplyItem,
        },
        [Path.SUPPLY_ITEM_MOVEMENT]: {
          exact: true,
          component: SupplyItemMovement,
        },
        [Path.SUPPLY_ITEM_SUMMARY]: {
          exact: true,
          component: SupplyItemSummary,
        },
        [Path.INVENTORY_SUPPLY_ITEM]: {
          exact: true,
          component: SupplyItemList,
        },
        [Path.INVENTORY_STOCKTAKE]: {
          exact: true,
          component: StocktakeList,
        },
        [Path.STOCKTAKE_PAGE]: {
          exact: true,
          component: StocktakeDetails,
        },
      }}
    />
  );
};

export default InventoryPage;
