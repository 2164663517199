import enumeration from "./enumeration";

const ProductType = {
  Standard: "STANDARD",
  Variant: "VARIANT",
  Composite: "COMPOSITE",
  SupplyItem: "SUPPLY_ITEM",
};

export default enumeration(ProductType);
