import React from "react";
import classnames from "classnames";
import { Text, Title } from "..";

const FieldWithTitle = ({ children, title, description, className }) => {
  return (
    <div className={classnames(className, "flex")}>
      <div className="mr-sm">{children}</div>
      <div>
        <Title>{title}</Title>
        <Text>{description}</Text>
      </div>
    </div>
  );
};

export default FieldWithTitle;
