import { useApi, useMount } from "hooks";
import React, { useCallback } from "react";
import lang from "translations";
import LocationForm from "../location-form/location-form";
import { createLocation } from "apis/location.api";
import { locationRequest } from "mappers";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateLocation = () => {
  const { request, loading: submitting } = useApi({
    api: createLocation,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: locationRequest,
  });

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createLocation,
    });
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,
          message: lang.populate(lang.locationNameCreated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  return <LocationForm submit={submitForm} title={lang.createLocation} submitting={submitting} />;
};

export default CreateLocation;
