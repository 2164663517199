import React, { useCallback, useContext } from "react";
import VoucherForm from "../voucher-form/voucher-form";
import lang from "translations";
import { useApi, useMount } from "hooks";
import { VenueContext } from "contexts";
import { createVoucher } from "apis";
import { voucherFormRequest } from "mappers";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateVoucher = () => {
  const { venue } = useContext(VenueContext);
  const { request: createVoucherRequest, loading: submitting } = useApi({
    api: createVoucher,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: voucherFormRequest,
  });

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createVoucherPage,
    });
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await createVoucherRequest({ ...params, venueId: venue.venueId });
        return {
          response: res,
          message: lang.populate(lang.voucherCreated, [params.name]),
        };
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    [createVoucherRequest, venue.venueId]
  );

  return (
    <div>
      <VoucherForm title={lang.createNewVoucher} submitting={submitting} submit={submitForm} />
    </div>
  );
};

export default CreateVoucher;
