import React, { useMemo } from "react";
import { Icon, Image, Skeleton, Text } from "..";
import styles from "./preview.module.scss";
import classNames from "classnames";
import { logoIcons } from "services/text.service";

const Preview = ({
  image,
  color,
  title = "",
  className,
  loading,
  fontColor,
  onClick,
  text,
  error,
  useInitials = false,
}) => {
  const preview = useMemo(() => {
    const isIcon = logoIcons.includes(image);
    if (!isIcon && image) {
      return <Image src={image} />;
    }

    return (
      <div className={classNames(styles.initials)}>
        <span
          className={classNames({
            [`${styles.border}`]: !fontColor,
            "border-white-darker": !error,
            "border-red": error,
          })}
          style={{
            // color: "red",
            color: `${fontColor}`,
          }}
        >
          {text && !image ? <Text label>{text}</Text> : null}
          {isIcon ? (
            <Icon name={image} fontSize="40px" />
          ) : useInitials ? (
            <Text size="text-xxl" className="py-xs md:py-sm">
              {title[0]}
            </Text>
          ) : null}
        </span>
      </div>
    );
  }, [image, fontColor, title, text, error, useInitials]);
  return (
    <div
      className={classNames(styles.preview, className, {
        "cursor-pointer": onClick,
      })}
      onClick={onClick}
    >
      <div
        className={classNames(styles.app)}
        style={{
          background: `${fontColor?.toString()}16`, // backgroundColor: `${color}16`,
        }}
      >
        {loading ? <Skeleton circle /> : preview}
      </div>
    </div>
  );
};

export default Preview;
