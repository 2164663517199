import { TransactionStatus, TransactionType, VoucherStatus } from "enums";
import moment from "moment";
import {
  formatName,
  mapObject,
  mapObjects,
  mapObjectsToSelect,
  mapSelectObjectsToValue,
  timeAgo,
} from "services";
import { toApiDateTimeFormat } from "services/date.service";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import {
  dateTimeRequest,
  dateToTimeAgo,
  filterRequest,
  selectToObjectRequest,
} from "./common.mapper";

export const voucherListResponse = {
  id: { key: "voucherId" },
  name: { key: "voucherName" },
  status: {
    transform: ({ src }) => {
      const { endDateTime, status } = src;

      if (moment().isAfter(endDateTime) && endDateTime) {
        return VoucherStatus.Expired;
      }
      return status;
    },
  },
  dateCreated: dateToTimeAgo("createdDate"),
};

export const voucherListFilterRequest = {
  ...filterRequest,
  orders: {
    transform: ({ src }) => {
      return { [src?.orders || `v.createdDate`]: src?.sort?.value || "desc" };
    },
  },
};

export const voucherMovementListResponse = {
  transactionId: { key: "transactionId" },
  dateCreated: {
    transform: ({ src }) => {
      return timeAgo(src.transaction.deviceCreatedDate, true);
    },
  },
  name: { key: "voucherName" },
  quantity: { key: "quantity" },
  guestName: {
    transform: ({ src }) => {
      const { userFirstName, userLastName } = src.transaction;
      return formatName(userFirstName, userLastName);
    },
  },
  userTagUid: {
    transform: ({ src }) => {
      return src.transaction?.userTagUid;
    },
  },
  locationName: {
    transform: ({ src }) => {
      return src.transaction?.locationName;
    },
  },
  staffName: {
    transform: ({ src }) => {
      const { staffFirstName, staffLastName } = src.transaction;
      return formatName(staffFirstName, staffLastName);
    },
  },
  voucherType: { key: "itemType" },
  tapStatus: {
    transform: ({ src }) => {
      return src.transaction?.nfcStatus;
    },
  },
  staffProfileId: {
    transform: ({ src }) => {
      return src.transaction?.staffProfileId;
    },
  },
  voucherId: { key: "voucherId" },
  locationId: {
    transform: ({ src }) => {
      return src.transaction?.locationId;
    },
  },
};

export const voucherMovementListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  transactionTypes: [
    TransactionType.REDEEM,
    TransactionType.ISSUE,
    TransactionType.REMOVE,
    TransactionType.MIXED,
  ],
  transactionStatus: TransactionStatus.SUCCESS,
  orders: {
    transform: ({ src }) => {
      return { [`t.deviceCreatedDate`]: src.sort.value };
    },
  },
  locationIds: selectToObjectRequest("locations"),
  staffIds: selectToObjectRequest("staffs"),
  transactionVoucherItemTypes: selectToObjectRequest("voucherTypes"),
  nfcStatus: { key: "tapStatus" },
};

export const voucherReportListRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  voucherId: { key: "voucher" },
  locationIds: selectToObjectRequest("locations"),
  orders: {
    transform: ({ src }) => {
      if (src.sort.key === "supplyValue") {
        return { totalRetailPrice: src.sort.value };
      }
      if (src.sort.key === "dateAndTime") {
        return { deviceUpdatedDate: src.sort.value };
      }
      return { [`${src.sort.key}`]: src.sort.value };
    },
  },
};

export const voucherReportListResponse = {
  voucherId: { key: "voucherId" },
  name: { key: "voucherName" },
  isVoucherDeleted: { key: "isVoucherDeleted" },
  valueRedeemed: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.totalValueRedeemed);
    },
  },
  supplyValue: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.totalSupplyValue);
    },
  },
  totalUse: { key: "totalUse" },
};

export const voucherDetailReportListResponse = {
  dateAndTime: dateToTimeAgo("timestamp"),
  transactionId: { key: "transactionId" },
  productRedeemed: { key: "productName" },
  valueRedeemed: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  supplyValue: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  guestName: {
    transform: ({ src }) => {
      return formatName(src.guestFirstName, src.guestLastName);
    },
  },
  guestTagUid: { key: "guestTagUid" },
  staffName: {
    transform: ({ src }) => {
      return formatName(src.staffFirstName, src.staffLastName);
    },
  },
  locations: {
    transform: ({ src }) => {
      const { productSkus = [] } = src;

      let locationNames = new Set();

      for (const productSku of productSkus) {
        const { locationStocks = [] } = productSku;

        for (const locationStock of locationStocks) {
          locationNames.add(locationStock.locationName);
        }
      }

      return locationNames;
    },
  },
  productId: {
    key: "productId",
  },
  locationName: {
    key: "locationName",
  },
};

export const voucherWithTransactionFilterRequest = {
  venueId: { key: "venueId" },
  ...dateTimeRequest,
};

export const voucherWithTransactionFilterResponse = {
  id: { key: "voucherId" },
  name: { key: "voucherName" },
};

export const voucherFormRequest = {
  venueId: { key: "venueId" },
  voucherId: { key: "id" },
  voucherName: { key: "name" },
  description: { key: "description" },
  locationIds: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.locations);
    },
  },
  sellableInPos: { key: "posStatus" },
  productSkuIds: { key: "productSkuIds" },
  issuanceLimit: { key: "issuanceLimit" },
  productLimit: 1,
  startDateTime: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[0]) return null;
      return toApiDateTimeFormat(src?.dateRange?.[0]);
    },
  },
  endDateTime: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[1]) return null;
      return toApiDateTimeFormat(src?.dateRange?.[1], true);
    },
  },
};

export const voucherFormResponse = {
  id: { key: "voucherId" },
  name: { key: "voucherName" },
  description: { key: "description" },
  posStatus: { key: "sellableInPos" },
  dateRange: {
    transform: ({ src }) => {
      const { startDateTime, endDateTime } = src;

      return [
        startDateTime ? moment(startDateTime) : null,
        endDateTime ? moment(endDateTime) : null,
      ];
    },
  },
  locations: {
    transform: ({ self }) => {
      return mapObjectsToSelect(self, { textKey: "locationName", valueKey: "locationId" });
    },
  },
  issuanceLimit: { key: "issuanceLimit" },
  voucherProductSkus: {
    transform: ({ self }) => {
      return mapObjects(self, {
        id: { key: "productId" },
        name: { key: "productName" },
        productSku: {
          transform: ({ self }) => {
            return mapObject(self, { id: self.productSkuId });
          },
        },
      });
    },
  },
  productSkuIds: {
    transform: ({ src }) => {
      return src.voucherProductSkus.map((vps) => {
        return vps.productSku.productSkuId;
      });
    },
  },
};
