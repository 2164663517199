import { ActionButton, Text, Input, Button, Toast, Modal, Title } from "components/commons";
import React, { useMemo, useState, useCallback } from "react";
import { useModal } from "hooks";
import { StyleType } from "enums";
import lang from "translations";
import { Popover } from "antd";
import classNames from "classnames";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber } from "services";
import { isDecimalLastCharacter, toAmount } from "services/number.service";

const StockAdjustmentPopover = ({
  product,
  location,
  onSubmit,
  trigger = "click",
  placement = "top",
  children,
}) => {
  const { skuId, measurement, name: productName } = product;
  const { id: locationId, stock: originalQuantity, name: locationName } = location;

  const [newQuantity, setNewQuantity] = useState(originalQuantity);
  const [adjustQuantity, setAdjustQuantity] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState({ newQuantity: false });

  const confirmModal = useModal();

  const isPc = useMemo(() => {
    return measurement === "pc";
  }, [measurement]);

  const adjustByInput = useCallback(
    (val) => {
      let value = parseAmountToNumber(val.value);
      const max = isPc ? 99999999 : 9999999.999;

      if (!val.value.trim()) {
        setNewQuantity("");
        setAdjustQuantity(0 - originalQuantity);
        return;
      }

      if (!isNumberValid(value) || !isValidDecimalPlaces(value, isPc ? 0 : 3)) {
        return;
      }

      if (value <= max) {
        if (!isPc && isDecimalLastCharacter(val.value)) {
          setNewQuantity(`${value}.`);
        } else {
          setNewQuantity(value);
        }
        setAdjustQuantity(value - originalQuantity);
      }
    },
    [isPc, originalQuantity]
  );

  const modifyAdjustQuantity = useCallback(
    (val) => {
      const v = typeof val === "object" ? val.value : String(val);
      const value = parseAmountToNumber(v);
      setError({ newQuantity: false });
      const max = isPc ? 99999999 : 9999999.999;
      if (v === "-" || !v) {
        setAdjustQuantity(v);
        return;
      }
      if (!isNumberValid(value) || !isValidDecimalPlaces(value, isPc ? 0 : 3)) {
        return;
      }
      const newValue = value + originalQuantity;
      if (newValue <= max) {
        setNewQuantity(toAmount(newValue, isPc ? "0,0" : "0,0.000"));
        setAdjustQuantity(value);
      }
    },
    [isPc, originalQuantity]
  );

  const hidePopover = () => {
    setVisible(false);
  };

  const saveChanges = useCallback(async () => {
    try {
      const params = {
        id: skuId,
        locationId: locationId,
        body: { stock: parseAmountToNumber(adjustQuantity), locationId: locationId },
      };
      await onSubmit(params);
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      throw error;
    }
  }, [adjustQuantity, locationId, skuId, onSubmit]);

  const confirm = useCallback(() => {
    if (parseAmountToNumber(newQuantity) === parseAmountToNumber(originalQuantity)) {
      hidePopover();
      return;
    }
    if (newQuantity < originalQuantity) {
      hidePopover();
      confirmModal.show();
    } else {
      hidePopover();
      saveChanges();
    }
  }, [newQuantity, confirmModal, originalQuantity, saveChanges]);

  const content = useMemo(() => {
    return (
      <div className="grid grid-cols-2 gap-3 w-56">
        <div className="col-span-1 grid grid-rows-4 gap-2">
          <div className="row-span-1 flex items-end justify-center flex-col	">
            <Text className="font-bold">{lang.original}</Text>
          </div>
          <div className="row-span-1 flex items-end justify-center flex-col	">
            <Text className=" font-bold">{lang.adjustQuantity}</Text>
          </div>
          <div className="row-span-1 flex items-end justify-center flex-col	">
            <Text className="font-bold">{lang.newQuantity}</Text>
          </div>
          <div className="row-span-1 flex flex-col">
            <Button type={StyleType.Secondary} onClick={() => hidePopover()}>
              {lang.cancel}
            </Button>
          </div>
        </div>
        <div className="col-span-1 grid grid-rows-4 gap-2">
          <div className="row-span-1 justify-center">
            <Input
              center
              readOnly
              className={classNames(
                {
                  "text-red-500": originalQuantity <= 0,
                },
                "pointer-events-none border-none float-right	"
              )}
              value={originalQuantity}
            />
          </div>
          <div className="row-span-1 justify-center">
            <div className="flex border rounded border-white-darker  border-solid relative min-h-field">
              <Button
                type={StyleType.Ghost}
                onClick={() => modifyAdjustQuantity(Number(adjustQuantity) - 1)}
              >
                -
              </Button>
              <Input
                center
                paddingless
                error={error.adjustQuantity}
                onChange={(obj, val) => modifyAdjustQuantity(val)}
                className={classNames(
                  {
                    "text-red-500": adjustQuantity < 0,
                  },
                  "border-none"
                )}
                value={adjustQuantity}
                placeholder={0}
                onBlur={() => {
                  setAdjustQuantity(toAmount(adjustQuantity, isPc ? "0,0" : "0,0.000"));
                }}
                onFocus={() => {
                  const v = parseAmountToNumber(adjustQuantity);
                  setAdjustQuantity(v || "");
                }}
              />
              <Button
                type={StyleType.Ghost}
                onClick={() => modifyAdjustQuantity(Number(adjustQuantity) + 1)}
              >
                +
              </Button>
            </div>
          </div>
          <div className="row-span-1 justify-center">
            <Input
              center
              error={error.newQuantity}
              className={classNames({
                "text-red-500": newQuantity <= 0,
              })}
              value={newQuantity}
              onChange={(obj, val) => adjustByInput(val)}
              placeholder={isPc ? "0" : "0.000"}
              onBlur={() => {
                setNewQuantity(toAmount(newQuantity, isPc ? "0,0" : "0,0.000"));
              }}
              onFocus={() => {
                const v = parseAmountToNumber(newQuantity);
                setNewQuantity(v || "");
              }}
            />
          </div>
          <div className="row-span-1 flex flex-col">
            <Button onClick={() => confirm()}>{lang.save}</Button>
          </div>
        </div>
      </div>
    );
  }, [
    error,
    adjustQuantity,
    newQuantity,
    isPc,
    originalQuantity,
    modifyAdjustQuantity,
    confirm,
    adjustByInput,
  ]);

  return (
    <>
      <Popover
        content={content}
        trigger={trigger}
        visible={visible}
        placement={placement}
        onVisibleChange={setVisible}
      >
        {children}
      </Popover>
      <Modal
        active={confirmModal.active}
        actionContent={
          <ActionButton
            loading={confirmModal.toggling}
            className="px-md py-md"
            secondary={{
              text: lang.cancel,
              onClick: () => {
                confirmModal.close();
              },
            }}
            primary={{
              text: lang.removeStocksConfirm,
              type: StyleType.Danger,
              onClick: () => {
                saveChanges();
                confirmModal.close();
              },
            }}
          />
        }
        title={<Title lg>{lang.removeStocks}</Title>}
        close={confirmModal.close}
      >
        <Text size="text-md">
          {lang.populate(lang.removeStocksDetails, [
            adjustQuantity * -1,
            measurement,
            productName,
            locationName,
          ])}
        </Text>
      </Modal>
    </>
  );
};

export default StockAdjustmentPopover;
