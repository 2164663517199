import React, { useCallback, useMemo } from "react";
import lang from "translations";
import DeviceForm from "../device-form/device-form";
import DeviceFormSkeleton from "../device-form/device-form-skeleton";
import { editDevice, getDevice } from "apis";
import { mapObject } from "services";
import { deviceUpdateRequest, device } from "mappers";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";

const EditDevice = () => {
  const { query } = useRouter();
  const { id } = query;

  const {
    request,
    loading = true,
    result,
    error,
  } = useApi({
    api: getDevice,
  });

  const data = useMemo(() => {
    return mapObject(result, device);
  }, [result]);

  const { request: submit, loading: submitting } = useApi({
    api: editDevice,
    handleOwnError: {
      badrequest: true,
    },
  });

  useMount(() => {
    request({ deviceId: id });

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editDevice,
    });
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await submit(mapObject({ ...params, id }, deviceUpdateRequest));
        return {
          response: res,
          message: lang.populate(lang.deviceNameUpdated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [submit, id]
  );

  if (loading || error) {
    return (
      <DeviceFormSkeleton
        error={error}
        title={lang.editDevice}
        loading={loading}
        path={Path.DEVICE}
      />
    );
  }

  return (
    <DeviceForm submit={submitForm} title={data.name} submitting={submitting} initialState={data} />
  );
};

export default EditDevice;
