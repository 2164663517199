import { DiscountType, Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";
import moment from "moment";

const initialFormState = (initialState = {}) => {
  const {
    posStatus = false,
    name = "",
    description = "",
    locations = [],
    type = DiscountType.Percentage,
    value = 0,
    dateRange = [moment().startOf("day"), moment().endOf("day")],
    managerAuthorization = false,
    productSkuIds = [],
    availableAnytime = true,
    noEndDate = false,
    categories = [],
  } = initialState;
  return {
    posStatus: {
      name: "posStatus",
      value: posStatus,
      type: Field.CHECKBOX,
      title: lang.availableOnPos,
      description: lang.discountCanBeApplied,
    },
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.discountName,
      placeholder: lang.egSeniorDiscount,
    },
    description: {
      name: "description",
      value: description,
      type: Field.INPUT,
      maxLength: 255,
      label: lang.description,
      placeholder: lang.egAppliesToGuests,
    },
    locations: {
      name: "locations",
      value: locations,
      type: Field.MULTIPLE_DROPDOWN,
      label: lang.locations,
      required: true,
      validations: [Validation.requiredMultiDropdown()],
    },
    type: {
      name: "type",
      value: type,
      type: Field.RADIO,
    },
    value: {
      name: "value",
      value,
      type: Field.INPUT,
      validations:
        type === DiscountType.Percentage
          ? [
              Validation.required(),
              Validation.number(),
              Validation.rangeValue(1, 100, lang.percentageBetween),
            ]
          : [
              Validation.required(),
              Validation.number(),
              Validation.rangeValue(1, 9999999, lang.fixedAmountRange),
            ],
    },
    dateRange: {
      name: "dateRange",
      value: dateRange,
      type: Field.DATE_RANGE,
      label: lang.dateRange,
    },
    managerAuthorization: {
      name: "managerAuthorization",
      value: managerAuthorization,
      type: Field.CHECKBOX,
    },
    productSkuIds: {
      name: "productSkuIds",
      value: productSkuIds,
      type: Field.ANY,
    },
    availableAnytime: {
      name: "availableAnytime",
      value: availableAnytime,
      type: Field.CHECKBOX,
    },
    noEndDate: {
      name: "noEndDate",
      value: noEndDate,
      type: Field.CHECKBOX,
    },
    categories: {
      name: "categories",
      placeholder: lang.searchCategories,
      type: Field.ANY,
      value: categories,
      emptyPlaceHolder: lang.noAvailableCategories,
    },
  };
};

export default initialFormState;
