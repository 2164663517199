const categoryPaths = {
  CATEGORY: "",
  SEARCH_CATEGORY: "search",
  CATEGORY_ID: (categoryId) => categoryId,
  CATEGORY_ID_COLORS: (request) => `${request.categoryId}/colors/${request.venueId}`,
  BATCH_CATEGORY: "batch-category",
  SEARCH_CATEGORY_REPORT: "search/report",
  SEARCH_CATEGORY_REPORT_EXPORT: "search/report/export",
  CATEGORY_REPORT: (categoryId) => `${categoryId}/report`,
};

export default categoryPaths;
