import { Panel, Text, Title, Icon, ButtonLink, Skeleton } from "components/commons";
import { TransactionType, PaymentMethod } from "enums";
import { Path } from "paths";
import React, { useMemo } from "react";
import lang from "translations";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { Alert } from "antd";

const TransactionSummary = ({ data = {}, loading }) => {
  const { paymentMethod } = data;
  const isPaidByCashOrCard = [
    PaymentMethod.Cash,
    PaymentMethod.CreditDebit,
    PaymentMethod.Credit,
    PaymentMethod.Others,
  ].includes(paymentMethod);
  const items = useMemo(() => {
    let list = [];
    const { type, items } = data;
    const isTypeWithSale = [
      TransactionType.MIXED,
      TransactionType.SALE,
      TransactionType.REDEEM,
    ].includes(type);
    const divider = (
      <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
    );

    if (items) {
      list = items.map((d, index) => {
        const { type } = d;
        if (type === "item") {
          const { name, info, amount, size = "text-md" } = d;

          return (
            <div className="mb-md" key={uuidv4()}>
              <div className="grid grid-cols-4 md:gap-4 gap-1">
                <div className="md:col-span-3 col-span-4">
                  <Text strong size={size} fontWeight="font-semibold">
                    {name}
                  </Text>
                  <Text color="text-gray">{info}</Text>
                </div>
                <div className="md:col-span-1 col-span-4 md:text-right">
                  <Text strong size={size} fontWeight="font-semibold">
                    {amount}
                  </Text>
                </div>
              </div>
            </div>
          );
        }
        if (type === "productItem") {
          const { productName, productId, productVariants, isDeleted, info } = d.product;
          const { quantity, total, originalPrice, deductions, discounts, hasDiscounts } = d;

          return (
            <div className="mb-md">
              <div className="grid grid-cols-4 md:gap-4 gap-1 flex justify-between items-center">
                <div className="md:col-span-2 col-span-4">
                  {!isDeleted ? (
                    <ButtonLink
                      path={Path.EDIT_PRODUCT_ID(productId)}
                      className="text-blue text-left font-semibold text-md"
                    >
                      {productName} {info && <span className="text-xs italic"> {info}</span>}
                    </ButtonLink>
                  ) : (
                    <Text className="text-black text-md" strong>
                      {productName}{" "}
                      {isDeleted ? (
                        <span className="text-sm">(deleted)</span>
                      ) : (
                        info && <span className="text-xs italic"> {info}</span>
                      )}
                    </Text>
                  )}
                </div>
                <div className="md:col-span-1 col-span-4 text-right">
                  <Text>{quantity}</Text>
                </div>
                <div className="md:col-span-1 col-span-4 md:text-right">
                  <div>
                    <Text strong size="text-md" fontWeight="font-semibold">
                      {total}
                    </Text>
                  </div>
                  {originalPrice && (
                    <div>
                      <Text className="line-through -mb-md" size="text-sm">
                        {originalPrice}
                      </Text>
                    </div>
                  )}
                </div>
              </div>

              {productVariants.length > 0 && (
                <div className="md:pd-0 pt-0 pb-sm">
                  {productVariants.map(({ attribute, value }) => {
                    return (
                      <div key={uuidv4()} className="grid grid-cols-4 gap-4 mt-xs">
                        <div className="md:col-span-4 col-span-4 md:flex">
                          <Text className="text-gray pr-sm">{attribute}</Text>
                          <Text>{value}</Text>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {hasDiscounts && (
                <div
                  key={uuidv4()}
                  className="grid grid-cols-4 md:gap-2 gap-1 md:mt-sm flex justify-between items-center acv"
                >
                  <div className="md:col-span-2 col-span-4 md:flex">
                    <div className="">
                      <span className="text-gray text-sm">{discounts.text}</span>
                      <span className="text-sm pl-xs">
                        {discounts.discountName} {discounts.discountAmountRaw}
                      </span>
                    </div>
                  </div>
                  <div className="md:col-span-1 col-span-4 text-right">
                    <Text className="text-gray text-sm">{discounts.quantityTimesPrice}</Text>
                  </div>
                </div>
              )}

              {deductions.length > 0 && (
                <div className="md:pt-0 pt-sm">
                  {deductions.map(({ label, value, text }) => {
                    return (
                      <div
                        key={uuidv4()}
                        className="grid grid-cols-4 md:gap-2 gap-1 md:mt-sm flex justify-between items-center acv"
                      >
                        <div className="md:col-span-2 col-span-4 md:flex">
                          <div className="">
                            <span className="text-gray text-sm">{label}</span>
                            <span className="text-sm"> {value}</span>
                          </div>
                        </div>
                        <div className="md:col-span-1 col-span-4 text-right">
                          <Text className="text-gray text-sm">{text}</Text>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {data.items.length !== index + 1 && divider}
            </div>
          );
        }
        if (type === "summary" && isTypeWithSale) {
          const { text, description, quantity, prices, classNames } = d;

          return (
            <div className="grid grid-cols-4 md:gap-4 gap-1 mt-md">
              <div className="md:col-span-2 col-span-4">
                <Text
                  className={classnames("text-gray font-semibold", classNames)}
                  uppercase
                  strong
                  size="text-xs"
                >
                  {text}
                </Text>
              </div>
              <div
                className={classnames("md:col-span-1 col-span-4", {
                  "hidden md:block": !description,
                })}
              >
                <Text className="text-right">{quantity}</Text>
                <Text className="text-right">{description}</Text>
              </div>
              <div className="md:col-span-1 col-span-4 md:text-right">
                {prices.map((price) => {
                  return (
                    <Text
                      key={uuidv4()}
                      className={classnames({
                        "line-through": price.lineThrough,
                        "text-md": !price.deduction && !price.discount,
                        "font-semibold": !price.deduction && !price.discount,
                      })}
                      strong={!price.deduction && !price.discount}
                    >
                      {price.value}
                    </Text>
                  );
                })}
              </div>
            </div>
          );
        }
        if (type === "tax") {
          const { text, description, value, taxes = [], className } = d;
          return (
            <div>
              {divider}
              <div className="grid grid-cols-4 md:gap-4 gap-1 mt-sm">
                <div className="md:col-span-2 col-span-4">
                  <Text
                    className={classnames("text-gray font-semibold", className)}
                    uppercase
                    strong
                    size="text-xs"
                  >
                    {text}
                  </Text>
                </div>
                <div className="md:col-span-1 col-span-4">
                  <Text strong className="font-semibold">
                    {description}
                  </Text>
                </div>
                <div className="md:col-span-1 col-span-4 md:text-right">
                  <Text strong className="font-semibold">
                    {value}
                  </Text>
                </div>
              </div>
              <div className="pt-sm md:pt-0">
                {taxes.map((tax) => {
                  const { name, taxName, rate } = tax;
                  return (
                    <div key={uuidv4()} className="grid grid-cols-4 md:gap-4 gap-1">
                      <div className="md:col-span-2 col-span-4">
                        <Text className="text-gray font-semibold" uppercase>
                          {name}
                        </Text>
                      </div>
                      <div className="md:col-span-1 col-span-4">
                        <Text color="text-gray">{taxName}</Text>
                      </div>
                      <div className="md:col-span-1 col-span-4 md:text-right">
                        <Text color="text-gray">{rate}</Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        return null;
      });
    }
    if (isTypeWithSale) {
      const { locationName } = data?.location || {};
      list = [
        <Title className="mb-md flex items-center" color="text-black">
          <Icon name="vendor" className="text-gray mr-md" />
          {locationName || "-"}
        </Title>,
        data.hasItems ? (
          <Text description uppercase strong className="mb-md">
            {lang.items}
          </Text>
        ) : (
          <Text italic color="text-gray">
            {lang.noData}
          </Text>
        ),
        ...list,
      ];
    } else {
      list = [<Title className="mb-md">{lang.summary}</Title>, ...list];
    }

    if (data.previousBalance && data.type !== "REDEEM") {
      if (data.type === "SALE" && isPaidByCashOrCard) {
        // do nothing
      } else {
        list.push(divider);

        data.previousBalance.forEach(
          ([name, amount, styles = {}, titleClass = "", amountClass = ""]) => {
            const {
              fontSize = "text-sm",
              danger = false,
              strong = false,
              strongTitle = false,
            } = styles;
            list.push(
              <div className={classnames("grid grid-cols-4 md:gap-0 gap-1")}>
                <div className="md:col-span-3 col-span-4 flex items-center">
                  <Text
                    size="text-sm"
                    className={classnames(titleClass, { "font-semibold": strongTitle })}
                  >
                    {name}
                  </Text>
                </div>
                <div className="md:col-span-1 col-span-4 md:text-right">
                  <Text
                    strong={strong}
                    size={fontSize}
                    danger={danger}
                    className={classnames(amountClass, { "font-semibold": strongTitle })}
                  >
                    {amount}
                  </Text>
                </div>
              </div>
            );
          }
        );
      }
    }

    if (data.totalPaid && data.type !== "REDEEM") {
      list.push(divider);
      data.totalPaid.forEach(([name, amount, styles = {}, titleClass = "", amountClass = ""]) => {
        const {
          fontSize = "text-sm",
          danger = false,
          strong = false,
          lineThrough = false,
        } = styles;
        list.push(
          <div className={classnames("flex items-center justify-between")}>
            <div>
              <Text
                size={fontSize}
                className={classnames(titleClass, { "line-through": lineThrough })}
              >
                {name}
              </Text>
            </div>
            <div>
              <Text
                strong={strong}
                size={fontSize}
                danger={danger}
                className={classnames(amountClass, {
                  "line-through": lineThrough,
                })}
              >
                {amount}
              </Text>
            </div>
          </div>
        );
      });
    }

    if (data.newBalance && data.type !== "REDEEM") {
      if (data.type === "SALE" && isPaidByCashOrCard) {
        // do nothing
      } else {
        list.push(divider);

        data.newBalance.forEach(
          ([name, amount, styles = {}, titleClass = "", amountClass = ""]) => {
            const {
              fontSize = "text-sm",
              danger = false,
              strong = false,
              strongTitle = false,
              superscript = "",
            } = styles;
            list.push(
              <div className={classnames("grid grid-cols-4 md:gap-0 gap-1")}>
                <div className="md:col-span-3 col-span-4 flex items-center">
                  <Text
                    size="text-sm"
                    className={classnames(titleClass, { "font-semibold": strongTitle })}
                  >
                    {name}
                    <sup>{superscript}</sup>
                  </Text>
                </div>
                <div className="md:col-span-1 col-span-4 md:text-right">
                  <Text
                    strong={strong}
                    size={fontSize}
                    danger={danger}
                    className={classnames(amountClass, { "font-semibold": strongTitle })}
                  >
                    {amount}
                  </Text>
                </div>
              </div>
            );
          }
        );
      }

      if (TransactionType.ISSUE_FOC === data.type) {
        list.push(
          <Alert
            className="mt-md flex items-baseline"
            message={
              <p className="text-sm ml-sm">(1) {lang.postpaidCreditsUsedCanOnlyBeReplenished}</p>
            }
            type="warning"
            showIcon
          />
        );
      }
    }

    return list;
  }, [data, isPaidByCashOrCard]);

  if (loading) {
    return (
      <Panel>
        <Skeleton />
      </Panel>
    );
  }

  return (
    <>
      <Panel>
        {items.map((item) => {
          return <div key={uuidv4()}>{item}</div>;
        })}
      </Panel>

      {data.isVoided && data.type !== "REDEEM" && (
        <Panel>
          <div>
            <Title>{lang.voidInfo}</Title>
          </div>
          {data.voidedPreviousBalance && !(data.type === TransactionType.SALE && isPaidByCashOrCard)
            ? data.voidedPreviousBalance.map(([name, amount, nameClass, amountClass]) => {
                return (
                  <div className="grid grid-cols-4 md:gap-4 gap-0" key={uuidv4()}>
                    <div className="md:col-span-3 col-span-4 flex items-center">
                      <Text className={classnames(nameClass)}>{name}</Text>
                    </div>
                    <div className="md:col-span-1 col-span-4 md:text-right">
                      <Text className={classnames(amountClass)}>{amount}</Text>
                    </div>
                  </div>
                );
              })
            : null}

          <div
            className={`-mr-lg -ml-lg mt-md ${
              !(data.type === TransactionType.SALE && isPaidByCashOrCard) ? "border" : ""
            } border-bottom border-gray-lightest`}
          ></div>
          {data.voidTotalPaid.map(
            ([name, amount, styles = {}, titleClass = "", amountClass = ""]) => {
              const { fontSize = "text-sm", danger = false, strong = false } = styles;

              return (
                <div className={classnames("grid grid-cols-4 md:gap-0 gap-1")}>
                  <div className="md:col-span-3 col-span-4 flex items-center">
                    <Text className={classnames(titleClass)}>{name}</Text>
                  </div>
                  <div className="md:col-span-1 col-span-4 md:text-right">
                    <Text
                      strong={strong}
                      size={fontSize}
                      danger={danger}
                      className={classnames(amountClass)}
                    >
                      {amount}
                    </Text>
                  </div>
                </div>
              );
            }
          )}
          <div
            className={`-mr-lg -ml-lg mt-md ${
              !(data.type === TransactionType.SALE && isPaidByCashOrCard) ? "border" : ""
            } border-bottom border-gray-lightest`}
          ></div>

          {data.voidedNewBalance && !(data.type === TransactionType.SALE && isPaidByCashOrCard)
            ? data.voidedNewBalance.map(([name, amount, nameClass, amountClass]) => {
                return (
                  <div className="grid grid-cols-4 md:gap-4 gap-0" key={uuidv4()}>
                    <div className="md:col-span-3 col-span-4 flex items-center">
                      <Text className={classnames(nameClass)}>{name}</Text>
                    </div>
                    <div className="md:col-span-1 col-span-4 md:text-right">
                      <Text className={classnames(amountClass)}>{amount}</Text>
                    </div>
                  </div>
                );
              })
            : null}

          {data.voidReason && (
            <div className="mt-lg flex border p-md rounded">
              <div className="mr-lg">
                <Icon name="double-quote" className="text-gray-lightest" fontSize="30px" />
              </div>
              <div>
                <Text className="text-gray font-semibold mb-md" uppercase strong size="text-xs">
                  {lang.voidReason}
                </Text>
                <Text italic>{data.voidReason}</Text>
              </div>
            </div>
          )}
        </Panel>
      )}
    </>
  );
};

export default TransactionSummary;
