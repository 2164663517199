import enumeration from "./enumeration";

const VoucherType = {
  Issue: "ISSUE",
  Redeem: "REDEEM",
  Remove: "REMOVE",
  Usage: "USAGE",
};

export default enumeration(VoucherType);
