import React, { useCallback } from "react";
import { Input, Text, Icon, TextArea } from "components/commons";
import styles from "./input-counter.module.scss";
import classnames from "classnames";

const InputCounter = ({
  label = "",
  maxLength = 75,
  required,
  error,
  onChange,
  textarea,
  message,
  customMessage,
  errorClassName,
  labelHidden = false,
  reference,
  customClass,
  customTextArea,
  customInputArea,
  ...inputProps
}) => {
  // const [inputValLength, setInputValLength] = useState(inputValue?.length || 0);

  const onChangeCb = useCallback(
    (name, val) => {
      const { value = "" } = val;
      if (onChange) {
        onChange(name, { value });
      }
    },
    [onChange]
  );

  return (
    <div
      className={
        customClass
          ? classnames(customClass, { [`${styles.error}`]: error }, styles.container)
          : classnames(
              "bg-white border rounded border-solid border-white-darker px-md py-md relative",
              {
                [`${styles.error}`]: error,
              },
              styles.container
            )
      }
    >
      {!labelHidden && (
        <div className="flex items-center justify-between">
          <Text description>{label}</Text>
          <Text>
            {inputProps.value?.length ? inputProps.value.length : 0} / {maxLength}
          </Text>
        </div>
      )}

      {textarea ? (
        <TextArea
          className={customTextArea ? customTextArea : [styles.input, styles.textarea]}
          maxLength={maxLength}
          {...inputProps}
          onChange={onChangeCb}
        />
      ) : (
        <Input
          className={customInputArea ? customInputArea : styles.input}
          maxLength={maxLength}
          {...inputProps}
          onChange={onChangeCb}
          ref={reference}
        />
      )}

      {required && (
        <div className={classnames(styles.required, { [`${styles.error}`]: error })}>
          <Icon
            name="asterisk"
            className={classnames(styles.icon, { [`${styles.error}`]: error })}
          />
        </div>
      )}
    </div>
  );
};

export default InputCounter;
