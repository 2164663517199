import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useModal, useRouter, useMount, useApi } from "hooks";
import { Skeleton, Panel, Title, Button, SimpleTable, Icon, Text, Pill } from "components/commons";
import { VenueContext } from "contexts";
import { getTaxes, createTax, editTax } from "apis/tax.api";
import { AccountName, PillType, StyleType } from "enums";
import { taxesList, taxRequest, createTaxRequest } from "mappers";
import TaxFormModal from "./tax-form/tax-form.modal";
import { mixpanel, TrackEvent } from "mixpanel";
import { getMewsIntegrationStatus } from "apis/integration.api";
import { getAccountMappings } from "apis";
const TaxSetting = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const taxFormModal = useModal();

  const { request, loading, mappedData, error } = useApi({
    api: getTaxes,
    params: {
      venueId,
    },
    mapper: taxesList,
    isArray: true,
  });

  const { request: mewsRequest, result } = useApi({
    api: getMewsIntegrationStatus,
    params: {
      venueId: venue?.venueId,
    },
    handleOwnError: true,
  });

  const { request: accountingCategoriesRequest, result: defaultAccountMapping } = useApi({
    api: getAccountMappings,
    params: {
      businessId: venue?.venueId,
    },
    handleOwnError: true,
  });

  const defaultTaxAccountMapping = defaultAccountMapping
    ? defaultAccountMapping?.data?.find(
        (d) => d.itemType === AccountName.TaxOnSalesLiabilityAccount
      )
    : null;
  const defaultTaxAccountCodeId = defaultTaxAccountMapping
    ? defaultTaxAccountMapping?.accountCodeId
    : null;
  useMount(async () => {
    await request();
    await mewsRequest();
    accountingCategoriesRequest();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.taxSettings,
    });
  });

  const isMewsAccountingIntegrated = Boolean(
    result?.data
      ?.flatMap((dt) => dt.integrationType)
      .join(",")
      ?.includes("MEWS_ACCOUNTING")
  );

  const { request: createTaxRequestApi, loading: createTaxLoading } = useApi({
    api: createTax,
    params: {
      venueId,
    },
    paramsMapper: createTaxRequest,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: editTaxRequestApi, loading: editTaxLoading } = useApi({
    api: editTax,
    params: {
      venueId,
    },
    paramsMapper: taxRequest,
    handleOwnError: {
      badrequest: true,
    },
  });

  const onSave = useCallback(
    async ({ api, message }, fields, success, error) => {
      try {
        const result = await api(fields);
        request();
        return success(result, { message });
      } catch (e) {
        return error(e);
      }
    },
    [request]
  );

  const onCreateSave = useCallback(
    (fields, success, error) => {
      return onSave(
        { api: createTaxRequestApi, message: lang.populate(lang.taxAddedName, [fields.name]) },
        fields,
        success,
        error
      );
    },
    [createTaxRequestApi, onSave]
  );

  const onEditSave = useCallback(
    (fields, success, error) => {
      return onSave({ api: editTaxRequestApi, message: lang.changesSaved }, fields, success, error);
    },
    [editTaxRequestApi, onSave]
  );

  const submitting = createTaxLoading || editTaxLoading;

  const data = useMemo(() => {
    const defaultTax = mappedData.filter((tax) => {
      return tax.isDefault;
    });
    const taxes = mappedData.filter((tax) => {
      return !tax.isDefault;
    });
    return [...defaultTax, ...taxes].map((d) => {
      return {
        ...d,
        nameAndTax: (
          <div className="flex items-center">
            <Text className="mr-sm" breakAll>
              {d.nameAndTax}
            </Text>
            {d.isDefault && (
              <Pill size="text-xs" type={PillType.Green}>
                {lang.default}
              </Pill>
            )}
          </div>
        ),
        edit: (
          <div className="display-hover">
            <Icon
              name="pencil"
              className="text-blue cursor-pointer"
              onClick={() => {
                taxFormModal.show({
                  title: lang.editTax,
                  primaryText: lang.save,
                  isEdit: true,
                  save: onEditSave,
                  id: d.id,
                  initialState: {
                    name: d.name,
                    percentage: d.percentage,
                    isDefault: d.isDefault,
                    id: d.id,
                    accountCodeId: d.accountCodeId ?? defaultTaxAccountCodeId,
                    isMewsTaxCode: d.isMewsTaxCode,
                    taxCode: d.taxCode,
                  },
                  isMewsTaxCode: d.isMewsTaxCode,
                  isMewsAccountingIntegrated,
                  defaultTaxAccountMapping,
                });
              }}
            />
          </div>
        ),
      };
    });
  }, [
    mappedData,
    taxFormModal,
    onEditSave,
    isMewsAccountingIntegrated,
    defaultTaxAccountMapping,
    defaultTaxAccountCodeId,
  ]);
  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.taxes}
          returnPath={Path.SETTING}
          onClick={() => history.push(Path.SETTING)}
        />
      }
    >
      {loading ? (
        <FragmentA title={lang.taxes}>
          <Skeleton />
        </FragmentA>
      ) : (
        <>
          <FragmentA title={""} description={lang.manageHowVenueTax}>
            <Panel>
              <Title md className="mb-sm">
                {lang.taxes}
              </Title>
              <SimpleTable
                className="-mx-6"
                showHeader={false}
                columns={[
                  {
                    key: "nameAndTax",
                    className: "py-lg px-6",
                  },
                  {
                    key: "edit",
                    className: "text-right p-lg",
                  },
                ]}
                data={data}
              />
              <Button
                className="mt-md"
                type={StyleType.Secondary}
                onClick={() => {
                  taxFormModal.show({
                    title: lang.addNewTax,
                    primaryText: lang.add,
                    initialState: {
                      name: undefined,
                      percentage: undefined,
                      isDefault: false,
                      id: 0,
                      accountCodeId: defaultTaxAccountCodeId,
                      taxCode: undefined,
                    },
                    isEdit: false,
                    save: onCreateSave,
                    isMewsAccountingIntegrated,
                    defaultTaxAccountMapping,
                  });
                }}
              >
                {lang.addNewTax}
              </Button>
            </Panel>
          </FragmentA>
        </>
      )}
      <TaxFormModal
        {...taxFormModal}
        fetchingTax={loading}
        submitting={submitting}
        refreshList={request}
      />
    </ModuleWrapper>
  );
};

export default TaxSetting;
