import React, { memo, useMemo } from "react";
import {
  ButtonLink,
  Panel,
  Title,
  Image,
  Text,
  Skeleton,
  OptionMenu,
  Modal,
  ActionButton,
} from "components/commons";
import lang from "translations";
import { DefaultImage } from "images";
import styles from "./manage-variant.module.scss";
import classNames from "classnames";
import PriceField from "./price-field";
import { useModal } from "hooks";
import ManageVariantModal from "./manage-variant-modal";
import { InventoryType, StyleType } from "enums";
import RetailPriceField from "./retail-price-field";
import { Path } from "paths";
import { pluralize } from "services";
import SkuField from "./sku-field";

const ManageVariantForm = ({
  form,
  requestMeasurement,
  requestTax,
  requestSupplyItems,
  isEdit,
  loading,
}) => {
  const manageVariantModal = useModal();
  const { variants, attributeAndOptions, hasTax, tax, generatedSku, inventoryType } = form;
  const variantLength = variants.value.length;
  const error = attributeAndOptions.error;
  const removeVariantModal = useModal();

  const title = variantLength === 1 ? `1 ${lang.variant}` : `${variantLength} ${lang.variants}`;

  const renderVariant = useMemo(() => {
    return variants.value.map((variant, index) => {
      const { value: variantFields } = variant;
      const {
        sku,
        supplyCost,
        markUp,
        retailPrice,
        logo,
        key,
        supplyUnit,
        supplyQuantity,
        sellingQuantity,
        compositions,
      } = variantFields;
      const netWeight = () => {
        if (!supplyUnit.value) {
          return "--";
        }
        if (supplyUnit.value === "pc") {
          return `${supplyQuantity.value || "--"}${supplyUnit.unit || "--"}`;
        }
        return `${sellingQuantity.value || "--"}${supplyUnit.unit || "--"}`;
      };
      return (
        <div key={`variantfield${index}`}>
          <div className="grid grid-cols-3 mt-md">
            <div className="col-span-2 flex align-center">
              <div className={classNames("p-xs", styles.image)}>
                <Image src={logo.image || DefaultImage} className={styles.imageLogo} />
              </div>

              <div className={classNames("ml-lg m-auto", styles.optionName)}>
                <Title lg>{key.value}</Title>
                {inventoryType.value === InventoryType.Whole && (
                  <Text description>{lang.populate(lang.netWeightN, [netWeight()])}</Text>
                )}
              </div>
            </div>
            {!isEdit && (
              <SkuField
                generatedSku={generatedSku}
                sku={{
                  ...sku,
                  disabled: generatedSku.value,
                  onChange: (name, value) => {
                    const v = variants.value;
                    v[index] = {
                      ...variant,
                      value: {
                        ...variant.value,
                        [name]: {
                          ...variant.value[name],
                          ...value,
                          invalid: false,
                        },
                      },
                    };
                    variants.onChange(variants.name, {
                      value: v,
                    });
                  },
                }}
              />
            )}
            <div className="col-span-1 flex align-center">
              {isEdit && (
                <RetailPriceField
                  retailPrice={{
                    ...retailPrice,
                    onChange: (name, value) => {
                      const v = variants.value;
                      v[index] = {
                        ...variant,
                        value: {
                          ...variant.value,
                          [name]: {
                            ...variant.value[name],
                            ...value,
                            invalid: false,
                          },
                        },
                      };
                      variants.onChange(variants.name, {
                        value: v,
                      });
                    },
                  }}
                />
              )}
              {isEdit && (
                <OptionMenu
                  className={"m-auto pl-md"}
                  options={[
                    // {
                    //   text:
                    //     variant.value.status.value === "ACTIVE"
                    //       ? lang.hideVariant
                    //       : lang.activateVariant,
                    //   onClick: () => {
                    //     const v = variants.value;
                    //     v[index] = {
                    //       ...variant,
                    //       value: {
                    //         ...variant.value,
                    //         status: {
                    //           ...variant.value.status,
                    //           value:
                    //             variant.value.status.value === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                    //         },
                    //       },
                    //     };
                    //     variants.onChange(variants.name, {
                    //       value: v,
                    //     });
                    //   },
                    // },
                    {
                      text: lang.removeVariant,
                      className: "text-red",
                      onClick: () => {
                        const isNotDeleteable = Boolean(compositions?.value?.length > 0);
                        removeVariantModal.show({
                          isNotDeleteable,
                          remove: () => {
                            variants.onChange(variants.name, {
                              value: variants.value.filter((va, vaIndex) => {
                                return vaIndex !== index;
                              }),
                            });
                          },
                          compositions: compositions.value,
                          variant: variant.value.key.value,
                        });
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>

          {!isEdit && (
            <PriceField
              hasTax={{
                value: hasTax.value,
              }}
              tax={tax}
              taxOptions={requestTax.mappedData}
              taxLoading={requestTax.loading}
              supplyCost={supplyCost}
              markUp={markUp}
              retailPrice={retailPrice}
              onChange={(value) => {
                const v = variants.value;
                v[index] = {
                  ...variant,
                  value: {
                    ...variant.value,
                    ...value,
                  },
                };
                variants.onChange(variants.name, {
                  value: v,
                });
              }}
            />
          )}

          {index + 1 !== variantLength && (
            <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
          )}
        </div>
      );
    });
  }, [
    variants,
    variantLength,
    hasTax,
    tax,
    requestTax,
    isEdit,
    inventoryType,
    removeVariantModal,
    generatedSku,
  ]);

  if (!variantLength) {
    return null;
  }

  return (
    <Panel>
      <div className="flex justify-between">
        <div>
          <Title color={error || loading ? "text-red" : ""}>
            {error || loading ? lang.addValidAttributeAndOptions : title}
          </Title>
        </div>
        <div className="flex">
          {isEdit && (
            <ButtonLink
              className="mr-md"
              onClick={() => {
                variants.onChange(variants.name, {
                  active: !variants.active,
                });
                manageVariantModal.show({
                  createVariant: true,
                });
              }}
            >
              {lang.addVariant}
            </ButtonLink>
          )}

          <ButtonLink
            onClick={() => {
              variants.onChange(variants.name, {
                active: !variants.active,
              });
              manageVariantModal.show({
                createVariant: false,
              });
            }}
            disabled={error}
          >
            {lang.manageVariants}
          </ButtonLink>
        </div>
      </div>
      {error || loading ? <Skeleton /> : <div>{renderVariant}</div>}

      <ManageVariantModal
        requestMeasurement={requestMeasurement}
        form={form}
        title={title}
        {...manageVariantModal}
        variantLength={variantLength}
        requestTax={requestTax}
        requestSupplyItems={requestSupplyItems}
        isEdit={isEdit}
        submit={(value) => {
          variants.onChange(variants.name, {
            ...value,
          });
        }}
      />
      <Modal
        {...removeVariantModal}
        title={removeVariantModal?.isNotDeleteable ? lang.unableToDelete : lang.removeVariant}
        actionContent={
          <ActionButton
            className="m-md pb-md"
            primary={{
              type: removeVariantModal?.isNotDeleteable ? StyleType.Secondary : StyleType.Danger,
              text: removeVariantModal?.isNotDeleteable ? lang.okay : lang.remove,
              onClick: () => {
                if (removeVariantModal?.isNotDeleteable) {
                  removeVariantModal.close();
                } else {
                  removeVariantModal.remove();
                  removeVariantModal.close();
                }
              },
            }}
            secondary={
              removeVariantModal?.isNotDeleteable
                ? false
                : {
                    text: lang.cancel,
                    onClick: () => {
                      removeVariantModal.close();
                    },
                  }
            }
          />
        }
      >
        {removeVariantModal?.isNotDeleteable ? (
          <Text>
            <strong className="text-sm">{removeVariantModal.variant}</strong>{" "}
            {lang.variantIsCurrentlyBeingUsed}{" "}
            {pluralize(removeVariantModal?.compositions?.length, lang.set, lang.sets)} -{" "}
            {removeVariantModal.compositions.map((p, index) => {
              return index < 5 ? (
                <>
                  <span
                    onClick={() => {
                      window.open(Path.EDIT_PRODUCT_SET_ID(p.productId));
                    }}
                    className="text-blue text-sm cursor-pointer"
                  >
                    {p.productName}
                  </span>
                  {index !== removeVariantModal.compositions.length - 1 ? (
                    <span className="text-sm">, </span>
                  ) : null}
                </>
              ) : null;
            })}
            {removeVariantModal.compositions.length > 5 ? (
              <>
                <span className="text-sm"> {lang.and} </span>
                <span className="text-blue text-sm">{` ${
                  removeVariantModal.compositions.length - 5
                } more`}</span>
              </>
            ) : null}
            . {lang.removeVariantFirstFromProduct}{" "}
            {pluralize(removeVariantModal?.compositions?.length, lang.set, lang.sets)}{" "}
            {lang.toDeleteItem}
          </Text>
        ) : (
          <Text>
            {lang.populate(lang.areYouSureYouWantToRemoveVariant, [removeVariantModal.variant])}
          </Text>
        )}
      </Modal>
    </Panel>
  );
};

export default memo(ManageVariantForm);
