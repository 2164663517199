const stocktakePaths = {
  STOCKTAKE: "",
  SEARCH_STOCKTAKE: "search",
  SUPPLY_STOCKTAKE: (id) => id,
  GET_SUPPLY_STOCKTAKE: ({ id, venueId }) => `${id}/venue/${venueId}`,
  COMPLETE_STOCKTAKE: (id) => `${id}/complete`,
  VOID_STOCKTAKE: (id) => `${id}/void`,
};

export default stocktakePaths;
