import React from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { PieChart } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { prepareChart } from "services";
import { Path } from "paths";

const TopLocationsChart = ({ ...props }) => {
  const data = prepareChart(props.mappedData);
  return (
    <ChartContainer
      title={{ to: Path.LOCATION, name: lang.topLocations }}
      {...props}
      empty={!data.data?.length}
    >
      <PieChart
        className="mt-lg"
        {...data}
        formatToolTipValue={(value) => {
          return formatNumberToMoneyWithCurrencySymbol(value);
        }}
        legendName={lang.locations}
        legendValue={lang.totalSales}
      />
    </ChartContainer>
  );
};

export default TopLocationsChart;
