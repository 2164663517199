import { searchLocation } from "apis";
import { Field, Filter, MultipleSelect } from "components/commons";
import { useApi, useMount } from "hooks";
import { allLocationRequest, location } from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect } from "services";
import lang from "translations";

const SupplyItemFilter = ({
  filterState,
  requestState,
  modifyFilter,
  clearFilter,
  applyFilter,
}) => {
  const {
    request: searchLocationRequest,
    loading: loadingLocations,
    mappedData: locations,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
    handleOwnError: true,
  });

  useMount(() => {
    fetchLocationRequest(requestState);
  });

  const fetchLocationRequest = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, allLocationRequest));
    },
    [searchLocationRequest]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      applyFilter(searchKey);
    },
    [applyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  const locationOptions = useMemo(() => {
    let sorted = locations
      .map((item) => {
        item.name = item.name.trim();
        return item;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let mapped = mapObjectsToSelect(sorted, { textKey: "name", valueKey: "id" });
    return mapped;
  }, [locations]);

  const stockLevelOptions = useMemo(() => {
    return [
      {
        text: lang.inStock,
        value: "IN_STOCK",
      },
      {
        text: lang.reachedReorderPoint,
        value: "REACHED_REORDER_POINT",
      },
      {
        text: lang.belowParLevel,
        value: "BELOW_PAR_LEVEL",
      },
      {
        text: lang.zeroInventory,
        value: "OTHERS",
      },
    ];
  }, []);

  return (
    <Filter
      placeholder={lang.searchSupplyItemBy}
      className="mb-lg"
      onClear={clearFilterCb}
      onApply={(searchKey) => {
        applyFilterCb(searchKey);
      }}
      filterState={filterState}
    >
      <Field className="col-span-4" filterLabel={lang.location}>
        <MultipleSelect
          name="locations"
          selectAllText={lang.allLocations}
          loading={loadingLocations}
          options={locationOptions}
          value={filterState.locations}
          isAll={filterState.locations && filterState.locations.length === 0}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!locationOptions.length ? lang.noLocationAvailable : lang.selectLocation}
        />
      </Field>
      <Field className="col-span-4" filterLabel={lang.stockLevels}>
        <MultipleSelect
          name="stockLevels"
          defaultAll={true}
          selectAllText={lang.allStockLevels}
          isAll={filterState.stockLevels && filterState.stockLevels.length === 0}
          options={stockLevelOptions}
          value={filterState.stockLevels}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={lang.selectStockLevel}
        />
      </Field>
    </Filter>
  );
};

export default SupplyItemFilter;
