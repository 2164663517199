import { Delete, Post } from "services";
import { ApiPath } from "paths";

export const searchNotificationStockLevel = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_NOTIFICATION_STOCK_LEVEL}`, body);
  return res;
};

export const addAccountNotificationStockLevel = async (body) => {
  const res = await Post(`${ApiPath.NOTIFICATION_STOCK_LEVEL}`, body);
  return res;
};

export const editAccountNotificationStockLevel = async ({ id, ...body }) => {
  const res = await Post(`${ApiPath.NOTIFICATION_STOCK_LEVEL_ID(id)}`, body);
  return res;
};

export const removeAccountNotificationStockLevel = async ({ id }) => {
  const res = await Delete(`${ApiPath.NOTIFICATION_STOCK_LEVEL_ID(id)}`);
  return res;
};
