import React from "react";
import IntegrationCard from "../../integration-card/integration-card";

const IntegrationCardListLoadingState = ({ loading }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
      <IntegrationCard fetching={loading} />
      <IntegrationCard fetching={loading} />
    </div>
  );
};

export default IntegrationCardListLoadingState;
