import { ActionButton, InfoPanel, Modal, Title } from "components/commons";
import { StyleType } from "enums";
import React from "react";
import lang from "translations";

const UnBanTagModal = ({ active, close, ok, unBanning, unBanningTagId, ...props }) => {
  return (
    <Modal
      {...props}
      noHeader
      active={active}
      actionContent={
        <ActionButton
          loading={unBanning}
          disabled={unBanning}
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              close();
            },
          }}
          primary={{
            loading: unBanning,
            disabled: unBanning,
            text: lang.unbanTag,
            type: StyleType.Danger,
            onClick: () => {
              ok(unBanningTagId);
            },
          }}
        />
      }
    >
      <Title lg className="mb-sm pt-md" strong={true}>
        {lang.reallyUnbanTag}
      </Title>
      <p className="text-sm">{lang.unbanningWillMakeTagAccessible}</p>
      <InfoPanel>{lang.reallyUnbanThisTagDescription}</InfoPanel>
    </Modal>
  );
};

export default UnBanTagModal;
