import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";
const initialFormState = (initialState = {}) => {
  const { name = "", hasTables = false, noOfTables = 1, tables = [], outletId } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    hasTables: {
      name: "hasTables",
      value: hasTables,
      type: Field.CHECKBOX,
      validations: [Validation.required()],
      required: true,
    },
    noOfTables: {
      name: "noOfTables",
      value: noOfTables,
      type: Field.INPUT,
      label: lang.selectNumberOfTables,
      max: 100,
      maxLength: 3,
    },
    tables: {
      isFormArray: true,
      type: Field.ANY,
      value: [LocationTableField],
      name: "tables",
      required: true,
      validations: hasTables ? [Validation.minArrayValues(1)] : [],
      ...tables,
    },
    outletId: {
      name: "outletId",
      value: outletId,
      type: Field.DROPDOWN
    }
  };
};

const defaultFields = {
  value: "",
  type: Field.INPUT,
  maxLength: 75,
};

export const CreateTableField = ({ tableId, tableNumber, tableName }) => {
  return {
    ...LocationTableField,
    value: {
      ...LocationTableField.value,
      tableId: {
        ...LocationTableField.value.tableId,
        ...tableId,
      },
      tableNumber: {
        ...LocationTableField.value.tableNumber,
        ...tableNumber,
      },
      tableName: {
        ...LocationTableField.value.tableName,
        ...tableName,
      },
    },
  };
};

export const LocationTableField = {
  type: Field.ANY,
  isFormGroup: true,
  value: {
    tableId: {
      name: "tableId",
      type: Field.LABEL,
      error: false,
      pristine: true,
      dirty: false,
    },
    tableNumber: {
      name: "tableNumber",
      type: Field.LABEL,
      error: false,
      pristine: true,
      dirty: false,
    },
    tableName: {
      ...defaultFields,
      name: "tableName",
      placeholder: "",
      value: null,
      type: Field.INPUT,
      maxLength: 75,
      error: false,
      pristine: true,
      dirty: false,
    },
  },
};

export default initialFormState;
