import React, { useCallback, useMemo, useState } from "react";
import lang from "translations";
import LocationForm from "../location-form/location-form";
// import LocationFormSkeleton from "../location-form/location-form-skeleton";
import {
  deleteLocation,
  editLocation,
  getLocation,
  getLocationInventoryValue,
} from "apis/location.api";
import { locationRequest, location } from "mappers";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { DeleteModal } from "components/modals";
import { Icon, Text, Title, Toast } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";
import { Field } from "enums";
import { CreateTableField } from "../location-form/location.form-state";
const EditLocation = () => {
  const { query, history } = useRouter();
  const { id } = query;
  const [inventoryValue, setInventoryValue] = useState(0);

  const deleteModal = useModal();
  const {
    request,
    loading = true,
    error,
    mappedData,
    result: locationResult,
  } = useApi({
    api: getLocation,
    mapper: location,
  });

  const { request: getInventoryValue, result } = useApi({
    api: getLocationInventoryValue,
  });

  const { request: submit, loading: submitting } = useApi({
    api: editLocation,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: locationRequest,
  });

  useMount(() => {
    request({ locationId: id });
    fetchInventoryValue();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editLocation,
    });
  });

  const fetchInventoryValue = useCallback(async () => {
    await getInventoryValue({ locationId: id });
  }, [getInventoryValue, id]);

  const { request: requestDeleteLocation } = useApi({
    api: () => deleteLocation({ locationId: id }),
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await submit({ ...params, id });
        return {
          response: res,
          message: lang.changesSaved,
        };
      } catch (e) {
        throw e;
      }
    },
    [submit, id]
  );

  // if (loading || error) {
  //   return (
  //     <LocationFormSkeleton
  //       error={error}
  //       title={lang.editLocation}
  //       loading={loading}
  //       path={Path.LOCATION}
  //     />
  //   );
  // }

  const initialState = useMemo(() => {
    const { metadata } = locationResult || {};
    const { name, onlineOrderTables = [], outletId } = mappedData;
    return {
      name: name,
      outletId: outletId,
      hasTables: onlineOrderTables.length > 0,
      noOfTables: metadata?.totalOnlineOrderTables,
      tables: {
        isFormArray: true,
        type: Field.ANY,
        value: onlineOrderTables.map((table) => {
          return CreateTableField({
            tableId: {
              value: table.tableId,
              error: false,
            },
            tableNumber: {
              value: table.defaultName,
              error: false,
            },
            tableName: {
              value: table.customName,
            },
          });
        }),
      },
    };
  }, [mappedData, locationResult]);

  return (
    <>
      <LocationForm
        loading={loading}
        error={error}
        submit={submitForm}
        title={lang.editLocation}
        submitting={submitting}
        initialState={initialState}
        deleteLocationButton={{
          text: lang.deleteLocation,
          onClick: () => {
            // history.push(Path.LOCATION_DELETE_ID(id));
            setInventoryValue(result.data);
            deleteModal.show({
              ok: async () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.deleteLocation,
                  Page: lang.editLocation,
                });

                await requestDeleteLocation();
                Toast({
                  content: lang.populate(lang.locationNameDeleted, [mappedData.name]),
                  success: true,
                  icon: "check",
                }).open();
                history.push(Path.LOCATION);
              },
            });
          },
        }}
      />
      <DeleteModal
        primaryText={lang.deleteLocation}
        customHeader={
          <div className="flex items-center justify-between">
            <Title lg className="mb-sm pt-md">
              {lang.deleteLocation + "?"}
            </Title>
            <Icon
              className="outline-none text-xl text-gray hover:text-gray cursor-pointer"
              name="remove"
              onClick={() => {
                deleteModal.close();
              }}
            />
          </div>
        }
        customContent={
          <>
            <Text>
              {lang.populate(lang.areYouSureYouWantToDeleteLocation, [
                mappedData.name,
                formatNumberToMoneyWithCurrencySymbol(inventoryValue),
              ])}
            </Text>
            <br></br>
            <Text>{lang.deletingThisLocation}</Text>
          </>
        }
        {...deleteModal}
      />
    </>
  );
};

export default EditLocation;
