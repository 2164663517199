import { BookingStatus } from "enums";
import lang from "translations";
import moment from "moment";

export const statusFilter = () => {
  return [
    { text: `${lang.future} / ${lang.confirmed}`, value: BookingStatus.CONFIRMED },
    { text: lang.confirmationPending, value: BookingStatus.NOT_CONFIRMED },
    { text: `${lang.checkedIn} / ${lang.inHouse}`, value: BookingStatus.CHECKED_IN },
    { text: lang.checkedOut, value: BookingStatus.CHECKED_OUT },
    { text: lang.noShow, value: BookingStatus.NO_SHOW },
    { text: lang.cancelled, value: BookingStatus.CANCELLED },
  ];
};

export const bookingListFilterState = (venueId) => {
  return {
    venueId,
    searchKey: "",
    statusList: [],
    sourceList: [],
    pageable: true,
    page: 1,
    pageSize: 20,
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    startDateTime: null,
    endDateTime: null,
  };
};
