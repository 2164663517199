import { StaffContext } from "contexts";
import React, { useContext, useMemo } from "react";
import { Switch, Redirect } from "react-router";
import { Route } from "react-router-dom";

const RouteRender = ({ renders, defaultRedirect }) => {
  const { staffAccess } = useContext(StaffContext);

  const render = useMemo(() => {
    if (!staffAccess.length) {
      return [
        Object.keys(renders).map((key) => {
          const route = renders[key];
          return {
            ...route,
            path: key,
          };
        }),
        defaultRedirect,
      ];
    }
    let firstPath = staffAccess.includes(defaultRedirect) ? defaultRedirect : "";
    const obj = staffAccess
      .filter((access) => {
        return renders?.[access];
      })
      .map((access) => {
        if (!firstPath) {
          firstPath = access;
        }
        return {
          ...renders[access],
          path: access,
        };
      });

    return [obj, firstPath];
  }, [renders, staffAccess, defaultRedirect]);
  return (
    <Switch>
      {render[0].map((item, key) => {
        return (
          (item.featureFlag === undefined || item.featureFlag) && (
            <Route component={item.component} exact={item.exact} path={item.path} key={key} />
          )
        );
      })}
      <Redirect to={render[1]} />
    </Switch>
  );
};

export default RouteRender;
