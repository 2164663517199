import React, { useCallback } from "react";
import { InputNumber as AntInputNumber } from "antd";
import classnames from "classnames";
// import styles from "./input-number.module.scss";
import { isInt } from "services";

const InputNumber = ({
  name,
  onChange,
  className,
  error = false,
  min = 1,
  max,
  defaultValue,
  step,
  formatter,
  precision,
  value,
}) => {
  const onChangeCb = useCallback(
    (value) => {
      if (isInt(value)) {
        onChange(name, { value });
      }
    },
    [name, onChange]
  );

  return (
    <AntInputNumber
      className={classnames(className, {
        "border-white-darker text-black bg-white": !error,
        "border-red text-red": error,
      })}
      min={min}
      max={max}
      formatter={formatter}
      precision={precision}
      step={step}
      defaultValue={defaultValue}
      onChange={onChangeCb}
      value={value}
      size="large"
      error={error}
    />
  );
};

export default InputNumber;
