import { Get, Post } from "services";
import { ApiPath } from "paths";

export const searchBookings = async (body) => {
  body.platform = "venue_dashboard"; // band aid fix for android crashing
  const res = await Post(`${ApiPath.SEARCH_BOOKING}`, body);
  return res;
};

export const getBookingDetails = async ({ bookingId }) => {
  const res = await Get(`${ApiPath.BOOKING_ID(bookingId)}`);
  return res;
};

// TODO below

export const searchBookingGuests = async ({ id, ...body }) => {
  const res = await Post(`${ApiPath.SEARCH_BOOKING_GUESTS(id)}`, body);
  return res;
};

export const searchBookingAccommodation = async ({ id, ...body }) => {
  const res = await Post(`${ApiPath.SEARCH_BOOKING_ACCOMMODATION(id)}`, body);
  return res;
};

export const getBookingSummary = async ({ bookingId }) => {
  const res = await Get(`${ApiPath.BOOKING_SUMMARY(bookingId)}`);
  return res;
};

export const getBookingSources = async () => {
  const res = await Get(`${ApiPath.BOOKING_SOURCES}`);
  return res;
}
