import { filterRequest, selectToObjectRequest } from "./common.mapper";
import {
  mapSelectObjectsToValue,
  mapObjectsToSelect,
  mapObjects,
  mapObject,
  sortByKeyName,
} from "services";

export const printer = {
  id: { key: "printerId" },
  name: {
    key: "printerName",
  },
  description: {
    key: "description",
  },
  locations: {
    transform: ({ self }) => {
      return mapObjectsToSelect(self, { textKey: "locationName", valueKey: "locationId" });
    },
  },
  printerProductSkus: {
    transform: ({ self }) => {
      return mapObjects(self, {
        id: { key: "productId" },
        name: { key: "productName" },
        productSku: {
          transform: ({ self }) => {
            return mapObject(self, { id: self.productSkuId });
          },
        },
      });
    },
  },
  productSkuIds: {
    transform: ({ src }) => {
      return src.printerProductSkus.map((pps) => {
        return pps.productSku.productSkuId;
      });
    },
  },
  venueId: { key: "venueId" },
};

export const printerListResponse = {
  id: { key: "printerId" },
  name: { key: "printerName" },
  // locations: {
  //   transform: ({ src }) => {
  //     const { locations } = src;
  //     return locations.length > 0
  //       ? locations
  //           .map((location) => {
  //             return location.locationName;
  //           })
  //           .join(", ")
  //       : "-";
  //   },
  // },
  locations: {
    transform: ({ src }) => {
      src.locations.sort(sortByKeyName("locationName", true));
      return src.locations;
    },
  },
};

export const printerListFilterRequest = {
  ...filterRequest,
};

export const printerCreateRequest = {
  _keys: ["locationIds", "productSkuIds", "venueId"],
  printerName: {
    key: "name",
  },
  description: {
    key: "description",
  },
  locationIds: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.locations);
    },
  },
  productSkuIds: { key: "productSkuIds" },
  venueId: { key: "venueId" },
};

export const printerUpdateRequest = {
  printerId: { key: "id" },
  printerName: {
    key: "name",
  },
  description: {
    key: "description",
  },
  locationIds: selectToObjectRequest("locations"),
  productSkuIds: {
    key: "productSkuIds",
  },
  venueId: {
    key: "venueId",
  },
};
