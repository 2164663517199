import React from "react";
import { PageHeader, Container, PageFooter } from "components/commons";
import styles from "./layout-b.module.scss";
import classnames from "classnames";
import "firebase/compat/auth";
import "firebase/compat/database";
import { useFlags } from "launchdarkly-react-client-sdk";

const LayoutB = ({ children, sideMenu, hasUserNav = true }) => {
  const { headerSupportLinks } = useFlags();
  // const { processingStatus } = useFlags();
  // const { notificationBell } = useFlags();

  return (
    <div className="h-full layout-b">
      <PageHeader
        hasUserNav={hasUserNav}
        hasProcessingStatus={true}
        hasSupportLinks={headerSupportLinks}
        // hasNotificationBell={notificationBell}
      />
      {sideMenu && <div className="fixed h-full z-10 pt-14">{sideMenu}</div>}

      <div className={classnames(styles.content, "md:ml-side ml-xl pt-12")}>
        <Container className={classnames(`p-xxs md:p-lg`, styles.child)}>{children}</Container>
        <PageFooter />
      </div>
    </div>
  );
};

export default LayoutB;
