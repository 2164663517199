import React, { useCallback, useContext, useMemo } from "react";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { FragmentA } from "components/fragments";
import {
  Field,
  Panel,
  ActionButton,
  Toast,
  Form,
  Skeleton,
  InputCounter,
  Title,
  InputMoney,
  Tooltip,
  Icon,
} from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { useForm, useModal, useRouter } from "hooks";
import initialFormState from "./staff-profile.form-state";
import { VenueContext } from "contexts";
import BrowseVoucher from "../../voucher/browse-voucher/browse-voucher.module";
import { mixpanel, TrackEvent } from "mixpanel";

const StaffProfileForm = ({
  submit,
  title,
  submitting,
  initialState = undefined,
  deleteButton,
  loading,
  error,
}) => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const unsaveChangesModal = useModal();

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty } = useForm({
    initialState: formState,
  });

  const goToList = useCallback(() => {
    history.push(Path.STAFF_PROFILE);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.saveStaffProfileForm,
        Page: lang.staffProfileList,
      });

      const res = await submit({ ...params, venueId });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.STAFF_PROFILE);
    } catch ({ code, handleError }) {
      const err = {
        3003: () => {
          applyFieldErrors({
            name: lang.locationNameAlreadyExist,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [submit, getFormValues, venueId, history, applyFieldErrors]);

  const submitFormValue = () => {
    submitForm(handleSubmit);
  };

  return (
    <ModuleWrapper
      error={error}
      header={<HeaderB title={title} returnText={lang.staffProfiles} onClick={leavePage} />}
      loading={loading}
      loader={
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <Skeleton />
          </Panel>
        </FragmentA>
      }
    >
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue}>
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <InputCounter {...fields.name} onChange={modifyField} />
            <InputCounter {...fields.description} onChange={modifyField} textarea />
          </Panel>
        </FragmentA>
        <FragmentA title={lang.vouchers} description={lang.assignVoucherStaff}>
          <Panel>
            <Title className="mb-md">{lang.preloadedVouchers}</Title>
            <BrowseVoucher
              value={fields.staffProfileVouchers.value}
              dirty={fields.staffProfileVouchers.dirty}
              onChange={(value) => {
                modifyField("staffProfileVouchers", {
                  value,
                });
              }}
            />
          </Panel>
        </FragmentA>
        <FragmentA title={lang.credits} description={lang.assignFreeCreditsStaff}>
          <Panel>
            <div className="flex items-center">
              <Title className="mr-sm">{lang.freeCredits}</Title>
              <Tooltip title={lang.freeCreditsHoverTooltip}>
                <div>
                  <Icon name="info" className="text-xs text-gray" />
                </div>
              </Tooltip>
            </div>
            <Field {...fields.freeCredit} width="w-1/4">
              <InputMoney
                {...fields.freeCredit}
                onChange={modifyField}
                limit={10000000}
                noDecimal
              />
            </Field>
          </Panel>
        </FragmentA>

        <ActionButton
          showLine
          loading={submitting}
          primary={{
            onClick: () => {
              submitFormValue();
            },
          }}
          secondary={{
            onClick: () => leavePage(),
          }}
          danger={deleteButton}
        />
      </Form>
    </ModuleWrapper>
  );
};

export default StaffProfileForm;
