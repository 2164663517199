import { Delete, Get, Post } from "services";
import { ApiPath } from "paths";

export const searchPrinter = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRINTER}`, body);
  return res;
};

export const getPrinter = async ({ printerId }) => {
  const res = await Get(`${ApiPath.PRINTER_ID(printerId)}`);
  return res.data;
};

export const createPrinter = async ({ description, locationIds, printerName, productSkuIds, venueId }) => {
  const res = await Post(`${ApiPath.PRINTER}`, { description, locationIds, printerName, productSkuIds, venueId });
  return res;
};

export const editPrinter = async ({ printerId, description, locationIds, printerName, productSkuIds, venueId }) => {
  const res = await Post(`${ApiPath.PRINTER_ID(printerId)}`, { description, locationIds, printerName, productSkuIds, venueId });
  return res;
};

export const deletePrinter = async ({ printerId }) => {
  const res = await Delete(`${ApiPath.PRINTER_ID(printerId)}`);
  return res.data;
};