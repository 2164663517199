import lang from "translations";

const columns = [
  {
    key: "product",
    text: lang.product,
    custom: true,
    callback: true,
  },
  {
    key: "stockLevel",
    text: lang.status,
    custom: true,
  },
  {
    key: "supplyPriceRange",
    text: lang.supplyCost,
    align: "right",
  },
  {
    key: "markUpRange",
    text: lang.markUp,
    align: "right",
  },
  {
    key: "retailPriceRange",
    text: lang.retailPrice,
    align: "right",
  },
  {
    key: "activeLocation",
    text: lang.displayedIn,
    align: "right",
  },
];

export default columns;
