export const guestPaths = {
  GUEST: "",
  GUEST_PROFILE: "profile",
  GUEST_PROFILE_CREATE: "profile/create",
  GUEST_PROFILE_EDIT: "profile/:id",
  GUEST_PROFILE_ID: (id) => `profile/${id}`,
  GUEST_DETAILS: ":id",
  GUEST_DETAILS_ID: (id) => `${id}`,
  GUEST_DETAILS_PROFILE: ":id/profile",
  GUEST_DETAILS_PROFILE_ID: (id) => `${id}/profile`,
};
