import classNames from "classnames";
import {
  Field,
  Input,
  MultipleSelect,
  Panel,
  TextArea,
  UploadImage,
  Select,
} from "components/commons";
import { FragmentA } from "components/fragments";
import React from "react";
import { groupAccountOptions } from "services/accounting.service";
import lang from "translations";
import styles from "./basic-form.module.scss";

const BasicInfoForm = ({
  form,
  categories,
  categoriesLoading,
  loader = true,
  accountingOptions,
  loading,
  modifyField,
}) => {
  const {
    productLogo,
    productName,
    description,
    category,
    revenueAccountCodeId,
    cogsAccountCodeId,
  } = form;

  return (
    <FragmentA title={lang.basicInfo}>
      <Panel>
        <div>
          <div className="flex w-full">
            <div className={classNames("pr-md pb-sm", styles.image)}>
              <UploadImage {...productLogo} loader={loader} />
            </div>
            <div className={styles.field}>
              <Field {...productName}>
                <Input {...productName} />
              </Field>
              <Field {...description}>
                <TextArea {...description} className={styles.textarea} />
              </Field>
            </div>
          </div>
          <Field {...category}>
            <MultipleSelect
              optionsLoading={categoriesLoading}
              {...category}
              options={categories.filter((category) => {
                return category.value !== 0;
              })}
            />
          </Field>
          <div className="grid grid-cols-2 gap-4">
            <Field {...revenueAccountCodeId}>
              <Select
                {...revenueAccountCodeId}
                loading={loading}
                searchable
                options={accountingOptions}
                groupedOptions={groupAccountOptions(
                  accountingOptions,
                  cogsAccountCodeId.suggested,
                  false
                )}
                customNotFoundContent={lang.accountNotFound}
              />
            </Field>
            <Field {...cogsAccountCodeId}>
              <Select
                {...cogsAccountCodeId}
                loading={loading}
                searchable
                options={accountingOptions}
                groupedOptions={groupAccountOptions(
                  accountingOptions,
                  cogsAccountCodeId.suggested,
                  false
                )}
                customNotFoundContent={lang.accountNotFound}
              />
            </Field>
          </div>
        </div>
      </Panel>
    </FragmentA>
  );
};

export default BasicInfoForm;
