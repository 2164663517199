import { Field, StaffRole } from "enums";
import { prettifyStaffRole } from "services";
import Validation from "services/validation.service";
import lang from "translations";

const roleOptions = () => {
  return [
    StaffRole.SellOnPos,
    StaffRole.TopupCredits,
    // StaffRole.ReturnCredits,
    StaffRole.RegisterGuests,
    StaffRole.StockManager,
  ].map((role) => {
    return {
      text: prettifyStaffRole(role),
      value: role,
    };
  });
};

const initialFormState = (initialState = {}) => {
  const {
    email,
    staffProfile,
    role = { value: [] },
    paymentMethod = { disabled: true, value: [] },
    location = { disabled: true, value: [] },
    isActive,
  } = initialState;
  let isManager = true;
  let hasPM = false;
  let hasLocation = false;
  const v = role.value.map((r) => {
    return r.value;
  });
  isManager = !v.length;
  if (
    isManager ||
    v.includes(StaffRole.TopupCredits) ||
    v.includes(StaffRole.RegisterGuests || v.includes(StaffRole.ReturnCredits))
  ) {
    hasPM = true;
  }
  if (isManager || v.includes(StaffRole.SellOnPos) || v.includes(StaffRole.StockManager)) {
    hasLocation = true;
  }

  return {
    email: {
      name: "email",
      value: "",
      type: Field.INPUT,
      validations: [Validation.emailAddress(), Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.emailAddress,
      placeholder: "john@example.com",
      ...email,
    },
    staffProfile: {
      name: "staffProfile",
      value: null,
      type: Field.INPUT,
      label: lang.staffProfile,
      options: [],
      ...staffProfile,
    },
    role: {
      name: "role",
      type: Field.ANY,
      validations: [Validation.requiredMultiDropdown()],
      required: true,
      label: lang.role,
      placeholder: lang.chooseRole,
      options: roleOptions(),
      selectAllText: lang.manager,
      value: role.value,
    },
    paymentMethod: {
      name: "paymentMethod",
      value: paymentMethod.value,
      type: Field.ANY,
      validations: [],
      required: true,
      label: lang.allowedPaymentMethod,
      placeholder: lang.choosePaymentMethod,
      selectAllText: lang.allPaymentMethods,
      options: [],
      disabled: paymentMethod?.disabled || !hasPM || paymentMethod.disabled,
      emptyPlaceHolder: lang.noAvailablePaymentMethodYet,
    },
    location: {
      name: "location",
      value: location.value,
      type: Field.ANY,
      validations: [],
      required: true,
      label: lang.locations,
      placeholder: lang.chooseLocationForThisStaff,
      selectAllText: lang.allLocations,
      options: [],
      disabled: location?.disabled || !hasLocation || location.disabled,
      emptyPlaceHolder: lang.noAvailableLocationsYet,
    },
    isActive: {
      name: "isActive",
      value: false,
      type: Field.CHECKBOX,
      title: lang.accountIsActive,
      description: lang.staffCanAccess,
      ...isActive,
    },
  };
};

export default initialFormState;
