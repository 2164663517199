import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import {
  StaffList,
  CreateStaff,
  EditStaff,
  CreateStaffProfile,
  StaffProfileList,
  EditStaffProfile,
} from "modules";

const StaffPage = () => {
  return (
    <Switch>
      <Route exact path={Path.STAFF_PROFILE_CREATE} component={CreateStaffProfile} />
      <Route exact path={Path.STAFF_PROFILE_EDIT_PAGE} component={EditStaffProfile} />
      <Route exact path={Path.STAFF_PROFILE} component={StaffProfileList} />
      <Route exact path={Path.STAFF_CREATE} component={CreateStaff} />
      <Route exact path={Path.STAFF_EDIT} component={EditStaff} />
      <Route exact path={Path.STAFF} component={StaffList} />
    </Switch>
  );
};

export default StaffPage;
