import { GuestStatus, CreditStatus } from "enums";
import lang from "translations";
import moment from "moment";

export const statusFilter = [
  { text: lang.all, value: null },
  { text: lang.checkedIn, value: GuestStatus.CHECKED_IN },
  { text: lang.checkedOut, value: GuestStatus.CHECKED_OUT },
];

export const creditFilter = [
  { text: lang.all, value: null },
  { text: lang.noRemainingBalance, value: CreditStatus.NO_REMAINING_BALANCE },
  { text: lang.withRemainingBalance, value: CreditStatus.WITH_REMAINING_BALANCE },
  { text: lang.withPendingPayments, value: CreditStatus.WITH_PENDING_PAYMENTS },
];

export const guestListFilterState = (venueId) => {
  return {
    venueId,
    searchKey: "",
    status: "",
    pageable: true,
    page: 1,
    pageSize: 20,
    staffs: [],
    guestProfiles: [],
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    guestTagStatus: null,
    creditStatus: null,
  };
};
