import React, { useCallback, useState } from "react";
import { Button, Icon, Input } from "components/commons";
import { StyleType } from "enums";
import { SearchOutlined } from "@ant-design/icons";
import classnames from "classnames";
import styles from "./filter.module.scss";
import { setGlobalDateTimeFilter } from "services";
import { useRouter } from "hooks";
import classNames from "classnames";
import { mixpanel, TrackEvent } from "mixpanel";
import lang from "translations";
import { removeWhitespace } from "services/number.service";
import { useFlags } from "launchdarkly-react-client-sdk";
const Filter = ({
  placeholder,
  children,
  className,
  onClear,
  onApply,
  searchBox = true,
  filterState = {},
  actionsSpan = 4,
}) => {
  const { dateRange } = filterState || {};
  const { filterMenuOpen } = useFlags();
  const [menuOpen, setMenuOpen] = useState(filterMenuOpen ? true : false);
  const [searchKey, setSearchKey] = useState(filterState.searchKey);
  const { history, pathname } = useRouter();
  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);
  let isUId = false;

  const validateUid = (id) => {
    const uidPattern = /([0-9A-Z]{4}\s+){3}([0-9A-Z]{2})/;
    return uidPattern.test(id);
  };

  if (validateUid(searchKey)) {
    isUId = true;
  }

  const onApplyCb = useCallback(
    (searchKeyOverride) => {
      if (searchKeyOverride === undefined || searchKeyOverride === null) {
        onApply(searchKey);
      } else {
        onApply(searchKeyOverride);
      }
    },
    [searchKey, onApply]
  );

  return (
    <div className={classnames(className)}>
      <div
        className={classnames("flex", {
          "shadow-sm": searchBox,
        })}
      >
        <Button
          className={classnames("filter-button text-md", styles.filterButton, {
            [`${styles.menuButtonOpen}`]: menuOpen,
            "rounded-r-none": searchBox,
            rounded: !searchBox,
            "shadow-sm": !searchBox,
          })}
          type={StyleType.Filter}
          iconPrefix={
            <Icon
              name="menu-filter"
              className={classnames("mr-sm mb-xxs text-sm text-gray-600", styles.filterIcon)}
              paddingless
            />
          }
          onClick={toggleMenu}
        >
          <span className="font-semibold text-black-light text-sm">{lang.filters}</span>
        </Button>
        {searchBox && (
          <Input
            clearable
            className={classnames("border-none rounded-l-none border-left", {
              "rounded-br-none": menuOpen,
            })}
            maxLength={75}
            placeholder={placeholder}
            iconSuffix={
              <SearchOutlined
                className="pointer text-gray-400"
                onClick={() => {
                  onApplyCb();
                }}
              />
            }
            onChange={(name, obj) => {
              setSearchKey(obj.value);
            }}
            value={searchKey}
            onClear={() => {
              setSearchKey("");
              onApplyCb("");
            }}
            onEnter={() => {
              if (isUId) {
                let v = searchKey;
                v = removeWhitespace(searchKey);
                setSearchKey(v);
                onApplyCb(v);
              } else {
                // if (searchKey?.length > 0) {
                onApplyCb();
                // }
              }
            }}
            onBlur={() => {
              if (isUId) {
                let v = searchKey;
                v = removeWhitespace(searchKey);
                setSearchKey(v);
              }
            }}
          />
        )}
      </div>
      {menuOpen && (
        <div
          className={classnames(
            "p-lg bg-pelorous-lighter rounded-b shadow-sm items-bottom lg:grid grid-cols-12 gap-4",
            styles.filterMenu
          )}
        >
          {children}
          <div
            className={classNames(`justify-end items-end mb-sm xs:flex col-span-${actionsSpan}`)}
          >
            <div className="text-right mx-sm">
              <Button
                type={StyleType.Secondary}
                onClick={() => {
                  mixpanel.track(TrackEvent.ClickedButton, {
                    button: lang.clearAllFilters,
                  });

                  setSearchKey("");
                  onClear();
                }}
              >
                {lang.clearAllFilters}
              </Button>
            </div>
            <div className="text-right">
              <Button
                type={StyleType.Primary}
                onClick={() => {
                  mixpanel.track(TrackEvent.ClickedButton, {
                    button: lang.applyFilter,
                  });

                  onApplyCb();
                  const locationFilterState = { ...filterState };
                  delete locationFilterState.dateRange;
                  delete locationFilterState.venueId;
                  history.replace({ pathname, state: locationFilterState });

                  if (dateRange) {
                    setGlobalDateTimeFilter(dateRange);
                  }
                }}
              >
                {lang.applyFilter}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
