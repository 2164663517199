import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { code = "", name = "" } = initialState;
  return {
    name: {
      name: "name",
      label: lang.accountName,
      value: name,
      type: Field.INPUT,
      validations: [
        Validation.minlength(3),
        Validation.required(),
        Validation.reserveWord(["others", "credit / debit card", "cash", "credit", "debit"]),
      ],
      required: true,
      maxLength: 75,
    },
    code: {
      name: "code",
      type: Field.INPUT,
      value: code,
      label: lang.accountCode,
    },
  };
};

export default initialFormState;
