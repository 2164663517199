import enumeration from "./enumeration";

const AccountType = {
  Asset: "ASSET",
  Liability: "LIABILITY",
  Equity: "EQUITY",
  Revenue: "REVENUE",
  Expense: "EXPENSE",
  Unknown: "UNKNOWN",
};

export default enumeration(AccountType);
