import { searchProductSku } from "apis/product.api";
import { Icon, Input, Text, Select, Tooltip, Field } from "components/commons";
import { VenueContext } from "contexts";
import { ProductType } from "enums";
import { useApi, useFilter } from "hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { parseAmountToNumber, toAmount } from "services";
import { formatNumberToMoney, parseMoneyToNumber } from "services/money.service";
import {
  add,
  divide,
  isDecimalLastCharacter,
  isNumberValid,
  isValidDecimalPlaces,
  multiply,
} from "services/number.service";
import { computeRetailPrice } from "services/product-price.service";
import Validation from "services/validation.service";
import lang from "translations";

const ItemsField = ({ form, modifyForm, productSkuId: skuId = null }) => {
  const { venue } = useContext(VenueContext);
  const { currencySymbol } = venue;
  const [searchKey, setSearchKey] = useState("");
  const { products } = form;

  const { request, mappedData, loading } = useApi({
    api: searchProductSku,
    isArray: true,
    mapper: {
      text: {
        key: "displayName",
      },
      product: {
        key: "product",
      },
      value: {
        key: "productSkuId",
      },
      supplyPrice: {
        key: "supplyPrice",
      },
      sku: {
        key: "sku",
      },
    },
  });

  const { modifyFilters } = useFilter({
    venueId: venue.venueId,
    page: 1,
    pageSize: 50,
    searchKey: "",
    filterProductSkuId: skuId,
  });

  const productOptions = useMemo(() => {
    if (!loading) {
      if (searchKey === "") {
        return [];
      }
      const selectedProductIds = products.value.map((p) => {
        return p.value?.productSkuId?.value;
      });
      const filteredProducts = mappedData.filter((el) => {
        return !selectedProductIds.includes(el.value);
      });
      return filteredProducts;
    }
    return [];
    // eslint-disable-next-line
  }, [mappedData, products]);

  useEffect(() => {
    setSearchKey("");
    // eslint-disable-next-line
  }, [products.value]);

  return (
    <div>
      <div className="grid grid-cols-9 mt-md ">
        <div className="col-span-4">
          <Text label>{lang.item}</Text>
        </div>
        <div className="col-span-2 px-sm flex items-center">
          <Text label>{lang.qty}</Text>
          <Tooltip title={<Text color="text-white">{lang.quantitySupplyItems}</Text>}>
            <span className="h-min mt-0 flex items-center">
              <Icon name="info" color="text-gray" className="text-xxs" />
            </span>
          </Tooltip>
        </div>
        <div className="col-span-3">
          <Text label className={"text-right pr-7"}>
            {lang.supplyCost}
          </Text>
        </div>
      </div>
      {products.value.map((field, index) => {
        const { value: product } = field;
        const onChange = (changes) => {
          const v = products.value;
          const indexValue = v[index];
          v[index] = {
            ...indexValue,
            value: {
              ...indexValue.value,
              ...changes,
            },
          };
          let supplyCost = 0;
          v.forEach((field) => {
            supplyCost = add(supplyCost, parseMoneyToNumber(field.value?.supplyPrice.value).value);
          });
          const markUp = parseMoneyToNumber(form.markUp.value).value;
          const muV = multiply(supplyCost, divide(markUp, 100));
          const rp = add(supplyCost, muV);
          modifyForm({
            [products.name]: {
              value: v,
            },
            supplyCost: {
              value: formatNumberToMoney(supplyCost),
            },
            retailPrice: {
              value: formatNumberToMoney(rp),
            },
          });
        };
        return (
          <div className="py-sm" key={index}>
            <div className="grid grid-cols-9">
              <div className="col-span-4">
                <Select
                  notFoundContent={""}
                  fetchingOptions={loading}
                  {...product.itemName}
                  searchable
                  height="h-14"
                  options={productOptions}
                  placeholder={lang.typeToSearch}
                  dynamicOptions={true}
                  onChange={(name, { value, option }) => {
                    const item = productOptions[option.key];
                    const indexValue = products.value[index];
                    const isSupplyItem = item.product.type === ProductType.SupplyItem;
                    onChange({
                      productSkuId: {
                        ...indexValue.value["productSkuId"],
                        value: item.value,
                        type: "ANY",
                      },
                      itemName: {
                        ...indexValue.value["itemName"],
                        value: item.text,
                        validations: [Validation.required()],
                      },
                      quantity: {
                        ...indexValue.value["quantity"],
                        value: toAmount(
                          0,
                          item.product.measurement === null
                            ? "0,0"
                            : item.product.measurement?.unit === "pc"
                            ? "0,0"
                            : "0,0.000"
                        ),
                        validations: [
                          Validation.required(),
                          Validation.number(),
                          !isSupplyItem || item.product.measurement?.unit === "pc"
                            ? Validation.minValue(1)
                            : Validation.minValue(0.1),
                        ],
                        symbol: isSupplyItem ? item.product.measurement?.unit : "pc",
                      },
                      supplyPrice: {
                        ...indexValue.value["supplyPrice"],
                        value: toAmount(0),
                      },
                      price: {
                        ...indexValue.value["price"],
                        value: item.supplyPrice,
                      },
                    });
                  }}
                  onSearch={(value) => {
                    const { filterState } = modifyFilters({
                      searchKey: value,
                      filterProductSkuId: skuId,
                    });
                    setSearchKey(value);
                    request(filterState);
                  }}
                />
              </div>
              <div className="col-span-2 px-sm">
                <Field {...product.quantity}>
                  <Input
                    {...product.quantity}
                    className="h-14"
                    right
                    iconSuffix={
                      <span className="text-sm text-gray-400 pl-sm">{product.quantity.symbol}</span>
                    }
                    onChange={(name, { value }) => {
                      const isProductPerPc = product.quantity.symbol === "pc";
                      if (
                        (isProductPerPc &&
                          (isDecimalLastCharacter(value) ||
                            !isNumberValid(value) ||
                            !isValidDecimalPlaces(value, 0))) ||
                        (!isProductPerPc &&
                          (!isNumberValid(value) ||
                            (!isDecimalLastCharacter(value) && !isValidDecimalPlaces(value, 3))))
                      ) {
                        return;
                      }
                      const max = isProductPerPc ? 99999999 : 9999999.999;
                      if (parseAmountToNumber(value) <= max) {
                        onChange({
                          [name]: {
                            ...products.value[index].value["quantity"],
                            value,
                            validations: [
                              Validation.required(),
                              Validation.number(),
                              isProductPerPc ? Validation.minValue(1) : Validation.minValue(0.1),
                            ],
                          },
                          supplyPrice: {
                            ...products.value[index].value["supplyPrice"],
                            value: formatNumberToMoney(
                              value * products.value[index].value["price"].value
                            ),
                          },
                          retailPrice: {
                            ...products.value[index].value["retailPrice"],
                            value: formatNumberToMoney(
                              computeRetailPrice(
                                form.supplyPrice?.value,
                                parseAmountToNumber(form.markUp.value)
                              )
                            ),
                          },
                        });
                      }
                      if (value === "" || isNaN(value)) {
                        onChange({
                          [name]: {
                            ...products.value[index].value["quantity"],
                            value: null,
                            error: true,
                          },
                          supplyPrice: {
                            ...products.value[index].value["supplyPrice"],
                            value: toAmount(0),
                          },
                        });
                        return;
                      }
                    }}
                    onFocus={() => {
                      const value = parseAmountToNumber(
                        products.value[index].value["quantity"].value
                      );
                      onChange({
                        quantity: {
                          ...product.quantity,
                          value: !value ? "" : value,
                        },
                      });
                    }}
                    onBlur={() => {
                      const isProductPerPc = product.quantity.symbol === "pc";
                      onChange({
                        quantity: {
                          ...product.quantity,
                          value: toAmount(
                            parseAmountToNumber(products.value[index].value["quantity"].value),
                            isProductPerPc ? "0,0" : "0,0.000"
                          ),
                        },
                      });
                    }}
                  />
                </Field>
              </div>
              <div className="col-span-3 flex items-start">
                <Input
                  {...product.supplyPrice}
                  right
                  disabled
                  className="h-14"
                  iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
                />
                {products.value.length > 1 && (
                  <Icon
                    name="remove"
                    color="text-gray"
                    onClick={() => {
                      const v = products.value.filter((item, itemIndex) => {
                        return index !== itemIndex;
                      });
                      products.onChange(products.name, {
                        value: v,
                      });
                      let supplyCost = 0;
                      v.forEach((field) => {
                        supplyCost = add(
                          supplyCost,
                          parseMoneyToNumber(field.value?.supplyPrice.value).value
                        );
                      });
                      const markUp = parseMoneyToNumber(form.markUp.value).value;
                      const muV = multiply(supplyCost, divide(markUp, 100));
                      const rp = add(supplyCost, muV);
                      modifyForm({
                        [products.name]: {
                          value: v,
                        },
                        supplyCost: {
                          value: formatNumberToMoney(supplyCost),
                        },
                        retailPrice: {
                          value: formatNumberToMoney(rp),
                        },
                      });
                    }}
                  />
                )}
              </div>
            </div>
            {index + 1 !== products.value.length && (
              <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ItemsField;
