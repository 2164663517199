import lang from "translations";

const columns = [
  {
    key: "name",
    text: lang.printerProfile,
  },
  {
    key: "locations",
    text: lang.locationDesignation,
  },
];

export default columns;
