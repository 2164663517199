import React, { useCallback, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { KitchenSink, VenueSelect } from "modules";
import { Path } from "paths";
import { VenuePage } from "pages";
import { useApi, useMount } from "hooks";
import { ProfileContext, VenuesContext } from "contexts";
import { getProfile, getVenues } from "apis";
import profileState from "./profile.state";
import { VenueRoute, VenueSelectRoute } from "routes";
import CreateVenue from "modules/venue/create-venue/create-venue.module";
import { mixpanel } from "mixpanel";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { environment } from "environments/environment";
import { sortByKeyName } from "services";

const AuthorizedPage = () => {
  // const location = useLocation();
  const [profile, setProfile] = useState(profileState);
  const [venues, setVenues] = useState([]);
  const [hasVenue, setHasVenue] = useState(true);
  const [LDProvider, setLaunchDarkly] = useState(null);
  const ldClient = useLDClient();

  const { request: getProfileRequest, loading: fetchingProfile = true } = useApi({
    api: getProfile,
  });
  const { request: getVenuesRequest, loading: fetchingVenues = true } = useApi({ api: getVenues });

  const setupAsyncWithLDProvider = async ({ email, id, name }) =>
    await asyncWithLDProvider({
      clientSideID: environment.FEATURE_FLAG.clientSideID,
      user: { key: id, email: email, name: name },
      options: {
        /* options... */
      },
    });

  useMount(() => {
    fetchProfile();
    fetchVenueList();
  });

  const fetchProfile = useCallback(async () => {
    const res = await getProfileRequest();
    setProfile(res);
    if (res.userId && res.userId !== 0) {
      // console.log(res)
      mixpanel.identify(res.userId);
      mixpanel.people.set({
        $email: res.email,
        name: res.fullName,
        resType: res.resType,
        resId: res.resId,
        syncUserId: res.syncUserId,
      });
      (async () => {
        try {
          const Provider = await setupAsyncWithLDProvider({
            id: res.userId,
            email: res.email,
            name: res.fullName,
          });
          await ldClient?.identify({ key: res.userId });
          setLaunchDarkly(() => Provider);
        } catch (error) {
          console.log("Failed to load feature flags", error);
          setLaunchDarkly(() => {});
        }
      })();
    }
  }, [getProfileRequest, ldClient]);

  const fetchVenueList = useCallback(async () => {
    const venues = await getVenuesRequest();
    const sorted = venues.sort(sortByKeyName("venueName"));
    setVenues(sorted);
    setHasVenue(sorted.length);
    return sorted;
  }, [getVenuesRequest]);

  return (
    LDProvider && (
      <LDProvider>
        <ProfileContext.Provider value={{ profile, fetchProfile, loading: fetchingProfile }}>
          <VenuesContext.Provider
            value={{ venues, fetchVenueList, loading: fetchingVenues, hasVenue }}
          >
            <Switch>
              <Route exact path={Path.KITCHEN_SINK} component={KitchenSink} />
              <Route exact path={Path.CREATE_VENUE} component={CreateVenue} />
              <VenueSelectRoute exact path={Path.VENUE_SELECT} component={VenueSelect} />
              <VenueRoute path={Path.SLASH} component={VenuePage} />
            </Switch>
          </VenuesContext.Provider>
        </ProfileContext.Provider>
      </LDProvider>
    )
  );
};

export default AuthorizedPage;
