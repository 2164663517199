import React from "react";
import { TotalSalesChart, TotalSalesLineChart } from "./chart";
import classnames from "classnames";

const SalesCharts = ({ graphOfTotalSales, lineChartTotalSales }) => {
  return (
    <div className={classnames("flex flex-wrap")}>
      <div className="flex-auto w-full md:w-2/6">
        <TotalSalesChart {...graphOfTotalSales} />
      </div>
      <div className="flex-auto w-full md:w-3/5">
        <TotalSalesLineChart {...lineChartTotalSales} />
      </div>
    </div>
  );
};

export default SalesCharts;
