import React, { useCallback, useContext, useMemo, useEffect, useState } from "react";
import { searchSupplyItem, updateStocktake, getStocktake } from "apis";
import {
  ActionButton,
  Button,
  Icon,
  Pill,
  TabBar,
  Title,
  Toast,
  ModuleWrapper,
  HeaderB,
  UnsaveChangesModal,
} from "components";
import { StaffContext, VenueContext } from "contexts";
import { useApi, useFilter, useMount, useRouter, useForm, useModal } from "hooks";
import { supplyItem, stocktakeResponse } from "mappers";
import columns from "./columns";
import { supplyItemFilterStocktake } from "modules/inventory/supply-item-list/filters";
import { Path } from "paths";
import lang from "translations";
import initialFormState, { CreateSupplyItemField } from "./stocktake-form.state";
import { PillType, StocktakeStatus, StyleType } from "enums";
import {
  parseAmountToNumber,
  prettifyStocktakeStatus,
  subtract,
  computeStocktakeItemAmount,
  formatId,
  redirectTo,
} from "services";
import StocktakeItemsTable from "./stocktake-items-table";
import { StocktakeSummary } from "modules";
import { ShareStocktakeModal, VoidStocktakeModal, CompleteStocktakeModal } from "..";

const StocktakeDetails = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const { venue } = useContext(VenueContext);
  const { staff } = useContext(StaffContext);
  const { venueId } = venue;
  const { profileId } = staff;

  const completeStocktakeModal = useModal();
  const voidStocktakeModal = useModal();
  const unsaveChangesModal = useModal();
  const shareStocktakeModal = useModal();

  const [items, setItems] = useState(null);
  const [locationId, setLocationId] = useState();
  const [dataBeforeComplete, setDataBeforeComplete] = useState();

  const {
    request,
    mappedData: stocktake,
    loading: fetchingStocktake = true,
    error = false,
  } = useApi({
    api: getStocktake,
    mapper: stocktakeResponse,
    handleOwnError: true,
    handleVenueBadRequest: true,
  });

  const formState = useMemo(() => {
    return initialFormState(stocktake);
  }, [stocktake]);

  const { fields, modifyField, modifyForm, getFormValues, clearForm, submitForm, dirty } = useForm({
    initialState: formState,
  });

  const { request: save } = useApi({
    api: updateStocktake,
  });

  const {
    request: searchSupplyItems,
    result: supplyItems = { data: [], metadata: {} },
    loading = true,
  } = useApi({
    api: searchSupplyItem,
    isArray: true,
    mapper: supplyItem,
  });

  useMount(async () => {
    try {
      const res = await request({ stocktakeId: id, venueId: venueId });
      const { data } = res;
      setLocationId(data.locationId);
      await searchSupplyItems({ ...filterState, locationId: data.locationId });
    } catch ({ code, handleError }) {
      const err = {
        3025: () => {
          Toast({
            content: lang.theLinkYouAreTryingToAccess,
            error: true,
          }).open();
        },
        3106: () => {
          Toast({
            content: lang.theLinkYouAreTryingToAccess,
            error: true,
          }).open();
        },
        3099: () => {
          Toast({
            content: lang.theLinkYouAreTryingToAccess,
            error: true,
          }).open();
        },
        3104: () => {
          Toast({
            content: lang.theLinkYouAreTryingToAccessDoesNotBelongToLocation,
            error: true,
          }).open();
        },
      };
      if (err[code]) {
        history.push(Path.INVENTORY_STOCKTAKE);
        err[code]();
      } else {
        handleError();
      }
    }
  });

  const { modifyFilters, filterState, requestState } = useFilter(
    supplyItemFilterStocktake(venueId, locationId)
  );

  const cleanUpSupplyItems = useCallback(
    (data) => {
      const { stocktakeItems = [], status } = stocktake;
      let items = [];
      data.forEach(
        ({
          productName = "",
          productSkuId = "",
          productId,
          inLocationStock,
          measurement = { unit: "" },
          pricePerOrderQuantity,
          orderQuantity,
          sku = "",
        }) => {
          let item =
            stocktakeItems && stocktakeItems.filter((item) => item.productSkuId === productSkuId);
          let currentStockLevel = item[0]?.currentStockLevel;
          items.push(
            CreateSupplyItemField({
              itemName: { value: productName },
              productSkuId: { value: productSkuId },
              productId: { value: productId },
              expectedStockQty: {
                value:
                  status === StocktakeStatus.Completed || status === StocktakeStatus.Void
                    ? currentStockLevel === undefined || currentStockLevel === null
                      ? inLocationStock
                      : currentStockLevel
                    : inLocationStock,
                symbol: measurement?.unit,
              },
              actualStockQty: {
                value:
                  item[0]?.actualStockLevel !== undefined ||
                  item[0]?.actualStockLevel !== null ||
                  item[0]?.actualStockLevel !== ""
                    ? item[0]?.actualStockLevel
                    : null,
                symbol: measurement?.unit,
              },
              pricePerOrderQty: { value: pricePerOrderQuantity ?? 0 },
              orderQty: { value: orderQuantity ?? 0 },
              sku: { value: sku },
            })
          );
        }
      );
      setItems(items);
      modifyField("stocktakeItems", { value: items, dirty: false });
    },
    [modifyField, stocktake]
  );

  useEffect(() => {
    if (!loading && !fetchingStocktake) {
      cleanUpSupplyItems(supplyItems?.data);
    }
    // eslint-disable-next-line
  }, [supplyItems.data, loading, fetchingStocktake]);

  const handleSaveStocktakeCb = useCallback(async () => {
    try {
      const params = getFormValues();
      let stocktakeItems = [];
      params.stocktakeItems.forEach((item) => {
        const { productId, productSkuId, actualStockQty, expectedStockQty } = item.value;
        const actualQty =
          actualStockQty.value !== "" &&
          actualStockQty.value !== null &&
          actualStockQty.value !== undefined
            ? parseAmountToNumber(actualStockQty.value)
            : null;
        stocktakeItems.push({
          productId: productId.value,
          productSkuId: productSkuId.value,
          expectedStockLevel: parseAmountToNumber(expectedStockQty.value),
          actualStockLevel: actualQty,
        });
      });
      const data = {
        venueId: venueId,
        stocktakeId: parseInt(id),
        stocktakeItems: stocktakeItems,
        staffProfileId: profileId,
        locationId,
      };
      setDataBeforeComplete(data);
      await save({ stocktakeId: id, body: data });
      await request({ stocktakeId: id, venueId });
      clearForm();
      await searchSupplyItems({ ...requestState, locationId });
      Toast({
        content: lang.stocktakeSaved,
        success: true,
      }).open();
    } catch (error) {}
  }, [
    getFormValues,
    save,
    id,
    venueId,
    profileId,
    request,
    clearForm,
    requestState,
    searchSupplyItems,
    locationId,
  ]);

  const saveStocktakeCb = () => {
    submitForm(handleSaveStocktakeCb);
  };

  const prepareData = useCallback(() => {
    return (
      items &&
      items.map((item) => {
        const {
          itemName = { value: "" },
          expectedStockQty = { value: 0 },
          actualStockQty,
          orderQty = { value: 0 },
          pricePerOrderQty = { value: 0 },
          sku = { value: "" },
        } = item?.value || {};
        let rawActualQty = parseAmountToNumber(actualStockQty.value);
        let actualQty = rawActualQty >= 0 ? rawActualQty : null;
        let amount = computeStocktakeItemAmount(
          pricePerOrderQty.value,
          orderQty.value,
          actualQty,
          expectedStockQty.value
        );
        let difference = actualQty >= 0 ? subtract(actualQty, expectedStockQty.value) : null;

        return {
          itemName: itemName.value,
          sku: sku.value,
          actualStockQty: actualStockQty,
          expectedStockQty: expectedStockQty.value,
          measurement: actualStockQty.symbol || expectedStockQty.value,
          difference,
          amount,
        };
      })
    );
    // eslint-disable-next-line
  }, [
    fields.stocktakeItems.value,
    fields.stocktakeItems.name,
    modifyForm,
    filterState.page,
    filterState.pageSize,
    fetchingStocktake,
    loading,
  ]);

  const data = useMemo(() => {
    return prepareData();
  }, [prepareData]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          history.push(Path.INVENTORY_STOCKTAKE);
          unsaveChangesModal.close();
        },
      });
      return;
    }
    history.push(Path.INVENTORY_STOCKTAKE);
  }, [dirty, history, unsaveChangesModal]);

  const actionButton = (showLine, showVoid = false) => {
    const isManager = staff?.roles?.includes("MANAGER");
    return (
      <ActionButton
        showLine={showLine}
        className="px-0 md:px-md"
        secondary={{
          text: lang.save,
          onClick: () => {
            saveStocktakeCb();
          },
        }}
        primary={
          isManager
            ? {
                text: lang.completeStocktake,
                onClick: () => {
                  completeStocktakeModal.show({
                    title: lang.populate(lang.completeStocktakeConfirmation, [
                      `ST-${formatId(stocktake.stocktakeId)}`,
                    ]),
                    formValues: getFormValues(),
                    id,
                    venueId,
                    profileId,
                    totalSupplyItems: supplyItems?.metadata.total,
                    onSave: save,
                    status: stocktake.status,
                    locationId,
                    items,
                    dataBeforeComplete,
                    stocktake,
                  });
                },
              }
            : null
        }
        danger={
          showVoid && isManager
            ? {
                text: lang.voidStocktake,
                onClick: () => {
                  voidStocktakeModal.show({
                    stocktakeId: id,
                    venueId,
                    profileId,
                    onSave: save,
                    formValues: getFormValues(),
                    locationId,
                  });
                },
              }
            : false
        }
      />
    );
  };

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState } = modifyFilters({
        page: 1,
        searchKey,
        locationId,
      });
      const { data } = await searchSupplyItems(requestState);
      cleanUpSupplyItems(data);
    },
    [searchSupplyItems, modifyFilters, cleanUpSupplyItems, locationId]
  );

  const renderStatus = useMemo(() => {
    const { status } = stocktake;
    const pillType =
      {
        [StocktakeStatus.InProgress]: PillType.Blue,
        [StocktakeStatus.ForApproval]: PillType.Orange,
        [StocktakeStatus.Void]: PillType.Red,
        [StocktakeStatus.Completed]: PillType.Green,
      }[status] || PillType.Gray;

    return (
      <Pill type={pillType} size="text-xs">
        {prettifyStocktakeStatus(status)}
      </Pill>
    );
  }, [stocktake]);

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          loading={fetchingStocktake}
          returnText={lang.stocktake}
          onClick={leavePage}
          customTitle={
            <div className="md:flex items-center gap-2">
              <Title xl className="mb-xs md:mb-0">
                {lang.populate(lang.stocktakeWithId, [formatId(id)])}
              </Title>
              {!error ? renderStatus : null}
            </div>
          }
          customActions={
            [StocktakeStatus.InProgress, StocktakeStatus.ForApproval].includes(stocktake.status) ? (
              <div className="mt-sm xs:flex items-center">
                <div>
                  <Button
                    type={StyleType.Link}
                    iconSuffix={<Icon name="share" />}
                    onClick={() => {
                      shareStocktakeModal.show({ stocktakeId: id });
                    }}
                  >
                    {lang.share}
                  </Button>
                </div>
                <div>{actionButton(false)}</div>
              </div>
            ) : null
          }
        />
      }
    >
      <StocktakeSummary loading={fetchingStocktake} stocktakeData={stocktake} />
      <TabBar className="mb-md" items={[{ to: Path.STOCKTAKE_ID(id), text: lang.items }]} />
      <StocktakeItemsTable
        data={data}
        columns={columns}
        filterState={filterState}
        modifyFilters={modifyFilters}
        loadingSupplyItems={loading}
        loadingStocktake={fetchingStocktake}
        total={supplyItems?.metadata.total ?? 0}
        applyFilter={applyFilterCb}
        items={items}
        searchSupplyItems={searchSupplyItems}
        locationId={locationId}
        status={stocktake.status}
        fields={fields}
        modifyForm={modifyForm}
      />
      {[StocktakeStatus.InProgress, StocktakeStatus.ForApproval].includes(stocktake.status)
        ? actionButton(
            true,
            [StocktakeStatus.InProgress, StocktakeStatus.ForApproval].includes(stocktake.status)
          )
        : null}
      <CompleteStocktakeModal {...completeStocktakeModal} />
      <UnsaveChangesModal {...unsaveChangesModal} />
      <VoidStocktakeModal
        {...voidStocktakeModal}
        refreshList={() => {
          redirectTo(Path.STOCKTAKE_ID(id));
        }}
      />
      <ShareStocktakeModal {...shareStocktakeModal} />
    </ModuleWrapper>
  );
};

export default StocktakeDetails;
