import { useCallback, useEffect, useMemo, useState } from "react";

const useMultipleSelect = ({
  items = [],
  key = "id",
  totalItems,
  setValue = () => {},
  defaultAll,
  ignored = [0],
  value = null,
  isAll: isAllState = undefined,
}) => {
  const [isAllValue, setIsAll] = useState(defaultAll);

  const isAll = useMemo(() => {
    if (isAllState !== undefined) {
      return isAllState;
    }
    return isAllValue;
  }, [isAllValue, isAllState]);

  const normalizeItems = useMemo(() => {
    const obj = {};
    if (items.length) {
      items.forEach((item, index) => {
        if (ignored.includes(item.id)) {
          return;
        }
        obj[item[key]] = { ...item, index };
      });
    }
    return obj;
  }, [items, key, ignored]);

  const options = useMemo(() => {
    return Object.keys(normalizeItems).map((k) => {
      return normalizeItems[k];
    });
  }, [normalizeItems]);

  const normalizeValues = useMemo(() => {
    const obj = {};
    if (value?.length) {
      value.forEach((item, index) => {
        obj[item[key]] = { ...item, index };
      });
    }
    return obj;
  }, [value, key]);

  const isAllSelected = useMemo(() => {
    const noOfSelectedItems = value?.length;
    if (!options.length) {
      return false;
    }
    if (totalItems) {
      if (noOfSelectedItems === totalItems) {
        return true;
      }
    }
    return noOfSelectedItems ? false : isAll;
  }, [options, isAll, totalItems, value]);

  const handleSetSelected = useCallback(
    (id) => {
      const values = { ...normalizeValues };
      setIsAll(false);
      if (values[id]) {
        delete values[id];
      } else {
        values[id] = {
          ...normalizeItems[id],
          checked: true,
        };
      }
      const selectedValues = Object.keys(values).map((k) => {
        return normalizeItems[k];
      });
      setValue({
        value: selectedValues.length ? selectedValues : null,
        isAll: false,
      });
    },
    [normalizeValues, normalizeItems, setValue]
  );

  const setSelectAll = useCallback(() => {
    const all = !isAllSelected;
    setIsAll(all);
    setValue({
      value: all ? [] : null,
      isAll: all,
    });
  }, [isAllSelected, setIsAll, setValue]);

  const optionsLength = useMemo(() => {
    if (totalItems) {
      return totalItems;
    }
    return items.length;
  }, [items.length, totalItems]);

  useEffect(() => {
    const selectedLength = value?.length;
    if (selectedLength === optionsLength && optionsLength && selectedLength) {
      setIsAll(true);
      setValue({
        value: [],
        isAll: true,
      });
    }
  }, [value, items, optionsLength, setValue, setIsAll]);

  return {
    isAllSelected,
    setSelectAll,
    setSelected: handleSetSelected,
    selected: normalizeValues,
    normalizeItems,
  };
};

export default useMultipleSelect;
