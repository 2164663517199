const locationPaths = {
  LOCATION: "",
  SEARCH_LOCATION: "search",
  LOCATION_ID: (locationId) => locationId,
  LOCATION_PRODUCT_ID: (id) => `${id}/product`,
  LOCATION_INVENTORY_VALUE: (locationId) => `${locationId}/location-inventory-value`,
  LOCATION_QR_EXPORT: (locationId) => `${locationId}/export`,
  LOCATION_QR_TABLE_EXPORT: (locationId) => `${locationId}/table/export`,
  LOCATION_STOCKTAKE: "stocktake",
};

export default locationPaths;
