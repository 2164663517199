import { mapSelectObjectsToValue } from "services";
import { timeAgo, toApiDateTimeFormat } from "services/date.service";
import * as moment from "moment";

export const dateTimeRequest = {
  startDateTime: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[0]) return toApiDateTimeFormat(moment().startOf("day"));
      return toApiDateTimeFormat(src?.dateRange?.[0]);
    },
  },
  endDateTime: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[1]) return toApiDateTimeFormat(moment().endOf("day"));
      return toApiDateTimeFormat(src?.dateRange?.[1], true);
    },
  },
};

export const filterRequest = {
  venueId: { key: "venueId" },
  page: { key: "page" },
  pageSize: { key: "pageSize" },
  searchKey: { key: "searchKey" },
};

export const selectToObjectRequest = (key) => {
  return {
    transform: ({ self }) => {
      return mapSelectObjectsToValue(self);
    },
    key,
  };
};

export const dateToTimeAgo = (key) => {
  return {
    transform: ({ self }) => {
      if (self === "Totals") {
        return "Totals";
      }
      if (!self) {
        return null;
      }
      return timeAgo(self, true);
    },
    key,
  };
};

export const getCurrentTimeWithTimezone = () => {
  return moment().format(moment.HTML5_FMT.DATETIME_LOCAL_MS + "ZZ");
};
