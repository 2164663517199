import lang from "translations";
const columns = [
  {
    key: "code",
    text: lang.code,
    custom: true,
  },
  {
    key: "name",
    text: lang.name,
    custom: true,
  },
  {
    key: "accountType",
    text: lang.accountType,
    custom: true,
  },
  {
    key: "currency",
    text: lang.currency,
    custom: true,
    // align: "right",
  },
  {
    key: "taxRate",
    text: lang.taxRate,
    custom: true,
    // align: "right",
  },
];

export default columns;
