import { Text, Input, Field } from "components";
import React from "react";
import { capitalize } from "services";
import lang from "translations";

const LocationTableForm = ({ tables = [], modifyForm }) => {
  return (
    <div>
      {tables.map((field, index) => {
        const onChange = (changes) => {
          const v = tables;
          const indexValue = v[index];
          v[index] = {
            value: {
              ...indexValue.value,
              ...changes,
            },
          };
          modifyForm({
            [tables.name]: { value: v },
          });
        };

        return (
          <div className="border-b" key={index}>
            <div className="p-md flex items-center">
              <Text {...field.value.tableNumber} size="text-base" className="w-full">{`Table #${
                index + 1
              }`}</Text>
              <Field {...field.value.tableName}>
                <Input
                  {...field.value.tableName}
                  onChange={(name, { value }) => {
                    if (value === " ") {
                      return;
                    }
                    onChange({
                      [name]: {
                        ...tables[index].value["tableName"],
                        value: value && capitalize(value),
                      },
                    });
                    const tableNames = tables.map((table) =>
                      table.value.tableName.value?.toLowerCase().trim()
                    );
                    const tableValue = value && value !== "" ? value.toLowerCase().trim() : "";
                    const hasDuplicate =
                      tableNames.filter((name) => name !== "" && name === tableValue).length > 1;
                    onChange({
                      tableName: {
                        ...tables[index].value["tableName"],
                        error: hasDuplicate,
                        dirty: true,
                        message: hasDuplicate ? lang.customNameIsAlreadyExisting : null,
                      },
                    });
                  }}
                  onBlur={() => {
                    let tableNames = new Set();
                    tables.forEach((table, index) => {
                      const val = table.value["tableName"].value;
                      const v = tables;
                      const indexValue = v[index];
                      if (val) {
                        v[index] = {
                          value: {
                            ...indexValue.value,
                            tableName: {
                              ...indexValue.value.tableName,
                              error: tableNames.has(val.trim()),
                              message: tableNames.has(val.trim())
                                ? lang.customNameIsAlreadyExisting
                                : "",
                            },
                          },
                        };
                      }
                      modifyForm({
                        [tables.name]: { value: v },
                      });
                      if (val && !tableNames.has(val)) {
                        tableNames.add(val.trim());
                      }
                    });
                  }}
                ></Input>
              </Field>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LocationTableForm;
