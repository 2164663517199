const profileState = {
  email: "",
  forId: 0,
  forType: "VENUE",
  profileId: 0,
  profileNfcTags: [],
  profilePermissions: [],
  profileType: "",
  status: "",
  syncUserId: 0,
  uniqueType: "",
  userId: 0,
  validated: false,
  validationMethod: [],
};

export default profileState;
