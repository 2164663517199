import { TimeUnit } from "enums";
import * as moment from "moment";

// prepareSeries = (data, dateTimeType) => {
//   const sort = data.sort((a, b) => {
//     const first = Object.keys(a)[0];
//     const second = Object.keys(b)[0];
//     const format = this.format(dateTimeType);
//     return moment(first, format).toDate() > moment(second, format).toDate()
//       ? 1
//       : moment(first, format).toDate() < moment(second, format).toDate()
//       ? -1
//       : 0;
//   });
//   const d = sort.map((plot) => {
//     const date = Object.keys(plot)[0];
//     const value = plot[date];
//     return {
//       name: this.formatLineChartDateDisplay(date, dateTimeType),
//       value,
//     };
//   });

//   return d;
// };

export const prepareChart = (series) => {
  if (series.length) {
    return {
      labels: series.map((s) => {
        return s.name;
      }),
      data: series.map((s) => {
        return s.value;
      }),
      items: series.map((s) => {
        return s.items;
      }),
    };
  }
  return {
    labels: [],
    data: [],
  };
};

export const getDateTimeFormatByTimeUnit = (type) => {
  const dateType = {
    [TimeUnit.Hour]: "ha",
    [TimeUnit.Day]: "MMM D",
    [TimeUnit.Month]: "MMM YYYY",
  };
  return dateType[type];
};

export const getApiDateTimeFormatByTimeUnit = (type) => {
  const dateType = {
    [TimeUnit.Hour]: "YYYY-MM-DD HH:mm:ss",
    [TimeUnit.Day]: "YYYY-MM-DD",
    [TimeUnit.Month]: "YYYY-MM",
  };
  return dateType[type];
};

export const denormalizePlotDateTimeFromApiResponse = (plot, timeUnit) => {
  return plot.map((p) => {
    const name = Object.keys(p)[0];
    return {
      name: moment(name, getApiDateTimeFormatByTimeUnit(timeUnit)).format(
        getDateTimeFormatByTimeUnit(timeUnit)
      ),
      value: p[name],
    };
  });
};
