import { TapStatus, TransactionStatus } from "enums";
import moment from "moment";

export const transactionListFilterState = (venueId, { query, guestCheckinId, initial }) => {
  let state = {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    dateRange: guestCheckinId
      ? [null, null]
      : [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    startDateTime: null,
    endDateTime: null,
    locations: [],
    shifts: [],
    staffs: [],
    transactionTypes: [],
    transactionStatus: guestCheckinId ? null : TransactionStatus.SUCCESS,
    tapStatus: guestCheckinId ? TapStatus.Success : null,
    sort: { key: "dateCreated", value: "desc" },
  };

  let queryState = {};
  if (query.hasOwnProperty("_startDateTime") && query.hasOwnProperty("_endDateTime")) {
    queryState = {
      ...queryState,
      dateRange: [moment(query._startDateTime), moment(query._endDateTime)],
    };
  }

  if (query.hasOwnProperty("shifts")) {
    queryState = { ...queryState, shifts: query.shifts || state.shifts };
  }

  if (query.hasOwnProperty("guestCheckinId")) {
    state.searchKey = query.guestCheckinId;
  }

  return { ...state, ...queryState };
};
