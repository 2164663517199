const voucherPaths = {
  VOUCHER: "",
  VOUCHER_ID: (categoryId) => categoryId,
  SEARCH_VOUCHER: "search",
  SEARCH_VOUCHER_MOVEMENT: "movement",
  SEARCH_VOUCHER_REPORT: "search/report",
  SEARCH_VOUCHER_REPORT_EXPORT: "search/report/export",
  VOUCHER_REPORT: (voucherId) => `${voucherId}/report`,
};

export default voucherPaths;
