import enumeration from "./enumeration";

const UserLanguage = {
  en: "EN",
  es: "ES",
  pt: "PT",
  th: "TH",
};

export default enumeration(UserLanguage);
