import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { filterRequest, selectToObjectRequest } from "./common.mapper";

export const supplyItem = {
  name: {
    key: "productName",
  },
  id: {
    key: "productId",
  },
  inStock: { key: "inStock" },
  reorderPoint: { key: "reorderPoint" },
  parLevel: { key: "parLevel" },
  inventoryValue: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  measurement: { key: "measurement" },
  sku: {
    transform: ({ src }) => {
      return `SI${src.productSkuId}`;
    },
  },
};

export const supplyItemSummary = {
  name: {
    key: "productName",
  },
  id: {
    key: "productId",
  },
  description: {
    key: "description",
  },
  inStock: { key: "inStock" },
  reorderPoint: { key: "reorderPoint" },
  parLevel: { key: "parLevel" },
  inventoryValue: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  measurement: {
    key: "measurement",
    transform: ({ src }) => {
      return src.measurement.unit;
    },
  },
  skuId: {
    key: "productSkus",
    transform: ({ src }) => {
      return src.productSkus[0].productSkuId;
    },
  },
  sku: {
    key: "productSkus",
    transform: ({ src }) => {
      return src.productSkus[0].sku;
    },
  },
  locationStocks: {
    key: "productSkus",
    transform: ({ src }) => {
      return src.productSkus[0].locationStocks;
    },
  },
  partOfCompositions: {
    key: "productSkus",
    transform: ({ src }) => {
      return src.productSkus[0].partOfCompositions;
    },
  },
};

export const supplyItemListFilterRequest = {
  ...filterRequest,
  locationIds: selectToObjectRequest("locations"),
  stockLevels: selectToObjectRequest("stockLevels"),
  orders: {
    "p.productName": "asc",
  },
};

export const supplyItemResponse = {
  name: {
    key: "productName",
  },
  description: {
    key: "description",
  },
  sku: {
    key: "productSkus",
    transform: ({ src }) => {
      return src.productSkus[0].sku;
    },
  },
  productSku: {
    key: "productSkus",
    transform: ({ src }) => {
      return {
        locationStocks: src.productSkus[0].locationStocks.map((stock) => {
          return {
            locationId: stock.locationId,
            name: stock.locationName,
            stock: stock.stock,
            parLevel: stock.parLevel,
            reorderPoint: stock.reorderPoint,
          };
        }),
        retailPrice: src.productSkus[0].retailPrice,
        supplyPrice: src.productSkus[0].supplyPrice,
        reorderPoint: src.productSkus[0].reorderPoint,
        parLevel: src.productSkus[0].parLevel,
        productSkuId: src.productSkus[0].productSkuId,
      };
    },
  },
  measurementId: {
    key: "measurement",
    transform: ({ src }) => {
      return src.measurement.measurementId;
    },
  },
  orderQty: {
    key: "orderQuantity",
  },
  pricePerOrderQty: {
    key: "pricePerOrderQuantity",
  },
  automaticallyGenerateSku: false,
  weightInGrams: { key: "weightInGrams" },
  supplyAccountCodeId: { key: "supplyAccountCodeId" },
};

export const supplyItemRequest = {
  productId: {
    key: "id",
  },
  productName: {
    key: "name",
  },
  description: {
    key: "description",
  },
  measurementId: {
    key: "measurementId",
  },
  pricePerOrderQuantity: {
    key: "pricePerOrderQuantity",
  },
  orderQuantity: {
    key: "orderQuantity",
  },
  productSku: {
    key: "productSku",
  },
  venueId: {
    key: "venueId",
  },
  supplyAccountCodeId: { key: "supplyAccountCodeId" },
  weightInGrams: { key: "weightInGrams" },
};
