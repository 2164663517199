import React from "react";
import { Line } from "react-chartjs-2";
import { formatNumberToShortUnit } from "services";

const LineChart = ({
  height = 250,
  labels,
  data = [],
  items,
  lineTension = 0,
  formatYAxis,
  formatToolTipValue,
}) => {
  // const value = useMemo(() => {
  //   if (data.length) {
  //     return data.sort().reverse()[0];
  //   }
  //   return 1;
  // });

  return (
    <div className="f-full">
      <Line
        height={height}
        data={{
          labels,
          datasets: [
            {
              data,
              lineTension,
              pointBackgroundColor: "#77C5DD",
              borderColor: "#77C5DD",
              fill: false,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            fontSize: 20,
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  // min: 0,
                  callback: function (label, index, labels) {
                    if (formatYAxis) {
                      return formatYAxis(label, index, labels);
                    }
                    return formatNumberToShortUnit(label);
                  },
                  precision: 0,
                  // stepSize: 1,
                  // beginAtZero: true,
                  // suggestedMin: 0,
                  // max: value * 0.1 + value,
                  // suggestedMax: value * 0.1 + value,
                },
              },
            ],
            xAxes: [
              {
                // ticks: {
                //   min: 0,
                // },
              },
            ],
          },
          tooltips: {
            displayColors: false,
            bodyAlign: "center",
            titleAlign: "center",
            bodySpacing: 3,
            bodyFontSize: 15,
            titleFontSize: 12,
            footerFontSize: 11,
            xPadding: 12,
            yPadding: 12,
            callbacks: {
              label: (l) => {
                if (formatToolTipValue) {
                  return formatToolTipValue(l);
                }
                return l.value || null;
              },
              footer: (l) => {
                if (items && items.length) {
                  const it = items[l[0].index];
                  return it?.map((item) => {
                    return `${item.name} · ${
                      formatToolTipValue ? formatToolTipValue({ value: item.value }) : item.value
                    }`;
                  });
                }
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
