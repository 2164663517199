import { sortByKeyName } from "services";

export const generateVariant = async (options) => {
  const optionsArray = [];
  const optionsNormalize = {};
  const attributeHeaders = [];

  await options.forEach((b, i) => {
    optionsArray.push(b.options);
    const attribute = b.attribute;

    attributeHeaders.push(attribute);

    b.options.forEach((optionList) => {
      optionsNormalize[optionList] = attribute;
    });
  });
  let result = [];
  if (Object.keys(optionsArray).length === 1) {
    optionsArray.forEach((b) => {
      b.forEach((v) => result.push([v]));
    });
  } else if (optionsArray[0]) {
    result = optionsArray[0].map((item) => {
      return [item];
    });
    for (let k = 1; k < optionsArray.length; k++) {
      const next = [];
      await result.forEach(async (item) => {
        await optionsArray[k].forEach((word) => {
          const line = item.slice(0);
          line.push(word);
          next.push(line);
        });
      });
      result = next;
    }
  }
  return {
    result: result
      .map((res) => {
        const normalize = {};
        res.forEach((r) => {
          normalize[optionsNormalize[r]] = r;
        });
        const sorted = attributeHeaders.sort().map((header) => {
          return normalize[header];
        });
        return {
          text: sorted.join(","),
          result: sorted,
        };
      })
      .sort(sortByKeyName("text"))
      .map((sorted) => {
        return sorted.result;
      }),
    optionsNormalize,
    attributeHeaders,
  };
};
