import { useRouter } from "hooks";
import React from "react";
import { Redirect } from "react-router";
import { isNumberValid } from "services";

const RedirectPage = () => {
  const { query } = useRouter();
  const { redirectUrl, accessToken, venueId } = query || {};

  if (accessToken) {
    localStorage.clear();
    localStorage.setItem("accessToken", accessToken);
    if (venueId && isNumberValid(venueId)) {
      localStorage.setItem("venueId", venueId);
    }
    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }
    return <Redirect to={`/`} />;
  }
  return null;
};

export default RedirectPage;
