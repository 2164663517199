import { Delete, Get, Post } from "services";
import { ApiPath } from "paths";

export const getVoucher = async ({ voucherId }) => {
  const res = await Get(`${ApiPath.VOUCHER_ID(voucherId)}`);
  return res;
};

export const searchVoucher = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_VOUCHER}`, body);
  return res;
};

export const searchVoucherMovement = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_VOUCHER_MOVEMENT}`, body);
  return res;
};

export const searchVoucherReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_VOUCHER_REPORT}`, body);
  return res;
};

export const searchVoucherReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_VOUCHER_REPORT_EXPORT}`, body);
  return res;
};

export const getVoucherDetailReport = async ({ voucherId, ...body }) => {
  const res = await Post(`${ApiPath.VOUCHER_REPORT(voucherId)}`, body);
  return res;
};

export const searchVoucherWithTransaction = async ({ venueId, ...body }) => {
  const res = await Post(`${ApiPath.VOUCHER_WITH_TRANSACTION(venueId)}`, body);
  return res;
};

export const createVoucher = async (body) => {
  const res = await Post(`${ApiPath.VOUCHER}`, body);
  return res;
};

export const editVoucher = async ({ voucherId, ...body }) => {
  const res = await Post(`${ApiPath.VOUCHER_ID(voucherId)}`, body);
  return res;
};

export const deleteVoucher = async ({ voucherId }) => {
  const res = await Delete(`${ApiPath.VOUCHER_ID(voucherId)}`);
  return res.data;
};
