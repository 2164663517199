import { getLocations } from "apis";
import { Field, MultipleSelect, ReportFilter, Select } from "components/commons";
import { useApi, useMount } from "hooks";
import { allLocationRequest, location } from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect } from "services";
import lang from "translations";

const InventoryReportFilter = ({
  filterState,
  requestState,
  modifyFilter,
  modifyFilters,
  clearFilter,
  fetchProductReports,
}) => {
  const {
    request: getLocationsRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: getLocations,
    isArray: true,
    mapper: location,
  });

  useMount(() => {
    fetchLocations(requestState);
  });

  const fetchLocations = useCallback(
    (requestState) => {
      getLocationsRequest(mapObject({ ...requestState }, allLocationRequest));
    },
    [getLocationsRequest]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ page: 1, searchKey });
      fetchProductReports(requestState);
    },
    [modifyFilters, fetchProductReports]
  );

  const clearFilterCb = useCallback(async () => {
    const { requestState } = await clearFilter();
    fetchProductReports(requestState);
  }, [clearFilter, fetchProductReports]);

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const measurementOptions = useMemo(() => {
    return [
      { text: lang.allMeasurements, value: null },
      { text: lang.lowParLevel, value: "lowParLevel" },
      { text: lang.reorderPointReached, value: "reorderPointReached" },
    ];
  }, []);

  return (
    <div>
      <ReportFilter onClear={clearFilterCb} onApply={applyFilterCb}>
        <Field className="col-span-4" filterLabel={lang.location}>
          <MultipleSelect
            name="locations"
            selectAllText={lang.allLocations}
            loading={loadingLocation}
            options={locationOptions}
            defaultAll
            isAll
            value={filterState.locations}
            onChange={(name, obj) => {
              modifyFilter(name, { value: obj.value });
            }}
            placeholder={lang.selectLocation}
            emptyPlaceHolder={lang.noLocationAvailable}
          />
        </Field>
        <Field className="col-span-4" filterLabel={lang.measurements}>
          <Select
            name="measurement"
            options={measurementOptions}
            value={filterState.measurement}
            onChange={modifyFilter}
          />
        </Field>
      </ReportFilter>
    </div>
  );
};

export default InventoryReportFilter;
