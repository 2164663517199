import { Button, Icon, Input, Modal, Text, Title } from "components/commons";
import React, { useCallback, useMemo, useContext } from "react";
import lang from "translations";
import { Field as FieldType, StyleType } from "enums";
import Validation from "services/validation.service";
import { useApi, useForm } from "hooks";
import { deactivateMews } from "apis/integration.api";
import { deactivateApplication } from "apis/application.api";
import { VenueContext } from "contexts";

const DisconnectModal = ({
  title,
  modifyField: modifyConnection,
  disconnectionMessage,
  id,
  request,
  isApp,
  integrationType,
  ...props
}) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue || {};
  const initialState = useMemo(() => {
    return {
      disconnect: {
        name: "disconnect",
        value: "",
        type: FieldType.INPUT,
        validations: [Validation.minlength(10)],
        maxLength: 10,
      },
    };
  }, []);

  const requestDeactivateMews = useApi({
    api: deactivateMews,
    params: { id, integrationType, venueId },
  });

  const deactivateApp = useApi({
    api: deactivateApplication,
  });

  const { fields, modifyField, submitForm } = useForm({
    initialState,
  });

  const submitFormValue = useCallback(async () => {
    if (!isApp) {
      await requestDeactivateMews.request();
    } else {
      await deactivateApp.request({
        id,
        body: {
          businessId: venueId,
          businessType: "VENUE",
        },
      });
    }

    modifyConnection("isConnected", { value: false });
    props.close();
    if (request) {
      request.request();
    }
  }, [modifyConnection, props, requestDeactivateMews, request, deactivateApp, isApp, venueId, id]);

  return (
    <Modal {...props} noHeader>
      <div className="p-sm">
        <div className="flex py-lg justify-between">
          <Title>{`Disconnect ${title}?`}</Title>{" "}
          <Icon name="remove" onClick={() => props.close()} />
        </div>

        <Text className="mb-md">{disconnectionMessage}</Text>
        <Text fontWeight="font-semibold" color="text-gray">
          {lang.typeDisconnect}
        </Text>
        <Input {...fields.disconnect} onChange={modifyField} />
        {fields.disconnect.value === "DISCONNECT" && (
          <Text label className="mt-xs">
            {lang.youAreAboutToDisconnect}
          </Text>
        )}
        <div className="flex my-md justify-end gap-2">
          <Button
            type={StyleType.Secondary}
            onClick={() => {
              props.close();
            }}
          >
            {lang.cancel}
          </Button>
          <Button
            loading={requestDeactivateMews.loading}
            type={StyleType.Danger}
            disabled={fields.disconnect.value !== "DISCONNECT"}
            onClick={() => {
              props.close();
              submitForm(submitFormValue);
            }}
          >
            {lang.disconnect}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisconnectModal;
