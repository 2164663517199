import React, { memo } from "react";
import { Upload as AntUpload } from "antd";
import classnames from "classnames";
import styles from "./upload.module.scss";

const Upload = ({
  accept,
  name,
  listType = "picture-card",
  showUploadList = false,
  beforeUpload,
  onChange = () => null,
  children,
  multiple = false,
  customRequest = null,
}) => {
  const handleOnChange = (data) => {
    onChange(data, name);
  };

  return (
    <AntUpload
      accept={accept}
      customRequest={customRequest}
      name={name}
      listType={listType}
      className={classnames(styles.uploadField, "uploadField")}
      showUploadList={showUploadList}
      beforeUpload={beforeUpload}
      onChange={handleOnChange}
      multiple={multiple}
    >
      {children}
    </AntUpload>
  );
};

export default memo(Upload);
