import React from "react";
import { Icon } from "..";
import styles from "./prev-next.module.scss";
import classNames from "classnames";

const PrevNext = ({ setPage, page, total }) => {
  const hasPrev = page !== 0;
  const hasNext = page + 1 !== total;
  return (
    <div className={styles.container}>
      <Icon
        name="left-arrow"
        fontSize={30}
        className={classNames({
          "text-pelorous": hasPrev,
          "text-gray cursor-not-allowed": !hasPrev,
        })}
        onClick={
          hasPrev
            ? () => {
                setPage(page - 1);
              }
            : null
        }
      />
      <Icon
        name="right-arrow"
        fontSize={30}
        className={classNames({
          "text-pelorous": hasNext,
          "text-gray cursor-not-allowed": !hasNext,
        })}
        onClick={
          hasNext
            ? () => {
                setPage(page + 1);
              }
            : null
        }
      />
    </div>
  );
};

export default PrevNext;
