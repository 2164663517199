import { filterRequest, selectToObjectRequest, dateTimeRequest } from "./common.mapper";
import { DateTime, StocktakeStatus } from "enums";
import { formatDate } from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { Text } from "components/commons";

export const stocktakeFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  locationIds: selectToObjectRequest("locations"),
  statusList: {
    transform: ({ src }) => {
      if (!src || src.status.length === 0 || src.status[0] === undefined) {
        return [
          StocktakeStatus.InProgress,
          StocktakeStatus.ForApproval,
          StocktakeStatus.Completed,
          StocktakeStatus.Void,
        ];
      } else {
        return src.status.map((selectedStatus) => {
          return selectedStatus.value;
        });
      }
    },
  },
  orders: {
    transform: ({ src }) => {
      return { [`s.updatedDate`]: src.sort?.value || "desc" };
    },
  },
};

export const stocktakeListResponse = {
  stocktakeId: { key: "stocktakeId" },
  locationName: { key: "locationName" },
  venueId: { key: "venueId" },
  status: { key: "status" },
  dateModified: {
    transform: ({ src }) => {
      return src.updatedDate ? formatDate(src.updatedDate, DateTime.C) : "-";
    },
  },
  timeModified: {
    transform: ({ src }) => {
      return src.updatedDate ? formatDate(src.updatedDate, DateTime.B) : "-";
    },
  },
  amount: {
    transform: ({ src }) => {
      if (src.status === StocktakeStatus.Void) {
        if (src.amount < 0) {
          return (
            <Text
              color="text-gray"
              className="line-through"
            >{`- ${formatNumberToMoneyWithCurrencySymbol(src.amount * -1)}`}</Text>
          );
        } else {
          return (
            <Text color="text-gray" className="line-through">
              {src.amount ? formatNumberToMoneyWithCurrencySymbol(src.amount) : "-"}
            </Text>
          );
        }
      }
      if (src.amount < 0) {
        return (
          <Text color="text-red">{`- ${formatNumberToMoneyWithCurrencySymbol(
            src.amount * -1
          )}`}</Text>
        );
      }
      return src.amount ? formatNumberToMoneyWithCurrencySymbol(src.amount) : "-";
    },
  },
};

export const stocktakeResponse = {
  stocktakeId: { key: "stocktakeId" },
  locationName: { key: "locationName" },
  locationId: { key: "locationId" },
  status: { key: "status" },
  lastModifiedOn: {
    transform: ({ src }) => {
      return src.updatedDate ? formatDate(src.updatedDate, DateTime.H) : "-";
    },
  },
  lastModifiedBy: { key: "modifiedBy" },
  completedBy: { key: "completedBy" },
  completedOn: {
    transform: ({ src }) => {
      return src.completedOn ? formatDate(src.completedOn, DateTime.H) : "-";
    },
  },
  startedOn: {
    transform: ({ src }) => {
      return src.startDate ? formatDate(src.startDate, DateTime.H) : "-";
    },
  },
  stocktakeItems: { key: "stocktakeItems" },
  totalAmount: { key: "amount" },
  voidedBy: { key: "voidedBy" },
  voidedOn: {
    transform: ({ src }) => {
      return src.voidedOn ? formatDate(src.voidedOn, DateTime.H) : "-";
    },
  },
};
