import { Field, InventoryType } from "enums";
import { capitalize } from "services";
import { formatNumberToMoney } from "services/money.service";
import Validation from "services/validation.service";
import lang from "translations";
import { AccountName, AccountType } from "enums";

const initialFormState = (initialState = {}) => {
  const {
    productLogo,
    productName,
    description,
    category,
    revenueAccountCodeId,
    cogsAccountCodeId,
    activePos,
    activeOnlineMenu = false,
    location,
    inventoryType,
    sku: skuField,
    generatedSku,
    supplyUnit,
    supplyQuantity,
    supplyItem,
    hasVariants,
    hasTax,
    tax,
    supplyCost,
    markUp,
    retailPrice,
    sellingQuantity,
    variants,
    attributeAndOptions,
    supplyItems,
    type,
    attributes,
  } = initialState;
  const sku = {
    validations: [],
    ...skuField,
  };
  return {
    productLogo: {
      name: "productLogo",
      image: undefined,
      value: undefined,
      file: "",
      type: Field.ANY,
      ...productLogo,
    },
    type: {
      ...type,
      type: Field.ANY,
      name: "type",
    },
    productName: {
      ...defaultFields,
      name: "productName",
      label: lang.productName,
      placeholder: lang.nameYourProduct,
      validations: [Validation.required()],
      required: true,
      ...productName,
    },
    description: {
      ...defaultFields,
      name: "description",
      label: lang.description,
      placeholder: lang.describeThisProduct,
      maxLength: 255,
      ...description,
    },
    category: {
      name: "category",
      label: capitalize(lang.category),
      placeholder: lang.selectToIncludeCategory,
      type: Field.ANY,
      selectAllText: lang.allCategories,
      isAll: false,
      value: [],
      emptyPlaceHolder: lang.noAvailableCategories,
      ...category,
    },
    revenueAccountCodeId: {
      name: "revenueAccountCodeId",
      label: lang.revenueAccount,
      type: Field.DROPDOWN,
      value: revenueAccountCodeId ? parseInt(revenueAccountCodeId) : null,
      emptyPlaceHolder: lang.noAccountsAvaiable,
      placeholder: lang.selectAccount,
      accountType: AccountName.RevenueAccount,
      suggested: AccountType.Revenue,
      ...revenueAccountCodeId,
    },
    cogsAccountCodeId: {
      name: "cogsAccountCodeId",
      label: lang.costOfGoodsSold,
      type: Field.DROPDOWN,
      value: cogsAccountCodeId ? parseInt(cogsAccountCodeId) : null,
      emptyPlaceHolder: lang.noAccountsAvaiable,
      placeholder: lang.selectAccount,
      accountType: AccountName.CostOfGoodsSold,
      suggested: AccountType.Expense,
      ...cogsAccountCodeId,
    },
    activePos: {
      name: "activePos",
      type: Field.CHECKBOX,
      label: lang.activateOnPos,
      value: true,
      ...activePos,
    },
    activeOnlineMenu: {
      name: "activeOnlineMenu",
      type: Field.CHECKBOX,
      label: lang.activateOnOnlineMenu,
      value: activeOnlineMenu,
      ...activeOnlineMenu,
    },
    location: {
      name: "location",
      label: lang.locations,
      placeholder: lang.chooseAtleast1Location,
      type: Field.ANY,
      selectAllText: lang.allLocations,
      value: [],
      emptyPlaceHolder: lang.noAvailableLocationsYet,
      isAll: false,
      validations: [Validation.requiredMultiDropdown(lang.pleaseSelectLoction)],
      required: true,
      ...location,
    },
    inventoryType: {
      name: "inventoryType",
      type: Field.ANY,
      value: null,
      ...inventoryType,
      required: true,
    },
    generatedSku: {
      name: "generatedSku",
      type: Field.CHECKBOX,
      value: true,
      ...generatedSku,
    },
    sku: {
      name: "sku",
      ...defaultFields,
      required: false,
      maxLength: 16,
      uppercase: true,
      ...sku,
      validations: [Validation.minlength(4), Validation.maxlength(16), ...sku.validations],
    },
    supplyUnit: {
      ...defaultFields,
      value: null,
      ...supplyUnit,
      label: lang.stockUnit,
      name: "supplyUnit",
      placeholder: lang.selectStockUnit,

      required: true,
      validations: [Validation.required()],
    },
    supplyItem: {
      ...defaultFields,
      ...supplyItem,
      name: "supplyItem",
      label: lang.supplyItem,
    },
    supplyQuantity: {
      ...defaultFields,
      ...supplyQuantity,
      name: "supplyQuantity",
      label: lang.populate(lang.stockWeightPerN, ["pc"]),
      required: false,
      right: true,
    },
    sellingQuantity: {
      ...defaultFields,
      ...sellingQuantity,
      label: lang.sellingQty,
      name: "sellingQuantity",
      dirty: false,
      pristine: true,
      value: sellingQuantity?.value ?? "0",
      // required: true,
      validations: [
        Validation.required(),
        Validation.greaterThanZero(lang.sellingQtyMustBeGreaterThanZero),
      ],
      right: true,
    },
    hasVariants: {
      ...defaultFields,
      ...hasVariants,
      name: "hasVariants",
      label: lang.thisProductsHasMultipleVariant,
    },
    hasTax: {
      ...defaultFields,
      ...hasTax,
      name: "hasTax",
      type: Field.CHECKBOX,
    },
    tax: {
      ...defaultFields,
      ...tax,
      name: "tax",
      placeholder: lang.selectTax,
    },
    supplyCost: {
      ...defaultFields,
      value: formatNumberToMoney(0),
      ...supplyCost,
      name: "supplyCost",
      disabled: true,
      label: lang.supplyCost,
      placeholder: "0",
      right: true,
    },
    markUp: {
      ...defaultFields,
      name: "markUp",
      label: lang.markUp,
      right: true,
      value: "0.00",
      disabled: true,
      validations: [Validation.maxValue(99999999.99, null, true)],
      ...markUp,
    },
    retailPrice: {
      ...defaultFields,
      value: formatNumberToMoney(0),
      name: "retailPrice",
      label: lang.retailPrice,
      right: true,
      validations: [
        Validation.maxValue(99999999.99, null, true),
        Validation.minValue(0, lang.retailPriceMustNotBeNegative),
      ],
      ...retailPrice,
    },
    attributeAndOptions: {
      type: Field.ANY,
      isFormArray: true,
      value: [AttributeAndOptionField],
      validations: [validateAttributeAndOptionsArray],
      name: "attributeAndOptions",
      ...attributeAndOptions,
    },
    variants: {
      type: Field.ANY,
      isFormArray: true,
      value: [],
      name: "variants",
      ...variants,
    },
    supplyItems: {
      isFormArray: true,
      type: Field.ANY,
      value: [SupplyItemField],
      name: "supplyItems",
      validations: [validateSupplyItems],
      ...supplyItems,
    },
    attributes: {
      type: Field.ANY,
      value: [],
      name: "attributes",
      ...attributes,
    },
  };
};

const defaultFields = {
  value: "",
  type: Field.INPUT,
  maxLength: 75,
};

export const CreateSupplyItemField = ({ item, quantity, cost }) => {
  return {
    ...SupplyItemField,
    value: {
      ...SupplyItemField.value,
      item: {
        ...SupplyItemField.value.item,
        ...item,
      },
      quantity: {
        ...SupplyItemField.value.quantity,
        ...quantity,
      },
      cost: {
        ...SupplyItemField.value.cost,
        ...cost,
      },
    },
  };
};

export const SupplyItemField = {
  type: Field.ANY,
  isFormGroup: true,
  value: {
    item: {
      ...defaultFields,
      name: "item",
      required: true,
      value: "",
      placeholder: lang.selectSupplyItem,
      type: Field.DROPDOWN,
      pristine: true,
      error: false,
      validations: [Validation.required()],
    },
    quantity: {
      ...defaultFields,
      name: "quantity",
      right: true,
      validations: [Validation.greaterThanZero(lang.sellingQtyMustBeGreaterThanZero)],
      pristine: true,
      dirty: false,
    },
    cost: {
      ...defaultFields,
      name: "cost",
      disabled: true,
      right: true,
      pristine: true,
    },
  },
};

const validateSupplyItems = (field, { parent }) => {
  let error = false;
  // if (!parent.hasVariants.value) {
  if (field.value?.length) {
    field.value.forEach((f) => {
      if (!f.value.item.value) {
        error = true;
      }
    });
  }

  // }
  const isError = parent?.inventoryType?.value === InventoryType.WholeProduct ? false : error;
  return {
    error: isError,
    message: "",
  };
};

const validateAttributeAndOptionsArray = (field, { form }) => {
  let error = false;
  field.value.forEach((f) => {
    if (f.error || !f.value.attribute.value.trim()) {
      error = true;
    }
  });
  return {
    error: !form.hasVariants.hidden && form.hasVariants.value ? error : false,
    message: "",
  };
};

const validateAttributes = (field, { array, index }) => {
  const values = array.value
    .filter((v, valueIndex) => {
      return index !== valueIndex;
    })
    .map((value) => {
      return value?.value?.attribute?.value || "";
    });

  const value = field.value.trim().toLowerCase();
  const error = value ? values.includes(value) : false;
  return {
    error: error,
    message: error ? lang.attributeAlreadyExists : "",
  };
};

const validateOptionInput = (field, { array }) => {
  const options = [];
  array.value.forEach((value) => {
    return value?.value?.options?.value.forEach(({ text }) => {
      options.push(text.trim().toLowerCase());
    });
  });
  const error = options.includes(field.value.trim().toLowerCase());
  return {
    error: error,
    message: error ? lang.optionNameAlreadyExists : "",
  };
};

const validateAttributeAndOptions = (field, { form }) => {
  const { value } = field;
  const { attribute, options } = value;
  const isValid =
    form.hasVariants.value && !form.hasVariants.hidden
      ? attribute.value.trim() && options.value.length
      : true;
  return {
    error: !isValid,
    message: "",
  };
};

export const AttributeAndOptionField = {
  name: "attributeAndOptionFieldItem",
  type: Field.ANY,
  isFormGroup: true,
  validations: [validateAttributeAndOptions],
  value: {
    attribute: {
      value: "",
      type: Field.INPUT,
      placeholder: lang.egSizeFlavor,
      validations: [validateAttributes],
      name: "attribute",
      maxLength: 75,
    },
    options: {
      value: [],
      type: Field.ANY,
      placeholder: lang.enterEachOption,
      name: "options",
      validations: [Validation.requiredArray],
    },
    input: {
      value: "",
      type: Field.INPUT,
      name: "input",
      validations: [validateOptionInput],
      maxLength: 75,
    },
  },
};

export const validateSkus = (field, { array, index }) => {
  const { value, invalid } = field;
  const skus = array.value
    .filter((v, variantIndex) => {
      return index !== variantIndex;
    })
    .map((variant) => {
      const v = variant.value.sku.value.trim();
      return v;
    });
  const sku = value.trim();
  const error = sku ? skus.includes(sku) : false;

  return {
    error: error || invalid,
    message: error || !invalid ? lang.alreadyExists : "",
    // validated: !error && sku ? true : false,
  };
};

export const VariantField = {
  type: Field.ANY,
  isFormGroup: true,
  validations: [
    (field) => {
      let error = false;
      Object.keys(field.value).forEach((key) => {
        if (field.value[key].error && !field.value[key].disabled) {
          error = true;
        }
      });
      return {
        error,
        message: "",
      };
    },
  ],
  value: {
    active: {
      type: Field.ANY,
      value: false,
    },
    productSkuId: {
      type: Field.ANY,
      value: null,
    },
    status: {
      name: "status",
      type: Field.ANY,
      label: lang.activateOnPos,
      value: "ACTIVE",
    },
    logo: {
      name: "logo",
      image: undefined,
      file: "",
      type: Field.ANY,
    },
    description: {
      ...defaultFields,
      name: "description",
    },
    sku: {
      ...defaultFields,
      name: "sku",
      label: lang.sku,
      validations: [Validation.minlength(4), Validation.maxlength(16), validateSkus],
      maxLength: 16,
      uppercase: true,
    },
    supplyCost: {
      ...defaultFields,
      value: formatNumberToMoney(0),
      name: "supplyCost",
      label: lang.supplyCost,
      right: true,
      disabled: true,
    },
    markUp: {
      ...defaultFields,
      name: "markUp",
      label: lang.markUp,
      right: true,
      value: 0,
    },
    retailPrice: {
      ...defaultFields,
      value: formatNumberToMoney(0),
      name: "retailPrice",
      label: lang.retailPrice,
      right: true,
    },
    attribute: {
      value: [],
      type: Field.ANY,
    },
    key: {
      value: "",
      type: Field.ANY,
    },
    supplyUnit: {
      ...defaultFields,
      label: lang.stockUnit,
      name: "supplyUnit",
      placeholder: lang.selectStockUnit,
      value: null,
      required: true,
      validations: [Validation.required()],
    },
    supplyQuantity: {
      ...defaultFields,
      name: "supplyQuantity",
      label: lang.populate(lang.stockWeightPerN, ["pc"]),
      required: false,
      right: true,
    },
    sellingQuantity: {
      ...defaultFields,
      label: lang.sellingQty,
      name: "sellingQuantity",
      // required: true,
      dirty: false,
      pristine: true,
      validations: [
        Validation.required(),
        Validation.greaterThanZero(lang.sellingQtyMustBeGreaterThanZero),
      ],
      right: true,
    },
    supplyItems: {
      isFormArray: true,
      type: Field.ANY,
      value: [SupplyItemField],
      name: "supplyItems",
      validations: [validateSupplyItems],
    },
    added: {
      type: Field.ANY,
      value: false,
    },
    compositions: {
      value: [],
    },
  },
};

export const CreateVariantForm = (obj) => {
  const value = {};
  Object.keys(VariantField.value).forEach((key) => {
    if (obj[key]) {
      value[key] = {
        ...VariantField.value[key],
        ...obj[key],
      };
    }
  });
  return {
    ...VariantField,
    value: {
      ...VariantField.value,
      ...value,
    },
  };
};

export default initialFormState;
