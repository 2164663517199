import {
  HeaderA,
  ModuleWrapper,
  DataTable,
  ButtonLink,
  Text,
  Pill,
  AlertMessage,
} from "components";
import { AlertType, EndOfDayReportStatus, PillType } from "enums";
import React, { useCallback, useMemo, useContext } from "react";
import { mapObject, redirectTo } from "services";
import lang from "translations";
import columns from "./columns";
import {
  useApi,
  useFilter,
  useModal,
  useMount,
  // useRouter
} from "hooks";
import { VenueContext } from "contexts";
import { getMewsIntegrationStatus, searchEndOfDayReports } from "apis";
import RunEndOfDayModal from "../run-end-of-day-modal/run-end-of-day-modal";
import { endOfDayListFilterRequest, endOfDayListResponse } from "mappers";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";
import { Link } from "react-router-dom";

const EndOfDayReportList = () => {
  const { venue } = useContext(VenueContext);
  // const { history } = useRouter();
  const runEndOfDayModal = useModal();
  const {
    request: searchEndOfDayRequest,
    loading,
    mappedData,
    error,
    result: searchEodResult = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: searchEndOfDayReports,
    handleOwnError: true,
    isArray: true,
    mapper: endOfDayListResponse,
  });

  const { request: mewsRequest, result } = useApi({
    api: getMewsIntegrationStatus,
    params: {
      venueId: venue?.venueId,
    },
    handleOwnError: true,
  });

  const { modifyFilters, filterState, requestState } = useFilter({
    venueId: venue.venueId,
    page: 1,
    pageSize: 10,
  });

  const fetchEndOfDayReports = useCallback(
    (requestState) => {
      searchEndOfDayRequest(mapObject(requestState, endOfDayListFilterRequest));
    },
    [searchEndOfDayRequest]
  );

  useMount(() => {
    mewsRequest();
    fetchEndOfDayReports(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.endOfDayReportsListPage,
    });
  });

  const isMewsAccountingIntegrated = Boolean(
    result?.data
      ?.flatMap((dt) => dt.integrationType)
      .join(",")
      ?.includes("MEWS_ACCOUNTING" || "MEWS_BOOKINGS")
  );

  // const alertMessage = useMemo(() => {
  //   const learnMoreLink =  <ButtonLink>{lang.learnMore}</ButtonLink>
  //   if (isMewsAccountingIntegrated) {
  //     return (
  //         // <Text>{[lang.forProperReportingWithYourMews, learnMoreLink]}</Text>
  //         `${lang.forProperReportingWithYourMews} ${<a></a>}`

  //     );
  //   } else {
  //     return <Text>{lang.forProperReportingAndTo}</Text>;
  //   }
  // }, [isMewsAccountingIntegrated]);

  const changePageConfigCb = useCallback(
    (pageProps) => {
      return modifyFilters(pageProps);
    },
    [modifyFilters]
  );

  const prepareEndOfDayReports = useCallback(() => {
    return mappedData.map((report) => {
      const {
        id,
        businessDay,
        dateStarted,
        timeStarted,
        dateEnded,
        timeEnded,
        shifts,
        totalTransactions,
        totalSales,
        status,
      } = report;

      return {
        id: (
          <ButtonLink
            // onClick={() => {
            //   history.push(Path.END_OF_DAY_REPORT_ID(id));
            // }}
            path={Path.END_OF_DAY_REPORT_ID(id)}
          >
            {id}
          </ButtonLink>
        ),
        businessDay: businessDay,
        timeStarted: (
          <div>
            <Text>{dateStarted}</Text>
            <Text>{timeStarted}</Text>
          </div>
        ),
        timeEnded:
          status === EndOfDayReportStatus.CLOSED ? (
            <div>
              <Text>{dateEnded}</Text>
              <Text>{timeEnded}</Text>
            </div>
          ) : (
            <Pill type={PillType.Green}>{lang.open}</Pill>
          ),
        shifts: <Text>{shifts}</Text>,
        transactions: <Text>{totalTransactions}</Text>,
        totalSales: <Text>{totalSales}</Text>,
      };
    });
  }, [mappedData]);

  const reports = useMemo(() => {
    return prepareEndOfDayReports();
  }, [prepareEndOfDayReports]);

  const getOpenEod = useCallback(() => {
    return mappedData.find((shift) => shift.status === EndOfDayReportStatus.OPEN)?.id;
  }, [mappedData]);

  return (
    <ModuleWrapper
      header={
        <HeaderA
          title={lang.endOfDayReports}
          description={lang.reviewYourDailyVenueActivity}
          className="mb-md"
          button={{
            text: lang.runEndOfDay,
            disabled: mappedData?.length < 1 || !getOpenEod(),
            onClick: () => {
              runEndOfDayModal.show({
                title: lang.endOfDay,
                id: getOpenEod(),
                close: () => {
                  runEndOfDayModal.close();
                  fetchEndOfDayReports(requestState);
                },
              });
            },
          }}
        />
      }
    >
      <AlertMessage
        type={AlertType.Info}
        message={
          isMewsAccountingIntegrated ? (
            <>
              {lang.forProperReportingWithYourMews}{" "}
              <Link
                className="cursor-pointer inline-block text-pelorous text-sm"
                onClick={() => {
                  redirectTo(
                    "https://help.mews.com/s/article/set-up-your-editable-history-windows?language=en_US#:~:text=%F0%9F%92%B8%20Accounting%20Editable%20History%20Window&text=Go%20to%20Main%20menu%20%3E%20Settings,Click%20Save%20."
                  );
                }}
              >
                {lang.learnMore}
              </Link>
            </>
          ) : (
            lang.forProperReportingAndTo
          )
        }
        showIcon={true}
        icon="info"
        className="mb-lg"
      />
      <DataTable
        total={searchEodResult.metadata.total}
        columns={columns}
        onChangePage={changePageConfigCb}
        data={reports}
        loading={loading}
        page={filterState.page}
        pageSize={filterState.pageSize}
        fetchList={fetchEndOfDayReports}
        error={error}
      />
      <RunEndOfDayModal
        {...runEndOfDayModal}
        refreshList={() => fetchEndOfDayReports(requestState)}
      />
    </ModuleWrapper>
  );
};

export default EndOfDayReportList;
