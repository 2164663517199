import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Text } from "components/commons";
import styles from "./tab-bar.module.scss";

const TabBar = ({ className, items }) => {
  return (
    <div className={classnames("flex items-baseline", className)}>
      {items.map((item, i) => {
        return (
          <NavLink
            key={i}
            activeClassName={classnames(styles.active)}
            className={classnames("mr-lg py-sm", styles.navLink)}
            to={item.to}
            exact
          >
            <Text size="text-md" color="text-inherit" strong>
              {item.text}
            </Text>
          </NavLink>
        );
      })}
    </div>
  );
};

export default TabBar;
