import enumeration from "./enumeration";

const StaffStatus = {
  Awaiting: "AWAITING",
  Inactive: "INACTIVE",
  Active: "ACTIVE",
  Deleted: "DELETED",
};

export default enumeration(StaffStatus);
