import {
  ActionButton,
  AlertMessage,
  ButtonLink,
  Field,
  Input,
  Modal,
  Text,
  Toast,
  Image,
} from "components/commons";
import React, { useCallback, useContext, useMemo, useState } from "react";
import lang from "translations";
import { AlertType, Field as FieldType, IntegrationType } from "enums";
import { useApi, useForm } from "hooks";
import { activateMews, editTokenMews } from "apis/integration.api";
import { VenueContext } from "contexts";
import { openInNewTab } from "services/url.service";
import classNames from "classnames";
import styles from "./connect-modal.module.scss";
import { activateCloudBeds, syncCloudBeds, activatePurchasePlus, deactivateMews } from "apis";
import { PurchasePlusLogo } from "images";
import Validation from "services/validation.service";

const ConnectModal = ({
  title = "",
  instruction,
  guideLink,
  modifyField: modifyConnection,
  onEdit = false,
  accessToken,
  hostname,
  username,
  password,
  directory,
  request,
  integrationId,
  integrationType,
  disableAccessToken,
  ...props
}) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue || {};

  const [isInvalid, setIsInvalid] = useState(false);
  const [sftpAuthFail, setSftpAuthFail] = useState(false);
  const [sftpNoFile, setSftpNoFile] = useState(false);

  const initialState = useMemo(() => {
    return {
      accessToken: {
        name: "accessToken",
        value: accessToken || null,
        type: FieldType.INPUT,
        label: lang.accessToken,
        // validations: [Validation.required()],
        placeholder: "667CCCE510874DB5A74EAD810067A286-E06E376C2277323CDEC2AC1533C58F6",
      },
      hostname: {
        name: "hostname",
        value: hostname || "",
        type: FieldType.INPUT,
        label: lang.hostname,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      },
      username: {
        name: "username",
        value: username || "",
        type: FieldType.INPUT,
        label: lang.username,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      },
      password: {
        name: "password",
        value: password || "",
        type: FieldType.INPUT,
        inputType: "password",
        label: lang.password,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      },
      directory: {
        name: "directory",
        value: directory || "",
        type: FieldType.INPUT,
        label: lang.directory,
        validations: title === lang.purchaseplus ? [Validation.required()] : [],
      },
    };
  }, [accessToken, directory, hostname, password, username, title]);

  const { fields, modifyField, submitForm, applyFieldErrors } = useForm({
    initialState,
  });

  const activateMewsRequest = useApi({
    api: activateMews,
    params: {
      businessId: venueId,
      integrationType: integrationType,
    },
  });

  const activateCloudBedsRequest = useApi({
    api: activateCloudBeds,
    params: {
      venueId,
    },
  });

  const activatePurchasePlusRequest = useApi({
    api: activatePurchasePlus,
    params: {
      venueId,
      directory,
      hostname,
      username,
      password,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const syncCloudBedsRequest = useApi({
    api: syncCloudBeds,
    params: {
      businessId: venueId,
      enterpriseId: 19423,
    },
  });

  const editTokenMewsRequest = useApi({
    api: editTokenMews,
    params: {
      businessId: venueId,
      integrationType: integrationType,
    },
  });

  const requestDeactivateMews = useApi({
    api: deactivateMews,
    params: { id: integrationId, integrationType, venueId },
  });

  const submitFormValue = useCallback(
    async (params) => {
      try {
        let res = {};
        if (
          integrationType === IntegrationType.MEWS ||
          integrationType === IntegrationType.MEWS_BOOKINGS ||
          integrationType === IntegrationType.MEWS_ACCOUNTING
        ) {
          const r = onEdit ? editTokenMewsRequest : activateMewsRequest;
          res = await r.request({
            token: params.accessToken.value,
          });
        } else if (integrationType === IntegrationType.CLOUDBEDS) {
          res = await activateCloudBedsRequest.request({
            token: params.accessToken.value,
          });
          await syncCloudBedsRequest.request({
            enterpriseId: 19423,
            venueId: venueId,
          });
        } else if (integrationType === IntegrationType.PURCHASE_PLUS) {
          try {
            setSftpAuthFail(false);
            setSftpNoFile(false);
            if (onEdit) {
              res = await activatePurchasePlusRequest.request({
                directory: params.directory.value,
                hostname: params.hostname.value,
                username: params.username.value,
                password: params.password.value,
              });
              if (
                params.hostname.value ||
                params.username.value ||
                params.password.value ||
                params.directory.value
              ) {
                res = await requestDeactivateMews.request({
                  id: integrationId,
                  integrationType: IntegrationType.PURCHASE_PLUS,
                  venueId: venueId,
                });
                res = await activatePurchasePlusRequest.request({
                  directory: params.directory.value,
                  hostname: params.hostname.value,
                  username: params.username.value,
                  password: params.password.value,
                });
              } else {
                res = await activatePurchasePlusRequest.request({
                  directory: params.directory.value,
                  hostname: params.hostname.value,
                  username: params.username.value,
                  password: params.password.value,
                });
              }
            } else {
              res = await activatePurchasePlusRequest.request({
                directory: params.directory.value,
                hostname: params.hostname.value,
                username: params.username.value,
                password: params.password.value,
              });
            }
          } catch ({ code, handleError }) {
            setIsInvalid(true);
            console.log(code);
            const err = {
              3109: () => {
                setSftpAuthFail(true);
              },
              3110: () => {
                setSftpNoFile(true);
              },
            };
            if (err[code]) {
              err[code]();
            } else {
              handleError();
            }
          }
        }
        if (res.data) {
          modifyConnection("isConnected", { value: true });
          props.close();
          Toast({
            content: lang.populate(lang.xConnectedSuccessfully, [title]),
            success: true,
            icon: "check",
          }).open();
          if (request) {
            request.request();
          }
        } else {
          console.log(res);
          applyFieldErrors({
            accessToken: lang.xConnectedFailed,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [
      modifyConnection,
      props,
      title,
      activateMewsRequest,
      venueId,
      applyFieldErrors,
      request,
      onEdit,
      editTokenMewsRequest,
      activateCloudBedsRequest,
      syncCloudBedsRequest,
      activatePurchasePlusRequest,
      integrationType,
      integrationId,
      requestDeactivateMews,
    ]
  );

  const headerWithLogo = useMemo(() => {
    return onEdit ? (
      <Text className="font-semibold text-lg ml-2">{lang.editSFTPDetails}</Text>
    ) : (
      <div>
        <div className="flex items-center justify-start">
          <div className="pr-2">
            <Image className="w-20" src={PurchasePlusLogo} />
          </div>
          <div>
            <Text className="font-semibold text-lg">{lang.purchaseplus}</Text>
            <Text color="text-gray" className="text-xs leading-4">
              {lang.inventoryManagement}
            </Text>
          </div>
        </div>
      </div>
    );
  }, [onEdit]);

  const header = useMemo(() => {
    return onEdit ? (
      <Text className="font-semibold text-lg">{lang.editAccessToken}</Text>
    ) : (
      <Text className="font-semibold text-lg">{`${title} Integration`}</Text>
    );
  }, [onEdit, title]);

  return (
    <Modal
      {...props}
      close={() => {
        setIsInvalid(false);
        setSftpAuthFail(false);
        setSftpNoFile(false);
        props.close();
      }}
      customTitle={integrationType === IntegrationType.PURCHASE_PLUS ? headerWithLogo : header}
    >
      <div className="p-sm pt-0">
        {integrationType === IntegrationType.PURCHASE_PLUS ? (
          <>
            {!onEdit && (
              <div>
                <div className="mb-2">
                  <Text className="font-semibold text-md leading-6">
                    {lang.connectPurchasePlus}
                  </Text>
                </div>
                <div className="pb-4">
                  <Text color="text-gray" className="text-base leading-6 font-semibold">
                    {lang.pleaseEnterYourSFTP}
                  </Text>
                </div>
              </div>
            )}

            {isInvalid && (
              <AlertMessage
                type={AlertType.Error}
                message={lang.credentialsAreInvalid}
                showIcon={true}
                icon="info"
                className="mb-lg"
              />
            )}

            <Field {...fields.hostname}>
              <Input
                {...fields.hostname}
                onChange={modifyField}
                className={sftpAuthFail ? "border-red" : "border-black"}
              />
            </Field>
            <Field {...fields.username}>
              <Input
                {...fields.username}
                onChange={modifyField}
                className={sftpAuthFail ? "border-red" : "border-black"}
              />
            </Field>
            <Field {...fields.password}>
              <Input
                {...fields.password}
                onChange={modifyField}
                className={sftpAuthFail ? "border-red" : "border-black"}
              />
            </Field>
            <Field {...fields.directory}>
              <Input
                {...fields.directory}
                onChange={modifyField}
                className={sftpNoFile ? "border-red" : "border-black"}
              />
            </Field>
          </>
        ) : (
          <Field {...fields.accessToken}>
            <Input
              {...fields.accessToken}
              disabled={activateMewsRequest.loading || disableAccessToken}
              onChange={modifyField}
            />
          </Field>
        )}
        {integrationType === IntegrationType.PURCHASE_PLUS ? null : (
          <>
            {instruction}
            <ButtonLink
              className={classNames("mt-sm underline text-pelorous", styles.button)}
              onClick={() => {
                openInNewTab(guideLink);
              }}
            >
              {lang.checkOutTheGuide}
            </ButtonLink>
          </>
        )}

        <ActionButton
          className="py-md"
          loading={
            activateMewsRequest.loading ||
            editTokenMewsRequest.loading ||
            activatePurchasePlusRequest.loading
          }
          secondary={{
            text: lang.cancel,
            onClick: () => {
              setIsInvalid(false);
              setSftpAuthFail(false);
              setSftpNoFile(false);
              props.close();
            },
          }}
          primary={{
            text: onEdit ? lang.reconnect : lang.connectAndInstall,
            onClick: () => {
              submitForm(submitFormValue);
            },
            disabled: title !== lang.purchaseplus ? !fields.accessToken.value : false,
          }}
        />
      </div>
    </Modal>
  );
};

export default ConnectModal;
