import lang from "translations";

const columns = [
  {
    key: "staff",
    text: lang.staff,
    custom: true,
  },
  {
    key: "tagId",
    text: lang.tagId,
    custom: true,
    width: "w-48",
  },
  {
    key: "role",
    text: lang.role,
    custom: true,
    width: "w-72",
  },
  {
    key: "location",
    text: lang.location,
    custom: true,
    width: "w-64",
  },
];

export default columns;
