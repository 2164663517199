import { formatNumberToMoney, parseMoneyToNumber } from "services/money.service";

export const creditLimitRequest = {
  postPaidBillingLimit: {
    transform: ({ src }) => {
      if (src.hasCreditLimit) {
        return Number(parseMoneyToNumber(src.creditLimit).value) || null;
      }
      return null;
    },
  },
  topupCreditLimit: {
    transform: () => {
      return null;
    },
  },
  creditExpirationType: {
    key: "creditExpirationType",
  },
  creditExpirationCount: {
    key: "creditExpirationCount",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.creditExpirationCount;
    },
  },
  creditExpirationSpan: {
    key: "creditExpirationSpan",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.creditExpirationSpan;
    },
  },
  creditResetExpiration: {
    key: "creditResetExpiration",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.creditResetExpiration;
    },
  },
  isCreditResetExpirationActive: {
    key: "isCreditResetExpirationActive",
    transform: ({ src }) => {
      if (src.creditExpirationType === "NEVER_EXPIRES_AFTER_CHECKOUT") {
        return null;
      }
      return src.isCreditResetExpirationActive;
    },
  },
  venueId: {
    key: "venueId",
  },
};

export const venueResponse = {
  venueId: {
    key: "venueId",
  },
  postPaidBillingLimit: {
    key: "postPaidBillingLimit",
  },
  postPaidBillingLimitMoney: {
    transform: ({ src }) => {
      return formatNumberToMoney(src.postPaidBillingLimit);
    },
  },
  venueName: {
    key: "venueName",
  },
  currencyCode: {
    key: "currencyCode",
  },
  currencySymbol: {
    key: "currencySymbol",
  },
  keepWristbandAllowed: {
    key: "keepWristbandAllowed",
  },
};

export const venueRequest = {
  venueId: {
    key: "venueId",
  },
  venueName: {
    key: "name",
  },
  currencyCode: {
    key: "currency",
  },
  demo: {
    key: "demo",
  },
  businessName: {
    key: "businessName",
  },
  businessAddress: {
    key: "businessAddress",
  },
  businessContactNumber: {
    key: "businessContactNumber",
  },
  businessTaxNumber: {
    key: "businessTaxNumber",
  },
  countryName: {
    key: "countryName",
  },
  logoLink: {
    key: "logoLink",
  },
  denominations: {
    key: "denominations"
  }
};

export const checklistRequest = {
  addedProduct: {
    key: "addedProduct",
  },
  addedSupplyItem: {
    key: "addedSupplyItem",
  },
  invitedStaff: {
    key: "invitedStaff",
  },
  loggedInApp: {
    key: "loggedInApp",
  },
};
