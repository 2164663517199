import { StocktakeStatus } from "enums";
import lang from "translations";
import React, { useMemo } from "react";
import { Panel, Skeleton, Text } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import classNames from "classnames";

const StocktakeSummary = ({ stocktakeData = {}, loading }) => {
  const {
    locationName,
    startedOn,
    lastModifiedOn,
    lastModifiedBy,
    completedOn,
    completedBy,
    totalAmount,
    voidedOn,
    voidedBy,
    status,
  } = stocktakeData;

  const labelText = (label, text) => {
    return (
      <>
        <Text color="text-gray" size="text-sm md:text-base" fontWeight="font-semibold">
          {label}
        </Text>
        <Text size="text-sm md:text-base" className="mt-xxs mb-md md:mt-sm md:mb-0">
          {text}
        </Text>
      </>
    );
  };

  const renderSummaryInProgress = useMemo(() => {
    return (
      <div
        className={
          lastModifiedOn && lastModifiedBy
            ? "block md:grid grid-cols-12 gap-4"
            : "block md:grid grid-cols-6 gap-4"
        }
      >
        <div className="grid col-span-3">{labelText(lang.location, locationName)}</div>
        <div className="grid col-span-3">{labelText(lang.startedOn, startedOn)}</div>
        {lastModifiedOn && lastModifiedBy && (
          <div className="grid col-span-3">{labelText(lang.lastModifiedOn, lastModifiedOn)}</div>
        )}
        {lastModifiedBy && (
          <div className="grid col-span-3">{labelText(lang.lastModifiedBy, lastModifiedBy)}</div>
        )}
      </div>
    );
  }, [locationName, startedOn, lastModifiedOn, lastModifiedBy]);

  const renderSummaryForApproval = useMemo(() => {
    return (
      <div className="block md:grid grid-cols-12 gap-4">
        <div className="grid col-span-3">{labelText(lang.location, locationName)}</div>
        <div className="grid col-span-3">{labelText(lang.startedOn, startedOn)}</div>
        <div className="grid col-span-3">{labelText(lang.lastModifiedOn, lastModifiedOn)}</div>
        <div className="grid col-span-3">{labelText(lang.lastModifiedBy, lastModifiedBy)}</div>
      </div>
    );
  }, [locationName, startedOn, lastModifiedOn, lastModifiedBy]);

  const renderSummaryCompleted = useMemo(() => {
    return (
      <div className="block md:grid grid-cols-10 gap-4">
        <div className="grid col-span-2">{labelText(lang.location, locationName)}</div>
        <div className="grid col-span-2">{labelText(lang.startedOn, startedOn)}</div>
        <div className="grid col-span-2">{labelText(lang.completedOn, completedOn)}</div>
        <div className="grid col-span-2">{labelText(lang.completedBy, completedBy)}</div>
        <div className="grid col-span-2">
          <Text color="text-gray" size="text-base" fontWeight="font-semibold">
            {lang.totalAmount}
          </Text>
          <Text
            size="text-sm md:text-base"
            className={classNames("mt-xxs md:mt-sm ", {
              "text-pelorous": totalAmount > 0,
              "text-red": totalAmount < 0,
              "text-black": totalAmount === 0,
            })}
          >
            {totalAmount ? formatNumberToMoneyWithCurrencySymbol(totalAmount) : "-"}
          </Text>
        </div>
      </div>
    );
  }, [locationName, startedOn, completedOn, completedBy, totalAmount]);

  const renderSummaryVoided = useMemo(() => {
    return (
      <div className="block md:grid grid-cols-10 gap-4">
        <div className="grid col-span-2">{labelText(lang.location, locationName)}</div>
        <div className="grid col-span-2">{labelText(lang.startedOn, startedOn)}</div>
        <div className="grid col-span-2">{labelText(lang.voidedOn, voidedOn)}</div>
        <div className="grid col-span-2">{labelText(lang.voidedBy, voidedBy)}</div>
        <div className="grid col-span-2">
          <Text color="text-gray" size="text-sm md:text-base" fontWeight="font-semibold">
            {lang.totalAmount}
          </Text>
          <Text
            size="text-md"
            className={classNames("mt-xxs md:mt-sm", {
              "text-pelorous": totalAmount > 0,
              "text-red": totalAmount < 0,
              "text-black": totalAmount === 0,
            })}
          >
            {totalAmount ? formatNumberToMoneyWithCurrencySymbol(totalAmount) : "-"}
          </Text>
        </div>
      </div>
    );
  }, [locationName, startedOn, voidedOn, voidedBy, totalAmount]);

  const renderSummary = () => {
    switch (status) {
      case StocktakeStatus.InProgress:
        return renderSummaryInProgress;
      case StocktakeStatus.ForApproval:
        return renderSummaryForApproval;
      case StocktakeStatus.Completed:
        return renderSummaryCompleted;
      case StocktakeStatus.Void:
        return renderSummaryVoided;
      default:
        return null;
    }
    // eslint-disable-next-line
  };

  return <Panel>{!loading ? renderSummary(status) : <Skeleton width="100%" active />}</Panel>;
};

export default StocktakeSummary;
