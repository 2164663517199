import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { BookingList } from "modules";
import { useApi, useMount } from "hooks";
import { bookingCheck } from "apis/integration.api";
import BookingDetailsPage from "./booking-details.page";
import { VenueContext } from "contexts";
import BookingNoIntegrationState from "modules/booking/booking-list/booking-no-integration-state.module";
import { ModuleWrapper } from "components/fragments";
import { HeaderA } from "components/headers";
import lang from "translations";
import { useFlags } from "launchdarkly-react-client-sdk";

const BookingPage = () => {
  const { venue } = useContext(VenueContext);
  const { bookingPage } = useFlags();

  const requestBookingCheck = useApi({
    api: bookingCheck,
    params: { venueId: venue.venueId },
  });

  useMount(() => {
    requestBookingCheck.request();
  });

  const { data } = requestBookingCheck?.result || {};

  if (!requestBookingCheck.loading && !data) {
    return (
      <ModuleWrapper
        header={<HeaderA title={lang.bookingList} description={lang.bookingDescription} />}
      >
        <BookingNoIntegrationState />
      </ModuleWrapper>
    );
  }

  return (
    bookingPage && (
      <Switch>
        <Route exact path={Path.BOOKING} component={BookingList} />
        <Route path={Path.BOOKING_VIEW} component={BookingDetailsPage} />
      </Switch>
    )
  );
};

export default BookingPage;
