import React from "react";
import { PageError } from "components/commons";
import { Skeleton } from "antd";

export const Fragment = ({ children, error, loading }) => {
  if (loading) {
    return <Skeleton width="100%" active={true} />;
  }
  if (error) {
    return <PageError />;
  }

  return children;
};

export default Fragment;
