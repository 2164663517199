import { SearchOutlined } from "@ant-design/icons";
import { searchBanTagsForBanning, createBanTag } from "apis/tag.api";
import classnames from "classnames";
import {
  ActionButton,
  Button,
  Field,
  Form,
  Input,
  InputCounter,
  Modal,
  Select,
  Title,
  Toast,
  InfoPanel,
} from "components/commons";
import { VenueContext } from "contexts";
import { StyleType } from "enums";
import { useApi, useForm } from "hooks";
import React, { useCallback, useContext, useMemo, useState } from "react";
import lang from "translations";
import initialFormState from "./ban-tag-state";
import { mixpanel, TrackEvent } from "mixpanel";

const BanTagModal = ({ active, close, ok, initialState = undefined, onBannedTag, ...props }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const [searchedKey, setSearchedKey] = useState("");
  const [banClicked, setBanClicked] = useState(false);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    clearForm,
    applyFieldErrors,
    validateForm,
  } = useForm({
    initialState: formState,
  });

  const { request, loading, mappedData } = useApi({
    api: searchBanTagsForBanning,
    mapper: {
      _keys: ["guestTag", "bannedTag"],
    },
    params: {
      searchKey: searchedKey.toString().trim().toUpperCase(),
      venueId,
    },
  });

  const { request: banTagRequest, loading: banTagLoading } = useApi({
    api: createBanTag,
    params: {
      searchKey: searchedKey.toString().trim().toUpperCase(),
      venueId,
    },
  });

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    try {
      await request({ ...params, venueId });
    } catch ({ code, handleError }) {
      handleError();
    }
  }, [request, getFormValues, venueId]);

  const handleSearch = useCallback(() => {
    mixpanel.track(TrackEvent.ClickedButton, {
      button: lang.searchTag,
    });

    if (!loading && fields.searchKey.value !== "" && fields.searchKey.value) {
      setSearchedKey(fields.searchKey.value);
      submitForm(handleSubmit);
    }
  }, [handleSubmit, setSearchedKey, fields.searchKey, submitForm, loading]);

  const handleSearchChange = useCallback(
    (name, val) => {
      if (val && val.value) {
        let value = val.value.replace(/\W/g, "").toUpperCase();
        modifyField(name, { value: value });
      } else {
        modifyField(name, { value: null });
      }
    },
    [modifyField]
  );

  const handleBanClicked = useCallback(() => {
    const value = { value: searchedKey.toString().trim().toUpperCase() };
    modifyField("searchKey", value);
    setBanClicked(true);
  }, [setBanClicked, modifyField, searchedKey]);

  const handleBanTagClicked = useCallback(async () => {
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        button: lang.banTag,
      });
      const params = getFormValues();
      validateForm();
      if (!params.banType) {
        applyFieldErrors({
          banType: lang.thisFieldIsRequired,
        });
        return;
      }
      if (params.banType === "OTHERS" && !params.banReason) {
        applyFieldErrors({
          banReason: lang.thisFieldIsRequired,
        });
        return;
      }
      params.tagUid = searchedKey.toString().trim().toUpperCase();
      await banTagRequest({ ...params, venueId });
      Toast({
        content: lang.tagBanned,
        success: true,
        icon: "check",
      }).open();
      setBanClicked(false);
      setSearchedKey("");
      clearForm();

      if (onBannedTag) {
        onBannedTag();
      }
    } catch ({ code, handleError }) {
      const err = {
        3094: () => {
          setBanClicked(false);

          applyFieldErrors({
            searchKey: lang.thisTagIdIsAlreadyBanned,
          });
        },
      };

      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [
    banTagRequest,
    getFormValues,
    venueId,
    applyFieldErrors,
    onBannedTag,
    validateForm,
    clearForm,
    searchedKey,
  ]);

  return (
    <Modal
      {...props}
      noHeader
      active={active}
      actionContent={
        <ActionButton
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              mixpanel.track(TrackEvent.ClickedButton, {
                button: lang.cancelBanTag,
              });

              if (banClicked) {
                setBanClicked(false);
              } else {
                setSearchedKey("");
                clearForm();
                close();
              }
            },
          }}
          primary={
            banClicked
              ? {
                  loading: banTagLoading,
                  disabled: banTagLoading,
                  type: StyleType.Danger,
                  text: lang.banTag,
                  onClick: () => {
                    handleBanTagClicked();
                  },
                }
              : false
          }
        />
      }
    >
      {banClicked ? (
        <Title lg className="mb-sm pt-md">
          {`Ban "${searchedKey}"?`}
        </Title>
      ) : (
        <Title lg className="mb-sm pt-md">
          {lang.banTag}
        </Title>
      )}

      {!banClicked ? (
        <Form onSubmit={handleSearch}>
          <p className="text-xs text-gray-500 m-0 font-semibold">{lang.tagId}</p>

          <Field {...fields.searchKey}>
            <div className="flex">
              <Input
                required
                {...fields.searchKey}
                className={classnames("rounded-r-none mt-xs border-r-0")}
                placeholder="e.g. 1234 5678 9012 3456"
                iconPrefix={<SearchOutlined className="mr-sm" />}
                onChange={handleSearchChange}
              />

              <Button
                loading={loading}
                disabled={loading || fields.searchKey.value === "" || !fields.searchKey.value}
                type={StyleType.Secondary}
                className={classnames(
                  "rounded-l-none bg-gradient-to-b from-white to-white-dark border-blue-lightest mt-xs"
                )}
                onClick={handleSearch}
              >
                {lang.search}
              </Button>
            </div>
          </Field>

          {searchedKey && !loading && mappedData && (
            <div>
              {mappedData.bannedTag ? (
                <div className="py-sm">
                  <p className="text-sm text-red">{lang.thisTagIdIsAlreadyBanned}</p>
                </div>
              ) : mappedData.guestTag ? (
                <div className="bg-blue-opaque px-md py-md flex rounded mt-sm justify-between">
                  <div>
                    <p className="text-sm">
                      <span className="text-sm font-semibold">{searchedKey}</span>{" "}
                    </p>
                    <p className="text-sm font-normal">
                      {mappedData.guestTag.useCount} {lang.usesLastIssuedTo}{" "}
                      {mappedData.guestTag.guest.firstName} {mappedData.guestTag.guest.lastName}.
                    </p>
                  </div>
                  <Button type={StyleType.Danger} loading={false} onClick={handleBanClicked}>
                    {lang.banTag}
                  </Button>
                </div>
              ) : (
                <div className="bg-blue-opaque px-md py-md flex rounded mt-sm justify-between">
                  <div>
                    <p className="text-sm">
                      {lang.noRecordsOf} <span className="text-sm font-semibold">{searchedKey}</span>
                      <p className="text-sm font-normal">{lang.continueBanningThisTag}</p>
                    </p>
                  </div>
                  <Button type={StyleType.Danger} loading={false} onClick={handleBanClicked}>
                    {lang.banTag}
                  </Button>
                </div>
              )}
            </div>
          )}
        </Form>
      ) : (
        <Form onSubmit={handleSearch}>
          <p className="text-xs text-gray-500 m-0 font-semibold">{lang.reason}</p>
          <Field {...fields.banType}>
            <Select
              {...fields.banType}
              placeholder={lang.chooseAReason}
              options={[
                { text: lang.lostTag, value: "LOST_TAG" },
                { text: lang.defectiveTag, value: "DEFECTIVE_TAG" },
                { text: lang.others, value: "OTHERS" },
              ]}
              onChange={modifyField}
            />
          </Field>
          {fields.banType.value === "OTHERS" ? (
            <div className="mt-md">
              <InputCounter {...fields.banReason} textarea onChange={modifyField} />
            </div>
          ) : null}
          <InfoPanel>{lang.banningThisTagWillOnlyTakeEffect}</InfoPanel>
        </Form>
      )}
    </Modal>
  );
};

export default BanTagModal;
