import { formatName } from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import {
  dateTimeRequest,
  dateToTimeAgo,
  filterRequest,
  selectToObjectRequest,
} from "./common.mapper";

export const categoryListResponse = {
  id: { key: "categoryId" },
  name: { key: "categoryName" },
  description: { key: "description" },
  outletId: { key: "outletId" },
  productCount: { key: "productCount" },
  color: { key: "color" },
  isPosAvailable: { key: "isPosAvailable" },
};

export const categoryListFilterRequest = {
  hasProduct: false,
  ...filterRequest,
};

export const categoryFormRequest = {
  venueId: { key: "venueId" },
  categoryId: { key: "id" },
  categoryName: { key: "name" },
  description: { key: "description" },
  outletId: { key: "outletId" },
  color: { key: "color" },
  isPosAvailable: { key: "isPosAvailable" },
};

export const categoryResponse = {
  id: { key: "categoryId" },
  name: { key: "categoryName" },
  description: { key: "description" },
  outletId: { key: "outletId" },
  color: { key: "color" },
  isPosAvailable: { key: "isPosAvailable" },
};

export const allCategoryRequest = {
  hasProduct: false,
  venueId: { key: "venueId" },
};

export const colorListResponse = {
  color: {
    transform: (self) => {
      return self.src;
    },
  },
};

export const categoryReportListResponse = {
  id: { key: "categoryId" },
  name: { key: "categoryName" },
  grossProfit: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  sale: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  supplyValue: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.supplyPrice);
    },
  },
  margin: {
    transform: ({ self }) => {
      return `${self}%`;
    },
  },
  itemSold: { key: "itemSold" },
};

export const categoryDetailReportListResponse = {
  dateAndTime: dateToTimeAgo("timestamp"),
  transactionId: { key: "transactionId" },
  product: { key: "productName" },
  productId: { key: "productId" },
  grossProfit: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  sale: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  supplyValue: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.supplyPrice);
    },
  },
  margin: {
    transform: ({ self }) => {
      return `${self}%`;
    },
  },
  discountApplied: { key: "discountApplied" },
  voucherApplied: { key: "voucherName" },
  guestName: {
    transform: ({ src }) => {
      return formatName(src.guestFirstName, src.guestLastName);
    },
  },
  staffName: {
    transform: ({ src }) => {
      return formatName(src.staffFirstName, src.staffLastName);
    },
  },
  location: { key: "locationName" },
};

export const categoryReportListRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  categoryId: { key: "category" },
  locationIds: selectToObjectRequest("locations"),
  staffIds: selectToObjectRequest("staffs"),
  orders: {
    transform: ({ src }) => {
      if (src.sort.key === "dateAndTime") {
        return { [`t.deviceUpdatedDate`]: src.sort.value };
      }
      return { [`${src.sort.key}`]: src.sort.value };
    },
  },
};

export const categoryWithTransactionFilterResponse = {
  id: { key: "categoryId" },
  name: { key: "categoryName" },
};

export const categoryWithTransactionFilterRequest = {
  venueId: { key: "venueId" },
  ...dateTimeRequest,
};
