import React from "react";
import { Container } from "..";
import classnames from "classnames";

const Hightlight = ({ children, className }) => {
  return (
    <Container
      className={classnames(
        className,
        "bg-yellow-lighter px-sm py-sm mt-md text-xs rounded border border-yellow-darker border-solid"
      )}
      mb={0}
    >
      {children}
    </Container>
  );
};

export default Hightlight;
