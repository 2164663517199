import React, { useCallback } from "react";
import lang from "translations";
import styles from "./upload-avatar.module.scss";
import { Button, Avatar, Upload, Toast, Text } from "components/commons";
// import CommunityImage from "images/community.svg";
import classnames from "classnames";
import { StyleType } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";

const UploadAvatar = ({ phrases, avatar = null, setAvatar, loading, className }) => {
  const beforeUpload = useCallback((file) => {
    const fileName = file.name.split(".");
    const fileExt = fileName[fileName.length - 1];
    if (fileName.length === 1) {
      showError();
      return false;
    }
    const isJpgOrPng = ["png", "jpg", "jpeg"].includes(fileExt.toString().toLowerCase());
    const isLt1M = file.size / 1024;
    const limit = isLt1M >= 1024;
    if (!isJpgOrPng || limit) {
      Toast({
        content: !isJpgOrPng ? lang.invalidFileType : lang.unbaleToUploadMoreThanMb,
        error: true,
        icon: "exclamation-fill",
      }).open();
      return false;
    }
    return true;
  }, []);

  const showError = () => {
    Toast({
      content: lang.somethingWentWrong,
      error: true,
      icon: "exclamation-fill",
    }).open();
  };

  const uploadAvatar = useCallback(async (res) => {
    const { onSuccess } = res;
    try {
      if (res && res.fileList) {
        onSuccess(res);
      }
    } catch {
      showError();
    }
  }, []);

  return (
    <div className={classnames(styles.avatarContainer, "flex items-center py-sm")}>
      <Avatar
        className={classnames(styles.avatar, className)}
        phrases={phrases}
        image={avatar}
        loading={loading}
        size={20}
        width="w-20"
        height="h-20"
      />
      <div className={"ml-lg"}>
        <Upload
          className={styles.uploadContainer}
          beforeUpload={() => {
            return true;
          }}
          customRequest={uploadAvatar}
          onChange={(res) => {
            mixpanel.track(TrackEvent.ClickedButton, {
              button: lang.uploadPhoto,
            });
            const reader = new FileReader();
            const file = res.fileList[res.fileList.length - 1].originFileObj;
            reader.readAsDataURL(file);
            let onload = null;
            onload = reader.addEventListener(
              "load",
              function () {
                const isImageValid = beforeUpload(file);
                if (isImageValid) {
                  setAvatar(reader.result, res.file);
                  reader.abort();
                  reader.removeEventListener("load", onload);
                }
              },
              false
            );
          }}
        >
          <Button className="self-start" type={StyleType.Secondary}>
            {avatar ? lang.changePhoto : lang.uploadPhoto}
          </Button>
        </Upload>

        <Text description className="mt-sm">
          {lang.imageTypeSize}
        </Text>
      </div>
    </div>
  );
};

export default UploadAvatar;
