import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useModal, useRouter, useForm, useMount, useApi } from "hooks";
import {
  Form,
  Panel,
  Title,
  Skeleton,
  ActionButton,
  Toast,
  CheckboxField,
} from "components/commons";
import { Field as FieldType } from "enums";
import { VenueContext } from "contexts";
import { getCurrencies } from "apis/utils.api";
import { setWristbandKeepingValue } from "apis/venue.api";
import { mixpanel, TrackEvent } from "mixpanel";

const WristbandTagSetting = () => {
  const { venue, fetchVenue, loading, error } = useContext(VenueContext);

  const { history } = useRouter();
  const unsaveChangesModal = useModal();

  const requestCurrencies = useApi({
    api: getCurrencies,
  });

  const { request, loading: submitting } = useApi({
    api: setWristbandKeepingValue,
    params: {
      venueId: venue.venueId,
    },
  });

  const initialState = useMemo(() => {
    return {
      keepWristbandAllowed: {
        name: "keepWristbandAllowed",
        value: venue.keepWristbandAllowed,
        type: FieldType.CHECKBOX,
      },
    };
  }, [venue]);

  const { fields, modifyField, dirty, submitForm, getFormValues, applyFieldErrors } = useForm({
    initialState,
  });

  const goToList = useCallback(() => {
    history.push(Path.SETTING);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const submitFormValue = useCallback(async () => {
    try {
      await request(getFormValues());
      await fetchVenue();
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      goToList();
    } catch ({ code, handleError }) {
      const err = {
        3028: () => {
          applyFieldErrors({
            name: lang.venueNameAlreadyExist,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [getFormValues, request, goToList, fetchVenue, applyFieldErrors]);

  useMount(() => {
    fetchVenue();
    requestCurrencies.request();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.wristbandTagSettings,
    });
  });

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.wristbandKeeping}
          returnPath={Path.SETTING}
          onClick={leavePage}
        />
      }
    >
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={() => {}}>
        {loading ? (
          <FragmentA title={""} description={lang.wristbandKeeping}>
            <Skeleton />
          </FragmentA>
        ) : (
          <>
            <FragmentA title={""} description={lang.wristbandKeeping}>
              <Panel>
                <Title md className="mb-sm">
                  {lang.wristbandKeeping}
                </Title>
                <CheckboxField
                  {...fields.keepWristbandAllowed}
                  onChange={modifyField}
                  textSize="text-sm"
                >
                  {lang.allowGuestWBKeeping}
                </CheckboxField>
              </Panel>
            </FragmentA>
          </>
        )}
      </Form>
      <ActionButton
        showLine
        loading={submitting}
        primary={{
          onClick: () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              button: lang.saveWristbandKeepingSettings,
              Page: lang.wristbandTagSettings,
            });
            submitForm(submitFormValue);
          },
          disabled: !dirty,
        }}
        secondary={{
          onClick: () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              button: lang.discardWristbandKeepingSettings,
              Page: lang.wristbandTagSettings,
            });
            leavePage();
          },
        }}
      />
    </ModuleWrapper>
  );
};

export default WristbandTagSetting;
