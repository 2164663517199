import lang from "translations";
const columns = [
  {
    id: 1,
    key: "supplyItemName",
    text: lang.supplyItem,
  },
  {
    id: 2,
    key: "expStockQty",
    text: lang.expStockQty,
    textMobile: lang.expectedQty,
    right: true,
  },
  {
    id: 3,
    key: "actualStockQty",
    text: lang.actualStockQty,
    textMobile: lang.actualQty,
    right: true,
  },
  {
    id: 4,
    key: "difference",
    text: lang.difference,
    right: true,
  },
  {
    id: 5,
    key: "amount",
    text: lang.amount,
    right: true,
  },
];

export default columns;
