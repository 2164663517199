import lang from "translations";

const columns = [
  {
    key: "transactionId",
    text: lang.transaction,
    custom: true,
    width: "w-auto",
  },
  {
    key: "dateCreated",
    text: lang.date,
    custom: true,
    fixWidth: "130px",
    width: "w-auto",
    sort: true,
  },
  {
    key: "guest",
    text: lang.guest,
    custom: true,
    fixWidth: "150px",
    width: "w-auto",
  },
  {
    key: "locationName",
    text: lang.location,
    width: "w-auto",
    fixWidth: "150px",
  },
  {
    key: "staffName",
    text: lang.staff,
    width: "w-auto",
    fixWidth: "150px",
  },
  {
    key: "transactionType",
    text: lang.type,
    custom: true,
    width: "w-auto",
  },
  {
    key: "items",
    text: lang.items,
    fixWidth: "170px",
  },
  {
    key: "quantity",
    text: lang.qty,
  },
  {
    key: "paidAmount",
    text: lang.amount,
    custom: true,
    align: "right",
    width: "w-auto",
    fixWidth: "150px",
  },
];

export default columns;
