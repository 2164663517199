import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    name = "",
    legalBusinessName = "",
    businessAddress1 = "",
    businessAddress2 = "",
    contactNumber = "",
    countryCode = "",
    countryName,
  } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.venueName,
    },
    legalBusinessName: {
      name: "legalBusinessName",
      value: legalBusinessName,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 255,
      label: lang.legalBusinessName,
    },
    businessAddress1: {
      name: "businessAddress1",
      value: businessAddress1,
      type: Field.INPUT,
      maxLength: 127,
      label: lang.businessAddress,
      placeholder: lang.streetVillage,
    },
    businessAddress2: {
      name: "businessAddress2",
      value: businessAddress2,
      type: Field.INPUT,
      maxLength: 127,
      placeholder: lang.cityStateCountry,
    },
    contactNumber: {
      name: "contactNumber",
      value: contactNumber,
      type: Field.INPUT,
      validations: [Validation.minlength(4)],
      maxLength: 15,
      label: lang.contactNumber,
    },
    countryCode: {
      name: "countryCode",
      value: countryCode,
      type: Field.DROPDOWN,
    },
    countryName: {
      name: "countryName",
      value: countryName,
      type: Field.DROPDOWN,
    },
  };
};

export default initialFormState;
