import enumeration from "./enumeration";

const ReportStatus = {
  processing: "PROCESSING",
  done: "DONE",
  expired: "EXPIRED",
  failed: "FAILED"
}

export default enumeration(ReportStatus);
