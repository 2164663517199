import enumeration from "./enumeration";

const StocktakeStatus = {
  InProgress: "IN_PROGRESS",
  ForApproval: "FOR_APPROVAL",
  Completed: "COMPLETED",
  Void: "VOID",
};

export default enumeration(StocktakeStatus);
