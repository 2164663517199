import lang from "translations";
import React from "react";
import { Panel, Text, Title, Button } from "components/commons";
import ItemsField from "./items-field";
import { ProductField } from "./product-set-form.state";
import { StyleType } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";

const ItemsForm = ({ form, modifyForm, productSkuId }) => {
  const { products } = form;

  return (
    <Panel>
      <Title>{lang.items}</Title>
      <Text>{lang.addProducts}</Text>
      <ItemsField form={form} modifyForm={modifyForm} productSkuId={productSkuId} />
      <div className="mt-md">
        <Button
          type={StyleType.Secondary}
          onClick={() => {
            products.onChange(products.name, {
              value: [...products.value, ProductField],
            });
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.addProduct,
              Page: lang.createProductSet,
            });
          }}
        >
          {lang.addAnotherProductOrSupplyItem}
        </Button>
        {products.value?.length < 2 ? (
          <Text strong className="mt-sm" color="text-red">
            {lang.minimumOf}
          </Text>
        ) : null}
      </div>
    </Panel>
  );
};

export default ItemsForm;
