export const supplyItemFilter = (venueId) => ({
  venueId,
  page: 1,
  pageSize: 20,
  searchKey: "",
  locations: [],
  stockLevels: [],
  orders: {
    "p.productName": "asc",
  },
});

export const supplyItemFilterStocktake = (venueId, locationId) => ({
  venueId,
  page: 1,
  pageSize: 10,
  searchKey: "",
  locationId: locationId,
  stockLevels: [],
  orders: {
    "p.productName": "asc",
  },
});
