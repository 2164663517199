export const transactionPaths = {
  TRANSACTIONS: "",
  REGISTER_REPORT: "register-report",
  REGISTER_REPORT_ID: (id) => `register-report/${id}`,
  TRANSACTION_ID: (id) => id,
  VIEW_TRANSACTION: ":id",
  VIEW_REGISTER_REPORT: "register-report/:id",
  END_OF_DAY_REPORT: "end-of-day",
  END_OF_DAY_REPORT_ID: (id) => `end-of-day/${id}`,
  VIEW_OF_DAY_REPORT_ID: `end-of-day/:id`,
};
