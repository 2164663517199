import { default as mixpanelBrowser } from "mixpanel-browser";
import { TrackEvent } from ".";

// add mixpanel functions here
const mixpanel = {
  track: (event, props) => {
    // console.log(props);
    if (event === TrackEvent.VisitedPage){
      // incremenet page view user properties by 1 
      mixpanelBrowser.people.increment('pageViews'); 
    }
    mixpanelBrowser.track(event, { ...props });
  },
  init: (token, props) => {
    mixpanelBrowser.init(token, { ...props });
  },
  identify: (uniqueId) => {
    mixpanelBrowser.identify(uniqueId);
  },
  people: {
    set: (props, to, callback) => {
      mixpanelBrowser.people.set({ ...props }, to, callback);
    },
    set_once: (props, to, callback) => {
      mixpanelBrowser.people.set_once({ ...props }, to, callback);
    },
    unset: (props, callback) => {
      mixpanelBrowser.people.unset({ ...props }, callback);
    },
  },
  reset: () => {
    mixpanelBrowser.reset();
  },
};

export default mixpanel;
