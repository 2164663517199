import React from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { MultiLineChart, Title } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

const TotalSalesOfLocationChart = ({ ...props }) => {
  const data = props.mappedData.data;
  const chartColors = [
    "#ffc107",
    "#ffecb3",
    "#ffe082",
    "#ffd54f",
    "#ffca28",
    "#ffb300",
    "#ffa000",
    "#ff8f00",
    "#ff6f00",
    "#fff8e1",
  ];

  return (
    <ChartContainer title={lang.totalSales} {...props} empty={!props.mappedData.totalSale}>
      <Title xl className="my-sm" fontWeight="font-normal">
        {props.mappedData.total}
      </Title>
      <MultiLineChart
        position={"right"}
        align={"start"}
        height={330}
        chartColors={chartColors}
        {...data}
        formatYAxis={(label) => {
          return formatNumberToMoneyWithCurrencySymbol(parseInt(label));
        }}
        formatToolTipValue={(value) => {
          return formatNumberToMoneyWithCurrencySymbol(value.value);
        }}
      />
    </ChartContainer>
  );
};

export default TotalSalesOfLocationChart;
