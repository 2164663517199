export const accountingResponse = {
  accountCodeId: { key: "id" },
  code: { key: "code" },
  accountType: { key: "accountType" },
  taxRate: { key: "taxRate" },
  description: { key: "description" },
  currency: { key: "currency" },
  accountName: { key: "name" },
  accountCode: { key: "accountCode" },
};

export const accountMappingsResponse = {
  itemType: { key: "itemType" },
  accountCodeId: { key: "accountCodeId" },
};
