import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";
const initialFormState = (initialState = {}) => {
  const { name = "", macAddress = "", imei = "", serialNumber = "" } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.deviceName,
    },
    macAddress: {
      name: "macAddress",
      value: macAddress,
      type: Field.LABEL,
      label: lang.deviceMac,
    },
    imei: {
      name: "imei",
      value: imei,
      type: Field.LABEL,
      label: lang.deviceImei,
    },
    serialNumber: {
      name: "serialNumber",
      value: serialNumber,
      type: Field.LABEL,
      label: lang.deviceSerialNumber,
    },
  };
};

export default initialFormState;
