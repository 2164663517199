import { ActionButton, Modal, Text } from "components/commons";
import { StyleType } from "enums";
import React from "react";
import { pluralize } from "services";
import lang from "translations";

const PosModal = ({ posStatus, count, toggling, ok, close, active }) => {
  return (
    <Modal
      active={active}
      actionContent={
        <ActionButton
          loading={toggling}
          className="px-md py-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              close();
            },
          }}
          primary={{
            text: posStatus ? lang.sellOnPos : lang.dontSellOnPos,
            type: posStatus ? StyleType.Primary : StyleType.Danger,
            onClick: () => {
              ok();
            },
          }}
        />
      }
      title={
        posStatus
          ? lang.populate(lang.turnOnPos, [count, pluralize(count, "product", "products")])
          : lang.populate(lang.turnOffPos, [count, pluralize(count, "product", "products")])
      }
    >
      <Text>{posStatus ? lang.allSelectedAvailablePos : lang.allSelectedNotPos}</Text>
    </Modal>
  );
};

export default PosModal;
