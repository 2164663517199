import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { getProduct, updateProduct, deleteProduct } from "apis/product.api";
import { product } from "mappers";
import { useApi, useMount, useRouter } from "hooks";
import { VenueContext } from "contexts";
import ProductForm from "../product-form/product-form";
import {
  CreateVariantForm,
  CreateSupplyItemField,
  validateSkus,
} from "../product-form/product-form.state";
import { formatNumberToMoney } from "services/money.service";
import { computeMarkUp } from "services/product-price.service";
import { formatNumberWithComma, sortByKeyName } from "services";
import { Skeleton, Toast } from "components/commons";
import { Path } from "paths";
import { add, multiply, toAmount } from "services/number.service";
import InventoryType from "enums/inventory-type";
import { ProductType } from "enums";
import Validation from "services/validation.service";
import { mixpanel, TrackEvent } from "mixpanel";
import { accountMappingsResponse } from "mappers/accounting.mapper";
import { getAccountMappings } from "apis";

const EditProduct = () => {
  const { venue } = useContext(VenueContext);
  const { query, history } = useRouter();
  const { id, revenueAccountCodeId, cogsAccountCodeId } = query;
  const { venueId } = venue || {};
  const {
    request: getProductRequest,
    loading,
    mappedData,
    error,
  } = useApi({
    api: getProduct,
    params: {
      productId: id,
      venueId,
    },
    mapper: {
      ...product,
      _keys: [
        "revenueAccountCodeId",
        "cogsAccountCodeId",
        "categories",
        "description",
        "imageLink",
        "sellableInPos",
        "isActiveMobileOrdering",
        "creationType",
        "productAttributes",
        "productSkus",
        "tax",
        "locations",
        "isActiveAllLocations",
        "isActiveAllCategories",
      ],
    },
    handleOwnError: true,
  });

  const { request, loading: submitting } = useApi({
    api: updateProduct,
    params: {
      productId: id,
      venueId,
      revenueAccountCodeId,
      cogsAccountCodeId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: deleteProductRequest } = useApi({
    api: deleteProduct,
  });

  const { request: accountMappingRequest } = useApi({
    api: getAccountMappings,
    params: {
      businessId: venue.venueId,
    },
    isArray: true,
    mapper: accountMappingsResponse,
    handleOwnError: true,
  });

  const goToList = useCallback(() => {
    history.push(Path.PRODUCT);
  }, [history]);

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,
          message: lang.changesSaved,
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  const submitDeleteProduct = useCallback(
    async (params, productName, close) => {
      try {
        await deleteProductRequest(params);
        close();
        goToList();
        Toast({
          content: lang.populate(lang.productDeleted, [productName]),
          success: true,
          icon: "check",
        }).open();
      } catch (e) {
        close();
        goToList();
        throw e;
      }
    },
    [goToList, deleteProductRequest]
  );

  useMount(async () => {
    getProductRequest();
    await accountMappingRequest();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editProduct,
    });
  });

  const initialState = useMemo(() => {
    const {
      name,
      imageLink,
      description,
      categories = [],
      creationType,
      sellableInPos,
      isActiveMobileOrdering = false,
      productAttributes = [],
      productSkus = [],
      hasTax,
      tax = {},
      locations = [],
      isActiveAllLocations,
      isActiveAllCategories,
      revenueAccountCodeId,
      cogsAccountCodeId,
    } = mappedData;

    const hasVariants = productAttributes.length;

    let obj = {
      productName: {
        value: name,
      },
      productLogo: {
        value: imageLink,
      },
      description: {
        value: description || "",
      },
      category: {
        isAll: isActiveAllCategories,
        value: categories.map(({ categoryId, categoryName }) => {
          return {
            value: categoryId,
            text: categoryName,
          };
        }),
      },
      activePos: {
        value: sellableInPos,
      },
      activeOnlineMenu: {
        value: isActiveMobileOrdering,
      },
      inventoryType: {
        value: creationType || InventoryType.WholeProduct,
      },
      hasTax: {
        value: hasTax,
      },
      tax: {
        value: tax.taxId,
      },
      generatedSku: {
        value: false,
      },
      supplyUnit: {
        disabled: creationType === InventoryType.AssembleProduct || hasVariants,
      },
      sellingQuantity: {
        disabled: creationType === InventoryType.AssembleProduct || hasVariants,
      },
      supplyQuantity: {
        disabled: creationType === InventoryType.AssembleProduct || hasVariants,
      },
      supplyItems: {
        disabled: hasVariants,
      },
      location: {
        isAll: isActiveAllLocations,
        value: locations.map(({ locationId, locationName }) => {
          return {
            value: locationId,
            text: locationName,
          };
        }),
      },
      revenueAccountCodeId: {
        value: revenueAccountCodeId,
      },
      cogsAccountCodeId: { value: cogsAccountCodeId },
    };

    if (hasVariants) {
      const productSkusSorted = productSkus
        .map((ps, index) => {
          const normalizeValue = {};
          const attributes = [];
          ps.productVariants.forEach((attr) => {
            normalizeValue[attr.attribute] = attr.value;
            attributes.push(attr.attribute);
          });
          return {
            text: attributes
              .sort()
              .map((attr) => normalizeValue[attr])
              .join(", "),
            index,
          };
        })
        .sort(sortByKeyName("text"));
      obj = {
        ...obj,

        hasVariants: {
          value: hasVariants,
          hidden: true,
        },
        type: {
          value: ProductType.Variant,
        },
        attributeAndOptions: {
          value: [],
        },
        variants: {
          value: productSkusSorted.map(({ text, index: productSkuIndex }) => {
            const variant = productSkus[productSkuIndex];
            const quantity =
              creationType === InventoryType.WholeProduct ? variant.compositions?.[0].quantity : 0;
            // const keys = variant.productVariants.map((attr) => attr.value);
            let supplyPriceValue = 0;
            const supplyItemsValue = {
              value: variant?.compositions?.map((composition) => {
                const costValue = multiply(composition.supplyPrice, composition.quantity);
                supplyPriceValue = add(costValue, supplyPriceValue);
                return CreateSupplyItemField({
                  item: {
                    value: composition.productSkuId,
                    symbol: composition.measurement?.unit || "",
                    error: false,
                    pristine: true,
                    validations: [],
                  },
                  quantity: {
                    value: toAmount(
                      composition.quantity,
                      composition.measurement?.unit === "pc" ? "0,0" : "0,0.000"
                    ),
                  },
                  cost: {
                    value: formatNumberToMoney(costValue),
                    amount: composition.supplyPrice,
                  },
                });
              }),
            };
            const supplyCostValue = supplyPriceValue;
            const compositions = {
              value: variant?.partOfCompositions?.map((composition) => {
                const { product } = composition;
                const { productName, productId } = product;
                return {
                  productName,
                  productId,
                };
              }),
            };
            return CreateVariantForm({
              productSkuId: {
                value: variant.productSkuId,
              },
              status: {
                value: variant.status,
              },
              logo: {
                value: variant.imageLink,
                image: variant.imageLink,
              },
              sku: {
                value: variant.sku,
                required: true,
                validations: [
                  Validation.required(),
                  Validation.minlength(4),
                  Validation.maxlength(16),
                  validateSkus,
                ],
              },
              attribute: {
                value: variant.productVariants,
              },
              key: {
                value: text,
              },
              supplyUnit: {
                value: variant.measurement?.measurementId,
                unit: variant.measurement?.unit,
                disabled: creationType === InventoryType.AssembleProduct,
              },
              sellingQuantity: {
                value: toAmount(quantity, variant.measurement?.unit === "pc" ? "0,0" : "0,0.000"),
                disabled: creationType === InventoryType.AssembleProduct,
              },
              supplyQuantity: {
                value: toAmount(variant.stockWeightPerPiece, "0,0.000"),
                disabled: creationType === InventoryType.AssembleProduct,
              },
              supplyItems: supplyItemsValue,
              supplyCost: {
                value: formatNumberToMoney(supplyCostValue),
              },
              retailPrice: {
                value: formatNumberToMoney(variant.retailPrice),
              },
              markUp: {
                value: formatNumberWithComma(computeMarkUp(supplyCostValue, variant.retailPrice)),
              },
              compositions,
            });
          }),
        },
      };
      const attributes = [];
      productAttributes.forEach((attr) => {
        if (!attributes.includes(attr.attribute)) {
          attributes.push(attr.attribute);
        }
      });
      obj.attributes = {
        value: attributes,
      };
    } else {
      const productSku = productSkus?.[0] || {};
      if (creationType === InventoryType.AssembleProduct) {
        let supplyPriceValue = 0;
        obj = {
          ...obj,
          supplyItems: {
            value: productSku?.compositions?.map((composition) => {
              const costValue = multiply(composition.supplyPrice, composition.quantity);
              supplyPriceValue = add(costValue, supplyPriceValue);

              return CreateSupplyItemField({
                item: {
                  value: composition.productSkuId,
                  unit: composition.measurement?.unit,
                  symbol: composition.measurement?.unit,
                  pristine: true,
                  validations: [],
                  error: false,
                },
                quantity: {
                  value: toAmount(
                    composition.quantity,
                    composition.measurement?.unit === "pc" ? "0,0" : "0,0.000"
                  ),
                },
                cost: {
                  value: formatNumberToMoney(costValue),
                  amount: composition.supplyPrice,
                },
              });
            }),
          },
          supplyCost: {
            value: formatNumberToMoney(supplyPriceValue),
          },
          markUp: {
            value: formatNumberWithComma(computeMarkUp(supplyPriceValue, productSku.retailPrice)),
            disabled: false,
          },
        };
      } else {
        const supplyCost =
          productSku.compositions?.[0].quantity * productSku.compositions?.[0].supplyPrice;
        obj = {
          ...obj,
          supplyItem: {
            value: productSku.compositions?.[0].productSkuId,
          },
          supplyUnit: {
            value: productSku.measurementId,
            unit: productSku.measurement?.unit,
            symbol: productSku.measurement?.unit,
          },
          sellingQuantity: {
            value: toAmount(
              productSku.compositions?.[0].quantity,
              productSku.measurement?.unit ? "0,0" : "0,0.000"
            ),
          },
          supplyQuantity: {
            value: formatNumberWithComma(productSku.stockWeightPerPiece),
          },
          supplyCost: {
            value: formatNumberToMoney(supplyCost),
          },
          markUp: {
            value: formatNumberWithComma(computeMarkUp(supplyCost, productSku.retailPrice)),
            disabled: false,
          },
        };
      }
      obj = {
        ...obj,
        sku: {
          value: productSku.sku,
          required: true,
          validations: [Validation.required(), Validation.minlength(4), Validation.maxlength(16)],
        },
        retailPrice: {
          value: formatNumberToMoney(productSku.retailPrice),
        },
      };
    }
    return obj;
  }, [mappedData]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <ProductForm
      title={lang.editProduct}
      error={error}
      submitting={submitting}
      onSubmit={submitForm}
      loading={loading}
      isEdit
      initialState={initialState}
      onDeleteProduct={submitDeleteProduct}
      productId={id}
    />
  );
};

export default EditProduct;
