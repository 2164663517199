import React from "react";
import { Container } from "components/commons";
import classnames from "classnames";

const Panel = ({ children, className, paddingless, parent = true, bordered = false, ...props }) => {
  return (
    <Container
      paddingless={paddingless}
      className={classnames(className, "bg-white rounded", {
        "border border-gray-border": bordered,
        "shadow-sm": !bordered,
      })}
      parent={parent}
      {...props}
    >
      {children}
    </Container>
  );
};

export default Panel;
