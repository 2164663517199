import React, { useState, useContext, useCallback } from "react";
import lang from "translations";
import { ModuleWrapper } from "components/fragments";
import {
  Text,
  Panel,
  Avatar,
  Title,
  ButtonLink,
  TextArea,
  Button,
  Icon,
  Field,
  PageError,
} from "components/commons";
import { useRouter, useMount, useApi, useModal } from "hooks";
import {
  getGuestCheckinInfo,
  createGuestNote,
  deleteGuestNote,
  getGuestCheckin,
  getGuestNotes,
} from "apis";
import { GuestDetailsSkeleton, GuestDetailsHeader } from "..";
import { DeleteModal } from "components/modals";
import { StaffContext, VenueContext } from "contexts";
import { DateTime, StyleType } from "enums";
import { capitalize, formatDate, mapObjects } from "services";
import classnames from "classnames";
import { guestNotesResponse, guestProfileResponse } from "mappers/guest.mapper";
import styles from "./guest-details-profile.module.scss";
import { Divider, Skeleton } from "antd";
import EditNoteModal from "./edit-note-modal.module";
import { mixpanel, TrackEvent } from "mixpanel";

const GuestDetailsProfile = () => {
  const { venue } = useContext(VenueContext);
  const { staff } = useContext(StaffContext);
  const { query } = useRouter();
  const { id } = query;
  const { venueId } = venue;
  const { profileId, name: staffName } = staff;
  const [showMore, setShowMore] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [notesList, setNotesList] = useState([]);
  const [deletingNote, setDeletingNote] = useState(false);
  const deleteNoteModal = useModal();
  const editNoteModal = useModal();

  const {
    request: fetchCheckinInfo,
    loading: loadingGuestCheckinInfo,
    mappedData: mappedGuestCheckinInfo,
    error: guestCheckinError,
  } = useApi({
    api: getGuestCheckinInfo,
    params: { guestId: id, venueId },
    mapper: guestProfileResponse,
    handleOwnError: true,
    returnMappedData: true,
  });

  const { request: createNoteRequest } = useApi({
    api: createGuestNote,
    params: {
      venueId,
      guestId: id,
    },
  });
  const { request: getNotes, loading: loadingNotes } = useApi({
    api: getGuestNotes,
    params: {
      venueId,
      guestId: id,
    },
    isArray: true,
  });

  const { request: deleteNoteRequest } = useApi({
    api: deleteGuestNote,
    params: {
      venueId,
      guestId: id,
    },
  });

  const { request: requestGuestCheckIn, mappedData: mappedGuestCheckin } = useApi({
    api: getGuestCheckin,
    params: { guestId: id, venueId },
    mapper: {
      pairedTagOnCheckin: { key: "pairedTagOnCheckin" },
      useCountOfPairedTag: { key: "useCountOfPairedTag" },
      checkedIn: {
        transform: ({ src }) => {
          return Boolean(!src.dateOfCheckOut);
        },
      },
      guestTagUids: { key: "guestTagUids" },
    },
  });

  const fetchGuestApis = (id) => {
    requestGuestCheckIn({
      checkinId: id,
    });
  };

  useMount(async () => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.guestDetails,
    });
    try {
      const res = await fetchCheckinInfo();
      const { currentCheckin = {}, guestCheckinIds } = res;
      let guestCheckInId = currentCheckin?.checkinId;
      if (!guestCheckInId && guestCheckinIds.length) {
        guestCheckInId = guestCheckinIds[0].checkinId;
      }
      fetchGuestApis(guestCheckInId);
    } catch (e) {
      console.log(e);
    }
  });

  const {
    firstName,
    lastName,
    birthdate,
    phone,
    gender,
    email,
    guestQuestions = [],
    imageUrl,
  } = mappedGuestCheckinInfo;

  const getUniqueQuestion = () => {
    let uniqueQuestions = [];
    let counts = [];
    let indexes = [];
    guestQuestions.forEach(function (x, idx) {
      counts[x.questionId] = (counts[x.questionId] || 0) + 1;
      indexes[x.questionId] = idx;
    });

    indexes.forEach((index) => {
      uniqueQuestions.push(guestQuestions[index]);
    });

    return uniqueQuestions;
  };

  const uniqueQuestions = getUniqueQuestion() ?? [];

  useMount(async () => {
    await fetchCheckinInfo({ guestId: id, venueId });
    fetchNotes();
  });

  const fetchNotes = useCallback(async () => {
    const res = await getNotes();
    const notes = mapObjects(res?.data, guestNotesResponse);
    if (!loadingNotes) {
      setNotesList(notes);
    }
  }, [loadingNotes, getNotes]);

  const avatar = {
    className: "m-auto bg-pelorous",
    phrases: [firstName, lastName],
    loading: loadingGuestCheckinInfo,
    image: imageUrl ?? false,
    defaultIconAvatar: "account-settings",
    size: 35,
  };

  const labelText = (label, text) => (
    <Field label={label}>
      <Text>{text}</Text>
    </Field>
  );

  const guestDetailsContent = (
    <div className="grid grid-cols-12">
      <div className="col-span-6">
        <div className="grid grid-rows-4 gap-4">
          <div className="row-span-1">{labelText(lang.firstName, firstName ?? "-")}</div>
          <div className="row-span-1">{labelText(lang.mobileNumber, phone ?? "-")}</div>
          <div className="row-span-1">{labelText(lang.emailAddress, email ?? "-")}</div>
          <div className="row-span-1">{labelText(lang.birthdate, birthdate ?? "-")}</div>
        </div>

        {uniqueQuestions.length > 0 ? (
          showMore ? (
            <div>
              {uniqueQuestions.map((question, index) => (
                <div className="mt-md" key={index}>
                  {labelText(question.question, question.answer)}
                </div>
              ))}
              <ButtonLink className="mt-sm" onClick={() => setShowMore(false)}>
                {lang.hideDetails}
              </ButtonLink>
            </div>
          ) : (
            <div className="mt-md">
              <ButtonLink onClick={() => setShowMore(true)}>{lang.showMore}</ButtonLink>
            </div>
          )
        ) : (
          <></>
        )}
      </div>

      <div className="col-span-6">
        <div className="grid grid-rows-4 gap-4">
          <div className="row-span-1">{labelText(lang.lastName, lastName ?? "-")}</div>
          <div className="row-span-1"></div>
          <div className="row-span-1"></div>
          <div className="row-span-1">
            {labelText(lang.gender, gender ? capitalize(gender) : "-")}
          </div>
        </div>
      </div>
    </div>
  );

  const profileContent = (
    <div className="w-full flex items-top justify between">
      <div>
        <Avatar height="h-24" width="w-24" textSize="text-5xl" {...avatar}></Avatar>
      </div>
      <div className="w-3/4 mx-md">
        <Title className="mb-md">{lang.guestDetails}</Title>
        <div>{guestDetailsContent}</div>
      </div>
    </div>
  );

  const createNote = async () => {
    let newNote = {
      noteId: null,
      note: noteContent,
      noteUpdatedDate: null,
      staffId: profileId,
      notedBy: staffName,
      sending: true,
      failed: false,
      noteCreatedDate: Date.now(),
    };
    setNotesList([newNote, ...notesList]);

    try {
      if (noteContent.length > 0 && noteContent.length <= 255) {
        setNoteContent("");

        const res = await createNoteRequest({
          venueId,
          note: noteContent,
          guestId: id,
        });

        newNote.noteId = res?.data.guestNoteId;
        newNote.sending = false;
      }
    } catch (error) {
      newNote.failed = true;
    } finally {
      setNotesList([newNote, ...notesList]);
    }
  };

  const deleteNote = async ({ noteId }) => {
    var noteState = notesList.filter((note) => note.noteId === noteId).pop();
    const newNotesList = notesList.filter((note) => note.noteId !== noteId);
    noteState = { ...noteState, sending: true, failed: false };
    setNotesList([noteState, ...newNotesList]);
    try {
      setDeletingNote(true);
      await deleteNoteRequest({ noteId });
      noteState.sending = false;
      setNotesList([...newNotesList]);
    } catch (error) {
    } finally {
      setDeletingNote(false);
    }
  };

  const notesContent = (
    <>
      <div className="p-lg">
        <Title className="mb-md">{lang.notes}</Title>
        <TextArea
          placeholder={lang.addANote}
          textarea
          value={noteContent}
          onChange={(name, value) => setNoteContent(value.value)}
          maxLength={255}
        />
        <div className="flex justify-end">
          <Button
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                Button: lang.createNote,
                Page: lang.guestDetails,
              });
              createNote();
            }}
            disabled={noteContent === ""}
          >
            {lang.addNote}
          </Button>
        </div>
      </div>
      {loadingNotes ? (
        <Skeleton className="px-lg pb-lg" />
      ) : notesList.length < 1 ? (
        <Text italic className="px-lg pb-lg">
          {lang.noNotesFound}
        </Text>
      ) : (
        notesList.map((note, index) => (
          <div key={index}>
            <div
              className={classnames(
                "px-lg py-md transition duration-300 ease-in-out hover:bg-white-dark",
                note.sending && "opacity-50"
              )}
            >
              <div className="flex justify-between ">
                <div>
                  <Text className={classnames(note.failed && "text-red")} breakAll>
                    {note.note}
                  </Text>
                </div>
                <div>
                  {note.staffId === profileId && !note.sending ? (
                    <ButtonLink
                      className="float-right text-red"
                      type={StyleType.Danger}
                      onClick={() => {
                        deleteNoteModal.show({
                          ok: () => {
                            mixpanel.track(TrackEvent.ClickedButton, {
                              Button: lang.deleteNote,
                              Page: lang.guestDetails,
                            });

                            deleteNote({ noteId: note.noteId }, note.note);
                            deleteNoteModal.close();
                          },
                          subject: lang.note,
                          subjectName: note.note,
                        });
                      }}
                    >
                      <Icon name="remove" />
                    </ButtonLink>
                  ) : null}
                </div>
              </div>

              <Text className={classnames("text-gray-lighter", note.failed && "text-red-400")}>
                {lang.populate(lang.notedByOn, [
                  note.notedBy,
                  formatDate(note.noteCreatedDate, DateTime.H),
                ])}
                {` `}
                {note.noteUpdatedDate === null
                  ? ""
                  : lang.populate(lang.updatedOn, [formatDate(note.noteUpdatedDate, DateTime.H)])}
              </Text>
              {note.staffId === profileId && !note.sending ? (
                <ButtonLink
                  onClick={() =>
                    editNoteModal.show({ noteContent: note.note, noteId: note.noteId })
                  }
                >
                  {lang.edit}
                </ButtonLink>
              ) : null}
            </div>
            {index !== notesList.length - 1 ? (
              <Divider className="p-0 m-0" />
            ) : (
              <div className="mb-md"></div>
            )}
          </div>
        ))
      )}
    </>
  );

  // const behaviorOverTimeContent = useMemo(() => {
  //   if (guestCheckinError) {
  //     return <PageError />;
  //   }
  //   return (
  //     <div>
  //       <Title className="mb-md">{lang.customerBehaviourOverTime}</Title>
  //       <Text label>{lang.totalSpentToDate}</Text>
  //       <Text fontWeight="font-medium" size="text-lg" className="break-all mb-md mt-sm">
  //         {totalSpent}
  //       </Text>
  //       <Text label>{lang.averageOrderValue}</Text>
  //       <Text fontWeight="font-medium" size="text-lg" className="break-all mb-md mt-sm">
  //         {averageOrderValue}
  //       </Text>
  //       <Text label>{lang.averageDurationOfStay}</Text>
  //       <Text fontWeight="font-medium" size="text-lg" className="break-all mb-md mt-sm">
  //         {averageDurationOfStay}
  //       </Text>
  //       <Text label>{lang.mostPurchasedProduct}</Text>
  //       <Text fontWeight="font-medium" size="text-lg" className="break-all mb-md mt-sm">
  //         {mostPurchasedProduct}
  //       </Text>
  //       <Text label>{lang.mostVisitedLocation}</Text>
  //       <Text fontWeight="font-medium" size="text-lg" className="break-all mt-sm">
  //         {mostVisitedLocation}
  //       </Text>
  //     </div>
  //   );
  // }, [
  //   averageOrderValue,
  //   mostVisitedLocation,
  //   mostPurchasedProduct,
  //   averageDurationOfStay,
  //   guestCheckinError,
  //   totalSpent,
  // ]);

  return loadingGuestCheckinInfo ? (
    <GuestDetailsSkeleton isGuestDetailsProfile />
  ) : (
    <ModuleWrapper
      loading={loadingGuestCheckinInfo}
      header={
        <GuestDetailsHeader
          loading={loadingGuestCheckinInfo}
          mappedData={mappedGuestCheckinInfo}
          mappedGuestCheckin={mappedGuestCheckin}
        />
      }
    >
      {guestCheckinError ? (
        <PageError />
      ) : (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <Panel className={classnames(styles.behaviorContainer)}>{profileContent}</Panel>
            <Panel paddingless>{notesContent}</Panel>
          </div>
          <div className="col-span-1">
            {/* <Panel className={classnames(styles.behaviorContainer)}>{behaviorOverTimeContent}</Panel> */}
          </div>
        </div>
      )}
      <DeleteModal {...deleteNoteModal} deleting={deletingNote} />
      <EditNoteModal notesList={notesList} setNotesList={setNotesList} {...editNoteModal} />
    </ModuleWrapper>
  );
};

export default GuestDetailsProfile;
