import React, { useCallback, useContext, useMemo, useState } from "react";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import { useApi, useMount, useFilter, useModal } from "hooks";
import { searchBanTags, unBanTag } from "apis/tag.api";
import { DataTable, Icon, Input, Text, Toast } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { SearchOutlined } from "@ant-design/icons";
import { formatDate, prettifyBanType } from "services";
import { BanType, DateTime } from "enums";
import { OctopusSunbathing } from "images";
import { v4 as uuidv4 } from "uuid";
import UnBanTagModal from "components/modals/ban-tag-modal/unban-tag-modal";
import BanTagModal from "components/modals/ban-tag-modal/ban-tag-modal";
import { mixpanel, TrackEvent } from "mixpanel";

const BanTagList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const [searchKey, setSearchKey] = useState("");
  const [submittedSearchKey, setSubmittedSearchKey] = useState("");
  const [unBanningTagId, setUnBanningTagId] = useState();

  const banTagModal = useModal();
  const unbanTagModal = useModal();

  const { modifyFilters, filterState } = useFilter({
    page: 1,
    pageSize: 20,
  });

  const {
    request,
    loading = true,
    error,
    result = { data: [] },
    mappedData,
  } = useApi({
    api: searchBanTags,
    isArray: true,
    mapper: {
      _keys: ["tagUid", "bannedTagId"],
      bannedDate: {
        transform: ({ src }) => {
          return formatDate(src?.createdDate, DateTime.H);
        },
      },
      bannedBy: {
        transform: ({ src }) => {
          if (src.bannedByProfile) {
            return src.bannedByProfile.fullName || "-";
          }
          return "-";
        },
      },
      reason: {
        transform: ({ src }) => {
          const { banType, banReason } = src || {};
          if (banType === BanType.OTHERS) {
            return banReason;
          }
          return prettifyBanType(banType);
        },
      },
    },
    params: {
      searchKey: searchKey.toString().trim().toLowerCase(),
      venueId,
      ...filterState,
    },
  });

  useMount(() => {
    request();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.banTagListSettings,
    });
  });

  const renderData = useMemo(() => {
    return mappedData.map(({ bannedTagId, tagUid, reason, bannedDate, bannedBy }) => {
      return {
        bannedTagId,
        tagUid,
        name: (
          <div key={uuidv4()}>
            <Text tagUid>{tagUid}</Text>
          </div>
        ),
        reason,
        bannedDate,
        bannedBy,
      };
    });
  }, [mappedData]);

  const showBanTagModal = useCallback(() => {
    banTagModal.show({
      title: lang.addAccount,
      primaryText: lang.banTag,
      initialState: undefined,
      isEdit: false,
      save: () => {
        console.log("test");
      },
    });
  }, [banTagModal]);

  const showUnBanTagModal = useCallback(() => {
    unbanTagModal.show({
      save: () => {
        console.log("test");
      },
    });
  }, [unbanTagModal]);

  const { request: unBanTagRequest, loading: unBanningTag } = useApi({
    api: unBanTag,
    handleOwnError: {
      badrequest: true,
    },
  });

  const unbanTag = useCallback(
    async (params) => {
      try {
        const res = await unBanTagRequest({ ...params });
        request();
        unbanTagModal.close();

        return {
          response: res,
          message: lang.tagUnbanned,
        };
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    [unBanTagRequest, unbanTagModal, request]
  );

  const handleUnbanTag = useCallback(async () => {
    try {
      const res = await unbanTag({ bannedTagId: unBanningTagId });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
    } catch ({ code, handleError }) {
      handleError();
    }
  }, [unbanTag, unBanningTagId]);

  return (
    <ModuleWrapper>
      <HeaderB
        returnPath={Path.SETTING}
        returnText={lang.settings}
        title={lang.banList}
        description={lang.onceYouBanATag}
        button={{
          text: lang.banATag,
          onClick: () => {
            showBanTagModal();
          },
        }}
        className="mb-md"
      />

      <Input
        className="mb-md"
        value={searchKey}
        disabled={loading}
        onChange={(name, { value }) => {
          value = value.toUpperCase();
          setSearchKey(value);
        }}
        onEnter={async () => {
          await request();
          setSubmittedSearchKey(searchKey);
        }}
        placeholder={lang.searchBanTags}
        iconSuffix={
          loading ? (
            <Icon loading />
          ) : (
            <SearchOutlined
              className="hover:text-blue cursor-pointer"
              onClick={async () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.searchTag,
                  Page: lang.banTagListSettings,
                });

                await request();
                setSubmittedSearchKey(searchKey);
              }}
            />
          )
        }
      />

      {!loading && renderData.length > 0 && submittedSearchKey !== "" ? (
        <p className="text-sm text-gray-400">{lang.searchResults}</p>
      ) : null}

      <BanTagModal
        {...banTagModal}
        onBannedTag={() => {
          banTagModal.close();
          request();
        }}
      />
      <UnBanTagModal
        {...unbanTagModal}
        unBanning={unBanningTag}
        unBanningTagId={unBanningTag}
        ok={(unBanningTagId) => {
          mixpanel.track(TrackEvent.ClickedButton, {
            Button: lang.unbanTag,
            Page: lang.banTagListSettings,
          });

          handleUnbanTag(unBanningTagId);
        }}
      />

      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={request}
        total={result.metadata?.total}
        loading={loading}
        columns={[
          {
            key: "name",
            text: lang.tagUID,
            custom: true,
            classNames: "whitespace-nowrap",
          },
          {
            key: "bannedDate",
            text: lang.dateBanned,
          },
          {
            key: "bannedBy",
            text: lang.bannedBy,
          },
          {
            key: "reason",
            text: lang.reason,
            width: "w-1/3",
          },
          {
            key: "action",
            actions: true,
            align: "right",
            actionTitle: lang.options,
            actionOptions: [
              {
                text: lang.unbanTag,
                onClick: (column) => {
                  setUnBanningTagId(column.bannedTagId);
                  showUnBanTagModal();
                },
              },
            ],
          },
        ]}
        data={renderData}
        error={error}
        hasAppliedFilter={submittedSearchKey}
        renderEmpty={{
          image: OctopusSunbathing,
          title: lang.noBannedTagsYet,
        }}
      />
    </ModuleWrapper>
  );
};

export default BanTagList;
