import React, { useMemo } from "react";
import lang from "translations";
import { Icon, Text } from "components/commons";
import styles from "./category-products-table.module.scss";
import classnames from "classnames";
import { pluralize } from "services";

const CategoryProductsTable = ({ linkedProducts, required, removeProduct, removeCategory }) => {
  const filteredLinkedProducts = useMemo(() => {
    linkedProducts?.forEach((productMap, categoryName, self) => {
      if (productMap.size === 0) {
        self.delete(categoryName);
      }
    });
    return linkedProducts;
  }, [linkedProducts]);

  const productCount = useMemo(() => {
    const productsSet = new Set();

    filteredLinkedProducts?.forEach((productMap) => {
      productMap.forEach((val, productName) => {
        productsSet.add(productName);
      });
    });

    return productsSet.size;
  }, [filteredLinkedProducts]);

  if (!filteredLinkedProducts || filteredLinkedProducts.size === 0) {
    if (required) {
      return (
        <Text className="text-center mt-md" size="text-sm text-red">
          {lang.noProductsSelected}
        </Text>
      );
    }
    return (
      <Text className="text-center mt-md" size="text-sm text-gray-light">
        {lang.noProductsSelected}
      </Text>
    );
  }

  if (productCount.length === 0) return null;

  return (
    <div className={classnames(styles.redeemableContainer, "mt-lg")}>
      <Text strong size="text-md px-md">{`${productCount} ${pluralize(
        productCount,
        lang.product,
        lang.products
      )}`}</Text>
      <div>
        <div className="flex">
          <div className="w-2/5 border-r-2">
            <Text label uppercase strong className="p-md">
              {lang.categories}
            </Text>
          </div>
          <div className="w-3/5">
            <Text label uppercase strong className="px-lg py-md">
              {lang.products}
            </Text>
          </div>
        </div>
        {Array.from(filteredLinkedProducts)
          .sort()
          .map(([categoryName, productMap], i) => {
            return (
              <div className="flex" key={i}>
                <div
                  className={classnames(
                    styles.category,
                    "flex w-2/5 border-r-2 border-t-2 items-start"
                  )}
                >
                  <Text strong className="p-lg break-all">
                    {categoryName}
                  </Text>
                  <Icon
                    name="remove"
                    className={classnames(
                      styles.icon,
                      styles.iconCat,
                      "text-red cursor-pointer mt-1.5"
                    )}
                    onClick={() => removeCategory(categoryName)}
                  />
                </div>
                <div className="w-3/5">
                  {Array.from(productMap)
                    .sort()
                    .map(([productName, productSkuIds], i) => {
                      if (productSkuIds.length > 1) {
                        return (
                          <div
                            key={i}
                            className={classnames(
                              styles.redeemable,
                              "flex justify-between items-center pl-lg py-md border-t-2"
                            )}
                          >
                            <Text className="break-all">{productName}</Text>
                            <div className="flex items-center">
                              <Text label>{productSkuIds.length} variants</Text>
                              <Icon
                                name="remove"
                                className={classnames(styles.icon, "text-red cursor-pointer mx-md")}
                                onClick={() => removeProduct(productName)}
                              />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={i}
                          className={classnames(
                            styles.redeemable,
                            "flex justify-between items-center pl-lg py-md border-t-2"
                          )}
                        >
                          <Text className="break-all">{productName}</Text>
                          <Icon
                            name="remove"
                            className={classnames(styles.icon, "text-red cursor-pointer mx-md")}
                            onClick={() => removeProduct(productName)}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoryProductsTable;
