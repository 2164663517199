import React, { useCallback } from "react";
import { StocktakeForm } from "modules";
import lang from "translations";
import { createStocktake } from "apis/stocktake.api";
import { useApi, useRouter } from "hooks";
import { Path } from "paths";

const CreateStockTake = () => {
  const { history } = useRouter();
  const {
    request,
    loading: submitting,
    error,
  } = useApi({
    api: createStocktake,
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        const id = res?.data.stocktakeId;
        history.push(Path.STOCKTAKE_ID(id));
      } catch (e) {
        throw e;
      }
    },
    // eslint-disable-next-line
    [request]
  );

  return (
    <StocktakeForm
      title={lang.createNewStocktake}
      submitting={submitting}
      submit={submitForm}
      error={error}
    />
  );
};

export default CreateStockTake;
