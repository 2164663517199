import { Text } from "components/commons";
import React, { useMemo, useState } from "react";
import lang from "translations";
import { Popover, Progress } from "antd";
import { convertTimeUnit } from "services";
import { SyncStatus } from "enums";

const ProcessStatusPopover = ({
  trigger = "click",
  placement = "bottom",
  syncStatus = null,
  noOfFailed = 0,
  noOfProcessing = 0,
  // totalnoOfProcessing = 1,
  timeRemainingValue = 0,
  children,
}) => {
  const [visible, setVisible] = useState(false);

  const isProcessing = useMemo(() => {
    const timeRemaining = convertTimeUnit(timeRemainingValue);

    return (
      <div className="w-56">
        <div className="flex justify-start">
          <Text className="text-base font-semibold">{lang.processingTransactions}</Text>
        </div>

        <div className="grid justify-items-stretch">
          <Progress
            showInfo={false}
            strokeLinecap="square"
            percent={99.9}
            // strokeColor={{
            //   from: "#9b9ca5",
            //   to: "#F2F6F7",
            // }}
            strokeColor={"#46B0C6"}
            status="active"
          />
        </div>

        <div>
          <div className="flex justify-start">
            <Text color="text-gray" className="text-xs">
              {`${noOfProcessing} processing`}
            </Text>
            <Text color="text-gray" className="px-2">
              {"•"}
            </Text>
            <Text color="text-gray" className="text-xs">
              {`${timeRemaining.value} ${timeRemaining.unit} left`}
            </Text>
          </div>
        </div>
      </div>
    );
  }, [
    noOfProcessing,
    // totalnoOfProcessing,
    timeRemainingValue,
  ]);

  const failedProcess = useMemo(() => {
    return (
      <div className="w-64">
        <div className="flex justify-start">
          <Text className="text-base font-semibold">{`${noOfFailed} ${lang.transactionsFailedToProcess}`}</Text>
        </div>
        <div className="flex justify-start">
          <Text color="text-gray" className="text-xs">
            {lang.thereIsSomeError}
          </Text>
        </div>
      </div>
    );
  }, [noOfFailed]);

  const offline = useMemo(() => {
    return (
      <div className="w-56">
        <div className="flex justify-start">
          <Text className="text-base font-semibold">{lang.offline}</Text>
        </div>
        <div className="flex justify-start">
          <Text color="text-gray" className="text-xs">
            {lang.pleaseCheckYourNetwork}
          </Text>
        </div>
      </div>
    );
  }, []);

  const content = useMemo(() => {
    if (syncStatus === SyncStatus.PROCESSING) {
      return isProcessing;
    }
    if (syncStatus === SyncStatus.FAILED) {
      return failedProcess;
    }
    if (syncStatus === SyncStatus.DISCONNECTED) {
      return offline;
    }
    return null;
  }, [failedProcess, isProcessing, offline, syncStatus]);

  return (
    <Popover
      content={content}
      trigger={trigger}
      visible={visible}
      placement={placement}
      onVisibleChange={setVisible}
      overlayInnerStyle={{ borderRadius: "0.25rem" }}
    >
      {children}
    </Popover>
  );
};

export default ProcessStatusPopover;
