import moment from "moment";

export const salesReportFilterState = (venueId) => {
  return {
    venueId,
    page: 1,
    pageSize: 10,
    searchKey: "",
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
  };
};
