import { Image, Modal, Text, Title } from "components/commons";
import { Can, Grocery, Plate } from "images";
import React from "react";
import lang from "translations";
import classNames from "classnames";
import styles from "./need-help-composition-modal.module.scss";
import { v4 as uuidv4 } from "uuid";

const NeedHelpCompositionModal = ({ ...modal }) => {
  return (
    <Modal {...modal} title={lang.compositionGuide}>
      <div>
        {[
          [
            Grocery,
            lang.productsVSupplyItems,
            <lang.Translate
              text={lang.productsAreSupplyItemsAre}
              items={[
                <b>{lang.products}</b>,
                <b>{lang.supplyItems}</b>,
                <span className="text-blue">{lang.inventory}</span>,
              ]}
            />,
          ],
          [Can, lang.wholeProduct, lang.aWholeProductIsFinished],
          [
            Plate,
            lang.assembleAProduct,
            <div>
              <span>
                <Text>{lang.byAssemblingAProduct}</Text>
              </span>
              <span>
                <Text>
                  <lang.Translate
                    text={lang.example1AssembleProduct}
                    items={[
                      <span className="italic">{lang.example1}</span>,
                      <b>{lang.coffeeBeans}</b>,
                      <b>{lang.milk}</b>,
                      <b>{lang.sugar}</b>,
                    ]}
                  />
                </Text>
              </span>
              <span>
                <Text>
                  <lang.Translate
                    text={lang.example2AssembleProduct}
                    items={[
                      <span className="italic">{lang.example2}</span>,
                      <b>{lang.bottle}</b>,
                      <b>{lang.glass}</b>,
                    ]}
                  />
                </Text>
              </span>
            </div>,
          ],
        ].map((item) => {
          return (
            <div key={uuidv4()} className="flex grid grid-cols-6 mb-lg">
              <div className="col-span-1 text-center">
                <Image src={item[0]} className="m-auto" />
              </div>
              <div className={classNames("col-span-5", styles.content)}>
                <Title md strong>
                  {item[1]}
                </Title>
                <div>
                  <Text>{item[2]}</Text>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex grid grid-cols-6 mb-lg pb-lg">
        <div className="col-span-1"></div>
        <div className={classNames("col-span-5")}>
          <Text>
            <lang.Translate
              text={lang.anyMoreQuestions}
              items={[<span className="text-blue">{lang.feelFreeToContactPouchnation}</span>]}
            />
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default NeedHelpCompositionModal;
