import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { name = "", description = "", freeCredit = 0, staffProfileVouchers = [] } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.profileName,
      placeholder: lang.staffProfileNamePlaceholder,
    },
    description: {
      name: "description",
      value: description,
      type: Field.INPUT,
      validations: [Validation.maxlength(255)],
      maxLength: 255,
      label: lang.description,
      placeholder: "",
    },
    freeCredit: {
      name: "freeCredit",
      value: freeCredit,
      type: Field.INPUT,
    },
    staffProfileVouchers: {
      name: "staffProfileVouchers",
      value: staffProfileVouchers,
      type: Field.ANY,
    },
  };
};

export default initialFormState;
