import React, { useCallback } from "react";
import { ActionButton, Button, Modal, Text, Toast } from "components";
import lang from "translations";
import { StyleType } from "enums";
import { formatId, parseAmountToNumber, redirectTo } from "services";
import { useApi } from "hooks";
import { Path } from "paths";
import { completeStocktake } from "apis";

const CompleteStocktakeModal = ({
  title,
  onComplete,
  formValues,
  id,
  venueId,
  profileId,
  totalSupplyItems,
  status,
  onSave,
  locationId,
  dataBeforeComplete,
  request,
  ...props
}) => {
  const { request: completeStocktakeRequest } = useApi({
    api: completeStocktake,
  });

  const completeStocktakeCb = useCallback(async () => {
    try {
      let stocktakeItems = [];
      formValues.stocktakeItems.forEach((item) => {
        const { productId, productSkuId, actualStockQty, expectedStockQty } = item.value;
        const actualQty =
          actualStockQty.value !== "" &&
          actualStockQty.value !== null &&
          actualStockQty.value !== undefined
            ? parseAmountToNumber(actualStockQty.value)
            : null;
        stocktakeItems.push({
          productId: productId.value,
          productSkuId: productSkuId.value,
          expectedStockLevel: parseAmountToNumber(expectedStockQty.value),
          actualStockLevel: actualQty,
        });
      });
      const data = {
        venueId: venueId,
        stocktakeId: parseInt(id),
        stocktakeItems: stocktakeItems,
        staffProfileId: profileId,
        locationId,
      };
      props.close();
      Toast({
        content: (
          <>
            ST-{formatId(id)} completed. Updated stocks of {totalSupplyItems} supply items.
            <Button
              type={StyleType.Link}
              onClick={() => {
                if (dataBeforeComplete !== null && dataBeforeComplete !== undefined) {
                  const res = onSave({
                    stocktakeId: data.stocktakeId,
                    body: dataBeforeComplete,
                  });
                  if (res) {
                    redirectTo(Path.STOCKTAKE_ID(id));
                  }
                } else {
                  const res = onSave({
                    stocktakeId: data.stocktakeId,
                    body: data,
                  });
                  if (res) {
                    redirectTo(Path.STOCKTAKE_ID(id));
                  }
                }
              }}
            >
              <Text fontWeight="font-semibold" color="text-white" className="ml-sm mt-xs">
                {lang.undo}
              </Text>
            </Button>
          </>
        ),
        duration: 2,
        success: true,
        icon: "check",
        iconSuffix: "clear",
        iconSuffixColor: "text-pelorous-darker opacity-25",
        iconSuffixAction: async () => {
          await completeStocktakeRequest({
            stocktakeId: data.stocktakeId,
            body: data,
          });
          redirectTo(Path.INVENTORY_STOCKTAKE);
        },
        onClose: async () => {
          await completeStocktakeRequest({
            stocktakeId: data.stocktakeId,
            body: data,
          });
          redirectTo(Path.INVENTORY_STOCKTAKE);
        },
      }).open();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [completeStocktakeRequest, id, venueId, profileId]);

  return (
    <Modal
      title={title}
      actionContent={
        <ActionButton
          showLine={false}
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              props.close();
            },
          }}
          primary={{
            text: lang.completeStocktake,
            onClick: () => {
              completeStocktakeCb();
            },
          }}
        />
      }
      {...props}
    >
      {lang.onceYouCompleteStocktake}
    </Modal>
  );
};

export default CompleteStocktakeModal;
