import { Title, Text, Button, Panel, Icon, Skeleton } from "components/commons";
import React, { useState } from "react";
import { StyleType } from "enums";
import lang from "translations";
import { useRouter } from "hooks";
import { Path } from "paths";
import "./dashboard-onboarding.scss";

const DashboardOnboarding = ({ setDisplay, onboardingStatus, closeBannerRequest, loading }) => {
  const [expand, setExpand] = useState(true);
  // const [hover, setHover] = useState(false);
  const { history } = useRouter();

  const isAllComplete =
    onboardingStatus.addedSupplyItem &&
    onboardingStatus.addedProduct &&
    onboardingStatus.invitedStaff &&
    onboardingStatus.loggedInApp;

  const handleCloseBanner = () => {
    setDisplay(false);
    closeBannerRequest();
  };

  const goToLink = ({ path, link, done }) => {
    if (!done) {
      history.push(path);
    }
    if (!done && link) {
      window.open(link, "_blank");
    }
  };

  const LabelItem = ({ label, done, path, link }) => {
    return (
      <div className={`flex mt-sm`}>
        <div
          className={`flex ${done ? "cursor-default" : "cursor-pointer"}`}
          onClick={() => goToLink({ path, link, done })}
        >
          <div className="flex items-center justify-center brightness-200">
            <Icon
              className={`${
                done
                  ? "bg-green-darker border border-transparent"
                  : "bg-white border-dashed border border-white-darkest"
              } rounded-full`}
              name="onboarding-check"
              color={`${done ? "text-white" : "text-white-darkest"}`}
            />
          </div>
          <Text
            className="flex ml-sm items-center"
            fontWeight="font-bold"
            size="text-sm"
            color={`${done ? "text-black" : "text-pelorous"}`}
          >
            {label}
          </Text>
        </div>
      </div>
    );
  };

  const OnboardingInProgress = () => (
    <Panel
      className={`${
        expand ? "" : "hover:border-pelorous-light"
        // hover ? "border-pelorous-light" : ""
      } grid grid-cols-4 relative onboarding-bg border-2 border-transparent`}
    >
      <div className="col-span-3 sm:col-span-2">
        <Title lg>{lang.onboardingTitle}</Title>
        <Text className="mt-sm">{lang.onboardingDescription}</Text>
        <div className={expand ? "block" : "hidden"}>
          <div className="grid grid-flow-row sm:grid-flow-col gap-4 mt-sm">
            <div>
              <LabelItem
                label={lang.addASupplyItem}
                done={onboardingStatus.addedSupplyItem}
                path={`${Path.INVENTORY_SUPPLY_ITEM}`}
              />
              <LabelItem
                label={lang.addAProduct}
                done={onboardingStatus.addedProduct}
                path={`${Path.PRODUCT}`}
              />
            </div>
            <div className="">
              <LabelItem
                label={lang.inviteYourStaff}
                done={onboardingStatus.invitedStaff}
                path={`${Path.STAFF}`}
              />
              <LabelItem
                label={lang.downloadAndSync}
                done={onboardingStatus.loggedInApp}
                link={`${"https://play.google.com/store/apps/details?id=com.pouchnation.venue"}`}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        // onMouseEnter={() => setHover(true)}
        // onMouseLeave={() => setHover(false)}
        className={`${expand ? "h-1/2" : "h-full"} cursor-pointer w-full absolute`}
        onClick={() => setExpand(!expand)}
      ></div>

      <div className={`absolute right-0 top-0 mt-lg mr-md`}>
        {
          <Icon
            className={"cursor-pointer"}
            name={!expand ? "arrow-right" : "arrow-down"}
            fontSize="24px"
            onClick={() => setExpand(!expand)}
            type={StyleType.Info}
            color="text-black sm:text-white"
          />
        }
      </div>
    </Panel>
  );

  const OnboardingComplete = () => (
    <Panel className="relative grid grid-cols-4 onboarding-bg">
      <div className="col-span-3 sm:col-span-2">
        <Title lg>{lang.onboardingTitleCompleted}</Title>
        <div className="mt-sm">
          <Text className="text-md">{lang.onboardingDescriptionCompleted}</Text>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap mt-lg gap-md">
          <Button
            type={StyleType.Primary}
            onClick={() => goToLink({ link: "https://pages.pouchnation.com/formfactors", done: false })}
          >
            {lang.orderYourNFCTags}
          </Button>
          <Button
            type={StyleType.Secondary}
            onClick={() => goToLink({ link: "https://pages.pouchnation.com/ebook", done: false })}
          >
            {lang.getPouchVENUEeBook}
          </Button>
          {/* <Button type={styleType.Secondary} onClick={() => handleCloseBanner()}>
            <Text color="pelorous">{lang.done}</Text>
          </Button> */}
        </div>
      </div>
      <div className={`absolute right-0 top-0 mt-lg mr-md`}>
        <Icon
          name="remove"
          onClick={() => handleCloseBanner()}
          type={StyleType.Info}
          color="text-black sm:text-white"
        />
      </div>
    </Panel>
  );

  const OnboardingLoading = () => {
    return (
      <Panel className="relative grid grid-cols-2 onboarding-bg">
        <Skeleton single />
      </Panel>
    );
  };

  return loading ? (
    <OnboardingLoading />
  ) : isAllComplete ? (
    <OnboardingComplete />
  ) : (
    <OnboardingInProgress />
  );
};

export default DashboardOnboarding;
