const guestPaths = {
  SEARCH_GUEST: "search",
  GRAPH_ACTIVATED_GUEST: (venueId) => `graph/activated-guest/venue/${venueId}`,
  STAFF_WITH_GUEST_REGISTRATION: (venueId) => `staff/search/venue/${venueId}`,
  GUEST_CHECKIN_INFO: ({ guestId, venueId }) => `${guestId}/checkin-info?venueId=${venueId}`,
  GUEST_NOTE: "guest-note",
  GUEST_NOTE_ID: ({ noteId }) => `guest-note/${noteId}`,
  GUEST_TAG: ({ venueId, guestCheckinId, guestTagUid, useCount }) =>
    `guest-tag/${guestCheckinId}/checkin-info?venueId=${venueId}&guestTagUid=${guestTagUid}&useCount=${useCount}`,
  GUEST_NOTES: ({ guestId, venueId }) => `guest-notes/${guestId}/?venueId=${venueId}`,
};

export default guestPaths;
