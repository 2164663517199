import { keyIndexToValueMapper } from "services";

export const questionResponse = {
  _keys: ["type", "guestProfileQuestions"],
  ...keyIndexToValueMapper([
    ["name", "question"],
    ["id", "questionId"],
    ["isPrecreated", "precreated"],
  ]),
  numberOfOptions: {
    transform: ({ src }) => {
      return src?.options?.length || null;
    },
  },
  options: {
    transform: ({ src }) => {
      if (src?.options) {
        return src?.options.map((option) => {
          return {
            id: option.questionOptionId,
            value: option.option,
            sort: option.sort,
          };
        });
      }
      return [];
    },
  },
};

export const questionRequest = {
  _keys: ["options", "type", "venueId"],
  ...keyIndexToValueMapper([
    ["question", "name"],
    ["questionId", "id"],
  ]),
};
