const AppCategory = {
  AccountingSoftware: "ACCOUNTING_SOFTWARE",
  Distribution: "DISTRIBUTION",
  InventoryManagement: "INVENTORY_MANAGEMENT",
  PointOfSale: "POINT_OF_SALE",
  PropertyManagementSystem: "PROPERTY_MANAGEMENT_SYSTEM",
  Others: "OTHERS",
};

export default Object.freeze(AppCategory);
