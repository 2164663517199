import React, { useEffect, useMemo, useState } from "react";
import { getGuestTag } from "apis";
import { Select, Title, Text, Icon, Pill, Panel, PageError } from "components/commons";
import { DateTime, PillType, TagStatus } from "enums";
import { useApi, useForm } from "hooks";
import { guestTagInfo } from "mappers/guest.mapper";
import lang from "translations";
import classnames from "classnames";
import { Divider, Skeleton } from "antd";
import { formatDate, formatNumberToOrdinal, formatTagUIds as formatUid, pluralize } from "services";
import { Field as FieldType } from "enums";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { useCallback } from "react";

const GuestTagInfo = ({
  venueId,
  mappedGuestCheckin,
  selectedGuestCheckinId: guestCheckinId,
  error,
  loading,
}) => {
  const [isCreditDetailsVisible, setIsCreditDetailsVisible] = useState(false);
  const [isVoucherDetailsVisible, setIsVoucherDetailsVisible] = useState(false);
  const [isTagInfoVisible, setIsTagInfoVisible] = useState(false);
  const {
    guestTagUids = [],
    checkedIn,
    lastSyncedDate,
    pairedTagOnCheckin: guestTagUid,
    useCountOfPairedTag: useCount,
  } = mappedGuestCheckin || {};

  const {
    request: requestGuestTag,
    loading: loadingGuestTag,
    mappedData,
  } = useApi({
    api: getGuestTag,
    params: {
      venueId,
    },
    mapper: guestTagInfo,
    handleOwnError: true,
  });

  const [selectedGuestTagUid, setSelectedGuestTagUid] = useState(guestTagUid);

  useEffect(() => {
    if (guestCheckinId && guestTagUid && useCount && !loading) {
      requestGuestTag({
        guestCheckinId,
        guestTagUid,
        useCount,
      });
      setIsTagInfoVisible(false);
      setIsVoucherDetailsVisible(false);
      setIsCreditDetailsVisible(false);
    }
    if (guestTagUids) {
      setSelectedGuestTagUid(guestTagUid);
    }
    // eslint-disable-next-line
  }, [guestCheckinId, guestTagUids, guestTagUid, useCount, loading]);

  const {
    banDate,
    banReason,
    bannedBy,
    credit,
    device,
    forcedCheckedOutBy,
    forcedCheckoutReason,
    freeCredit,
    issuedBy,
    issuedOn,
    status,
    tagUid,
    useCount: useCountTag,
    vouchers = [],
    checkedOutBy,
    dateOfCheckout,
    deviceNameCheckout,
    creditBalance,
    postpaidAvailable,
    paidPostpaid,
  } = mappedData;

  const divider = (
    <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
  );

  const guestTagStatus = useMemo(() => {
    return (
      <div className="flex gap-x-3 items-center">
        <div
          className={classnames("h-2 w-2 rounded-lg", {
            "bg-white-darkest": status === TagStatus.FORCED || TagStatus.CHECKED_OUT,
            "bg-green-darker": status === TagStatus.ACTIVE,
            "bg-red": status === TagStatus.BANNED,
          })}
        ></div>
        <Text fontMono color="text-pelorous" size="text-md">
          {formatUid(tagUid)}
        </Text>
        <Pill size="text-xs" type={checkedIn ? PillType.Blueish : PillType.White}>
          {`${useCountTag} use`}
        </Pill>
      </div>
    );
  }, [status, tagUid, useCountTag, checkedIn]);

  const initialState = useMemo(() => {
    return {
      pairedTagUid: {
        name: "pairedTagUid",
        value: guestTagStatus,
        type: FieldType.DROPDOWN,
        dirty: false,
      },
    };
  }, [guestTagStatus]);

  const { fields } = useForm({
    initialState,
  });

  const formatNumberWithCurrency = (number) => {
    if (number < 0) {
      let numberString = number.toString();
      return `${numberString[0]}${formatNumberToMoneyWithCurrencySymbol(
        numberString.slice(1, numberString?.length)
      )}`;
    }
    return formatNumberToMoneyWithCurrencySymbol(number);
  };

  const creditBalanceInfo = useMemo(() => {
    return (
      <div>
        <div className="flex items-center justify-between">
          <div>
            <Title className="pb-sm flex items-center">{lang.creditBalance}</Title>
            <Text
              size="sm:text-xl"
              fontWeight="font-medium"
              breakAll
              color={creditBalance < 0 ? "text-red" : "text-black"}
            >
              {formatNumberWithCurrency(creditBalance)}
            </Text>
          </div>
          <div>
            <Icon
              className="text-xl"
              color="text-blue-400"
              name={isCreditDetailsVisible ? "arrow-down" : "arrow-right"}
              onClick={() => setIsCreditDetailsVisible(!isCreditDetailsVisible)}
            />
          </div>
        </div>
        {isCreditDetailsVisible && (
          <div className="py-4">
            <div className="flex justify-between items-center">
              <Text>{lang.credits}</Text>
              <Text className="ml-lg sm:text-lg" breakAll>
                {formatNumberToMoneyWithCurrencySymbol(credit)}
              </Text>
            </div>
            <Divider className="my-xs" />
            <div className="flex justify-between items-center">
              <Text>{lang.freeCredits}</Text>
              <Text className="ml-lg sm:text-lg">
                {formatNumberToMoneyWithCurrencySymbol(freeCredit)}
              </Text>
            </div>
            <Divider className="my-xs" />
            <div className="flex justify-between mt-sm">
              <div>
                <Text>{lang.postpaidCreditsUsed}</Text>
              </div>
              <div>
                <Text className="ml-lg text-right sm:text-lg">
                  {formatNumberToMoneyWithCurrencySymbol(paidPostpaid)}
                </Text>
                <Text label className="text-right">{`${formatNumberToMoneyWithCurrencySymbol(
                  postpaidAvailable ?? 0
                )} ${lang.available}`}</Text>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }, [credit, freeCredit, isCreditDetailsVisible, postpaidAvailable, creditBalance, paidPostpaid]);

  const vouchersInfo = useMemo(() => {
    let total = 0;
    vouchers?.forEach((voucher) => {
      total += voucher.quantity;
    });

    return (
      <div>
        <div className="flex items-center justify-between">
          <div>
            <Title className="pb-sm flex items-center">{lang.vouchers}</Title>
            <Text className="sm:text-xl" fontWeight="font-medium">
              {`${total} ${pluralize(total, lang.voucher, lang.vouchers)}`}
            </Text>
          </div>
          <div>
            <Icon
              className="text-xl"
              color={total !== 0 ? "text-blue-400" : "text-gray-lighter"}
              name={isVoucherDetailsVisible ? "arrow-down" : "arrow-right"}
              onClick={() =>
                total !== 0 ? setIsVoucherDetailsVisible(!isVoucherDetailsVisible) : null
              }
            />
          </div>
        </div>
        {isVoucherDetailsVisible && total !== 0 && (
          <div className="py-4">
            {vouchers && vouchers.length > 0 ? (
              <div>
                {vouchers.map((voucher, index) => {
                  return (
                    <div className="flex justify-between items-center py-sm" key={index}>
                      <Text>{voucher.voucherName}</Text>
                      <span className="text-sm">{voucher.quantity}</span>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }, [isVoucherDetailsVisible, vouchers]);

  const checkInDetails = useMemo(() => {
    return (
      <div>
        {!checkedIn && (
          <Text className="mt-md font-bold uppercase text-xs text-gray">{lang.checkInDetails}</Text>
        )}
        <div>
          <Text label className="mt-md font-bold">
            {lang.issuedBy}
          </Text>
          <Text className="text-sm break-all">{issuedBy}</Text>
        </div>
        <div>
          <Text label className="mt-md font-bold">
            {lang.issuedOn}
          </Text>
          <Text className="text-sm">{issuedOn}</Text>
        </div>
        <div>
          <Text label className="mt-md font-bold">
            {lang.deviceName}
          </Text>
          <Text className="text-sm break-all">{device}</Text>
        </div>
      </div>
    );
  }, [checkedIn, device, issuedOn, issuedBy]);

  const checkOutDetails = useMemo(() => {
    return (
      <div>
        <Text className="mt-md font-bold uppercase text-xs text-gray">{lang.checkOutDetails}</Text>
        <div>
          <Text label className="mt-md font-bold">
            {lang.checkedOutBy}
          </Text>
          <Text className="text-sm break-all">{checkedOutBy || "-"}</Text>
        </div>
        <div>
          <Text label className="mt-md font-bold">
            {lang.checkedOutOn}
          </Text>
          <Text className="text-sm">{dateOfCheckout || "-"}</Text>
        </div>
        <div>
          <Text label className="mt-md font-bold">
            {lang.deviceName}
          </Text>
          <Text className="text-sm break-all">{deviceNameCheckout}</Text>
        </div>
        {divider}
        {checkInDetails}
      </div>
    );
    // eslint-disable-next-line
  }, [checkInDetails, checkedOutBy, dateOfCheckout, deviceNameCheckout]);

  const forcedCheckOutDetails = useMemo(() => {
    return (
      <div>
        <Text className="mt-md font-bold uppercase text-xs text-gray">{lang.forceBanned}</Text>
        <div>
          <Text label className="mt-md font-bold">
            {lang.forcedCheckedOutBy}
          </Text>
          <Text className="text-sm break-all">{forcedCheckedOutBy}</Text>
        </div>
        <div>
          <Text label className="mt-md font-bold">
            {lang.checkedOutOn}
          </Text>
          <Text className="text-sm">{dateOfCheckout}</Text>
        </div>
        {forcedCheckoutReason && (
          <div>
            <Text label className="mt-md font-bold">
              {lang.reason}
            </Text>
            <Text className="text-sm">{forcedCheckoutReason}</Text>
          </div>
        )}
        {divider}
        {checkInDetails}
      </div>
    );
    // eslint-disable-next-line
  }, [checkInDetails, dateOfCheckout, forcedCheckedOutBy, forcedCheckoutReason]);

  const banDetails = useMemo(() => {
    return (
      <div>
        <Text className="mt-md font-bold uppercase text-xs text-gray">{lang.banDetails}</Text>
        <div>
          <Text label className="mt-md font-bold">
            {lang.bannedBy}
          </Text>
          <Text className="text-sm break-all">{bannedBy}</Text>
        </div>
        <div>
          <Text label className="mt-md font-bold">
            {lang.dateBanned}
          </Text>
          <Text className="text-sm">{banDate}</Text>
        </div>
        <div>
          <Text label className="mt-md font-bold">
            {lang.reason}
          </Text>
          <Text className="text-sm break-all">{banReason}</Text>
        </div>
        {divider}
        {checkInDetails}
      </div>
    );
    // eslint-disable-next-line
  }, [banDate, banReason, bannedBy, checkInDetails]);

  const customOptionContent = (tagUid, status, useCount) => (
    <div className="flex flex-row gap-x-5 items-center">
      <div
        className={classnames("h-2 w-2 rounded-lg self-center", {
          "bg-white-darkest": status === TagStatus.FORCED || TagStatus.CHECKED_OUT,
          "bg-green-darker": status === TagStatus.ACTIVE,
          "bg-red": status === TagStatus.BANNED,
        })}
      ></div>
      <span className="text-sm text-blue-400 font-bold flex flex-row gap-x-3 ">
        {tagUid}
        <Pill size="text-xs self-center" type={PillType.White}>
          {`${formatNumberToOrdinal(useCount)} use`}
        </Pill>
      </span>
    </div>
  );

  const tagsInfoDetails = useCallback(() => {
    if (status === TagStatus.BANNED) {
      return banDetails;
    } else if (status === TagStatus.CHECKED_OUT) {
      return checkOutDetails;
    } else if (status === TagStatus.FORCED) {
      return forcedCheckOutDetails;
    } else if (status === TagStatus.ACTIVE) {
      return checkInDetails;
    }
  }, [banDetails, checkOutDetails, forcedCheckOutDetails, checkInDetails, status]);

  const tagsInfo = useMemo(() => {
    return (
      <div>
        <Title className="pb-sm flex justify-between items-center">
          {lang.tagInfo}
          <Icon
            className="text-xl float-right"
            color="text-blue-400"
            name={isTagInfoVisible ? "arrow-down" : "arrow-right"}
            onClick={() => setIsTagInfoVisible(!isTagInfoVisible)}
          />
        </Title>
        {isTagInfoVisible && tagsInfoDetails()}
      </div>
    );
    // eslint-disable-next-line
  }, [isTagInfoVisible]);

  const tagSelectOptions = guestTagUids.map((guestTagUid) => {
    return {
      value: guestTagUid.tagUid,
      text: formatUid(guestTagUid.tagUid),
      subvalue: guestTagUid.useCount,
      custom: customOptionContent(guestTagUid.tagUid, guestTagUid.status, guestTagUid.useCount),
    };
  });

  if (error) {
    return <PageError />;
  }

  return (
    <Panel className="mt-md">
      {!loadingGuestTag ? (
        <>
          <div>
            <Title className="pb-sm">{lang.pairedTags}</Title>
            <Select
              {...fields.pairedTagUid}
              options={tagSelectOptions}
              loading={loadingGuestTag}
              // value={guestTagStatus}
              onChange={(name, { value, option }) => {
                const useCount = option.subvalue;
                const guestTagUid = value;
                requestGuestTag({ guestCheckinId, guestTagUid, useCount });
                setSelectedGuestTagUid(guestTagUid);
              }}
              placeholder={selectedGuestTagUid}
              value={selectedGuestTagUid}
              disabled={guestTagUids.length <= 1 ? true : false}
            ></Select>
            <Text label className="mt-3.5">
              <lang.Translate
                text={lang.lastSynced}
                items={[lastSyncedDate ? formatDate(lastSyncedDate, DateTime.H) : "-"]}
              ></lang.Translate>
            </Text>
          </div>
          {divider}
          <div className="mt-md">{creditBalanceInfo}</div>
          {divider}
          <div className="mt-md">{vouchersInfo}</div>
          {divider}
          <div className="mt-md">{tagsInfo}</div>
        </>
      ) : (
        <Skeleton />
      )}
    </Panel>
  );
};

export default GuestTagInfo;
