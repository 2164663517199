import moment from "moment";

export const voucherMovementFilterState = (venueId) => ({
  venueId,
  page: 1,
  pageSize: 20,
  searchKey: "",
  // dateRange: [moment().startOf("month"), moment().endOf("day")],
  dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
  locations: [],
  staffs: [],
  voucherTypes: [],
  tapStatus: null,
  sort: { key: "dateCreated", value: "desc" },
});
