import React, { useEffect } from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

const Column = ({ lastRow, id, column, row, colA, firstRow }) => {
  return (
    <>
      <td
        className={classnames(`align-top w-1/3 ${id}`, {
          "overflow-x-auto": lastRow,
          "overflow-hidden": !lastRow,
          "border-r-2": colA,
        })}
      >
        <div>
          {firstRow && (
            <div className="inline-flex border-b">
              {column.map((c) => {
                return (
                  <div
                    className={"p-md"}
                    style={{
                      width: c.width,
                    }}
                  >
                    {c.text}
                  </div>
                );
              })}
            </div>
          )}
          <div className={`inline-flex`}>
            {column.map((c) => {
              return (
                <div
                  className={"p-md"}
                  style={{
                    width: c.width,
                  }}
                >
                  {row[c.key]}
                </div>
              );
            })}
          </div>
        </div>
      </td>
    </>
  );
};

const Splists = ({ columns = [], data, divideAtIndex = 0 }) => {
  const c1 = uuidv4();
  const c2 = uuidv4();
  const columnA = columns.slice(0, divideAtIndex);
  const columnB = columns.slice(divideAtIndex, columns.length);

  useEffect(() => {
    setTimeout(() => {
      var a = document.getElementsByClassName(c1);
      var b = document.getElementsByClassName(c2);
      var aScroll = Array.prototype.filter.call(a, function () {
        return true;
      });
      var bScroll = Array.prototype.filter.call(b, function () {
        return true;
      });

      function scrollAllA(scrollLeft) {
        aScroll.forEach(function (element) {
          element.scrollLeft = scrollLeft;
        });
      }

      function scrollAllB(scrollLeft) {
        bScroll.forEach(function (element) {
          element.scrollLeft = scrollLeft;
        });
      }

      aScroll.forEach(function (element) {
        element.addEventListener("scroll", function (e) {
          scrollAllA(e.target.scrollLeft);
        });
      });

      bScroll.forEach(function (element) {
        element.addEventListener("scroll", function (e) {
          scrollAllB(e.target.scrollLeft);
        });
      });
    }, [100]);
  }, [c1, c2]);

  return (
    <div>
      <table className="table-fixed table w-full bg-white">
        <tbody>
          {data.map((row, index) => {
            const lastRow = index + 1 === data.length;
            return (
              <tr>
                <Column
                  column={columnA}
                  row={row}
                  lastRow={lastRow}
                  firstRow={!index}
                  id={c1}
                  colA
                />
                <Column column={columnB} row={row} lastRow={lastRow} firstRow={!index} id={c2} />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Splists;
