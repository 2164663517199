import { ActionButton, Image, Modal, Text, Title } from "components/commons";
import { Path } from "paths";
import React from "react";
import lang from "translations";

const NoLocationModal = ({ content, onClose, ...modal }) => {
  return (
    <Modal
      {...modal}
      noHeader
      noCloseButton
      actionContent={
        <ActionButton
          className="p-lg pt-0"
          primary={{
            onClick: () => {
              window.open(Path.LOCATION, "_blank");
            },
            text: lang.createALocation,
          }}
          secondary={{
            onClick: () => {
              modal.close();
              if (onClose) {
                onClose();
              }
            },
            text: lang.cancel,
          }}
        />
      }
    >
      <div className="flex p-lg pt-xl pb-0">
        <div className="w-6/12 pr-lg pb-0">
          <Image
            src={
              "https://pouch-inventory.s3.us-east-2.amazonaws.com/landing-image/no-locations.png"
            }
          />
        </div>
        <div className="w-6/12">
          <Title className="mb-md">{lang.looksLikeYouHaveNoLocationYet}</Title>
          <Text>{content}</Text>
        </div>
      </div>
    </Modal>
  );
};

export default NoLocationModal;
