import { Field } from "enums";

const initialFormState = (initialState = {}) => {
  const { headerSettings = [], footerSettings = [], salesSettings = [] } = initialState;
  return {
    headerSettings: {
      name: "headerSettings",
      type: Field.ANY,
      required: true,
      value: headerSettings,
    },
    footerSettings: {
      name: "footerSettings",
      type: Field.ANY,
      required: true,
      value: footerSettings,
    },
    salesSettings: {
      name: "salesSettings",
      type: Field.ANY,
      required: true,
      value: salesSettings,
    },
  };
};

export default initialFormState;
