import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { formatNumberToShortUnit } from "services";
// const chartColors = [
//   "#ffc107",
//   "#ffecb3",
//   "#ffe082",
//   "#ffd54f",
//   "#ffca28",
//   "#ffb300",
//   "#ffa000",
//   "#ff8f00",
//   "#ff6f00",
//   "#fff8e1",
// ];

const MultiLineChart = ({
  labels,
  data = [],
  lineTension = 0,
  formatYAxis,
  checkBeginAtZero,
  formatToolTipValue,
  height,
  position,
  align,
  chartColors = [],
  isLegendPointStyle,
  legendBoxWidth = 20,
}) => {
  const datasets = useMemo(() => {
    if (data.length) {
      return data.map((d, index) => {
        return {
          data: d.data,
          lineTension,
          pointBackgroundColor: chartColors[index],
          // eslint-disable-next-line
          borderColor: chartColors[index],
          fill: false,
          label: d.label,
        };
      });
    }
    return [];
    // eslint-disable-next-line
  }, [data, lineTension]);
  return (
    <div>
      <Line
        height={height}
        data={{
          labels,
          datasets,
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            fontSize: 20,
          },
          legend: {
            display: true,
            position: position,
            align: align,
            labels: {
              usePointStyle: isLegendPointStyle ? true : false,
              boxWidth: legendBoxWidth,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: checkBeginAtZero ? true : false,
                  precision: 0,
                  callback: function (label, index, labels) {
                    if (formatYAxis) {
                      return formatYAxis(label, index, labels);
                    }
                    return formatNumberToShortUnit(Number(label));
                  },
                },
              },
            ],
          },
          tooltips: {
            displayColors: false,
            bodyAlign: "center",
            titleAlign: "center",
            bodySpacing: 3,
            bodyFontSize: 15,
            titleFontSize: 12,
            xPadding: 12,
            yPadding: 12,
            callbacks: {
              label: (l) => {
                if (formatToolTipValue) {
                  return `${datasets[l.datasetIndex].label} - ${formatToolTipValue(l)}`;
                }
                return l.value;
              },
            },
          },
        }}
      />
    </div>
  );
};

export default MultiLineChart;
