import React from "react";
import { Icon, Panel } from "..";
import classNames from "classnames";

const InfoPanel = ({ className, icon = "info", children, ...props }) => {
  return (
    <Panel className={classNames(className, "bg-blue-accent py-md px-md mt-md")} {...props}>
      <div className="flex">
        <div>
          <Icon name={icon} className="text-blue" />
        </div>
        <div className="ml-xs text-sm">{children}</div>
      </div>
    </Panel>
  );
};

export default InfoPanel;
