import lang from "translations";

const columns = [
  {
    text: lang.transaction,
    key: "recentTransactionId",
    className: "p-md",
    headerClassName: "pl-md font-bold",
  },
  {
    text: lang.type,
    key: "type",
    className: "p-md",
    headerClassName: "pl-md font-bold",
  },
  {
    text: lang.location,
    key: "location",
    className: "p-md",
    headerClassName: "pl-md font-bold",
  },
  {
    text: lang.date,
    key: "date",
    className: "p-md",
    headerClassName: "pl-md font-bold",
  },
  {
    text: lang.items,
    key: "items",
    className: "p-md ",
    headerClassName: "pl-md font-bold",
  },
  {
    text: lang.qty,
    key: "qty",
    className: "p-md",
    headerClassName: "pl-md font-bold",
  },
  {
    text: lang.amount,
    key: "amount",
    className: "text-right p-md",
    headerClassName: "text-right pr-md font-bold",
  },
];

export default columns;
