import { searchLocation } from "apis";
import { Field, Filter, MultipleSelect, RangePicker, Text, TimePicker } from "components/commons";
import { useApi, useMount } from "hooks";
import { allLocationRequest, location } from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect } from "services";
import lang from "translations";

const StocktakeFilter = ({ filterState, requestState, modifyFilter, clearFilter, onApply }) => {
  const {
    request: searchLocationRequest,
    loading: loadingLocations,
    mappedData: locations,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
    handleOwnError: true,
  });

  useMount(() => {
    fetchLocationRequest(requestState);
  });

  const fetchLocationRequest = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, allLocationRequest));
    },
    [searchLocationRequest]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      onApply(searchKey);
    },
    [onApply]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  const locationOptions = useMemo(() => {
    let sorted = locations
      .map((item) => {
        item.name = item.name.trim();
        return item;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let mapped = mapObjectsToSelect(sorted, { textKey: "name", valueKey: "id" });
    return mapped;
  }, [locations]);

  const statusOptions = useMemo(() => {
    return [
      {
        text: lang.inProgress,
        value: "IN_PROGRESS",
      },
      {
        text: lang.forApproval,
        value: "FOR_APPROVAL",
      },
      {
        text: lang.completed,
        value: "COMPLETED",
      },
      {
        text: lang.void,
        value: "VOID",
      },
    ];
  }, []);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      const { requestState } = modifyFilter(name, { value });
      fetchLocationRequest(requestState);
    },
    [fetchLocationRequest, modifyFilter]
  );

  return (
    <Filter
      placeholder={lang.searchForStocktakeId}
      className="mb-lg"
      onApply={applyFilterCb}
      onClear={clearFilterCb}
      filterState={filterState}
      actionsSpan={12}
    >
      <Field className="col-span-3" filterLabel={lang.dateRange}>
        <RangePicker name="dateRange" onChange={changeDateRangeCb} value={filterState.dateRange} />
      </Field>
      <Field className="col-span-3" filterLabel={lang.timeRange}>
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            disabled={!filterState.dateRange[0]}
            onChange={(startTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0].set({
                    hour: startTime.get("hour"),
                    minute: startTime.get("minute"),
                  }),
                  filterState.dateRange[1],
                ],
              });
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            {lang.to}
          </Text>
          <TimePicker
            disabled={!filterState.dateRange[0]}
            value={filterState.dateRange[1]}
            onChange={(endTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0],
                  filterState.dateRange[1].set({
                    hour: endTime.get("hour"),
                    minute: endTime.get("minute"),
                  }),
                ],
              });
            }}
          />
        </div>
      </Field>
      <Field className="col-span-3" filterLabel={lang.location}>
        <MultipleSelect
          name="locations"
          selectAllText={lang.allLocations}
          loading={loadingLocations}
          options={locationOptions}
          value={filterState.locations}
          isAll={filterState.locations && filterState.locations.length === 0}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={!locationOptions.length ? lang.noLocationAvailable : lang.selectLocation}
        />
      </Field>
      <Field className="col-span-3" filterLabel={lang.status}>
        <MultipleSelect
          name="status"
          defaultAll={true}
          selectAllText={lang.allStatus}
          isAll={filterState.status && filterState.status.length === 0}
          options={statusOptions}
          value={filterState.status}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={lang.selectStockLevel}
        />
      </Field>
    </Filter>
  );
};

export default StocktakeFilter;
