import lang from "translations";

const columns = [
  {
    key: "transactionId",
    text: lang.transaction,
    custom: true,
    width: "w-1/16",
  },
  {
    key: "dateCreated",
    text: lang.date,
    custom: true,
    width: "w-3/16",
    sort: true,
  },
  {
    key: "voucher",
    text: lang.voucher,
    width: "w-3/16",
  },
  {
    key: "quantity",
    text: lang.qty,
    width: "w-1/16",
  },
  {
    key: "guest",
    text: lang.guest,
    custom: true,
    width: "w-3/16",
  },
  {
    key: "locationName",
    text: lang.location,
    width: "w-3/16",
  },
  {
    key: "staffName",
    text: lang.staff,
    width: "w-3/16",
  },
  {
    key: "voucherType",
    text: lang.voucherType,
    custom: true,
    width: "w-2/16",
  },
];

export default columns;
