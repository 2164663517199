export const productFilterState = (venueId) => ({
  venueId,
  page: 1,
  pageSize: 20,
  searchKey: "",
  categories: [],
  locations: [],
  productStockLevels: [],
  posStatus: null,
  sort: { key: "dateCreated", value: "desc" },
});
