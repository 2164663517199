import React, { useContext } from "react";
import { Text, Icon } from "components/commons";
import lang from "translations";
import { openInNewTab } from "services/url.service";
import Dropdown from "../dropdown/dropdown";
import classnames from "classnames";
import styles from "./support-link-dropdown.module.scss";
import SupportLinkModal from "./support-link-form/support-link.modal";
import { useModal } from "hooks";
import { VenueContext } from "contexts";

const SupportLinkDropdown = () => {
  const supportLinkModal = useModal();

  const { venue } = useContext(VenueContext);
  const {
    currencyCode,
    // countryName
  } = venue;
  const SEACurrencies = ["BND", "KHR", "IDR", "LAK", "MYR", "MMK", "PHP", "SGD", "THB", "VND"];

  const isInSoutheastAsia = () => {
    if (SEACurrencies.includes(currencyCode)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dropdown
      trigger="click"
      id="support-link"
      classname={classnames(styles.container)}
      placement="bottomCenter"
      options={[
        {
          value: 0,
          text: (
            <div className="w-48">
              <div className="pt-1">
                <div className="my-xs cursor-default">
                  <Text color="text-pelorous-darkest ml-md" strong>
                    {lang.needHelp}
                  </Text>
                </div>
                <div
                  className="flex items-center py-2 hover:bg-gray-lightest"
                  onClick={() => {
                    openInNewTab("http://help.pouchnation.com/support/tickets/new");
                  }}
                >
                  <Icon name="new-ticket" className="text-lg text-gray-light ml-md" />
                  <Text color="text-gray-darkest" className="pl-2">
                    {lang.reportAProblem}
                  </Text>
                </div>
                <div
                  className="flex items-center py-2 hover:bg-gray-lightest"
                  onClick={() => {
                    if (isInSoutheastAsia()) {
                      openInNewTab(
                        "https://calendly.com/pouchnation-support/onboarding?mc_cid=3d3178fb03&month=2022-03"
                      );
                    } else {
                      supportLinkModal.show();
                    }
                  }}
                >
                  <Icon name="call" className="text-lg text-gray-light ml-md" />
                  <Text color="text-gray-darkest" className="pl-2">
                    {lang.scheduleACall}
                  </Text>
                </div>
                <SupportLinkModal {...supportLinkModal} />
                <div
                  className="flex items-center py-2 hover:bg-gray-lightest"
                  onClick={() => {
                    openInNewTab("http://help.pouchnation.com/support/home");
                  }}
                >
                  <Icon name="question-box" className="text-lg text-gray-light ml-md" />
                  <Text color="text-gray-darkest" className="pl-2">
                    {lang.helpCenter}
                  </Text>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    >
      <div className="cursor-pointer">
        <Icon name="question-box" className="text-md text-gray-dark hover:text-pelorous active:bg-pelorous focus:bg-pelorous" />
      </div>
    </Dropdown>
  );
};

export default SupportLinkDropdown;
