import numeral from "numeral";
import { parseMoneyToNumber } from "./money.service";
import { add, divide, multiply, subtract } from "./number.service";

export const computeMarkUp = (supplyPrice, retailPrice) => {
  // if (!numeral(retailPrice).value() && numeral(supplyPrice).value()) {
  //   return "-100.00";
  // }
  if (!numeral(retailPrice).value() || !numeral(supplyPrice).value()) {
    return "0.00";
  }
  let markUp = divide(retailPrice, supplyPrice);
  markUp = subtract(markUp, 1);
  markUp = multiply(markUp, 100);
  return markUp;
};

export const computeTax = (value, taxPercentageValue) => {
  let taxPercentage = taxPercentageValue;
  const price = parseMoneyToNumber(value).value;
  taxPercentage = divide(taxPercentage, 100);
  taxPercentage = add(1, taxPercentage);
  let tax = divide(price, taxPercentage);
  tax = subtract(price, tax);
  return tax;
};

export const computeRetailPrice = (supplyPrice, markUp) => {
  if (!numeral(markUp).value() || !numeral(supplyPrice).value()) {
    return "0";
  }
  const v = parseMoneyToNumber(markUp).value;
  const sc = parseMoneyToNumber(supplyPrice).value;
  const muV = multiply(sc, divide(v, 100));
  const rp = add(sc, muV);
  if (parseInt(rp) < 0) {
    return 0;
  }
  return rp;
};

export const computeStocktakeItemAmount = (
  pricePerOrderQty = 0,
  orderQty = 0,
  actualStockQty = null,
  expectedStockQty = 0
) => {
  let amount = subtract(
    (pricePerOrderQty / orderQty) * actualStockQty,
    (pricePerOrderQty / orderQty) * expectedStockQty
  );

  if (Number.isNaN(amount)) {
    return 0;
  }

  return amount;
};
