import React, { useMemo } from "react";
import {
  Panel,
  Field,
  Input,
  Skeleton,
  MultipleSelect,
  FieldWithTitle,
  Switch,
  ButtonLink,
  // Select,
  // Text,
} from "components/commons";
import { FragmentA } from "components/fragments";
import { StaffRole } from "enums";
import lang from "translations";
import { sortByKeyName } from "services";
import { Path } from "paths";

const StaffFormFields = ({
  fields,
  formTitle,
  modifyField,
  loading,
  requestPaymentMethod,
  requestLocations,
  // requestStaffProfile,
  modifyForm,
  emailBottomRender,
  basicInfoRender,
  isAwait,
  isEdit,
  noLocationModal,
  setPristine,
  dirty,
}) => {
  const roles = useMemo(() => {
    const role = fields.role.value;
    if (!role) {
      return [];
    }
    if (role && fields.role.isAll) {
      return [
        StaffRole.TopupCredits,
        StaffRole.RegisterGuests,
        StaffRole.SellOnPos,
        StaffRole.StockManager,
      ];
    }
    return role.map((r) => {
      return r.value;
    });
  }, [fields.role]);

  return (
    <>
      {!isAwait && isEdit && !loading && (
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <FieldWithTitle {...fields.isActive}>
              <Switch {...fields.isActive} onChange={modifyField} />
            </FieldWithTitle>
            {basicInfoRender}
          </Panel>
        </FragmentA>
      )}
      <FragmentA title={formTitle}>
        {loading ? (
          <Panel>
            <Skeleton />
          </Panel>
        ) : (
          <Panel>
            {((isAwait && isEdit) || !isEdit) && (
              <Field {...fields.email}>
                <Input
                  {...fields.email}
                  onChange={modifyField}
                  disabled={isAwait || requestPaymentMethod.loading || requestLocations.loading}
                />
                {emailBottomRender}
              </Field>
            )}
            {/* <Field {...fields.staffProfile} className="mt-sm">
              <Select
                {...fields.staffProfile}
                options={staffProfileOptions}
                onChange={(name, { value }) => {
                  modifyField(name, { value: value });
                }}
                optionClassName="py-xs"
                placeholder={
                  <Text className="text-black text-opacity-50">{lang.assignAProfile}</Text>
                }
              />
            </Field> */}
            <Field {...fields.role} className="mt-sm">
              <MultipleSelect
                {...fields.role}
                total={fields.role.options.length + 1}
                disabled={isAwait}
                onChange={async (name, { value, isAll }) => {
                  let isManager = false;
                  let hasPM = false;
                  let hasLocation = false;

                  if (value) {
                    const v = value.map((r) => {
                      return r.value;
                    });
                    isManager = !v.length;

                    if (
                      isManager ||
                      v.includes(StaffRole.TopupCredits) ||
                      v.includes(StaffRole.RegisterGuests)
                      // || v.includes(StaffRole.ReturnCredits)
                    ) {
                      requestPaymentMethod.request();
                      hasPM = true;
                    }
                    if (
                      isManager ||
                      v.includes(StaffRole.SellOnPos) ||
                      v.includes(StaffRole.StockManager)
                    ) {
                      const d = await requestLocations.request();
                      if (d.data && !d.data.length && !isManager) {
                        noLocationModal.show();
                      }
                      hasLocation = Boolean(isManager || (d.data && d.data.length));
                    }
                  } else {
                    isManager = true;
                    hasLocation = true;
                  }

                  if (value == null) {
                    value = [];
                    isAll = true;
                  }

                  modifyForm({
                    [name]: {
                      value,
                      isAll,
                    },
                    paymentMethod: {
                      value: isManager || !hasPM ? [] : fields.paymentMethod.value,
                      disabled: isManager || !hasPM,
                      isAll: true,
                    },
                    location: {
                      value: isManager || !hasLocation ? [] : fields.location.value,
                      disabled: isManager || !hasLocation,
                      isAll: hasLocation,
                    },
                  });
                }}
              />
            </Field>
            {(roles.includes(StaffRole.TopupCredits) ||
              roles.includes(StaffRole.RegisterGuests) ||
              // roles.includes(StaffRole.ReturnCredits) ||
              roles.length === 0) && (
              <Field
                className="mt-sm"
                {...fields.paymentMethod}
                labelSuffix={
                  <ButtonLink
                    size="text-xs"
                    newTabPath={Path.PAYMENT_METHOD_SETTING}
                    suffix
                    icon="arrow-diagonal-right"
                  >
                    {lang.editPaymentMethods}
                  </ButtonLink>
                }
              >
                <MultipleSelect
                  {...fields.paymentMethod}
                  onClick={() => {
                    if (!fields.paymentMethod.disabled) {
                      requestPaymentMethod.request();
                    }
                  }}
                  onChange={(name, obj) => {
                    let isDirty = dirty;
                    modifyField(name, { value: obj.value });
                    if (obj.isAll && !isDirty) {
                      setPristine();
                    }
                  }}
                  loading={requestPaymentMethod.loading}
                  options={requestPaymentMethod.mappedData.sort(sortByKeyName("text"))}
                />
              </Field>
            )}
            {(roles.includes(StaffRole.StockManager) ||
              roles.includes(StaffRole.SellOnPos) ||
              roles.length === 0) && (
              <Field
                className="mt-sm"
                {...fields.location}
                labelSuffix={
                  <ButtonLink
                    size="text-xs"
                    newTabPath={Path.LOCATION}
                    suffix
                    icon="arrow-diagonal-right"
                  >
                    {lang.editLocations}
                  </ButtonLink>
                }
              >
                <MultipleSelect
                  {...fields.location}
                  onClick={() => {
                    if (!fields.location.disabled) {
                      requestLocations.request();
                    }
                  }}
                  onChange={(name, obj) => {
                    let isDirty = dirty;
                    modifyField(name, { value: obj.value });
                    if (obj.isAll && !isDirty) {
                      setPristine();
                    }
                  }}
                  loading={requestLocations.loading}
                  options={requestLocations.mappedData.sort(sortByKeyName("text"))}
                />
              </Field>
            )}
          </Panel>
        )}
      </FragmentA>
    </>
  );
};

export default StaffFormFields;
