import React, { useContext } from "react";
import { Dropdown, Text, Avatar } from "..";
import { ProfileContext } from "contexts";
import styles from "./user-nav.module.scss";
import classnames from "classnames";
import { reloadPage } from "services";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";

const UserNavB = () => {
  const { profile, loading } = useContext(ProfileContext) || {};
  const { firstName, lastName, fullName, email, avatar: profileAvatar } = profile || {};

  const avatar = {
    className: "m-auto",
    phrases: [firstName, lastName],
    loading,
    image: profileAvatar,
  };

  return (
    <Dropdown
      trigger="click"
      id="user-nav"
      placement="bottomRight"
      className={classnames(styles.container, "h-full mr-lg")}
      options={[
        {
          value: "0",
          text: (
            <div className="w-72">
              <div className={classnames(styles.borderBottom, "p-lg text-center")}>
                <div className="text-center mb-md">
                  <Avatar {...avatar} size={14} width="w-14" height="h-14" />
                </div>
                <div>
                  <Text color="text-gray-600" size="text-md" strong>
                    {fullName}
                  </Text>
                </div>
                <div>
                  <Text sm>{email}</Text>
                </div>
              </div>

              <div>
                <div
                  className="py-sm px-md hover:bg-gray-lightest"
                  onClick={() => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      button: "Logout",
                    });
                    mixpanel.reset();
                    localStorage.clear();
                    reloadPage();
                  }}
                >
                  <Text color="text-gray-600">{lang.logout}</Text>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    >
      <div className="flex items-center h-full px-lg">
        <div>
          <Avatar {...avatar} textSize="text-md" />
        </div>
      </div>
    </Dropdown>
  );
};

export default UserNavB;
