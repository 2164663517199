import { searchLocationWithTransaction, searchVoucherWithTransaction } from "apis";
import {
  Field,
  MultipleSelect,
  RangePicker,
  ReportFilter,
  Select,
  Text,
  TimePicker,
} from "components/commons";
import { useApi, useMount } from "hooks";
import {
  locationWithTransactionFilterResponse,
  locationWithTransactionFilterRequest,
  voucherWithTransactionFilterRequest,
  voucherWithTransactionFilterResponse,
} from "mappers";
import React, { useCallback, useMemo } from "react";
import { mapObject, mapObjectsToSelect, sortByKeyName } from "services";
import lang from "translations";

const VoucherReportFilter = ({
  filterState,
  requestState,
  modifyFilter,
  clearFilter,
  onClear,
  onApply,
}) => {
  const {
    request: searchLocationRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: searchLocationWithTransaction,
    isArray: true,
    mapper: locationWithTransactionFilterResponse,
  });

  const { request: searchVoucherRequest, loading: loadingVoucher, mappedData: vouchers } = useApi({
    api: searchVoucherWithTransaction,
    isArray: true,
    mapper: voucherWithTransactionFilterResponse,
  });

  useMount(() => {
    fetchLocations(requestState);
    fetchVouchers(requestState);
  });

  const fetchLocations = useCallback(
    (requestState) => {
      searchLocationRequest(mapObject({ ...requestState }, locationWithTransactionFilterRequest));
    },
    [searchLocationRequest]
  );

  const fetchVouchers = useCallback(
    (requestState) => {
      searchVoucherRequest(mapObject({ ...requestState }, voucherWithTransactionFilterRequest));
    },
    [searchVoucherRequest]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      onApply(searchKey);
    },
    [onApply]
  );

  const clearFilterCb = useCallback(() => {
    const { filterState, requestState } = clearFilter();
    onClear(filterState, requestState);
    fetchLocations(requestState);
    fetchVouchers(requestState);
  }, [clearFilter, onClear, fetchLocations, fetchVouchers]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      const { requestState } = modifyFilter(name, { value });
      fetchLocations(requestState);
      fetchVouchers(requestState);
    },
    [fetchLocations, fetchVouchers, modifyFilter]
  );

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const voucherOptions = useMemo(() => {
    return [
      { text: lang.allVouchers, value: null },
      ...mapObjectsToSelect(vouchers, { textKey: "name" }).sort(sortByKeyName("text")),
    ];
  }, [vouchers]);

  return (
    <div>
      <ReportFilter
        onClear={clearFilterCb}
        onApply={applyFilterCb}
        dateRange={filterState.dateRange}
        span={8}
      >
        <Field filterLabel={lang.dateRange} className="col-span-4">
          <RangePicker
            name="dateRange"
            onChange={changeDateRangeCb}
            value={filterState.dateRange}
          />
        </Field>
        <Field filterLabel={lang.timeRange} className="col-span-4">
          <div className="flex items-center">
            <TimePicker
              value={filterState.dateRange[0]}
              onChange={(startTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0].set({
                      hour: startTime.get("hour"),
                      minute: startTime.get("minute"),
                    }),
                    filterState.dateRange[1],
                  ],
                });
              }}
            />
            <Text className="mx-sm" color="text-black-light">
              to
            </Text>
            <TimePicker
              value={filterState.dateRange[1]}
              onChange={(endTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0],
                    filterState.dateRange[1].set({
                      hour: endTime.get("hour"),
                      minute: endTime.get("minute"),
                    }),
                  ],
                });
              }}
            />
          </div>
        </Field>
        <Field filterLabel={lang.location} className="col-span-4">
          <MultipleSelect
            name="locations"
            selectAllText={lang.allLocations}
            loading={loadingLocation}
            options={locationOptions}
            value={filterState.locations}
            onChange={(name, obj) => {
              modifyFilter(name, { value: obj.value });
            }}
            placeholder={!locationOptions.length ? lang.noLocationAvailable : lang.selectLocation}
            emptyPlaceHolder={lang.noLocationAvailable}
          />
        </Field>
        <Field filterLabel={lang.voucher} className="col-span-4">
          <Select
            name="voucher"
            loading={loadingVoucher}
            options={voucherOptions}
            value={voucherOptions.length <= 1 ? lang.noVoucherAvailable : filterState.voucher}
            onChange={modifyFilter}
            disabled={voucherOptions.length <= 1}
          />
        </Field>
      </ReportFilter>
    </div>
  );
};

export default VoucherReportFilter;
