import { Path } from "paths";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const VenueRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem("venueId") ? (
          <Component {...props} />
        ) : (
          <Redirect to={Path.VENUE_SELECT} />
        );
      }}
    />
  );
};

export default VenueRoute;
