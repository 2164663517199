const venuePaths = {
  VENUE: "",
  GET_VENUE: (venueId) => venueId,
  VENUE_CREDIT_SETTINGS: (id) => `${id}/credit-settings`,
  VENUE_WRISTBAND: (id) => `${id}/keep-wristband`,
  GET_RECEIPT_SETTINGS: (id) => `${id}/receipt-settings`,
  SUPPLY_ITEM_VENUE_ID: (venueId) => `${venueId}/supply-item`,
  GET_PABBLY_SSO_LINK: (venueId) => `${venueId}/pabbly-link`,
  GET_CHECKLIST_STATUS: (venueId) => `${venueId}/checklist`,
  CLOSE_BANNER: (venueId) => `${venueId}/close-banner`,
};

export default venuePaths;
