import { Modal, Text, AlertMessage } from "components/commons";
import { AlertType } from "enums";
import React from "react";
import lang from "translations";

const CreditsInfoModal = ({ active, ...props }) => {
  return (
    <Modal {...props} active={active} title={lang.learnMore}>
      <div className="pb-lg">
        <Text strong size="text-md" className="mb-sm">
          {lang.freeCredits}
        </Text>
        <p className="text-sm">
          {lang.freeCreditsAreCreditsThatAreFreeOfCharge}{" "}
          <strong className="text-sm">{lang.lowerCaseNoneRefundable}</strong> {lang.andWouldBe}{" "}
          <strong className="text-sm">{lang.prioritizedAndUsedFirst}</strong>
        </p>
        <Text strong size="text-md" className="mb-sm mt-md">
          {lang.postpaidCredits}
        </Text>
        <p className="text-sm">{lang.postPaidCreditsIsTheAmount}</p>

        <AlertMessage
          className="mt-md"
          customMessage={
            <p className="text-sm ml-sm">
              {lang.toAddMoreCreditsToATag}{" "}
              <strong className="text-sm">{lang.lowerCaseTopUpCredits}</strong>{" "}
              {lang.uponRegistration}
            </p>
          }
          type={AlertType.Warning}
          showIcon
        />
      </div>
    </Modal>
  );
};

export default CreditsInfoModal;
