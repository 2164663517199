import { DataTable, Icon, Text, Tooltip } from "components/commons";
import { React } from "react";
import columns from "./columns";
import lang from "translations";
import { useMount } from "hooks";
import { formatDate } from "services";
import { DateTime } from "enums";
import { mixpanel } from "mixpanel";

const BookingGuests = ({ data: bookingDetailsData, loading: loadingBookingDetails, error }) => {
  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.bookingGuests,
    });
  });

  return (
    <DataTable
      loading={loadingBookingDetails}
      error={error}
      total={bookingDetailsData?.customers?.length}
      title={lang.guestList}
      columns={columns}
      data={
        bookingDetailsData && bookingDetailsData.customers
          ? bookingDetailsData.customers.map((customer, index) => {
              const { name, checkedInAt, isOwner } = customer;
              return {
                name: (
                  <div>
                    <div className="flex items-center mt-sm mb-sm">
                      <Text>{name}</Text>
                      {isOwner && (
                        <Tooltip title={lang.headOfBooking} placement="top">
                          <div className="p-0 m-0 h-min flex items-start -mt-1">
                            <Icon name="star" color="text-pelorous" className="text-xxs" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                ),
                checkinDate: checkedInAt ? (
                  formatDate(checkedInAt, DateTime.I)
                ) : (
                  <Text color="text-gray">{lang.notCheckedIn}</Text>
                ),
              };
            })
          : []
      }
      minWidth="800px"
    />
  );
};

export default BookingGuests;
