import { Delete, Get, Post } from "services";
import { ApiPath } from "paths";

export const searchGuestProfile = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_GUEST_PROFILE}`, body);
  return res;
};

export const createGuestProfile = async (body) => {
  const res = await Post(`${ApiPath.GUEST_PROFILE}`, body);
  return res;
};

export const getGuestProfile = async ({ guestProfileId }) => {
  const res = await Get(`${ApiPath.GUEST_PROFILE_ID(guestProfileId)}`, {});
  return res;
};

export const editGuestProfile = async (body) => {
  const res = await Post(`${ApiPath.GUEST_PROFILE_ID(body.guestProfileId)}`, body);
  return res;
};

export const deleteGuestProfile = async (body) => {
  const res = await Delete(`${ApiPath.GUEST_PROFILE_ID(body.guestProfileId)}`, body);
  return res;
};
