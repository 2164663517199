import { getAccountMappings, getProduct } from "apis";
import { deleteProduct, updateProduct } from "apis/product.api";
import { Toast } from "components/commons";
import { VenueContext } from "contexts";
import { Field, ProductType } from "enums";
import { useApi, useMount, useRouter } from "hooks";
import { accountMappingsResponse } from "mappers/accounting.mapper";
import { productSetRequest } from "mappers/product.mapper";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo } from "react";
import { sortByKeyName, toAmount } from "services";
import { formatNumberToMoney } from "services/money.service";
import { computeMarkUp } from "services/product-price.service";
import Validation from "services/validation.service";
import lang from "translations";
import ProductSetForm from "../product-set-form/product-set-form";
import { CreateProductField } from "../product-set-form/product-set-form.state";
import { mixpanel, TrackEvent } from "mixpanel";

const EditProductSet = () => {
  const { venue } = useContext(VenueContext);
  const { query, history } = useRouter();
  const { id, revenueAccountCodeId, cogsAccountCodeId } = query;
  const { venueId } = venue || {};

  const { request, mappedData } = useApi({
    api: getProduct,
    params: {
      venueId,
      productId: id,
    },
    handleOwnError: {
      badrequest: true,
    },
    mapper: productSetRequest,
  });

  const { request: saveProduct, loading: submitting } = useApi({
    api: updateProduct,
    params: {
      productId: id,
      venueId,
      revenueAccountCodeId,
      cogsAccountCodeId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: deleteProductRequest } = useApi({
    api: deleteProduct,
  });

  const { request: accountCategories } = useApi({
    api: getAccountMappings,
    params: {
      businessId: venue.venueId,
    },
    isArray: true,
    mapper: accountMappingsResponse,
    handleOwnError: true,
  });

  useMount(async () => {
    fetchProduct();
    await accountCategories();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editProductSet,
    });
  });

  const fetchProduct = useCallback(async () => {
    await request();
  }, [request]);

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await saveProduct(params);
        return {
          response: res,
          message: lang.changesSaved,
        };
      } catch (e) {
        throw e;
      }
    },
    [saveProduct]
  );

  const goToList = useCallback(() => {
    history.push(Path.PRODUCT);
  }, [history]);

  const submitDeleteProductSet = useCallback(
    async (params, productName, close) => {
      try {
        await deleteProductRequest(params);
        close();
        goToList();
        Toast({
          content: lang.populate(lang.productSetDeleted, [productName]),
          success: true,
          icon: "check",
        }).open();
      } catch (e) {
        close();
        goToList();
        throw e;
      }
    },
    [goToList, deleteProductRequest]
  );

  const initialState = useMemo(() => {
    const {
      productLogo,
      productName,
      activePos,
      description,
      category = [],
      hasTax,
      tax,
      location = [],
      isActiveAllLocations,
      isActiveAllCategories,
      activeOnlineMenu = false,
      sku,
      products = [],
      retailPrice,
      productSkuId,
      revenueAccountCodeId,
      cogsAccountCodeId,
    } = mappedData;

    let supplyPrice = 0;
    products.forEach((product) => {
      let qty = product.quantity;
      let price = product.price;
      supplyPrice += qty * price;
    });

    let obj = {
      productName,
      productLogo,
      description,
      category: {
        isAll: isActiveAllCategories,
        value: category,
      },
      activePos,
      activeOnlineMenu,
      hasTax,
      tax,
      generatedSku: {
        value: false,
      },
      location: {
        isAll: isActiveAllLocations,
        value: location,
      },
      sku: {
        value: sku,
        required: true,
        validations: [Validation.required(), Validation.minlength(4)],
      },
      retailPrice,
      products: {
        isFormArray: true,
        type: Field.ANY,
        value: products.sort(sortByKeyName("productName")).map((product) => {
          return CreateProductField({
            productSkuId: {
              value: product.productSkuId,
            },
            itemName: {
              value: product.productName,
              error: false,
            },
            quantity: {
              value: product.quantity,
              symbol: product.type === ProductType.SupplyItem ? product.measurement?.unit : "pc",
            },
            supplyPrice: {
              value: formatNumberToMoney((product.price || 0) * product.quantity),
            },
            price: {
              value: product.price,
            },
            type: {
              value: product.type,
            },
          });
        }),
      },
      supplyCost: {
        value: formatNumberToMoney(supplyPrice),
      },
      markUp: {
        value:
          toAmount(supplyPrice) === toAmount(retailPrice)
            ? "0.00"
            : toAmount(computeMarkUp(supplyPrice, retailPrice)),
      },
      productSkuId,
      revenueAccountCodeId: {
        value: revenueAccountCodeId,
      },
      cogsAccountCodeId: { value: cogsAccountCodeId },
    };

    return obj;
  }, [mappedData]);

  return (
    <ProductSetForm
      title={lang.editProductSet}
      initialState={initialState}
      submitting={submitting}
      onSubmit={submitForm}
      onEdit={true}
      onDeleteProduct={submitDeleteProductSet}
      productSetId={id}
    />
  );
};

export default EditProductSet;
