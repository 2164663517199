import { ModuleWrapper, Pill, HeaderB } from "components";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useContext, useMemo } from "react";
import lang from "translations";
import { getShiftById, exportShiftEOD } from "apis/shift.api";
import { FragmentB } from "components/fragments";
import ShiftSummary from "./shift-summary";
import { viewShiftResponse } from "mappers";
import { Title, Panel, Text, Icon, Skeleton } from "components/commons";
import { v4 as uuidv4 } from "uuid";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";
import { PillType } from "enums";

const ViewShift = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue || {};
  const { query } = useRouter();
  const { id } = query || {};
  const shiftTitle = `#${id}`;

  const { request, loading, mappedData, error } = useApi({
    api: getShiftById,
    params: {
      shiftId: id,
    },
    mapper: viewShiftResponse,
  });

  const exportShiftEODRequest = useApi({
    api: exportShiftEOD,
    params: {
      shiftId: id,
      venueId,
    },
  });

  useMount(() => {
    request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.reportsInfoPage,
    });
  });

  const { staff = { roles: [] }, deviceInfo = {}, description, summary = {}, redirectUrl } = mappedData;
  const { totalTransactions = 0, hasTransactions, shiftEnd } = summary;

  const renderStaffDetails = useMemo(() => {
    if (staff.roles.length) {
      return (
        <div className="mt-md">
          <Text uppercase strong color="text-gray" size="text-xs">
            {lang.roles}
          </Text>
          {staff.roles.map((d) => {
            return (
              <Text className="mt-sm" key={uuidv4()}>
                {d}
              </Text>
            );
          })}
        </div>
      );
    }
    return null;
  }, [staff]);

  const deviceInfoDetails = useMemo(() => {
    const keys = [
      ["deviceName", lang.deviceName],
      ["deviceImei", lang.deviceImei],
      ["deviceMacAddress", lang.deviceMacAddress],
      ["deviceSerialNumber", lang.deviceSerialNumber],
      ["deviceLogId", 'Shift Device Log Id'],
      ["deviceLogUuid", 'Shift Device Log UUID'],
    ];
    const data = keys.map(([key, label]) => {
      return [deviceInfo?.[key] || "-", label];
    });

    return (
      <div className="mt-md">
        {data.map(([value, label], index) => {
          return (
            <div className="mt-md" key={uuidv4()}>
              <Text uppercase strong color="text-gray" size="text-xs">
                {label}
              </Text>
              <Text
                fontMono={index}
                size="text-sm"
                color="text-black"
              >
                {value}
              </Text>
            </div>
          );
        })}
      </div>
    );
  }, [deviceInfo]);

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          loading={loading}
          customTitle={
            <div className="flex items-center gap-2">
              <Title xl>{shiftTitle}</Title>
              {!shiftEnd && <Pill type={PillType.Green}>{lang.open}</Pill>}
            </div>
          }
          description={description}
          returnPath={Path.REGISTER_REPORT}
          returnText={lang.registerReports}
          button={
            shiftEnd
              ? {
                  iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
                  text: lang.exportXlsx,
                  loading: exportShiftEODRequest.loading,
                  disabled: loading || exportShiftEODRequest.loading,
                  onClick: () => {
                    exportShiftEODRequest.request();
                  },
                }
              : null
          }
          secondaryButton={
            hasTransactions
              ? {
                  disabled: loading,
                  iconPrefix: (
                    <Icon className="mr-sm" name="transactions" paddingless fontSize={12} />
                  ),
                  text: lang.populate(lang.viewNTransactions, [totalTransactions]),
                  onClick: () => {
                    window.open(redirectUrl);
                  },
                }
              : null
          }
        ></HeaderB>
      }
    >
      <FragmentB
        sideContent={
          <div>
            <Panel className="mb-md">
              <Title className="mb-md">{lang.staff}</Title>
              <div className="flex items-center">
                <div>
                  <Icon name="guest" className="text-pelorous mr-md" fontSize="25px" />
                </div>
                {loading ? (
                  <div className="w-full ">
                    <div className="mb-xs w-full">
                      <Skeleton single />
                    </div>
                    <Skeleton single />
                  </div>
                ) : (
                  <div>
                    <Text className="break-all">{staff.name}</Text>
                    <Text tagUid={staff.hasTagUid} italic={!staff.hasTagUid}>
                      {staff.tagUid}
                    </Text>
                  </div>
                )}
              </div>
              {loading ? null : renderStaffDetails}
            </Panel>
            <Panel>
              <Title className="mb-md">{lang.deviceInfo}</Title>
              {loading ? <Skeleton /> : deviceInfoDetails}
            </Panel>
          </div>
        }
      >
        <ShiftSummary data={mappedData} loading={loading} />
      </FragmentB>
    </ModuleWrapper>
  );
};

export default ViewShift;
