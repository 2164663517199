import { Button, Image, Panel, Text, Title } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { React } from "react";
import lang from "translations";
import { Subscription } from "images";
import { redirectTo } from "services";
import { environment } from "environments/environment";
import { useContext } from "react";
import { ProfileContext, VenueContext } from "contexts";
import { LayoutC } from "components/layouts";
import { Fragment } from "react";

const PendingPayment = () => {
  const { profile } = useContext(ProfileContext);
  const { registrationMark } = profile;
  const isEnterprise = registrationMark === "ENTERPRISE";
  const { venue } = useContext(VenueContext);

  console.log(profile.pabblyCustomerId);

  return (
    <LayoutC>
      <Fragment>
        <ModuleWrapper>
          <div className="flex justify-center center">
            <Panel className="mt-md max-h-full max-w-full md:max-w-1/2 rounded md:rounded-lg">
              <div className="p-xs md:p-md lg:p-xxl">
                <div className="flex justify-center center">
                  <Image className="w-48 md:max-w-xs" src={Subscription} />
                </div>
                <div className="flex justify-center center">
                  <Title xl>{lang.almostThere}</Title>
                </div>
                <div className="flex items-center text-center">
                  <Text size="text-md">{lang.completeYourSubscription + venue.venueName}</Text>
                </div>
                <div className="flex justify-center center">
                  <Button
                    className={"mt-md"}
                    onClick={() => {
                      let url = new URL(
                        `${
                          isEnterprise
                            ? environment.PABBLY_SUBSCRIPTION_WITHOUT_FREE_TRIAL
                            : environment.PABBLY_SUBSCRIPTION_WITH_FREE_TRIAL
                        }`
                      );

                      let parameters = new URLSearchParams(url.search.slice(1));
                      parameters.append(
                        "redirectUrl",
                        `${environment.POUCH_VENUES}redirect?redirectUrl=/create/venue`
                      );
                      parameters.append("clientId", "VENUE_DASHBOARD");
                      parameters.append("customer_id", `${profile.pabblyCustomerId}`);

                      redirectTo(`${url}?${parameters.toString()}`);
                    }}
                  >
                    {lang.completeSubscription}
                  </Button>
                </div>
              </div>
            </Panel>
          </div>
        </ModuleWrapper>
      </Fragment>
    </LayoutC>
  );
};

export default PendingPayment;
