import React from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { LineChart, BarStackChart } from "components/commons";

const CheckInsChart = ({ ...props }) => {
  const data = props.mappedData;
  return (
    <ChartContainer
      title={lang.checkIns}
      {...props}
      empty={!data.total?.value}
      emptyText={lang.noCheckInsInDateRange}
    >
      <BarStackChart
        data={[
          {
            label: lang.new,
            value: data.total?.value,
            color: "#FDC02F",
          },
        ]}
      />
      <LineChart {...data.data} />
    </ChartContainer>
  );
};

export default CheckInsChart;
