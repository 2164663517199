import React, { Fragment, useCallback, useMemo, useState } from "react";
import lang from "translations";
import PrinterForm from "../printer-form/printer-form";
import PrinterFormSkeleton from "../printer-form/printer-form-skeleton";
import { deletePrinter, editPrinter, getPrinter } from "apis";
import { searchProduct } from "apis";
import { mapObject, mapObjects } from "services";
import { printerUpdateRequest, printer } from "mappers";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { Path } from "paths";
import { DeleteModal, Toast } from "components";
import { mixpanel, TrackEvent } from "mixpanel";

const EditPrinter = () => {
  const [linkedProductsMap, setLinkedProductsMap] = useState([]);
  const [linkedProductsLoaded, setLinkedProductsLoaded] = useState(false);
  const { query, history } = useRouter();
  const { id } = query;

  const {
    request: getPrinterRequest,
    loading = true,
    result,
    error,
  } = useApi({
    api: getPrinter,
  });

  const data = useMemo(() => {
    if (result) {
      return mapObject(result, printer);
    }
  }, [result]);

  const { request: submit, loading: submitting } = useApi({
    api: editPrinter,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: deletePrinterRequest, loading: deleting } = useApi({
    api: deletePrinter,
  });

  const deleteModal = useModal();

  const { request: searchProductRequest, loading: loadingProduct } = useApi({
    api: searchProduct,
    isArray: true,
  });

  useMount(() => {
    fetchPrinter();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editPrinter,
    });
  });

  const fetchPrinter = useCallback(async () => {
    const data = await getPrinterRequest({ printerId: id });
    const { data: products } = await searchProductRequest({
      venueId: data.venueId,
      locationIds: data.locations.map((l) => l.locationId),
    });

    const productCategories = groupProductsByCategory(
      mapObjects(products, {
        id: { key: "productId" },
        name: { key: "productName" },
        categories: {
          transform: ({ self }) =>
            mapObjects(self, { id: { key: "categoryId" }, name: { key: "categoryName" } }),
        },
      })
    );

    const map = new Map();

    productCategories.forEach((pc) => {
      const productMap = new Map();

      data.printerProductSkus.forEach((pps) => {
        const { productId, productName, productSku } = pps;

        if (checkIfProductBelongsToCategory(productId, pc.id, products)) {
          if (productMap.has(productName)) {
            productMap.set(productName, [...productMap.get(productName), productSku.productSkuId]);
          } else {
            productMap.set(productName, [productSku.productSkuId]);
          }
        }
      });

      map.set(pc.name, productMap);
    });

    setLinkedProductsMap(map);
    setLinkedProductsLoaded(true);
  }, [getPrinterRequest, id, searchProductRequest]);

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await submit(mapObject({ ...params, id }, printerUpdateRequest));
        return {
          response: res,
          message: lang.populate(lang.printerProfileUpdated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [submit, id]
  );

  if (loading || error) {
    return (
      <PrinterFormSkeleton
        error={error}
        title={lang.editPrinter}
        loading={loading}
        path={Path.PRINTER}
      />
    );
  }

  return (
    <Fragment>
      <PrinterForm
        loading={loading || loadingProduct}
        submit={submitForm}
        title={lang.editPrinter}
        submitting={submitting}
        initialState={data}
        deleting={deleting}
        linkedProductsMap={linkedProductsMap}
        linkedProductsLoaded={linkedProductsLoaded}
        deletePrinterButton={{
          text: lang.deletePrinterProfile,
          onClick: () => {
            deleteModal.show({
              ok: async () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.deletePrinterProfile,
                  Page: lang.editPrinter,
                });
                await deletePrinterRequest({ printerId: result.printerId });
                Toast({
                  content: lang.populate(lang.printerProfileDeleted, [data.name]),
                  success: true,
                  icon: "check",
                }).open();
                history.push(Path.PRINTER);
                deleteModal.close();
              },
            });
          },
        }}
      />
      <DeleteModal {...deleteModal} subject={lang.printerProfile} subjectName={data.name} />
    </Fragment>
  );
};
const groupProductsByCategory = (products) => {
  const categoryMap = new Map();
  products.forEach((p) => p.categories.forEach((c) => categoryMap.set(c.id, c.name)));
  categoryMap.set(0, lang.uncategorized);
  return Array.from(categoryMap).map(([key, value]) => ({ id: key, name: value }));
};

const checkIfProductBelongsToCategory = (productId, categoryId, products) => {
  const product = products.find((p) => p.productId === productId);
  console.log(product);
  if (categoryId === 0) {
    return !product || !product?.categories || product?.categories.length === 0;
  } else {
    return product?.categories?.some((c) => c.categoryId === categoryId);
  }
};

export default EditPrinter;
