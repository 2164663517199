import lang from "translations";

const columns = [
  {
    key: "voucher",
    text: lang.voucherName,
    custom: true,
    width: "w-2/4",
  },
  {
    key: "status",
    text: lang.status,
    custom: true,
    width: "w-1/4",
  },
  {
    key: "dateCreated",
    text: lang.dateCreated,
    custom: true,
    width: "w-1/4",
    sort: true,
  },
];

export default columns;
