export const paymentMethodList = {
  id: {
    key: "paymentMethodId",
  },
  name: {
    key: "paymentMethodName",
  },
  icon: {
    key: "iconLink",
  },
  refundable: {
    key: "refundable",
  },
  isEditable: {
    key: "editable",
  },
  accountCodeId: {
    key: "accountCodeId",
  },
};

export const createPaymentMethodRequest = {
  paymentMethodName: {
    key: "name",
  },
  venueId: {
    key: "venueId",
  },
  editable: {
    key: "isEditable",
  },
  iconLink: {
    key: "icon",
  },
  refundable: {
    key: "refundable",
  },
  accountCodeId: {
    key: "accountCodeId",
  },
};

export const paymentMethodRequest = {
  ...createPaymentMethodRequest,
  paymentMethodId: {
    key: "id",
  },
};
