import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { FragmentA } from "components/fragments";
import {
  Field,
  Input,
  Panel,
  ActionButton,
  Toast,
  Form,
  Skeleton,
  Title,
  CheckboxField,
  Select,
  Text,
  InputMoney,
  InputCounter,
  Button,
} from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { useApi, useFilter, useForm, useModal, useMount, useRouter } from "hooks";
import { VenueContext } from "contexts";
import initialFormState from "./supply-item.form-state";
import { location } from "mappers";
import {
  searchLocation,
  getMewsIntegrationStatus,
  getAccountMappings,
  getAccountCodes,
} from "apis";
import en from "translations/en";
import { Divider } from "antd";
import { getMeasurements } from "apis/measurement.api";
import { measurement } from "mappers/measurement.mapper";
import { formatNumberToMoney, parseMoneyToNumber } from "services/money.service";
import { AccountName, StyleType } from "enums";
import DeleteSupplyItem from "../delete-supply-item/delete-supply-item.module";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber, toAmount } from "services";
import { mixpanel, TrackEvent } from "mixpanel";
import { accountingResponse } from "mappers/accounting.mapper";
import { groupAccountOptions, mapAccountOptions } from "services/accounting.service";

const SupplyItemForm = ({
  submit,
  title,
  submitting,
  deleteItemButton,
  loading,
  error,
  initialState = undefined,
  onEdit = false,
  stateReady = false,
}) => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;
  const [productUnitSymbol, setProductUnitSymbol] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const unsaveChangesModal = useModal();
  const deleteModal = useModal();

  const {
    request: locationRequest,
    loading: loadingLocation,
    // error: errorLocation,
    mappedData: mappedLocation,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
  });

  const { filterState } = useFilter({
    page: 1,
    pageSize: 2000,
    venueId,
  });

  const {
    request: measurementRequest,
    loading: loadingMeasurement,
    // error: errorLocation,
    mappedData: mappedMeasurements,
  } = useApi({
    api: getMeasurements,
    isArray: true,
    mapper: measurement,
    params: {
      venueId: venueId,
    },
  });

  const { request: mewsRequest } = useApi({
    api: getMewsIntegrationStatus,
    params: {
      venueId: venue?.venueId,
    },
    mapper: {
      id: { key: "id" },
      integrationType: { key: "integrationType" },
    },
  });

  const {
    request: accountRequest,
    mappedData,
    loading: accountLoading,
  } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venueId,
    },
    isArray: true,
    mapper: accountingResponse,
  });

  const { request: accountingCategoriesRequest, result: defaultAccountMapping } = useApi({
    api: getAccountMappings,
    params: {
      businessId: venue?.venueId,
    },
  });

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty } = useForm({
    initialState: formState,
  });

  const defaultAccount = defaultAccountMapping
    ? defaultAccountMapping?.data?.find((d) => d.itemType === AccountName.InventoryAccount)
    : null;

  const defaultAccountCodeId = defaultAccount ? defaultAccount?.accountCodeId : null;

  useMount(async () => {
    await measurementRequest();
    accountRequest();
    accountingCategoriesRequest();
    mewsRequest();
    const { data } = await locationRequest(filterState);
    if (!onEdit) {
      cleanUpLocations(data);
    }
  });

  const cleanUpLocations = useCallback(
    (data) => {
      const locationStocks = [];
      data.forEach((location) => {
        locationStocks.push({
          locationId: location.locationId,
          name: location.locationName,
          stock: toAmount(0),
          parLevel: toAmount(0),
          reorderPoint: toAmount(0),
        });
      });
      modifyField("productSku", {
        value: {
          sku: null,
          retailPrice: 0,
          supplyPrice: 0,
          parLevel: 0,
          reorderPoint: 0,
          locationStocks: locationStocks,
        },
        dirty: false,
      });
    },
    [modifyField]
  );

  const goToList = useCallback(() => {
    history.push(Path.INVENTORY_SUPPLY_ITEM);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();

    if (fields.automaticallyGenerateSku.value) {
      params.productSku.sku = null;
    } else {
      params.productSku.sku = fields.sku.value;
    }

    if (params.productSku.locationStocks) {
      params.productSku.locationStocks = params.productSku.locationStocks.map((locationStock) => {
        locationStock.stock = parseAmountToNumber(locationStock.stock);
        locationStock.parLevel = parseAmountToNumber(locationStock.parLevel);
        locationStock.reorderPoint = parseAmountToNumber(locationStock.reorderPoint);
        return locationStock;
      });
    }

    if (!params.pricePerOrderQuantity) {
      params.pricePerOrderQuantity = 0;
    }

    if (params.orderQuantity) {
      params.orderQuantity = parseAmountToNumber(params.orderQuantity);
    }

    params.pricePerOrderQuantity = parseMoneyToNumber(params.pricePerOrderQuantity).value;
    params.weightInGrams = parseAmountToNumber(params.weightInGrams);
    if (productUnitSymbol !== "pc") {
      params.weightInGrams = 0;
    }

    if (!params.supplyAccountCodeId && defaultAccountCodeId) {
      params.supplyAccountCodeId = defaultAccountCodeId;
    }

    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Page: "Supply Item",
        Button: lang.saveNewSupplyItemForm,
      });

      const res = await submit({ ...params, venueId });
      Toast({
        content: !onEdit ? res.message : lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.INVENTORY_SUPPLY_ITEM);
    } catch ({ code, handleError }) {
      const err = {
        3006: () => {
          applyFieldErrors({
            name: lang.supplyItemAlreadyExists,
          });
        },
        3001: () => {
          applyFieldErrors({
            sku: lang.skuAlreadyExists,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [
    submit,
    getFormValues,
    venueId,
    history,
    applyFieldErrors,
    fields.automaticallyGenerateSku.value,
    fields.sku.value,
    onEdit,
    productUnitSymbol,
    defaultAccountCodeId,
  ]);

  const submitFormValue = () => {
    submitForm(handleSubmit);
  };

  const totalInStock = useMemo(() => {
    let total = 0.0;
    fields.productSku.value.locationStocks.forEach((location) => {
      let val = (location.stock + "").trim();
      val = parseAmountToNumber(val);
      total += Number(val);
    });
    if (productUnitSymbol === "pc" || productUnitSymbol === "") {
      total = parseInt(total);
    } else {
      total = total.toFixed(2);
    }
    return isNaN(total) ? 0.0 : total;
    // formatNumberToMoney(total, true, productUnitSymbol === "pc" || productUnitSymbol === "");
  }, [fields.productSku, productUnitSymbol]);

  const totalParLevel = useMemo(() => {
    let total = 0.0;
    fields.productSku.value.locationStocks.forEach((location) => {
      let val = (location.parLevel + "").trim();
      val = parseAmountToNumber(val);
      total += Number(val);
    });
    return isNaN(total)
      ? 0.0
      : formatNumberToMoney(total, true, productUnitSymbol === "pc" || productUnitSymbol === "", 3);
  }, [fields.productSku, productUnitSymbol]);

  const totalReorderPoint = useMemo(() => {
    let total = 0.0;
    fields.productSku.value.locationStocks.forEach((location) => {
      let val = (location.reorderPoint + "").trim();
      val = parseAmountToNumber(val);
      total += Number(val);
    });
    return isNaN(total) ? 0.0 : toAmount(total, productUnitSymbol === "pc" ? "0,0" : "0,0.000");
  }, [fields.productSku, productUnitSymbol]);

  const measurementOptions = useMemo(() => {
    return mappedMeasurements.map((measurement) => {
      return { text: `${measurement.name} (${measurement.unit})`, value: measurement.id };
    });
  }, [mappedMeasurements]);

  const isStockValid = useCallback((value) => {
    value = parseAmountToNumber(value) || 0;
    return (
      ((Number(value) || value === "0" || !value) && Number(value) < 99999999) ||
      (value && value.toString().indexOf(".") === value.length - 1)
    );
  }, []);

  const cleanupStockValue = useCallback((value, productUnitSymbol) => {
    let val = parseAmountToNumber(value);

    return toAmount(
      val,
      productUnitSymbol === "pc" || productUnitSymbol === "" ? "0,0" : "0,0.000"
    );
  }, []);

  const accountOptions = useMemo(() => {
    return mapAccountOptions(mappedData);
  }, [mappedData]);
  console.log(accountOptions);
  const locationForms = useMemo(() => {
    return (
      <div>
        <div className="flex justify-between items-center mb-sm mt-md grid grid-cols-4 gap-2 lg:grid-cols-6 gap-4">
          <div className="text-sm col-span-1 lg:col-span-3 text-gray-500">{en.location}</div>
          <div className="text-sm text-gray-500">{en.inStock}</div>
          <div className="text-sm text-gray-500">{en.parLevel}</div>
          <div className="text-sm text-gray-500">{en.reorderPoint}</div>
        </div>
        {fields.productSku.value.locationStocks.map((location, index) => {
          return (
            <div
              className="flex justify-between items-center mb-md grid grid-cols-4 gap-2 lg:grid-cols-6 gap-4"
              key={index}
            >
              <div className="col-span-1 lg:col-span-3 text-sm text-gray-800 font-bold break-words">
                {location.name}
              </div>

              <Input
                right
                value={fields.productSku.value.locationStocks[index].stock}
                onChange={(obj, value) => {
                  let val = (value.value + "").trim();
                  if (isStockValid(val)) {
                    const newValue = fields.productSku.value;
                    newValue.locationStocks[index].stock = val;
                    modifyField("productSku", newValue);
                  }
                }}
                onBlur={() => {
                  let val = fields.productSku.value.locationStocks[index].stock;
                  const newValue = fields.productSku.value;
                  newValue.locationStocks[index].stock = cleanupStockValue(val, productUnitSymbol);
                  modifyField("productSku", newValue);
                }}
                iconSuffix={
                  <span className="text-sm text-gray-400 md:pl-sm">{productUnitSymbol}</span>
                }
              />
              <Input
                right
                value={fields.productSku.value.locationStocks[index].parLevel}
                onChange={(obj, value) => {
                  let val = (value.value + "").trim();
                  if (isStockValid(val)) {
                    const newValue = fields.productSku.value;
                    newValue.locationStocks[index].parLevel = val;
                    modifyField("productSku", newValue);
                  }
                }}
                onBlur={() => {
                  let val = fields.productSku.value.locationStocks[index].parLevel;
                  const newValue = fields.productSku.value;
                  newValue.locationStocks[index].parLevel = cleanupStockValue(
                    val,
                    productUnitSymbol
                  );
                  modifyField("productSku", newValue);
                }}
                iconSuffix={
                  <span className="text-sm text-gray-400 md:pl-sm">{productUnitSymbol}</span>
                }
              />
              <Input
                right
                value={fields.productSku.value.locationStocks[index].reorderPoint}
                onChange={(obj, value) => {
                  let val = (value.value + "").trim();
                  if (isStockValid(val)) {
                    const newValue = fields.productSku.value;
                    newValue.locationStocks[index].reorderPoint = val;
                    modifyField("productSku", newValue);
                  }
                }}
                onBlur={() => {
                  let val = fields.productSku.value.locationStocks[index].reorderPoint;
                  const newValue = fields.productSku.value;
                  newValue.locationStocks[index].reorderPoint = cleanupStockValue(
                    val,
                    productUnitSymbol
                  );
                  modifyField("productSku", newValue);
                }}
                iconSuffix={
                  <span className="text-sm text-gray-400 md:pl-sm">{productUnitSymbol}</span>
                }
              />
            </div>
          );
        })}
        <Divider />
        <div className="flex justify-between items-center mb-sm mt-md grid grid-cols-4 gap-2 lg:grid-cols-6 gap-4">
          <div className="col-span-1 lg:col-span-3 text-sm text-gray-800 font-bold">
            {en.totals}
          </div>
          <div className="flex">
            <span className="flex-grow truncate text-right text-sm text-gray-500">
              {totalInStock}
            </span>
            <span className="flex-none text-sm text-gray-400 pl-sm pr-md">{productUnitSymbol}</span>
          </div>
          <div className="flex">
            <span className="flex-grow truncate text-right text-sm text-gray-500">
              {totalParLevel}
            </span>
            <span className="text-sm text-gray-400 pl-sm pr-md">{productUnitSymbol}</span>
          </div>
          <div className="flex">
            <span className="flex-grow truncate text-right text-sm text-gray-500">
              {totalReorderPoint}
            </span>
            <span className="text-sm text-gray-400 pl-sm pr-md">{productUnitSymbol}</span>
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields,
    modifyField,
    productUnitSymbol,
    totalInStock,
    totalParLevel,
    totalReorderPoint,
    cleanupStockValue,
    isStockValid,
    stateReady,
  ]);

  const emptyLocations = useMemo(() => {
    return (
      <div className="align-center text-center p-md">
        <p className="text-md text-gray-600 mb-sm">{lang.noLocationAvailable}</p>
        <p className="text-sm text-gray-400 mb-md">{lang.pleaseCreateLocation}</p>
        <Button
          type={StyleType.Secondary}
          onClick={() => {
            history.push(Path.LOCATION);
          }}
        >
          {lang.createLocation}
        </Button>
      </div>
    );
  }, [history]);

  useEffect(() => {
    // if (integrationStatus?.id) {
    //   (async () => {
    //     await syncAccounting();
    //   })();
    // }
    const measurementId = fields.measurementId.value;
    mappedMeasurements.forEach((measurement) => {
      if (measurementId === measurement.id) {
        setProductUnitSymbol(measurement.unit);
        const newValue = fields.productSku.value;
        newValue.locationStocks.forEach((location) => {
          location.stock = cleanupStockValue(location.stock, measurement.unit);
          location.reorderPoint = cleanupStockValue(location.reorderPoint, measurement.unit);
          location.parLevel = cleanupStockValue(location.parLevel, measurement.unit);
        });
        modifyField("productSku", { value: newValue, dirty: false });
        modifyField("orderQuantity", {
          value: cleanupStockValue(fields.orderQuantity.value, measurement.unit),
          dirty: false,
        });
      }
    });
    // eslint-disable-next-line
  }, [fields.measurementId.value, mappedMeasurements]);

  const totalAmount = useMemo(() => {
    if (fields.orderQuantity && fields.pricePerOrderQuantity.value) {
      let totalInStockClean = parseAmountToNumber(totalInStock);
      let orderQuantityClean = parseAmountToNumber(fields.orderQuantity.value);
      let pricePerOrderQuantityClean = parseMoneyToNumber(fields.pricePerOrderQuantity.value).value;
      let totalAmount = (pricePerOrderQuantityClean / orderQuantityClean) * totalInStockClean;
      let computedTotalAmount =
        orderQuantityClean && pricePerOrderQuantityClean > 0 && !isNaN(totalAmount.toFixed(2))
          ? totalAmount.toFixed(2)
          : 0;
      return formatNumberToMoney(computedTotalAmount, true, false, 2);
    } else {
      return 0;
    }
    // eslint-disable-next-line
  }, [fields.pricePerOrderQuantity.value, fields.orderQuantity.value, totalInStock]);

  useEffect(() => {
    modifyField("sku", {
      disabled: fields.automaticallyGenerateSku.value,
      required: !fields.automaticallyGenerateSku.value,
      dirty: false,
    });
    // eslint-disable-next-line
  }, [fields.automaticallyGenerateSku.value, fields.sku.disabled]);

  const renderAccountCodeId = useMemo(() => {
    if (!fields.supplyAccountCodeId.value) {
      return defaultAccountCodeId;
    }
    return fields.supplyAccountCodeId.value;
  }, [defaultAccountCodeId, fields]);

  return (
    <ModuleWrapper
      error={error}
      header={<HeaderB title={title} returnText={lang.supplyItems} onClick={leavePage} />}
    >
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue}>
        <FragmentA title={lang.basicInfo}>
          <Panel>
            {loading ? (
              <Skeleton />
            ) : (
              <div>
                <Field {...fields.name}>
                  <Input
                    required
                    {...fields.name}
                    onChange={modifyField}
                    placeholder={lang.nameYourSupplyItem}
                  />
                </Field>
                <Field {...fields.description} className="mt-md">
                  <InputCounter
                    {...fields.description}
                    textarea
                    onChange={modifyField}
                    labelHidden={true}
                  />
                </Field>
                <Field
                  {...fields.supplyAccountCodeId}
                  customLabel={
                    <div className="flex items-center">
                      <Text label>{lang.inventoryAccount}</Text>
                    </div>
                  }
                  className="mt-md"
                >
                  <Select
                    {...fields.supplyAccountCodeId}
                    onChange={modifyField}
                    loading={accountLoading}
                    searchable
                    searchValue={searchKey}
                    value={renderAccountCodeId}
                    onSearch={(val) => {
                      setSearchKey(val);
                    }}
                    options={accountOptions}
                    groupedOptions={groupAccountOptions(
                      accountOptions,
                      fields.supplyAccountCodeId.suggested,
                      true
                    )}
                    customNotFoundContent={lang.accountNotFound}
                  />
                </Field>
              </div>
            )}
          </Panel>
        </FragmentA>
        <FragmentA title={lang.inventory} description={lang.manageTheStocksPerLocation}>
          <Panel>
            {loading ? (
              <Skeleton />
            ) : (
              <div>
                <Title md className="mb-sm" fontWeight="font-bold">
                  {lang.skuStockKeepingUnit}
                </Title>

                <Field {...fields.sku}>
                  <Input
                    {...fields.sku}
                    value={fields.automaticallyGenerateSku.value ? null : fields.sku.value}
                    onChange={(obj, value) => {
                      let newValue = "";
                      if (value.value) {
                        newValue = value.value.replace(/\W/g, "").replace(/_/g, "").toUpperCase();
                      }
                      modifyField(obj, { value: newValue });
                    }}
                  />
                </Field>

                {!onEdit ? (
                  <CheckboxField
                    textSize="text-sm mt-sm"
                    {...fields.automaticallyGenerateSku}
                    onChange={modifyField}
                  >
                    {fields.automaticallyGenerateSku.label}
                  </CheckboxField>
                ) : null}
                <div className="grid grid-cols-2 gap-2 mt-md">
                  <Field {...fields.measurementId}>
                    <Select
                      {...fields.measurementId}
                      disabled={loadingMeasurement}
                      loading={loadingMeasurement}
                      placeholder={lang.pleaseSelectStockUnit}
                      options={measurementOptions}
                      onChange={modifyField}
                    />
                  </Field>
                  {productUnitSymbol === "pc" ? (
                    <Field {...fields.weightInGrams}>
                      <Input
                        {...fields.weightInGrams}
                        right
                        iconSuffix={<span className="text-sm text-gray-400 md:pl-sm">g</span>}
                        onChange={(name, { value }) => {
                          if (!isNumberValid(value) || !isValidDecimalPlaces(value, 3)) {
                            return;
                          }
                          const max = 9999999.999;
                          if (parseAmountToNumber(value) <= max) {
                            modifyField(name, { value });
                          }
                        }}
                        onFocus={() => {
                          modifyField("weightInGrams", {
                            value: parseAmountToNumber(fields.weightInGrams.value) || "",
                          });
                        }}
                        onBlur={() => {
                          modifyField("weightInGrams", {
                            value: toAmount(fields.weightInGrams.value),
                          });
                        }}
                      ></Input>
                    </Field>
                  ) : null}
                </div>
              </div>
            )}
          </Panel>
          <Panel>
            <div>
              <Title md className="mb-sm" fontWeight="font-bold">
                {lang.stocks}
              </Title>
              {loading || loadingLocation || !stateReady ? (
                <Skeleton />
              ) : mappedLocation && mappedLocation.length > 0 ? (
                locationForms
              ) : (
                emptyLocations
              )}
            </div>
          </Panel>
        </FragmentA>
        <FragmentA title={lang.pricing}>
          <Panel>
            {loading ? (
              <Skeleton />
            ) : (
              <div>
                <Title md className="mb-sm" fontWeight="font-bold">
                  {lang.supplyCost}
                </Title>
                <div className="grid grid-cols-3 gap-4">
                  <Field {...fields.orderQuantity}>
                    <Input
                      placeholder="0"
                      right
                      {...fields.orderQuantity}
                      onChange={(obj, value) => {
                        let val = (value.value + "").trim();
                        if (isStockValid(val)) {
                          modifyField(obj, { value: val });
                        }
                      }}
                      onBlur={() => {
                        if (fields.orderQuantity.value) {
                          modifyField("orderQuantity", {
                            value: cleanupStockValue(fields.orderQuantity.value, productUnitSymbol),
                          });
                        }
                      }}
                      iconSuffix={
                        <span className="text-sm text-gray-400 pl-sm">{productUnitSymbol}</span>
                      }
                    />
                  </Field>
                  <Field {...fields.pricePerOrderQuantity}>
                    <InputMoney
                      placeholder="0.00"
                      {...fields.pricePerOrderQuantity}
                      onChange={(obj, value) => {
                        let val = (value.value + "").trim();
                        if (isStockValid(val)) {
                          modifyField(obj, { value: val });
                        }
                      }}
                    />
                  </Field>
                  <Field label={lang.totalAmount}>
                    <InputMoney onChange={modifyField} disabled={true} value={totalAmount} />
                    <Text label className="mt-xs">
                      * Cost of {totalInStock} {productUnitSymbol}
                    </Text>
                  </Field>
                </div>
              </div>
            )}
          </Panel>
        </FragmentA>
        <ActionButton
          showLine
          loading={submitting}
          primary={{
            disabled: !dirty,
            onClick: () => {
              submitFormValue();
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
          danger={deleteItemButton}
        />
      </Form>
      <DeleteSupplyItem
        isAllowed={true}
        name={fields.name.value}
        totalInStock={totalInStock}
        measurement={fields.measurementId.value}
        products={[]}
        {...deleteModal}
      ></DeleteSupplyItem>
    </ModuleWrapper>
  );
};

export default SupplyItemForm;
