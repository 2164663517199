import { ActionButton, Button, Icon, Input, Modal, Text } from "components/commons";
import { VenueContext } from "contexts";
import React, { useState, useContext } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatId, getCurrentUrl } from "services";
import lang from "translations";
const ShareStocktakeModal = ({ stocktakeId, ...props }) => {
  const [copied, setCopied] = useState(false);
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const link = `${getCurrentUrl()}?venue=${venueId}`;
  return (
    <Modal
      title={lang.share}
      actionContent={
        <ActionButton
          showLine={false}
          className="px-md pb-md"
          secondary={{
            text: lang.done,
            onClick: () => {
              setCopied(false);
              props.close();
            },
          }}
          primary={false}
        />
      }
      {...props}
    >
      {
        <>
          <lang.Translate
            text={lang.shareThisLink}
            items={[<span className="font-semibold">{`ST-${formatId(stocktakeId)}`}</span>]}
          />
          <div className="flex mt-md">
            <Input type="text" className="rounded-r-none" value={link} valueSize="text-md" />
            <CopyToClipboard text={link}>
              <Button className="rounded-l-none w-1/4" onClick={() => setCopied(true)}>
                {!copied ? (
                  <Text fontWeight="font-semibold" color="text-white">
                    {lang.copyLink}
                  </Text>
                ) : (
                  <div className="flex items-center justify-between gap-2">
                    <div>
                      <Text fontWeight="font-semibold" color="text-white">
                        {lang.copied}
                      </Text>
                    </div>
                    <div>
                      <Icon name="check-only" fontSize="13px" />
                    </div>
                  </div>
                )}
              </Button>
            </CopyToClipboard>
          </div>
        </>
      }
    </Modal>
  );
};

export default ShareStocktakeModal;
