import { getSupplyItem } from "apis";
import { deleteProduct } from "apis/product.api";
import { ActionButton, Modal, Text, Title, Toast, Icon } from "components/commons";
import { DeleteModal } from "components/modals";
import { useApi, useMount, useRouter } from "hooks";
import { supplyItemSummary } from "mappers/supply-item.mapper";
import { Path } from "paths";
import React, { useCallback, useEffect, useMemo } from "react";
import { sortByKeyName } from "services";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";

const DeleteSupplyItem = ({ refreshList, id = 0, onList = false, ...props }) => {
  const { history } = useRouter();

  const { request } = useApi({
    api: deleteProduct,
    params: {
      id,
    },
  });

  const {
    request: requestSupplyItem,
    loading = true,
    mappedData,
  } = useApi({
    api: getSupplyItem,
    params: {
      id,
    },
    handleOwnError: {
      badrequest: true,
    },
    mapper: supplyItemSummary,
  });

  useMount(() => {
    fetchIfNotOnSupplyList();
  });

  const fetchIfNotOnSupplyList = () => {
    if (!onList && id !== 0) {
      fetchSupplyItem();
    }
  };

  const isPartOfCompositions = useMemo(() => {
    return Boolean(mappedData?.partOfCompositions);
  }, [mappedData]);

  const retrievePartOfCompositions = useMemo(() => {
    const products = [];
    if (isPartOfCompositions) {
      mappedData?.partOfCompositions.forEach((v) => {
        products.push({
          name: v["product"].productName,
          id: v["product"].productId,
        });
      });
    }
    return products.sort(sortByKeyName("name"));
  }, [mappedData, isPartOfCompositions]);

  const countProducts = useMemo(() => {
    return mappedData?.partOfCompositions?.length;
  }, [mappedData]);

  const fetchSupplyItem = useCallback(async () => {
    await requestSupplyItem();
  }, [requestSupplyItem]);

  useEffect(() => {
    if (onList && id !== 0) {
      fetchSupplyItem();
    }
    // eslint-disable-next-line
  }, [id, onList]);

  return (
    <>
      {!isPartOfCompositions && !loading ? (
        <DeleteModal
          primaryText={lang.deleteSupplyItem}
          customHeader={
            <div className="flex items-center justify-between">
              <Title lg className="mb-sm pt-md">
                {lang.deleteSupplyItem + "?"}
              </Title>
              <Icon
                className="outline-none text-xl text-gray hover:text-gray cursor-pointer"
                name="remove"
                onClick={() => {
                  props.close();
                }}
              />
            </div>
          }
          customContent={
            <Text>
              {lang.populate(lang.areYouSureYouWantToDeleteProduct, [
                mappedData.name,
                mappedData.inStock,
                mappedData.measurement,
              ])}
            </Text>
          }
          ok={async () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.deleteSupplyItem,
              Page: lang.supplyItems,
            });

            await request();
            Toast({
              content: lang.supplyItemDeleted,
              success: true,
              icon: "check",
            }).open();
            props.close();
            history.push(Path.INVENTORY_SUPPLY_ITEM);
            if (refreshList) {
              refreshList();
            }
          }}
          {...props}
        />
      ) : null}
      {isPartOfCompositions & !loading ? (
        <Modal
          title={<strong>{lang.unableToDelete}</strong>}
          actionContent={
            <ActionButton
              className="px-md pb-md"
              secondary={{
                text: lang.okay,
                onClick: () => {
                  props.close();
                },
              }}
              primary={false}
            />
          }
          {...props}
        >
          <Text>
            {mappedData.name} is currently being used in the following products -{" "}
            {retrievePartOfCompositions.map((p, index) => {
              return index < 5 ? (
                <>
                  <span
                    onClick={() => {
                      window.open(Path.EDIT_PRODUCT_ID(p.id));
                    }}
                    className="text-blue text-sm cursor-pointer"
                  >
                    {p.name}
                  </span>
                  {index !== retrievePartOfCompositions.length - 1 ? (
                    <span className="text-sm">, </span>
                  ) : null}
                </>
              ) : null;
            })}
            {countProducts > 5 ? (
              <>
                <span className="text-sm"> and</span>
                <span className="text-blue text-sm">{` ${countProducts - 5} more`}</span>
              </>
            ) : null}
            . {lang.removeThisSupplyItem}
          </Text>
        </Modal>
      ) : null}
    </>
  );
};

export default DeleteSupplyItem;
