export const measurement = {
  id: {
    key: "measurementId",
  },
  name: {
    key: "measurementName",
  },
  unit: {
    key: "unit",
  },
};
