import { Get, Post, Delete } from "services";
import { ApiPath } from "paths";

export const searchLocation = async (params) => {
  const res = await Post(`${ApiPath.SEARCH_LOCATION}`, params);
  return res;
};

export const getLocations = async ({ venueId }) => {
  const res = await Get(`${ApiPath.LOCATION}`, { venueId });
  return res;
};

export const getLocation = async ({ locationId }) => {
  const res = await Get(`${ApiPath.LOCATION_ID(locationId)}`);
  return res;
};

export const getLocationProducts = async ({ locationId }) => {
  const res = await Get(`${ApiPath.LOCATION_PRODUCT_ID(locationId)}`);
  return res.data;
};

export const createLocation = async ({ venueId, locationName, onlineOrderTables, outletId }) => {
  const res = await Post(`${ApiPath.LOCATION}`, { venueId, locationName, onlineOrderTables, outletId });
  return res;
};

export const editLocation = async ({ venueId, locationName, locationId, onlineOrderTables, outletId }) => {
  const res = await Post(`${ApiPath.LOCATION_ID(locationId)}`, {
    venueId,
    locationName,
    onlineOrderTables,
    outletId
  });
  return res;
};

export const deleteLocation = async ({ locationId }) => {
  const res = await Delete(`${ApiPath.LOCATION_ID(locationId)}`);
  return res.data;
};

export const searchLocationWithTransaction = async ({ venueId, ...body }) => {
  const res = await Post(`${ApiPath.LOCATION_WITH_TRANSACTION(venueId)}`, body);
  return res;
};

export const getLocationInventoryValue = async ({ locationId }) => {
  const res = await Get(`${ApiPath.LOCATION_INVENTORY_VALUE(locationId)}`);
  return res;
};

export const getLocationsWithStocktakeStatus = async ({ venueId }) => {
  const res = await Get(`${ApiPath.LOCATION_STOCKTAKE}`, { venueId });
  return res;
};

export const generateQRCodeLocation = async ({ locationId, venueId }) => {
  const res = await Post(`${ApiPath.LOCATION_QR_EXPORT(locationId)}`, { venueId });
  return res;
};

export const generateQRCodePerLocation = async ({ locationId, venueId }) => {
  const res = await Post(`${ApiPath.LOCATION_QR_TABLE_EXPORT(locationId)}`, { venueId });
  return res;
};
