import React, { useMemo } from "react";
import { questionResponse } from "mappers";
import { getQuestions } from "apis/question.api";
import { useMount, useApi, useSelectItems } from "hooks";
import lang from "translations";
import { Skeleton, SimpleTable, Text, Checkbox, DraggableItems } from "components/commons";
import classnames from "classnames";
import styles from "./select-question.module.scss";

const defaultQuestion = [
  [lang.firstName, "firstNameRequired", true],
  [lang.lastName, "lastNameRequired", true],
  [lang.emailAddress, "emailRequired"],
  [lang.mobileNumber, "mobileRequired"],
  [lang.birthday, "birthdayRequired"],
  [lang.gender, "genderRequired"],
];

const SelectQuestion = ({
  defaultQuestionField,
  customQuestionRequiredField,
  onChangeDefaultQuestion,
  onChangeCustomQuestionRequiredField,
  customQuestionIncludesField,
  onChangeCustomQuestionIncludesField,
  venueId,
  sortQuestions,
  setSortQuestions,
}) => {
  const { request, loading, mappedData } = useApi({
    api: getQuestions,
    params: {
      venueId,
    },
    mapper: questionResponse,
    isArray: true,
    returnMappedData: true,
  });

  useMount(async () => {
    const d = await request();
    setSortQuestions(
      [
        ...sortQuestions,
        ...d.filter(({ id }) => {
          let contains = false;
          sortQuestions.forEach(({ id: sortQuestionId }) => {
            if (sortQuestionId === id) {
              contains = true;
            }
          });
          return !contains;
        }),
      ],
      false
    );
  });

  const useCustomIncludes = useSelectItems({
    logKey: "custom includes",
    items: mappedData,
    indeterminate: true,
    selected: customQuestionIncludesField.value,
    setSelected: (value) => {
      onChangeCustomQuestionIncludesField(value);
    },
  });

  const useCustomRequired = useSelectItems({
    logKey: "custom required",
    items: mappedData,
    indeterminate: true,
    ignored: mappedData
      .filter((question) => {
        return !useCustomIncludes.selected[question.id];
      })
      .map((question) => {
        return question.id;
      }),
    selected: customQuestionRequiredField.value,
    setSelected: (value) => {
      onChangeCustomQuestionRequiredField(value);
    },
  });

  const useDefaultQuestion = useSelectItems({
    items: defaultQuestion.map(([name, key]) => {
      return {
        id: key,
        name,
      };
    }),
    indeterminate: true,
    defaultValue: {
      firstNameRequired: {
        checked: true,
      },
      lastNameRequired: {
        checked: true,
      },
    },
    selected: defaultQuestionField.value,
    setSelected: (value) => {
      onChangeDefaultQuestion(value);
    },
  });

  const defaultQuestionRender = useMemo(() => {
    return defaultQuestion.map(([name, key, isDisabled = false]) => {
      return {
        name,
        isRequired: (
          <div className="text-center w-16 ml-auto mr-0">
            <Checkbox
              disabled={isDisabled}
              className={classnames("m-auto")}
              value={useDefaultQuestion.selected[key]}
              onChange={() => {
                useDefaultQuestion.setSelected(key);
              }}
            />
          </div>
        ),
      };
    });
  }, [useDefaultQuestion]);

  return (
    <div>
      <SimpleTable
        addBorder
        className="-mx-6"
        columns={[
          {
            key: "name",
            className: "py-lg px-6 ",
            headerClassName: "py-lg px-6 ",
            text: (
              <Text className="font-medium text-gray" size="text-xs">
                {lang.defaultFields}
              </Text>
            ),
          },
          {
            key: "isRequired",
            className: "py-lg px-6 text-right",
            headerClassName: "py-lg px-6 ",
            text: (
              <div className="text-right">
                <Text className="font-medium text-gray mb-xs" size="text-xs">
                  {lang.required}
                </Text>
                <div className="text-center w-16 ml-auto mr-0">
                  <Checkbox
                    value={useDefaultQuestion.isAllSelected}
                    className={classnames("m-auto")}
                    onChange={() => {
                      useDefaultQuestion.setSelectAll();
                    }}
                  />
                </div>
              </div>
            ),
          },
        ]}
        data={defaultQuestionRender}
      />

      {loading ? (
        <Skeleton />
      ) : (
        <div className="mt-md -mr-lg -ml-lg">
          <div className="flex justify-between px-lg">
            <div className="w-9/12">
              <Text uppercase className="font-medium text-gray mb-xs" size="text-xs">
                {lang.customFields}
              </Text>
            </div>
            <div className="flex justify-between w-3/12">
              <div className="w-16 text-center ml-sm">
                <Text uppercase className="font-medium text-gray mb-xs" size="text-xs">
                  {lang.include}
                </Text>
                <Checkbox
                  value={useCustomIncludes.isAllSelected}
                  className={classnames("m-auto")}
                  onChange={() => {
                    useCustomIncludes.setSelectAll();
                    sortQuestions.forEach((question) => {
                      useCustomRequired.selected[question.id] = false;
                    });
                  }}
                />
              </div>
              <div className="w-16 text-center">
                <Text uppercase className="font-medium text-gray mb-xs" size="text-xs">
                  {lang.required}
                </Text>
                <Checkbox
                  value={useCustomRequired.isAllSelected}
                  className={classnames("m-auto")}
                  onChange={() => {
                    useCustomRequired.setSelectAll();
                  }}
                  disabled={!useCustomIncludes.isAllSelected}
                />
              </div>
            </div>
          </div>
          <DraggableItems
            name="options"
            items={sortQuestions}
            render={sortQuestions.map((question) => {
              const questionId = question.id;
              return {
                id: `QUESTION_ID_${questionId}`,
                className: styles.draggableItem,
                content: (
                  <div className={classnames("flex justify-between w-full py-md")}>
                    <div className="flex justify-between w-9/12">
                      <Text>{question.name}</Text>
                    </div>
                    <div className="flex justify-between w-3/12">
                      <div className="w-16 text-center">
                        <Checkbox
                          value={useCustomIncludes.selected[questionId]}
                          className={classnames("m-auto")}
                          onChange={() => {
                            useCustomIncludes.setSelected(questionId);
                            if (
                              useCustomIncludes.selected[questionId] &&
                              useCustomRequired.selected[questionId]
                            ) {
                              delete useCustomRequired.selected[questionId];
                            }
                          }}
                        />
                      </div>
                      <div className="w-16 text-center">
                        <Checkbox
                          value={
                            !useCustomIncludes.selected[questionId]
                              ? false
                              : useCustomRequired.selected[questionId]
                          }
                          className={classnames("m-auto")}
                          onChange={() => {
                            useCustomRequired.setSelected(questionId);
                          }}
                          disabled={!useCustomIncludes.selected[questionId]}
                        />
                      </div>
                    </div>
                  </div>
                ),
              };
            })}
            onChange={(name, { value }) => {
              setSortQuestions(value);
            }}
            className="mt-md"
            hasRemove={false}
          />
        </div>
      )}
    </div>
  );
};

export default SelectQuestion;
