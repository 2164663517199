import React, { useCallback } from "react";
import { DatePicker as AntDatePicker } from "antd";
import moment from "moment";
import styles from "./date-picker.module.scss";
import classnames from "classnames";

const DatePicker = ({ name, className, format = "MMM DD, YYYY", value = moment(), onChange }) => {
  const onChangeCb = useCallback(
    (v) => {
      if (onChange) {
        onChange(name, v);
      }
    },
    [onChange, name]
  );

  return (
    <div>
      <AntDatePicker
        inputReadOnly
        className={classnames(styles.datePicker, className)}
        value={value}
        format={format}
        defaultValue={moment().startOf("day")}
        onChange={onChangeCb}
        allowClear={false}
        showToday={false}
      />
    </div>
  );
};

export default DatePicker;
