import React, { useMemo } from "react";
import { Title, Skeleton, Panel } from "components/commons";
import classnames from "classnames";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";

const ChartContainer = ({
  children,
  empty,
  error,
  title,
  emptyText,
  loading,
  className,
  h = "full",
  retry,
  paddingless,
}) => {
  const render = useMemo(() => {
    if (loading) {
      return (
        <Skeleton
          className={classnames({
            "pb-md": !paddingless,
            "pl-md": paddingless,
          })}
        />
      );
    }
    if (error) {
      return (
        <div
          className={classnames("flex text-sm py-lg cursor-pointer", {
            "pb-md": !paddingless,
            "pl-md": paddingless,
          })}
          onClick={() => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.retryReloadingChart,
              Page: lang.salesReport,
            });
            retry();
          }}
        >
          {lang.failedToLoad}
          <div className="flex text-sm ml-xs italic text-pelorous">{lang.clickHereToRetry}</div>
        </div>
      );
    }
    if (empty) {
      return (
        <div className={classnames(className, `h-${h}`, "flex items-center")}>
          <p
            className={classnames("text-sm text-gray-400", {
              "pb-xl": paddingless,
              "pb-md": !paddingless,
              "pl-md": paddingless,
            })}
          >
            {emptyText || lang.noDataAvailable}
          </p>
        </div>
      );
    }
    return children;
  }, [empty, error, children, emptyText, loading, retry, className, h, paddingless]);
  return (
    <Panel
      className={classnames(className, `h-${h}`)}
      mb="0"
      parent={false}
      paddingless={paddingless}
    >
      <Title
        md
        className={classnames(className, {
          "px-md": paddingless,
          "pt-md": paddingless,
        })}
      >
        {title}
      </Title>
      {render}
    </Panel>
  );
};

export default ChartContainer;
