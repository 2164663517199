import { Popover } from "antd";
import React, { useState } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

const OptionMenu = ({ options, title, className }) => {
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      overlayClassName="options-popover"
      placement="bottomRight"
      title={title}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={options.map((option) => {
        return (
          <button
            key={uuidv4()}
            className={classNames(`border-none outline-none w-full text-left`, option?.className)}
            onClick={() => {
              option.onClick();
              hide();
            }}
          >
            {option.text}
          </button>
        );
      })}
      trigger="click"
      arrow={false}
    >
      <div className={classNames("text-right", className)}>
        <i
          className={classNames(`icon-options align-middle`)}
          style={{
            fontSize: 3,
          }}
        />
      </div>
    </Popover>
  );
};

export default OptionMenu;
