import enumeration from "./enumeration";

const PeriodicalTimeUnit = {
  ONCE: "ONCE",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  WEEKDAY: "WEEKDAY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
};

export default enumeration(PeriodicalTimeUnit);
