import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Path } from "paths";
import { useMount } from "hooks";
import { redirectTo } from "services";

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  useMount(() => {
    if (rest.location.search.includes("code") && !localStorage.getItem("accessToken")) {
      redirectTo(`${Path.AUTH}${rest.location.search}`);
    }
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem("accessToken") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Path.AUTH,
              state: {
                next: rest.location.pathname,
                venue:
                  (!rest.location.search.includes("code") && rest.location.search) ||
                  localStorage.getItem("venueId"),
              },
            }}
          />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
