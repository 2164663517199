import React, { useState, useCallback, useMemo, useImperativeHandle, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Text, Pill, Icon } from "components/commons";
import classnames from "classnames";
import styles from "./input-pill.module.scss";
import { forwardRef } from "react";

const InputPill = (
  {
    value,
    setValue,
    handleSubmitKey,
    loading,
    handleRemovePill,
    suffix,
    error = false,
    required,
    placeholder,
    renderOwnValue,
    classNameInput,
    disabled,
    input,
    onSearchChanged,
  },
  ref
) => {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const inputValueState = useState("");
  const [removing, setRemoving] = useState(null);

  const [inputValue, setInputValue] = useMemo(() => {
    if (input) {
      return input;
    }
    return inputValueState;
  }, [input, inputValueState]);

  const focusOnInput = useCallback(
    (e) => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }

      if (e) {
        e.preventDefault();
      }
    },
    [inputRef]
  );

  const handlePushValue = useCallback(
    (key) => {
      if (error || !key) {
        return false;
      }
      setValue([
        ...value,
        typeof key === "object"
          ? key
          : {
              text: key,
            },
      ]);
      setInputValue("");
      focusOnInput();
    },
    [setValue, value, setInputValue, focusOnInput, error]
  );

  const onKeyUp = useCallback(
    async (e) => {
      const trimmedInputVal = inputValue.trim();
      // handler for enter and spacebar
      if (e.key === "Enter" || trimmedInputVal.includes(",")) {
        const v = trimmedInputVal.replace(",", "");
        if (handleSubmitKey && typeof handleSubmitKey === "function") {
          try {
            const newValue = await handleSubmitKey(v);
            if (newValue) {
              handlePushValue(typeof newValue === "object" ? newValue : v);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          handlePushValue(v);
        }
      }
    },
    [handlePushValue, handleSubmitKey, inputValue]
  );

  const onRemovePill = useCallback(
    async (index) => {
      const removePill = () => {
        const pills = [...value];
        pills.splice(index, 1);
        setValue(pills);
      };
      if (!handleRemovePill) {
        removePill();
      }
      setRemoving(index.toString());
      try {
        await handleRemovePill(value[index]);
        removePill();
        setRemoving(null);
      } catch (e) {
        setRemoving(null);
      }
    },
    [handleRemovePill, value, setValue]
  );

  return (
    <div
      className={classnames(
        "bg-white px-sm py-xs min-h-field rounded border-solid border border-white-darker relative flex items-center justify-between",
        {
          [`${styles.error}`]: error,
          "border-white-darker text-black": !error,
          "bg-gray-disabled": disabled,
          "cursor-not-allowed": disabled,
        }
      )}
      onClick={(e) => focusOnInput(e)}
    >
      <div className={classnames("min-h-10 max-h-28 overflow-y-auto", styles.wrapper)}>
        {renderOwnValue || (
          <>
            {value.map((v = {}, index) => {
              return (
                <Pill
                  key={uuidv4()}
                  type={null}
                  {...v?.pillAttributes}
                  className={classnames(
                    styles.pill,
                    "min-h-6 p-xxs bg-gray-lightest border border-solid border-gray-lighter rounded mt-xs",
                    {
                      [`danger border bored-solid border-red ${styles.danger}`]: v.danger,
                      "cursor-not-allowed": disabled,
                    }
                  )}
                >
                  <Text
                    size="text-xs"
                    strong
                    className="flex leading-none text-black-light align-center items-center"
                  >
                    <span className="text-xs text-left">{v.text}</span>
                    {!v.disabled ? (
                      <span className={styles.spanRemove}>
                        <Icon
                          className={classnames("text-xs text-gray-light", styles.removePill, {
                            "cursor-not-allowed": disabled,
                          })}
                          name="remove"
                          loading={removing === index.toString()}
                          onClick={() => {
                            if (!removing && !disabled) {
                              onRemovePill(index);
                              focusOnInput();
                            }
                          }}
                        />
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </Text>
                </Pill>
              );
            })}
            <input
              ref={inputRef}
              className={classnames(classNameInput, styles.input, "text-sm h-6 pl-sm", {
                "w-full": !value.length,
              })}
              value={inputValue}
              onKeyUp={onKeyUp}
              onChange={(e) => {
                setInputValue(e.target.value);
                if (onSearchChanged) {
                  onSearchChanged(e.target.value);
                }
              }}
              size={inputValue.length + 1}
              placeholder={!value.length ? placeholder : ""}
              disabled={disabled}
              maxLength={75}
            />
          </>
        )}
      </div>
      <div>{loading ? <Icon loading className={"text-gray-lighter"} /> : suffix}</div>
      {required && (
        <div className={classnames(styles.required, { [`${styles.error}`]: error })}>
          <Icon
            name="asterisk"
            className={classnames(styles.icon, { [`${styles.error}`]: error })}
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef(InputPill);
