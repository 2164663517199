import React, { useReducer, useEffect } from "react";
import classnames from "classnames";
import styles from "./slider.module.scss";
import { Icon, Avatar, Text } from "components/commons";

// Custom Panel
const Container = ({ item, offset, current, enlargeHeader = false }) => {
  const { header, body, footer, highlight } = item;

  const filterText = (paragraph, highlight) => {
    return paragraph.split(highlight);
  };

  const rightSideLayout = (
    <div
      className={classnames(
        styles.slideContent,
        "transition-all duration-500 ease-in-out mt-xl mx-auto shadow-md rounded-2xl bg-white"
      )}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
        "--current": current,
        opacity: current ? 1 : 0.7,
        height: current ? "82vh" : "80vh",
      }}
    >
      {/* HEADER */}
      <div
        className={classnames(enlargeHeader && "transition-all duration-500 ease-in-out")}
        style={{
          height: current ? "56%" : enlargeHeader ? "100%" : "56%",
        }}
      >
        <div
          className={"bg-white h-full w-full rounded-t-2xl"}
          style={{
            backgroundImage: `url(${header.image})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="flex h-full justify-end flex-col">
            <div className="flex flex-col items-end mr-md mb-md">
              <Text className="text-white">{header.description.place}</Text>
              <Text className="text-white">{header.description.country}</Text>
            </div>
          </div>
        </div>
      </div>

      {/* BODY */}
      <div
        className={classnames(
          "h-2/5 flex flex-col justify-between mx-lg",
          current ? "opacity-1" : enlargeHeader ? "opacity-0" : "opacity-1",
          enlargeHeader && "transition-all duration-500 "
        )}
        style={{
          height: current ? "44%" : enlargeHeader ? "0%" : "44%",
        }}
      >
        {/* CONTENT */}
        <div
          className={classnames(
            "slider-body h-2/3 mt-lg",
            current ? "overflow-scroll" : "overflow-hidden"
          )}
        >
          <Text
            size={"text-lg"}
            className={classnames("leading-6 text-black-light", styles.slideContentText)}
          >
            {filterText(body, highlight)[0]}
            <span className="text-lg shadow-text">{highlight}</span>
            {filterText(body, highlight)[1]}
          </Text>
        </div>

        {/* FOOTER */}
        <div className="flex h-1/3 mb-sm">
          <div className="flex self-center mr-md">
            <Avatar
              className="border border-gray-border"
              image={footer.avatar}
              defaultIconAvatar="account-settings"
            />
          </div>
          <div className="flex self-center">
            <div className="">
              <Text color="text-pelorous">{footer.name}</Text>
              <Text color="text-black">{footer.description}</Text>
              <Text label>{footer.place}</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return rightSideLayout;
};

const Dot = ({ current, index, jump }) => {
  return (
    <div onClick={jump}>
      <div
        className={classnames(
          "my-1 mx-1 rounded-full h-1 w-1 transition duration-300 ease-in-out",
          current ? "bg-pelorous-light" : "bg-pelorous-dark"
        )}
      ></div>
    </div>
  );
};

const SliderButton = ({ left, infinite, items, state, dispatch, transparent }) => {
  return (
    <button
      className={classnames(
        "absolute opacity-70 h-full",
        !left && "right-0",
        left
          ? !infinite && state.slideIndex === 0
            ? "hidden"
            : "block"
          : !infinite && state.slideIndex === (items.length - 1) * -1
          ? "hidden"
          : "block"
      )}
      onClick={() => dispatch({ type: left ? "PREV" : "NEXT" })}
    >
      <Icon
        color={transparent ? "text-transparent" : ""}
        name={left ? "arrow-left" : "arrow-right"}
        fontSize={32}
      />
    </button>
  );
};

const Slider = ({ items, infinite, enlargeHeader, showButton, setSliderIndex, sliderIndex }) => {

  const initialState = {
    slideIndex: sliderIndex,
  };

  const loopNext = (time) => {
    setTimeout(() => {
      dispatch({ type: "NEXT" });
      loopNext(time);
    }, time);
  };

  const sliderReducer = (state, action) => {
    switch (action.type) {
      case "NEXT":
        return {
          ...state,
          slideIndex: (state.slideIndex - 1) % items.length,
        };
      case "PREV":
        return {
          ...state,
          slideIndex:
            state.slideIndex === 0 ? -items.length + 1 : (state.slideIndex + 1) % items.length,
        };
      case "JUMP":
        return {
          ...state,
          slideIndex: action.jump,
        };
      default:
        throw new Error();
  }
  };

  const [state, dispatch] = useReducer(sliderReducer, initialState);

  useEffect(() => {
    loopNext(7000);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSliderIndex(state.slideIndex);
    //eslint-disable-next-line
  }, [state]);

  return (
    <div className={classnames("grid overflow-hidden h-full")}>
      {items.map((item, index) => {
        return (
          <Container
            key={index}
            item={item}
            offset={state.slideIndex + index}
            current={index * -1 === state.slideIndex}
            enlargeHeader={enlargeHeader}
          />
        );
      })}

      <div className="fixed w-1/2 bottom-8">
        <div className="flex flex-row justify-center">
          {items.map((item, index) => {
            return (
              <Dot
                key={index}
                current={index * -1 === state.slideIndex}
                index={index}
                jump={() => dispatch({ type: "JUMP", jump: index * -1 })}
              />
            );
          })}
        </div>
      </div>

      {showButton ? (
        <>
          <SliderButton
            left
            transparent
            infinite={infinite}
            state={state}
            dispatch={dispatch}
            items={items}
          />
          <SliderButton
            transparent
            infinite={infinite}
            state={state}
            dispatch={dispatch}
            items={items}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Slider;
