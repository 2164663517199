import { Field, MultipleSelect, Panel, Title, Switch } from "components/commons";
import { FragmentA } from "components/fragments";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import lang from "translations";

const LocationForm = ({ form, locations = [], locationLoading }) => {
  const { activePos, activeOnlineMenu, location } = form;
  const { onlineOrder } = useFlags();

  return (
    <FragmentA title={lang.location} description={lang.chooseTheLocationWhereThisProduct}>
      <Panel>
        <div className="flex mb-md">
          <Switch {...activePos} className="mr-sm" />
          <Title>{activePos.label}</Title>
        </div>
        {onlineOrder && (
          <div className="flex mb-md">
            <Switch {...activeOnlineMenu} className="mr-sm" />
            <Title>{activeOnlineMenu.label}</Title>
          </div>
        )}
        <Field {...location}>
          <MultipleSelect {...location} options={locations} optionsLoading={locationLoading} />
        </Field>
      </Panel>
    </FragmentA>
  );
};

export default LocationForm;
