import {
  ActionButton,
  Checkbox,
  Icon,
  Input,
  InputNumber,
  Modal,
  PageError,
  SearchAndBrowse,
  SimpleTable,
  Skeleton,
  Text,
  Title,
  Field,
} from "components/commons";
import { useApi, useModal, useMount } from "hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import lang from "translations";
import { SearchOutlined } from "@ant-design/icons";
import { voucherListResponse, voucherListFilterRequest } from "mappers";
import { searchVoucher } from "apis";
import { VenueContext } from "contexts";
import { v4 as uuidv4 } from "uuid";
import styles from "./browse-voucher.module.scss";
import { sortByKeyName } from "services";
import { VoucherStatus } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";

const BrowseVoucher = ({ value = [], onChange }) => {
  const { venue } = useContext(VenueContext);
  const [checked, setChecked] = useState([]);
  const [dirty, setDirty] = useState(false);
  const { venueId } = venue;
  const [searchValue, setSearchValue] = useState("");
  const modal = useModal();

  const {
    request: searchVoucherRequest,
    loading: loadingVoucher,
    error,
    mappedData,
  } = useApi({
    api: searchVoucher,
    isArray: true,
    mapper: voucherListResponse,
    paramsMapper: voucherListFilterRequest,
    params: {
      page: null,
      orders: "v.voucherName",
      venueId,
      searchKey: searchValue,
    },
    handleOwnError: true,
  });

  useMount(() => {
    searchVoucherRequest();
    mixpanel.track(TrackEvent.ClickedButton, {
      Button: lang.browseVouchers,
    });
  });

  useEffect(() => {
    setChecked(value);
    setDirty(false);
    // eslint-disable-next-line
  }, [value]);

  const renderModal = useMemo(() => {
    if (loadingVoucher) {
      return <Skeleton className="mx-md" />;
    }
    if (error) {
      return <PageError padding="md:p-sm" />;
    }
    const md = mappedData.sort(sortByKeyName("name")).filter(({ status }) => {
      return status === VoucherStatus.Active;
    });
    if (!md.length) {
      return (
        <div className="flex items-center text-center h-36">
          <Text className="m-auto overflow-y-scroll max-h-32 p-md" color="text-gray">
            {searchValue
              ? lang.populate(lang.noSearchResultsForName, [searchValue])
              : lang.noVoucherAvailable}
          </Text>
        </div>
      );
    }
    return (
      <div className="mt-md">
        {md.map(({ id, name }, index) => {
          const isChecked = checked.some(({ value }) => {
            return id === value;
          });
          return (
            <div key={uuidv4()} className={styles.item}>
              <div className="flex py-sm min-h-field">
                <Checkbox
                  value={isChecked}
                  onChange={() => {
                    const newValue = !isChecked
                      ? [
                          ...checked,
                          {
                            value: id,
                            name,
                            quantity: 1,
                          },
                        ]
                      : checked.filter(({ value }) => {
                          return value !== id;
                        });
                    setChecked(newValue);
                    setDirty(true);
                  }}
                />
                <Text className="ml-sm" breakAll>
                  {name}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [loadingVoucher, error, mappedData, checked, searchValue]);

  const renderData = useMemo(() => {
    return value.sort(sortByKeyName("name")).map((v) => {
      return {
        ...v,
        name: <Text breakAll>{v.name}</Text>,
        quantity: (
          <div className="flex items-center justify-between overflow-hidden">
            <Field {...v} width="w-3/4">
              <InputNumber
                className="align-right"
                error={v.error}
                value={v.quantity}
                min={1}
                max={10000}
                onChange={(name, { value: inputValue }) => {
                  if (inputValue <= v?.limit) {
                    v.error = false;
                    v.message = "";
                  }
                  onChange(
                    value.map((voucher) => {
                      if (voucher.value === v.value) {
                        return {
                          ...voucher,
                          quantity: inputValue,
                        };
                      }
                      return voucher;
                    })
                  );
                }}
              />
            </Field>
            <div>
              <Icon
                name="remove"
                className="text-red display-right-hidden"
                onClick={() => {
                  onChange(
                    value.filter(({ value: valueId }) => {
                      return valueId !== v.value;
                    })
                  );
                }}
              />
            </div>
          </div>
        ),
      };
    });
  }, [value, onChange]);

  const vouchersCount = value.length;
  return (
    <div>
      <SearchAndBrowse
        searchText={lang.browseVouchers}
        placeholder={lang.chooseVouchers}
        onClick={() => {
          modal.show();
          searchVoucherRequest();
        }}
      />
      {vouchersCount ? (
        <div className="mt-md">
          <Title>{`${vouchersCount} ${vouchersCount <= 1 ? lang.voucher : lang.vouchers}`}</Title>
          <SimpleTable
            showHeader
            className="-mr-lg -ml-lg"
            columns={[
              {
                text: lang.voucherName,
                key: "name",
                className: styles.tableItem,
                headerClassName: "pl-lg",
              },
              {
                text: lang.quantity,
                key: "quantity",
                className: styles.tableItem,
                headerClassName: "px-xl text-left",
              },
            ]}
            data={renderData}
            useKeys={"browsevoucher"}
          />
        </div>
      ) : (
        <div className="pt-md flex items-center text-center">
          <Text size="text-sm" color="text-gray" className="mx-auto">
            {lang.noVouchersSelectedYet}
          </Text>
        </div>
      )}
      <Modal
        {...modal}
        paddingless
        title={lang.selectVouchers}
        onClose={() => {
          modal.close();
          setChecked(value);
          setSearchValue("");
        }}
        actionContent={
          <div className="p-md pt-0">
            <ActionButton
              primary={{
                onClick: () => {
                  onChange(checked);
                  modal.close();
                  setChecked(value);
                  setSearchValue("");
                },
                text: lang.add,
                disabled: !dirty || checked.length === 0,
              }}
              secondary={{
                onClick: () => {
                  modal.close();
                  setChecked(value);
                  setSearchValue("");
                },
                text: lang.cancel,
              }}
            />
          </div>
        }
      >
        <div className="px-md">
          <Input
            iconSuffix={
              <SearchOutlined
                onClick={() => {
                  searchVoucherRequest();
                }}
              />
            }
            placeholder={lang.searchVouchers}
            value={searchValue}
            onChange={(name, { value }) => {
              setSearchValue(value);
            }}
            onEnter={() => {
              searchVoucherRequest();
            }}
          />
        </div>
        {renderModal}
      </Modal>
    </div>
  );
};

export default BrowseVoucher;
