import { useApi, useMount } from "hooks";
import React, { useCallback } from "react";
import lang from "translations";
import SupplyItemForm from "../supply-item-form/supply-item-form";
import { createSupplyItem } from "apis/supply-item.api";
import { supplyItemRequest } from "mappers/product.mapper";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateSupplyItem = () => {
  const { request, loading: submitting } = useApi({
    api: createSupplyItem,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: supplyItemRequest,
  });

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.supplyItems,
    });
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,
          message: lang.populate(lang.supplyItemNameCreated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  return (
    <SupplyItemForm
      submit={submitForm}
      title={lang.createNewSupplyItem}
      submitting={submitting}
      stateReady={true}
    />
  );
};

export default CreateSupplyItem;
