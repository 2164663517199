export const bookingPaths = {
  BOOKING: "",
  BOOKING_VIEW: ":id",
  BOOKING_ID: (id) => `${id}/summary`,
  BOOKING_SUMMARY_NAV: (id) => `${id}/summary`,
  BOOKING_SUMMARY: ":id/summary",
  BOOKING_ARRIVALS: "",
  BOOKING_DEPARTURE: "/departure",
  BOOKING_STAYING: "/staying",
  BOOKING_GUESTS: ":id/guests",
  BOOKING_GUESTS_NAV: (id) => `${id}/guests`,
  BOOKING_ACCOMMODATION: ":id/accommodation",
  BOOKING_ACCOMMODATION_NAV: (id) => `${id}/accommodation`,
};
