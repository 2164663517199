import React from "react";
import lang from "translations";
import { Modal, Text, ActionButton, ButtonLink } from "components";
import { openInNewTab } from "services/url.service";

const SupportLinkModal = ({ active, close }) => {
  const closeModal = () => {
    close();
  };
  return (
    <Modal
      active={active}
      title={lang.scheduleACall}
      onClose={close}
      close={close}
      closable
      actionContent={
        <ActionButton
          className="px-md py-md"
          primary={null}
          secondary={{
            text: lang.done,
            onClick: () => {
              closeModal();
            },
          }}
        />
      }
    >
      <div>
        <Text>
          <lang.Translate
            text={lang.pleaseEmailUs}
            items={[
              <ButtonLink
                className="text-xs text-blue"
                flex={false}
                onClick={() => openInNewTab("mailto:support@pouchnation.com?")}
              >
                {lang.supportPouchnation}
              </ButtonLink>,
              <span className="">{lang.toScheduleACall.toLowerCase()}</span>,
            ]}
          />
        </Text>
      </div>
    </Modal>
  );
};

export default SupportLinkModal;
