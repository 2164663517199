import { Post, Get, Delete } from "services";
import { ApiPath } from "paths";

export const searchGuest = async (body) => {
  body.platform = "venue_dashboard";
  const res = await Post(`${ApiPath.SEARCH_GUEST}`, body);
  return res;
};

export const getGraphOfActivatedGuest = async (body) => {
  const res = await Post(`${ApiPath.GRAPH_ACTIVATED_GUEST(body.venueId)}`, body);
  return res;
};

export const getGuestCheckinInfo = async ({ guestId, venueId }) => {
  const res = await Get(`${ApiPath.GUEST_CHECKIN_INFO({ guestId, venueId })}`);
  return res;
};

export const createGuestNote = async (body) => {
  const res = await Post(`${ApiPath.GUEST_NOTE}`, body);
  return res;
};

export const editGuestNote = async ({ noteId, ...body }) => {
  const res = await Post(`${ApiPath.GUEST_NOTE_ID({ noteId })}`, body);
  return res;
};

export const deleteGuestNote = async ({ noteId }) => {
  const res = await Delete(`${ApiPath.GUEST_NOTE_ID({ noteId })}`);
  return res;
};

export const getGuestNotes = async ({ guestId, venueId }) => {
  const res = await Get(`${ApiPath.GUEST_NOTES({ guestId, venueId })}`);
  return res;
};

export const getGuestTag = async ({ venueId, guestCheckinId, guestTagUid, useCount }) => {
  const res = await Get(`${ApiPath.GUEST_TAG({ venueId, guestCheckinId, guestTagUid, useCount })}`);
  return res;
};
