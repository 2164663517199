import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { Field, Input, Panel, ActionButton, Toast, Form, Text, HeaderB } from "components";
import { ModuleWrapper, FragmentA } from "components/fragments";
import { useForm, useModal, useRouter } from "hooks";
import initialFormState from "./device.form-state";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";

const DeviceForm = ({
  submit,
  title,
  submitting,
  initialState = undefined,
  deleteDeviceButton,
}) => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const unsaveChangesModal = useModal();

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty } = useForm({
    initialState: formState,
  });

  const goToList = useCallback(() => {
    history.push(Path.DEVICE);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.saveDeviceForm,
        Page: lang.deviceList,
      });
      await submit({ ...params, venueId });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.DEVICE);
    } catch ({ code, handleError }) {
      const err = {
        3060: () => {
          applyFieldErrors({
            name: lang.deviceNameAlreadyExists,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [submit, getFormValues, venueId, history, applyFieldErrors]);

  const submitFormValue = () => {
    submitForm(handleSubmit);
  };

  return (
    <ModuleWrapper width="medium">
      <HeaderB title={title} returnText={lang.posDevices} onClick={leavePage} />
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue}>
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <Field {...fields.name}>
              <Input required {...fields.name} onChange={modifyField} />
            </Field>
            <Field {...fields.macAddress} className="mt-sm">
              <Text>{fields.macAddress.value}</Text>
            </Field>
            {fields.imei.value ? (
              <Field {...fields.imei} className="mt-sm">
                <Text>{fields.imei.value}</Text>
              </Field>
            ) : null}
            <Field {...fields.serialNumber} className="mt-sm">
              <Text>{fields.serialNumber.value}</Text>
            </Field>
          </Panel>
        </FragmentA>
        <ActionButton
          showLine
          loading={submitting}
          primary={{
            onClick: () => {
              submitFormValue();
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
          danger={deleteDeviceButton}
        />
      </Form>
    </ModuleWrapper>
  );
};

export default DeviceForm;
