import { Button, Panel, Title, Text, Field, Input, Icon } from "components/commons";
import { StyleType } from "enums";
import React, { useState } from "react";
import lang from "translations";

const ChangePassword = ({ active, setActive, form }) => {
  const { fields, modifyField } = form;
  const [visible, setVisible] = useState(false);
  return (
    <Panel>
      <div className="flex w-full justify-between">
        <div>
          <Title>{lang.changePassword}</Title>
          <Text>{lang.chooseAPassword}</Text>
        </div>
        <div>
          <Button type={StyleType.Secondary} onClick={() => setActive(!active)}>
            {active ? lang.cancel : lang.edit}
          </Button>
        </div>
      </div>
      {active && (
        <div className="w-6/12">
          <Field label={lang.currentPassword} {...fields.oldPassword} className="mt-sm">
            <Input {...fields.oldPassword} onChange={modifyField} inputType="password" />
          </Field>
          <Field
            {...fields.password}
            label={lang.newPassWord}
            labelSuffix={
              <div
                className="flex items-center text-blue cursor-pointer"
                onClick={() => setVisible(!visible)}
              >
                <Icon name={visible ? "eye-close" : "eye-open"} />
                <Text className="text-blue" size="text-xs">
                  {visible ? lang.hide : lang.show}
                </Text>
              </div>
            }
            className="mt-sm"
          >
            <Input
              {...fields.password}
              inputType={visible ? "text" : "password"}
              onChange={modifyField}
            />
            <Text italic size="text-xs" color="text-gray">
              {lang.populate(lang.minOfNCharacters, [8])}
            </Text>
          </Field>
          <Field label={lang.confirmPassword} {...fields.confirmPassword} className="mt-sm">
            <Input
              inputType={visible ? "text" : "password"}
              {...fields.confirmPassword}
              onChange={modifyField}
            />
          </Field>
        </div>
      )}
    </Panel>
  );
};

export default ChangePassword;
