import { searchVoucherReport, getVoucherDetailReport, searchVoucherReportExport } from "apis";
import { Button, DataTable, Text, Icon, ButtonLink } from "components/commons";
import { ColumnModal } from "components/modals";
import { VenueContext } from "contexts";
import { useApi, useFilter, useModal, useMount } from "hooks";
import {
  voucherDetailReportListResponse,
  voucherReportListRequest,
  voucherReportListResponse,
} from "mappers";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { mapObject, pluralize, sortByKeyName } from "services";
import { voucherReportFilterState } from "./filters";
import { voucherDetailReportColumn, voucherReportColumn } from "./columns";
import VoucherReportFilter from "./voucher-report-filter";
import lang from "translations";
import { StyleType } from "enums";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { Path } from "paths";
import { ProductNamePopover } from "components/popovers";
import { mixpanel, TrackEvent } from "mixpanel";

const VoucherReport = () => {
  const { venue } = useContext(VenueContext);
  const [showVoucherDetailResults, setShowVoucherDetailResults] = useState();
  const [columnValues, setColumnValues] = useState({
    productRedeemed: true,
    valueRedeemed: true,
    supplyValue: true,
    guestName: true,
    staffName: true,
    locationName: true,
  });

  const columnModal = useModal();

  const {
    request: searchVoucherReportRequest,
    loading: loadingVoucherReports,
    result: searchVoucherReportResult = { data: [], metadata: { total: 0 } },
    mappedData: voucherReportsData,
    error: errorVoucherReports,
  } = useApi({
    api: searchVoucherReport,
    isArray: true,
    mapper: voucherReportListResponse,
  });

  const {
    request: getVoucherDetailReportRequest,
    loading: loadingVoucherDetailReports,
    result: getVoucherDetailReportResult = {
      data: [],
      metadata: { total: 0, totalTransaction: 0 },
    },
    mappedData: voucherDetailReportsData,
    error: errorVoucherDetailReports,
  } = useApi({
    api: getVoucherDetailReport,
    isArray: true,
    mapper: voucherDetailReportListResponse,
  });

  const { request: searchVoucherReportExportRequest, loading: searchVoucherReportExportLoading } =
    useApi({
      api: searchVoucherReportExport,
    });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    voucherReportFilterState(venue.venueId)
  );

  useMount(() => {
    fetchVoucherReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.voucherReport,
    });
  });

  const showVoucherDetailResultsCb = useCallback((filterState) => {
    if (filterState.voucher || filterState.voucher === 0) {
      setShowVoucherDetailResults(true);
    } else {
      setShowVoucherDetailResults(false);
    }
  }, []);

  const fetchVoucherReports = useCallback(
    (filterState, requestState) => {
      searchVoucherReportRequest(mapObject({ ...requestState }, voucherReportListRequest));
      showVoucherDetailResultsCb(filterState);
    },
    [searchVoucherReportRequest, showVoucherDetailResultsCb]
  );

  const fetchVoucherDetailReports = useCallback(
    (filterState, requestState) => {
      getVoucherDetailReportRequest(mapObject({ ...requestState }, voucherReportListRequest));
      showVoucherDetailResultsCb(filterState);
    },
    [getVoucherDetailReportRequest, showVoucherDetailResultsCb]
  );

  const renderName = useCallback((name, voucherId, isVoucherDeleted, index) => {
    return index === 0 ? (
      <Text>{name}</Text>
    ) : isVoucherDeleted ? (
      <Text>{name}</Text>
    ) : (
      <ButtonLink path={Path.VOUCHER_ID(voucherId)}>{name}</ButtonLink>
    );
  }, []);

  const prepareVoucherReports = useCallback(() => {
    return voucherReportsData.map((voucherReport, index) => {
      const { voucherId, name, valueRedeemed, supplyValue, totalUse, isVoucherDeleted } =
        voucherReport;

      return {
        voucherId,
        name: renderName(name, voucherId, isVoucherDeleted, index),
        valueRedeemed,
        supplyValue,
        totalUse,
      };
    });
  }, [voucherReportsData, renderName]);

  const voucherReports = useMemo(() => {
    const vouchers = prepareVoucherReports();
    const top = [];
    const body = [];
    vouchers.forEach((v) => {
      if (v.name === "Totals") {
        top.push(v);
      } else {
        body.push(v);
      }
    });
    body.sort(sortByKeyName("name"));
    const sortedVouchers = [...top, ...body];
    return sortedVouchers;
  }, [prepareVoucherReports]);

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchVoucherReports(filterState, requestState);
    },
    [fetchVoucherReports]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      if (filterState.voucher || filterState.voucher === 0) {
        const { filterState, requestState } = modifyFilters({
          page: 1,
          searchKey,
          sort: { key: "deviceUpdatedDate", value: "desc" },
        });
        fetchVoucherDetailReports(filterState, requestState);
      } else {
        const { filterState, requestState } = modifyFilters({
          page: 1,
          searchKey,
          sort: { key: "supplyValue", value: "desc" },
        });
        fetchVoucherReports(filterState, requestState);
      }
    },
    [fetchVoucherReports, fetchVoucherDetailReports, modifyFilters, filterState.voucher]
  );

  const renderDateAndTime = useCallback((dateAndTime) => {
    if (typeof dateAndTime === "string") {
      return (
        <div>
          <Text>{dateAndTime}</Text>
        </div>
      );
    }
    return (
      <div>
        <Text>{dateAndTime?.date}</Text>
        {dateAndTime?.time && <Text color="text-gray">{dateAndTime.time}</Text>}
      </div>
    );
  }, []);

  const renderTransaction = useCallback((transactionId) => {
    if (transactionId) {
      return (
        <ButtonLink
          onClick={() => {
            window.open(Path.TRANSACTION_ID(transactionId));
          }}
        >{`#${transactionId}`}</ButtonLink>
      );
    }
    return null;
  }, []);

  const renderProductRedeemed = useCallback((productId, productName) => {
    return (
      <ProductNamePopover productId={productId}>
        <a href={"/product/edit/" + productId} target="_blank" rel="noreferrer">
          <Text color="text-blue" className="whitespace-nowrap">
            {productName}
          </Text>
        </a>{" "}
      </ProductNamePopover>
    );
  }, []);

  const renderGuestName = useCallback(
    (guestName, tagUid) => (
      <div>
        <Text>{guestName}</Text>
        <Text tagUid>{tagUid}</Text>
      </div>
    ),
    []
  );

  const prepareVoucherDetailReports = useCallback(() => {
    return voucherDetailReportsData.map((voucherDetailReport) => {
      const {
        dateAndTime,
        transactionId,
        productId,
        productRedeemed,
        valueRedeemed,
        supplyValue,
        guestName,
        guestTagUid,
        staffName,
        locationName,
      } = voucherDetailReport;

      return {
        dateAndTime: renderDateAndTime(dateAndTime),
        transaction: renderTransaction(transactionId),
        productRedeemed: renderProductRedeemed(productId, productRedeemed),
        valueRedeemed,
        supplyValue,
        guestName: renderGuestName(guestName, guestTagUid),
        guestTagUid,
        staffName,
        locationName,
      };
    });
  }, [
    voucherDetailReportsData,
    renderDateAndTime,
    renderTransaction,
    renderProductRedeemed,
    renderGuestName,
  ]);

  const voucherDetailReports = useMemo(() => {
    return prepareVoucherDetailReports();
  }, [prepareVoucherDetailReports]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      if (showVoucherDetailResults) {
        fetchVoucherDetailReports(filterState, requestState);
      } else {
        fetchVoucherReports(filterState, requestState);
      }
    },
    [fetchVoucherReports, fetchVoucherDetailReports, showVoucherDetailResults, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchVoucherReportExportRequest(mapObject({ ...requestState }, voucherReportListRequest));
  }, [searchVoucherReportExportRequest, requestState]);

  const voucherDetailTableRightContent = useMemo(() => {
    if (showVoucherDetailResults) {
      return (
        <div>
          <Button
            type={StyleType.Secondary}
            onClick={() => {
              columnModal.show();
            }}
          >
            {lang.showHideColumns}
          </Button>
        </div>
      );
    }
  }, [showVoucherDetailResults, columnModal]);

  const voucherDetailResultContent = useMemo(() => {
    if (showVoucherDetailResults) {
      return `${getVoucherDetailReportResult.metadata.total} ${pluralize(
        getVoucherDetailReportResult.metadata.total,
        lang.transaction,
        lang.transactions
      )}`;
    }
  }, [getVoucherDetailReportResult.metadata.total, showVoucherDetailResults]);

  const prepareColumns = useCallback(() => {
    if (showVoucherDetailResults) {
      return voucherDetailReportColumn(columnValues);
    }
    return [
      ...voucherReportColumn,
      {
        key: "action",
        actions: true,
        align: "right",
        actionOptions: [
          {
            text: lang.viewDetails,
            onClick: (column) => {
              mixpanel.track(TrackEvent.ClickedButton, {
                Button: lang.viewVoucherDetails,
                Page: lang.voucherReport,
              });

              filterState.voucher = column.voucherId;
              modifyFilter("voucher", {
                value: column.voucherId,
              });
              applyFilterCb();
            },
          },
        ],
        exclude: [0],
      },
    ];
    // eslint-disable-next-line
  }, [columnValues, showVoucherDetailResults, applyFilterCb, modifyFilter]);

  const columns = useMemo(() => {
    return prepareColumns();
  }, [prepareColumns]);

  const onChangeColumnCb = useCallback(
    (name, { value }) => {
      setColumnValues({ ...columnValues, [name]: value });
    },
    [columnValues]
  );

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.voucherReport}
        description={lang.viewAndExportVoucherReport}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchVoucherReportExportLoading,
          disabled: searchVoucherReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <VoucherReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {voucherReports.length > 0 ? (
        <DataTable
          page={filterState.page}
          resultContent={voucherDetailResultContent}
          renderRightContent={voucherDetailTableRightContent}
          pageSize={filterState.pageSize}
          fetchList={showVoucherDetailResults ? fetchVoucherDetailReports : fetchVoucherReports}
          onChangePage={modifyFilters}
          total={
            showVoucherDetailResults
              ? getVoucherDetailReportResult.metadata.total
              : searchVoucherReportResult.metadata.total
          }
          loading={loadingVoucherReports || loadingVoucherDetailReports}
          columns={columns}
          data={showVoucherDetailResults ? voucherDetailReports : voucherReports}
          error={errorVoucherReports || errorVoucherDetailReports}
          sort={filterState.sort}
          setSort={sortCb}
        />
      ) : (
        noResult
      )}

      <ColumnModal
        {...columnModal}
        columns={[
          [
            { text: lang.productRedeemed, name: "productRedeemed" },
            { text: lang.valueRedeemed, name: "valueRedeemed" },
          ],
          [
            { text: lang.supplyValue, name: "supplyValue" },
            { text: lang.guestName, name: "guestName" },
          ],
          [
            { text: lang.staffName, name: "staffName" },
            { text: lang.location, name: "locationName" },
          ],
        ]}
        columnValues={columnValues}
        onChange={onChangeColumnCb}
      />
    </ModuleWrapper>
  );
};

export default VoucherReport;
