import React, { useCallback, useContext, useEffect, useState } from "react";
import lang from "translations";
import { useModal, useApi } from "hooks";
import { Select } from "components/commons";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";
import { createAccountMapping } from "apis/accounting.api";
import { debounced } from "services";
import AccountCodeFormModal from "./account-code-form/account-code-form.modal";
import { getAccountCodes } from "apis";
import { groupAccountOptions } from "services/accounting.service";

const AccountCodeField = ({ modifyField, fields, isMewsIntegrated, options }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const [searchKey, setSearchKey] = useState("");
  const accountCategoryFormModal = useModal();

  const {
    request,
    loading,
    error,
    // mappedData
  } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venueId,
    },
    isArray: true,
    mapper: {
      code: { key: "code" },
      text: { transform: ({ src }) => `${src.code + " - "}${src.name}` },
      value: { key: "id" },
      accountCodeId: { key: "id" },
      accountName: { key: "name" },
    },
    handleOwnError: true,
  });

  const onSave = useCallback(
    async ({ api, message }, fields, success, error) => {
      try {
        const result = await api(fields);
        request();
        return success(result, { message });
      } catch (e) {
        return error(e);
      }
    },
    [request]
  );

  const createRequestApi = useApi({
    api: createAccountMapping,
    params: { businessId: venueId },
  });

  // eslint-disable-next-line
  const onCreateSave = useCallback(
    (fields, success, error) => {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.addPaymentMethod,
        Page: lang.accountMapping,
      });

      return onSave(
        {
          api: createRequestApi.request,
          message: lang.populate(lang.accountCodeAddedName, [fields.code]),
        },
        fields,
        success,
        error
      );
    },
    [createRequestApi, onSave]
  );

  const submitting = createRequestApi.loading;

  // eslint-disable-next-line
  useEffect(
    debounced(1000, () => {
      if (searchKey.trim()) {
        request({
          searchKey: searchKey,
          page: 1,
        });
      }
    }),
    [searchKey]
  );

  return (
    <div>
      <Select
        error={error}
        onChange={modifyField}
        {...fields}
        fetchingOptions={loading}
        options={options}
        groupedOptions={groupAccountOptions(options, fields.suggested)}
        onClick={() => {
          request({
            searchKey: "",
            page: 1,
          });
        }}
        customNotFoundContent={lang.accountNotFound}
        // onScroll={(s) => {
        //   console.log("DIV", s.target.children[0].clientHeight);
        //   console.log("SCROLL", s.target.scrollTop, s.target.clientHeight);
        //   console.log(s.target.clientHeight - 80 === s.target.scrollTop);
        // }}
        // customRenderContent={(menu) => {
        //   const showAddCategory =
        //     !searchKey ||
        //     (searchKey &&
        //       !mappedData.filter((option) => {
        //         return option.text.toLowerCase().trim() === searchKey.toLowerCase().trim();
        //       }).length);
        //   return (
        //     <div>
        //       {isMewsIntegrated && showAddCategory && (
        //         <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
        //           <div
        //             className="p-xs"
        //             onClick={() => {
        //               accountCategoryFormModal.show({
        //                 initialState: {
        //                   name: searchKey,
        //                 },
        //                 title: lang.addNewAccount,
        //                 save: onCreateSave,
        //                 primaryText: lang.addAccount,
        //               });
        //             }}
        //           >
        //             <Text color="text-pelorous" fontWeight="font-bold" className="cursor-pointer">
        //               {searchKey
        //                 ? lang.populate(lang.addNewAccountCodeName, [searchKey])
        //                 : lang.addNewAccountCode}
        //             </Text>
        //           </div>
        //         </div>
        //       )}
        //       {menu}
        //     </div>
        //   );
        // }}
        searchable
        searchValue={searchKey}
        onSearch={(val) => {
          setSearchKey(val);
        }}
      />
      <AccountCodeFormModal {...accountCategoryFormModal} submitting={submitting} />
    </div>
  );
};

export default AccountCodeField;
