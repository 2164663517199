import { ProductType } from "enums";
import { pluralize, prettifyProductType, mapObjects, mapSelectObjectsToValue } from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { toAmount, toPercentage } from "services/number.service";
import { computeMarkUp } from "services/product-price.service";
import { filterRequest, selectToObjectRequest, getCurrentTimeWithTimezone } from "./common.mapper";
import lang from "translations";

export const product = {
  name: {
    key: "productName",
  },
  id: {
    key: "productId",
  },
  type: {
    key: "type",
  },
  productCount: {
    transform: ({ src }) => {
      return src.productSkus?.length || 0;
    },
  },
  hasTax: {
    transform: ({ src }) => {
      return Boolean(src?.tax);
    },
  },
};

export const productRequest = {
  productName: {
    key: "name",
  },
  venueId: {
    key: "venueId",
  },
  productId: {
    key: "id",
  },
};

export const productListResponse = {
  id: { key: "productId" },
  name: { key: "productName" },
  creationType: { key: "creationType" },
  sellableInPos: { key: "sellableInPos" },
  isActiveAllLocations: {
    key: "isActiveAllLocations",
  },
  isActiveAllCategories: {
    key: "isActiveAllCategories",
  },
  isActiveMobileOrdering: {
    key: "isActiveMobileOrdering",
  },
  productLogo: { key: "imageLink" },
  categories: {
    transform: ({ src }) =>
      mapObjects(src.categories, {
        value: { key: "categoryId" },
        text: { key: "categoryName" },
        color: { key: "color" },
      }),
  },
  locations: {
    transform: ({ src }) =>
      mapObjects(src.locations, { value: { key: "locationId" }, text: { key: "locationName" } }),
  },
  hasVariants: {
    transform: ({ src }) => {
      const { attributes = [] } = src || {};
      return attributes?.length;
    },
  },
  productType: { key: "type" },
  type: {
    transform: ({ src }) => {
      const { type, productSkus } = src;
      if (type === ProductType.Variant) {
        return `${productSkus.length} ${pluralize(productSkus.length, "variant", "variants")}`;
      }
      return prettifyProductType(type);
    },
  },
  productSkus: {
    transform: ({ src }) => {
      const { type, productSkus = [] } = src;
      if (type === ProductType.Variant) {
        return productSkus.map((p) => {
          const value = p.productVariants ? mapSelectObjectsToValue(p.productVariants) : [];
          return {
            product: value.join(" · "),
            supplyPrice: formatNumberToMoneyWithCurrencySymbol(p.supplyPrice),
            retailPrice: formatNumberToMoneyWithCurrencySymbol(p.retailPrice),
            markUp: toPercentage(computeMarkUp(p.supplyPrice, p.retailPrice)),
          };
        });
      }
      return [];
    },
  },
  retailPrice: {
    transform: ({ src }) => {
      const { retailPrice } = src;
      return formatNumberToMoneyWithCurrencySymbol(retailPrice);
    },
  },
  retailPriceRange: {
    transform: ({ src }) => {
      if (src.type !== ProductType.Variant) {
        const { retailPrice } = src;
        return formatNumberToMoneyWithCurrencySymbol(retailPrice);
      } else {
        let retailPriceLow = null;
        let retailPriceHigh = null;
        if (src.productSkus) {
          src.productSkus.forEach((productSku) => {
            if (retailPriceLow === null || retailPriceLow > productSku.retailPrice) {
              retailPriceLow = productSku.retailPrice;
            }
            if (retailPriceHigh === null || retailPriceHigh < productSku.retailPrice) {
              retailPriceHigh = productSku.retailPrice;
            }
          });
        }
        return (
          formatNumberToMoneyWithCurrencySymbol(retailPriceLow) +
          " - " +
          formatNumberToMoneyWithCurrencySymbol(retailPriceHigh, true, false, 2, true)
        );
      }
    },
  },
  supplyPrice: {
    transform: ({ src }) => {
      const { supplyCost } = src;
      return formatNumberToMoneyWithCurrencySymbol(supplyCost);
    },
  },
  supplyPriceRange: {
    transform: ({ src }) => {
      if (src.type !== ProductType.Variant) {
        const { supplyCost } = src;
        return formatNumberToMoneyWithCurrencySymbol(supplyCost);
      } else {
        let supplyCostLow = null;
        let supplyCostHigh = null;
        if (src.productSkus) {
          src.productSkus.forEach((productSku) => {
            if (supplyCostLow === null || supplyCostLow > productSku.supplyPrice) {
              supplyCostLow = productSku.supplyPrice;
            }
            if (supplyCostHigh === null || supplyCostHigh < productSku.supplyPrice) {
              supplyCostHigh = productSku.supplyPrice;
            }
          });
        }
        return (
          formatNumberToMoneyWithCurrencySymbol(supplyCostLow) +
          " - " +
          formatNumberToMoneyWithCurrencySymbol(supplyCostHigh, true, false, 2, true)
        );
      }
    },
  },
  markUp: {
    transform: ({ src }) => {
      const { markUp } = src;

      return toPercentage(markUp);
    },
  },
  markUpRange: {
    transform: ({ src }) => {
      if (src.type !== ProductType.Variant) {
        const { supplyCost, retailPrice } = src;
        return toPercentage(computeMarkUp(supplyCost, retailPrice));
      } else {
        let markUpLow = null;
        let markUpHigh = null;

        if (src.productSkus) {
          src.productSkus.forEach((productSku) => {
            let markUpFinal = computeMarkUp(productSku.supplyPrice, productSku.retailPrice);
            if (markUpLow === null || markUpLow > markUpFinal) {
              markUpLow = markUpFinal;
            }
            if (markUpHigh === null || markUpHigh < markUpFinal) {
              markUpHigh = markUpFinal;
            }
          });
        }
        return toPercentage(markUpLow) + " - " + toPercentage(markUpHigh, true, false, 2, true);
      }
    },
  },
  stockLevel: { key: "stockLevel" },
  activeLocation: {
    transform: ({ src }) => {
      let location = null;
      if (src.sellableInPos && !src.isActiveMobileOrdering) {
        location = lang.posOnly;
      } else if (src.isActiveMobileOrdering && !src.sellableInPos) {
        location = lang.onlineMenuOnly;
      } else if (!src.sellableInPos && !src.isActiveMobileOrdering) {
        location = lang.disabled;
      } else {
        location = lang.posAndOnlineMenu;
      }
      return location;
    },
  },
};

export const productListFilterRequest = {
  ...filterRequest,
  categoryIds: selectToObjectRequest("categories"),
  locationIds: selectToObjectRequest("locations"),
  productStockLevels: selectToObjectRequest("productStockLevels"),
  posStatus: { key: "posStatus" },
  orders: {
    transform: ({ src }) => {
      return { [`p.createdDate`]: src.sort.value };
    },
  },
};

export const topProduct = {
  id: {
    key: "productId",
  },
  name: {
    key: "productName",
  },
  unitSold: {
    transform: ({ src }) => {
      return toAmount(src.unitSold);
    },
  },
};

export const topCategory = {
  id: {
    key: "categoryId",
  },
  name: {
    key: "categoryName",
  },
  unitSold: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.unitSold);
    },
  },
};

export const outOfStockProduct = {
  name: {
    transform: ({ src }) => {
      let name = src.productName;
      src.productVariants?.forEach((v) => {
        name = `${name} · ${v.value}`;
      });
      return name;
    },
  },
  location: {
    key: "locationName",
  },
  quantity: {
    key: "stock",
  },
};

export const productReportListResponse = {
  name: { key: "productName" },
  sku: { key: "sku" },
  productSkuId: { key: "productSkuId" },
  stock: { key: "stock" },
  unit: { key: "unit" },
  parLevel: {
    transform: ({ src }) => {
      const { parLevel, unit } = src;
      return `${parLevel} ${unit}`.trim();
    },
  },
  reorderPoint: {
    transform: ({ src }) => {
      const { reorderPoint, unit } = src;
      return `${reorderPoint} ${unit}`.trim();
    },
  },
  stockVariance: { key: "stockVariance" },
};

export const productReportListRequest = {
  ...filterRequest,
  belowParLevel: {
    transform: ({ src }) => {
      if (src.measurement === "lowParLevel") {
        return true;
      }
      return false;
    },
  },
  belowReorderPoint: {
    transform: ({ src }) => {
      if (src.measurement === "reorderPointReached") {
        return true;
      }
      return false;
    },
  },
  locationIds: selectToObjectRequest("locations"),
  orders: {
    transform: ({ src }) => {
      if (src.sort.key === "name") {
        return { [`p.productName`]: src.sort.value };
      } else if (src.sort.key === "stockVariance") {
        return { [`variance`]: src.sort.value };
      } else {
        return null;
      }
    },
  },
  requestedAt: getCurrentTimeWithTimezone(),
};

export const batchProductResponse = {
  id: { key: "productId" },
  name: { key: "productName" },
  type: { key: "type" },
  productSkus: {
    transform: ({ self }) => {
      return mapObjects(self, {
        partOfCompositions: {
          transform: ({ self }) => {
            return mapObjects(self, {
              productId: {
                transform: ({ src }) => {
                  return src.product.productId;
                },
              },
              productName: {
                transform: ({ src }) => {
                  return src.product.productName;
                },
              },
            });
          },
        },
        productVariants: { key: "productVariants" },
      });
    },
  },
};

export const browseProductCategoryListRequest = {
  venueId: { key: "venueId" },
  page: 1,
  searchKey: { key: "searchKey" },
  categoryIds: {
    transform: ({ src }) => {
      if (src.categoryId) {
        return [src.categoryId];
      }
    },
  },
  locationIds: selectToObjectRequest("locations"),
  orders: {
    transform: () => {
      return { [`p.productName`]: "asc" };
    },
  },
};

export const productStocks = {
  stocks: {
    transform: ({ src }) => {
      const type = src.type;
      const { unit } = src.measurement;
      return mapObjects(src.productSkus, {
        locationStocks: {
          transform: ({ src }) =>
            mapObjects(src.locationStocks, {
              locationId: { key: "locationId" },
              locationName: { key: "locationName" },
              stock: {
                transform: ({ self }) => `${self} ${unit}`,
              },
            }),
        },
        productVariants: {
          transform: ({ self }) => {
            if (type === ProductType.Variant) {
              return mapObjects(self, { _keys: ["attribute", "value"] });
            }
            return [];
          },
        },
      });
    },
  },
};

export const browseProductCategoryListResponse = {
  id: { key: "productId" },
  name: { key: "productName" },
  type: { key: "type" },
  categories: {
    transform: ({ self }) =>
      mapObjects(self, { id: { key: "categoryId" }, name: { key: "categoryName" } }),
  },
  productSkus: {
    transform: ({ self }) => {
      return mapObjects(self, {
        productSkuId: { key: "productSkuId" },
        productVariants: { key: "productVariants" },
      });
    },
  },
};

export const supplyItemRequest = {
  venueId: {
    key: "venueId",
  },
  measurementId: {
    key: "measurementId",
  },
  productName: {
    key: "name",
  },
  productId: {
    key: "id",
  },
  description: {
    key: "description",
  },
  orderQuantity: {
    key: "orderQuantity",
  },
  pricePerOrderQuantity: {
    key: "pricePerOrderQuantity",
  },
  sellableInPos: {
    key: "sellableInPos",
  },
  sku: {
    key: "sku",
  },
  productSku: {
    key: "productSku",
  },
  supplyAccountCodeId: { key: "supplyAccountCodeId" },
  weightInGrams: { key: "weightInGrams" },
};

export const productSetRequest = {
  productLogo: { key: "imageLink" },
  productName: { key: "productName" },
  description: { key: "description" },
  category: {
    transform: ({ src }) =>
      mapObjects(src.categories, { value: { key: "categoryId" }, text: { key: "categoryName" } }),
  },
  activePos: { key: "sellableInPos" },
  activeOnlineMenu: { key: "isActiveMobileOrdering" },
  location: {
    transform: ({ src }) =>
      mapObjects(src.locations, { value: { key: "locationId" }, text: { key: "locationName" } }),
  },
  sku: {
    transform: ({ src }) => {
      return src?.productSkus[0].sku;
    },
  },
  products: {
    transform: ({ src }) =>
      mapObjects(src.productSkus[0].compositions, {
        productName: { key: "productName" },
        productSkuId: { key: "productSkuId" },
        quantity: { key: "quantity" },
        price: { key: "supplyPrice" },
        measurement: { key: "measurement" },
        type: { key: "type" },
        creationType: { key: "creationType" },
      }),
  },
  hasTax: {
    transform: ({ src }) => {
      return Boolean(src?.tax);
    },
  },
  retailPrice: {
    transform: ({ src }) => {
      return src?.productSkus[0].retailPrice;
    },
  },
  tax: {
    transform: ({ src }) => {
      return src?.tax?.taxId;
    },
  },
  isActiveAllLocations: {
    key: "isActiveAllLocations",
  },
  productSkuId: {
    transform: ({ src }) => {
      return src?.productSkus[0].productSkuId;
    },
  },
  isActiveAllCategories: {
    key: "isActiveAllCategories",
  },
  revenueAccountCodeId: {
    key: "revenueAccountCodeId",
  },
  cogsAccountCodeId: {
    key: "cogsAccountCodeId",
  },
};

export const productInfoResponse = {
  id: { key: "productId" },
  name: { key: "productName" },
  sellableInPos: { key: "sellableInPos" },
  isActiveAllLocations: {
    key: "isActiveAllLocations",
  },
  isActiveAllCategories: {
    key: "isActiveAllCategories",
  },
  productLogo: { key: "imageLink" },
  stockLevel: { key: "stockLevel" },
  categories: {
    transform: ({ src }) =>
      mapObjects(src.categories, {
        value: { key: "categoryId" },
        text: { key: "categoryName" },
        color: { key: "color" },
      }),
  },
  locations: {
    transform: ({ src }) =>
      mapObjects(src.locations, { value: { key: "locationId" }, text: { key: "locationName" } }),
  },
  retailPriceRange: {
    transform: ({ src }) => {
      if (src.type !== ProductType.Variant) {
        const { retailPrice } = src;
        return formatNumberToMoneyWithCurrencySymbol(retailPrice);
      } else {
        let retailPriceLow = null;
        let retailPriceHigh = null;
        if (src.productSkus) {
          src.productSkus.forEach((productSku) => {
            if (retailPriceLow === null || retailPriceLow > productSku.retailPrice) {
              retailPriceLow = productSku.retailPrice;
            }
            if (retailPriceHigh === null || retailPriceHigh < productSku.retailPrice) {
              retailPriceHigh = productSku.retailPrice;
            }
          });
        }
        return (
          formatNumberToMoneyWithCurrencySymbol(retailPriceLow) +
          " - " +
          formatNumberToMoneyWithCurrencySymbol(retailPriceHigh)
        );
      }
    },
  },
  supplyPriceRange: {
    transform: ({ src }) => {
      if (src.type !== ProductType.Variant) {
        const { supplyCost } = src;
        return formatNumberToMoneyWithCurrencySymbol(supplyCost);
      } else {
        let supplyCostLow = null;
        let supplyCostHigh = null;
        if (src.productSkus) {
          src.productSkus.forEach((productSku) => {
            if (supplyCostLow === null || supplyCostLow > productSku.supplyPrice) {
              supplyCostLow = productSku.supplyPrice;
            }
            if (supplyCostHigh === null || supplyCostHigh < productSku.supplyPrice) {
              supplyCostHigh = productSku.supplyPrice;
            }
          });
        }
        return (
          formatNumberToMoneyWithCurrencySymbol(supplyCostLow) +
          " - " +
          formatNumberToMoneyWithCurrencySymbol(supplyCostHigh)
        );
      }
    },
  },
  markUpRange: {
    transform: ({ src }) => {
      if (src.type !== ProductType.Variant) {
        const { supplyCost, retailPrice } = src;
        return toPercentage(computeMarkUp(supplyCost, retailPrice));
      } else {
        let markUpLow = null;
        let markUpHigh = null;

        if (src.productSkus) {
          src.productSkus.forEach((productSku) => {
            let markUpFinal = computeMarkUp(productSku.supplyPrice, productSku.retailPrice);
            if (markUpLow === null || markUpLow > markUpFinal) {
              markUpLow = markUpFinal;
            }
            if (markUpHigh === null || markUpHigh < markUpFinal) {
              markUpHigh = markUpFinal;
            }
          });
        }
        return toPercentage(markUpLow) + " - " + toPercentage(markUpHigh, true, false, 2, true);
      }
    },
  },
};
