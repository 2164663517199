import React, { useContext, useMemo } from "react";
import { HeaderA } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import { useApi, useMount, useRouter, useFilter } from "hooks";
import { searchGuestProfile } from "apis/guest-profile.api";
import { ButtonLink, DataTable, Text } from "components/commons";
import { guestProfileResponse } from "mappers";
import { v4 as uuidv4 } from "uuid";
import { sortByKeyName } from "services";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";

const GuestProfileList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const {
    request,
    loading = true,
    error,
    result = { data: [] },
    mappedData,
  } = useApi({
    api: searchGuestProfile,
    isArray: true,
    mapper: guestProfileResponse,
    params: {
      orders: {
        "gp.guestProfileDefault": "desc",
        "gp.createdDate": "desc",
      },
      venueId,
    },
  });

  const { modifyFilters, filterState } = useFilter({
    page: 1,
    pageSize: 20,
    venueId,
  });

  useMount(() => {
    request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.guestProfileList,
    });
  });

  const renderData = useMemo(() => {
    return mappedData.map(({ name, id, vouchers, postpaid, freeCredit }) => {
      let newPreloaded = [];
      let preloaded = [];
      for (const v of vouchers) {
        newPreloaded.push({ name: v.name, count: v.quantity, id: v.value });
      }
      newPreloaded.sort(sortByKeyName("name"));
      newPreloaded.forEach((a) => {
        preloaded.push({ name: a.name.trim(), id: a.id, count: a.count });
      });

      return {
        id,
        name: (
          <ButtonLink
            className="text-left"
            // onClick={() => history.push(Path.GUEST_PROFILE_ID(id))}
            path={Path.GUEST_PROFILE_ID(id)}
          >
            {name}
          </ButtonLink>
        ),
        preloaded: preloaded ? (
          <div>
            {preloaded.length
              ? preloaded.map((voucher) => {
                  return (
                    <div key={uuidv4()} className="flex">
                      <Text className="text-sm mr-xs">{voucher.count} x</Text>
                      <ButtonLink path={Path.VOUCHER_ID(voucher.id)}>{voucher.name}</ButtonLink>
                    </div>
                  );
                })
              : "-"}
          </div>
        ) : null,
        postpaid,
        freeCredit,
      };
    });
  }, [mappedData]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.guestProfiles}
        description={lang.manageAndAssignQuestions}
        button={{
          path: Path.GUEST_PROFILE_CREATE,
          text: lang.createNewProfile,
        }}
        className="mb-md"
      />
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={request}
        total={result.metadata?.total}
        loading={loading}
        columns={[
          {
            key: "name",
            text: lang.guestProfileName,
            custom: true,
          },
          {
            key: "freeCredit",
            text: lang.freeCredits,
            align: "right",
          },
          {
            key: "postpaid",
            text: lang.postpaidCredits,
            align: "right",
          },
          {
            key: "preloaded",
            text: lang.preloadedVouchers,
            custom: true,
          },
          {
            key: "action",
            actions: true,
            align: "right",
            actionTitle: lang.options,
            actionOptions: [
              {
                text: lang.edit,
                onClick: (column) => {
                  history.push(Path.GUEST_PROFILE_ID(column.id));
                },
              },
            ],
          },
        ]}
        data={renderData}
        error={error}
      />
    </ModuleWrapper>
  );
};

export default GuestProfileList;
