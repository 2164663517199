import field from "enums/field";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { reason = null, other = "" } = initialState;
  return {
    reason: {
      name: "reason",
      value: reason,
      type: field.DROPDOWN,
      placeholder: lang.chooseAReason,
      options: [
        {
          text: lang.lostTag,
          value: "Lost Tag",
        },
        {
          text: lang.defectiveTag,
          value: "Defective Tag",
        },
        {
          text: lang.other,
          value: "Other",
        },
      ],
    },
    other: {
      name: "other",
      maxLength: 255,
      type: field.INPUT,
      value: other,
      placeholder: lang.typeAReason,
    },
  };
};

export default initialFormState;
