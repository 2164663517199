export const outletMapper = {
  outletId: {
    key: "id",
  },
  outletName: {
    key: "name",
  },
  active: {
    key: "active",
  },
};
