import { Input, Field } from "components/commons";
import React from "react";
import { isAlphaNumeric } from "services/text.service";

const SkuField = ({ sku, generatedSku = {} }) => {
  return (
    <Field {...sku}>
      <Input
        {...sku}
        disabled={generatedSku.value}
        onChange={(name, { value }) => {
          if (!isAlphaNumeric(value) && value) {
            return;
          }
          sku.onChange(name, {
            value: value.trim(),
          });
        }}
      />
    </Field>
  );
};

export default SkuField;
