import { Delete, Get, Post } from "services";
import { ApiPath } from "paths";

export const getDiscount = async ({ discountId }) => {
  const res = await Get(`${ApiPath.DISCOUNT_ID(discountId)}`);
  return res;
};

export const searchDiscount = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_DISCOUNT}`, body);
  return res;
};

export const createDiscount = async (body) => {
  const res = await Post(`${ApiPath.DISCOUNT}`, body);
  return res;
};

export const editDiscount = async ({ discountId, ...body }) => {
  const res = await Post(`${ApiPath.DISCOUNT_ID(discountId)}`, body);
  return res;
};

export const deleteDiscount = async ({ discountId }) => {
  const res = await Delete(`${ApiPath.DISCOUNT_ID(discountId)}`);
  return res.data;
};

export const updateDiscountPos = async ({ discountId, sellableOnPos }) => {
  const res = await Post(`${ApiPath.UPDATE_DISCOUNT_POS({ sellableOnPos, discountId })}`, {});
  return res;
};

export const searchDiscountReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_DISCOUNT_REPORT}`, body);
  return res;
};

export const searchDiscountReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_DISCOUNT_REPORT_EXPORT}`, body);
  return res;
};

export const getDiscountDetailReport = async ({ discountId, ...body }) => {
  const res = await Post(`${ApiPath.DISCOUNT_REPORT(discountId)}`, body);
  return res;
};

export const searchDiscountWithTransaction = async ({ venueId, ...body }) => {
  const res = await Post(`${ApiPath.DISCOUNT_WITH_TRANSACTION(venueId)}`, body);
  return res;
};
