import React from "react";
import { Radio as AntRadio } from "antd";
import classnames from "classnames";
import { Text } from "..";
import styles from "./radio.module.scss";

const Radio = ({ text, disabled, value, className, alternateText }) => {
  return (
    <AntRadio
      className={classnames("flex items-center -mb-xxs", styles.container, className)}
      value={value}
      disabled={disabled}
    >
      <Text className="mt-sm">{text}</Text>
      {alternateText && <Text className="text-gray">{alternateText}</Text>}
    </AntRadio>
  );
};

export default Radio;
