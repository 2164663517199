import { Delete, Get, Post } from "services";
import { ApiPath } from "paths";

export const searchStaffProfile = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_STAFF_PROFILE}`, body);
  return res;
};

export const createStaffProfile = async (body) => {
  const res = await Post(`${ApiPath.STAFF_PROFILE}`, body);
  return res;
};

export const getStaffProfile = async ({ staffProfileId }) => {
  const res = await Get(`${ApiPath.STAFF_PROFILE_ID(staffProfileId)}`, {});
  return res;
};

export const editStaffProfile = async (body) => {
  const res = await Post(`${ApiPath.STAFF_PROFILE_ID(body.staffProfileId)}`, body);
  return res;
};

export const deleteStaffProfile = async (body) => {
  const res = await Delete(`${ApiPath.STAFF_PROFILE_ID(body.staffProfileId)}`, body);
  return res;
};
