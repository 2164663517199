import React from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { BarStackChart, LineChart } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

const TotalTopUpsChart = ({ ...props }) => {
  const barStackChart = props.mappedData.barChartData;
  const lineChart = props.mappedData.lineChartData;
  return (
    <ChartContainer
      title={lang.totalTopUps}
      {...props}
      empty={!props.mappedData.total}
      emptyText={lang.noSalesInDateRange}
    >
      <BarStackChart
        data={barStackChart}
        renderValue={(v) => {
          return formatNumberToMoneyWithCurrencySymbol(v);
        }}
      />
      <LineChart
        {...lineChart}
        formatToolTipValue={(v) => {
          return formatNumberToMoneyWithCurrencySymbol(v.value);
        }}
        // formatYAxis={(label) => {
        //   return formatNumberToMoneyWithCurrencySymbol(label);
        // }}
      />
    </ChartContainer>
  );
};

export default TotalTopUpsChart;
