import React, { Fragment, useCallback, useContext, useMemo } from "react";
import CategoryForm from "../category-form/category-form";
import lang from "translations";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { deleteCategory, editCategory, getCategory } from "apis";
import { mapObject } from "services";
import { categoryFormRequest, categoryResponse } from "mappers";
import { VenueContext } from "contexts";
import { Path } from "paths";
import { DeleteModal } from "components/modals";
import { Toast } from "components/commons";
import { mixpanel, TrackEvent } from "mixpanel";

const EditCategory = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const { venue } = useContext(VenueContext);

  const deleteModal = useModal();

  const {
    request: getCategoryRequest,
    loading = true,
    error,
    mappedData,
  } = useApi({
    api: getCategory,
    mapper: categoryResponse,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: editCategoryRequest, loading: submitting } = useApi({
    api: editCategory,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: deleteCategoryRequest, loading: deleting } = useApi({
    api: deleteCategory,
  });

  useMount(() => {
    getCategoryRequest({ categoryId: id });
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editCategory,
    });
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await editCategoryRequest(
          mapObject({ ...params, venueId: venue.venueId, id }, categoryFormRequest)
        );
        return {
          response: res,
          message: lang.populate(lang.categoryUpdated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [editCategoryRequest, venue.venueId, id]
  );

  const { name, color, description, outletId, isPosAvailable, id: categoryId } = mappedData;

  const initialState = useMemo(() => {
    return {
      name: name,
      description: description,
      outletId: outletId,
      color: color,
      isPosAvailable: isPosAvailable,
    };
  }, [name, color, description, outletId, isPosAvailable]);

  return (
    <Fragment>
      <CategoryForm
        loading={loading}
        initialState={initialState}
        submit={submitForm}
        title={name}
        submitting={submitting}
        deleting={deleting}
        error={error}
        deleteCategoryButton={{
          text: lang.deleteCategory,
          onClick: () => {
            deleteModal.show({
              ok: async () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: "Delete Category",
                  Page: lang.editCategory,
                });

                await deleteCategoryRequest({ categoryId });
                Toast({
                  content: lang.populate(lang.categoryDeleted, [name]),
                  success: true,
                  icon: "check",
                }).open();
                history.push(Path.CATEGORY);
                deleteModal.close();
              },
            });
          },
        }}
      />
      <DeleteModal {...deleteModal} subject={lang.category} subjectName={name} />
    </Fragment>
  );
};

export default EditCategory;
