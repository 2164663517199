import { ModuleWrapper } from "components/fragments";
import React, { useCallback, useMemo, useState } from "react";
import lang from "translations";
import { Button, ButtonLink, Field, Image, Input, Text, Title, Toast } from "components/commons";
import { ActivateStaff } from "images";
import { useApi, useForm, useRouter } from "hooks";
import { Field as FieldType } from "enums";
import Validation from "services/validation.service";
import BusinessPath from "paths/page/business.path";
import { activateStaff } from "apis/staff.api";
import classNames from "classnames";
import { mixpanel, TrackEvent } from "mixpanel";

const RegisterStaff = () => {
  const { query, history } = useRouter();
  const { token } = query || {};
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const { request, loading } = useApi({
    api: activateStaff,
    params: {
      token,
    },
  });

  const initialState = useMemo(() => {
    return {
      firstName: {
        name: "firstName",
        value: "",
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
        placeholder: "John",
        filterLabel: lang.firstName,
      },
      lastName: {
        name: "lastName",
        value: "",
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
        filterLabel: lang.lastName,
        placeholder: "Appleseed",
      },
      password: {
        name: "password",
        value: "",
        type: FieldType.INPUT,
        validations: [Validation.required(), Validation.maxlength(16), Validation.minlength(8)],
        required: true,
        minLength: 8,
        maxLength: 16,
        filterLabel: lang.password,
        placeholder: lang.choosePassword,
      },
      confirmPassword: {
        name: "confirmPassword",
        value: "",
        type: FieldType.INPUT,
        validations: [Validation.required(), Validation.maxlength(16), Validation.minlength(8)],
        required: true,
        minLength: 8,
        maxLength: 16,
        filterLabel: lang.confirmPassword,
        placeholder: lang.choosePassword,
      },
    };
  }, []);

  const { fields, modifyField, submitForm, getFormValues } = useForm({
    initialState,
  });

  const submit = useCallback(async () => {
    try {
      if (passwordsMatch && fields.confirmPassword.value.length > 0) {
        const params = getFormValues();
        await request({
          ...params,
          token: token,
          passwordRequest: {
            password: params.password,
          },
        });
        Toast({
          content: lang.youCanNowLogin,
          success: true,
          icon: "check",
        }).open();
        history.push("/");
      }
    } catch (e) {
      console.error(e);
    }
  }, [getFormValues, history, request, token, passwordsMatch, fields.confirmPassword.value]);

  const validatePasswords = useCallback(
    (value1, value2) => {
      let match = value1 === value2;
      if (
        fields.confirmPassword &&
        fields.confirmPassword.value &&
        fields.confirmPassword.value.length > 0
      ) {
        setPasswordsMatch(match);
      } else {
        setPasswordsMatch(true);
      }
    },
    [setPasswordsMatch, fields.confirmPassword]
  );

  return (
    <ModuleWrapper>
      <div className="flex">
        <div>
          <Image src={ActivateStaff} />
        </div>
        <div className="max-w-7xl w-96">
          <Title xl>{lang.setUpYourAccount}</Title>
          <div className="mt-lg">
            <Field {...fields.firstName} className="mt-md">
              <Input {...fields.firstName} onChange={modifyField} />
            </Field>
            <Field {...fields.lastName} className="mt-md">
              <Input {...fields.lastName} onChange={modifyField} />
            </Field>
            <Field
              {...fields.password}
              labelSuffix={
                <ButtonLink
                  onClick={() => {
                    setPasswordStatus(!passwordStatus);
                  }}
                  prefix
                  icon={passwordStatus ? "eye-open" : "eye-close"}
                >
                  {passwordStatus ? lang.show : lang.hide}
                </ButtonLink>
              }
              className="mt-md"
            >
              <Input
                {...fields.password}
                inputType={passwordStatus ? "password" : ""}
                onChange={(name, obj) => {
                  modifyField(name, obj);
                  validatePasswords(obj.value, fields.confirmPassword.value);
                }}
              />
            </Field>
            <div className="flex align-center items-center mt-sm">
              <div className={classNames("h-2 w-2 rounded-lg cursor-pointer bg-blue mr-xs")}></div>
              <Text sm className="text-gray-500">
                {lang.eightCharactersMinimum}
              </Text>
            </div>
            <Field
              {...fields.confirmPassword}
              customMessage={!passwordsMatch ? <Text error>{lang.passwordsDoNotMatch}</Text> : null}
              className="mt-md"
            >
              <Input
                {...fields.confirmPassword}
                error={!passwordsMatch || fields.confirmPassword.error}
                inputType={passwordStatus ? "password" : ""}
                onChange={(name, obj) => {
                  modifyField(name, obj);
                  validatePasswords(obj.value, fields.password.value);
                }}
              />
            </Field>
          </div>
          <div className="mt-lg flex items-center">
            <div>
              <Button
                loading={loading}
                disabled={loading}
                onClick={() => {
                  mixpanel.track(TrackEvent.ClickedButton, {
                    Button: lang.registerStaff,
                    Page: lang.inviteNewStaff,
                  });
                  submitForm(submit);
                }}
              >
                {lang.signUp}
              </Button>
            </div>
            <div className="ml-md mt-xs leading-none">
              <Text>
                <lang.Translate
                  className="text-xs"
                  text={lang.termsInSignUp}
                  items={[
                    <ButtonLink
                      className="text-xs text-blue"
                      flex={false}
                      onClick={() => window.open(BusinessPath.TERMS_CONDITION)}
                    >
                      {lang.termsOfService}
                    </ButtonLink>,
                    <ButtonLink
                      className="text-xs text-blue"
                      flex={false}
                      onClick={() => window.open(BusinessPath.PRIVACY)}
                    >
                      {lang.privacyPolicy}
                    </ButtonLink>,
                    <ButtonLink
                      className="text-xs text-blue"
                      flex={false}
                      onClick={() => window.open(BusinessPath.COMMUNITY_GUIDELINES)}
                    >
                      {lang.communityGuidelines}
                    </ButtonLink>,
                  ]}
                />
              </Text>
            </div>
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default RegisterStaff;
