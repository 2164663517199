import React, { useCallback } from "react";
import { ActionButton, Modal } from "components/commons";
import lang from "translations";
import { useApi, useRouter } from "hooks";
import { voidStocktake } from "apis/stocktake.api";
import { formatId, parseAmountToNumber } from "services";
import { StyleType } from "enums";

const VoidStocktakeModal = ({
  stocktakeId,
  venueId,
  profileId,
  refreshList,
  onSave = undefined,
  formValues,
  locationId,
  ...props
}) => {
  const { history } = useRouter();
  const { request: voidStocktakeRequest } = useApi({
    api: voidStocktake,
  });

  const voidStocktakeCb = useCallback(async () => {
    try {
      let stocktakeItems = [];
      if (formValues && onSave) {
        formValues.stocktakeItems.forEach((item) => {
          const { productId, productSkuId, actualStockQty, expectedStockQty } = item.value;
          const actualQty =
            actualStockQty.value !== "" &&
            actualStockQty.value !== null &&
            actualStockQty.value !== undefined
              ? parseAmountToNumber(actualStockQty.value)
              : null;
          stocktakeItems.push({
            productId: productId.value,
            productSkuId: productSkuId.value,
            expectedStockLevel: parseAmountToNumber(expectedStockQty.value),
            actualStockLevel: actualQty,
          });
        });
      }

      let params = {
        staffProfileId: profileId,
        venueId,
        stocktakeId,
        stocktakeItems,
        locationId,
      };

      if (onSave) {
        await onSave({
          stocktakeId,
          body: params,
        });
      }

      await voidStocktakeRequest({ stocktakeId, body: params });
      props.close();
      refreshList();
    } catch ({ code, handleError }) {
      console.log(code);
    }
    // eslint-disable-next-line
  }, [voidStocktakeRequest, stocktakeId, venueId, profileId, history]);

  return (
    <Modal
      title={lang.populate(lang.voidId, [`ST-${formatId(stocktakeId)}`])}
      actionContent={
        <ActionButton
          showLine={false}
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              props.close();
            },
          }}
          primary={{
            text: lang.voidStocktake,
            type: StyleType.Danger,
            onClick: () => {
              voidStocktakeCb();
            },
          }}
        />
      }
      {...props}
    >
      {
        <lang.Translate
          text={lang.voidConfirmation}
          items={[<span className="font-semibold">{`ST-${formatId(stocktakeId)}`}</span>]}
        />
      }
    </Modal>
  );
};

export default VoidStocktakeModal;
