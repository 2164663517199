import React from "react";
import { HeaderB } from "components/headers";
import lang from "translations";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { Panel, PageError } from "components/commons";
import { Skeleton } from "antd";

const DeviceFormSkeleton = ({ title, error, loading, path }) => {
  return (
    <ModuleWrapper width="full">
      <HeaderB title={title} loading={loading} returnPath={path} />
      {error ? (
        <PageError />
      ) : (
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <Skeleton width="100%" active={true} />
          </Panel>
        </FragmentA>
      )}
    </ModuleWrapper>
  );
};

export default DeviceFormSkeleton;
