const discountPaths = {
  DISCOUNT: "",
  SEARCH_DISCOUNT: "search",
  DISCOUNT_ID: (discountId) => discountId,
  UPDATE_DISCOUNT_POS: ({ discountId, sellableOnPos }) =>
    `${discountId}/pos?sellableInPos=${sellableOnPos}`,
  SEARCH_DISCOUNT_REPORT: "search/report",
  SEARCH_DISCOUNT_REPORT_EXPORT: "search/report/export",
  DISCOUNT_REPORT: (discountId) => `${discountId}/report`,
  DISCOUNT_CREATE: "create",
};

export default discountPaths;
