import React, { useCallback, useContext, useMemo, useState } from "react";
import DiscountForm from "../discount-form/discount-form";
import lang from "translations";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { VenueContext } from "contexts";
import { deleteDiscount, editDiscount, getDiscount, searchProduct } from "apis";
import { discountFormRequest, discountFormResponse } from "mappers";
import { Toast } from "components/commons";
import { Path } from "paths";
import { DeleteModal } from "components/modals";
import { mapObjects } from "services";
import { mixpanel, TrackEvent } from "mixpanel";

const EditDiscount = () => {
  const [linkedProductsMap, setLinkedProductsMap] = useState();
  const { query, history } = useRouter();
  const { id } = query;

  const { venue } = useContext(VenueContext);

  const deleteModal = useModal();

  const {
    request: getDiscountRequest,
    loading = true,
    error,
    mappedData,
  } = useApi({
    api: getDiscount,
    mapper: discountFormResponse,
  });

  const { request: editDiscountRequest, loading: submitting } = useApi({
    api: editDiscount,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: discountFormRequest,
  });

  const { request: searchProductRequest, loading: loadingProduct } = useApi({
    api: searchProduct,
    isArray: true,
  });

  const { request: deleteDiscountRequest, loading: deleting } = useApi({
    api: deleteDiscount,
  });

  useMount(() => {
    fetchDiscounts();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editDiscountsPage,
    });
  });

  const fetchDiscounts = useCallback(async () => {
    const { data } = await getDiscountRequest({ discountId: id });
    const { data: products } = await searchProductRequest({
      venueId: venue.venueId,
      locationIds: data.locations.map((l) => l.locationId),
    });

    const productCategories = groupProductsByCategory(
      mapObjects(products, {
        id: { key: "productId" },
        name: { key: "productName" },
        categories: {
          transform: ({ self }) =>
            mapObjects(self, { id: { key: "categoryId" }, name: { key: "categoryName" } }),
        },
      })
    );

    const map = new Map();

    productCategories.forEach((pc) => {
      const productMap = new Map();

      data.discountProductSkus.forEach((dps) => {
        const { productId, productName, productSku } = dps;

        if (checkIfProductBelongsToCategory(productId, pc.id, products)) {
          if (productMap.has(productName)) {
            productMap.set(productName, [...productMap.get(productName), productSku.productSkuId]);
          } else {
            productMap.set(productName, [productSku.productSkuId]);
          }
        }
      });

      map.set(pc.name, productMap);
    });

    setLinkedProductsMap(map);
  }, [getDiscountRequest, id, searchProductRequest, venue]);

  const initialState = useMemo(() => {
    const {
      name,
      description,
      posStatus,
      type,
      value,
      dateRange,
      noEndDate,
      productSkuIds,
      locations,
      availableAnytime,
      managerAuthorization,
    } = mappedData;

    return {
      posStatus,
      name,
      description,
      type,
      value,
      dateRange,
      noEndDate,
      productSkuIds,
      locations,
      availableAnytime,
      managerAuthorization,
    };
  }, [mappedData]);

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await editDiscountRequest({
          ...params,
          venueId: venue.venueId,
          id: mappedData.id,
        });
        return {
          response: res,
          message: lang.populate(lang.voucherUpdated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [editDiscountRequest, mappedData, venue]
  );

  const deleteDiscountCb = useCallback(async () => {
    await deleteDiscountRequest({ discountId: mappedData.id });
    Toast({
      content: lang.populate(lang.discountDeleted, [mappedData.name]),
      success: true,
      icon: "check",
    }).open();
    history.push(Path.DISCOUNT);
    deleteModal.close();
  }, [deleteModal, deleteDiscountRequest, history, mappedData]);

  return (
    <div>
      <DiscountForm
        loading={loading || loadingProduct}
        initialState={initialState}
        submit={submitForm}
        title={mappedData.name}
        submitting={submitting}
        error={error}
        deleteButton={{
          text: lang.deleteDiscount,
          onClick: () => {
            deleteModal.show({ ok: deleteDiscountCb });
          },
        }}
        linkedProductsMap={linkedProductsMap}
      />
      <DeleteModal
        {...deleteModal}
        deleting={deleting}
        subject={lang.discount.toLowerCase()}
        subjectName={mappedData.name}
        primaryText={lang.deleteDiscount}
      />
    </div>
  );
};

const groupProductsByCategory = (products) => {
  const categoryMap = new Map();

  products.forEach((p) => p.categories.forEach((c) => categoryMap.set(c.id, c.name)));

  categoryMap.set(0, lang.uncategorized);

  return Array.from(categoryMap).map(([key, value]) => ({ id: key, name: value }));
};

const checkIfProductBelongsToCategory = (productId, categoryId, products) => {
  const product = products.find((p) => p.productId === productId) || {};

  if (categoryId === 0 || !product.categories) {
    return !product.categories || product.categories?.length === 0;
  } else {
    return product.categories.some((c) => c.categoryId === categoryId);
  }
};

export default EditDiscount;
