import { Get, Post, Put } from "services";

import { ApiPath } from "paths";

export const getAccountCodes = async (body) => {
  const res = await Get(ApiPath.ACCOUNT_CODES, body);
  return res;
};

export const getAccountMappings = async ({ businessId }) => {
  const res = await Get(`${ApiPath.ACCOUNT_MAPPINGS}?businessId=${businessId}`);
  return res;
};

export const createAccountMapping = async ({ businessId, accountCategories }) => {
  const res = await Post(`${ApiPath.ACCOUNT_MAPPINGS}?businessId=${businessId}`, accountCategories);
  return res;
};

export const updateAccountMapping = async ({ businessId, accountCategories, ...body }) => {
  const res = await Put(`${ApiPath.ACCOUNT_MAPPINGS}?businessId=${businessId}`, accountCategories);
  return res;
};
