import lang from "translations";
const columns = [
  {
    key: "name",
    text: lang.name,
    custom: true,
    width: "w-4/12",
  },
  {
    key: "description",
    text: lang.description,
    custom: true,
    width: "w-5/12",
  },
  {
    key: "productCount",
    text: lang.productCount,
    custom: true,
    width: "w-3/12",
    align: "right",
  },
];

export default columns;
