import React from "react";
import classnames from "classnames";
import { Container, PageError, Skeleton } from "components/commons";

const ModuleWrapper = ({ children, width = "full", header, error, loading, loader }) => {
  const render = () => {
    if (loading) {
      return (
        loader || (
          <Container>
            <Skeleton />
          </Container>
        )
      );
    }

    if (error) {
      return <PageError />;
    }

    return children;
  };
  return (
    <div
      className={classnames(
        {
          "xl:w-10/12 lg:w-11/12 md:w-full sm:w-full": width === "medium",
          "w-full": width === "full",
        },
        "xl:p-md 2xl:p-xl"
      )}
    >
      {header}
      {render()}
    </div>
  );
};

export default ModuleWrapper;
