import React from "react";
import { Modal, ActionButton, Text } from "components/commons";
import lang from "translations";
import {mixpanel, TrackEvent} from "mixpanel"

const UnsaveChangesModal = ({ active, close, ok }) => {
  return (
    <Modal
      active={active}
      title={lang.youHaveUnsaveChanges}
      noCloseButton
      actionContent={
        <ActionButton
          className="px-md py-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              close();
            },
          }}
          primary={{
            text: lang.discardChanges,
            onClick: () => {
              mixpanel.track(TrackEvent.ClickedButton, {
                button: lang.discardChanges
              })
              ok();
            },
          }}
        />
      }
    >
      <Text>{lang.ifYouLeaveThisPage}</Text>
    </Modal>
  );
};

export default UnsaveChangesModal;
