import React, { useMemo } from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { SimpleTable, Text } from "components/commons";
import { Path } from "paths";

const TopSpendingGuestsChart = ({ ...props }) => {
  const data = useMemo(() => {
    return props.mappedData.map((p) => {
      return {
        ...p,
        name: (
          <div className="px-md py-sm">
            <Text>{p.name}</Text>
          </div>
        ),
        total: (
          <div className="px-md py-sm">
            <Text>{p.total}</Text>
          </div>
        ),
      };
    });
  }, [props.mappedData]);

  return (
    <ChartContainer
      title={{
        to: Path.GUEST,
        name: lang.topSpendingGuests,
      }}
      {...props}
      empty={!props.mappedData.length}
      paddingless
    >
      <SimpleTable
        className="mt-md"
        columns={[
          {
            text: lang.guest,
            key: "name",
            className: "py-sm",
            headerClassName: "pl-md text-left capitalize font-bold",
          },
          {
            text: lang.spend,
            key: "total",
            className: "py-sm text-right",
            headerClassName: "text-right pr-md capitalize font-bold",
          },
        ]}
        data={data}
      />
    </ChartContainer>
  );
};

export default TopSpendingGuestsChart;
