import { ApiPath } from "paths";
import { Get, Post, Put } from "services";

export const searchStocktake = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_STOCKTAKE}`, body);
  return res;
};

export const createStocktake = async (params) => {
  const res = await Post(`${ApiPath.STOCKTAKE}`, params);
  return res;
};

export const getStocktake = async ({ stocktakeId, venueId }) => {
  const res = await Get(`${ApiPath.GET_SUPPLY_STOCKTAKE({ id: stocktakeId, venueId })}`);
  return res;
};

export const updateStocktake = async ({ stocktakeId, body }) => {
  const res = await Put(`${ApiPath.SUPPLY_STOCKTAKE(stocktakeId)}`, body);
  return res;
};

export const completeStocktake = async ({ stocktakeId, body }) => {
  const res = await Post(`${ApiPath.COMPLETE_STOCKTAKE(stocktakeId)}`, body);
  return res;
};

export const voidStocktake = async ({ stocktakeId, body }) => {
  const res = await Post(`${ApiPath.VOID_STOCKTAKE(stocktakeId)}`, body);
  return res;
};
