import React from "react";
import { Icon, Modal, SimpleTable, Title, AlertMessage } from "components";
import styles from "./end-of-day.module.scss";
import lang from "translations";
import { useFlags } from "launchdarkly-react-client-sdk";

const EndOfDayChecklistModal = ({
  active,
  close,
  title,
  columns,
  back,
  loading,
  error,
  request,
  devicesData = [],
  shiftsData = [],
  shifts = false,
  devices = false,
  ignoreAllShifts,
  allTransactionSynced,
  unsyncedTransactionWarningFlag,
  ...props
}) => {
  const { eodIgnoreUnignoreAllShifts } = useFlags();
  return (
    <Modal
      className="h-full"
      width={800}
      active={active}
      close={close}
      paddingless
      customTitle={
        <div className="flex w-full border-solid">
          <Icon name="arrow-left" onClick={back} />
          <Title>{title}</Title>
        </div>
      }
    >
      <div className={styles.tableHeight}>
        <div className="border-b"></div>
        {unsyncedTransactionWarningFlag ? (
          !allTransactionSynced ? (
            <div className="flex justify-start ml-lg mt-lg">
              <AlertMessage
                type="warning"
                icon="warning"
                showIcon
                message={lang.someShiftsHaveUnsynced}
              />
            </div>
          ) : null
        ) : null}
        {/* <div className="border-b"></div> */}
        <div className="overflow-x-hidden overflow-y-auto h-auto" style={{ maxHeight: "400px" }}>
          <SimpleTable
            addBorderContent
            loading={loading}
            columns={columns}
            data={shifts ? shiftsData : devicesData}
          />
        </div>
      </div>
      {eodIgnoreUnignoreAllShifts ? ignoreAllShifts : null}
    </Modal>
  );
};

export default EndOfDayChecklistModal;
