import lang from "translations";
const columns = [
  {
    key: "stocktakeId",
    text: lang.id,
  },
  {
    key: "dateModified",
    text: lang.dateModified,
  },
  {
    key: "location",
    text: lang.location,
  },
  {
    key: "status",
    text: lang.status,
    custom: true,
  },
  {
    key: "amount",
    text: lang.amount,
    align: "right",
  },
  {
    key: "action",
    align: "right",
  },
];

export default columns;
