import enumeration from "./enumeration";

const TagStatus = {
  ACTIVE: "ACTIVE",
  CHECKED_OUT: "CHECKED_OUT",
  BANNED: "BANNED",
  FORCED: "FORCED_CHECK_OUT",
};

export default enumeration(TagStatus);
