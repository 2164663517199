import { Panel, Title, CheckboxField } from "components/commons";
import React from "react";
import Validation from "services/validation.service";
import lang from "translations";
import SkuField from "./sku-field";

const SkuForm = ({
  sku,
  generatedSku,
  modifyForm,
  showSkuField = true,
  onChangeAutoGenerated,
  showAutogenerateCheckbox = true,
}) => {
  return (
    <Panel title={lang.skuStockKeepingUnit}>
      <Title>{lang.skuStockKeepingUnit}</Title>
      {showSkuField && <SkuField sku={sku} generatedSku={generatedSku} />}
      {showAutogenerateCheckbox && (
        <CheckboxField
          {...generatedSku}
          onChange={(name, { value }) => {
            let val = {
              [name]: {
                value,
              },
              sku: {
                required: !value,
                value: "",
                validations: value
                  ? [Validation.minlength(4), Validation.maxlength(16)]
                  : [Validation.minlength(4), Validation.maxlength(16), Validation.required()],
              },
            };
            if (onChangeAutoGenerated) {
              val = onChangeAutoGenerated(value, val);
            }
            modifyForm(val);
          }}
        >
          {lang.automaticallyGenerateSku}
        </CheckboxField>
      )}
    </Panel>
  );
};

export default SkuForm;
