import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import SupplyItemForm from "../supply-item-form/supply-item-form";
import lang from "translations";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import { getSupplyItem, searchLocation, updateSupplyItem } from "apis";
import { supplyItemRequest, supplyItemResponse } from "mappers/supply-item.mapper";
import DeleteSupplyItem from "../delete-supply-item/delete-supply-item.module";
import { VenueContext } from "contexts";
import { mapObject, toAmount } from "services";
import { formatNumberToMoney } from "services/money.service";
import { mixpanel, TrackEvent } from "mixpanel";

const EditSupplyItem = () => {
  const { query } = useRouter();
  const { id } = query;
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const deleteModal = useModal();
  const { request, error, mappedData, loading } = useApi({
    api: getSupplyItem,
    params: {
      id,
    },
    handleOwnError: {
      badrequest: true,
    },
    mapper: supplyItemResponse,
  });
  const [stateReady, setStateReady] = useState(false);

  const {
    request: locationRequest,
    loading: loadingLocation,
    mappedData: mappedLocation,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: {
      name: {
        key: "locationName",
      },
      locationId: {
        key: "locationId",
      },
    },
  });

  const { request: save, loading: submitting } = useApi({
    api: updateSupplyItem,
    params: {
      productId: parseInt(id),
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const { filterState } = useFilter({
    page: 1,
    pageSize: 2000,
    venueId,
  });

  useMount(() => {
    fetchSupplyItem();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editSupplyItem,
    });
  });

  const fetchSupplyItem = useCallback(() => {
    request();
  }, [request]);

  const fetchLocations = useCallback(() => {
    locationRequest(filterState);
  }, [locationRequest, filterState]);

  useEffect(() => {
    if (Object.keys(mappedData).length > 0) {
      fetchLocations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedData]);

  const submitForm = useCallback(
    async (params) => {
      try {
        await save(mapObject(params, supplyItemRequest));
      } catch (e) {
        throw e;
      }
    },
    [save]
  );

  const initialState = useMemo(() => {
    const {
      automaticallyGenerateSku,
      description,
      measurementId,
      supplyAccountCodeId,
      name,
      orderQty,
      pricePerOrderQty,
      productSku = {
        locationStocks: [],
        parLevel: 0,
        reorderPoint: 0,
        retailPrice: 0,
        supplyPrice: 0,
        productSkuId: 0,
      },
      sku,
      weightInGrams,
    } = mappedData;

    if (Object.keys(mappedData).length > 0 && mappedLocation.length > 0) {
      const locationStocks =
        mappedData && mappedData.productSku ? mappedData.productSku["locationStocks"] : [];
      const locationStocksId =
        Object.keys(mappedData).length !== 0
          ? productSku.locationStocks.map((l) => l.locationId)
          : [];

      mappedLocation.forEach((location) => {
        if (locationStocksId.includes(location.locationId)) {
          const v = locationStocks.find((v) => v.locationId === location.locationId);
          location["stock"] = toAmount(v.stock);
          location["parLevel"] = toAmount(v.parLevel);
          location["reorderPoint"] = toAmount(v.reorderPoint);
        } else {
          location["stock"] = 0;
          location["parLevel"] = 0;
          location["reorderPoint"] = 0;
        }
      });
      productSku["locationStocks"] = mappedLocation;
      setStateReady(true);
    }

    return {
      automaticallyGenerateSku,
      description,
      measurementId,
      supplyAccountCodeId,
      name,
      orderQty: toAmount(orderQty),
      pricePerOrderQty: formatNumberToMoney(pricePerOrderQty),
      productSku,
      sku,
      weightInGrams,
    };
  }, [mappedData, mappedLocation]);

  return (
    <>
      <SupplyItemForm
        submit={submitForm}
        submitting={submitting}
        loading={loading && loadingLocation}
        error={error}
        title={lang.editSupplyItem}
        initialState={initialState}
        onEdit={true}
        deleteItemButton={{
          text: lang.deleteSupplyItem,
          onClick: () => deleteModal.show(),
        }}
        stateReady={stateReady}
      />
      <DeleteSupplyItem id={id} {...deleteModal}></DeleteSupplyItem>
    </>
  );
};

export default EditSupplyItem;
