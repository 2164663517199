import moment from "moment";

export const discountReportFilterState = (venueId) => {
  return {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    locations: [],
    discount: null,
    sort: { key: "sales", value: "desc" },
  };
};
