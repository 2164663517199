import React, { useState } from "react";
import {
  Container,
  Title,
  Text,
  Pill,
  Button,
  Panel,
  Input,
  Icon,
  Table,
  Field,
  TextArea,
  InputCounter,
  Switch,
  Pagination,
  InputPill,
  Modal,
  MultipleSelect,
  Select,
  Filter,
  SideNav,
  Splists,
} from "components/commons";
import { PillType } from "enums";
import { StyleType } from "enums";
import { useModal, useSelectItems } from "hooks";

const KitchenSink = () => {
  const [sort, setSort] = useState({
    value: "ascending",
    key: "name",
  });
  const [switchState, setSwitchState] = useState(false);
  const { show, close, active } = useModal();
  const [select, setSelect] = useState(null);

  const dataTable = [
    {
      id: 1,
      name:
        "Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 ",
      nickname: "Kim",
      role: "Admin",
      text: "Option 1",
      value: "option1",
    },
    {
      id: 2,
      name: "Name 2",
      nickname: "Kim 2",
      role: "Member",
      text: "Option 2",
      value: "option2",
    },
  ];
  const {
    selected: selectedInTable,
    setSelected: setSelectedInTable,
    isAllSelected,
    setSelectAll,
  } = useSelectItems({
    items: dataTable,
    indeterminate: true,
  });

  const {
    selected: selectedInDropdowm,
    setSelected: setSelectedInDropdowm,
    isAllSelected: isAllDropdown,
    setSelectAll: setSelectAllDropdowm,
    value: dropdownValue,
    replaceSelected,
    options,
    addOption,
  } = useSelectItems({
    items: dataTable,
    key: "value",
    allowAddOption: true,
    reverse: true,
    totalItems: dataTable.length,
  });

  const [inputPill, setInputPill] = useState([]);

  return (
    <Container className="bg-gray-lightest">
      <Title xl>Kitchen Sink</Title>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Typography</Title>
        <Container>
          <Title>Titles</Title>
          <Container>
            <Title xl>Extra Large Title</Title>
            <Title lg>Large Title</Title>
            <Title>Default Title</Title>
            <Title sm>Small Title (used in table column)</Title>
          </Container>
          <Title>Texts</Title>
          <Container>
            <Title underline>Types</Title>
            <Text>Default Text</Text>
            <Text description>Description</Text>
            <Text label>Label</Text>
            <Text tagUid>04C54312716384</Text>
            <Text error>Error</Text>
            <Title xs>Style Override (attributes)</Title>
            <Text underline>Underlined</Text>
            <Text danger>Danger</Text>
            <Text strong>Strong</Text>
            <Text strong danger>
              Strong Danger
            </Text>
          </Container>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Content Containers</Title>
        <Container>
          <Title>Pill</Title>
          <Container>
            <Pill size="text-xs">Small Pill 1</Pill>
            <Pill>Pill 1</Pill>
            <Pill type={PillType.Red}>Pill Red</Pill>
            <Pill type={PillType.Disabled}>Pill Disabled</Pill>
          </Container>
          <Title>Panel</Title>
          <Container>
            <Panel>
              <Text>Heyyy</Text>
            </Panel>
            <Panel>
              <Title>Panel Title</Title>
              <Text description>Description</Text>
            </Panel>
          </Container>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title>Icon</Title>
        <Container>
          <Text label>Arrow Left</Text>
          <Icon name="arrow-left" />
          <Text label>Cups</Text>
          <Icon name="cups" />
          <Text label>Loading</Text>
          <Icon loading />
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Buttons</Title>
        <Container>
          <Button className="mr-xs">Primary</Button>
          <Button className="mr-xs" type={StyleType.Secondary}>
            Secondary
          </Button>
          <Button className="mr-xs" type={StyleType.Danger}>
            Danger
          </Button>
          <Button loading>Loading</Button>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Switches</Title>
        <Container>
          <Switch
            checked={switchState}
            onChange={() => {
              setSwitchState(!switchState);
            }}
          ></Switch>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Inputs</Title>
        <Container>
          <Title>Simple</Title>
          <Container>
            <Input placeholder="Simple" />
          </Container>
          <Title>Required</Title>
          <Container>
            <Input required placeholder="Required" />
          </Container>
          <Title>Error</Title>
          <Container>
            <Input error required placeholder="Error" />
          </Container>
          <Title>Loading</Title>
          <Container>
            <Input loading placeholder="Loading" />
          </Container>
          <Title>With icons</Title>
          <Container>
            <Input iconPrefix="pencil" iconSuffix="warning" placeholder="With icons" />
          </Container>
          <Title>Input Counter</Title>
          <Container>
            <InputCounter required placeholder="Counter" />
          </Container>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Textareas</Title>
        <Container>
          <Title>Simple</Title>
          <Container>
            <TextArea></TextArea>
          </Container>
          <Title>Error</Title>
          <Container>
            <TextArea error={true}></TextArea>
          </Container>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Input Pill</Title>
        <Container>
          <InputPill
            value={inputPill}
            placeholder={"Press enter to add Pill"}
            setValue={setInputPill}
            handleSubmitKey={(value) => {
              if (value === "error") {
                return {
                  text: value,
                  pillAttributes: {
                    type: PillType.Red,
                  },
                  danger: true,
                };
              }
              return value;
            }}
          />
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Select</Title>
        <Container>
          <Title>Single</Title>
          <Select
            placeholder="Select Value"
            options={dataTable}
            value={select}
            onChange={(name, { value: v }) => {
              setSelect(v);
            }}
            required
          />
          <Title>Multiple</Title>
          <MultipleSelect
            value={dropdownValue}
            selectAllText="All Vendor"
            isAllSelected={isAllDropdown}
            setSelectAll={setSelectAllDropdowm}
            selected={selectedInDropdowm}
            options={options}
            onChange={setSelectedInDropdowm}
            placeholder="Select Vendor"
            replaceSelected={replaceSelected}
            required
            allowAddOption
            addOption={addOption}
          />
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Fields</Title>
        <Container>
          <Container>
            <Field label="Input Field" message="This field is optional">
              <Input />
            </Field>
            <Field label="Select Field">
              <Select
                placeholder="Select Value"
                options={dataTable}
                value={select}
                onChange={(name, { value: v }) => {
                  setSelect(v);
                }}
              />
            </Field>
          </Container>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Table</Title>
        <Container>
          <Title md>Auto Column Width</Title>
          <Table
            sort={sort}
            setSort={setSort}
            tableStyle="auto"
            columns={[
              {
                key: "name",
                text: "Name",
                sort: true,
              },
              {
                key: "nickname",
                text: "Nick Name",
                sort: true,
              },
              {
                key: "role",
                text: "Role",
                sort: true,
              },
            ]}
            data={dataTable}
            selected={selectedInTable}
            setSelected={setSelectedInTable}
            isAllSelected={isAllSelected}
            setSelectAll={setSelectAll}
          />
        </Container>
        <Container>
          <Title md>Fixed Column Width</Title>
          <Table
            columns={[
              {
                key: "name",
                text: "Name",
              },
              {
                key: "nickname",
                text: "Nick Name",
              },
              {
                key: "role",
                text: "Role",
              },
            ]}
            data={[
              {
                name:
                  "Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 ",
                nickname: "Kim",
                role: "Admin",
              },
              {
                name: "Name 2",
                nickname: "Kim 2",
                role: "Member",
              },
            ]}
          />
          <Table
            loading
            columns={[
              {
                key: "name",
                text: "Name",
              },
              {
                key: "nickname",
                text: "Nick Name",
              },
              {
                key: "role",
                text: "Role",
              },
            ]}
            data={[
              {
                name:
                  "Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 Name 1 ",
                nickname: "Kim",
                role: "Admin",
              },
              {
                name: "Name 2",
                nickname: "Kim 2",
                role: "Member",
              },
            ]}
          />
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Pagination</Title>
        <Container>
          <Pagination />
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Modal</Title>
        <Container>
          <Button onClick={show}>Show</Button>
          <Modal active={active} onClose={close} onConfirm={close}>
            Modal Content....
          </Modal>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Title lg>Filter</Title>
        <Container>
          <Filter placeholder="Filter">
            <div className="flex">
              <Field className="mx-sm">
                <Select />
              </Field>
              <Field className="mx-sm">
                <Input />
              </Field>
              <Field className="mx-sm">
                <Input />
              </Field>
            </div>
          </Filter>
        </Container>
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <SideNav
          items={[
            {
              to: "/kitchen-sink",
              text: "Kitchen Sink",
              icon: "vendor",
            },
            {
              to: "/guests",
              text: "Guests",
              icon: "guest",
            },
          ]}
        />
      </Panel>
      <Panel className="border border-solid border-blue-dark bg-gray-lightest">
        <Splists
          divideAtIndex={3}
          columns={[
            {
              text: "Column A",
              key: "a",
              width: "500px",
            },
            {
              text: "Column B",
              key: "b",
              width: "300px",
            },
            {
              text: "Column C",
              key: "c",
              width: "300px",
            },
            {
              text: "Column D",
              key: "d",
              width: "300px",
            },
            {
              text: "Column E",
              key: "e",
              width: "300px",
            },
            {
              text: "Column F",
              key: "f",
              width: "300px",
            },
            {
              text: "Column G",
              key: "g",
              width: "300px",
            },
            {
              text: "Column H",
              key: "h",
              width: "300px",
            },
          ]}
          data={[
            {
              a: (
                <div>
                  <p>Test</p>
                  <p>Test2</p>
                </div>
              ),
              b: "B",
              c: "C",
              d: "D",
              e: "E",
              f: "F",
              g: "G",
              h: "H",
            },
            {
              a: (
                <div>
                  <p>A2</p>
                  <p>A2</p>
                </div>
              ),
              b: (
                <div>
                  <p>Test</p>
                  <p>Test2</p>
                  <p>Test2</p>
                  <p>Test2</p>
                </div>
              ),
              c: "C",
              d: "D",
              e: "E",
              f: "F",
              g: "G",
              h: "H",
            },
          ]}
        />
      </Panel>
    </Container>
  );
};

export default KitchenSink;
