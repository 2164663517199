import React, { useCallback, useContext, useMemo, useState } from "react";
import { HeaderA } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import { useApi, useMount, useRouter, useFilter, useModal } from "hooks";
import { DataTable, Icon, Text, Tooltip, ButtonLink } from "components/commons";
import { supplyItem } from "mappers";
import { searchSupplyItem } from "apis/supply-item.api";
import classNames from "classnames";
import { ModuleWrapper } from "components/fragments";
import SupplyItemFilter from "./supply-item-filter";
import { supplyItemFilter } from "./filters";
import { mapObject, toAmount } from "services";
import { supplyItemListFilterRequest } from "mappers/supply-item.mapper";
import DeleteSupplyItem from "../delete-supply-item/delete-supply-item.module";
import { mixpanel, TrackEvent } from "mixpanel";

const SupplyItemList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const deleteModal = useModal();
  const {
    request,
    loading = true,
    error,
    result = { data: [] },
    mappedData,
  } = useApi({
    api: searchSupplyItem,
    isArray: true,
    mapper: supplyItem,
  });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter, isFilterDirty } =
    useFilter(supplyItemFilter(venueId));

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    fetchSupplyItems(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.supplyItems,
    });
  });

  const fetchSupplyItems = useCallback(
    (requestState) => {
      request(mapObject(requestState, supplyItemListFilterRequest));
    },
    [request]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState } = clearFilter();
    fetchSupplyItems(requestState);
    setHasAppliedFilter(false);
  }, [fetchSupplyItems, clearFilter]);

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({
        page: 1,
        searchKey,
        sort: { key: "p.productName", value: "asc" },
      });
      fetchSupplyItems(requestState);
      setHasAppliedFilter(true);
    },
    [fetchSupplyItems, modifyFilters]
  );

  const renderData = useMemo(() => {
    return mappedData.map(
      ({ id, name, inStock, parLevel, reorderPoint, inventoryValue, measurement = {} }) => {
        return {
          id,
          name: (
            <Tooltip
              title={
                inStock < parLevel
                  ? lang.stockBelowParLevel
                  : inStock <= reorderPoint && reorderPoint > 0
                  ? lang.stockReachedReorderPoint
                  : null
              }
            >
              <div className="flex align-center items-center ">
                <ButtonLink
                  // onClick={() => {
                  //   history.push(Path.SUPPLY_ITEM_ID(id));
                  // }}
                  path={Path.SUPPLY_ITEM_ID(id)}
                >
                  <Text color="text-sm text-black font-semibold text-left hover:text-gray">{name}</Text>
                </ButtonLink>

                {inStock < parLevel ? (
                  <Icon paddingless name="warning" className="ml-sm text-red-500" />
                ) : inStock <= reorderPoint && reorderPoint > 0 ? (
                  <Icon paddingless name="warning" className="ml-sm text-yellow-400" />
                ) : null}
              </div>
            </Tooltip>
          ),
          inStock: (
            <Text
              className={classNames({
                "text-red-500": inStock < parLevel,
              })}
            >
              {toAmount(inStock, measurement?.unit === "pc" ? "0,0" : "0,0.00")} {measurement?.unit}
            </Text>
          ),
          parLevel: (
            <Text>
              {toAmount(parLevel, measurement?.unit === "pc" ? "0,0" : "0,0.00")}{" "}
              {measurement?.unit}
            </Text>
          ),
          reorderPoint: (
            <Text>
              {toAmount(reorderPoint, measurement?.unit === "pc" ? "0,0" : "0,0.00")}{" "}
              {measurement?.unit}
            </Text>
          ),
          inventoryValue,
        };
      }
    );
  }, [mappedData]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.supplyItems}
        description={lang.createAndManageRawMaterials}
        button={{
          path: Path.CREATE_SUPPLY_ITEM,
          text: lang.createNewSupplyItem,
        }}
        className="mb-md"
      />

      <SupplyItemFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        modifyFilters={modifyFilters}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />

      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchSupplyItems}
        total={result.metadata?.total}
        loading={loading}
        hasAppliedFilter={hasAppliedFilter}
        columns={[
          {
            key: "name",
            text: lang.supplyItem,
            custom: true,
            align: "left",
          },
          {
            key: "inStock",
            text: lang.inStock,
            custom: true,
            align: "right",
          },
          {
            key: "parLevel",
            text: lang.parLevel,
            custom: true,
            align: "right",
          },
          {
            key: "reorderPoint",
            text: lang.reorderPoint,
            custom: true,
            align: "right",
          },
          {
            key: "inventoryValue",
            text: lang.inventoryValue,
            align: "right",
          },
          {
            key: "action",
            actions: true,
            align: "right",
            actionOptions: [
              {
                text: lang.editSupplyItem,
                onClick: (column) => {
                  history.push(Path.EDIT_SUPPLY_ITEM(column.id));
                },
              },
              {
                text: lang.deleteSupplyItem,
                className: "text-red-500",
                onClick: (column) => {
                  deleteModal.show({
                    id: column.id,
                  });
                },
              },
            ],
          },
        ]}
        data={renderData}
        error={error}
      />
      <DeleteSupplyItem
        onList={true}
        {...deleteModal}
        refreshList={() => fetchSupplyItems(requestState)}
      ></DeleteSupplyItem>
    </ModuleWrapper>
  );
};

export default SupplyItemList;
