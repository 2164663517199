import { Post, Delete } from "services";
import { ApiPath } from "paths";

export const searchPaymentMethod = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PAYMENT_METHOD}`, body);
  return res;
};

export const createPaymentMethod = async (body) => {
  const res = await Post(`${ApiPath.PAYMENT_METHOD}`, body);
  return res;
};

export const deletePaymentMethod = async ({ paymentMethodId }) => {
  const res = await Delete(`${ApiPath.PAYMENT_METHOD_ID(paymentMethodId)}`);
  return res;
};

export const editPaymentMethod = async (body) => {
  const res = await Post(`${ApiPath.PAYMENT_METHOD_ID(body.paymentMethodId)}`, body);
  return res;
};
