import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { CreateDiscount, DiscountList, EditDiscount } from "modules";

const DiscountPage = () => {
  return (
    <Switch>
      <Route exact path={Path.DISCOUNT_CREATE} component={CreateDiscount} />
      <Route exact path={Path.DISCOUNT_EDIT} component={EditDiscount} />
      <Route exact path={Path.DISCOUNT} component={DiscountList} />
    </Switch>
  );
};

export default DiscountPage;
