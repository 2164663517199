import { DeleteModal } from "components/modals";
import React from "react";
import lang from "translations";
import { deleteQuestion } from "apis/question.api";
import { useApi } from "hooks";
import { ButtonLink, Hightlight, Icon, Text, Title, Toast } from "components/commons";
import { Path } from "paths";
import { v4 as uuidv4 } from "uuid";
import { mixpanel, TrackEvent } from "mixpanel";

const DeleteQuestionModal = ({ name, active, close, id, goToList, guestProfiles = [] }) => {
  const { request, loading } = useApi({
    api: deleteQuestion,
    params: {
      questionId: id,
    },
  });
  return (
    <DeleteModal
      deleting={loading}
      customHeader={
        <Title lg className="py-lg">
          {lang.deleteThisQuestion}
        </Title>
      }
      active={active}
      customContent={
        guestProfiles.length ? (
          <div>
            <Text>{lang.youAreAboutToDeleteAQuestionThatIsLinked}</Text>
            <Hightlight className="flex items-center">
              <Icon name="warning" className="text-red mr-sm" />
              {lang.populate(lang.thisQuestionIsLinkedToNProfiles, [guestProfiles.length])}
            </Hightlight>
            <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
            {guestProfiles.map(({ guestProfile }) => {
              const { guestProfileName, guestProfileId } = guestProfile;
              return (
                <ButtonLink
                  key={uuidv4()}
                  newTabPath={Path.GUEST_PROFILE_ID(guestProfileId)}
                  className="py-lg"
                  icon="arrow-diagonal-right"
                  suffix
                >
                  {guestProfileName}
                </ButtonLink>
              );
            })}
            <div className="-mr-lg -ml-lg border border-bottom border-gray-lightest"></div>
          </div>
        ) : (
          lang.youAreAboutToDeleteQuestion
        )
      }
      close={close}
      primaryText={lang.proceed}
      ok={async () => {
        mixpanel.track(TrackEvent.ClickedButton, {
          Button: lang.deleteQuestion,
          Page: lang.questionSettings,
        });
        await request();
        Toast({
          content: lang.populate(lang.questionDeleted, [name]),
          success: true,
          icon: "check",
        }).open();
        close();
        goToList();
      }}
    />
  );
};

export default DeleteQuestionModal;
