import { forceCheckout } from "apis/guest-checkin.api";
import {
  ActionButton,
  Field,
  Form,
  InputCounter,
  Modal,
  Select,
  Title,
  Toast,
  InfoPanel,
} from "components/commons";
import { VenueContext } from "contexts";
import { StyleType } from "enums";
import { useApi, useForm } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import initialFormState from "./force-checkout-state";
import { mixpanel, TrackEvent } from "mixpanel";

const ForceCheckoutModal = ({
  checkinId,
  active,
  close,
  ok,
  pairedTagOnCheckin,
  initialState = undefined,
  ...props
}) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, getFormValues, clearForm, validateForm } = useForm({
    initialState: formState,
  });

  const { request: checkoutRequest, loading: checkoutLoading } = useApi({
    api: forceCheckout,
    params: {
      checkinId,
      banGuestTagUid: pairedTagOnCheckin,
    },
  });

  const handleSubmit = useCallback(async () => {
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        button: lang.forceCheckout,
      });
      const params = getFormValues();
      if (params.reason === "Other") {
        params.reason = params.other;
      }
      params.manualCheckoutSource = "VENUE_DASHBOARD";
      validateForm();
      await checkoutRequest({ ...params, venueId });
      Toast({
        content: lang.guestCheckedOutAndBanned,
        success: true,
        icon: "check",
      }).open();
      clearForm();
      close();
      ok();
    } catch ({ code, handleError }) {
      // const err = {
      //   3094: () => {
      //     applyFieldErrors({
      //       searchKey: "This Tag ID is already banned.",
      //     });
      //   },
      // };

      // if (err[code]) {
      //   err[code]();
      // } else {
      handleError();
      // }
    }
  }, [checkoutRequest, getFormValues, venueId, validateForm, clearForm, close, ok]);

  return (
    <Modal
      {...props}
      noHeader
      active={active}
      actionContent={
        <ActionButton
          loading={checkoutLoading}
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              clearForm();
              close();
            },
          }}
          primary={{
            loading: checkoutLoading,
            disabled: checkoutLoading,
            text: lang.checkout,
            type: StyleType.Danger,
            onClick: () => {
              handleSubmit();
            },
          }}
        />
      }
    >
      <Title lg className="mb-sm pt-md">
        {props.title}
      </Title>
      <Form onSubmit={handleSubmit}>
        <Field label={lang.reason}>
          <Select {...fields.reason} textarea onChange={modifyField} />
        </Field>
        {fields.reason.value === "Other" && (
          <div className="mt-md">
            <InputCounter {...fields.other} textarea onChange={modifyField} />
          </div>
        )}
        <InfoPanel>{lang.banningThisTagWillOnlyTakeEffect}</InfoPanel>
      </Form>
    </Modal>
  );
};

export default ForceCheckoutModal;
