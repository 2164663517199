import enumeration from "./enumeration";

const ProductStockLevel = {
  InStock: "IN_STOCK",
  CheckStock: "CHECK_STOCK",
  ReorderStock: "REORDER_STOCK",
  NoStock: "NO_STOCK",
};

export default enumeration(ProductStockLevel);
