import React from "react";
import { Path } from "paths";
import SettingNavPage from "./setting-nav-page";
import {
  GeneralSetting,
  TaxSetting,
  PaymentMethodSetting,
  CreditLimitSetting,
  AccountSetting,
  QuestionSetting,
  WristbandTagSetting,
  BanTagList,
  NotificationSetting,
  StockLevelNotification,
  ReceiptSetting,
  IntegrationSetting,
  AccountMapping,
  ChartOfAccounts,
} from "modules";
import { RouteRender } from "components/commons";
import { useFlags } from "launchdarkly-react-client-sdk";

const SettingPage = () => {
  const { accountMapping, chartOfAccounts, integrationsSetting } = useFlags();

  return (
    <RouteRender
      defaultRedirect={Path.SETTING}
      renders={{
        [Path.SETTING_GENERAL_SETTING]: {
          exact: true,
          component: GeneralSetting,
        },
        [Path.WRISTBAND_KEEPING]: {
          exact: true,
          component: WristbandTagSetting,
        },
        [Path.SETTING_NOTIFICATION]: {
          exact: false,
          component: NotificationSetting,
        },
        [Path.SETTING_NOTIFICATION_STOCK_LEVEL]: {
          exact: true,
          component: StockLevelNotification,
        },
        [Path.BAN_TAG]: {
          exact: true,
          component: BanTagList,
        },
        [Path.SETTING_TAX]: {
          exact: true,
          component: TaxSetting,
        },
        [Path.PAYMENT_METHOD_SETTING]: {
          exact: true,
          component: PaymentMethodSetting,
        },
        [Path.CREDIT_LIMIT_SETTING]: {
          exact: true,
          component: CreditLimitSetting,
        },
        [Path.QUESTION_SETTING]: {
          exact: true,
          component: QuestionSetting,
        },
        [Path.ACCOUNT_SETTING]: {
          exact: true,
          component: AccountSetting,
        },
        [Path.RECEIPT_SETTING]: {
          exact: true,
          component: ReceiptSetting,
        },
        [Path.INTEGRATION_SETTING]: {
          exact: true,
          component: IntegrationSetting,
          featureFlag: integrationsSetting,
        },
        [Path.ACCOUNT_MAPPING]: {
          exact: true,
          component: AccountMapping,
          featureFlag: accountMapping,
        },
        [Path.CHART_OF_ACCOUNTS]: {
          exact: true,
          component: ChartOfAccounts,
          featureFlag: chartOfAccounts,
        },
        [Path.SETTING]: {
          exact: true,
          component: SettingNavPage,
        },
      }}
    />
  );
};

export default SettingPage;
