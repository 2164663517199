import React, { memo, useEffect, useMemo, useState } from "react";
import lang from "translations";
import { useApi, useMount } from "hooks";
import { ActionButton, ButtonLink, Modal, Skeleton, Text, Title } from "components/commons";
import { ProductCreationType, StyleType } from "enums";
import { getProduct } from "apis";
import { product } from "mappers";
import { Path } from "paths";
import { ArrowRightOutlined } from "@ant-design/icons";
import { mixpanel, TrackEvent } from "mixpanel";

const DeleteProductModal = ({ active, close, ok, productId }) => {
  const {
    request: getProductRequest,
    loading,
    mappedData: productToBeDeleted,
  } = useApi({
    api: getProduct,
    mapper: { ...product, _keys: ["productSkus", "creationType"] },
  });

  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (active) {
      setDeleting(false);
      getProductRequest({ productId });
    }
    //eslint-disable-next-line
  }, [active, productId]);

  useMount(() => {
    mixpanel.track(TrackEvent.ClickedButton, {
      Button: lang.deleteAProduct,
    });
  });

  const productsSelectedForDeletion = useMemo(() => {
    let valid = [];
    let invalid = [];
    let productSet = [];
    let composites = [];
    if (productToBeDeleted.creationType === "PRODUCT_SET") {
      productSet.push(productToBeDeleted);
    }
    if (productToBeDeleted.productSkus) {
      const hasPartOfComposition = productToBeDeleted.productSkus.some((psku) => {
        if (psku.partOfCompositions) {
          composites = psku.partOfCompositions.sort((a, b) => {
            const f = a["product"]["productName"];
            const s = b["product"]["productName"];
            return f < s ? -1 : f > s ? 1 : 0;
          });
        }
        return psku.partOfCompositions;
      });
      if (hasPartOfComposition) {
        invalid.push(productToBeDeleted);
      } else {
        valid.push(productToBeDeleted);
      }
    }
    return { valid, invalid, productSet, composites };
  }, [productToBeDeleted]);

  const validProductsForDeletion = useMemo(
    () => productsSelectedForDeletion.valid,
    [productsSelectedForDeletion]
  );

  const productSetForDeletion = useMemo(
    () => productsSelectedForDeletion.productSet,
    [productsSelectedForDeletion]
  );

  const invalidProductsForDeletion = useMemo(
    () => productsSelectedForDeletion.invalid,
    [productsSelectedForDeletion]
  );

  const compositeProducts = useMemo(
    () => productsSelectedForDeletion.composites,
    [productsSelectedForDeletion]
  );

  const refuseDelete = useMemo(
    () => Boolean(invalidProductsForDeletion.length > 0),
    [invalidProductsForDeletion]
  );

  const sortedCompositeProducts = useMemo(
    () =>
      compositeProducts.length > 0
        ? compositeProducts.sort((a, b) => {
            const f = a["product"]["productName"];
            const s = b["product"]["productName"];
            return f < s ? -1 : f > s ? 1 : 0;
          })
        : [],
    [compositeProducts]
  );

  const header = useMemo(() => {
    if (loading) {
      return <Skeleton single />;
    }

    return (
      <div className="m-sm">
        <Title lg>
          {refuseDelete
            ? lang.unableToDelete
            : productSetForDeletion.length > 0
            ? lang.deleteProductSet
            : lang.deleteAProduct2}
        </Title>
      </div>
    );
  }, [productSetForDeletion, loading, refuseDelete]);

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton />;
    }
    return (
      <div className="mx-sm">
        {!refuseDelete && <DeleteProductMessage product={validProductsForDeletion[0]} />}
        {refuseDelete && (
          <CannotDeleteProductDetail
            product={invalidProductsForDeletion[0]}
            composites={sortedCompositeProducts}
          />
        )}
      </div>
    );
  }, [
    loading,
    validProductsForDeletion,
    refuseDelete,
    invalidProductsForDeletion,
    sortedCompositeProducts,
  ]);

  const primaryText = useMemo(() => {
    if (refuseDelete) {
      return lang.okay;
    }
    return lang.delete;
  }, [refuseDelete]);

  return (
    <Modal
      width={660}
      active={active}
      close={close}
      actionContent={
        loading ? (
          <div className="px-md py-md"> </div>
        ) : (
          <ActionButton
            loading={loading || deleting}
            className="px-md py-md"
            secondary={
              refuseDelete
                ? null
                : {
                    text: lang.cancel,
                    onClick: () => close(),
                  }
            }
            primary={{
              disabled: deleting,
              text: primaryText,
              type: refuseDelete ? StyleType.Secondary : StyleType.Danger,
              onClick: refuseDelete
                ? () => close()
                : () => {
                    setDeleting(true);
                    ok({ id: productId }, productToBeDeleted.name, close);
                  },
            }}
          />
        )
      }
      title={header}
    >
      {content}
    </Modal>
  );
};

const DeleteProductMessage = ({ product }) => {
  if (!product) {
    return "";
  }
  const { name } = product;
  return (
    <div>
      <Text size="text-md">
        <lang.Translate
          className="text-sm"
          text={lang.areYouSureYouWantToDeleteProduct2}
          items={[<b>{name}</b>]}
        />
      </Text>
    </div>
  );
};

const CannotDeleteProductDetail = ({ product, composites }) => {
  const divider = useMemo(
    () => <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>,
    []
  );
  if (!product) {
    return "";
  }
  const { name } = product;

  return (
    <div>
      <Text size="text-md">
        <lang.Translate text={lang.deleteCurrentlyBeingUsed} items={[<b>{name}</b>]} />
      </Text>
      {divider}
      <div className="overflow-auto max-h-40">
        {composites.map((composite, index) => {
          return (
            <>
              <ButtonLink
                className="mt-md"
                newTabPath={
                  composite.product?.creationType === ProductCreationType.ProductSet
                    ? Path.EDIT_PRODUCT_SET_ID(composite.product.productId)
                    : Path.EDIT_PRODUCT_ID(composite.product.productId)
                }
                key={index}
              >
                <b>{composite.product.productName}</b>
                <ArrowRightOutlined rotate={-45} className="ml-sm" />
              </ButtonLink>
            </>
          );
        })}
      </div>
      {divider}
    </div>
  );
};

export default memo(DeleteProductModal);
