import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Path } from "paths";

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem("accessToken") ? (
          <Redirect to={`${Path.SLASH}`} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default AuthRoute;
