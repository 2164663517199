import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import lang from "translations";
import {
  Field,
  Input,
  ActionButton,
  Toast,
  Form,
  Skeleton,
  CheckboxField,
  Select,
} from "components/commons";
import { useApi, useFilter, useForm, useModal, useMount } from "hooks";
import { VenueContext } from "contexts";
import initialFormState from "modules/inventory/supply-item-form/supply-item.form-state";
import { location } from "mappers";
import { searchLocation } from "apis";
import { getMeasurements } from "apis/measurement.api";
import { measurement } from "mappers/measurement.mapper";
import { formatNumberToMoney } from "services/money.service";
import { mixpanel, TrackEvent } from "mixpanel";

const AddNewSupplyItemForm = ({
  submit,
  submitting,
  initialState = undefined,
  deleteItemButton,
  loading,
  close,
  onEdit = false,
}) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const unsaveChangesModal = useModal();

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty } = useForm({
    initialState: formState,
  });

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(false);
    if (
      fields.name.value === "" ||
      (!fields.automaticallyGenerateSku.value && fields.sku.value === "") ||
      fields.measurementId.value === null
    ) {
      setDisabled(true);
    }
  }, [fields]);

  const { request: locationRequest } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
  });

  const { filterState } = useFilter({
    page: 1,
    pageSize: 2000,
    venueId,
  });

  const {
    request: measurementRequest,
    loading: loadingMeasurement,
    mappedData: mappedMeasurements,
  } = useApi({
    api: getMeasurements,
    isArray: true,
    mapper: measurement,
    params: {
      venueId: venueId,
    },
  });

  useMount(async () => {
    await measurementRequest();
    const { data } = await locationRequest(filterState);
    if (!onEdit) {
      cleanUpLocations(data);
    }
  });

  const cleanUpLocations = useCallback(
    (data) => {
      const locationStocks = [];
      data.forEach((location) => {
        locationStocks.push({
          locationId: location.locationId,
          name: location.locationName,
          stock: formatNumberToMoney(0, true, true),
          parLevel: formatNumberToMoney(0, true, true),
          reorderPoint: formatNumberToMoney(0, true, true),
        });
      });
      modifyField("productSku", {
        value: {
          sku: null,
          retailPrice: 0,
          supplyPrice: 0,
          parLevel: 0,
          reorderPoint: 0,
          locationStocks: locationStocks,
        },
        dirty: false,
      });
    },
    [modifyField]
  );

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();

    if (fields.automaticallyGenerateSku.value) {
      params.productSku.sku = null;
    } else {
      params.productSku.sku = fields.sku.value;
    }

    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.saveNewSupplyItemForm,
      });

      const res = await submit({ ...params, venueId });
      Toast({
        content: !onEdit ? res.message : lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      close();
    } catch ({ code, handleError }) {
      const err = {
        3006: () => {
          applyFieldErrors({
            name: lang.supplyItemAlreadyExists,
          });
        },
        3001: () => {
          applyFieldErrors({
            sku: lang.skuAlreadyExists,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [
    submit,
    getFormValues,
    venueId,
    applyFieldErrors,
    fields.automaticallyGenerateSku.value,
    fields.sku.value,
    onEdit,
    close,
  ]);

  const submitFormValue = () => {
    submitForm(handleSubmit);
  };

  const measurementOptions = useMemo(() => {
    return mappedMeasurements.map((measurement) => {
      return { text: `${measurement.name} (${measurement.unit})`, value: measurement.id };
    });
  }, [mappedMeasurements]);

  const cleanupStockValue = useCallback((value, productUnitSymbol) => {
    let val = (value + "").trim();
    val = val.replace(/[^0-9.]/g, "");
    if (Number(val) || val === "0") {
      val = Number(val);
    } else {
      val = 0;
    }
    return formatNumberToMoney(
      val,
      true,
      productUnitSymbol === "pc" || productUnitSymbol === "",
      3
    );
  }, []);

  useEffect(() => {
    const measurementId = fields.measurementId.value;
    mappedMeasurements.forEach((measurement) => {
      if (measurementId === measurement.id) {
        const newValue = fields.productSku.value;
        newValue.locationStocks.forEach((location) => {
          location.stock = cleanupStockValue(location.stock, measurement.unit);
          location.reorderPoint = cleanupStockValue(location.reorderPoint, measurement.unit);
          location.parLevel = cleanupStockValue(location.parLevel, measurement.unit);
        });
        modifyField("productSku", { value: newValue, dirty: false });
        modifyField("orderQuantity", {
          value: cleanupStockValue(fields.orderQuantity.value, measurement.unit),
          dirty: false,
        });
      }
    });
    // eslint-disable-next-line
  }, [fields.measurementId.value, mappedMeasurements]);

  useEffect(() => {
    modifyField("sku", {
      disabled: fields.automaticallyGenerateSku.value,
      required: !fields.automaticallyGenerateSku.value,
      dirty: false,
    });
    // eslint-disable-next-line
  }, [fields.automaticallyGenerateSku.value, fields.sku.disabled]);

  return (
    <div className="pb-md px-sm">
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue}>
        {loading ? (
          <Skeleton />
        ) : (
          <div>
            <Field {...fields.name}>
              <Input
                required
                {...fields.name}
                onChange={modifyField}
                placeholder={lang.nameYourSupplyItem}
              />
            </Field>
          </div>
        )}
        {loading ? (
          <Skeleton />
        ) : (
          <div>
            <Field className="pt-md" label={lang.skuStockKeepingUnit} {...fields.sku}>
              <Input
                {...fields.sku}
                value={fields.automaticallyGenerateSku.value ? null : fields.sku.value}
                onChange={(obj, value) => {
                  let newValue = "";
                  if (value.value) {
                    newValue = value.value.replace(/\W/g, "").replace(/_/g, "").toUpperCase();
                  }
                  modifyField(obj, { value: newValue });
                }}
              />
            </Field>

            {!onEdit ? (
              <CheckboxField
                textSize="text-sm mt-sm"
                {...fields.automaticallyGenerateSku}
                onChange={modifyField}
              >
                {fields.automaticallyGenerateSku.label}
              </CheckboxField>
            ) : null}

            <Field {...fields.measurementId} className="mt-md">
              <Select
                {...fields.measurementId}
                disabled={loadingMeasurement}
                loading={loadingMeasurement}
                placeholder={lang.pleaseSelectStockUnit}
                options={measurementOptions}
                onChange={modifyField}
                className="w-1/2"
              />
            </Field>
          </div>
        )}
        <ActionButton
          className="pt-lg"
          loading={submitting}
          primary={{
            disabled: !dirty || disabled,
            text: lang.addNewSupplyItem,
            onClick: () => {
              submitFormValue();
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => close(),
          }}
          danger={deleteItemButton}
        />
      </Form>
    </div>
  );
};

export default AddNewSupplyItemForm;
