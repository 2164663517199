import {
  Container,
  Icon,
  Pagination,
  Skeleton,
  Text,
  Panel,
  Title,
  SearchInput,
  Input,
} from "components/commons";
import { StocktakeStatus } from "enums";
import React, { useCallback, useState } from "react";
import {
  toAmount,
  isNumberValid,
  isValidDecimalPlaces,
  parseAmountToNumber,
  formatNumberToMoneyWithCurrencySymbol,
} from "services";
import lang from "translations";
import classNames from "classnames";
import styles from "./stocktake-items-table.module.scss";

const StocktakeItemsTable = ({
  data,
  columns,
  loadingSupplyItems,
  loadingStocktake,
  filterState,
  modifyFilters,
  searchSupplyItems,
  applyFilter,
  items,
  total,
  locationId,
  status,
  fields,
  modifyForm,
}) => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const changePageCb = useCallback(
    async (page) => {
      const { filterState, requestState } = modifyFilters({ page, locationId });
      await searchSupplyItems(filterState, requestState);
    },
    [modifyFilters, searchSupplyItems, locationId]
  );

  const changePageSizeCb = useCallback(
    async (pageSize) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        pageSize,
        locationId,
      });
      await searchSupplyItems(filterState, requestState);
    },
    [modifyFilters, searchSupplyItems, locationId]
  );

  window.onresize = function () {
    setCurrentWidth(window.innerWidth);
  };

  return (
    <Panel paddingless className="pb-md">
      <div className="flex items-center justify-between pl-sm pr-md py-md md:pt-sm pl-0">
        <Title className="pl-sm hidden md:flex">{lang.items}</Title>
        <SearchInput
          className="bg-gray-100 w-full md:w-2/5 lg:w-1/4"
          filterState={filterState}
          onApply={applyFilter}
        />
      </div>
      {total === 0 && !loadingSupplyItems && !loadingStocktake ? (
        <Container className="bg-white text-center min-h-page flex items-center">
          <div className="m-auto">
            <Icon name="empty-search-image" fontSize="250px" className="text-gray" />
            <Text color="text-gray" size="text-md" className="mt-md">
              {lang.noResultsFound}
            </Text>
          </div>
        </Container>
      ) : (
        <div>
          <div className={classNames(styles.columnName)}>
            {columns.map((column) => {
              return (
                <div key={column.id} className={column.id === 1 ? "col-span-2" : "col-span-1"}>
                  <Text
                    color="text-gray"
                    fontWeight="font-semibold"
                    className={column.right ? "text-right" : null}
                  >
                    {column.text}
                  </Text>
                </div>
              );
            })}
          </div>
          <div>
            {!loadingSupplyItems && !loadingStocktake && items ? (
              data.map((d, index) => {
                const {
                  itemName,
                  sku,
                  expectedStockQty,
                  measurement,
                  actualStockQty,
                  amount,
                  difference,
                } = d;
                const onChange = (changes) => {
                  const v = fields.stocktakeItems.value;
                  const indexValue = v[index];
                  v[index] = {
                    ...indexValue,
                    value: {
                      ...indexValue.value,
                      ...changes,
                    },
                  };
                  modifyForm({
                    [fields.stocktakeItems.name]: {
                      value: v,
                    },
                  });
                };
                return (
                  <div className={classNames(styles.itemRow)} key={sku}>
                    <div className={classNames(styles.itemValue)}>
                      <Text fontWeight="font-semibold truncate">{itemName}</Text>
                      <Text color="text-gray">{sku}</Text>
                    </div>
                    <div className={classNames(styles.itemValue)}>
                      <Text className={classNames(styles.itemLabel)}>{columns[1].textMobile}</Text>
                      <div className="flex gap-2">
                        <Text>
                          {toAmount(expectedStockQty, measurement === "pc" ? "0,0" : "0,0.00")}
                        </Text>
                        <Text>{measurement}</Text>
                      </div>
                    </div>
                    {[StocktakeStatus.InProgress, StocktakeStatus.ForApproval].includes(status) ? (
                      <div className={classNames(styles.itemValue)}>
                        <Text className={classNames(styles.itemLabel)}>
                          {columns[2].textMobile}
                        </Text>
                        <div>
                          <Input
                            {...actualStockQty}
                            right
                            inputMode="decimal"
                            value={actualStockQty.value}
                            onChange={(name, { value }) => {
                              if (
                                !isNumberValid(value) ||
                                !isValidDecimalPlaces(value, actualStockQty.symbol === "pc" ? 0 : 3)
                              ) {
                                return;
                              }

                              const max = 9999999.999;
                              if (parseAmountToNumber(value) <= max) {
                                onChange({
                                  [name]: { name, value, symbol: actualStockQty.symbol },
                                });
                              }
                            }}
                            onBlur={(value) => {
                              if (value !== "") {
                                onChange({
                                  [actualStockQty.name]: {
                                    name: "actualStockQty",
                                    value: toAmount(
                                      parseAmountToNumber(value),
                                      actualStockQty.symbol === "pc" ? "0,0" : "0,0.000"
                                    ),
                                    symbol: actualStockQty.symbol,
                                  },
                                });
                              }
                            }}
                            onFocus={() => {
                              const value = parseAmountToNumber(actualStockQty.value);
                              onChange(actualStockQty.name, {
                                value: !value ? "" : value,
                              });
                            }}
                            iconSuffix={
                              <span className="text-sm text-gray-400 md:pl-sm">
                                {actualStockQty.symbol}
                              </span>
                            }
                          ></Input>
                        </div>
                      </div>
                    ) : (actualStockQty.value === null || actualStockQty.value === undefined) &&
                      ![StocktakeStatus.InProgress, StocktakeStatus.ForApproval].includes(
                        status
                      ) ? (
                      <div className={classNames(styles.itemValue)}>
                        <Text className={classNames(styles.itemLabel)}>
                          {columns[2].textMobile}
                        </Text>
                        <Text className="text-right">-</Text>
                      </div>
                    ) : (
                      <div className={classNames(styles.itemValue)}>
                        <Text className={classNames(styles.itemLabel)}>
                          {columns[2].textMobile}
                        </Text>
                        <div className="flex gap-2">
                          <Text>{actualStockQty.value}</Text>
                          <Text>{actualStockQty.symbol}</Text>
                        </div>
                      </div>
                    )}
                    {actualStockQty.value === undefined ||
                    actualStockQty.value === null ||
                    actualStockQty.value === "" ? (
                      <div className={classNames(styles.itemValue)}>
                        <Text className={classNames(styles.itemLabel)}>{columns[3].text}</Text>
                        <Text className="text-right">-</Text>
                      </div>
                    ) : (
                      <div className={classNames(styles.itemValue)}>
                        <Text className={classNames(styles.itemLabel)}>{columns[3].text}</Text>
                        <div className="flex gap-2">
                          <Text>
                            {toAmount(
                              difference,
                              actualStockQty.symbol === "pc" ? "0,0" : "0,0.00"
                            )}
                          </Text>
                          <Text>{actualStockQty.symbol}</Text>
                        </div>
                      </div>
                    )}
                    {actualStockQty.value === undefined ||
                    actualStockQty.value === null ||
                    actualStockQty.value === "" ? (
                      <div className={classNames(styles.itemValue)}>
                        <Text className={classNames(styles.itemLabel)}>{columns[4].text}</Text>
                        <Text className="text-right">-</Text>
                      </div>
                    ) : (
                      <div className={classNames(styles.itemValue)}>
                        <Text className={classNames(styles.itemLabel)}>{columns[4].text}</Text>
                        <Text
                          className={classNames("text-right", {
                            "text-red": amount < 0,
                            "text-blue": amount > 0,
                          })}
                        >
                          {amount > 0
                            ? `+${formatNumberToMoneyWithCurrencySymbol(amount)}`
                            : amount < 0
                            ? `-${formatNumberToMoneyWithCurrencySymbol(amount * -1)}`
                            : formatNumberToMoneyWithCurrencySymbol(amount)}
                        </Text>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className={classNames(styles.loading)}>
                {columns.map((column) => {
                  return (
                    <div
                      key={column.id}
                      className={
                        column.id === 1
                          ? "py-sm sm:col-span-2 text-left"
                          : "py-sm sm:col-span-1 text-right"
                      }
                    >
                      <Skeleton single />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}

      <Pagination
        className="mr-md mt-sm"
        optionClassnames="hidden md:block"
        onChangePage={changePageCb}
        onChangePageSize={changePageSizeCb}
        page={filterState.page}
        pageSize={filterState.pageSize}
        total={total}
        size={currentWidth <= 768 ? "small" : "default"}
      />
    </Panel>
  );
};

export default StocktakeItemsTable;
