import { Get, Post } from "services";
import { ApiPath } from "paths";

export const getVenues = async () => {
  const res = await Get(`${ApiPath.VENUE}`, {});
  return res.data;
};

export const getVenue = async ({ venueId }) => {
  const res = await Get(`${ApiPath.GET_VENUE(venueId)}`, {});
  return res.data;
};

export const setVenue = async (body) => {
  const res = await Post(`${ApiPath.GET_VENUE(body.venueId)}`, body);
  return res.data;
};

export const setVenueCreditSettings = async (body) => {
  const res = await Post(`${ApiPath.VENUE_CREDIT_SETTINGS(body.venueId)}`, body);
  return res.data;
};

export const setWristbandKeepingValue = async (body) => {
  const res = await Post(`${ApiPath.VENUE_WRISTBAND(body.venueId)}`, body);
  return res.data;
};

export const getReceiptSettings = async ({ venueId }) => {
  const res = await Get(`${ApiPath.GET_RECEIPT_SETTINGS(venueId)}`, {});
  return res;
};

export const setReceiptSettings = async ({ venueId, ...body }) => {
  const request = [];
  [body].forEach((v) => {
    for (const key in v) {
      request.push(v[key]);
    }
  });
  const res = await Post(`${ApiPath.GET_RECEIPT_SETTINGS(venueId)}`, request);
  return res;
};

export const createVenue = async (body) => {
  const res = await Post(`${ApiPath.VENUE}`, body);
  return res;
};

export const getOnboardingChecklist = async ({ venueId }) => {
  const res = await Get(`${ApiPath.GET_CHECKLIST_STATUS(venueId)}`);
  return res;
};

export const closeOnboardingBanner = async ({ venueId }) => {
  const res = await Post(`${ApiPath.CLOSE_BANNER(venueId)}`);
  return res;
};