// import { capitalize } from "services";
// import { formatNumberToMoney } from "services/money.service";
// import Validation from "services/validation.service";
// import lang from "translations";
import { Field as FieldType } from "enums";
import { AccountName, AccountType } from "enums";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    inventoryAccount = null,
    inventoryGainLossAccount = null,
    taxOnPurchasesAccount = null,

    revenueAccount = null,
    costOfGoodsSold = null,
    taxOnSalesLiabilityAccount = null,
    salesDiscounts = null,
    salesFOC = null,

    creditsLiabilityAccount = null,
    freeCreditsLiabilityAccount = null,
    postpaidCreditsLiabilityAccount = null,
    clearedUnusedAndExpiredCredits = null,
  } = initialState;

  return {
    // inventory purchasing
    inventoryAccount: {
      ...inventoryAccount,
      name: "inventoryAccount",
      type: FieldType.DROPDOWN,
      label: lang.inventoryAccount,
      description: lang.inventoryAccountMessage,
      placeholder: lang.inventoryAccountPlaceholder,
      accountType: AccountName.InventoryAccount,
      suggested: AccountType.Asset,
    },
    inventoryGainLossAccount: {
      ...inventoryGainLossAccount,
      name: "inventoryGainLossAccount",
      type: FieldType.DROPDOWN,
      label: lang.inventoryGainLossAccount,
      description: lang.inventoryGainLossAccountMessage,
      placeholder: lang.InventoryGainLossAccountPlaceholder,
      accountType: AccountName.InventoryGainLossAccount,
      suggested: AccountType.Expense,
    },
    taxOnPurchasesAccount: {
      ...taxOnPurchasesAccount,
      name: "taxOnPurchasesAccount",
      type: FieldType.DROPDOWN,
      label: lang.taxOnPurchasesAccount,
      description: lang.taxOnPurchasesAccountMessage,
      placeholder: lang.taxOnPurchasesAccountPlaceholder,
      accountType: AccountName.TaxOnPurchaseAccount,
      suggested: AccountType.Liability,
    },

    // Sales
    revenueAccount: {
      ...revenueAccount,
      name: "revenueAccount",
      type: FieldType.DROPDOWN,
      label: lang.RevenueAccount,
      description: lang.RevenueAccountMessage,
      placeholder: lang.revenueAccountPlaceholder,
      accountType: AccountName.RevenueAccount,
      suggested: AccountType.Revenue,
    },
    costOfGoodsSold: {
      ...costOfGoodsSold,
      name: "costOfGoodsSold",
      type: FieldType.DROPDOWN,
      label: lang.costOfGoodsSold,
      description: lang.costOfGoodsSoldMessage,
      placeholder: lang.costOfGoodsSoldPlaceholder,
      accountType: AccountName.CostOfGoodsSold,
      suggested: AccountType.Expense,
    },
    taxOnSalesLiabilityAccount: {
      ...taxOnSalesLiabilityAccount,
      name: "taxOnSalesLiabilityAccount",
      type: FieldType.DROPDOWN,
      label: lang.taxOnSalesLiabilityAccount,
      description: lang.taxOnSalesLiabilityAccountMessage,
      placeholder: lang.taxOnSalesLiabilityAccountPlaceholder,
      accountType: AccountName.TaxOnSalesLiabilityAccount,
      suggested: AccountType.Liability,
    },
    salesDiscounts: {
      ...salesDiscounts,
      name: "salesDiscounts",
      type: FieldType.DROPDOWN,
      label: lang.salesDiscounts,
      description: lang.salesDiscountsMessage,
      placeholder: lang.salesDiscountsPlaceholder,
      accountType: AccountName.SaleDiscounts,
      suggested: AccountType.Expense,
    },
    salesFOC: {
      ...salesFOC,
      name: "salesFOC",
      type: FieldType.DROPDOWN,
      label: lang.salesFOC,
      description: lang.salesFOCMessage,
      placeholder: lang.salesFOCPlaceholder,
      accountType: AccountName.SaleFoc,
      suggested: AccountType.Revenue,
    },

    // Credits
    creditsLiabilityAccount: {
      ...creditsLiabilityAccount,
      name: "creditsLiabilityAccount",
      type: FieldType.DROPDOWN,
      label: lang.creditsLiabilityAccount,
      description: lang.creditsLiabilityAccountMessage,
      placeholder: lang.creditsLiabilityAccountPlaceholder,
      accountType: AccountName.CreditsLiabilityAccount,
      suggested: AccountType.Liability,
    },
    freeCreditsLiabilityAccount: {
      ...freeCreditsLiabilityAccount,
      name: "freeCreditsLiabilityAccount",
      type: FieldType.DROPDOWN,
      label: lang.freeCreditsLiabilityAccount,
      description: lang.freeCreditsLiabilityAccountMessage,
      placeholder: lang.freeCreditsLiabilityAccountPlaceholder,
      accountType: AccountName.FreeCreditsLiabilityAccount,
      suggested: AccountType.Liability,
    },
    postpaidCreditsLiabilityAccount: {
      ...postpaidCreditsLiabilityAccount,
      name: "postpaidCreditsLiabilityAccount",
      type: FieldType.DROPDOWN,
      label: lang.postpaidCreditsLiabilityAccount,
      description: lang.postpaidCreditsLiabilityAccountMessage,
      placeholder: lang.postpaidCreditsLiabilityAccountPlaceholder,
      accountType: AccountName.PostpaidCreditsLiabilityAccount,
      suggested: AccountType.Asset,
    },
    clearedUnusedAndExpiredCredits: {
      ...clearedUnusedAndExpiredCredits,
      name: "clearedUnusedAndExpiredCredits",
      type: FieldType.DROPDOWN,
      label: lang.clearedUnusedAndExpiredCredits,
      description: lang.clearedUnusedAndExpiredCreditsMessage,
      placeholder: lang.clearedUnusedAndExpiredCreditsPlaceholder,
      accountType: AccountName.ClearedUnusedExpiredCredit,
      suggested: AccountType.Revenue,
    },
  };
};

export default initialFormState;
