const staffState = {
  avatar: null,
  country: null,
  countryCode: null,
  dob: null,
  email: "",
  facebookId: null,
  firstName: null,
  forType: "",
  fullName: null,
  gender: null,
  isActive: false,
  isAwait: false,
  isDeleted: false,
  lastName: null,
  locations: [],
  paymentMethods: [],
  phone: null,
  profileId: 0,
  profileNfcTags: [],
  profileType: "",
  roles: [],
  status: "",
  userId: 0,
};

export default staffState;
