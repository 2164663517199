import { StaffRole, StaffStatus } from "enums";
import {
  formatName,
  formatNumberToOrdinal,
  formatTagUIds,
  mapSelectObjectsToValue,
  prettifyStaffRole,
  prettifyStaffStatus,
} from "services";
import { dateTimeRequest, filterRequest } from "./common.mapper";
import lang from "translations";

export const staffListResponse = ({ locations = [] }) => {
  return {
    id: {
      key: "profileId",
    },
    name: {
      transform: ({ src }) => {
        if (src.firstName || src.lastName) {
          return formatName(src.firstName, src.lastName);
        }
        return src.email || "";
      },
    },
    status: {
      transform: ({ src }) => {
        if (src.isAwait) {
          return prettifyStaffStatus(StaffStatus.Awaiting);
        }
        if (src.isActive) {
          return "";
        }
        return prettifyStaffStatus(StaffStatus.Inactive);
      },
    },
    tagUids: {
      transform: ({ src }) => {
        let tagUids = new Set();

        const profileNfcTags = src.profileNfcTags || [];

        for (const profileNfcTag of profileNfcTags) {
          tagUids.add(profileNfcTag.tagUid);
        }

        return tagUids;
      },
    },
    isManager: {
      transform: ({ src }) => {
        const isManager = src.roles.some((r) => r.role === StaffRole.Manager);

        if (isManager) {
          return [prettifyStaffRole(StaffRole.Manager)];
        }
        return false;
      },
    },
    roles: {
      transform: ({ src }) => {
        const isManager = src.roles.some((r) => r.role === StaffRole.Manager);

        if (isManager) {
          return [prettifyStaffRole(StaffRole.Manager)];
        }
        return src.roles.map((r) => prettifyStaffRole(r.role));
      },
    },
    locations: {
      transform: ({ src }) => {
        if (locations.length === src.locations.length) {
          return ["All Locations"];
        }
        return src.locations.map((l) => l.locationName);
      },
    },
  };
};

export const staffListFilterRequest = {
  showOwnProfile: false,
  ...filterRequest,
};

export const staff = {
  name: {
    transform: ({ src }) => {
      return src.fullName || src.email || `#${src.profileId}`;
    },
  },
  fullName: {
    transform: ({ src }) => {
      return src.fullName || `#${src.userId}`;
    },
  },
  roles: {
    transform: ({ src }) => {
      return src.roles.map((r) => r.role);
    },
  },
};

export const staffWithGuestFilterRequest = {
  venueId: { key: "venueId" },
  ...dateTimeRequest,
};

export const staffWithTransactionFilterResponse = {
  id: { key: "profileId" },
  name: {
    transform: ({ src }) => {
      if (src.firstName || src.lastName) {
        return formatName(src.firstName, src.lastName);
      }
      return src.email || "";
    },
  },
};

export const staffWithTransactionFilterRequest = {
  venueId: { key: "venueId" },
  transactionTypes: [],
  ...dateTimeRequest,
};

export const staffInviteRequest = {
  _keys: ["email", "venueId"],
  staffProfileId: {
    transform: ({ src }) => {
      return parseInt(src.staffProfile);
    },
  },
  locationIds: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.location);
    },
  },
  paymentMethodIds: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.paymentMethod);
    },
  },
  roles: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.role);
    },
  },
};

export const editStaffRequest = {
  _keys: ["venueId"],
  locationIds: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.location);
    },
  },
  paymentMethodIds: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.paymentMethod);
    },
  },
  active: {
    key: "isActive",
  },
  staffId: {
    key: "id",
  },
  roles: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.role);
    },
  },
};

export const staffResponse = {
  _keys: ["email", "isAwait", "isActive"],
  status: {
    transform: ({ src }) => {
      if (src.isAwait) {
        return prettifyStaffStatus(StaffStatus.Awaiting);
      }
      if (src.isActive) {
        return prettifyStaffStatus(StaffStatus.Active);
      }
      return prettifyStaffStatus(StaffStatus.Inactive);
    },
  },
  name: {
    transform: ({ src }) => {
      if (src.firstName || src.lastName) {
        return formatName(src.firstName, src.lastName);
      }
      return src.email || "";
    },
  },
  staffProfile: { key: "staffProfile" },
  roles: {
    transform: ({ src }) => {
      let roles = src.roles.map((r) => {
        return {
          value: r.role,
        };
      });
      return roles;
    },
  },
  isManager: {
    transform: ({ src }) => {
      return src.roles.some((r) => r.role === StaffRole.Manager);
    },
  },
  paymentMethod: {
    transform: ({ src }) => {
      return src.paymentMethods.map((r) => {
        return {
          value: r.paymentMethodId,
          text: r.paymentMethodName,
        };
      });
    },
  },
  location: {
    transform: ({ src }) => {
      return src.locations.map((r) => {
        return {
          value: r.locationId,
          text: r.locationName,
        };
      });
    },
  },
  tagUids: {
    transform: ({ src }) => {
      return src.profileNfcTags.map(({ tagUid, useCount }) => {
        return `${formatTagUIds(tagUid)} (${lang.populate(lang.nthUse, [
          formatNumberToOrdinal(useCount),
        ])})`;
      });
    },
  },
};
