import { Post } from "services";
import { v1 as uuidv1 } from "uuid";
import { ApiPath } from "paths";

export const uploadFile = async ({ file }) => {
  const formData = new FormData();
  const fileName = uuidv1();
  formData.append("files", file, fileName);
  const res = await Post(`${ApiPath.STORAGE_UPLOAD}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};
