export const settingPaths = {
  SETTING_GENERAL_SETTING: "general",
  SETTING_TAX: "tax",
  PAYMENT_METHOD_SETTING: "payment-method",
  CREDIT_LIMIT_SETTING: "credit-settings",
  ACCOUNT_SETTING: "account",
  QUESTION_SETTING: "question",
  WRISTBAND_KEEPING: "wristband-keeping",
  BAN_TAG: "ban-tag",
  SETTING_NOTIFICATION: "notifications",
  SETTING_NOTIFICATION_STOCK_LEVEL: "notification/stock-level",
  RECEIPT_SETTING: "receipt",
  INTEGRATION_SETTING: "integration",
  ACCOUNT_MAPPING: "account-mapping",
  CHART_OF_ACCOUNTS: "chart-of-accounts",
};
