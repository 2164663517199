import React, { useState, useCallback } from "react";
import { Input } from "components/commons";
import classnames from "classnames";
import { SearchOutlined } from "@ant-design/icons";
import lang from "translations";
const SearchInput = ({ filterState = {}, onApply, className }) => {
  const [searchKey, setSearchKey] = useState(filterState.searchKey);

  const onApplyCb = useCallback(
    (searchKeyOverride) => {
      if (searchKeyOverride === undefined || searchKeyOverride === null) {
        onApply(searchKey);
      } else {
        onApply(searchKeyOverride);
      }
    },
    [searchKey, onApply]
  );

  return (
    <div className={classnames(className, "ml-sm border-none rounded")}>
      <Input
        clearable
        className={classnames("border-none rounded bg-gray-100")}
        maxLength={75}
        placeholder={lang.search2}
        iconSuffix={
          <SearchOutlined
            className="pointer text-gray-400"
            onClick={() => {
              onApplyCb();
            }}
          />
        }
        onChange={(name, obj) => {
          setSearchKey(obj.value);
          onApplyCb(obj.value);
        }}
        value={searchKey}
        onClear={() => {
          onApplyCb("");
        }}
        onEnter={() => {
          if (searchKey?.length > 0) {
            onApplyCb();
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
