import lang from "translations";

const downloaderState = {
  title: lang.loading,
  expanded: true,
  excludedIds: [],
  processingCount: 0,
  successCount: 0,
  failedCount: 0,
};

export default downloaderState;
