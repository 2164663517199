export const profileRequest = {
  venueId: {
    key: "venueId",
  },
  profileId: {
    key: "profileId",
  },
  firstName: {
    key: "firstName",
  },
  lastName: {
    key: "lastName",
  },
};
