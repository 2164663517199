import React, { useCallback, useContext } from "react";
import CategoryForm from "../category-form/category-form";
import lang from "translations";
import { useApi, useMount } from "hooks";
import { createCategory } from "apis";
import { mapObject } from "services";
import { categoryFormRequest } from "mappers";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateCategory = () => {
  const { venue } = useContext(VenueContext);
  const { request: createCategoryRequest, loading: submitting } = useApi({
    api: createCategory,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await createCategoryRequest(
          mapObject({ ...params, venueId: venue.venueId }, categoryFormRequest)
        );
        return {
          response: res,
          message: lang.populate(lang.categoryCreated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [createCategoryRequest, venue.venueId]
  );

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createCategory,
    });
  });

  return (
    <CategoryForm submit={submitForm} title={lang.createNewCategory} submitting={submitting} />
  );
};

export default CreateCategory;
