import React from "react";
import ChartContainer from "../chart-container";
import lang from "translations";
import { Title, LineChart } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

const TotalSalesChart = ({ ...props }) => {
  const data = props.mappedData;
  return (
    <ChartContainer title={lang.totalSales} {...props} empty={!data.totalSales}>
      <Title xl className="my-sm mb-xl" fontWeight="font-normal">
        {data.total}
      </Title>
      <LineChart
        height={330}
        {...data.data}
        // formatYAxis={(label) => {
        //   return formatNumberWithComma(parseInt(label));
        // }}
        formatToolTipValue={(value) => {
          return formatNumberToMoneyWithCurrencySymbol(value.value);
        }}
      />
    </ChartContainer>
  );
};

export default TotalSalesChart;
