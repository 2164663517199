import { activateApplication } from "apis/application.api";
import { ActionButton, ButtonLink, Icon, Modal, Text } from "components/commons";
import { HeaderD } from "components/headers";
import { VenueContext } from "contexts";
import { IntegrationType } from "enums";
import { useApi, useModal } from "hooks";
import React, { useContext } from "react";
import lang from "translations";
import ConnectModal from "./connect-modal";
import styles from "./connect-modal.module.scss";

const AllowAccessModal = ({ data, modifyField, refreshList, background, ...props }) => {
  const {
    name,
    imageLink,
    type,
    company,
    permissions,
    privacyLink,
    termsAndConditionsLink,
    instruction,
    request,
    isApp,
    connectedIntegrationsList,
    authorizationLink,
  } = data;
  const connectModal = useModal();
  const { venue } = useContext(VenueContext);
  const { venueId } = venue || {};

  const activateApp = useApi({
    api: activateApplication,
  });

  return (
    <>
      <Modal {...props} noHeader>
        <div className="p-sm">
          <div className="flex py-lg justify-between">
            <HeaderD
              logoUrl={imageLink}
              title={name}
              label={type}
              description={company}
              background={background}
              className={styles.header}
            />
            <Icon name="remove" onClick={() => props.close()} />
          </div>
          <Text fontWeight="font-semibold text-md">{`Connect ${name} to your PouchNATION account.`}</Text>
          {permissions &&
            (name === lang.purchaseplus ? (
              <Text label className="text-sm my-md">
                <lang.Translate
                  text={lang.wouldReadOrWrite}
                  items={[<span className="font-bold text-black-dark">{lang.pouchnation}</span>]}
                />
              </Text>
            ) : (
              <Text label className="text-sm my-md">
                {/* {lang.thisAppRequests} */}
                {lang.thisAppWouldRead}
              </Text>
            ))}
          {permissions &&
            permissions.map((permission) => {
              return (
                <div className="flex items-center my-sm" key={permission.permissionId}>
                  <Icon name="check-only" className="text-pelorous text-sm mr-sm" />
                  <Text size="text-sm" fontWeight="font-semibold">
                    {permission.text}
                  </Text>
                </div>
              );
            })}
          {!isApp ? (
            name === lang.purchaseplus ? (
              <div className="leading-none mt-lg mb-md">
                <Text>
                  <lang.Translate
                    className="text-xs text-gray"
                    text={lang.termsInAllowingAccess3}
                    items={[
                      <span>{lang.pouchnation}</span>,
                      <span>{lang.purchasePlus}</span>,
                      <ButtonLink
                        className="text-xs text-blue underline"
                        flex={false}
                        onClick={() => window.open(termsAndConditionsLink)}
                      >
                        {lang.termsAndConditions}
                      </ButtonLink>,
                      <ButtonLink
                        className="text-xs text-blue underline"
                        flex={false}
                        onClick={() => window.open(privacyLink)}
                      >
                        {lang.privacyPolicy2}
                      </ButtonLink>,
                    ]}
                  />
                </Text>
              </div>
            ) : (
              <div className="leading-none mt-lg mb-md">
                <Text>
                  <lang.Translate
                    className="text-xs text-gray"
                    text={lang.termsInAllowingAccess}
                    items={[
                      <span>{name}</span>,
                      <span>{name}</span>,
                      <ButtonLink
                        className="text-xs text-blue underline"
                        flex={false}
                        onClick={() => window.open(termsAndConditionsLink)}
                      >
                        {lang.termsAndConditions}
                      </ButtonLink>,
                      <ButtonLink
                        className="text-xs text-blue underline"
                        flex={false}
                        onClick={() => window.open(privacyLink)}
                      >
                        {lang.privacyPolicy2}
                      </ButtonLink>,
                    ]}
                  />
                </Text>
              </div>
            )
          ) : (
            <Text>
              <lang.Translate
                className="text-xs text-gray"
                text={lang.termsInAllowingAccess2}
                items={[<span>{name}</span>, <span>{name}</span>]}
              />
            </Text>
          )}
          <ActionButton
            className="pb-md mt-md"
            secondary={{
              text: lang.cancel,
              onClick: () => {
                props.close();
              },
            }}
            primary={{
              text: !isApp ? lang.allowAccess : lang.allowAccessAndConnect,
              onClick: async () => {
                if (isApp) {
                  await activateApp.request({
                    id: data?.id,
                    body: {
                      businessId: venueId,
                      businessType: "VENUE",
                    },
                  });
                  if (refreshList) {
                    refreshList();
                  }
                } else if (!isApp && name===lang.cloudbeds){
                    window.open(authorizationLink)
                }else {
                  connectModal.show({
                    ...data,
                    title: name,
                    instruction,
                    request,
                    disableAccessToken:
                      (name === lang.mewsAccounting.replace("_", " ") &&
                        connectedIntegrationsList.includes(IntegrationType.MEWS)) ||
                      (name === lang.mewsBookings.replace("_", " ") &&
                        connectedIntegrationsList.includes(IntegrationType.MEWS_ACCOUNTING)),
                    name: name,
                  });
                }
                props.close();
              },
            }}
          />
        </div>
      </Modal>
      <ConnectModal {...connectModal} modifyField={modifyField} />
    </>
  );
};

export default AllowAccessModal;
