import enumeration from "./enumeration";

const InventoryMovementType = {
  AddedStock: "ADDED_STOCK",
  RemovedStock: "REMOVED_STOCK",
  CreatedSupplyItem: "CREATED_SUPPLY_ITEM",
  TrackedInventory: "TRACKED_INVENTORY",
  Sale: "SALE",
  SaleReturn: "SALE_RETURN",
  Redeemed: "REDEEMED",
  Mixed: "MIXED",
};

export default enumeration(InventoryMovementType);
