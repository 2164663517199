import { Modal, Text, Title, Image } from "components/commons";
import { useRouter } from "hooks";
import { Meal } from "images";
import { ProductCup } from "images";
import { Path } from "paths";
import React from "react";
import lang from "translations";
import styles from "./create-product-modal.module.scss";
import { v4 as uuidv4 } from "uuid";

const CreateProductModal = ({ requestState, ...modal }) => {
  const { history } = useRouter();
  return (
    <Modal
      {...modal}
      noHeader
      className={styles.modal}
      closable
      onClose={() => {
        modal.close();
      }}
      paddingless={true}
    >
      <div className={styles.container}>
        {[
          {
            title: lang.newProduct,
            description: lang.createTheProduct,
            image: ProductCup,
            path: Path.CREATE_PRODUCT,
          },
          {
            title: lang.newProductSet,
            description: lang.combineYourProducts,
            image: Meal,
            path: Path.CREATE_PRODUCT_SET,
          },
        ].map((item) => {
          return (
            <div
              key={uuidv4()}
              className={styles.item}
              onClick={() => {
                modal.close();
                history.push(item.path, {
                  page: requestState.page,
                  categories: requestState.categories,
                });
              }}
            >
              <Image className={styles.image} src={item.image} />
              <Title lg color="text-pelorous" fontWeight="font-semibold">
                {item.title}
              </Title>
              <Text className={styles.description}>{item.description}</Text>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default CreateProductModal;
