import lang from "translations";

const columns = [
  {
    key: "discount",
    text: lang.discountName,
    custom: true,
    width: "w-auto",
  },
  {
    key: "value",
    text: lang.value,
    width: "w-2/12",
    align: "right",
  },
  {
    key: "status",
    text: lang.status,
    custom: true,
    width: "w-1/12",
  },
  {
    key: "availability",
    text: lang.availability,
    custom: true,
    width: "w-auto",
  },
  {
    key: "dateCreated",
    text: lang.dateCreated,
    custom: true,
    width: "w-auto",
  },
  {
    key: "onPos",
    text: lang.onPos,
    custom: true,
    width: "w-1/12",
  },
];

export default columns;
