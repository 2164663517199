import lang from "translations";
import * as moment from "moment";

export const setPageTitle = (key) => {
  document.title = `${key ? `${lang[key]} - ` : ""}${lang.venueDashboard}`;
};

export const setGlobalDateTimeFilter = (dateRange) => {
  localStorage.setItem(
    "globalDateTimeFilter",
    [moment(dateRange[0]).format("MMDDYYYYHHmm"), moment(dateRange[1]).format("MMDDYYYYHHmm")].join(
      ","
    )
  );
};

export const getGlobalDateTimeFilter = () => {
  const dateTime = localStorage.getItem("globalDateTimeFilter");

  if (dateTime) {
    const d = dateTime.split(",");
    return [moment(d[0], "MMDDYYYYHHmm"), moment(d[1], "MMDDYYYYHHmm")];
  }
  return [moment().startOf("day"), moment().endOf("day")];
};

const timerIds = [];

export const debounced = (delay, fn) => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      // trigger function only on last hit
      if (timerIds[timerIds.length - 1] === timerId) {
        fn(...args);
      }
      timerId = null;
    }, delay);
    timerIds.push(timerId);
  };
};

// export const setTemporaryShiftId = (id) => {
//   localStorage.setItem("shiftId", id);
// };

// export const setTemporaryShiftId = (id) => {
//   localStorage.getItem("shiftId");
// };

// export const setTemporaryDateTime = (dateRange) => {
//   localStorage.setItem("temporaryDateTime", [dateRange[0], dateRange[1]].join(","));
// };

// export const getTemporaryDateTime = () => {
//   const dateTime = localStorage.getItem("temporaryDateTime");

//   if (dateTime) {
//     const d = dateTime.split(",");
//     return [moment(d[0]), moment(d[1])];
//   }
//   return [];
// };
