import lang from "translations";
const columns = [
  {
    key: "name",
    text: lang.name,
    align: "left",
    width: "w-1/2",
  },
  {
    key: "checkinDate",
    text: lang.checkInDate,
    align: "right",
  },
];

export default columns;
