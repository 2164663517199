import { Pill } from "components/commons";
import { BookingStatus, IntegrationType, PillType } from "enums";
import { useCallback } from "react";
import { prettifyBookingStatus } from "services/pretty.service";
import lang from "translations";

const BookingStatusPill = ({ status, source }) => {
  const renderStatus = useCallback((status, source) => {
    let formattedStatus = status?.toUpperCase();
    switch (source) {
      case IntegrationType.CLOUDBEDS:
        switch (formattedStatus) {
          case BookingStatus.CHECKED_IN:
            return <Pill type={PillType.Green}>{lang.inHouse}</Pill>;
          case BookingStatus.CHECKED_OUT:
            return <Pill type={PillType.Gray}>{prettifyBookingStatus(formattedStatus)}</Pill>;
          case BookingStatus.CONFIRMED:
            return <Pill type={PillType.Blue}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.CONFIRMATION_PENDING:
            return <Pill type={PillType.Blue}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.NO_SHOW:
            return <Pill type={PillType.Reddish}>{prettifyBookingStatus(formattedStatus)}</Pill>;
          case BookingStatus.CANCELLED:
            return <Pill type={PillType.Red}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.CANCELED:
            return <Pill type={PillType.Red}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.TO_BE_CHECKED_IN:
            return <Pill type={PillType.Blue}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.TO_BE_CHECKED_OUT:
            return <Pill type={PillType.Orange}>{prettifyBookingStatus(status)}</Pill>;
          default:
            return <Pill type={PillType.Gray}>{prettifyBookingStatus(formattedStatus)}</Pill>;
        }
      default:
        switch (formattedStatus) {
          case BookingStatus.TO_BE_CHECKED_IN:
            return <Pill type={PillType.Blue}>{prettifyBookingStatus(formattedStatus)}</Pill>;
          case BookingStatus.CHECKED_IN:
            return <Pill type={PillType.Green}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.FUTURE:
            return <Pill type={PillType.Yellow}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.MISSED_CHECK_IN:
            return <Pill type={PillType.Gray}>{prettifyBookingStatus(formattedStatus)}</Pill>;
          case BookingStatus.TO_BE_CHECKED_OUT:
            return <Pill type={PillType.Yellow}>{prettifyBookingStatus(status)}</Pill>;
          case BookingStatus.MISSED_CHECK_OUT:
            return <Pill type={PillType.Red}>{prettifyBookingStatus(formattedStatus)}</Pill>;
          case BookingStatus.CANCELLED:
            return <Pill type={PillType.Gray}>{prettifyBookingStatus(formattedStatus)}</Pill>;
          default:
            return <Pill type={PillType.Gray}>{prettifyBookingStatus(formattedStatus)}</Pill>;
        }
    }
  }, []);

  return renderStatus(status, source);
};

export default BookingStatusPill;
