import { Delete, Post } from "services";
import { ApiPath } from "paths";

export const searchBanTags = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_BAN_TAG}`, body);
  return res;
};

export const createBanTag = async (body) => {
  const res = await Post(`${ApiPath.CREATE_BAN_TAG}`, body);
  return res.data;
};

export const unBanTag = async ({ bannedTagId }) => {
  const res = await Delete(`${ApiPath.DELETE_BAN_TAG(bannedTagId)}`);
  return res.data;
};

export const searchBanTagsForBanning = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_BAN_TAG_FOR_BANNING}`, body);
  return res;
};
