import React, { useCallback, useContext } from "react";
import DiscountForm from "../discount-form/discount-form";
import lang from "translations";
import { useApi, useMount } from "hooks";
import { createDiscount } from "apis";
import { discountFormRequest } from "mappers";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateDiscount = () => {
  const { venue } = useContext(VenueContext);
  const { request: createDiscountRequest, loading: submitting } = useApi({
    api: createDiscount,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: discountFormRequest,
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await createDiscountRequest({ ...params, venueId: venue.venueId });
        return {
          response: res,
          message: lang.populate(lang.voucherCreated, [params.name]),
        };
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    [createDiscountRequest, venue.venueId]
  );

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createDiscountPage,
    });
  });

  return (
    <div>
      <DiscountForm title={lang.createNewDiscount} submitting={submitting} submit={submitForm} />
    </div>
  );
};

export default CreateDiscount;
