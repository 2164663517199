import moment from "moment";

export const stocktakeFilter = (venueId) => ({
  venueId,
  searchKey: "",
  dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
  page: 1,
  pageSize: 20,
  pageable: true,
  status: [],
  locations: [],
  sort: { key: "updatedDate", value: "desc" },
});
