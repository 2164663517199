export const productPaths = {
  PRODUCT: "",
  PRODUCT_ID: (id) => id,
  CATEGORY: "/category",
  CATEGORY_CREATE: "/category/create",
  CATEGORY_EDIT: "/category/:id",
  CATEGORY_ID: (id) => `/category/${id}`,
  CREATE_PRODUCT: "create",
  CREATE_PRODUCT_SET: "create/set",
  EDIT_PRODUCT_SET: "edit/set/:id",
  EDIT_PRODUCT_SET_ID: (id) => `edit/set/${id}`,
  EDIT_PRODUCT: "edit/:id",
  EDIT_PRODUCT_ID: (id) => `edit/${id}`,
};
