import lang from "translations";

const columns = [
  {
    key: "name",
    text: lang.supplyItem,
    sort: true,
  },
  {
    key: "sku",
    text: lang.sku,
    width: "w-28",
  },
  {
    key: "parLevel",
    text: lang.parLevel,
    align: "right",
    width: "w-40",
  },
  {
    key: "reorderPoint",
    text: lang.reorderPoint,
    align: "right",
    width: "w-40",
  },
  {
    key: "stock",
    text: lang.inStock,
    align: "right",
    width: "w-40",
  },
  {
    key: "stockVariance",
    text: lang.stockVariance,
    align: "right",
    sort: true,
    width: "w-48",
  },
];

export default columns;
