import { DataTable, Text } from "components/commons";
import { BookingItemPropertyType } from "enums";
import { useMount } from "hooks";
import { mixpanel } from "mixpanel";
import React from "react";
import lang from "translations";
import { columns } from "./columns";

const BookingAccommodation = ({
  data: bookingDetailsData,
  loading: loadingBookingDetails,
  error,
}) => {
  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.bookingAccomodation,
    });
  });

  return (
    <DataTable
      loading={loadingBookingDetails}
      error={error}
      total={bookingDetailsData?.accommodations?.length || 0}
      title={lang.assignedRooms}
      columns={columns}
      data={
        bookingDetailsData?.accommodations?.map((accommodation) => {
          const { name, adultCount, childCount } = accommodation;
          const assignedSpace = accommodation.properties?.filter(
            (property) => property.type === BookingItemPropertyType.ASSIGNED_SPACE
          )?.[0];
          return {
            rooms: (
              <>
                <Text>{name}</Text>
                {bookingDetailsData?.products?.map((product, index) => {
                  return (
                    <Text color="text-gray" key={index}>
                      {product.quantity > 1 ? `(${product.quantity}x)` : ""} {product.name} included
                    </Text>
                  );
                })}
              </>
            ),
            capacity: <Text>{(adultCount ?? 0) + (childCount ?? 0)}</Text>,
            assignedSpace: <Text>{assignedSpace?.value}</Text>,
          };
        }) || []
      }
      minWidth="800px"
    />
  );
};

export default BookingAccommodation;
