import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import {
  GuestList,
  GuestProfileList,
  CreateGuestProfile,
  EditGuestProfile,
  GuestDetails,
  GuestDetailsProfile,
} from "modules";

const GuestPage = () => {
  return (
    <Switch>
      <Route exact path={Path.GUEST_PROFILE_CREATE} component={CreateGuestProfile} />
      <Route exact path={Path.GUEST_PROFILE_EDIT} component={EditGuestProfile} />
      <Route exact path={Path.GUEST_PROFILE} component={GuestProfileList} />
      <Route exact path={Path.GUEST_DETAILS} component={GuestDetails} />
      <Route exact path={Path.GUEST_DETAILS_PROFILE} component={GuestDetailsProfile} />
      <Route exact path={Path.GUEST} component={GuestList} />
    </Switch>
  );
};

export default GuestPage;
