import { Line, Panel, Skeleton } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import { useRouter } from "hooks";
import { Path } from "paths";
import lang from "translations";

const SupplyItemMovementSkeleton = () => {
  const { history } = useRouter();
  return (
    <ModuleWrapper width="full">
      <HeaderB
        returnText={lang.supplyItems}
        onClick={() => history.push(Path.INVENTORY_SUPPLY_ITEM)}
      />
      <Skeleton single />
      <Line></Line>
      <>
        <Panel>
          <Skeleton width="100%" active={true} />
        </Panel>
      </>
    </ModuleWrapper>
  );
};

export default SupplyItemMovementSkeleton;
