import StaffProfileForm from "../staff-profile-form/staff-profile-form";
import { useApi, useModal, useMount, useRouter } from "hooks";
import React, { useCallback, useMemo } from "react";
import lang from "translations";
import { editStaffProfile, getStaffProfile, deleteStaffProfile } from "apis/staff-profile.api";
import { staffProfile, staffProfileRequest } from "mappers";
import { DeleteModal } from "components/modals";
import { Text, Toast } from "components/commons";
import { Path } from "paths";
import { mapObject } from "services";
import { mixpanel, TrackEvent } from "mixpanel";

const EditStaffProfile = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const deleteModal = useModal();

  const {
    request,
    loading = true,
    error,
    mappedData,
  } = useApi({
    api: getStaffProfile,
    mapper: staffProfile,
    params: {
      staffProfileId: id,
    },
    handleOwnError: true,
  });

  const { name, staffProfileDefault } = mappedData || {};

  const { request: submit, loading: submitting } = useApi({
    api: editStaffProfile,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: deleteRequest, loading: deleting } = useApi({
    api: deleteStaffProfile,
    params: {
      staffProfileId: id,
    },
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await submit(mapObject({ ...params, id }, staffProfileRequest));
        return {
          response: res,
          message: lang.changesSaved,
        };
      } catch (e) {
        throw e;
      }
    },
    [submit, id]
  );

  useMount(() => {
    request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editStaffProfilePage,
    });
  });

  const initialState = useMemo(() => {
    const { name, description, staffProfileVouchers, freeCredit } = mappedData || {};
    return {
      name,
      description,
      staffProfileVouchers,
      freeCredit,
    };
  }, [mappedData]);

  return (
    <>
      <StaffProfileForm
        error={error}
        loading={loading}
        title={name}
        submit={submitForm}
        submitting={submitting || deleting}
        initialState={initialState}
        deleteButton={
          staffProfileDefault
            ? null
            : {
                onClick: () => {
                  deleteModal.show();
                },
                text: lang.deleteStaffProfile,
              }
        }
      />
      <DeleteModal
        {...deleteModal}
        deleting={deleting}
        primaryText={lang.deleteStaffProfile}
        ok={async () => {
          await deleteRequest();
          Toast({
            content: lang.staffProfileDeleted,
            success: true,
            icon: "check",
          }).open();
          history.push(Path.STAFF_PROFILE);
        }}
        customContent={
          <div>
            <Text>{lang.youAreAboutToDeleteStaffProfile}</Text>
          </div>
        }
        customHeader={
          <Text modalTitle className="py-md">
            {lang.askDeleteStaffProfile}
          </Text>
        }
      />
    </>
  );
};

export default EditStaffProfile;
