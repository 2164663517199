import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { useMount } from "hooks";
import { setPageTitle } from "services";
import { DeviceList, EditDevice, EditPrinter, PrinterList, CreatePrinter } from "modules";

const DevicePage = () => {
  useMount(() => {
    setPageTitle("devices");
  });
  return (
    <Switch>
      <Route exact path={Path.PRINTER} component={PrinterList} />
      <Route exact path={Path.PRINTER_CREATE} component={CreatePrinter} />
      <Route exact path={Path.PRINTER_EDIT} component={EditPrinter} />
      <Route exact path={Path.DEVICE_EDIT} component={EditDevice} />
      <Route exact path={Path.DEVICE} component={DeviceList} />
    </Switch>
  );
};

export default DevicePage;
