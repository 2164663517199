import React, { useContext, useMemo } from "react";
import { HeaderA } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import {
  useApi,
  useMount,
  // useRouter,
  useFilter,
  useModal,
} from "hooks";
import { searchLocation } from "apis/location.api";
import { ButtonLink, DataTable } from "components/commons";
import { location } from "mappers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import GenerateCodeModal from "./generate-code-modal";
import { useFlags } from "launchdarkly-react-client-sdk";

const LocationList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const generateCodeModal = useModal();
  const { locationTableManagement } = useFlags();
  const {
    request,
    loading = true,
    error,
    result = { data: [] },
    mappedData,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
  });

  const { modifyFilters, filterState } = useFilter({
    page: 1,
    pageSize: 20,
    venueId,
  });

  useMount(() => {
    request(filterState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.locationsList,
    });
  });

  const renderData = useMemo(() => {
    return mappedData.map(({ name, id, noOfTables }) => {
      return {
        name: <ButtonLink path={Path.LOCATION_ID(id)}>{name}</ButtonLink>,
        id: id,
        noOfTables: noOfTables ?? "-",
      };
    });
  }, [mappedData]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.locations}
        description={lang.managePlacesYouStock}
        button={{
          path: Path.LOCATION_CREATE,
          text: lang.createNewLocation,
        }}
        className="mb-md"
      />
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={request}
        total={result.metadata?.total}
        renderEmpty={{
          image:
            "https://pouch-inventory.s3.us-east-2.amazonaws.com/landing-image/no-locations.png",
          title: lang.createLocationToStoreYourProducts,
        }}
        loading={loading}
        columns={[
          {
            key: "name",
            text: lang.locations,
            custom: true,
          },
          locationTableManagement && {
            key: "noOfTables",
            text: lang.noOfTables,
          },
          locationTableManagement && {
            key: "action",
            actions: true,
            align: "right",
            actionOptions: [
              {
                text: lang.generateQRCode,
                onClick: (column) => {
                  generateCodeModal.show({
                    id: column.id,
                    noOfTables: column.noOfTables,
                  });
                },
              },
            ],
          },
        ]}
        data={renderData}
        error={error}
      />
      <GenerateCodeModal {...generateCodeModal} />
    </ModuleWrapper>
  );
};

export default LocationList;
