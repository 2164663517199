import React, { useCallback, useMemo } from "react";
import lang from "translations";
import { Field, Input, Form, Skeleton, Button, Select, Text } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { useForm, useModal } from "hooks";
import initialFormState from "./venue.form-state";
import classNames from "classnames";
import styles from "./venue-form.module.scss";
import { isNumberValid, mapObject } from "services";
import { countriesOptions, countryDialingCodeOptions } from "services/country.service";
import { mixpanel, TrackEvent } from "mixpanel";

const VenueForm = ({
  description,
  submit,
  submitting,
  initialState = undefined,
  loading,
  error,
}) => {
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const unsaveChangesModal = useModal();

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    dirty,
    isFormSubmittable,
    applyFieldErrors,
  } = useForm({
    initialState: formState,
  });

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        button: lang.saveVenueForm,
      });

      await submit(
        mapObject(
          { ...params },
          {
            venueName: {
              key: "name",
            },
            businessName: {
              key: "legalBusinessName",
            },
            businessAddress: {
              transform: ({ src }) => {
                return `${src.businessAddress1} ${src.businessAddress2}`.trim();
              },
            },
            businessContactNumber: {
              transform: ({ src }) => {
                return `${src.countryCode}${src.contactNumber}`;
              },
            },
            countryName: {
              key: "countryName",
            },
          }
        )
      );
    } catch ({ code, handleError }) {
      const err = {
        3028: () => {
          applyFieldErrors({ name: lang.alreadyExists });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }

    // localStorage.setItem("venueId", res.data.venueId);
  }, [submit, getFormValues, applyFieldErrors]);

  const submitFormValue = () => {
    submitForm(handleSubmit);
  };

  return (
    <ModuleWrapper error={error}>
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue}>
        <div className={classNames("text-left", styles.container)}>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Field {...fields.name}>
                <Input {...fields.name} onChange={modifyField} required={false} />
                {fields.name.value && <Text color={"text-gray"}>{lang.youCanChangeThisLater}</Text>}
              </Field>
              <Field {...fields.legalBusinessName}>
                <Input {...fields.legalBusinessName} onChange={modifyField} required={false} />
              </Field>
              <Field {...fields.businessAddress1}>
                <Input {...fields.businessAddress1} onChange={modifyField} required={false} />
                <Input
                  className="mt-sm"
                  {...fields.businessAddress2}
                  onChange={modifyField}
                  required={false}
                />
              </Field>
              <Field {...fields.countryName}>
                <Select
                  {...fields.countryName}
                  options={countriesOptions()}
                  onChange={modifyField}
                  searchable
                  customNotFoundContent={lang.noSearchFound}
                  placeholder={lang.selectCountry}
                />
              </Field>
              <Field {...fields.contactNumber}>
                <div className="grid grid-cols-4">
                  <div className="col-span-1">
                    <Select
                      {...fields.countryCode}
                      options={countryDialingCodeOptions()}
                      onChange={modifyField}
                      searchable
                    />
                  </div>
                  <div className="col-span-3 pl-sm">
                    <Input
                      {...fields.contactNumber}
                      onChange={(name, { value }) => {
                        let v = value;
                        if (isNumberValid(v)) {
                          modifyField(name, { value });
                        }
                      }}
                      required={false}
                    />
                  </div>
                </div>
              </Field>
            </>
          )}

          <Button
            className="mt-lg"
            onClick={() => {
              submitFormValue();
            }}
            loading={submitting}
            disabled={!dirty || submitting || !isFormSubmittable}
          >
            {lang.proceedToCheckout}
          </Button>
        </div>
      </Form>
    </ModuleWrapper>
  );
};

export default VenueForm;
