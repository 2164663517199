import { Field } from "enums";

const initialFormState = (initialState = {}) => {
  const { stocktakeId = null, stocktakeItems = [], status = "" } = initialState;
  return {
    stocktakeId: {
      name: "stocktakeId",
      type: Field.ANY,
      value: stocktakeId,
    },
    status: {
      name: "status",
      type: Field.ANY,
      value: status,
    },
    stocktakeItems: {
      name: "stocktakeItems",
      type: Field.ANY,
      value: stocktakeItems,
    },
  };
};

export const CreateSupplyItemField = ({
  itemName,
  productSkuId,
  productId,
  expectedStockQty,
  actualStockQty,
  measurementUnit,
  pricePerOrderQty,
  orderQty,
  sku,
}) => {
  return {
    ...SupplyItemField,
    value: {
      ...SupplyItemField.value,
      itemName: {
        ...SupplyItemField.value.itemName,
        ...itemName,
      },
      productSkuId: {
        ...SupplyItemField.value.productSkuId,
        ...productSkuId,
      },
      productId: {
        ...SupplyItemField.value.productId,
        ...productId,
      },
      expectedStockQty: {
        ...SupplyItemField.value.expectedStockQty,
        ...expectedStockQty,
      },
      actualStockQty: {
        ...SupplyItemField.value.actualStockQty,
        ...actualStockQty,
      },
      measurementUnit: {
        ...SupplyItemField.value.measurementUnit,
        ...measurementUnit,
      },
      pricePerOrderQty: {
        ...SupplyItemField.value.pricePerOrderQty,
        ...pricePerOrderQty,
      },
      orderQty: {
        ...SupplyItemField.value.orderQty,
        ...orderQty,
      },
      sku: {
        ...SupplyItemField.value.sku,
        ...sku,
      },
    },
  };
};

export const SupplyItemField = {
  type: Field.ANY,
  isFormGroup: true,
  value: {
    itemName: {
      name: "itemName",
      value: "",
    },
    productSkuId: {
      name: "productSkuId",
      value: null,
    },
    productId: {
      name: "productId",
      value: null,
    },
    expectedStockQty: {
      name: "expectedStockQty",
      value: 0,
    },
    actualStockQty: {
      name: "actualStockQty",
      value: null,
      dirty: false,
    },
    measurementUnit: {
      name: "measurementUnit",
      value: "",
    },
    pricePerOrderQty: {
      name: "pricePerOrderQty",
      value: 0,
    },
    orderQty: {
      name: "orderQty",
      value: 0,
    },
    sku: {
      name: "sku",
      value: "",
    },
  },
};

export default initialFormState;
