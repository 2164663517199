import { environment } from "environments/environment";
import { useCallback, useEffect, useState } from "react";
import * as Stomp from "stompjs";
import { notification } from "antd";
import downloaderState from "pages/venue/downloader.state";
import moment from "moment";
import reportStatus from "enums/report-status";
import { StatusIcon, Toast } from "components/commons";
import { downloadReport, deleteReport, searchReport, getStaff } from "apis";
import useMount from "./useMount";
import { useApi } from "hooks";
import { reportListResponse } from "mappers/report.mapper";
import { mixpanel, TrackEvent } from "mixpanel";
import lang from "translations";
import { StaffRole } from "enums";

const useDownloader = (venueId) => {
  const [downloader, setDownloader] = useState(downloaderState);
  const [client, setClient] = useState(Stomp.client(environment.ACTIVE_MQ_HOST));
  const [response, setResponse] = useState();
  const key = "downloaderNotification";

  const { request: getReportRequest } = useApi({
    api: searchReport,
    isArray: true,
    mapper: reportListResponse,
    handleOwnError: true,
  });

  const { request: getStaffRequest } = useApi({
    api: getStaff,
    pageError: true,
    handleOwnError: true,
  });

  const initialize = () => {
    client.debug = null;
    setClient({
      ...client,
      debug: null,
      reconnect_delay: 5000,
    });
    connect();
  };

  const connect = () => {
    try {
      if (client) {
        client.connect(
          environment.ACTIVE_MQ_USER,
          environment.ACTIVE_MQ_PW,
          onConnectionSuccessful,
          onConnectionError
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onConnectionSuccessful = () => {
    const topic = `/topic/reports/${venueId}`;
    subscribe(topic);
  };

  const onConnectionError = (err) => {
    console.error("ERROR CONNECTING TO STOMP", err);
  };

  const deinitialize = () => {
    client.unsubscribe();
  };

  const processItems = useCallback(
    async (items) => {
      var processing = 0;
      var success = 0;
      var failed = 0;

      const json = items
        .filter((item) => {
          return !downloader.excludedIds.includes(item.reportId);
        })
        .sort(function (a, b) {
          return b.reportId - a.reportId;
        })
        .map((item) => {
          const expired = moment(item.expiresAt).diff(moment()) < 0;
          var actions = [];

          if (item.reportStatus === reportStatus.done) {
            item.status = reportStatus.done;
            item.label = `${expired ? "Expired" : "Expires"} ${moment(item.expiresAt).fromNow()}`;
            if (!expired) {
              actions.push({
                label: lang.download,
                action: "download",
              });
            }
            actions.push({
              label: lang.clear,
              action: "clear",
            });

            success++;
          } else if (item.reportStatus === reportStatus.failed) {
            item.status = reportStatus.failed;
            item.label = lang.failed;
            failed++;
          } else {
            item.status = reportStatus.processing;
            item.label = lang.generatingReport;
            processing++;
          }
          item.title = item.reportName;
          item.actions = actions;
          return item;
        });
      var downloaderTitle = lang.downloading;
      if (json.length === success) {
        downloaderTitle = lang.allReportsReadyForDownload;
      } else if (json.length === processing) {
        downloaderTitle = "Preparing Report";
      } else {
        downloaderTitle = `${success} of ${json.length} ${
          json.length > 1 ? "reports" : "report"
        } ready for download`;
      }

      setDownloader({
        ...downloader,
        title: downloaderTitle,
        successCount: success,
        processingCount: processing,
        failedCount: failed,
      });

      setResponse(json);
    },
    [downloader]
  );

  const fetchReports = useCallback(
    async (requestState) => {
      try {
        const res = await getReportRequest(requestState);
        const reports = res.data;
        processItems(reports);
      } catch (e) {
        Toast({
          content: lang.reportsFailedToLoad,
          error: true,
          icon: "exclamation-fill",
        }).open();
      }
    },
    [getReportRequest, processItems]
  );

  const [isManager, setIsManager] = useState(false);

  const fetchStaffRole = useCallback(async () => {
    const s = await getStaffRequest({ venueId });
    const staffRole = s.roles.map((r) => {
      return r.role;
    });
    setIsManager(staffRole.includes(StaffRole.Manager));
  }, [getStaffRequest, venueId]);

  const subscribe = (topic) => {
    return client.subscribe(topic, (response) => {
      processItems(JSON.parse(response.body));
    });
  };

  const toggleExpanded = useCallback(() => {
    setDownloader({
      ...downloader,
      expanded: !downloader.expanded,
    });
  }, [setDownloader, downloader]);

  const getFileType = (fileType) => {
    if (fileType === ".xlsx" || fileType === ".xls") {
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else {
      return "application/octet-stream";
    }
  };

  const handleClickReport = useCallback(async (report) => {
    try {
      const res = await downloadReport(report.reportId);
      const fileType = getFileType(report.fileType);
      downloadFile(res, fileType, report.reportName, report.fileType);
      return {
        response: res,
      };
    } catch (e) {
      throw e;
    }
  }, []);

  const handleClearReport = useCallback(
    async (report) => {
      try {
        setDownloader({
          ...downloader,
          excludedIds: downloader.excludedIds.push(report.reportId),
        });
        const res = await deleteReport(report.reportId);
        return {
          response: res,
        };
      } catch (e) {
        throw e;
      }
    },
    [downloader]
  );

  const downloadFile = (data, type, name, format) => {
    const anchor = document.createElement("a");
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    anchor.download = name + format;
    anchor.href = url;
    anchor.click();
  };

  const createdDownloadedContent = useCallback(
    (response) => {
      return (
        <div>
          {response.map((item, i) => {
            return (
              <div className="flex download-item" key={i}>
                <div className="p-md">
                  {item.icon === ".zip" ? (
                    <i className="icon-zip process-icon text-pelorous text-3xl"></i>
                  ) : item.fileType === ".pdf" ? (
                    <i className="icon-pdf process-icon text-pelorous text-3xl"></i>
                  ) : item.fileType === ".zip" ? (
                    <i className="icon-zip process-icon text-pelorous text-3xl"></i>
                  ) : item.fileType === ".xlsx" ? (
                    <i className="icon-xlsx2 process-icon text-pelorous text-3xl"></i>
                  ) : (
                    <i className="icon-png process-icon text-pelorous text-3xl"></i>
                  )}
                </div>
                <div className="flex-1 pt-md pb-md">
                  <p className="download-item-title text-xs font-normal">{item.title}</p>
                  {item.reportStatus === reportStatus.done ? (
                    <div className="flex items-center">
                      <div className="flex-1 flex text-xs">
                        <div
                          className="text-pelorous-dark text-xs cursor-pointer"
                          onClick={() => {
                            mixpanel.track(TrackEvent.ClickedButton, {
                              button: lang.download,
                            });
                            handleClickReport(item);
                          }}
                        >
                          Download
                        </div>
                        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                        <div
                          className="text-pelorous-dark text-xs cursor-pointer"
                          onClick={() => {
                            mixpanel.track(TrackEvent.ClickedButton, {
                              button: lang.clearDownloads,
                            });
                            handleClearReport(item);
                          }}
                        >
                          {lang.clear}
                        </div>
                      </div>
                      <p className="download-item-label text-xxs pl-md text-gray-400">
                        {item.label}
                      </p>
                    </div>
                  ) : item.reportStatus === reportStatus.failed ? (
                    <div className="flex">
                      <p className="download-item-label text-xs text-gray-400">Export failed</p>
                    </div>
                  ) : (
                    <div className="flex">
                      <p className="download-item-label text-xs text-gray-400">{item.label}</p>
                    </div>
                  )}
                </div>
                <div className="p-md">
                  <StatusIcon
                    type={item.reportStatus}
                    onClear={() => {
                      handleClearReport(item);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [handleClickReport, handleClearReport]
  );

  const openDownloaderBox = useCallback(
    async (title, content) => {
      notification.open({
        bottom: 0,
        key: key,
        icon: null,
        className: `downloader ${downloader.expanded ? "open" : ""}`,
        message: (
          <div onClick={toggleExpanded} className="flex items-center">
            <p className="flex-1">{title}</p>
            <i className={`icon-caret-down text-sm`}></i>
          </div>
        ),
        description: content,
        placement: "bottomRight",
        duration: 0,
        closeIcon: <p></p>,
        btn: null,
      });
    },
    [downloader, toggleExpanded]
  );

  useEffect(() => {
    if (isManager) {
      if (response && response.length > 0) {
        openDownloaderBox(downloader.title, createdDownloadedContent(response));
      } else {
        notification.close(key);
      }
    }
  }, [response, downloader, isManager, openDownloaderBox, createdDownloadedContent]);

  useMount(() => {
    fetchStaffRole();
    fetchReports({
      page: 1,
      reportStatuses: ["PROCESSING", "UPLOADING", "DONE", "FAILED"],
      searchKey: "",
      types: [],
      venueId: venueId,
    });
  });

  return {
    initialize,
    response,
    downloader,
    setDownloader,
    openDownloaderBox,
    deinitialize,
  };
};

export default useDownloader;
