import React, { useContext, useMemo, useState, useCallback } from "react";
import { ProcessStatusPopover } from "components/popovers";
import { Icon } from "components/commons";
import { environment } from "environments/environment";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { useMount } from "hooks";
import { VenueContext } from "contexts";
import { SyncStatus } from "enums";

const ProcessStatus = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const [tooltipStatus, setTooltipStatus] = useState(null);

  const [failed, setFailed] = useState();
  const [processing, setProcessing] = useState();
  // const [current, setCurrent] = useState();
  // const [total, setTotal] = useState();
  const [timeLeft, setTimeLeft] = useState();

  useMount(() => {
    init();
  }, []);

  const init = useCallback(async () => {
    try {
      const db = firebase.database();
      await firebase.auth().signInAnonymously();
      const transactionRef = db.ref(`msTransaction/${environment.id}/${venueId}`);
      const connectedRef = db.ref(".info/connected");

      if (connectedRef) {
        connectedRef.on("value", (snap) => {
          if (snap.val() === true) {
            setTooltipStatus(null);
          } else {
            setTooltipStatus(SyncStatus.DISCONNECTED);
          }
        });
      }

      if (transactionRef) {
        transactionRef.on("value", (snapshot) => {
          if (snapshot.val()) {
            setFailed(snapshot.val().failed);
            setProcessing(snapshot.val().processing);
            setTimeLeft(snapshot.val().processing * 2);

            if (snapshot.val().failed > 0) {
              setTooltipStatus(SyncStatus.FAILED);
            } else if (snapshot.val().processing > 0) {
              setTooltipStatus(SyncStatus.PROCESSING);
            } else {
              if (tooltipStatus !== SyncStatus.DISCONNECTED) {
                setTooltipStatus(null);
              }
            }
          }
        });
      }
    } catch (err) {
      console.log("error");
      console.error(err);
    }
  }, [venueId, tooltipStatus]);

  const renderIcon = useMemo(() => {
    if (tooltipStatus === SyncStatus.FAILED) {
      return "sync-alert";
    } else if (tooltipStatus === SyncStatus.PROCESSING) {
      return "sync";
    } else if (tooltipStatus === SyncStatus.DISCONNECTED) {
      return "disconnected";
    } else {
      return null;
    }
  }, [tooltipStatus]);

  const classNames = useMemo(() => {
    if (tooltipStatus === SyncStatus.FAILED) {
      return "fill-current text-red";
    } else if (tooltipStatus === SyncStatus.PROCESSING) {
      return "fill-current text-gray-dark";
    } else if (tooltipStatus === SyncStatus.DISCONNECTED) {
      return "fill-current text-gray-dark";
    } else {
      return null;
    }
  }, [tooltipStatus]);

  return (
    <ProcessStatusPopover
      syncStatus={tooltipStatus}
      noOfFailed={failed}
      noOfProcessing={processing}
      // currentTotal={current}
      // totalnoOfProcessing={total}
      timeRemainingValue={timeLeft}
    >
      {renderIcon && <Icon name={renderIcon} className={classNames} />}
    </ProcessStatusPopover>
  );
};

export default ProcessStatus;
