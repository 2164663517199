import { mapObject } from "services";
import {
  formatNumberToMoney,
  formatNumberToMoneyCurrency,
  parseMoneyToNumber,
} from "services/money.service";
import { questionResponse } from "./question.mapper";

export const guestProfileResponse = {
  id: {
    key: "guestProfileId",
  },
  name: {
    key: "guestProfileName",
  },
  amount: {
    transform: ({ src }) => {
      return src.amount ? formatNumberToMoneyCurrency(src.amount) : "-";
    },
  },
  freeCredit: {
    transform: ({ src }) => {
      return src.freeCredit ? formatNumberToMoneyCurrency(src.freeCredit) : "-";
    },
  },
  postpaid: {
    transform: ({ src }) => {
      return src.postpaidCreditLimit ? formatNumberToMoneyCurrency(src.postpaidCreditLimit) : "-";
    },
  },
  preloaded: {
    transform: ({ src }) => {
      if (src.guestProfileVouchers) {
        return src.guestProfileVouchers.map((gp) => {
          return `${gp.quantity} x ${gp?.voucher?.voucherName || "-"}`;
        });
      }
      return [];
    },
  },
  vouchers: {
    transform: ({ src }) => {
      if (src.guestProfileVouchers) {
        return src.guestProfileVouchers.map(({ quantity, voucher }) => {
          return {
            value: voucher.voucherId,
            quantity,
            name: voucher.voucherName,
          };
        });
      }
      return [];
    },
  },
};

export const guestProfileIdResponse = {
  _keys: ["description", "guestProfileDefault"],
  id: {
    key: "guestProfileId",
  },
  name: {
    key: "guestProfileName",
  },
  vouchers: {
    transform: ({ src }) => {
      if (src.guestProfileVouchers) {
        return src.guestProfileVouchers.map(({ quantity, voucher }) => {
          return {
            value: voucher.voucherId,
            quantity,
            name: voucher.voucherName,
          };
        });
      }
      return [];
    },
  },
  questions: {
    transform: ({ src }) => {
      const obj = {};
      [
        "firstNameRequired",
        "lastNameRequired",
        "emailRequired",
        "mobileRequired",
        "birthdayRequired",
        "genderRequired",
        "customQuestions",
      ].forEach((key) => {
        if (src[key]) {
          obj[key] = true;
        }
      });
      return obj;
    },
  },
  isAllowedPostLimit: {
    transform: ({ src }) => {
      if (!src.postpaidAllowed) {
        return false;
      }
      return true;
    },
  },
  isAskQuestion: {
    transform: ({ src }) => {
      if (!src.questionnaireActive) {
        return false;
      }
      return true;
    },
  },
  customQuestionIncludes: {
    transform: ({ src }) => {
      const obj = {};
      if (src.guestProfileQuestions) {
        src.guestProfileQuestions.forEach(({ question }) => {
          if (question?.questionId) {
            obj[question.questionId] = question;
          }
        });
      }
      return obj;
    },
  },
  customQuestionsRequired: {
    transform: ({ src }) => {
      const obj = {};
      if (src.guestProfileQuestions) {
        src.guestProfileQuestions.forEach(({ question, required }) => {
          if (required) {
            obj[question.questionId] = question;
          }
        });
      }

      return obj;
    },
  },
  sortQuestions: {
    transform: ({ src }) => {
      if (src.guestProfileQuestions) {
        return src.guestProfileQuestions.map(({ question }) => {
          return mapObject(question, questionResponse);
        });
      }
      return [];
    },
  },
  limit: {
    transform: ({ src }) => {
      return formatNumberToMoney(src.postpaidCreditLimit);
    },
  },
  freeCredit: {
    transform: ({ src }) => {
      return formatNumberToMoney(src.freeCredit);
    },
  },
  amount: {
    transform: ({ src }) => {
      return formatNumberToMoney(src.amount);
    },
  },
};

export const guestProfileRequest = {
  _keys: [
    "description",
    "venueId",
    "firstNameRequired",
    "lastNameRequired",
    "emailRequired",
    "mobileRequired",
    "birthdayRequired",
    "genderRequired",
    "customQuestions",
    "topupCreditLimit",
    "topupCreditLimited",
  ],
  guestProfileName: {
    key: "name",
  },
  description: {
    key: "description",
  },
  postpaidAllowed: {
    key: "isAllowedPostLimit",
  },
  postpaidCreditLimit: {
    transform: ({ src }) => {
      return parseMoneyToNumber(src.limit).value;
    },
  },
  amount: {
    transform: ({ src }) => {
      return parseMoneyToNumber(src.amount).value;
    },
  },
  freeCredit: {
    transform: ({ src }) => {
      return parseMoneyToNumber(src.freeCredit).value;
    },
  },
  questionnaireActive: {
    key: "isAskQuestion",
  },
  vouchers: {
    transform: ({ self }) => {
      return self.map((voucher) => {
        return {
          voucherId: voucher.value,
          quantity: voucher.quantity,
        };
      });
    },
  },
};

export const editGuestProfileRequest = {
  ...guestProfileRequest,
  guestProfileId: {
    key: "guestProfileId",
  },
};

export const guestProfileFilterRequest = {
  includeAppliedGuestProfile: true,
  orders: { "gp.createdDate": "desc", "gp.guestProfileDefault": "desc" },
  venueId: { key: "venueId" },
  includeDeleted: { key: "includeDeleted" },
};

export const guestProfileFilterResponse = {
  id: { key: "guestProfileId" },
  name: {
    transform: ({ src }) => {
      return src.guestProfileName + (src.deleted ? " (deleted)" : "");
    },
  },
};
