import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Path } from "paths";
import {
  InventoryReport,
  CategoryReport,
  DiscountReport,
  VoucherReport,
  SalesReport,
} from "modules";

const ReportPage = () => {
  return (
    <Switch>
      <Route path={Path.REPORT_INVENTORY} component={InventoryReport} />
      <Route path={Path.REPORT_CATEGORY} component={CategoryReport} />
      <Route path={Path.REPORT_DISCOUNT} component={DiscountReport} />
      <Route path={Path.REPORT_VOUCHER} component={VoucherReport} />
      <Route path={Path.REPORT_SALES} component={SalesReport} />
      <Redirect to={Path.REPORT_INVENTORY} />
    </Switch>
  );
};

export default ReportPage;
