import { authPaths } from "./auth.path";
import { venuePaths } from "./venue.path";
import { productPaths } from "./product.path";
import { guestPaths } from "./guest.path";
import { staffPaths } from "./staff.path";
import { locationPaths } from "./location.path";
import { discountPaths } from "./discount.path";
import { voucherPaths } from "./voucher.path";
import { transactionPaths } from "./transaction.path";
import { devicePaths } from "./device.path";
import { reportPaths } from "./report.path";
import { settingPaths } from "./setting.path";
import { bookingPaths } from "./booking.path";
import { inventoryPaths } from "./inventory.path";

const preparePaths = ({ prefix, paths = {} }) => {
  let newPaths = {};

  for (const [k, path] of Object.entries(paths)) {
    if (typeof path === "function") {
      newPaths[k] = (id) => `/${prefix}/${path(id)}`.replace(/\/+/g, "/");
    } else if (path) {
      newPaths[k] = `/${prefix}/${path}`.replace(/\/+/g, "/");
    } else {
      newPaths[k] = `/${prefix}`.replace(/\/+/g, "/");
    }
  }

  newPaths[prefix.toString().toUpperCase()] = `/${prefix}`;
  return newPaths;
};

const Path = {
  ...preparePaths({ prefix: "auth", paths: authPaths }),
  ...preparePaths({ prefix: "venue", paths: venuePaths }),
  ...preparePaths({ prefix: "dashboard" }),
  ...preparePaths({ prefix: "product", paths: productPaths }),
  ...preparePaths({ prefix: "inventory", paths: inventoryPaths }),
  ...preparePaths({ prefix: "staff", paths: staffPaths }),
  ...preparePaths({ prefix: "location", paths: locationPaths }),
  ...preparePaths({ prefix: "booking", paths: bookingPaths }),
  ...preparePaths({ prefix: "guest", paths: guestPaths }),
  ...preparePaths({ prefix: "voucher", paths: voucherPaths }),
  ...preparePaths({ prefix: "discount", paths: discountPaths }),
  ...preparePaths({ prefix: "transaction", paths: transactionPaths }),
  ...preparePaths({ prefix: "report", paths: reportPaths }),
  ...preparePaths({ prefix: "device", paths: devicePaths }),
  ...preparePaths({ prefix: "setting", paths: settingPaths }),
  ...preparePaths({
    prefix: "register",
    paths: {
      ACTIVATE_STAFF: "activate-staff",
    },
  }),
  KITCHEN_SINK: "/kitchen-sink",
  SLASH: "/",
};

export default Path;
