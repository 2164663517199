import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useModal, useRouter, useMount, useApi } from "hooks";
import { Skeleton, Panel, Button, SimpleTable, Icon, Text, ButtonLink } from "components/commons";
import { VenueContext } from "contexts";
import { StyleType } from "enums";
import { questionRequest, questionResponse } from "mappers";
import QuestionFormModal from "./question-form/question-form.modal";
import { getQuestions, createQuestion, editQuestion } from "apis/question.api";
import { mixpanel, TrackEvent } from "mixpanel";
// import classnames from "classnames";

const QuestionSetting = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const questionFormModal = useModal();

  const { request, loading, mappedData } = useApi({
    api: getQuestions,
    params: {
      venueId,
    },
    mapper: questionResponse,
    isArray: true,
  });

  useMount(() => {
    request();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.questionSettings,
    });
  });

  const { request: createRequestApi, loading: createLoading } = useApi({
    api: createQuestion,
    params: {
      venueId,
    },
    paramsMapper: questionRequest,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: editRequestApi, loading: editLoading } = useApi({
    api: editQuestion,
    params: {
      venueId,
    },
    paramsMapper: questionRequest,
    handleOwnError: {
      badrequest: true,
    },
  });

  const onSave = useCallback(
    async ({ api, message }, fields, success, error) => {
      try {
        const result = await api(fields);
        request();
        return success(result, { message });
      } catch (e) {
        return error(e);
      }
    },
    [request]
  );

  const onCreateSave = useCallback(
    (fields, success, error) => {
      return onSave(
        {
          api: createRequestApi,
          message: lang.populate(lang.questionAddedName, [fields.name]),
        },
        fields,
        success,
        error
      );
    },
    [createRequestApi, onSave]
  );

  const onEditSave = useCallback(
    (fields, success, error) => {
      return onSave({ api: editRequestApi, message: lang.changesSaved }, fields, success, error);
    },
    [editRequestApi, onSave]
  );

  const submitting = createLoading || editLoading;

  const data = useMemo(() => {
    if (mappedData) {
      return mappedData.map((d) => {
        return {
          ...d,
          _highlightRemove: d.isPrecreated,
          nameAndIcon: (
            <div className="">
              <Text
                classes={{
                  "text-gray-light": d.isPrecreated,
                }}
              >
                {d.name}
              </Text>
              {d.numberOfOptions && (
                <Text size="text-sm" color="text-gray-light">
                  {lang.populate(lang.nOptions, [d.numberOfOptions])}
                </Text>
              )}
            </div>
          ),
          edit: !d.isPrecreated ? (
            <div className="display-hover">
              <Icon
                name="pencil"
                className="text-pelorous cursor-pointer"
                onClick={() => {
                  questionFormModal.show({
                    title: lang.customQuestion,
                    primaryText: lang.save,
                    isEdit: true,
                    save: onEditSave,
                    id: d.id,
                    guestProfiles: d.guestProfileQuestions,
                    initialState: {
                      name: d.name,
                      id: d.id,
                      type: d.type,
                      options: d.options,
                    },
                  });
                }}
              />
            </div>
          ) : null,
        };
      });
    }
    return [];
  }, [mappedData, questionFormModal, onEditSave]);

  return (
    <ModuleWrapper>
      <HeaderB
        returnText={lang.settings}
        title={lang.checkInQuestions}
        returnPath={Path.SETTING}
        onClick={() => history.push(Path.SETTING)}
      />
      {loading ? (
        <FragmentA title={""}>
          <Skeleton />
        </FragmentA>
      ) : (
        <>
          <FragmentA
            title={""}
            description={
              <lang.Translate
                text={lang.questionDescription}
                items={[
                  <ButtonLink size="text-sm" newTabPath={Path.GUEST_PROFILE} anchor>
                    {lang.guestProfile}
                  </ButtonLink>,
                ]}
              />
            }
          >
            <Panel>
              <Text className="mb-lg" label strong uppercase>
                {lang.defaultQuestions}
              </Text>
              <SimpleTable
                className="-mx-6"
                showHeader={false}
                columns={[
                  {
                    key: "title",
                    className: "py-md px-6",
                  },
                ]}
                data={[
                  lang.firstName,
                  lang.lastName,
                  lang.emailAddress,
                  lang.mobileNumber,
                  lang.birthday,
                  lang.gender,
                ].map((title) => {
                  return {
                    title: <Text color="text-gray-light">{title}</Text>,
                  };
                })}
              />
              <Text className="mb-lg mt-lg" label strong uppercase>
                {lang.customQuestions}
              </Text>
              <SimpleTable
                className="-mx-6"
                showHeader={false}
                columns={[
                  {
                    key: "nameAndIcon",
                    className: "py-lg px-6",
                  },
                  {
                    key: "edit",
                    className: "text-right p-lg",
                  },
                ]}
                data={data}
              />
              <Button
                className="mt-md"
                type={StyleType.Secondary}
                onClick={() => {
                  questionFormModal.show({
                    title: lang.customQuestion,
                    primaryText: lang.save,
                    initialState: undefined,
                    isEdit: false,
                    save: onCreateSave,
                  });
                }}
              >
                {lang.addCustomQuestion}
              </Button>
            </Panel>
          </FragmentA>
        </>
      )}
      <QuestionFormModal {...questionFormModal} submitting={submitting} refreshList={request} />
    </ModuleWrapper>
  );
};

export default QuestionSetting;
