import GuestProfileForm from "../guest-profile-form/guest-profile-form";
import { useApi, useModal, useMount, useRouter } from "hooks";
import React, { useCallback, useMemo } from "react";
import lang from "translations";
import { editGuestProfile, getGuestProfile, deleteGuestProfile } from "apis/guest-profile.api";
import { editGuestProfileRequest, guestProfileIdResponse } from "mappers";
import { DeleteModal } from "components/modals";
import { Text, Toast } from "components/commons";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";

const EditGuestProfile = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const deleteModal = useModal();

  const {
    request,
    loading = true,
    error,
    mappedData,
  } = useApi({
    api: getGuestProfile,
    mapper: guestProfileIdResponse,
    params: {
      guestProfileId: id,
    },
    handleOwnError: true,
  });

  const { name, guestProfileDefault } = mappedData || {};

  const { request: submit, loading: submitting } = useApi({
    api: editGuestProfile,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: editGuestProfileRequest,
    params: {
      guestProfileId: id,
    },
  });

  const { request: deleteRequest, loading: deleting } = useApi({
    api: deleteGuestProfile,
    params: {
      guestProfileId: id,
    },
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await submit(params);
        return {
          response: res,
          message: lang.changesSaved,
        };
      } catch (e) {
        throw e;
      }
    },
    [submit]
  );

  useMount(() => {
    request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editGuestProfile,
    });
  });

  const initialState = useMemo(() => {
    const {
      name,
      description,
      questions,
      vouchers,
      isAskQuestion,
      customQuestionIncludes,
      customQuestionsRequired,
      sortQuestions,
      isAllowedPostLimit,
      limit,
      freeCredit,
      amount,
    } = mappedData || {};
    return {
      name,
      description,
      questions,
      vouchers,
      isAskQuestion,
      customQuestionIncludes,
      customQuestionsRequired,
      sortQuestions,
      isAllowedPostLimit,
      limit,
      freeCredit,
      amount,
    };
  }, [mappedData]);

  return (
    <>
      <GuestProfileForm
        error={error}
        loading={loading}
        title={name}
        submit={submitForm}
        submitting={submitting || deleting}
        initialState={initialState}
        deleteButton={
          guestProfileDefault
            ? null
            : {
                onClick: () => {
                  deleteModal.show();
                },
                text: lang.deleteGuestProfile,
              }
        }
      />
      <DeleteModal
        {...deleteModal}
        deleting={deleting}
        primaryText={lang.deleteGuestProfile}
        ok={async () => {
          await deleteRequest();
          Toast({
            content: lang.guestProfileDeleted,
            success: true,
            icon: "check",
          }).open();
          history.push(Path.GUEST_PROFILE);
        }}
        customContent={
          <div>
            <Text>{lang.youAreAboutToDeleteGuestProfile}</Text>
          </div>
        }
        customHeader={
          <Text modalTitle className="py-md">
            {lang.askDeleteGuestProfile}
          </Text>
        }
      />
    </>
  );
};

export default EditGuestProfile;
