import {
  AlertMessage,
  Button,
  DataTable,
  Filter,
  MultipleSelect,
  Text,
  Field,
  Icon,
  Title,
} from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useState, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useRouter, useMount, useSelectItems, useApi, useFilter } from "hooks";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";
import columns from "./columns";
import { StyleType, AccountType } from "enums";
import { getAccountCodes, getMewsIntegrationStatus } from "apis";
import { getAccountingType, prettifyAccountingType, pluralize } from "services";
import { accountingResponse } from "mappers/accounting.mapper";

const accountTypeFilter = [
  { text: lang.asset, value: AccountType.Asset },
  { text: lang.liability, value: AccountType.Liability },
  { text: lang.equity, value: AccountType.Equity },
  { text: lang.revenue, value: AccountType.Revenue },
  { text: lang.expense, value: AccountType.Expense },
  { text: lang.unknown, value: AccountType.Unknown },
];

const ChartOfAccounts = () => {
  const { error, venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;

  const {
    request: fetchIntegrationStatus,
    mappedData: integrationStatus,
    loading: loadingIntegrationStatus,
  } = useApi({
    api: getMewsIntegrationStatus,
    params: { venueId },
    isArray: true,
    mapper: {
      id: { key: "id" },
      integrationType: { key: "integrationType" },
    },
  });

  const {
    request: getAccountCodesRequest,
    mappedData,
    loading,
    result: getAccountCodesResult = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venueId,
      pageable: true,
    },
    isArray: true,
    handleOwnerror: true,
    mapper: accountingResponse,
  });

  const {
    modifyFilter,
    clearFilter,
    filterState,
    modifyFilters,
    isFilterDirty,
    requestState: filterRequestState,
  } = useFilter({
    venueId,
    pageable: true,
    page: 1,
    pageSize: 20,
    searchKey: "",
    accountTypeList: [],
  });

  const hasFilter = useMemo(() => {
    return filterRequestState.searchKey || filterRequestState?.accountTypeList?.length;
  }, [filterRequestState]);

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(async () => {
    const res = await fetchIntegrationStatus();
    if (res) {
      fetchAccountCodes();
    }

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.chartOfAccounts,
    });
  });

  const hasAccountingIntegration = useMemo(() => {
    if (integrationStatus && integrationStatus.length > 0) {
      return integrationStatus[0]?.integrationType.indexOf("MEWS_ACCOUNTING") > -1;
    }
    return false;
  }, [integrationStatus]);

  const goToList = useCallback(() => {
    history.push(Path.SETTING);
  }, [history]);

  const leavePage = useCallback(() => {
    goToList();
  }, [goToList]);

  const ChartOfAccountsEmpty = ({ noIntegration }) => {
    return (
      <div>
        <Title>{lang.noAccountsYet}</Title>
        <Text className="my-md">
          {noIntegration ? lang.pleaseConnectToAnAccounting : lang.pleaseCreateAccounts}
        </Text>
        <Button
          type={StyleType.Secondary}
          onClick={() => {
            history.push(Path.INTEGRATION_SETTING);
          }}
        >
          {lang.viewIntegrations}
        </Button>
      </div>
    );
  };

  const ChartOfAccountsAlert = () => {
    return (
      <AlertMessage
        icon="info"
        showIcon
        className="mt-lg mb-sm"
        customMessage={
          <Text>
            <lang.Translate
              text={lang.mapAndManageAccountsLink}
              flex={false}
              items={[
                <span
                  className="cursor-pointer text-pelorous font-bold"
                  onClick={() => history.push(Path.ACCOUNT_MAPPING)}
                >
                  {lang.accountMapping}
                </span>,
              ]}
            />
          </Text>
        }
      />
    );
  };

  const prepareChartOfAccountsList = useCallback(
    (mappedData) => {
      const { page, pageSize } = filterState;

      const mp = [...mappedData];
      const result = hasFilter ? mp.slice((page - 1) * pageSize, page * pageSize) : mp;

      return result.map((account) => {
        const { accountName, code, description, currency, taxRate, accountCodeId } = account;
        return {
          accountName: accountName,
          accountId: code,
          code: code ? (
            <Text fontWeight="font-semibold">{code}</Text>
          ) : (
            <div className="flex items-center">
              <Text fontWeight="font-semibold" color="text-red">
                {lang.setCode}
              </Text>
              <Icon name="info" color="text-red" />
            </div>
          ),
          name: (
            <div>
              <Text className={`${!code && "text-gray-light"}`}>
                {accountName ? accountName : "-"}
              </Text>
              <Text label>{description}</Text>
            </div>
          ),
          accountType: (
            <Text className={`${!code && "text-gray-light"}`}>
              {prettifyAccountingType(getAccountingType(code))}
            </Text>
          ),
          currency: (
            <Text className={`${!code && "text-gray-light"}`}>{!currency ? "-" : "+"}</Text>
          ),
          taxRate: (
            <Text className={`${!code && "text-gray-light"}`}>{!taxRate ? "-" : taxRate}</Text>
          ),
          id: accountCodeId,
        };
      });
    },
    [filterState, hasFilter]
  );

  const filterAccounts = useMemo(() => {
    const res = mappedData.filter(({ code = "", accountName = "" }) => {
      let show = true;
      const searchKey = filterState.searchKey.toLowerCase().trim();
      if (searchKey) {
        show = `${code?.toLowerCase()} + ${accountName?.toLowerCase()}`.includes(searchKey);
      }
      if (filterState?.accountTypeList.length && show) {
        show = filterState?.accountTypeList?.some((accountType) => {
          const at = getAccountingType(code).toLowerCase() || "unknown";
          const accountCodeValue = accountType.value.toLowerCase();
          return at.includes(accountCodeValue);
        });
      }
      return show;
    });
    return res;
    //eslint-disable-next-line
  }, [mappedData]);

  const chartOfAccounts = useMemo(() => {
    return prepareChartOfAccountsList(filterAccounts);
  }, [filterAccounts, prepareChartOfAccountsList]);

  const { selected, setSelected, isAllSelected, setSelectAll, clearSelected } = useSelectItems({
    items: chartOfAccounts,
    indeterminate: true,
  });

  const fetchAccountCodes = useCallback(
    (rs) => {
      const param = {
        ...filterRequestState,
        ...rs,
      };
      getAccountCodesRequest({
        ...param,
        pageable: !param.searchKey,
        size: param.searchKey || param.accountTypeList.length ? null : param.pageSize,
        accountTypeList: param.accountTypeList.map((v) => v.value).join(","),
      });
    },
    [filterRequestState, getAccountCodesRequest]
  );

  const clearFilterCb = useCallback(() => {
    clearSelected();
    clearFilter();
    setHasAppliedFilter(false);
    fetchAccountCodes({ page: 1, searchKey: "" });
  }, [fetchAccountCodes, setHasAppliedFilter, clearFilter, clearSelected]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      // if (filterState?.accountTypeList?.length === undefined) return;
      const { requestState } = await modifyFilters({
        page: 1,
        searchKey,
      });
      fetchAccountCodes({ ...requestState, searchKey });
      clearSelected();
      setHasAppliedFilter(Boolean(searchKey.trim()) || filterState?.accountTypeList?.length !== 0);
    },
    [fetchAccountCodes, modifyFilters, clearSelected, filterState]
  );

  const accountListResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${filterAccounts.length} ${pluralize(
        filterAccounts.length,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
    //eslint-disable-next-line
  }, [mappedData, filterAccounts]);

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.chartOfAccounts}
          description={lang.categorizeAndManage}
          returnPath={Path.SETTING}
          onClick={() => {
            leavePage();
          }}
        />
      }
    >
      <ChartOfAccountsAlert />
      <Filter
        filterState={filterState}
        onApply={applyFilterCb}
        onClear={clearFilterCb}
        placeholder={lang.search2}
        className="my-md"
      >
        <Field filterLabel={lang.accountType} className="col-span-4">
          <MultipleSelect
            name="accountTypeList"
            selectAllText={lang.allAccountTypes}
            emptyPlaceHolder={lang.accountType}
            placeholder={lang.selectAccountType}
            defaultAll
            options={accountTypeFilter}
            isAll={filterState.accountTypeList && filterState.accountTypeList.length === 0}
            value={filterState.accountTypeList}
            onChange={(name, obj) => {
              modifyFilter(name, { value: obj.value });
            }}
          />
        </Field>
        <span className="col-span-4" />
      </Filter>
      <DataTable
        resultContent={accountListResultContent}
        total={hasFilter ? filterAccounts.length : getAccountCodesResult?.metadata?.count || 0}
        data={chartOfAccounts}
        loading={loading || loadingIntegrationStatus}
        minWidth="800px"
        selected={selected}
        setSelected={setSelected}
        isAllSelected={isAllSelected}
        setSelectAll={setSelectAll}
        pageSize={filterState.pageSize}
        page={filterState.page}
        fetchList={(f, r) => {
          if (!hasFilter || (hasFilter && f.page === 1)) {
            fetchAccountCodes(f, r);
          }
        }}
        onChangePage={modifyFilters}
        hasAppliedFilter={hasAppliedFilter}
        renderEmpty={{
          custom: <ChartOfAccountsEmpty noIntegration={!hasAccountingIntegration} />,
        }}
        columns={[...columns]}
      />
    </ModuleWrapper>
  );
};

export default ChartOfAccounts;
