import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { CategoryList, CreateCategory, EditCategory, EditProduct } from "modules";
import ProductList from "modules/product/product-list/product-list.module";
import CreateProductForm from "modules/product/create-product/create-product.module";
import CreateProductSet from "modules/product/create-product-set/create-product-set.module";
import EditProductSet from "modules/product/edit-product-set/edit-product-set.module";

const ProductPage = () => {
  return (
    <Switch>
      <Route exact path={Path.EDIT_PRODUCT} component={EditProduct} />
      <Route exact path={Path.CREATE_PRODUCT} component={CreateProductForm} />
      <Route exact path={Path.CREATE_PRODUCT_SET} component={CreateProductSet} />
      <Route exact path={Path.EDIT_PRODUCT_SET} component={EditProductSet} />
      <Route exact path={Path.PRODUCT} component={ProductList} />
      <Route exact path={Path.CATEGORY} component={CategoryList} />
      <Route exact path={Path.CATEGORY_CREATE} component={CreateCategory} />
      <Route exact path={Path.CATEGORY_EDIT} component={EditCategory} />
    </Switch>
  );
};

export default ProductPage;
