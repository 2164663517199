import React, { useContext } from "react";
import { Input, Text } from "..";
import { VenueContext } from "contexts";
import { formatNumberToMoney, parseMoneyToNumber } from "services/money.service";

const InputMoney = ({ onChange, value, limit, noDecimal, right = true, ...props }) => {
  const { venue } = useContext(VenueContext);
  const { currencySymbol } = venue;
  return (
    <div>
      <Input
        value={value}
        {...props}
        right={right ? right : false}
        onChange={(name, { value }) => {
          if (noDecimal && value.includes(".")) {
            return false;
          }
          if (Number(value) || value === "0" || !value) {
            onChange(name, { value });
          }
        }}
        iconPrefix={
          <Text color="text-gray" size="text-sm" className="pr-4">
            {currencySymbol}
          </Text>
        }
        onBlur={() => {
          let v = value;
          const number = parseMoneyToNumber(value).value;
          if (noDecimal) {
            v = parseInt(value);
          }
          if (limit && Number(number) > Number(limit)) {
            v = limit;
          }
          const money = formatNumberToMoney(v);
          onChange(props.name, {
            value: money,
          });
        }}
        onFocus={() => {
          const number = parseMoneyToNumber(value);
          onChange(props.name, {
            value: Number(number.value) || "",
          });
        }}
      />
    </div>
  );
};

export default InputMoney;
