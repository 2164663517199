import { Modal, Title } from "components/commons";
import { useApi } from "hooks";
import { React, useMemo, useCallback } from "react";
import lang from "translations";
import { createSupplyItem } from "apis";
import AddNewSupplyItemForm from "./add-new-supply-item-form";
import { supplyItemRequest } from "mappers/product.mapper";
import { getSupplyItem } from "apis";

const AddNewSupplyItemModal = ({ active, close, onSubmit, itemName }) => {
  const { request, loading: submitting } = useApi({
    api: createSupplyItem,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: { ...supplyItemRequest },
  });

  const { request: getSupplyItemRequest } = useApi({
    api: getSupplyItem,
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        const id = res.data.productId;
        const data = await getSupplyItemRequest({ id });
        var newSupply = data.data;
        onSubmit(newSupply);
        return {
          response: res,
          message: lang.populate(lang.supplyItemNameCreated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [request, onSubmit, getSupplyItemRequest]
  );

  const initialState = useMemo(() => {
    return { name: itemName };
  }, [itemName]);

  const header = useMemo(() => {
    return (
      <div>
        <Title>{lang.addNewSupplyItem}</Title>
      </div>
    );
  }, []);

  return (
    <Modal active={active} close={close} title={header}>
      <AddNewSupplyItemForm
        submit={submitForm}
        submitting={submitting}
        close={close}
        itemName={itemName}
        initialState={initialState}
      />
    </Modal>
  );
};

export default AddNewSupplyItemModal;
