import React from "react";
import {
  TotalSalesChart,
  TopLocationsChart,
  TotalTopUpsChart,
  TotalReturnCreditsChart,
  CheckInsChart,
  TopProductsChart,
  TopSpendingGuestsChart,
  // LowOrOutOfStockChart,
  TopCategoriesBySalesChart,
  TotalSalesOfLocationChart,
} from "./chart";
import ChartContainer from "./chart-container";
import lang from "translations";
import classnames from "classnames";
import { Title } from "components/commons";
import { formatNumberWithComma } from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

const DashboardCharts = ({
  graphOfTotalSales,
  graphOfTotalSalesOfLocation,
  topLocationsBySales,
  graphOfTopUpSales,
  graphOfReturnCredits,
  graphOfActivatedGuest,
  topProductsBySales,
  topSpendingGuest,
  outOfStockProducts,
  topCategoriesBySales,
  averageOrderValue,
  totalTransactionsValue,
  hasLocations,
}) => {
  return (
    <div className="grid grid-cols-12 gap-4">
      {hasLocations ? (
        <div className={classnames("md:col-span-12 col-span-12")}>
          <TotalSalesOfLocationChart {...graphOfTotalSalesOfLocation} />
        </div>
      ) : (
        <div className={classnames("lg:col-span-8 md:col-span-8 col-span-12")}>
          <TotalSalesChart {...graphOfTotalSales} />
        </div>
      )}

      {!hasLocations && (
        <div className="sm:col-span-12 col-span-12 lg:col-span-4 md:col-span-4 col-span-8">
          <TopLocationsChart {...topLocationsBySales} />
        </div>
      )}
      <div className="md:col-span-4 col-span-12">
        <TotalTopUpsChart {...graphOfTopUpSales} />
      </div>
      <div className="md:col-span-4 col-span-12">
        <TotalReturnCreditsChart {...graphOfReturnCredits} />
      </div>
      <div className="md:col-span-4 col-span-12">
        <CheckInsChart {...graphOfActivatedGuest} />
      </div>
      <div className="md:col-span-6 col-span-12">
        <ChartContainer title={lang.averageOrderValue} {...averageOrderValue} h="auto">
          <Title xl className="mt-sm" fontWeight="font-normal">
            {formatNumberToMoneyWithCurrencySymbol(averageOrderValue.result?.data || 0)}
          </Title>
        </ChartContainer>
      </div>
      <div className="md:col-span-6 col-span-12">
        <ChartContainer title={lang.totalTransactions} {...totalTransactionsValue} h="auto">
          <Title xl className="mt-sm" fontWeight="font-normal">
            {formatNumberWithComma(totalTransactionsValue.result?.data || 0)}
          </Title>
        </ChartContainer>
      </div>
      <div className="md:col-span-6 col-span-12">
        <TopSpendingGuestsChart {...topSpendingGuest} />
      </div>
      {/* <div className="md:col-span-6 col-span-12">
        <LowOrOutOfStockChart {...outOfStockProducts} />
      </div> */}
      <div className="md:col-span-6 col-span-12">
        <TopCategoriesBySalesChart {...topCategoriesBySales} />
      </div>

      <div className="md:col-span-6 col-span-12">
        <TopProductsChart {...topProductsBySales} />
      </div>
    </div>
  );
};

export default DashboardCharts;
