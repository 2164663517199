import { StaffRole } from "enums";
import { Path } from "paths";

export const roleAccess = {
  [StaffRole.StockManager]: [
    Path.INVENTORY,
    Path.SETTING,
    Path.INVENTORY_STOCKTAKE,
    Path.STOCKTAKE_PAGE,
    Path.ACCOUNT_SETTING,
  ],
};
