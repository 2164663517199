import lang from "translations";
export const columns = [
  {
    key: "rooms",
    text: lang.rooms,
    align: "left",
    width: "w-1/2",
  },
  {
    key: "capacity",
    text: lang.capacity,
    align: "right",
  },
  {
    key: "assignedSpace",
    text: lang.assignedSpace,
    align: "right",
  },
];
