import { Get, Post } from "services";
import { ApiPath } from "paths";

export const searchDevice = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_DEVICE}`, body);
  return res;
};

export const searchDeviceWithTransaction = async ({ venueId, ...body }) => {
  const res = await Post(`${ApiPath.DEVICE_WITH_TRANSACTION(venueId)}`, body);
  return res;
};

export const getDevice = async ({ deviceId }) => {
  const res = await Get(`${ApiPath.DEVICE_ID(deviceId)}`);
  return res.data;
};

export const editDevice = async ({ deviceId, deviceName }) => {
  const res = await Post(`${ApiPath.DEVICE_ID(deviceId)}`, { deviceName });
  return res;
};
