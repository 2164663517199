import React, { useContext, useMemo, useState } from "react";
import { HeaderA } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import {
  useApi,
  useMount,
  // useRouter,
  useFilter,
} from "hooks";
import { ButtonLink, DataTable, Text } from "components/commons";
import { staffProfileResponse } from "mappers";
import { searchStaffProfile } from "apis/staff-profile.api";
import { mixpanel, TrackEvent } from "mixpanel";

const StaffProfileList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  // const { history } = useRouter();
  const {
    request,
    loading = true,
    error,
    result = { data: [] },
    mappedData,
  } = useApi({
    api: searchStaffProfile,
    isArray: true,
    mapper: staffProfileResponse,
  });

  const { modifyFilters, filterState } = useFilter({
    searchKey: "",
    page: 1,
    pageSize: 20,
    venueId,
    orders: {
      "sp.staffProfileId": "asc",
    },
  });

  const [expandedVoucherList, setExpandedVoucherList] = useState([]);

  useMount(() => {
    request(filterState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.staffProfileList,
    });
  });

  const renderData = useMemo(() => {
    return mappedData.map(
      ({ name, id, freeCredit, staffProfileVouchers, showMore }, mappedDataIndex) => {
        return {
          name: (
            <ButtonLink
              // onClick={() => history.push(Path.STAFF_PROFILE_EDIT(id))}
              path={Path.STAFF_PROFILE_EDIT(id)}
            >
              {name}
            </ButtonLink>
          ),
          freeCredit,
          staffProfileVouchers: staffProfileVouchers ? (
            <div>
              {staffProfileVouchers.map((voucher, index) => {
                if (
                  !showMore ||
                  expandedVoucherList.includes(mappedDataIndex) ||
                  (showMore && index < 2)
                ) {
                  return (
                    <div>
                      <Text>{voucher}</Text>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {showMore ? (
                <div>
                  <ButtonLink
                    onClick={() => {
                      var list = expandedVoucherList ?? [];
                      if (expandedVoucherList.includes(mappedDataIndex)) {
                        for (var i = 0; i < expandedVoucherList.length; i++) {
                          if (expandedVoucherList[i] === mappedDataIndex) {
                            expandedVoucherList.splice(i, 1);
                          }
                        }
                      } else {
                        list.push(mappedDataIndex);
                      }
                      setExpandedVoucherList([...list]);
                    }}
                  >
                    {expandedVoucherList.includes(mappedDataIndex) ? lang.showLess : lang.showMore}
                  </ButtonLink>
                </div>
              ) : null}
            </div>
          ) : null,
        };
      }
    );
  }, [mappedData, expandedVoucherList]);

  return (
    <div>
      <HeaderA
        title={lang.staffProfiles}
        description={lang.manageYourStaffProfileDescription}
        button={{
          path: Path.STAFF_PROFILE_CREATE,
          text: lang.createNewProfile,
        }}
      />
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={request}
        total={result.metadata?.total}
        loading={loading}
        columns={[
          {
            key: "name",
            text: lang.staffProfileName,
            custom: true,
          },
          {
            key: "freeCredit",
            text: lang.freeCredits,
            align: "right",
          },
          {
            key: "staffProfileVouchers",
            text: lang.preloadedVouchers,
            custom: true,
          },
        ]}
        data={renderData}
        error={error}
      />
    </div>
  );
};

export default StaffProfileList;
