import enumeration from "./enumeration";

const ReceiptName = {
  VenueName: "VENUE_NAME",
  BusinessName: "BUSINESS_NAME",
  BusinessAddress: "BUSINESS_ADDRESS",
  BusinessContactNumber: "BUSINESS_CONTACT_NUMBER",
  TaxNumber: "TAX_NUMBER",
  DeviceId: "DEVICE_ID",
  SerialNumber: "SERIAL_NUMBER",
  ReceiptNumber: "RECEIPT_NUMBER",
  DateAndTimeOfTransaction: "DATE_AND_TIME_OF_TRANSACTION",
  VenueLocation: "VENUE_LOCATION",
  StaffNameAndOrTagId: "STAFF_NAME_AND_OR_TAG_ID",
  PosProvider: "POS_PROVIDER",
  GuestNameAndOrTagId: "GUEST_NAME_AND_OR_TAG_ID",
  TransactionId: "TRANSACTION_ID",
  ItemTax: "ITEM_TAX",
  GuestsPreviousBalanceTotalPaidAndNewBalance: "GUESTS_PREVIOUS_BALANCE_TOTAL_PAID_AND_NEW_BALANCE",
};

export default enumeration(ReceiptName);
