import { Alert } from "antd";
import classnames from "classnames";
import { Icon, Text } from "components/commons";
import { AlertType } from "enums";

const AlertMessage = ({
  customMessage,
  message = "",
  className,
  type = AlertType.Info,
  showIcon = false,
  description,
  closable,
  icon,
  iconClassName = "text-xs",
  banner = false,
}) => {
  const renderIcon = icon ? <Icon name={icon} className={iconClassName} /> : null;

  return (
    <Alert
      className={classnames(className, "flex items-baseline rounded-md")}
      type={type}
      message={customMessage ? customMessage : <Text>{message}</Text>}
      showIcon={showIcon}
      icon={renderIcon}
      banner={banner}
      description={description ? <Text size="text-xs">{description}</Text> : null}
      closable={closable}
    />
  );
};

export default AlertMessage;
