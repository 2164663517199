import { Post } from "services";
import { ApiPath } from "paths";

export const activatePurchasePlus = async (body) => {
  const res = await Post(`${ApiPath.PURCHASE_PLUS_ACTIVATE}`, body);
  return res;
};

export const editTokenPurchasePlus = async (body) => {
  const res = await Post(`${ApiPath.MEWS_ACTIVATE}/edit`, body);
  return res;
};

export const deactivatePurchasePlus = async ({ id, integrationType, venueId }) => {
  const res = await Post(`${ApiPath.MEWS_DEACTIVATE({ id, integrationType, venueId })}`);
  return res;
};
