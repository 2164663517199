import enumeration from "./enumeration";

const AccountName = {
  InventoryAccount: "INVENTORY_ACCOUNT",
  InventoryGainLossAccount: "INVENTORY_GAIN_LOSS_ACCOUNT",
  TaxOnPurchaseAccount: "TAX_ON_PURCHASES_ACCOUNT",
  RevenueAccount: "REVENUE_ACCOUNT",
  CostOfGoodsSold: "COST_OF_GOODS_SOLD",
  TaxOnSalesLiabilityAccount: "TAX_ON_SALES_LIABILITY_ACCOUNT",
  SaleDiscounts: "SALE_DISCOUNTS",
  SaleFoc: "SALE_FOC",
  CreditsLiabilityAccount: "CREDITS_LIABILITY_ACCOUNT",
  FreeCreditsLiabilityAccount: "FEE_CREDITS_LIABILITY_ACCOUNT",
  PostpaidCreditsLiabilityAccount: "POSTPAID_CREDITS_LIABILITY_ACCOUNT",
  ClearedUnusedExpiredCredit: "CLEARED_UNUSED_EXPIRED_CREDIT",
};

export default enumeration(AccountName);
