export const inventoryPaths = {
  INVENTORY: "",
  INVENTORY_SUPPLY_ITEM: "supply-item",
  CREATE_SUPPLY_ITEM: "supply-item/create",
  SUPPLY_ITEM_ID: (id) => `supply-item/${id}`,
  EDIT_SUPPLY_ITEM: (id) => `supply-item/${id}/edit`,
  EDIT_SUPPLY_ITEM_PAGE: "supply-item/:id/edit",
  SUPPLY_ITEM_SUMMARY: "supply-item/:id",
  SUPPLY_ITEM_MOVEMENT: "supply-item/:id/movement",
  SUPPLY_ITEM_MOVEMENT_NAV: (id) => `supply-item/${id}/movement`,
  INVENTORY_STOCKTAKE: "stocktake",
  STOCKTAKE_PAGE: "stocktake/:id",
  CREATE_STOCKTAKE: "stocktake/create",
  STOCKTAKE_ID: (id) => `stocktake/${id}`,
};
