import React from "react";
import { UnsaveChangesModal } from "components/modals";

const Form = ({ children, unsaveChangesModal, onSubmit }) => {
  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSubmit();
      }}
    >
      {children}
      <UnsaveChangesModal {...unsaveChangesModal} />
    </form>
  );
};

export default Form;
