import StaffProfileForm from "../staff-profile-form/staff-profile-form";
import { useApi } from "hooks";
import React, { useCallback, useMount } from "react";
import lang from "translations";
import { staffProfileRequest } from "mappers";
import { createStaffProfile } from "apis/staff-profile.api";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateStaffProfile = () => {
  const { request, loading: submitting } = useApi({
    api: createStaffProfile,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: staffProfileRequest,
  });

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createStaffProfile,
    });
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,
          message: lang.populate(lang.staffProfileNameCreated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  return (
    <StaffProfileForm
      title={lang.createNewStaffProfile}
      submit={submitForm}
      submitting={submitting}
    />
  );
};

export default CreateStaffProfile;
