import { Delete, Get, Post } from "services";
import { ApiPath } from "paths";

export const getProfile = async () => {
  const res = await Get(`${ApiPath.PROFILE}`, {});
  return res.data;
};

export const getStaff = async ({ venueId }) => {
  const res = await Get(`${ApiPath.STAFF}`, { venueId });
  return res.data;
};

export const searchStaff = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_STAFF}`, body);
  return res;
};

export const searchStaffWithGuestRegistration = async ({ venueId, ...body }) => {
  const res = await Post(`${ApiPath.STAFF_WITH_GUEST_REGISTRATION(venueId)}`, body);
  return res;
};

export const searchStaffWithTransaction = async ({ venueId, ...body }) => {
  const res = await Post(`${ApiPath.STAFF_WITH_TRANSACTION(venueId)}`, body);
  return res;
};

export const inviteStaff = async (body) => {
  const res = await Post(`${ApiPath.INVITE_STAFF}`, body);
  return res;
};

export const editStaff = async ({ staffId, ...body }) => {
  const res = await Post(`${ApiPath.STAFF_ID(staffId)}`, body);
  return res;
};

export const deleteStaff = async ({ staffId, venueId }) => {
  const res = await Delete(`${ApiPath.STAFF_ID(staffId)}?venueId=${venueId}`, {});
  return res;
};

export const getStaffById = async ({ staffId, ...body }) => {
  const res = await Get(`${ApiPath.STAFF_ID(staffId)}`, body);
  return res;
};

export const resendInvite = async (body) => {
  const res = await Post(`${ApiPath.STAFF_RESEND_INVITE}`, body);
  return res;
};

export const registerStaffValidateToken = async (body) => {
  const res = await Get(`${ApiPath.STAFF_VALIDATE_TOKEN}`, body);
  return res;
};

export const activateStaff = async (body) => {
  const res = await Post(`${ApiPath.PROFILE_ACTIVATE_STAFF}`, body);
  return res;
};
