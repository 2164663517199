import { Post, Get } from "services";
import { ApiPath } from "paths";

export const searchShift = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_SHIFT}`, body);
  return res;
};

export const getShiftById = async ({ shiftId }) => {
  const res = await Get(`${ApiPath.SHIFT_ID(shiftId)}`, {});
  return res;
};

export const exportShiftEOD = async ({ shiftId, ...body }) => {
  const res = await Post(`${ApiPath.EXPORT_EOD_SHIFT(shiftId)}`, body);
  return res;
};

export const exportRegisterReport = async ({ ...body }) => {
  const res = await Post(`${ApiPath.SEARCH_SHIFT_EXPORT}`, body);
  return res;
};

export const searchShiftLocations = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_SHIFT_LOCATION}`, body);
  return res;
};

export const searchShiftStaffs = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_SHIFT_STAFF}`, body);
  return res;
};

export const searchShiftDevices = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_SHIFT_DEVICE}`, body);
  return res;
};
