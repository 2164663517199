import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    name = "",
    description = "",
    amount = 0,
    isAllowedPostLimit = false,
    limit = 0,
    freeCredit = 0,
    isAskQuestion = false,
    questions = {},
    vouchers = [],
    customQuestionsRequired = {},
    sortQuestions = [],
    customQuestionIncludes = {},
  } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.profileName,
      placeholder: lang.guestProfileNamePlaceholder,
    },
    description: {
      name: "description",
      value: description || "",
      type: Field.INPUT,
      validations: [Validation.maxlength(255)],
      maxLength: 255,
      label: lang.description,
      placeholder: lang.guestProfileDescriptionPlaceholder,
    },
    amount: {
      name: "amount",
      description: lang.theAmountTheGuestWillPay,
      value: amount,
      type: Field.INPUT,
      label: lang.bookingPrice,
      placeholder: lang.guestProfileAmountPlaceholder,
    },
    isAllowedPostLimit: {
      name: "isAllowedPostLimit",
      value: isAllowedPostLimit,
      type: Field.CHECKBOX,
      label: lang.allowPostpaidInGuestProfile,
    },
    freeCredit: {
      name: "freeCredit",
      value: freeCredit,
      type: Field.INPUT,
    },
    limit: {
      name: "limit",
      value: limit,
      type: Field.INPUT,
    },
    isAskQuestion: {
      name: "isAskQuestion",
      value: isAskQuestion,
      type: Field.CHECKBOX,
    },
    customQuestionsRequired: {
      name: "customQuestionsRequired",
      value: customQuestionsRequired,
      type: Field.ANY,
    },
    questions: {
      name: "questions",
      value: {
        firstNameRequired: {
          checked: true,
        },
        lastNameRequired: {
          checked: true,
        },
        ...questions,
      },
      type: Field.ANY,
    },
    sortQuestions: {
      name: "sortQuestions",
      value: sortQuestions,
      type: Field.ANY,
    },
    vouchers: {
      name: "vouchers",
      value: vouchers,
      type: Field.ANY,
    },
    customQuestionIncludes: {
      name: "customQuestionIncludes",
      value: customQuestionIncludes,
      type: Field.ANY,
    },
  };
};

export default initialFormState;
