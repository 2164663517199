export const taxesList = {
  id: {
    key: "taxId",
  },
  name: {
    key: "taxName",
  },
  nameAndTax: {
    transform: ({ src }) => {
      return `${src.taxName || "-"} (${src.taxPercentage || 0}%)`.trim();
    },
  },
  percentage: {
    key: "taxPercentage",
  },
  isDefault: {
    key: "taxDefault",
  },
  accountCodeId: {
    key: "accountCodeId",
  },
  taxCode: {
    key: "taxCode",
  },
  isMewsTaxCode: {
    key: "taxMewsCode",
  },
};

export const createTaxRequest = {
  taxName: {
    key: "name",
  },
  taxPercentage: {
    key: "percentage",
  },
  venueId: {
    key: "venueId",
  },
  taxDefault: {
    key: "isDefault",
  },
  editable: {
    key: "editable",
  },
  accountCodeId: {
    key: "accountCodeId",
  },
  taxCode: {
    key: "taxCode",
  },
  taxMewsCode: {
    key: "isMewsTaxCode",
  },
};

export const taxRequest = {
  ...createTaxRequest,
  taxId: {
    key: "id",
  },
};
