import lang from "translations";

const columns = [
  {
    key: "shiftId",
    text: lang.shift,
    custom: true,
    width: "w-auto",
  },
  {
    key: "device",
    text: lang.device,
    custom: true,
    width: "w-auto",
  },
  {
    key: "staff",
    text: lang.staff,
    width: "w-auto",
  },
  {
    key: "start",
    text: lang.start,
    custom: true,
    width: "w-auto",
  },
  {
    key: "end",
    text: lang.end,
    custom: true,
    width: "w-auto",
    sort: true,
  },
  {
    key: "location",
    text: lang.location,
    width: "w-auto",
  },
  {
    key: "transactions",
    text: lang.transactions,
    custom: true,
    width: "w-auto",
  },
];

export default columns;
