import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    posStatus = true,
    name = "",
    description = "",
    locations = [],
    dateRange = [null, null],
    issuanceLimit = 10,
    productSkuIds = [],
    categories = [],
  } = initialState;

  return {
    posStatus: {
      name: "posStatus",
      value: posStatus,
      type: Field.CHECKBOX,
      title: lang.availableOnPos,
      description: lang.voucherAvailable,
      ...posStatus,
    },
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.voucherName,
    },
    description: {
      name: "description",
      value: description,
      type: Field.INPUT,
      maxLength: 255,
      label: lang.description,
    },
    locations: {
      name: "locations",
      value: locations,
      type: Field.MULTIPLE_DROPDOWN,
      validations: [Validation.requiredMultiDropdown(`Please select a location.`, false)],
      label: lang.locations,
    },
    dateRange: {
      name: "dateRange",
      value: dateRange,
      type: Field.DATE_RANGE,
      label: lang.dateRange,
    },
    issuanceLimit: {
      name: "issuanceLimit",
      value: issuanceLimit,
      type: Field.NUMBER,
      max: 5000,
      precision: 0,
    },
    productSkuIds: {
      name: "productSkuIds",
      value: productSkuIds,
      type: Field.ANY,
      required: true,
    },
    categories: {
      name: "categories",
      placeholder: lang.searchCategories,
      type: Field.ANY,
      value: categories,
      emptyPlaceHolder: lang.noAvailableCategories,
    },
  };
};

export default initialFormState;
