import { Get, Post } from "services";
import { ApiPath } from "paths";

export const bookingCheck = async ({ venueId }) => {
  const res = await Get(`${ApiPath.BOOKING_CHECK}?venueId=${venueId}`);
  return res;
};

export const getMewsIntegrationStatus = async ({ venueId }) => {
  const res = await Get(`${ApiPath.MEWS_INTEGRATION}?venueId=${venueId}`);
  return res;
};

export const activateMews = async (body) => {
  const res = await Post(`${ApiPath.MEWS_ACTIVATE}`, body);
  return res;
};

export const editTokenMews = async (body) => {
  const res = await Post(`${ApiPath.MEWS_ACTIVATE}/edit`, body);
  return res;
};

export const deactivateMews = async ({ id, integrationType, venueId }) => {
  const res = await Post(`${ApiPath.MEWS_DEACTIVATE({ id, integrationType, venueId })}`);
  return res;
};
