import { PouchnationLogo } from "images";
import React from "react";
import { Image } from "..";
import classNames from "classnames";
import styles from "./logo.module.scss";

const Logo = ({ className }) => {
  return <Image className={classNames(className, styles.logo)} src={PouchnationLogo} />;
};

export default Logo;
