import { Field } from "enums";
import { prettifyFieldType } from "services";
import Validation from "services/validation.service";
import lang from "translations";

const typeOptions = () => {
  return [
    Field.INPUT,
    Field.CHECKBOX,
    Field.DROPDOWN,
    Field.RADIO,
    Field.COUNTRY,
    Field.NATIONALITY,
  ].map((type) => {
    return {
      text: prettifyFieldType(type),
      value: type,
    };
  });
};

const validateDuplicate = (field, { parent, index }) => {
  const values = parent.value
    .filter((v, valueIndex) => {
      return valueIndex !== index;
    })
    .map((v) => v.value.trim().toLowerCase());
  const isDuplicate = values.includes(field.value.trim().toLowerCase());
  return {
    error: isDuplicate,
    message: isDuplicate ? lang.duplicateOption : null,
  };
};

export const optionFormState = {
  value: "",
  placeholder: lang.typeAnOption,
  required: true,
  validations: [Validation.required(), validateDuplicate],
  dirty: false,
  type: Field.INPUT,
  maxLength: 75,
};

const initialFormState = (initialState = {}) => {
  const { name = "", type = null, options = [] } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      placeholder: lang.typeAQuestion,
      label: lang.question,
    },
    type: {
      name: "type",
      value: type,
      type: Field.DROPDOWN,
      validations: [Validation.required()],
      required: true,
      placeholder: lang.selectAType,
      options: typeOptions(),
      label: lang.type,
    },
    options: {
      name: "options",
      type: Field.ANY,
      required: true,
      isFormArray: true,
      validations: [
        (item, { parent }) => {
          if (![Field.DROPDOWN, Field.RADIO].includes(parent.type.value)) {
            return { error: false, message: null };
          }
          if (!item.value?.length) {
            return { error: true, message: lang.addOptions };
          }
          return { error: false, message: null };
        },
      ],
      value: options.map((option) => {
        return {
          ...option,
          placeholder: lang.typeAnOption,
          required: true,
          validations: [Validation.required(), validateDuplicate],
        };
      }),
    },
  };
};

export default initialFormState;
