import React from "react";
import { Text } from "components/commons";
import { environment } from "environments/environment";
import lang from "translations";
import classnames from "classnames";
import styles from "./page-footer.module.scss";
const PageFooter = ({ className }) => {
  return (
    <div className={classnames(className || styles.footerContainer)}>
      <div className={classnames(styles.footerItems)}>
        <Text size="text-xs" className="text-gray-600">
          {lang.copyrightYear}
          <a href="https://pouchnation.com" target="_blank" rel="noreferrer" className="text-xs">
            {lang.pouchnation}
          </a>
          . {lang.allRightsReserved}
        </Text>

        <Text size="text-xs" className="text-gray-400">
          <a
            href="https://pouchnation.com/terms/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.termsOfUse}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400">
          <a
            href="https://pouchnation.com/privacy/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.privacy}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400">
          <a
            href="https://pouchnation.atlassian.net/wiki/external/1136001061/NzliOGQ5MmM4MjA3NDI4NGFhZjE4ZDE1YTk3NWNlMjY"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.glossary}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400">
          <a
            href="https://feedback.pouchnation.com/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.feedback}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400">
          <a
            href="https://feedback.pouchnation.com/roadmap"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.roadMap}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400">
          <a
            href="https://pouchnation.com/contact-us/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.contactUs2}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400">
          <a href={environment.CONNECTOR_URL} target="_blank" rel="noreferrer" className="text-xs">
            {lang.pouchConnect}
          </a>
        </Text>
      </div>
    </div>
  );
};

export default PageFooter;
