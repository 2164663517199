import { Button, Image, Panel, Text, Title } from "components/commons";
import { IdeaBulb } from "images";
import lang from "translations";
import React from "react";
import { redirectTo } from "services";
import { Path } from "paths";

const BookingNoIntegrationState = () => {
  return (
    <Panel className="mt-md h-full">
      <div className="p-xs md:p-md lg:p-xxl grid grid-flow-col grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 items-center gap-4">
        <div className="place-self-center">
          <Image className="max-w-xxs md:max-w-sm" src={IdeaBulb} />
        </div>
        <div className="pr-lg">
          <Title xl>{lang.powerYourVenue}</Title>
          <Text size="text-md">{lang.integrateDesc}</Text>
          <ul className="list-disc ml-md">
            <li className="text-pelorous-light text-xl my-sm">
              <Title md>{lang.viewBookingInfo}</Title>
            </li>
            <li className="text-pelorous-light text-xl my-sm">
              <Title md>{lang.easierGuestReg}</Title>
            </li>
          </ul>
          <Button className="mt-md" onClick={() => redirectTo(Path.INTEGRATION_SETTING)}>
            {lang.viewIntegrations}
          </Button>
        </div>
      </div>
    </Panel>
  );
};

export default BookingNoIntegrationState;
