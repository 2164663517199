import { HalfPage, LangSelector, Logo, Text, Title } from "components/commons";
import React, { useContext, useState } from "react";
import VenueForm from "../venue-form/venue-form";
import styles from "../venue-form/venue-form.module.scss";
import classNames from "classnames";
import lang from "translations";
import { useApi, useRouter, useMount } from "hooks";
import { createVenue } from "apis/venue.api";
import { ProfileContext, VenuesContext } from "contexts";
import { environment } from "environments/environment";
import { Redirect } from "react-router";
import { mixpanel, TrackEvent } from "mixpanel";

const CreateVenue = () => {
  const { profile } = useContext(ProfileContext);
  const { pabblyCustomerId, registrationMark } = profile || {};
  const { request, loading } = useApi({
    api: createVenue,
    handleOwnError: {
      badrequest: true,
    },
  });
  const [processing, setProcessing] = useState(false);
  const { history } = useRouter();
  const { venues } = useContext(VenuesContext);
  const isEnterprise = registrationMark === "ENTERPRISE";

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createVenuePage,
    });
  });

  const submit = async (params) => {
    setProcessing(true);
    try {
      const venue = await request({ ...params, currencyCode: "USD" });
      if (venue.data.venueId) {
        localStorage.setItem("venueId", venue.data.venueId);
      }

      if (pabblyCustomerId) {
        window.location.href = `${
          isEnterprise
            ? environment.PABBLY_SUBSCRIPTION_WITHOUT_FREE_TRIAL
            : environment.PABBLY_SUBSCRIPTION_WITH_FREE_TRIAL
        }?customer_id=${pabblyCustomerId}`;
      } else {
        history.push("/");
      }

      return venue;
    } catch (e) {
      setProcessing(false);
      throw e;
    }
  };

  if (venues.length) {
    // only allow create venue if user has no venues
    return <Redirect path={"/"} />;
  }

  return (
    <HalfPage>
      <div className="grid grid-cols-1 justify-items-start">
        <div className={classNames(styles.container, "pt-xl py-md text-left")}>
          <Logo />
          <Title className="mt-lg">{lang.createYourVenue}</Title>
          {!isEnterprise && <Text>{lang.thisVenueFreeTrial}</Text>}
        </div>
        <VenueForm submitting={loading || processing} submit={submit} />

        <div className={classNames(styles.container, "max-w-lg pt-36 mr-auto")}>
          <LangSelector />
        </div>
      </div>
    </HalfPage>
  );
};

export default CreateVenue;
