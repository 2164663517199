export const staffPaths = {
  STAFF: "",
  STAFF_CREATE: "create",
  STAFF_ID: (id) => id,
  STAFF_EDIT: ":id",
  STAFF_PROFILE: "profile",
  STAFF_PROFILE_CREATE: "profile/create",
  STAFF_PROFILE_ID: (id) => `profile/${id}`,
  STAFF_PROFILE_EDIT: (id) => `profile/${id}/edit`,
  STAFF_PROFILE_EDIT_PAGE: "profile/:id/edit",
};
