import field from "enums/field";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { searchKey = "", banType = null, banReason = "" } = initialState;
  return {
    searchKey: {
      name: "searchKey",
      value: searchKey,
      type: field.INPUT,
      validations: [Validation.required()],
      required: false,
      // maxLength: 14,
    },
    banType: {
      name: "banType",
      value: banType,
      type: field.DROPDOWN,
    },
    banReason: {
      name: "banReason",
      value: banReason,
      type: field.INPUT,
      maxLength: 255,
      label: lang.typeAReason,
    },
  };
};

export default initialFormState;
