import styles from "./end-of-day.module.scss";
export const deviceColumns = [
  {
    key: "name",
    className: styles.tableItem,
  },
  {
    key: "syncDate",
    className: styles.tableItem,
  },
];

export const shiftColumns = [
  {
    key: "name",
    className: styles.tableItem,
  },
  {
    key: "deviceName",
    className: styles.tableItem,
  },
  {
    key: "dateTimeStarted",
    className: styles.tableItem,
  },
  {
    key: "dateTimeEnded",
    className: styles.tableItem,
  },
  {
    key: "shiftIgnoreStatus",
    className: styles.tableItem,
  },
];
