import classNames from "classnames";
import { Panel, Title, Text, ButtonLink, Image, Skeleton } from "components/commons";
import { FragmentA } from "components/fragments";
import { InventoryType } from "enums";
import { Can, Plate } from "images";
import React from "react";
import lang from "translations";
import styles from "./inventory-form.module.scss";
import { v4 as uuidv4 } from "uuid";
import SupplyItemField from "./supply-item-field";
import SupplyItemsField from "./supply-items-field";
import SkuForm from "./sku-form";
import { useModal } from "hooks";
import { NeedHelpCompositionModal } from "components/modals";
import { formatNumberToMoney } from "services/money.service";
import { formatNumberWithComma } from "services";
import { SupplyItemField as SupplyItemFieldState, validateSkus } from "./product-form.state";
import { Path } from "paths";
import Validation from "services/validation.service";
import { mixpanel } from "mixpanel";

const InventoryForm = ({
  form,
  hasSelectedInventoryType,
  requestSupplyItems,
  modifyForm,
  requestMeasurement,
  isEdit,
}) => {
  const {
    hasVariants,
    inventoryType,
    sku,
    generatedSku,
    supplyUnit,
    supplyItem,
    supplyQuantity,
    sellingQuantity,
    supplyCost,
    variants,
  } = form;

  const needHelpModal = useModal();

  if (isEdit && hasVariants.value) {
    return null;
  }

  return (
    <FragmentA title={lang.inventory} description={lang.manageInventoryAndSupply}>
      {!isEdit && (
        <Panel>
          <div className="flex justify-between">
            <div>
              <Title>{lang.composition}</Title>
              <Text>{lang.selectAndMakeComposition}</Text>
            </div>
            <div>
              <ButtonLink
                onClick={() => {
                  mixpanel.track(TrackEvent.ClickedButton, {
                    Button: lang.needHelp,
                    Page: lang.createProduct,
                  });
                  needHelpModal.show();
                }}
              >
                {lang.needHelp}
              </ButtonLink>
            </div>
          </div>
          <div className={styles.container}>
            {[
              {
                title: lang.addAWholeProduct,
                description: lang.addThisProduct,
                image: Can,
                value: InventoryType.WholeProduct,
                onClick: () => {
                  modifyForm({
                    [inventoryType.name]: {
                      value: InventoryType.WholeProduct,
                    },
                    supplyCost: {
                      disabled: true,
                      value: formatNumberToMoney(0),
                      pristine: true,
                    },
                    markUp: {
                      value: formatNumberWithComma(0),
                      pristine: true,
                      disabled: true,
                    },
                    supplyUnit: {
                      required: true,
                      pristine: true,
                      value: null,
                      disabled: false,
                    },
                    supplyQuantity: {
                      required: false,
                      pristine: true,
                      value: formatNumberWithComma(0),
                      disabled: false,
                    },
                    sellingQuantity: {
                      // required: true,
                      pristine: true,
                      value: formatNumberWithComma(0),
                      disabled: false,
                      validations: [
                        Validation.required(),
                        Validation.greaterThanZero(lang.sellingQtyMustBeGreaterThanZero),
                      ],
                    },
                    supplyItems: {
                      disabled: true,
                      pristine: true,
                      value: [SupplyItemFieldState],
                    },
                    hasVariants: {
                      value: false,
                    },
                    variants: {
                      value: [],
                    },
                  });
                },
              },
              {
                title: lang.assembleAProduct,
                description: lang.chooseASimpleOrMultipleItem,
                image: Plate,
                value: InventoryType.AssembleProduct,
                onClick: () => {
                  modifyForm({
                    [inventoryType.name]: {
                      value: InventoryType.AssembleProduct,
                    },
                    supplyCost: {
                      disabled: true,
                      value: formatNumberToMoney(0),
                      pristine: true,
                      required: false,
                    },
                    markUp: {
                      value: formatNumberWithComma(0),
                      pristine: true,
                      disabled: false,
                    },
                    supplyUnit: {
                      required: false,
                      pristine: true,
                      disabled: true,
                    },
                    sellingQuantity: {
                      required: false,
                      pristine: true,
                      disabled: true,
                      validations: [],
                    },
                    supplyItems: {
                      disabled: false,
                      pristine: true,
                      value: [SupplyItemFieldState],
                    },
                    hasVariants: {
                      value: false,
                    },
                    variants: {
                      value: [],
                    },
                  });
                },
              },
            ].map((item) => {
              return (
                <div
                  key={uuidv4()}
                  className={classNames(styles.item, {
                    [`${styles.active}`]: inventoryType.value === item.value,
                  })}
                  onClick={() => {
                    item.onClick();
                    if (item.value === InventoryType.AssembleProduct) {
                      requestSupplyItems.request();
                    }
                  }}
                >
                  <div>
                    <Title lg color="text-pelorous" fontWeight="font-semibold">
                      {item.title}
                    </Title>
                    <Text className={styles.description}>{item.description}</Text>
                  </div>
                  <Image className={styles.image} src={item.image} />
                </div>
              );
            })}
          </div>
        </Panel>
      )}
      {hasSelectedInventoryType && (
        <SkuForm
          generatedSku={generatedSku}
          sku={sku}
          modifyForm={modifyForm}
          showSkuField={!hasVariants.value}
          showAutogenerateCheckbox={!isEdit}
          onChangeAutoGenerated={(value, obj) => {
            // if (hasVariants.value) {
            return {
              ...obj,
              variants: {
                value: variants.value.map((variant) => ({
                  ...variant,
                  value: {
                    ...variant.value,
                    sku: {
                      ...variant.value.sku,
                      value: "",
                      required: !value,
                      disabled: value,
                      validations: [
                        Validation.minlength(4),
                        Validation.maxlength(16),
                        validateSkus,
                        ...(!value ? [Validation.required()] : []),
                      ],
                    },
                  },
                })),
              },
            };
          }}
        />
      )}
      {hasSelectedInventoryType && !hasVariants.value && (
        <>
          {inventoryType.value === InventoryType.WholeProduct ? (
            <Panel title={lang.skuStockKeepingUnit}>
              <div className="flex justify-between">
                <div className="pr-lg">
                  <Title>{lang.supplyItem}</Title>
                  {/* <Text>{lang.selectTheItemsThisProductIsMadeOf}</Text> */}
                </div>
                <div>
                  <div>
                    <ButtonLink
                      className="whitespace-nowrap"
                      suffix
                      newTabPath={Path.INVENTORY_SUPPLY_ITEM}
                    >
                      {lang.manageSupplyItems}
                    </ButtonLink>
                  </div>
                </div>
              </div>
              {requestSupplyItems.loading ? (
                <Skeleton></Skeleton>
              ) : (
                <SupplyItemField
                  supplyItem={supplyItem}
                  supplyUnit={supplyUnit}
                  supplyQuantity={supplyQuantity}
                  sellingQuantity={sellingQuantity}
                  supplyCost={supplyCost}
                  modifyForm={modifyForm}
                  requestMeasurement={requestMeasurement}
                  isEdit={isEdit}
                  options={requestSupplyItems.mappedData}
                />
              )}
            </Panel>
          ) : (
            <Panel>
              <div className="mb-md">
                <Title>{lang.supplyItems}</Title>
                <Text>{lang.selectItemsThisProductMadeOf}</Text>
              </div>
              <SupplyItemsField
                form={form}
                options={requestSupplyItems.mappedData}
                modifyForm={modifyForm}
                loadingOptions={requestSupplyItems.loading}
                requestSupplyItems={requestSupplyItems}
              />
            </Panel>
          )}
        </>
      )}
      <NeedHelpCompositionModal {...needHelpModal} />
    </FragmentA>
  );
};

export default InventoryForm;
