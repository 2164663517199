import lang from "translations";

export const discountDetailReportColumn = () => {
  let columns = [
    {
      key: "dateAndTime",
      text: lang.dateAndTime,
      custom: true,
      sort: true,
      nowrap: true,
    },
    {
      key: "transaction",
      text: lang.transactions,
      custom: true,
    },
    {
      key: "product",
      text: lang.product,
      custom: true,
    },
    {
      key: "grossProfit",
      text: lang.grossProfit,
      sort: true,
      align: "right",
      nowrap: true,
    },
    { key: "sales", text: lang.sales, align: "right", nowrap: true },

    { key: "supplyValue", text: lang.supplyValue, align: "right", nowrap: true },
    { key: "margin", text: lang.margin, align: "right", nowrap: true },
    { key: "discountAmount", text: lang.discountAmount, align: "right", nowrap: true },
    {
      key: "discountValue",
      text: lang.discountRateOrValue,
      align: "right",
      nowrap: true,
    },
    { key: "guestName", text: lang.guestName },
    { key: "staffName", text: lang.staffName },
    { key: "location", text: lang.location },
    { key: "device", text: lang.device },
  ];

  return columns;
};
