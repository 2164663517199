import { Get, Post, Delete } from "services";
import { ApiPath } from "paths";

export const getCategory = async ({ categoryId }) => {
  const res = await Get(`${ApiPath.CATEGORY_ID(categoryId)}`);
  return res;
};

export const getAvailableCategoryColors = async ({ categoryId, venueId }) => {
  const res = await Get(`${ApiPath.CATEGORY_ID_COLORS({ categoryId, venueId })}`);
  return res;
};

export const searchCategory = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_CATEGORY}`, body);
  return res;
};

export const createCategory = async (body) => {
  const res = await Post(`${ApiPath.CATEGORY}`, body);
  return res;
};

export const editCategory = async ({ categoryId, ...body }) => {
  const res = await Post(`${ApiPath.CATEGORY_ID(categoryId)}`, body);
  return res;
};

export const deleteCategory = async ({ categoryId }) => {
  const res = await Delete(`${ApiPath.CATEGORY_ID(categoryId)}`);
  return res.data;
};

export const batchDeleteCategory = async (body) => {
  const res = await Post(`${ApiPath.BATCH_CATEGORY}`, body);
  return res.data;
};

export const searchCategoryReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_CATEGORY_REPORT}`, body);
  return res;
};

export const searchCategoryReportExport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_CATEGORY_REPORT_EXPORT}`, body);
  return res;
};

export const searchCategoryWithTransaction = async ({ venueId, ...body }) => {
  const res = await Post(`${ApiPath.CATEGORY_WITH_TRANSACTION(venueId)}`, body);
  return res;
};

export const getCategoryDetailReport = async ({ categoryId, ...body }) => {
  const res = await Post(`${ApiPath.CATEGORY_REPORT(categoryId)}`, body);
  return res;
};
