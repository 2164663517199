import React from "react";
import lang from "translations";
import { HeaderC } from "components/headers";
import { Path } from "paths";
import { TabBar, Title } from "components/commons";
import { formatDate } from "services";
import { DateTime, IntegrationType } from "enums";
import BookingStatusPill from "../booking-status-pill";
import { normalizeBookingStatus } from "services/pretty.service";

const BookingDetailsHeader = ({ data, loading, id }) => {
  const formatDescription = (bookedAt, bookedBy, integrationName) => {
    return (
      <p className="text-sm">
        Booked on <strong className="text-sm">{formatDate(bookedAt, DateTime.C) ?? "-"}</strong> by{" "}
        <strong className="text-sm">{bookedBy ?? "-"}</strong> from{" "}
        <strong className="text-sm">{integrationName ?? "-"}</strong>
      </p>
    );
  };

  return (
    <>
      <HeaderC
        loading={loading}
        customTitle={
          <div className="flex items-center gap-3">
            <Title xl className="font-semibold">
              {`${lang.bookingNo} ${data.bookingNumber}`}
            </Title>
            <BookingStatusPill status={normalizeBookingStatus(data)}></BookingStatusPill>
          </div>
        }
        customDescription={formatDescription(
          data.bookedAt,
          data.bookedBy,
          data.integrationName || IntegrationType.MEWS
        )}
        returnText={lang.bookings}
        returnPath={Path.BOOKING}
      />
      <TabBar
        className="mb-md"
        items={[
          { to: Path.BOOKING_SUMMARY_NAV(id), text: lang.summary },
          { to: Path.BOOKING_GUESTS_NAV(id), text: lang.guests },
          { to: Path.BOOKING_ACCOMMODATION_NAV(id), text: lang.accommodation },
        ]}
      ></TabBar>
    </>
  );
};

export default BookingDetailsHeader;
