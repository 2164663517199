import { Button, Field, Icon, Modal, Text, Title } from "components/commons";
// import { IntegrationType } from "enums";
import StyleType from "enums/style-type";
import { useModal } from "hooks";
import React from "react";
import { openInNewTab } from "services/url.service";
import lang from "translations";
import ConnectModal from "./connect-modal";
import DisconnectModal from "./disconnect-modal";
const IntegrationInfoModal = ({ modifyField, data, active, refreshList, ...props }) => {
  const {
    accessToken,
    name,
    instruction,
    request,
    disconnectionMessage,
    dashboardLink,
    guideLink,
    isApp = false,
    id,
    integrationType = "",
    // connectedIntegrationsList,
    SFTPDetails,
  } = data;

  const connectModal = useModal();
  const disconnectModal = useModal();

  return (
    <>
      <Modal
        {...props}
        active={connectModal.active ? false : active}
        title={
          <div className="flex justify-between ml-2">
            {integrationType === "PURCHASE_PLUS" ? (
              <Title>{`${name}`}</Title>
            ) : (
              <Title>{`${name} ${lang.integration}`}</Title>
            )}
            {/* <Icon name="remove" onClick={() => props.close()} /> */}
          </div>
        }
      >
        <div className="p-sm">
          <div className="grid grid-cols-1 gap-4">
            <Field
              customLabel={
                <Text fontWeight="font-semibold" color="text-gray">
                  {lang.status}
                </Text>
              }
            >
              <div className="flex items-center my-sm">
                <Icon name="check" color="text-green-darker" size="text-xs" />
                <Text className="ml-xs" fontWeight="font-semibold">
                  {lang.connected}
                </Text>
              </div>
            </Field>
            {!isApp && integrationType === "MEWS_Accounting || MEWS" ? (
              <Field
                customLabel={
                  <Text fontWeight="font-semibold" color="text-gray">
                    {lang.accessToken}
                  </Text>
                }
              >
                <div className="flex items-center">
                  <Text className="my-sm" fontMono breakAll>
                    {accessToken}
                  </Text>
                  <div
                    className="ml-xs mt-xxs text-blue text-sm cursor-pointer"
                    onClick={() => {
                      connectModal.show({
                        onEdit: true,
                        accessToken,
                        instruction,
                        modifyField,
                        title: name,
                        guideLink,
                        request,
                        integrationType,
                        integrationId: data.id,
                        disableAccessToken: false,
                      });
                    }}
                  >
                    {lang.edit}
                  </div>
                </div>
              </Field>
            ) : integrationType === "PURCHASE_PLUS" ? (
              <Field
                customLabel={
                  <Text fontWeight="font-semibold" color="text-gray">
                    {lang.hostname}
                  </Text>
                }
              >
                <div className="flex items-center">
                  <Text className="my-sm">{SFTPDetails.hostname}</Text>
                </div>
              </Field>
            ) : null}
          </div>
          <div className="flex my-md justify-end gap-2">
            <Button
              type={StyleType.Danger}
              onClick={() => {
                props.close();
                disconnectModal.show({
                  disconnectionMessage,
                  modifyField,
                  title: name,
                  guideLink,
                  isApp,
                  id,
                });
              }}
            >
              {lang.disconnect}
            </Button>

            {integrationType === "PURCHASE_PLUS" ? (
              <Button
                type={StyleType.Primary}
                onClick={() => {
                  connectModal.show({
                    onEdit: true,
                    hostname: SFTPDetails.hostname,
                    username: SFTPDetails.username,
                    password: SFTPDetails.password,
                    directory: SFTPDetails.directory,
                    modifyField,
                    title: name,
                    request,
                    integrationType,
                    integrationId: data.id,
                  });
                }}
              >
                {lang.editDetails}
              </Button>
            ) : null}

            {!isApp && dashboardLink ? (
              <Button
                type={StyleType.Primary}
                onClick={() => {
                  if (dashboardLink) {
                    openInNewTab(dashboardLink);
                  }
                }}
              >
                {lang.populate(lang.goToXDashboard, [name])}
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
      <ConnectModal {...connectModal} />
      <DisconnectModal
        {...disconnectModal}
        id={id}
        integrationType={integrationType}
        request={request}
      />
    </>
  );
};

export default IntegrationInfoModal;
