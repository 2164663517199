import React from "react";
import { PillType } from "enums";
import classnames from "classnames";
import styles from "./pill.module.scss";

const Pill = ({ type = PillType.Default, size = "text-sm", className, children }) => {
  const classStyle = classnames(`${size}`, className);

  return (
    <div
      className={classnames(
        "whitespace-nowrap",
        styles.container,
        {
          // "bg-blue-darker text-blue-lighter": type === PillType.Default,
          "bg-gray-lightest text-blue": type === PillType.Secondary,
          "bg-gray-lightest text-black-light": type === PillType.Tertiary,
          // "bg-red-lighter text-white": type === PillType.Red,
          "bg-blue-lightest text-gray-dark": type === PillType.Disabled,
          "bg-blue-lightest text-gray italic": type === PillType.Italic,
          // "bg-green text-white": type === PillType.Green,
          // "bg-blue-lighter text-blue-light": type === PillType.Blueish,
          // "bg-orange text-white": type === PillType.Orange,
          [`${styles.orange}`]: type === PillType.Orange,
          [`${styles.green}`]: type === PillType.Greenish || type === PillType.Green,
          [`${styles.red}`]: type === PillType.Red,
          [`${styles.reddish}`]: type === PillType.Reddish,
          [`${styles.yellow}`]: type === PillType.Yellow,
          [`${styles.blue}`]: type === PillType.Blue || type === PillType.Blueish,
          [`${styles.gray}`]: type === PillType.Gray || type === PillType.Default,
          [`${styles.opaque}`]: type === PillType.Opaque,
          [`${styles.white}`]: type === PillType.White,
        },
        classStyle
      )}
    >
      {children}
    </div>
  );
};

export default Pill;
