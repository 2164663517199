import React, {useState} from "react";
import { ButtonLink, Text } from "..";
import lang from "translations";
import { reloadPage } from "services";
import {Slider, SliderRightSideData} from "components/commons/slider"

const HalfPage = ({ children }) => {
  const [sliderIndex, setSliderIndex] = useState(0); // create venue slider

  const isLoggedIn = localStorage.getItem("accessToken");
  return (
    <div className="fixed bg-pelorous h-full w-full">
      <div className="fixed text-center h-full w-full md:w-6/12 bg-white overflow-y-auto p-md">
        {children}
      </div>
      {isLoggedIn && (
        <div className="fixed right-0 p-md z-10">
          <ButtonLink
            className="text-white"
            onClick={() => {
              localStorage.clear();
              reloadPage();
            }}
          >
            <Text color="text-white" size="text-md" strong>
              {lang.logout}
            </Text>
          </ButtonLink>
        </div>
      )}
      {/* Slider */}
      <div className="w-0 md:w-1/2 h-full absolute right-0 z-0">
        <Slider items={SliderRightSideData} sliderIndex={sliderIndex} setSliderIndex={setSliderIndex} infinite />
      </div>
    </div>
  );
};

export default HalfPage;
