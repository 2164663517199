import lang from "translations";

export const voucherReportColumn = [
  {
    key: "name",
    text: lang.voucherName,
  },
  {
    key: "valueRedeemed",
    text: lang.valueRedeemed,
    align: "right",
  },
  {
    key: "supplyValue",
    text: lang.supplyValue,
    align: "right",
    sort: true,
  },
  {
    key: "totalUse",
    text: lang.totalUse,
    align: "right",
  },
];

export const voucherDetailReportColumn = ({
  productRedeemed,
  valueRedeemed,
  supplyValue,
  guestName,
  staffName,
  locationName,
}) => {
  let columns = [
    {
      key: "dateAndTime",
      text: lang.dateAndTime,
      sort: true,
    },
    {
      key: "transaction",
      text: lang.transaction,
    },
  ];

  if (productRedeemed)
    columns.push({
      key: "productRedeemed",
      text: lang.productRedeemed,
    });
  if (valueRedeemed)
    columns.push({
      key: "valueRedeemed",
      text: lang.valueRedeemed,
      align: "right",
    });
  if (supplyValue) columns.push({ key: "supplyValue", text: lang.supplyValue, align: "right" });
  if (guestName) columns.push({ key: "guestName", text: lang.guestName, custom: true });
  if (staffName) columns.push({ key: "staffName", text: lang.staffName });
  if (locationName) columns.push({ key: "locationName", text: lang.location });

  return columns;
};
