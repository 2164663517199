import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React from "react";
import lang from "translations";
import { Path } from "paths";
import { useMount, useRouter } from "hooks";
import { Panel, Title, Text } from "components/commons";
import { v4 as uuidv4 } from "uuid";
import { mixpanel, TrackEvent } from "mixpanel";

const NotificationSetting = () => {
  const { history } = useRouter();

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.notificationSettings,
    });
  });

  return (
    <ModuleWrapper
      header={
        <HeaderB returnText={lang.settings} title={lang.notifications} returnPath={Path.SETTING} />
      }
    >
      <FragmentA title={""} description={lang.manageEmailNotif}>
        <Panel>
          {[
            {
              title: lang.stockLevelAlerts,
              description: lang.whenAnItemAlertLevel,
              path: Path.SETTING_NOTIFICATION_STOCK_LEVEL,
            },
          ].map(({ title, description, path }) => {
            return (
              <div
                key={uuidv4()}
                className="-mr-lg -ml-lg hover:bg-gray-lightest p-lg cursor-pointer"
                onClick={() => {
                  history.push(path);
                }}
              >
                <Title md className="mb-xs">
                  {title}
                </Title>
                <Text>{description}</Text>
              </div>
            );
          })}
        </Panel>
      </FragmentA>
    </ModuleWrapper>
  );
};

export default NotificationSetting;
