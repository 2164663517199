import { Icon } from "components/commons";
import { VenuesContext } from "contexts";
import { Path } from "paths";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

const VenueSelectRoute = ({ component: Component, ...rest }) => {
  const { venues, loading, hasVenue } = useContext(VenuesContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return (
            <div className="w-full h-full flex items-center">
              <Icon loading className="m-auto" fontSize="30px" />
            </div>
          );
        }
        if (!hasVenue && !loading) {
          return <Redirect to={Path.CREATE_VENUE} />;
        }
        if (venues.length > 1) {
          return <Component {...props} />;
        } else if (venues.length === 1) {
          localStorage.setItem("venueId", venues[0].venueId);
          return <Redirect to={Path.VENUE_SLASH} />;
        }
      }}
    />
  );
};

export default VenueSelectRoute;
