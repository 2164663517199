import { SearchOutlined } from "@ant-design/icons";
import {
  ActionButton,
  Checkbox,
  CheckboxField,
  Icon,
  Input,
  Modal,
  Pill,
  Skeleton,
  Text,
  Title,
} from "components/commons";
import { VenueContext } from "contexts";
import { PillType, ProductType, StyleType } from "enums";
import { useApi, useMount } from "hooks";
import { browseProductCategoryListRequest, browseProductCategoryListResponse } from "mappers";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import lang from "translations";
import { searchProduct } from "apis";
import { mapObjects } from "services";
import { mixpanel, TrackEvent } from "mixpanel";

const BrowseProductModal = ({ active, close, locations, onAdd, preSelectedSkus = [] }) => {
  const { venue } = useContext(VenueContext);

  const searchInputRef = useRef();

  const [selectedProductCategory, setSelectedProductCategory] = useState();
  const [selectedMap, setSelectedMap] = useState([]);
  const [searchMode, setSearchMode] = useState();
  const [productList, setProductList] = useState([]);

  const {
    request: searchProductRequest,
    loading: loadingProduct,
    mappedData: products,
  } = useApi({
    api: searchProduct,
    isArray: true,
    mapper: browseProductCategoryListResponse,
    paramsMapper: browseProductCategoryListRequest,
  });

  useMount(() => {
    fetchProducts();
  });

  useEffect(() => {
    if (active) {
      fetchProducts();
      searchInputRef.current.clearValue();
    } else {
      setSelectedProductCategory();
      setSearchMode(false);
    }
    //eslint-disable-next-line
  }, [active]);

  const fetchProducts = useCallback(
    async (searchKey) => {
      const res = await searchProductRequest({
        venueId: venue.venueId,
        locations,
        searchKey,
      });
      const productsRes = mapObjects(res.data, browseProductCategoryListResponse);
      const selectedMap = prepareProductSelectOptions(productsRes, preSelectedSkus);
      setProductList(productsRes);
      setSelectedMap(selectedMap);
    },
    [searchProductRequest, locations, venue, preSelectedSkus]
  );

  const searchProductCb = useCallback(
    async (searchKey) => {
      await searchProductRequest({
        venueId: venue.venueId,
        locations,
        searchKey,
        categoryId: selectedProductCategory?.id,
      });
      setSearchMode(true);
    },
    [searchProductRequest, locations, venue, selectedProductCategory]
  );

  const selectSku = useCallback(
    (productId, productSkuId, value) => {
      selectedMap.forEach((productMap) => {
        if (productMap.has(productId)) {
          const skuMap = productMap.get(productId);
          if (skuMap.has(productSkuId)) {
            skuMap.set(productSkuId, value);
          }
        }
      });
      setSelectedMap(new Map(selectedMap));
    },
    [selectedMap]
  );

  const selectProduct = useCallback(
    (productId, { value }) => {
      selectedMap.forEach((productMap) => {
        if (productMap.has(productId)) {
          const skuMap = productMap.get(productId);
          skuMap.forEach((v, k, self) => {
            self.set(k, value);
          });
        }
      });
      setSelectedMap(new Map(selectedMap));
    },
    [selectedMap]
  );

  const selectCategory = useCallback(
    (categoryId, { value }) => {
      if (selectedMap.has(categoryId)) {
        const productMap = selectedMap.get(categoryId);

        productMap.forEach((skuMap, productId) => {
          selectProduct(productId, { value });
        });

        setSelectedMap(new Map(selectedMap));
      }
    },
    [selectedMap, selectProduct]
  );

  const backToProductCategory = useCallback(async () => {
    setSelectedProductCategory();
    setSearchMode(false);
    // await searchProductRequest({
    //   venueId: venue.venueId,
    //   locations,
    // });
  }, []);

  const header = useMemo(() => {
    return (
      <div className="pt-lg">
        <div className="flex items-center">
          {(selectedProductCategory || searchMode) && (
            <Icon onClick={backToProductCategory} name="arrow-left" className="pl-0" />
          )}
          <Title lg>{lang.selectProducts}</Title>
        </div>
        <div className="flex items-center flex-wrap mt-sm mb-md">
          <Text className="ml-xs mr-sm">{lang.in}</Text>
          {locations?.length === 0 ? (
            <Pill size="text-xs" type={PillType.Tertiary}>
              {lang.allLocations}
            </Pill>
          ) : (
            locations?.map((l, i) => (
              <Pill key={i} size="text-xs" type={PillType.Tertiary}>
                {l.text}
              </Pill>
            ))
          )}
        </div>
        <Input
          ref={searchInputRef}
          className="ml-xs mb-md"
          placeholder={
            selectedProductCategory
              ? lang.populate(lang.searchProductsIn, [selectedProductCategory.name])
              : lang.searchProductByKeyOrSku
          }
          iconSuffix={
            <SearchOutlined
              onClick={() => searchProductCb(searchInputRef.current.value())}
              className="cursor-pointer"
            />
          }
          onEnter={searchProductCb}
        />
      </div>
    );
  }, [locations, selectedProductCategory, searchProductCb, backToProductCategory, searchMode]);

  const showProductCategoryDetail = useCallback((category) => {
    setSelectedProductCategory(category);
  }, []);

  const hasSelectedAnything = useMemo(() => {
    var hasSelected = false;
    const productCategories = groupProductsByCategory(productList, false);

    if (selectedMap) {
      selectedMap.forEach((productMap, categoryId) => {
        const productCategory = productCategories.find((pc) => {
          return pc.id === categoryId;
        });
        if (!productCategory) return;

        productMap.forEach((skuMap, productId) => {
          skuMap.forEach((value, productSkuId) => {
            if (value) {
              hasSelected = true;
            }
          });
        });
      });
    }

    return hasSelected;
  }, [selectedMap, productList]);

  const content = useMemo(() => {
    if (!active) {
      return null;
    }
    if (loadingProduct) {
      return <Skeleton className="pb-md" />;
    }

    if (products.length === 0) {
      return (
        <div className="mt-xl mb-sm">
          {searchInputRef.current ? (
            searchInputRef.current.value().length !== 0 ? (
              <div className="flex items-center text-center">
                <Text size="text-sm" color="text-gray" className="m-auto">
                  {lang.populate(lang.noResultsMatchingX, [searchInputRef.current.value])}
                </Text>
              </div>
            ) : null
          ) : null}

          <div className="flex items-center text-center">
            <Text size="text-sm" color="text-gray" className="m-auto">
              {lang.productsDoesNotExist}
            </Text>
          </div>
        </div>
      );
    }

    if (selectedProductCategory || searchMode) {
      return renderProductCategoryDetailContent(
        products,
        selectedProductCategory,
        selectSku,
        selectedMap,
        selectProduct,
        searchInputRef?.current?.value()
      );
    }
    if (products.length > 0 && selectedMap) {
      return renderProductCategoryContent(
        products,
        showProductCategoryDetail,
        selectedMap,
        selectCategory
      );
    }
  }, [
    selectedProductCategory,
    loadingProduct,
    products,
    showProductCategoryDetail,
    selectSku,
    selectedMap,
    selectProduct,
    active,
    selectCategory,
    searchMode,
  ]);

  return (
    <div>
      <Modal
        className="h-full"
        noHeader
        width={800}
        active={active}
        close={close}
        actionContent={
          !loadingProduct && (
            <ActionButton
              className="px-md py-md"
              secondary={{
                text: lang.cancel,
                onClick: () => close(),
              }}
              primary={{
                disabled: products.length === 0 || !hasSelectedAnything,
                text: lang.add,
                type: StyleType.Primary,
                onClick: () => {
                  mixpanel.track(TrackEvent.ClickedButton, {
                    button: lang.addProduct,
                  });
                  onAdd(getOnlySelectedProduct(selectedMap, productList));
                  close();
                },
              }}
            />
          )
        }
      >
        {header}
        <div className="overflow-x-hidden overflow-y-auto h-auto" style={{ maxHeight: "400px" }}>
          {content}
        </div>
      </Modal>
    </div>
  );
};

const rowStyle =
  "p-md px-lg -mx-lg first:border-t-2 border-b border-gray-lightest hover:bg-gray-lightest";

const renderProductCategoryContent = (
  products,
  showProductCategoryDetail,
  selectedMap,
  selectCategory
) => {
  const productCategories = groupProductsByCategory(products);

  return (
    <div>
      {productCategories.map((pc, i) => {
        let categoryCheckboxValue = false;
        let checkedAll = true;
        let notCheckedAll = true;
        if (selectedMap.has(pc.id)) {
          const categoryMap = selectedMap.get(pc.id);

          categoryMap.forEach((skuMap) => {
            skuMap.forEach((checked) => {
              if (checked) {
                notCheckedAll = false;
              } else {
                checkedAll = false;
              }
            });
          });

          if (!checkedAll && !notCheckedAll) {
            categoryCheckboxValue = "indeterminate";
          } else if (checkedAll && !notCheckedAll) {
            categoryCheckboxValue = true;
          } else {
            categoryCheckboxValue = false;
          }
        }

        return (
          <div
            onClick={() => {
              showProductCategoryDetail(pc);
            }}
            key={i}
            className={`flex items-center justify-between cursor-pointer ${rowStyle}`}
          >
            <CheckboxField
              name={pc.id}
              value={categoryCheckboxValue}
              textSize="text-sm"
              onChange={selectCategory}
            >
              {pc.name}
            </CheckboxField>
            <Icon name="arrow-right" className="text-blue text-lg" />
          </div>
        );
      })}
    </div>
  );
};

const renderProductCategoryDetailContent = (
  products,
  selectedProductCategory,
  selectSku,
  selectedMap,
  selectProduct,
  searchKey
) => {
  if (products.length === 0) {
    return (
      <div className="text-center my-xl">
        {searchKey && <p>{`${lang.noResultsMatching} "${searchKey}"`}</p>}
        <p>{lang.productsDoesNotExist}</p>
      </div>
    );
  }
  if (selectedProductCategory) {
    products = filterProductsByCategory(products, selectedProductCategory.id);
  }

  let productDisplay = [
    <div className={`${rowStyle} uppercase font-medium text-sm`}>{lang.productsVariants}</div>,
  ];

  products.forEach((p, i) => {
    if (p.type === ProductType.Standard || p.type === ProductType.Composite) {
      productDisplay.push(
        <div className={rowStyle}>
          <CheckboxField
            value={selectedMap
              .get(
                selectedProductCategory ? selectedProductCategory.id : getProductCategoryIdFirst(p)
              )
              .get(p.id)
              .get(p.productSkus[0].productSkuId)}
            name={p.productSkus[0].productSkuId}
            key={i}
            textSize="text-sm"
            onChange={(name, { value }) => selectSku(p.id, name, value)}
          >
            {p.name}
          </CheckboxField>
        </div>
      );
    }

    if (p.type === ProductType.Variant) {
      const skuMap = selectedMap
        .get(selectedProductCategory ? selectedProductCategory.id : getProductCategoryIdFirst(p))
        .get(p.id);
      const skuArray = Array.from(skuMap).map(([key, value]) => ({ productSkuId: key, value }));

      let variantCheckboxValue = false;
      let checkedAll = true;
      let notCheckedAll = true;
      skuArray.forEach((sku) => {
        if (sku.value) {
          notCheckedAll = false;
        } else {
          checkedAll = false;
        }
      });

      if (!checkedAll && !notCheckedAll) {
        variantCheckboxValue = "indeterminate";
      } else if (checkedAll && !notCheckedAll) {
        variantCheckboxValue = true;
      } else {
        variantCheckboxValue = false;
      }

      productDisplay.push(
        <div
          className={`p-md px-lg -mx-lg first:border-t-2 border-b-0 border-gray-lightest hover:bg-gray-lightest`}
        >
          <CheckboxField
            key={i}
            name={p.id}
            value={variantCheckboxValue}
            textSize="text-sm"
            onChange={selectProduct}
          >
            {p.name}
          </CheckboxField>
        </div>
      );
      const attributes = new Set();

      p.productSkus.forEach((psku) => {
        psku.productVariants.forEach((pv) => {
          attributes.add(pv.attribute);
        });
      });

      const sortedAttributes = [...attributes].sort();

      productDisplay.push(
        <div className="flex items-center pl-9">
          {sortedAttributes.map((sa, i) => (
            <Text key={i} size="text-xs" color="text-gray" className="min-w-72" uppercase>
              {sa}
            </Text>
          ))}
        </div>
      );

      let variants = [];

      p.productSkus.forEach((psku) => {
        let variant = [];
        sortedAttributes.forEach((sa) => {
          psku.productVariants.forEach((pv) => {
            if (pv.attribute === sa) {
              variant.push(pv.value);
            }
          });
        });

        const variantObj = { productSkuId: psku.productSkuId, value: variant };
        variants.push(variantObj);
      });

      variants.forEach((variantObj) => {
        productDisplay.push(
          <div className={`flex items-baseline ${rowStyle} pl-14`}>
            {variantObj.value.map((v, i) =>
              i === 0 ? (
                <div key={i} className="flex min-w-72">
                  <Checkbox
                    name={variantObj.productSkuId}
                    value={selectedMap
                      .get(
                        selectedProductCategory
                          ? selectedProductCategory.id
                          : getProductCategoryIdFirst(p)
                      )
                      .get(p.id)
                      .get(variantObj.productSkuId)}
                    onChange={(name, { value }) => selectSku(p.id, name, value)}
                  ></Checkbox>
                  <Text className="ml-sm">{v}</Text>
                </div>
              ) : (
                <Text className="min-w-72">{v}</Text>
              )
            )}
          </div>
        );
      });
    }
  });

  return <div>{productDisplay}</div>;
};

const groupProductsByCategory = (products, allProductsOption = true, hasUncategorized = false) => {
  var categoryMap = new Map();

  const centerMap = new Map();
  const bottomMap = new Map();
  products.forEach((p) =>
    p.categories.length === 0
      ? bottomMap.set(0, lang.uncategorized)
      : p.categories.forEach((c) => centerMap.set(c.id, c.name))
  );
  const sortedMap = new Map([...centerMap.entries()].sort((a, b) => a[1].localeCompare(b[1])));
  if (allProductsOption) {
    categoryMap.set(null, lang.allProducts);
  }
  categoryMap = new Map([...categoryMap].concat([...sortedMap, ...bottomMap]));

  return Array.from(categoryMap).map(([key, value]) => ({ id: key, name: value }));
};

const filterProductsByCategory = (products, selectedCategoryId) => {
  if (selectedCategoryId === 0) {
    return products.filter((p) => {
      return p.categories.length === 0;
    });
  }

  if (!selectedCategoryId) {
    return products;
  }
  return products.filter((p) => {
    return p.categories.some((c) => {
      return c.id === selectedCategoryId;
    });
  });
};

const prepareProductSelectOptions = (products, preSelectedSkus) => {
  const groupedProducts = groupProductsByCategory(products);
  const categoryMap = new Map();

  groupedProducts.forEach((gp) => {
    categoryMap.set(gp.id, new Map());
  });

  categoryMap.forEach((val, categoryId) => {
    const productMap = new Map();

    const filteredProducts = filterProductsByCategory(products, categoryId);

    filteredProducts.forEach((fp) => {
      const skuMap = new Map();
      fp.productSkus.forEach((psku) => {
        const { productSkuId } = psku;
        if (preSelectedSkus.includes(productSkuId)) {
          skuMap.set(productSkuId, true);
        } else {
          skuMap.set(productSkuId, false);
        }
      });
      productMap.set(fp.id, skuMap);
    });

    categoryMap.set(categoryId, productMap);
  });

  return categoryMap;
};

const getProductCategoryIdFirst = (product) => {
  if (product.categories.length === 0) {
    return 0;
  }
  return product.categories[0].id;
};

const getOnlySelectedProduct = (selectedMap, productList) => {
  const categoryMap = new Map();

  const productCategories = groupProductsByCategory(productList, false);

  selectedMap.forEach((productMap, categoryId) => {
    const productCategory = productCategories.find((pc) => {
      return pc.id === categoryId;
    });
    if (!productCategory) return;

    const map = new Map();

    productMap.forEach((skuMap, productId) => {
      const skuIds = new Set();
      skuMap.forEach((value, productSkuId) => {
        if (value) {
          skuIds.add(productSkuId);
        }
      });
      if (skuIds.size > 0) {
        const product = productList.find((p) => p.id === productId);
        map.set(product.name, [...skuIds]);
      }
    });

    categoryMap.set(productCategory.name, map);
  });
  return categoryMap;
};

export default BrowseProductModal;
