import { CheckboxField, Modal, Text } from "components/commons";
import React, { useMemo } from "react";
import lang from "translations";

const ColumnModal = ({ active, close, ok, columns, columnValues, onChange }) => {
  const renderCheckboxes = useMemo(() => {
    return (
      <div>
        {columns.map((column, i) => {
          return (
            <div key={i} className="flex items-center py-md">
              {column.map((c, j) => {
                return (
                  <CheckboxField
                    key={j}
                    className="w-full"
                    name={c.name}
                    value={columnValues[c.name]}
                    onChange={onChange}
                  >
                    {c.text}
                  </CheckboxField>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }, [columns, columnValues, onChange]);

  return (
    <Modal active={active} title={lang.columns} onClose={close}>
      <Text>{lang.chooseColumns}</Text>
      {renderCheckboxes}
    </Modal>
  );
};

export default ColumnModal;
