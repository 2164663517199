import React from "react";
import { Input, Button } from "components/commons";
import lang from "translations";
import { SearchOutlined } from "@ant-design/icons";
import classnames from "classnames";
import styles from "./search-and-browse.module.scss";
import { StyleType } from "enums";

const SearchAndBrowse = ({ onClick, searchText = lang.search, placeholder = lang.search }) => {
  return (
    <div className="flex" onClick={() => onClick()}>
      <Input
        className={classnames("rounded-r-none", styles.browseInput)}
        placeholder={placeholder}
        iconPrefix={<SearchOutlined className="mr-sm" />}
        readOnly
      />
      <Button
        type={StyleType.Secondary}
        className={classnames(
          "rounded-l-none bg-gradient-to-b from-white to-white-dark border-l-0 border-white-darker"
        )}
      >
        {searchText}
      </Button>
    </div>
  );
};

export default SearchAndBrowse;
