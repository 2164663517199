import { Icon, Image, Text, Title, Toast } from "components/commons";
import { LayoutA } from "components/layouts";
import { StaffContext } from "contexts";
import { MobilePreview, PhoneFrame } from "images";
import React, { Fragment, useCallback, useContext } from "react";
import { getCurrentUrl, prettifyStaffRole } from "services";
import lang from "translations";
import styles from "./go-to-app-page.module.scss";
import classnames from "classnames";
import { ModuleWrapper } from "components/fragments";
import { useMount } from "hooks";

const GoToAppPage = () => {
  const { staff } = useContext(StaffContext);

  const contactUsCb = useCallback(() => {
    window.location.href = "mailto:info@pouchnation.com";
  }, []);

  useMount(() => {
    const currentUrl = getCurrentUrl();
    if (currentUrl.includes("inventory/stocktake/")) {
      Toast({
        content: lang.youDontHavePermission,
        error: true,
      }).open();
    }
  });

  return (
    <LayoutA>
      <ModuleWrapper width="medium">
        <div className="lg:flex items-center p-24">
          <div className={classnames("relative mr-xl", styles.image)}>
            <Image className={classnames(styles.phoneFrame)} src={PhoneFrame} />
            <Image className={classnames(styles.mobilePreview)} src={MobilePreview} />
          </div>
          <div className="max-w-md">
            <Title xl className="mb-lg">
              {staff.firstName ? `${lang.hello} ${staff.firstName}!` : `${lang.hello}!`}
            </Title>
            <Text size="text-md" className="mb-lg">
              {lang.youAreAssigned}
            </Text>
            <Fragment>
              {staff.roles.map((s, i) => {
                return (
                  <div key={i} className="flex items-center mb-lg">
                    <Icon className="text-green mr-sm" name="check" />
                    <Text size="text-md">{prettifyStaffRole(s)}</Text>
                  </div>
                );
              })}
            </Fragment>
            <lang.Translate
              text={lang.useVenueAppAndLogin}
              items={[
                <span className="text-blue cursor-pointer" onClick={contactUsCb}>
                  {lang.contactUs}
                </span>,
              ]}
            />
          </div>
        </div>
      </ModuleWrapper>
    </LayoutA>
  );
};

export default GoToAppPage;
