import React from "react";
import { Skeleton, Panel, Line, TabBar } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { useRouter } from "hooks";

const GuestDetailsSkeleton = ({ isGuestDetailsProfile }) => {
  const { history, query } = useRouter();
  const { id } = query;

  const header = (
    <div>
      <HeaderB returnText={lang.guests} onClick={() => history.push(Path.GUEST)} />
      <Skeleton single />
      <TabBar
        className="my-md"
        items={[
          { to: Path.GUEST_DETAILS_ID(id), text: lang.overview },
          { to: Path.GUEST_DETAILS_PROFILE_ID(id), text: lang.profile },
        ]}
      ></TabBar>
    </div>
  );

  const content = !isGuestDetailsProfile ? (
    <div className="lg:grid grid-cols-12 gap-4">
      <div className="col-span-8">
        <div>
          <Panel>
            <Skeleton />
          </Panel>
        </div>

        <div>
          <Panel>
            <Skeleton />
          </Panel>
        </div>
      </div>

      <div className="col-span-4">
        <Panel>
          <Skeleton />
        </Panel>
      </div>
    </div>
  ) : (
    <div>
      <Panel>
        <Skeleton />
        <Line />
        <Skeleton single />
      </Panel>
    </div>
  );
  <div className="grid grid-cols-12 gap-4">
    <div className="col-span-12 lg:col-span-6">
      <Panel>
        <Skeleton />
      </Panel>

      <div className="md:grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Panel>
            <Skeleton />
          </Panel>
        </div>

        <div className="col-span-6 ">
          <Panel>
            <Skeleton />
          </Panel>
        </div>
      </div>
    </div>

    <div className="col-span-12 lg:col-span-6 -mt-4 lg:-mt-0">
      <Panel>
        <Skeleton />
      </Panel>
    </div>
  </div>;

  return (
    <ModuleWrapper>
      {header}
      {content}
    </ModuleWrapper>
  );
};

export default GuestDetailsSkeleton;
