import lang from "translations";

const columns = [
  {
    key: "id",
    text: lang.id,
    custom: true,
    width: "w-auto",
  },
  {
    key: "businessDay",
    text: lang.businessDay,
    width: "w-auto",
  },
  {
    key: "timeStarted",
    text: lang.timeStarted,
    custom: true,
    width: "w-auto",
  },
  {
    key: "timeEnded",
    text: lang.timeEnded,
    custom: true,
    width: "w-auto",
  },
  {
    key: "shifts",
    text: lang.shifts,
    width: "w-auto",
    align: "right",
  },
  {
    key: "transactions",
    text: lang.transactions,
    custom: true,
    width: "w-auto",
    align: "right",
  },
  {
    key: "totalSales",
    text: lang.totalSales,
    custom: true,
    width: "w-auto",
    align: "right",
  },
];

export default columns;
