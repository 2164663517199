import React from "react";
import { LayoutA } from "components/layouts";
import { Route, Switch } from "react-router-dom";
import { SignIn } from "modules";

const AuthPage = () => {
  return (
    <LayoutA>
      <Switch>
        <Route path="/" component={SignIn} />
      </Switch>
    </LayoutA>
  );
};

export default AuthPage;
