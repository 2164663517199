import { ApiPath } from "paths";
import { Post } from "services";

export const getCloudBedsAccessToken = async ({ code, state }) => {
  return await Post(`inventory/integration/cloudbeds/callback`, { code, state });
};
export const activateCloudBeds = async (body) => {
  const res = await Post(`${ApiPath.CLOUDBEDS_ACTIVATE}`, body);
  return res;
};
export const syncCloudBeds = async (body) => {
  return await Post(
    `inventory/integration/cloudbeds/bookings/sync?enterpriseId=${body.enterpriseId}&venueId=${body.venueId}`,
    body
  );
};
