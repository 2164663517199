import { DateTime, DiscountStatus, DiscountType } from "enums";
import moment from "moment";
import {
  formatDate,
  formatName,
  mapObject,
  mapObjects,
  mapObjectsToSelect,
  mapSelectObjectsToValue,
  stringToDate,
  timeAgo,
} from "services";
import {
  formatNumberToMoney,
  formatNumberToMoneyWithCurrencySymbol,
  parseMoneyToNumber,
} from "services/money.service";
import {
  dateTimeRequest,
  dateToTimeAgo,
  filterRequest,
  selectToObjectRequest,
} from "./common.mapper";

export const discountListResponse = {
  discountId: { key: "discountId" },
  name: { key: "discountName" },
  value: {
    transform: ({ src }) => {
      if (src.discountType === DiscountType.Fixed) {
        return `${formatNumberToMoneyWithCurrencySymbol(src.discountValue)} off`;
      }
      return `${src.discountValue}%`;
    },
  },
  status: {
    transform: ({ src }) => {
      const { startDate, startTime, endDate, endTime, availableAnytime, sellableInPos } = src;
      const format = DateTime.F;
      const now = moment();

      if (!sellableInPos) {
        return DiscountStatus.Inactive;
      }

      let sDateTime = null;
      let eDateTime = null;

      if (availableAnytime) {
        sDateTime = moment(startDate, format).startOf("day");
        eDateTime = moment(endDate, format).endOf("day");
      }

      if (endDate) {
        sDateTime = moment(`${startDate} ${startTime ? startTime : "00:00:00"}`, format);
        eDateTime = moment(`${endDate} ${endTime ? endTime : "23:59:00"}`, format);
        if (now.isAfter(eDateTime)) {
          return DiscountStatus.Expired;
        }
      } else {
        sDateTime = moment(`${startDate} ${startTime ? startTime : "00:00:00"}`, format);
        eDateTime = moment().endOf("day");
      }

      if (now.isBetween(sDateTime, eDateTime)) {
        return DiscountStatus.Active;
      }
      return DiscountStatus.Inactive;
    },
  },
  startDate: {
    transform: ({ self }) => {
      return formatDate(self, DateTime.C);
    },
  },
  startTime: {
    transform: ({ self }) => {
      return formatDate(stringToDate(self, DateTime.E), DateTime.D);
    },
  },
  endDate: {
    transform: ({ self }) => {
      return formatDate(self, DateTime.C);
    },
  },
  endTime: {
    transform: ({ self }) => {
      return formatDate(stringToDate(self, DateTime.E), DateTime.D);
    },
  },
  dateCreated: {
    transform: ({ src }) => {
      return timeAgo(src.createdDate, true);
    },
  },
  onPos: { key: "sellableInPos" },
};

export const discountListFilterRequest = {
  ...filterRequest,
};

export const discountReportListResponse = {
  isDiscountDeleted: { key: "isDiscountDeleted" },
  discountId: { key: "discountId" },
  name: { key: "discountName" },
  sales: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.sale);
    },
  },
  discountAmount: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.discountValue);
    },
  },
  used: {
    transform: ({ src }) => {
      return src.use;
    },
  },
};

export const discountDetailReportListResponse = {
  productId: { key: "productId" },
  dateAndTime: dateToTimeAgo("dateAndTime"),
  transactionId: { key: "transactionId" },
  product: { key: "productName" },
  grossProfit: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  sales: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  supplyValue: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  margin: {
    transform: ({ self }) => {
      return `${self}%`;
    },
  },
  discountAmount: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  discountValue: { key: "discountValue" },
  guestName: {
    transform: ({ src }) => {
      const name = formatName(src.guestFirstName, src.guestLastName).trim();
      return name || src.guestTagUid;
    },
  },
  isGuestTagUid: {
    transform: ({ src }) => {
      const name = formatName(src.guestFirstName, src.guestLastName).trim();
      return !Boolean(name);
    },
  },
  staffName: {
    transform: ({ src }) => {
      return formatName(src.staffFirstName, src.staffLastName);
    },
  },
  device: { key: "setDeviceName" },
  productSku: { key: "productSku" },
  locationName: { key: "locationName" },
};

export const discountReportListRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  discountId: { key: "discount" },
  locationIds: selectToObjectRequest("locations"),
  orders: {
    transform: ({ src }) => {
      if (src.sort.key === "dateAndTime") {
        return { deviceDateAndTime: src.sort.value };
      }
      return { [`${src.sort.key}`]: src.sort.value };
    },
  },
};

export const discountWithTransactionFilterRequest = {
  venueId: { key: "venueId" },
  ...dateTimeRequest,
};

export const discountWithTransactionFilterResponse = {
  id: { key: "discountId" },
  name: { key: "discountName" },
};

export const discountFormRequest = {
  venueId: { key: "venueId" },
  discountId: { key: "id" },
  discountName: { key: "name" },
  discountType: { key: "type" },
  discountValue: {
    transform: ({ src }) => {
      return parseMoneyToNumber(src.value).value;
    },
  },
  description: { key: "description" },
  sellableInPos: { key: "posStatus" },
  availableAnytime: { key: "availableAnytime" },
  locationIds: {
    transform: ({ src }) => {
      return mapSelectObjectsToValue(src.locations);
    },
  },
  productSkuIds: { key: "productSkuIds" },
  startDate: {
    transform: ({ src }) => {
      return src.dateRange[0].format("MM/DD/YYYY");
    },
  },
  authRequired: { key: "managerAuthorization" },
  startTime: {
    transform: ({ src }) => {
      if (src.availableAnytime) {
        return null;
      }
      return src.dateRange[0].format("HH:mm:ss");
    },
  },
  endDate: {
    transform: ({ src }) => {
      if (src.noEndDate) {
        return null;
      }
      return src.dateRange[1].format("MM/DD/YYYY");
    },
  },
  endTime: {
    transform: ({ src }) => {
      if (src.availableAnytime) {
        return null;
      }
      return src.dateRange[1].format("HH:mm:ss");
    },
  },
};

export const discountFormResponse = {
  id: { key: "discountId" },
  name: { key: "discountName" },
  description: { key: "description" },
  posStatus: { key: "sellableInPos" },
  type: { key: "discountType" },
  value: {
    transform: ({ src }) => {
      if (src.discountType === DiscountType.Fixed) {
        return formatNumberToMoney(src.discountValue);
      }
      return src.discountValue;
    },
  },
  availableAnytime: { key: "availableAnytime" },
  managerAuthorization: { key: "authRequired" },
  dateRange: {
    transform: ({ src }) => {
      if (src.availableAnytime) {
        if (src.endDate) {
          return [moment(src.startDate).startOf("day"), moment(src.endDate).endOf("day")];
        }
        return [moment(src.startDate).startOf("day"), moment().endOf("day")];
      }

      return [
        moment(`${src.startDate} ${src.startTime}`, "MM/DD/YYYY HH:mm:ss"),
        src.endDate
          ? moment(`${src.endDate} ${src.endTime}`, "MM/DD/YYYY HH:mm:ss")
          : moment(src.endTime, "HH:mm:ss"),
      ];
    },
  },
  locations: {
    transform: ({ self }) => {
      return mapObjectsToSelect(self, { textKey: "locationName", valueKey: "locationId" });
    },
  },
  discountProductSkus: {
    transform: ({ self }) => {
      return mapObjects(self, {
        id: { key: "productId" },
        name: { key: "productName" },
        productSku: {
          transform: ({ self }) => {
            return mapObject(self, { id: self.productSkuId });
          },
        },
      });
    },
  },
  productSkuIds: {
    transform: ({ src }) => {
      return src.discountProductSkus.map((dps) => {
        return dps.productSku.productSkuId;
      });
    },
  },
  noEndDate: {
    transform: ({ src }) => {
      return src.endDate === null;
    },
  },
};
