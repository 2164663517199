import lang from "translations";

export const categoryDetailReportColumn = ({
  grossProfit,
  sale,
  supplyValue,
  margin,
  discountApplied,
  voucherApplied,
  guestName,
  staffName,
  location,
}) => {
  let columns = [
    {
      key: "dateAndTime",
      text: lang.dateAndTime,
      classNames: "whitespace-nowrap",
      custom: true,
      sort: true,
    },
    {
      key: "transaction",
      text: lang.transaction,
      custom: true,
    },
    {
      key: "product",
      text: lang.product,
      custom: true,
    },
  ];

  if (grossProfit)
    columns.push({
      key: "grossProfit",
      text: lang.grossProfit,
      sort: true,
      align: "right",
      classNames: "whitespace-nowrap",
    });
  if (sale)
    columns.push({
      key: "sale",
      text: lang.sales,
      sort: true,
      align: "right",
      classNames: "whitespace-nowrap",
    });
  if (supplyValue) columns.push({ key: "supplyValue", text: lang.supplyValue, align: "right" });
  if (margin) columns.push({ key: "margin", text: lang.margin, align: "right" });
  if (discountApplied)
    columns.push({
      key: "discountApplied",
      text: lang.discountApplied,
      align: "right",
    });
  if (voucherApplied) columns.push({ key: "voucherApplied", text: lang.voucherApplied });
  if (guestName) columns.push({ key: "guestName", text: lang.guestName });
  if (staffName) columns.push({ key: "staffName", text: lang.staffName });
  if (location) columns.push({ key: "location", text: lang.location });

  return columns;
};
