import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import {
  ShiftList,
  TransactionList,
  ViewTransaction,
  ViewShift,
  EndOfDayReportList,
} from "modules";
import ViewEndOfDay from "modules/end-of-day-report/view-end-of-day/view-end-of-day.module";

const TransactionPage = () => {
  return (
    <Switch>
      <Route exact path={Path.END_OF_DAY_REPORT} component={EndOfDayReportList} />
      <Route exact path={Path.VIEW_OF_DAY_REPORT_ID} component={ViewEndOfDay} />
      <Route exact path={Path.REGISTER_REPORT} component={ShiftList} />
      <Route exact path={Path.TRANSACTION} component={TransactionList} />
      <Route exact path={Path.VIEW_REGISTER_REPORT} component={ViewShift} />
      <Route exact path={Path.VIEW_TRANSACTION} component={ViewTransaction} />
    </Switch>
  );
};

export default TransactionPage;
