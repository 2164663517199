import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { LocationList, CreateLocation, EditLocation, DeleteLocation } from "modules";
import { useMount } from "hooks";
import { setPageTitle } from "services";

const LocationPage = () => {
  useMount(() => {
    setPageTitle("locations");
  });
  return (
    <Switch>
      <Route exact path={Path.LOCATION_CREATE} component={CreateLocation} />
      <Route exact path={Path.LOCATION_DELETE} component={DeleteLocation} />
      <Route exact path={Path.LOCATION_EDIT} component={EditLocation} />
      <Route exact path={Path.LOCATION} component={LocationList} />
    </Switch>
  );
};

export default LocationPage;
