import React, { useContext, useMemo } from "react";
import { Dropdown, Text, Avatar, Skeleton } from "..";
import { VenueContext, VenuesContext, ProfileContext } from "contexts";
import styles from "./user-nav.module.scss";
import classnames from "classnames";
import { redirectTo, reloadPage } from "services";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "hooks";
import { Path } from "paths";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";
import { openInNewTab } from "services/url.service";

const UserNav = () => {
  const { venue = {}, loading: venueLoading } = useContext(VenueContext) || {};
  const { profile, loading } = useContext(ProfileContext) || {};
  const { venues = [] } = useContext(VenuesContext) || {};
  const { firstName, lastName, fullName, email, avatar: profileAvatar } = profile || {};
  const { venueName } = venue || {};
  const { history } = useRouter();
  const { venueId } = venue;
  const { _language } = lang;

  const avatar = {
    className: "m-auto",
    phrases: [firstName, lastName],
    loading,
    image: profileAvatar,
  };

  const yourVenues = useMemo(() => {
    return venues.filter(({ venueId: vId }) => {
      return venueId !== vId;
    });
  }, [venues, venueId]);

  if (!profile) {
    return null;
  }

  return (
    <Dropdown
      trigger="click"
      id="user-nav"
      placement="bottomRight"
      className={classnames(styles.container, "h-full mr-lg")}
      options={[
        {
          value: "0",
          text: (
            <div className="w-72">
              <div className={classnames(styles.borderBottom, "p-lg text-center")}>
                <div className="text-center mb-md">
                  <Avatar {...avatar} size={14} width="w-14" height="h-14" />
                </div>
                <div>
                  <Text color="text-gray-600" size="text-md" strong className="whitespace-normal">
                    {fullName}
                  </Text>
                </div>
                <div>
                  <Text sm className="whitespace-normal">
                    {email}
                  </Text>
                </div>
              </div>
              {yourVenues.length ? (
                <div className={classnames(styles.borderBottom)}>
                  <Text
                    color="text-gray-300"
                    size="text-xs"
                    strong
                    uppercase
                    className="py-sm px-md"
                  >
                    {lang.yourVenues}
                  </Text>
                  <div className={"max-h-52 overflow-y-auto"}>
                    {yourVenues.map((v) => {
                      return (
                        <div
                          key={uuidv4()}
                          className="py-sm px-md hover:bg-gray-lightest"
                          onClick={() => {
                            mixpanel.track(TrackEvent.ClickedButton, {
                              button: "Switch Venue",
                            });
                            const { venueId, currencyCode, currencySymbol } = v;
                            localStorage.setItem("venueId", venueId);
                            localStorage.setItem("currencyCode", currencyCode);
                            localStorage.setItem("currencySymbol", currencySymbol);
                            reloadPage();
                            redirectTo("/");
                          }}
                        >
                          <Text color="text-gray-600">{v.venueName}</Text>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              <div className={classnames(styles.borderBottom)}>
                <div
                  className="py-md px-md hover:bg-gray-lightest"
                  onClick={() => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      button: lang.orderYourNFCTags,
                    });
                    openInNewTab("https://pages.pouchnation.com/formfactors");
                  }}
                >
                  <Text color="text-gray-600">{lang.orderYourNFCTags}</Text>
                </div>
              </div>

              <div>
                <div
                  className="py-sm px-md hover:bg-gray-lightest"
                  onClick={() => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      button: lang.accountSettings,
                    });
                    history.push(Path.ACCOUNT_SETTING);
                  }}
                >
                  <Text color="text-gray-600">{lang.accountSettings}</Text>
                </div>
                <div
                  className="py-sm px-md hover:bg-gray-lightest"
                  onClick={() => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      button: lang.logout,
                    });
                    mixpanel.reset();
                    localStorage.clear();
                    localStorage.setItem("locale", _language);
                    reloadPage();
                    redirectTo(Path.AUTH)
                  }}
                >
                  <Text color="text-gray-600">{lang.logout}</Text>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    >
      <div className="flex items-center h-full px-lg">
        <div className="mr-sm">
          {venueLoading ? (
            <Skeleton single />
          ) : (
            <Text color="text-pelorous-darker" fontWeight="font-semibold" size="text-sm">
              {venueName}
            </Text>
          )}
        </div>
        <div>
          <Avatar {...avatar} textSize="text-md" />
        </div>
      </div>
    </Dropdown>
  );
};

export default UserNav;
