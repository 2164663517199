import { Post, Get } from "services";
import { ApiPath } from "paths";

export const searchEndOfDayReports = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_END_OF_DAY_REPORT}`, body);
  return res;
};

export const runEndOfDay = async ({ eodId, body }) => {
  const res = await Post(`${ApiPath.RUN_END_OF_DAY(eodId)}`, body);
  return res;
};

export const getEndOfDayReport = async ({ eodId }) => {
  const res = await Get(`${ApiPath.END_OF_DAY_ID(eodId)}`);
  return res;
};

export const updateIncludedShift = async ({ eodId, shiftId, body }) => {
  const res = await Post(`${ApiPath.EOD_UPDATE_INCLUDED_SHIFT({ eodId, shiftId })}`, body);
  return res;
};

export const updateAllIgnoredShifts = async ({ eodReportId, venueId, body }) => {
  const res = await Post(`${ApiPath.END_OF_DAY_ID_INCLUDED_VENUE({ eodReportId, venueId })}`, body);
  return res;
};
