const staffPaths = {
  STAFF: "",
  CHECK_STAFF: "check",
  SEARCH_STAFF: "search",
  INVITE_STAFF: "invite",
  STAFF_ID: (id) => id,
  STAFF_RESEND_INVITE: "resend-invite",
  STAFF_VALIDATE_TOKEN: "validate-token",
};

export default staffPaths;
