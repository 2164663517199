import { DeleteModal } from "components/modals";
import React from "react";
import lang from "translations";
import { deleteTax } from "apis/tax.api";
import { useApi } from "hooks";
import { Title, Toast } from "components/commons";
import { mixpanel, TrackEvent } from "mixpanel";

const DeleteTaxModal = ({ name, active, close, id, goToTax }) => {
  const { request, loading } = useApi({
    api: deleteTax,
    params: {
      taxId: id,
    },
  });
  return (
    <DeleteModal
      deleting={loading}
      customHeader={
        <Title lg className="pt-md mb-md">
          {lang.populate(lang.deleteName, [name])}
        </Title>
      }
      active={active}
      customContent={lang.populate(lang.youAreAboutToDeleteTax, [name])}
      close={close}
      primaryText={lang.deleteTax}
      ok={async () => {
        mixpanel.track(TrackEvent.ClickedButton, {
          Button: lang.deleteTax,
          Page: lang.taxSettings,
        });

        await request();
        Toast({
          content: lang.populate(lang.deletedTax, [name]),
          success: true,
          icon: "check",
        }).open();
        close();
        goToTax();
      }}
    />
  );
};

export default DeleteTaxModal;
