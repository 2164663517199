import { Panel, Title, Text } from "components/commons";
import React from "react";
import lang from "translations";

const BookingSummary = ({ totalBookings, toBeCheckedInCount, toBeCheckedOutCount }) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <Panel>
        <Title>{lang.totalBookings}</Title>
        <Text size="text-lg" className="mt-sm">
          {totalBookings || 0}
        </Text>
      </Panel>
      <Panel>
        <Title>{lang.toBeCheckedIn}</Title>
        <Text size="text-lg" className="mt-sm">
          {toBeCheckedInCount || 0}
        </Text>
      </Panel>
      <Panel>
        <Title>{lang.toBeCheckedOut}</Title>
        <Text size="text-lg" className="mt-sm">
          {toBeCheckedOutCount || 0}
        </Text>
      </Panel>
    </div>
  );
};

export default BookingSummary;
