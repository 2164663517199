import React, { useContext, useMemo } from "react";
import { VenueContext } from "contexts";
import lang from "translations";
import { useApi, useForm, useRouter } from "hooks";
import { editGuestNote } from "apis";
import { ActionButton, Modal, TextArea } from "components/commons";
import { Field as FieldType } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";

const EditNoteModal = ({ noteId, noteContent, show, close, active, setNotesList, notesList }) => {
  const { venue } = useContext(VenueContext);
  const { query } = useRouter();
  const { id } = query;
  const { venueId } = venue;

  const { request: editNoteRequest, loading } = useApi({
    api: editGuestNote,
    params: {
      venueId,
      guestId: id,
    },
  });

  const initialState = useMemo(() => {
    return {
      note: {
        name: "note",
        value: noteContent,
        type: FieldType.INPUT,
        dirty: false,
      },
    };
  }, [noteContent]);

  const { fields, modifyField, getFormValues } = useForm({
    initialState,
  });

  const editNote = async () => {
    const params = getFormValues();
    let noteState = notesList.filter((note) => note.noteId === noteId).pop();
    const newNotesList = notesList.filter((note) => note.noteId !== noteId);
    noteState = {
      ...noteState,
      sending: true,
      failed: false,
      note: params.note,
      noteUpdatedDate: Date.now(),
    };
    setNotesList([noteState, ...newNotesList]);
    try {
      await editNoteRequest({ noteId, note: params.note });
      noteState.sending = false;
    } catch (error) {
    } finally {
      setNotesList([noteState, ...newNotesList]);
    }
  };

  return (
    <Modal
      title={lang.editNote}
      active={active}
      close={close}
      actionContent={
        <ActionButton
          loading={loading}
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              close();
            },
          }}
          primary={{
            text: lang.editNote,
            disabled: !fields.note.dirty,
            onClick: () => {
              mixpanel.track(TrackEvent.ClickedButton, {
                Button: lang.editNote,
                Page: lang.guestDetails,
              });
              editNote();
              close();
            },
          }}
        />
      }
    >
      <TextArea
        {...fields.note}
        placeholder={lang.addANote}
        textarea
        onChange={modifyField}
        maxLength={255}
      />
    </Modal>
  );
};

export default EditNoteModal;
