import { Get, Post } from "services";
import { ApiPath } from "paths";

export const getGuestCheckin = async ({ guestId, venueId, checkinId }) => {
  const res = await Get(`${ApiPath.GUEST_CHECKIN({ guestId, venueId, checkinId: checkinId })}`);
  return res;
};

export const forceCheckout = async ({ checkinId, ...body }) => {
  const res = await Post(`${ApiPath.FORCE_CHECKOUT({ checkinId })}`, body);
  return res;
};

export const exportBillingStatement = async ({ checkinId, ...body }) => {
  const res = await Post(`${ApiPath.EXPORT_BILLING_STATEMENT({ checkinId })}`, body);
  return res;
};
