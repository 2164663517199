import { getLocations, searchStaff } from "apis";
import { Pill, Text, Image, Container, ButtonLink } from "components/commons";
import DataTable from "components/commons/data-table/data-table";
import { HeaderA } from "components/headers";
import { VenueContext } from "contexts";
import { PillType } from "enums";
import {
  useApi,
  useFilter,
  useMount,
  // useRouter
} from "hooks";
import { staffListResponse, staffListFilterRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { mapObject, mapObjects } from "services";
import columns from "./columns";
import lang from "translations";
import { Path } from "paths";
import { SearchingOctopus } from "images";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";

const StaffList = () => {
  const { venue } = useContext(VenueContext);
  // const { history } = useRouter();

  const {
    error,
    request: searchStaffRequest,
    loading: loadingStaff,
    result: searchStaffResult = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: searchStaff,
    handleOwnError: true,
  });
  const {
    request: getLocationsRequest,
    loading: loadingLocations,
    result: getLocationsResult = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: getLocations,
    params: { venueId: venue.venueId },
    handleOwnError: true,
  });

  const { modifyFilters, filterState, requestState } = useFilter({
    venueId: venue.venueId,
    page: 1,
    pageSize: 20,
  });

  useMount(() => {
    fetchLocations();
    fetchStaffs(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.staffList,
    });
  });

  const fetchLocations = useCallback(async () => {
    await getLocationsRequest();
  }, [getLocationsRequest]);

  const fetchStaffs = useCallback(
    (requestState) => {
      searchStaffRequest(mapObject(requestState, staffListFilterRequest));
    },
    [searchStaffRequest]
  );

  const renderStaff = useCallback((name, status, id) => {
    return (
      <>
        <div
        // className="cursor-pointer"
        // onClick={() => {
        //   history.push(Path.STAFF_ID(id));
        // }}
        >
          <ButtonLink className="" path={Path.STAFF_ID(id)}>
            <Text className="text-pelorous">{name}</Text>
          </ButtonLink>
        </div>
        <div>
          {status && (
            <Pill className="mt-sm" type={PillType.Italic} size="text-xs">
              {status}
            </Pill>
          )}
        </div>
      </>
    );
  }, []);

  const renderTagUids = useCallback((tagUids) => {
    const tagUidsArr = [...tagUids];

    if (tagUidsArr.length === 0) {
      return <Text italic>No tag yet</Text>;
    }

    return (
      <div>
        {tagUidsArr.map((tagUid, i) => (
          <Text key={i} tagUid>
            {tagUid}
          </Text>
        ))}
      </div>
    );
  }, []);

  const renderRoles = useCallback((roles) => {
    return (
      <div>
        {roles.map((role, i) => (
          <Text key={i}>{role}</Text>
        ))}
      </div>
    );
  }, []);

  const renderLocations = useCallback((locations) => {
    return (
      <div>
        {locations.map((location, i) => (
          <Text key={i}>{location}</Text>
        ))}
      </div>
    );
  }, []);

  const prepareStaffList = useCallback(() => {
    const mappedStaffs = mapObjects(
      searchStaffResult.data,
      staffListResponse({ locations: getLocationsResult.data })
    );

    return mappedStaffs.map((staff) => {
      const { name, status, tagUids, roles, locations, id } = staff;
      return {
        staff: renderStaff(name, status, id),
        tagId: renderTagUids(tagUids),
        role: renderRoles(roles),
        location: renderLocations(locations),
      };
    });
  }, [
    getLocationsResult.data,
    searchStaffResult.data,
    renderStaff,
    renderTagUids,
    renderRoles,
    renderLocations,
  ]);

  const staffs = useMemo(() => {
    return prepareStaffList();
  }, [prepareStaffList]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.staff}
        description={lang.inviteYourVenueStaff}
        button={{
          path: Path.STAFF_CREATE,
          text: lang.inviteNewStaff,
        }}
        className="mb-md"
      />
      {!staffs.length && !loadingStaff ? (
        <Container className="bg-white text-center min-h-page flex items-center border rounded">
          <div className="m-auto">
            <Image src={SearchingOctopus} className="w-3/5 mx-auto my-lg" />
            <Text size="text-md mb-lg text-gray-500">{lang.createStaffWhoCan}</Text>
          </div>
        </Container>
      ) : (
        <DataTable
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchStaffs}
          total={searchStaffResult.metadata.total}
          loading={loadingStaff || loadingLocations}
          columns={columns}
          data={staffs}
          error={error}
          minWidth="1000px"
          renderEmpty={{
            image: SearchingOctopus,
            title: lang.createStaffWhoCan,
          }}
        />
      )}
    </ModuleWrapper>
  );
};

export default StaffList;
