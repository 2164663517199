import React from "react";
import HeaderB from "./header-b";

const HeaderC = ({ action, ...props }) => {
  return (
    <div className="flex justify-between">
      <div>
        <HeaderB {...props} />
      </div>
      <div>{action}</div>
    </div>
  );
};

export default HeaderC;
