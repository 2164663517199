import React from "react";
import { TimePicker as AntTimePicker } from "antd";
import classnames from "classnames";
import styles from "./time-picker.module.scss";

const TimePicker = ({
  className,
  placeholder = "Set Time",
  value,
  militaryFormat = false,
  format = "hh:mm A",
  onChange,
  disabled,
  error = false,
}) => {
  return (
    <AntTimePicker
      disabled={disabled}
      className={classnames(className, styles.timePicker, { "border-red text-red": error })}
      placeholder={placeholder}
      value={value}
      use12Hours={!militaryFormat}
      format={format}
      inputReadOnly
      allowClear={false}
      showNow={false}
      autoComplete="true"
      onSelect={(v) => {
        if (onChange) {
          onChange(v);
        }
      }}
    />
  );
};

export default TimePicker;
