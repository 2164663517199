import { formatNumberToMoneyCurrency } from "services/money.service";

export const staffProfileResponse = {
  _keys: ["description", "venueId"],
  id: {
    key: "staffProfileId",
  },
  name: {
    key: "profileName",
  },
  freeCredit: {
    transform: ({ src }) => {
      return formatNumberToMoneyCurrency(src.freeCredit);
    },
  },
  staffProfileVouchers: {
    transform: ({ src }) => {
      if (src.staffProfileVouchers) {
        return src.staffProfileVouchers.map((gp) => {
          return `${gp.quantity} x ${gp.voucherName || "-"}`;
        });
      }
      return [];
    },
  },
  showMore: {
    transform: ({ src }) => {
      if (src.staffProfileVouchers) {
        return src.staffProfileVouchers.length > 2;
      }
      return false;
    },
  },
};

export const staffProfileRequest = {
  _keys: ["description", "venueId", "freeCredit"],
  staffProfileId: { key: "id" },
  profileName: {
    key: "name",
  },
  staffProfileVouchers: {
    transform: ({ self }) => {
      return self.map((voucher) => {
        return {
          voucherId: voucher.value,
          quantity: voucher.quantity,
        };
      });
    },
  },
};

export const staffProfile = {
  _keys: ["description", "venueId"],
  id: {
    key: "staffProfileId",
  },
  name: {
    key: "profileName",
  },
  freeCredit: {
    transform: ({ src }) => {
      return formatNumberToMoneyCurrency(src.freeCredit);
    },
  },
  staffProfileVouchers: {
    transform: ({ src }) => {
      return src.staffProfileVouchers.map((voucher) => {
        return {
          name: voucher.voucherName,
          quantity: voucher.quantity,
          value: voucher.voucherId,
        };
      });
    },
  },
};
