import React, { useCallback } from "react";
import { Button } from "components/commons";
import { StyleType } from "enums";
import { setGlobalDateTimeFilter } from "services";
import classNames from "classnames";
import { mixpanel, TrackEvent } from "mixpanel";
import lang from "translations";

const ReportFilter = ({ children, onClear, onApply, dateRange, span = 4, disabled }) => {
  const onApplyCb = useCallback(() => {
    onApply();
  }, [onApply]);

  return (
    <div className="w-full bg-white p-md mb-lg border border-gray-100 rounded lg:grid grid-cols-12 gap-4 items-bottom">
      {children}
      <div className={classNames(`justify-end items-end mb-xs xs:flex col-span-${span}`)}>
        <div className="text-right">
          <Button
            type={StyleType.Link}
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                button: lang.clearAllFilters,
              });
              onClear();
            }}
          >
            {lang.clearAllFilters}
          </Button>
        </div>
        <div className="text-right">
          <Button
            disabled={disabled}
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                button: lang.applyFilter,
              });
              onApplyCb();
              if (dateRange) {
                setGlobalDateTimeFilter(dateRange);
              }
            }}
          >
            {lang.applyFilter}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;
