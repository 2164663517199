import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { HeaderB } from "components/headers";
import {
  DataTable,
  Panel,
  SimpleDropdown,
  TabBar,
  Text,
  Title,
  ButtonLink,
  Loader,
} from "components/commons";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import { Path } from "paths";
import { StyleType } from "enums";
import { getSupplyItem } from "apis/supply-item.api";
import { supplyItemSummary } from "mappers/supply-item.mapper";
import { ModuleWrapper } from "components/fragments";
import SupplyItemSummarySkeleton from "./supply-item-summary-skeleton";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { getLocations, searchLocation, editProductLocationStock } from "apis";
import { location } from "mappers";
import { VenueContext } from "contexts";
import DeleteSupplyItem from "../delete-supply-item/delete-supply-item.module";
import { StockAdjustmentPopover } from "components/popovers";
import { mixpanel, TrackEvent } from "mixpanel";

const SupplyItemSummary = () => {
  const { history, query } = useRouter();
  const { id } = query;
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const deleteModal = useModal();
  const labels = [
    lang.sku,
    lang.totalInStock,
    lang.parLevel,
    lang.reorderPoint,
    lang.inventoryValue,
  ];

  const {
    request,
    loading = true,
    mappedData,
    error,
  } = useApi({
    api: getSupplyItem,
    params: {
      id,
    },
    handleOwnError: true,
    mapper: supplyItemSummary,
  });

  const {
    request: getLocationsRequest,
    loading: loadingLocation,
    mappedData: locations,
    error: errorGetLocation,
  } = useApi({
    api: getLocations,
    params: {
      venueId,
    },
    isArray: true,
    mapper: location,
    handleOwnError: true,
  });

  const {
    request: requestLocation,
    result,
    mappedData: searchedLocations,
    loading: searching,
    error: errorLocation,
  } = useApi({
    api: searchLocation,
    handleOwnError: true,
    isArray: true,
    mapper: {
      id: {
        key: "locationId",
      },
      name: {
        key: "locationName",
      },
      status: {
        key: "status",
      },
      deleted: {
        key: "deleted",
      },
    },
  });

  const { filterState, requestState, modifyFilters } = useFilter({
    page: 1,
    pageSize: 10,
    venueId,
  });

  useMount(() => {
    fetchSupplyItem();
    getLocationsRequest();
    fetchLocations(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.supplyItemSummaryPage,
    });
  });

  const fetchLocations = useCallback(
    async (requestState) => {
      await requestLocation(requestState);
    },
    [requestLocation]
  );

  const fetchSupplyItem = useCallback(async () => {
    await request();
  }, [request]);

  const formatWithMeasurement = (item) => {
    return item + " " + mappedData.measurement;
  };

  const { request: editProductLocationStockRequest, loading: loadingStock } = useApi({
    api: editProductLocationStock,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitNewStock = useCallback(
    async (params) => {
      try {
        await editProductLocationStockRequest(params);
        fetchSupplyItem();
      } catch (error) {
        console.log(error);
      }
    },
    [editProductLocationStockRequest, fetchSupplyItem]
  );

  const inStockAdjustment = (location) => {
    return (
      <StockAdjustmentPopover product={mappedData} location={location} onSubmit={submitNewStock}>
        <ButtonLink>{formatWithMeasurement(location.stock || 0)}</ButtonLink>
      </StockAdjustmentPopover>
    );
  };

  const renderTotalStocksPerLocation = useMemo(() => {
    const locationStocks = mappedData.locationStocks ? mappedData.locationStocks : [];
    const locationStocksId = mappedData.locationStocks
      ? mappedData.locationStocks.map((l) => l.locationId)
      : [];
    searchedLocations.forEach((location) => {
      if (!loading) {
        if (locationStocksId.includes(location.id)) {
          const v = locationStocks.find((v) => v.locationId === location.id);
          location["stock"] = v.stock;
          location["parLevel"] = v.parLevel;
          location["reorderPoint"] = v.reorderPoint;
          location["inventoryValue"] = v.inventoryValue;
        } else {
          location["stock"] = 0;
          location["parLevel"] = 0;
          location["reorderPoint"] = 0;
          location["inventoryValue"] = 0;
        }
      }
    });

    return searchedLocations
      ? searchedLocations.map((v) => {
          return {
            location: v.name,
            inStock: inStockAdjustment(v),
            // inStock: formatWithMeasurement(v.stock || 0),
            parLevel: formatWithMeasurement(v.parLevel || 0),
            reorderPoint: formatWithMeasurement(v.reorderPoint || 0),
            inventoryValue: formatNumberToMoneyWithCurrencySymbol(v.inventoryValue || 0),
          };
        })
      : [];
    // eslint-disable-next-line
  }, [mappedData, loadingLocation, locations, loading, searchedLocations]);

  return !loading ? (
    <ModuleWrapper
      error={error}
      header={
        <>
          <HeaderB
            title={mappedData.name || "--"}
            description={mappedData.description || ""}
            returnText={lang.supplyItems}
            onClick={() => history.push(Path.INVENTORY_SUPPLY_ITEM)}
            button={{
              type: StyleType.Link,
              text: (
                <SimpleDropdown
                  trigger="click"
                  classname="cursor-pointer border rounded border-solid border-1 border-gray-200 flex align-center 
                      p-sm bg-white justify-center w-24 -m-md"
                  placement="bottomRight"
                  color="text-blue"
                  text={<Text className="text-blue">{lang.actions}</Text>}
                  options={[
                    {
                      value: 0,
                      text: <Text>{lang.editSupplyItem}</Text>,
                      onClick: () => {
                        history.push(Path.EDIT_SUPPLY_ITEM(id));
                      },
                    },
                    {
                      value: 1,
                      text: <Text className="text-red">{lang.deleteSupplyItem}</Text>,
                      onClick: () => {
                        deleteModal.show();
                      },
                    },
                  ]}
                ></SimpleDropdown>
              ),
            }}
          />
          <TabBar
            className="mb-lg"
            items={[
              { to: Path.SUPPLY_ITEM_ID(id), text: lang.summary },
              { to: Path.SUPPLY_ITEM_MOVEMENT_NAV(id), text: lang.inventoryMovement },
            ]}
          />
        </>
      }
    >
      <Loader loading={loadingStock} />
      <Panel paddingless>
        <div className="grid grid-flow-col grid-cols-5 grid-rows-1 px-lg pt-sm gap-4">
          {labels.map((label, index) => {
            return (
              <>
                <div key={index}>
                  <Text label className="mt-md font-bold">
                    {label}
                  </Text>
                </div>
              </>
            );
          })}
        </div>
        <div className="grid grid-flow-col grid-cols-5 grid-rows-1 px-lg pb-md gap-4">
          <>
            <div>
              <Text size="text-lg sm:text-xxl" className="font-medium break-all">
                {mappedData.sku}
              </Text>
            </div>
            <div>
              <Text size="text-lg sm:text-xxl" className="font-medium break-all">
                {formatWithMeasurement(mappedData.inStock)}
              </Text>
            </div>
            <div>
              <Text size="text-lg sm:text-xxl" className="font-medium break-all">
                {formatWithMeasurement(mappedData.parLevel)}
              </Text>
            </div>
            <div>
              <Text size="text-lg sm:text-xxl" className="font-medium break-all">
                {formatWithMeasurement(mappedData.reorderPoint)}
              </Text>
            </div>
            <div>
              <Text size="text-lg sm:text-xxl" className="font-medium break-all">
                {mappedData.inventoryValue}
              </Text>
            </div>
          </>
        </div>
      </Panel>

      <Title className="font-bold bg-white p-md rounded-t-lg">{lang.totalStocksPerLocation}</Title>
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchLocations}
        total={result ? result.metadata.total : 0}
        loading={searching}
        breakAll
        showHeader
        columns={[
          {
            text: (
              <Text md className="mb-sm text-gray capitalize font-bold">
                {lang.location}
              </Text>
            ),
            key: "location",
            headerClassName: "pl-lg",
            className: "py-4 px-4 border-t border-gray-200",
            width: "w-5/12",
          },
          {
            text: (
              <Text md className="mb-sm text-gray capitalize font-bold">
                {lang.inStock}
              </Text>
            ),
            key: "inStock",
            headerClassName: "px-4 text-right",
            className: "py-4 px-4 border-t border-gray-200",
            align: "right",
          },
          {
            text: (
              <Text md className="mb-sm text-gray capitalize font-bold">
                {lang.parLevel}
              </Text>
            ),
            key: "parLevel",
            headerClassName: "px-4",
            className: "py-4 px-4 border-t border-gray-200",
            align: "right",
          },
          {
            text: (
              <Text md className="mb-sm text-gray capitalize font-bold">
                {lang.reorderPoint}
              </Text>
            ),
            key: "reorderPoint",
            headerClassName: "px-4",
            className: "py-4 px-4 border-t border-gray-200",
            align: "right",
          },
          {
            text: (
              <Text md className="mb-sm text-gray capitalize font-bold">
                {lang.inventoryValue}
              </Text>
            ),
            key: "inventoryValue",
            headerClassName: "px-6",
            className: "py-4 px-4 border-t border-gray-200",
            align: "right",
          },
        ]}
        data={renderTotalStocksPerLocation}
        error={error || errorLocation || errorGetLocation}
      />

      <DeleteSupplyItem id={id} {...deleteModal}></DeleteSupplyItem>
    </ModuleWrapper>
  ) : (
    <SupplyItemSummarySkeleton />
  );
};

export default SupplyItemSummary;
