import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    name = "",
    description = "",
    outletId,
    isPosAvailable = false,
    color = null,
  } = initialState;
  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [
        Validation.required(),
        Validation.reserveWord(["uncategorized", "uncategorised"]),
      ],
      required: true,
      maxLength: 75,
      label: lang.categoryName,
    },
    description: {
      name: "description",
      value: description,
      type: Field.INPUT,
      maxLength: 255,
      label: lang.description,
    },
    outletId: {
      name: "outletId",
      value: outletId,
      type: Field.DROPDOWN,
      label: lang.outlet,
    },
    isPosAvailable: {
      name: "isPosAvailable",
      value: isPosAvailable,
      type: Field.CHECKBOX,
    },
    color: {
      name: "color",
      value: color,
      type: Field.ANY,
    },
  };
};

export default initialFormState;
