import { Get, Post } from "services";
import { ApiPath } from "paths";

export const verifyEmail = async ({ email }) => {
  const res = await Get(`${ApiPath.CHECK_STAFF}`, { email });
  return res.data;
};

export const signIn = async ({ email, password }) => {
  const body = {
    clientId: "VENUE_DASHBOARD",
    grantType: "email",
    email,
    password,
  };
  const res = await Post(`${ApiPath.SIGN_IN}`, body);
  return res.data;
};
