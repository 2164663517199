import { useMemo, useEffect, useCallback } from "react";
import {
  Text,
  SimpleTable,
  Checkbox,
  Panel,
  Title,
  Icon,
  Field,
  Button,
  Container,
} from "components";
import { useForm } from "hooks";
import lang from "translations";
import styles from "./receipt-setting.module.scss";
import initialFormState from "./receipt-setting.form-state";
import { StyleType } from "enums";
import { prettifyReceiptOptions } from "services";

const ReceiptSettingForm = ({
  initialState,
  submit,
  onChange,
  setDirty,
  previewVisible,
  onChangeVisibility,
}) => {
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, getFormValues, submitForm, dirty } = useForm({
    initialState: formState,
  });

  const handleSubmit = useCallback(async () => {
    try {
      const data = formatData(getFormValues());
      await submit(data);
    } catch (e) {
      throw e;
    }
  }, [getFormValues, submit]);

  const formatData = (params) => {
    const res = [];
    params.headerSettings.forEach((p) => {
      res.push(p);
    });
    params.footerSettings.forEach((p) => {
      res.push(p);
    });
    params.salesSettings.forEach((p) => {
      res.push(p);
    });
    return res;
  };

  const submitFormValue = () => {
    submitForm(handleSubmit);
  };

  useEffect(() => {
    checkFieldsState();
    return onChange(getFormValues());
    // eslint-disable-next-line
  }, [fields]);

  const checkFieldsState = () => {
    if (dirty) {
      return setDirty(true);
    } else {
      return setDirty(false);
    }
  };

  const headerData = useMemo(() => {
    const keys = Object.keys(fields.headerSettings.value);
    return keys.map((key) => {
      const d = fields.headerSettings.value[key];
      return {
        name: (
          <Title md className="pl-1">
            {prettifyReceiptOptions(d.name)}
          </Title>
        ),
        isIncluded: (
          <div className="text-center ml-auto mr-0">
            <Field>
              <Checkbox
                {...fields.headerSettings.value[key].isIncluded}
                name="isIncluded"
                value={fields.headerSettings.value[key].isIncluded}
                className={"m-auto"}
                disabled={d.name === "VENUE_NAME" || d.name === "BUSINESS_NAME"}
                onChange={(name, value) => {
                  const newHeaderSettings = fields.headerSettings;
                  newHeaderSettings.value[key].isIncluded = value.value;
                  modifyField("headerSettings", newHeaderSettings);
                }}
              />
            </Field>
          </div>
        ),
      };
    });
  }, [fields.headerSettings, modifyField]);

  const footerData = useMemo(() => {
    const keys = Object.keys(fields.footerSettings.value);
    return keys.map((key) => {
      const d = fields.footerSettings.value[key];
      return {
        ...d,
        name: (
          <Title md className="pl-1">
            {prettifyReceiptOptions(d.name)}
          </Title>
        ),
        isIncluded: (
          <div className="text-center ml-auto mr-0">
            <Field>
              <Checkbox
                {...fields.footerSettings.value[key].isIncluded}
                name="isIncluded"
                value={fields.footerSettings.value[key].isIncluded}
                className={"m-auto"}
                onChange={(name, value) => {
                  const newFooterSettings = fields.footerSettings;
                  newFooterSettings.value[key].isIncluded = value.value;
                  modifyField("footerSettings", newFooterSettings);
                }}
              />
            </Field>
          </div>
        ),
      };
    });
  }, [fields.footerSettings, modifyField]);

  const salesData = useMemo(() => {
    const keys = Object.keys(fields.salesSettings.value);
    return keys.map((key) => {
      const d = fields.salesSettings.value[key];
      return {
        ...d,
        name: (
          <Title md className="pl-1">
            {prettifyReceiptOptions(d.name)}
          </Title>
        ),
        isIncluded: (
          <div className="text-center ml-auto mr-0">
            <Field>
              <Checkbox
                {...fields.salesSettings.value[key].isIncluded}
                name="isIncluded"
                value={fields.salesSettings.value[key].isIncluded}
                className={"m-auto"}
                onChange={(name, value) => {
                  const newSalesSettings = fields.salesSettings;
                  newSalesSettings.value[key][name] = value.value;
                  modifyField("salesSettings", newSalesSettings);
                }}
              />
            </Field>
          </div>
        ),
      };
    });
  }, [fields.salesSettings, modifyField]);

  return (
    <>
      <Panel>
        <div className="flex justify-between">
          <Title md className="mb-sm">
            {lang.formatting}
          </Title>
          {/* <ButtonLink size="text-sm">
            <Image
              className="ml-3"
              src={ManageBusiness}
              style={{ width: "20px", height: "20px" }}
            />
            <Text color="text-green-500">{lang.manageBusinessInfo}</Text>
            <Icon name="arrow-diagonal-right" className="text-green-500 text-xxs ml-xs" />
          </ButtonLink> */}
        </div>
        <Text color="" size="text-sm" className="">
          {lang.customizeSectionReceipt}
        </Text>
        <SimpleTable
          showHeader
          className="-mx-5 mt-sm"
          columns={[
            {
              text: (
                <Title md className="mb-sm text-gray capitalize">
                  {lang.headerSection}
                </Title>
              ),
              key: "name",
              className: styles.tableItem,
              headerClassName: "pl-lg",
            },
            {
              text: (
                <div className="text-center">
                  <Title md className="mb-sm ml-md text-gray capitalize">
                    {lang.include}
                  </Title>
                </div>
              ),
              key: "isIncluded",
              className: styles.tableItem,
            },
          ]}
          data={headerData}
        />
        <SimpleTable
          showHeader
          className="-mx-5 mt-sm"
          columns={[
            {
              text: (
                <Title md className="mb-sm text-gray capitalize">
                  {lang.footerSection}
                </Title>
              ),
              key: "name",
              className: styles.tableItem,
              headerClassName: "pl-lg",
            },
            {
              key: "isIncluded",
              className: styles.tableItem,
            },
          ]}
          data={footerData}
        />
      </Panel>
      <Panel>
        <Title md className="mb-sm">
          {lang.salesInformation}
        </Title>
        <Text color="" size="text-sm" className="">
          {lang.chooseInformation}
        </Text>
        <SimpleTable
          showHeader
          className="-mx-5 mt-sm"
          columns={[
            {
              key: "name",
              className: styles.tableItem,
              headerClassName: "pl-lg",
            },
            {
              key: "isIncluded",
              className: styles.tableItem,
            },
          ]}
          data={salesData}
        />
      </Panel>
      <Container className={"flex flex-row-reverse"} mb={0} paddingless={true}>
        <div className="flex align-center">
          <Button
            type={StyleType.Primary}
            onClick={() => {
              submitFormValue();
            }}
          >
            {lang.save}
          </Button>
        </div>
        {!previewVisible ? (
          <div className="flex align-center">
            <Button type={StyleType.Link}>
              <div
                className="flex items-center text-blue cursor-pointer"
                onClick={() => onChangeVisibility(!previewVisible)}
              >
                <Text color="text-blue">{lang.viewPreview}</Text>
                <Icon name={previewVisible ? "eye-close" : "eye-open"} />
              </div>
            </Button>
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default ReceiptSettingForm;
