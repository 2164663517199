import { PillType, TransactionType } from "enums";

export const getPillTypeOfTransactionType = (type) => {
  return (
    {
      [TransactionType.MIXED]: PillType.Orange,
      [TransactionType.REDEEM]: PillType.Blue,
    }[type] || PillType.Green
  );
};
