import {
  Panel,
  Title,
  CheckboxField,
  Input,
  InputPill,
  Text,
  Button,
  Icon,
  Field,
} from "components/commons";
import { FragmentA } from "components/fragments";
import { InventoryType, StyleType } from "enums";
import React, { useCallback, useMemo } from "react";
import lang from "translations";
import { AttributeAndOptionField, VariantField, validateSkus } from "./product-form.state";
import { generateVariant } from "./generate-variant";
import ManageVariantForm from "./manage-variant-form";
import Validation from "services/validation.service";

const VariantForm = ({
  form,
  modifyForm,
  requestMeasurement,
  requestTax,
  requestSupplyItems,
  isEdit,
}) => {
  const {
    hasVariants,
    attributeAndOptions,
    variants,
    supplyUnit,
    supplyQuantity,
    sellingQuantity,
    supplyItems,
    inventoryType,
    supplyCost,
    generatedSku,
  } = form;

  const generateVariantValues = useCallback(
    async (val) => {
      const attributeOptionsValue = val || attributeAndOptions;
      if (attributeOptionsValue.error) {
        return false;
      }

      const v = attributeOptionsValue.value.map((attribute) => {
        const { value } = attribute || {};
        return {
          attribute: value.attribute.value,
          options: value.options.value.map((option) => {
            return option.text;
          }),
        };
      });
      const generatedVariant = await generateVariant(v);

      const normalizeExistingVariants = {};

      variants.value.forEach((variant) => {
        const key = variant.value.key.value;
        normalizeExistingVariants[key] = variant;
      });
      const variantsFields = generatedVariant.result.map((variant) => {
        const variantKey = variant.join(", ");
        const existingVariant = normalizeExistingVariants[variantKey];
        return {
          ...VariantField,
          value: {
            ...VariantField.value,
            ...existingVariant?.value,
            key: {
              ...VariantField.key,
              value: variantKey,
            },
            sku: {
              ...VariantField.value.sku,
              required: !generatedSku.value,
              validations: [
                Validation.minlength(4),
                Validation.maxlength(16),
                validateSkus,
                ...(!generatedSku.value ? [Validation.required()] : []),
              ],
            },
            attribute: {
              ...VariantField.attribute,
              value: variant.map((attribute) => {
                return {
                  attribute: generatedVariant.optionsNormalize[attribute],
                  value: attribute,
                };
              }),
            },
            supplyItems: {
              ...supplyItems,
              ...existingVariant?.value.supplyItems,
              disabled: inventoryType.value === InventoryType.WholeProduct,
            },
            supplyUnit: {
              ...supplyUnit,
              ...existingVariant?.value.supplyUnit,
              disabled: inventoryType.value === InventoryType.AssembleProduct,
            },
            supplyQuantity: {
              ...supplyQuantity,
              ...existingVariant?.value.supplyQuantity,
              disabled: inventoryType.value === InventoryType.AssembleProduct,
            },
            sellingQuantity: {
              ...sellingQuantity,
              ...existingVariant?.value.sellingQuantity,
              disabled: inventoryType.value === InventoryType.AssembleProduct,
            },
            supplyCost: {
              ...supplyCost,
              ...existingVariant?.value.supplyCost,
              disabled: true,
            },
          },
        };
      });

      const f = {
        [variants.name]: {
          value: variantsFields,
        },
        [attributeOptionsValue.name]: attributeOptionsValue,
      };
      modifyForm(f);
    },
    [
      variants,
      attributeAndOptions,
      modifyForm,
      sellingQuantity,
      supplyItems,
      supplyUnit,
      supplyQuantity,
      supplyCost,
      inventoryType,
      generatedSku.value,
    ]
  );

  const isAttributeValid = useMemo(() => {
    let valid = true;
    attributeAndOptions.value.forEach((f) => {
      const { value, error } = f;
      const { attribute = { value: "" }, options = { value: [] } } = value || {};
      if (!attribute.value.trim() || !options.value.length || error) {
        valid = false;
      }
    });
    return valid;
  }, [attributeAndOptions]);

  if (!isEdit && inventoryType.value === InventoryType.WholeProduct && !supplyUnit.value) {
    return null;
  }

  if (!isEdit && inventoryType.value === InventoryType.AssembleProduct) {
    const incompleteSupplyItems = [...supplyItems.value].some((supplyItem) => {
      return !supplyItem?.value?.item?.value;
    });
    if (incompleteSupplyItems) {
      return null;
    }
  }
  return (
    <FragmentA title={lang.variants}>
      {!hasVariants.hidden && (
        <Panel>
          <Title>{lang.variants}</Title>
          <CheckboxField
            className="mt-sm"
            {...hasVariants}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: {
                  value,
                },
                attributeAndOptions: {
                  value: [AttributeAndOptionField],
                },
                variants: {
                  disabled: !value,
                  value: [],
                },
                // supplyItems: {
                //   disabled: value,
                // },
                retailPrice: {
                  disabled: value,
                },
                sellingQuantity: {
                  disabled: value,
                },
                // supplyQuantity: {
                //   disabled: value,
                // },
                // supplyUnit: {
                //   disabled: value,
                // },
                sku: {
                  disabled: value,
                },
              });
            }}
          >
            {lang.thisProductsHasMultipleVariant}
          </CheckboxField>
          {hasVariants.value && (
            <>
              <div className="mt-lg">
                <div className="grid grid-cols-4">
                  <div className="col-span-1">
                    <Text description>{lang.attribute}</Text>
                  </div>
                  <div className="col-span-1 pl-md">
                    <Text description>{lang.options}</Text>
                  </div>
                </div>
                {attributeAndOptions.value.map((variant, index) => {
                  const { value } = variant;
                  const { attribute, options, input } = value;

                  const onChange = async (changes) => {
                    const value = attributeAndOptions.value;
                    value[index] = {
                      ...value[index],
                      value: {
                        ...value[index].value,
                        ...changes,
                      },
                    };
                    const newValue = await attributeAndOptions.onChange(attributeAndOptions.name, {
                      value,
                    });
                    generateVariantValues(newValue);
                  };

                  return (
                    <div key={`variant${index}`}>
                      <div className="grid grid-cols-4 mt-md">
                        <div className="col-span-1">
                          <Field {...attribute}>
                            <Input
                              {...attribute}
                              onChange={(name, { value: val }) => {
                                onChange({
                                  [name]: {
                                    ...attribute,
                                    value: val,
                                  },
                                });
                              }}
                            />
                          </Field>
                        </div>
                        <div className="col-span-3 pl-md grid grid-cols-12">
                          <div className="col-span-11">
                            <Field {...input}>
                              <InputPill
                                {...options}
                                error={input.error}
                                message={input.message}
                                input={[
                                  input.value,
                                  (v) => {
                                    onChange({
                                      [input.name]: {
                                        ...input,
                                        value: v,
                                      },
                                    });
                                  },
                                ]}
                                setValue={(val) => {
                                  onChange({
                                    [options.name]: {
                                      ...options,
                                      value: val,
                                    },
                                  });
                                }}
                              />
                            </Field>
                          </div>
                          {attributeAndOptions.value.length > 1 && (
                            <div className="col-span-1 text-center flex items-center">
                              <Icon
                                name="remove"
                                className="m-auto"
                                color="text=gray"
                                onClick={async () => {
                                  const value = attributeAndOptions.value.filter((v, vIndex) => {
                                    return index !== vIndex;
                                  });
                                  const newVal = await attributeAndOptions.onChange(
                                    attributeAndOptions.name,
                                    {
                                      value,
                                    }
                                  );
                                  generateVariantValues(newVal);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {index + 1 !== attributeAndOptions.value.length && (
                        <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
                      )}
                    </div>
                  );
                })}
              </div>
              <Button
                className="mt-md"
                type={StyleType.Secondary}
                disabled={!isAttributeValid}
                onClick={async () => {
                  const newValue = await attributeAndOptions.onChange(attributeAndOptions.name, {
                    value: [...attributeAndOptions.value, AttributeAndOptionField],
                  });
                  generateVariantValues(newValue);
                }}
              >
                {lang.addAnotherAttribute}
              </Button>
            </>
          )}
        </Panel>
      )}

      <ManageVariantForm
        form={form}
        requestTax={requestTax}
        loading={attributeAndOptions.error || !isAttributeValid}
        requestMeasurement={requestMeasurement}
        requestSupplyItems={requestSupplyItems}
        isEdit={isEdit}
      />
    </FragmentA>
  );
};

export default VariantForm;
