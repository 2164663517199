import React, { Fragment, useCallback } from "react";
import lang from "translations";
import PrinterForm from "../printer-form/printer-form";
import { createPrinter } from "apis";
import { printerCreateRequest } from "mappers";
import { useApi, useMount } from "hooks";
import { mixpanel, TrackEvent } from "mixpanel";

const CreatePrinter = () => {
  const { request: createPrinterRequest, loading: submitting } = useApi({
    api: createPrinter,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: printerCreateRequest,
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await createPrinterRequest(params);
        return {
          response: res,
          message: lang.populate(lang.printerProfileCreated, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [createPrinterRequest]
  );

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createPrinterProfile,
    });
  });

  return (
    <Fragment>
      <PrinterForm submit={submitForm} title={lang.createPrinterProfile} submitting={submitting} />
    </Fragment>
  );
};

export default CreatePrinter;
