import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo, useEffect } from "react";
import lang from "translations";
import { Path } from "paths";
import { useRouter, useMount, useForm, useModal, useApi } from "hooks";
import { ActionButton, Form, Panel, Field, Text, Toast, Skeleton } from "components/commons";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";
import {
  getAccountMappings,
  createAccountMapping,
  getMewsIntegrationStatus,
  getAccountCodes,
} from "apis";
import initialFormState from "./account-mapping-form.state";

import AccountCodeField from "./account-category-form/account-code-field";
import { AccountName } from "enums";
import { mapAccountOptions } from "services/accounting.service";
import { accountMappingsResponse, accountingResponse } from "mappers/accounting.mapper";

const AccountMapping = () => {
  const { error, venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;

  const unsaveChangesModal = useModal();

  const {
    request: fetchIntegrationStatus,
    mappedData: integrationStatus,
    result,
    loading,
  } = useApi({
    api: getMewsIntegrationStatus,
    params: { venueId },
    mapper: {
      id: { key: "id" },
      integrationType: { key: "integrationType" },
    },
  });

  const isMewsAccountingIntegrated = Boolean(result?.data);

  const { request: fetchAccounts, mappedData: accountListMappedData } = useApi({
    api: getAccountCodes,
    mapper: accountingResponse,
    isArray: true,
    params: {
      businessId: venueId,
    },
  });

  const { request: createAccountMappingRequest } = useApi({
    api: createAccountMapping,
    params: {
      businessId: venueId,
    },
  });

  const {
    request: accountingCategoryRequest,
    mappedData,
    loading: accountCategoriesLoading,
  } = useApi({
    api: getAccountMappings,
    params: {
      businessId: venueId,
    },
    isArray: true,
    mapper: accountMappingsResponse,
  });

  const initialState = useMemo(() => {
    let obj = {};
    // ...
    mappedData?.forEach((account) => {
      if (account.itemType === AccountName.InventoryAccount) {
        obj = { ...obj, inventoryAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.InventoryGainLossAccount) {
        obj = { ...obj, inventoryGainLossAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.TaxOnPurchaseAccount) {
        obj = { ...obj, taxOnPurchasesAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.RevenueAccount) {
        obj = { ...obj, revenueAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.CostOfGoodsSold) {
        obj = { ...obj, costOfGoodsSold: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.TaxOnSalesLiabilityAccount) {
        obj = { ...obj, taxOnSalesLiabilityAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.SaleDiscounts) {
        obj = { ...obj, salesDiscounts: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.SaleFoc) {
        obj = { ...obj, salesFOC: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.CreditsLiabilityAccount) {
        obj = { ...obj, creditsLiabilityAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.FreeCreditsLiabilityAccount) {
        obj = { ...obj, freeCreditsLiabilityAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.PostpaidCreditsLiabilityAccount) {
        obj = { ...obj, postpaidCreditsLiabilityAccount: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.ClearedUnusedExpiredCredit) {
        obj = { ...obj, clearedUnusedAndExpiredCredits: { value: account.accountCodeId } };
      }
    });
    return obj;

    //eslint-disable-next-line
  }, [mappedData]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, dirty, submitForm, getFormValues } = useForm({
    initialState: formState,
  });

  useMount(() => {
    fetchIntegrationStatus();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.accountMapping,
    });
  });

  useEffect(() => {
    (async () => {
      if (isMewsAccountingIntegrated) {
        await fetchAccounts();
        await accountingCategoryRequest();
      }
    })();
    //eslint-disable-next-line
  }, [integrationStatus]);

  const goToList = useCallback(() => {
    history.push(Path.SETTING);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const customMessage = (description) => {
    return (
      <Text color="text-gray-light" size="text-xs" className="mt-sm">
        {description}
      </Text>
    );
  };

  const accountPurchasingFields = [
    { ...fields.inventoryAccount },
    { ...fields.inventoryGainLossAccount },
    { ...fields.taxOnPurchasesAccount },
  ];

  const accountSalesFields = [
    { ...fields.revenueAccount },
    { ...fields.costOfGoodsSold },
    { ...fields.taxOnSalesLiabilityAccount },
    { ...fields.salesDiscounts },
    { ...fields.salesFOC },
  ];

  const accountCreditsFields = [
    { ...fields.creditsLiabilityAccount },
    { ...fields.freeCreditsLiabilityAccount },
    { ...fields.postpaidCreditsLiabilityAccount },
    { ...fields.clearedUnusedAndExpiredCredits },
  ];

  const validateAccountCategoryValues = (params) => {
    let array = [];
    Object.keys(fields).forEach((fieldKey, index) => {
      Object.keys(params).forEach((paramKey, index) => {
        if (params[paramKey] && paramKey === fieldKey) {
          array.push({ itemType: fields[fieldKey].accountType, accountCodeId: params[paramKey] });
        }
      });
    });
    return array;
  };

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    const result = validateAccountCategoryValues(params);
    try {
      await createAccountMappingRequest({ accountCategories: result });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      goToList();
    } catch ({ code, handleError }) {
      handleError();
    }
    //eslint-disable-next-line
  }, [getFormValues]);

  const submitFormValues = () => {
    submitForm(handleSubmit);
  };

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.accountMapping}
          description={lang.mapAndManageAccounts}
          button={{ text: lang.save, onClick: submitFormValues }}
          onClick={() => {
            leavePage();
          }}
        />
      }
    >
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValues}>
        <>
          <AccountPanelForm
            accountFields={accountPurchasingFields}
            title={lang.purchasingAndInventory}
            description={lang.mapYourAccounts}
            modifyField={modifyField}
            customMessage={customMessage}
            isMewsIntegrated={isMewsAccountingIntegrated}
            options={mapAccountOptions(accountListMappedData)}
            loading={loading || accountCategoriesLoading || accountCategoriesLoading === undefined}
          />
          <AccountPanelForm
            accountFields={accountSalesFields}
            title={lang.sales}
            description={lang.manageYourAccountsSales}
            modifyField={modifyField}
            customMessage={customMessage}
            isMewsIntegrated={isMewsAccountingIntegrated}
            options={mapAccountOptions(accountListMappedData)}
            loading={loading || accountCategoriesLoading || accountCategoriesLoading === undefined}
          />
          <AccountPanelForm
            accountFields={accountCreditsFields}
            title={lang.credits}
            description={lang.manageToppedUpCredits}
            modifyField={modifyField}
            customMessage={customMessage}
            isMewsIntegrated={isMewsAccountingIntegrated}
            options={mapAccountOptions(accountListMappedData)}
            loading={loading || accountCategoriesLoading || accountCategoriesLoading === undefined}
          />
        </>
      </Form>

      <ActionButton
        showLine
        secondary={false}
        primary={{ onClick: submitFormValues, text: lang.save }}
      ></ActionButton>
    </ModuleWrapper>
  );
};

const AccountPanelForm = ({
  accountFields,
  title,
  description,
  customMessage,
  modifyField,
  isMewsIntegrated,
  options,
  loading,
}) => (
  <FragmentA title={title} description={description}>
    <Panel>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          {accountFields?.map((fields, index) => {
            return (
              <Field key={index} customMessage={customMessage(fields.description)} {...fields}>
                <AccountCodeField
                  modifyField={modifyField}
                  fields={fields}
                  isMewsIntegrated={isMewsIntegrated}
                  options={options}
                />
              </Field>
            );
          })}
        </>
      )}
    </Panel>
  </FragmentA>
);

export default AccountMapping;
