import { Post, Delete, Get } from "services";
import { ApiPath } from "paths";

export const getQuestions = async (body) => {
  const res = await Get(`${ApiPath.QUESTION}/`, body);
  return res;
};

export const getQuestion = async ({ questionId }) => {
  const res = await Get(`${ApiPath.QUESTION_ID(questionId)}`, {});
  return res;
};

export const createQuestion = async (body) => {
  const res = await Post(`${ApiPath.QUESTION}`, body);
  return res;
};

export const deleteQuestion = async ({ questionId }) => {
  const res = await Delete(`${ApiPath.QUESTION_ID(questionId)}`);
  return res;
};

export const editQuestion = async (body) => {
  const res = await Post(`${ApiPath.QUESTION_ID(body.questionId)}`, body);
  return res;
};
