import React, { useCallback, useContext, useMemo, useState } from "react";
import { searchTransaction, exportTransaction } from "apis";
import { Pill, Text, DataTable, ButtonLink, Tooltip } from "components/commons";
import { VenueContext } from "contexts";
import { PillType, TapStatus, TransactionStatus, TransactionType, PaymentMethod } from "enums";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { transactionListFilterRequest, transactionListResponse } from "mappers";
import { mapObject, pluralize } from "services";
import columns from "./columns";
import { prettifyTransactionType } from "services/pretty.service";
import TransactionFilter from "./transaction-filter";
import { transactionListFilterState } from "./filters";
import lang from "translations";
import { Path } from "paths";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";

const TransactionList = () => {
  const { venue } = useContext(VenueContext);
  const { history, pathname, query } = useRouter();
  const { guestCheckinId: filteredGuestCheckinId } = query || {};
  const [guestCheckinId, setGuestCheckinId] = useState(null);

  const {
    request: searchTransactionRequest,
    loading: loadingTransaction,
    result: searchTransactionResult = { data: [], metadata: { total: 0 } },
    error,
    mappedData,
  } = useApi({
    api: searchTransaction,
    isArray: true,
    mapper: transactionListResponse,
    handleOwnError: true,
  });

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState, isFilterDirty } =
    useFilter(
      transactionListFilterState(venue.venueId, {
        history,
        pathname,
        query,
        guestCheckinId: filteredGuestCheckinId,
      }),
      transactionListFilterState(venue.venueId, {
        history,
        pathname,
        query: {},
      })
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    setGuestCheckinId(filteredGuestCheckinId);
    fetchTransactions(requestState);

    if (Object.keys(query).length > 0 && !filteredGuestCheckinId) {
      history.replace(pathname);
    }
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.transactionsPage,
    });
  });

  const fetchTransactions = useCallback(
    (requestState) => {
      const params = mapObject(requestState, transactionListFilterRequest);
      if (!requestState.dateRange[0]) {
        params.startDateTime = null;
        params.endDateTime = null;
      }
      if (
        (guestCheckinId || filteredGuestCheckinId) &&
        [guestCheckinId, filteredGuestCheckinId].includes(params.searchKey)
      ) {
        params.guestCheckinId = guestCheckinId || filteredGuestCheckinId;
        params.searchKey = null;
      }
      searchTransactionRequest(params);
    },
    [searchTransactionRequest, guestCheckinId, filteredGuestCheckinId]
  );

  const renderTransactionId = useCallback(
    (transactionId) => (
      <ButtonLink
        // onClick={() => {
        //   history.push(Path.TRANSACTION_ID(transactionId), { fromList: true });
        // }}
        path={Path.TRANSACTION_ID(transactionId)}
      >
        #{transactionId}
      </ButtonLink>
    ),
    []
  );

  const renderDateCreated = useCallback(
    (dateCreated) => (
      <div>
        <Text>{dateCreated?.date}</Text>
        {dateCreated?.time && <Text color="text-gray">{dateCreated.time}</Text>}
      </div>
    ),
    []
  );

  const renderGuestName = useCallback((guestName, userTagUid, guestId, paymentMethod, type) => {
    const isPaidByCashOrCard = [
      PaymentMethod.Cash,
      PaymentMethod.CreditDebit,
      PaymentMethod.Credit,
      PaymentMethod.Others,
    ].includes(paymentMethod);
    if (isPaidByCashOrCard && type === TransactionType.SALE) {
      return (
        <div>
          <Text strong>-</Text>
        </div>
      );
    }

    return (
      <div>
        {guestName && 
          <ButtonLink className="cursor-pointer group" path={Path.GUEST_DETAILS_ID(guestId)}>
            <Text className="group-hover:text-gray">{guestName}</Text>
          </ButtonLink>
        }
        <Text tagUid className="group-hover:text-gray">
          {userTagUid}
        </Text>
      </div>
    );
  }, []);

  const renderLocationName = useCallback(
    (locationName, locationId) => (
      <ButtonLink
        className="cursor-pointer"
        // onClick={() => history.push(Path.LOCATION_ID(locationId))}
        path={Path.LOCATION_ID(locationId)}
      >
        <Text className="hover:text-gray">{locationName}</Text>
      </ButtonLink>
    ),
    []
  );

  const renderStaffName = useCallback(
    (staffName, staffTagUid, staffProfileId) => (
      <div>
        <ButtonLink
          className="cursor-pointer group"
          // onClick={() => history.push(Path.STAFF_ID(staffProfileId))}
          path={Path.STAFF_ID(staffProfileId)}
        >
          <Text className="group-hover:text-gray">{staffName}</Text>
        </ButtonLink>
        <Text tagUid className="group-hover:text-gray">
          {staffTagUid}
        </Text>
      </div>
    ),
    []
  );

  const renderTransactionType = useCallback((type, status, tapStatus) => {
    if (status === TransactionStatus.VOID) {
      if (tapStatus === TapStatus.Failed) {
        return (
          <Pill size="text-xs" type={PillType.Red}>
            {`${lang.failed} ${prettifyTransactionType(type)} ${lang.void}`}
          </Pill>
        );
      }
      return (
        <Pill size="text-xs" type={PillType.Gray}>
          {`${prettifyTransactionType(type)} ${lang.voided}`}
        </Pill>
      );
    }
    if (tapStatus === TapStatus.Failed) {
      return (
        <Pill size="text-xs" type={PillType.Red}>
          {`${lang.failed} ${prettifyTransactionType(type)}`}
        </Pill>
      );
    }
    if (
      type === TransactionType.TOPUP ||
      type === TransactionType.SALE ||
      type === TransactionType.RETURN ||
      type === TransactionType.ISSUE_FOC ||
      type === TransactionType.REMOVE_FOC
    ) {
      return (
        <Pill size="text-xs" type={PillType.Green}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    if (type === TransactionType.MIXED) {
      return (
        <Pill size="text-xs" type={PillType.Orange}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    if (type === TransactionType.REDEEM) {
      return (
        <Pill size="text-xs" type={PillType.Blue}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    if (type === TransactionType.REMOVE) {
      return (
        <Pill size="text-xs" type={PillType.Red}>
          {prettifyTransactionType(type)}
        </Pill>
      );
    }
    return null;
  }, []);

  const renderPaidAmount = useCallback(
    (paidAmount, type) => (
      <div>
        <Text danger={type === TransactionType.RETURN}>{paidAmount}</Text>
      </div>
    ),
    []
  );

  const renderItems = useCallback((items, type) => {
    const list = items.slice(0, 4);
    if (items.length > 4) {
      list.push(`${items.length - 4} more.`);
    }
    if (items.length) {
      return (
        <Tooltip
          title={
            <Text breakAll color="text-white" fontWeight="font-semibold">
              {items.join(", ")}
            </Text>
          }
        >
          <div>
            <Text>{list.join(", ")}</Text>
          </div>
        </Tooltip>
      );
    }
  }, []);

  const prepareTransactionList = useCallback(() => {
    return mappedData.map((transaction) => {
      const {
        transactionId,
        dateCreated,
        guestName,
        userTagUid,
        locationName,
        locationId,
        staffName,
        staffTagUid,
        staffProfileId,
        transactionType,
        paidAmount,
        transactionStatus,
        tapStatus,
        guestId,
        items,
        qty,
        paymentMethod,
      } = transaction;

      return {
        transactionId: renderTransactionId(transactionId),
        dateCreated: renderDateCreated(dateCreated),
        guest: renderGuestName(guestName, userTagUid, guestId, paymentMethod, transactionType),
        locationName: renderLocationName(locationName, locationId),
        staffName: renderStaffName(staffName, staffTagUid, staffProfileId),
        transactionType: renderTransactionType(transactionType, transactionStatus, tapStatus),
        paidAmount: renderPaidAmount(paidAmount, transactionType),
        items: renderItems(items, transactionType),
        quantity: (
          <div>
            <Text>{qty}</Text>
          </div>
        ),
      };
    });
  }, [
    mappedData,
    renderTransactionId,
    renderDateCreated,
    renderGuestName,
    renderLocationName,
    renderTransactionType,
    renderPaidAmount,
    renderStaffName,
    renderItems,
  ]);

  const transactions = useMemo(() => {
    return prepareTransactionList();
  }, [prepareTransactionList]);

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ page: 1, searchKey });
      fetchTransactions(requestState);

      setHasAppliedFilter(true);
    },
    [fetchTransactions, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState } = clearFilter();
    fetchTransactions(requestState);

    setHasAppliedFilter(false);
  }, [clearFilter, fetchTransactions]);

  const transactionResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${searchTransactionResult.metadata.total} ${pluralize(
        searchTransactionResult.metadata.total,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
  }, [searchTransactionResult.metadata.total, hasAppliedFilter]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState } = modifyFilters({ sort: { key, value } });
      fetchTransactions(requestState);
    },
    [fetchTransactions, modifyFilters]
  );

  const { request: exportTransactionsRequest, loading: exportTransactionsLoading } = useApi({
    api: exportTransaction,
  });

  const exportReport = useCallback(() => {
    var request = {
      ...mapObject({ ...requestState }, transactionListFilterRequest),
      venueId: venue.venueId,
    };
    delete request.page;
    delete request.pageSize;
    exportTransactionsRequest(request);
  }, [exportTransactionsRequest, requestState, venue.venueId]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.transactions}
        description={lang.viewEveryTransactions}
        button={{
          text: lang.exportTransactions,
          loading: exportTransactionsLoading,
          disabled: exportTransactionsLoading,
          onClick: () => {
            exportReport();
          },
        }}
        className="mb-md"
      />

      <TransactionFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        fetchTransactions={fetchTransactions}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />

      <DataTable
        resultContent={transactionResultContent}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchTransactions}
        total={searchTransactionResult.metadata.total}
        loading={loadingTransaction}
        columns={columns}
        data={transactions}
        error={error}
        sort={filterState.sort}
        setSort={sortCb}
        hasAppliedFilter={isFilterDirty && hasAppliedFilter}
      />
    </ModuleWrapper>
  );
};

export default TransactionList;
