import React from "react";
import { Button as AntButton, Divider } from "antd";
import { StyleType } from "enums";
import { Icon } from "components/commons";
import classnames from "classnames";

const ButtonNavigation = ({
  className,
  type = StyleType.Secondary,
  loading,
  onClickLeft,
  onClickRight,
  disabledRight,
  disabledLeft,
}) => {
  return (
    <div>
      <AntButton
        disabled={disabledLeft}
        onClick={onClickLeft}
        className={classnames(
          "h-auto focus:outline-none p-0 border-t border-l border-b border-r-0",
          {
            [`text-pelorous bg-white border-gray-200`]: type === StyleType.Secondary,
            [`text-white`]: type === StyleType.Danger,
            [`text-pelorous bg-transparent border-0`]: type === StyleType.Link,
            [`text-pelorous bg-transparent`]: type === StyleType.Plain || type === StyleType.Ghost,
          },
          className
        )}
      >
        <div className={classnames("flex items-center align-center justify-center text-sm strong")}>
          {loading && <Icon loading fontSize="36px" paddingless={true} />}
          <Icon fontSize="36px" paddingless name="left-arrow" />
        </div>
      </AntButton>
      <Divider type="vertical" className="p-0 m-0 h-auto" />
      <AntButton
        disabled={disabledRight}
        onClick={onClickRight}
        className={classnames(
          "h-auto focus:outline-none p-0 border-t border-l-0 border-b border-r",
          {
            [`text-pelorous bg-white border-gray-200`]: type === StyleType.Secondary,
            [`text-white`]: type === StyleType.Danger,
            [`text-pelorous bg-transparent border-0`]: type === StyleType.Link,
            [`text-pelorous bg-transparent`]: type === StyleType.Plain || type === StyleType.Ghost,
          },
          className
        )}
      >
        <div className={classnames("flex items-center align-center justify-center text-sm strong")}>
          {loading && <Icon loading fontSize="36px" paddingless={true} />}
          <Icon fontSize="36px" paddingless name="right-arrow" />
        </div>
      </AntButton>
    </div>
  );
};

export default ButtonNavigation;
