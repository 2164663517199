import { Field } from "enums";
import lang from "translations";
import Validation from "services/validation.service";
import moment from "moment";

const initialFormState = (initialState = {}) => {
  const dateTime = moment();
  const { locationId = null, dateRange = dateTime } = initialState;

  return {
    locationId: {
      name: "locationId",
      value: locationId,
      type: Field.ANY,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: lang.location,
      placeholder: lang.selectLocation,
    },
    startDate: {
      name: "startDate",
      value: dateRange,
      type: Field.DATE_TIME,
    },
  };
};

export default initialFormState;
