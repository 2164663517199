import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useApi, useFilter, useModal, useMount } from "hooks";
import {
  Panel,
  Text,
  Skeleton,
  Image,
  Button,
  SimpleTable,
  Switch,
  Icon,
  Title,
  Toast,
} from "components/commons";
import {
  searchNotificationStockLevel,
  addAccountNotificationStockLevel,
  editAccountNotificationStockLevel,
  removeAccountNotificationStockLevel,
} from "apis/notification.api";
import { VenueContext } from "contexts";
import { OctopusHiding } from "images";
import { StyleType } from "enums";
import { formatName } from "services";
import NotificationEmailForm from "../notification-email-form/notification-email-form";
import { DeleteModal } from "components/modals";
import { mixpanel, TrackEvent } from "mixpanel";

const StockLevelNotification = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const formModal = useModal();

  const { filterState } = useFilter({
    page: 1,
    pageSize: null,
    venueId,
  });

  const { request, loading, mappedData, error } = useApi({
    api: searchNotificationStockLevel,
    params: {
      ...filterState,
    },
    isArray: true,
    mapper: {
      _keys: ["stockLevelAlert", "firstName", "lastName", "email"],
      name: {
        transform: ({ src }) => {
          const { firstName, lastName, email } = src;
          return `${formatName(firstName, lastName) || "-"} (${email})`;
        },
      },
      id: {
        key: "notificationProfileId",
      },
    },
    handleOwnError: true,
  });

  const { request: createRequestApi, loading: createLoading } = useApi({
    api: addAccountNotificationStockLevel,
    params: {
      venueId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const {
    request: editRequestApi,
    loading: editLoading,
    submittedParams,
  } = useApi({
    api: editAccountNotificationStockLevel,
    params: {
      venueId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const deleteModal = useModal();

  const { request: removeRequestApi, loading: deleting } = useApi({
    api: removeAccountNotificationStockLevel,
    params: {
      venueId,
      id: deleteModal?.id,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  useMount(() => {
    request();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.stockLevelNotificationSettings,
    });
  });

  const onSave = useCallback(
    async ({ api, message }, fields, success, error) => {
      try {
        const result = await api(fields);
        request();
        return success(result, { message });
      } catch (e) {
        return error(e);
      }
    },
    [request]
  );

  const onEditSave = useCallback(
    (fields, success, error) => {
      return onSave({ api: editRequestApi, message: lang.changesSaved }, fields, success, error);
    },
    [editRequestApi, onSave]
  );

  const onCreateSave = useCallback(
    (fields, success, error) => {
      return onSave(
        {
          api: createRequestApi,
          message: lang.populate(lang.accountEmailAdded, [fields.email]),
        },
        fields,
        success,
        error
      );
    },
    [createRequestApi, onSave]
  );

  const showCreateModal = useCallback(() => {
    formModal.show({
      title: lang.addAccount,
      primaryText: lang.add,
      initialState: undefined,
      isEdit: false,
      save: onCreateSave,
      params: {
        stockLevelAlert: true,
      },
    });
  }, [formModal, onCreateSave]);

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton />;
    }
    if (!mappedData.length) {
      return (
        <div className="text-center ">
          <Image src={OctopusHiding} className="w-9/12 mx-auto my-lg" />
          <Text size="text-lg mb-lg">{lang.yourMailingIsEmpty}</Text>
          <Button type={StyleType.Secondary} onClick={() => showCreateModal()}>
            {lang.addAccount}
          </Button>
        </div>
      );
    }
    return (
      <>
        <SimpleTable
          className="-mx-6"
          columns={[
            {
              key: "name",
              className: "py-lg px-6",
              text: (
                <Text className="pl-lg" strong capitalize size="text-md">
                  {lang.mailingList}
                </Text>
              ),
            },
            {
              key: "notifications",
              className: "text-center p-lg",
              headerClassName: "text-center w-32",
              text: lang.getNotifications,
            },
            {
              key: "edit",
              className: "text-right p-lg overflow-hidden",
              headerClassName: "w-14",
              text: "",
            },
          ]}
          data={mappedData.map(({ name, stockLevelAlert, firstName, lastName, email, id }) => {
            return {
              name: <Text breakAll>{name}</Text>,
              notifications: (
                <div>
                  <Switch
                    value={stockLevelAlert}
                    loading={editLoading && submittedParams.id === id}
                    disabled={editLoading}
                    onChange={() => {
                      onEditSave(
                        {
                          firstName,
                          lastName,
                          email,
                          id,
                          stockLevelAlert: !stockLevelAlert,
                        },
                        async () => {
                          try {
                            await request();
                            Toast({
                              content: lang.changesSaved,
                              success: true,
                              icon: "check",
                            }).open();
                          } catch {
                            console.error("Failed to toggle email notif.");
                          }
                        },
                        () => {}
                      );
                    }}
                  />
                </div>
              ),
              edit: (
                <span className="display-right-hidden">
                  <Icon
                    name="pencil"
                    className="text-blue"
                    onClick={() => {
                      formModal.show({
                        title: lang.editAccount,
                        primaryText: lang.save,
                        isEdit: true,
                        save: onEditSave,
                        params: {
                          stockLevelAlert,
                          id,
                        },
                        initialState: {
                          firstName,
                          lastName,
                          email,
                        },
                        deleteButton: {
                          text: lang.removeFromList,
                          onClick: () => {
                            deleteModal.show({
                              email,
                              id,
                            });
                          },
                        },
                      });
                    }}
                  />
                </span>
              ),
            };
          })}
        />
        <Button type={StyleType.Secondary} onClick={() => showCreateModal()} className="mt-md">
          {lang.addAnotherAccount}
        </Button>
      </>
    );
  }, [
    mappedData,
    loading,
    showCreateModal,
    onEditSave,
    formModal,
    deleteModal,
    request,
    editLoading,
    submittedParams,
  ]);

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.notifications}
          title={lang.stockLevelAlerts}
          returnPath={Path.SETTING_NOTIFICATION}
        />
      }
    >
      <FragmentA title={""} description={lang.manageAccountNotificationStockLevel}>
        <Panel>{content}</Panel>
      </FragmentA>
      <NotificationEmailForm
        {...formModal}
        active={deleteModal.active ? false : formModal.active}
        submitting={createLoading || editLoading}
      />
      <DeleteModal
        deleting={deleting}
        customHeader={
          <Title lg className="py-md">
            {lang.removeThisAccount}
          </Title>
        }
        {...deleteModal}
        customContent={
          <Text>{lang.populate(lang.youAreAboutToRemoveFromMailingList, [deleteModal.email])}</Text>
        }
        primaryText={lang.proceed}
        ok={async () => {
          await removeRequestApi(deleteModal.id);
          Toast({
            content: lang.populate(lang.accountEmailRemoved, [deleteModal.email]),
            success: true,
            icon: "check",
          }).open();
          deleteModal.close();
          formModal.close();
          request();
        }}
      />
    </ModuleWrapper>
  );
};

export default StockLevelNotification;
