import { ApiPath } from "paths";
import { Post, Get } from "services";

export const createSupplyItem = async (body) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM}`, body);
  return res;
};

export const searchSupplyItem = async (params) => {
  const res = await Post(`${ApiPath.SEARCH_SUPPLY_ITEM}`, params);
  return res;
};

export const getSupplyItems = async (params) => {
  const res = await Get(`${ApiPath.SUPPLY_ITEM_VENUE_ID(params.venueId)}`, params);
  return res;
};

export const getSupplyItem = async ({ id }) => {
  const res = await Get(`${ApiPath.SUPPLY_ITEM_ID(id)}`);
  return res;
};

export const updateSupplyItem = async (body) => {
  const res = await Post(`${ApiPath.SUPPLY_ITEM_ID(body.productId)}`, body);
  return res;
};
