import { Input, Field, Text } from "components/commons";
import { VenueContext } from "contexts";
import React, { useContext } from "react";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber, toAmount } from "services";
import { parseMoneyToNumber } from "services/money.service";
import { isDecimalLastCharacter, isZeroLastCharacter } from "services/number.service";

const RetailPriceField = ({ retailPrice }) => {
  const { venue } = useContext(VenueContext);
  const { currencySymbol } = venue;
  return (
    <Field {...retailPrice}>
      <Input
        {...retailPrice}
        iconPrefix={<Text color="text-gray">{currencySymbol}</Text>}
        onChange={(name, { value }) => {
          if (!isNumberValid(value) || !isValidDecimalPlaces(value, 2)) {
            return false;
          } else if (isDecimalLastCharacter(value) || isZeroLastCharacter(value)) {
            retailPrice.onChange(name, {
              value: value,
            });
            return false;
          }
          const v = parseMoneyToNumber(value).value;
          const max = 99999999.99;
          if (v <= max) {
            retailPrice.onChange(name, {
              value: value,
            });
          }
        }}
        onBlur={() => {
          retailPrice.onChange(retailPrice.name, {
            value: toAmount(parseAmountToNumber(retailPrice.value), "0,0.00"),
          });
        }}
        onFocus={() => {
          const value = parseAmountToNumber(retailPrice.value);
          retailPrice.onChange(retailPrice.name, {
            value: !value ? "" : value,
          });
        }}
      />
    </Field>
  );
};

export default RetailPriceField;
