import { searchProductReport, searchProductReportExport } from "apis";
import { ButtonLink, DataTable, Icon, Text } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount } from "hooks";
import { productReportListResponse, productReportListRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { mapObject } from "services";
import { productReportFilterState } from "./filters";
import InventoryReportFilter from "./inventory-report-filter";
import columns from "./columns";
import lang from "translations";
import classnames from "classnames";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";

const InventoryReport = () => {
  const { venue } = useContext(VenueContext);

  const {
    request: searchProductReportRequest,
    loading: locationProductReports,
    result: productReportResult = { data: [], metadata: { total: 0 } },
    mappedData,
    error,
  } = useApi({
    api: searchProductReport,
    isArray: true,
    mapper: productReportListResponse,
  });

  const { request: searchProductReportExportRequest, loading: searchProductReportExportLoading } =
    useApi({
      api: searchProductReportExport,
    });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    productReportFilterState(venue.venueId)
  );

  useMount(() => {
    fetchProductReports(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.inventoryReport,
    });
  });

  const fetchProductReports = useCallback(
    (requestState) => {
      searchProductReportRequest(mapObject({ ...requestState }, productReportListRequest));
    },
    [searchProductReportRequest]
  );

  const renderName = useCallback((name, productId) => {
    return <ButtonLink path={Path.PRODUCT}>{name}</ButtonLink>;
  }, []);

  const renderStock = useCallback((stock, unit) => {
    return (
      <Text className={classnames({ "text-red": stock <= 0 })}>
        {stock} {unit}
      </Text>
    );
  }, []);

  const renderStockVariance = useCallback((variance, unit) => {
    return (
      <Text className={classnames({ "text-red": variance <= 0 })}>
        {variance} {unit}
      </Text>
    );
  }, []);

  const prepareProductReports = useCallback(() => {
    return mappedData.map((productReport) => {
      const { name, sku, productSkuId, parLevel, reorderPoint, stock, unit, stockVariance } =
        productReport;

      return {
        name: renderName(name, productSkuId),
        sku,
        parLevel,
        reorderPoint,
        stock: renderStock(stock, unit),
        stockVariance: renderStockVariance(stockVariance, unit),
      };
    });
  }, [mappedData, renderStock, renderStockVariance, renderName]);

  const productReports = useMemo(() => {
    return prepareProductReports();
  }, [prepareProductReports]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState } = modifyFilters({ sort: { key, value } });
      fetchProductReports(requestState);
    },
    [fetchProductReports, modifyFilters]
  );

  const exportXlsx = useCallback(() => {
    searchProductReportExportRequest(mapObject({ ...requestState }, productReportListRequest));
  }, [searchProductReportExportRequest, requestState]);

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.inventoryReport}
        description={lang.trackWhichItemsLow}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchProductReportExportLoading,
          disabled: searchProductReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />
      <InventoryReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        modifyFilters={modifyFilters}
        clearFilter={clearFilter}
        fetchProductReports={fetchProductReports}
      />
      {productReports.length > 0 ? (
        <DataTable
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchProductReports}
          total={productReportResult.metadata.total}
          loading={locationProductReports}
          columns={columns}
          data={productReports}
          error={error}
          sort={filterState.sort}
          setSort={sortCb}
        />
      ) : (
        noResult
      )}
    </ModuleWrapper>
  );
};

export default InventoryReport;
