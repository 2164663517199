import { getPabblySsoLink } from "apis";
import classNames from "classnames";
import { Icon, Panel, Title, Text, Pill } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderA } from "components/headers";
import { StaffContext, VenueContext } from "contexts";
import { PillType, SubscriptionStatus } from "enums";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useContext, useCallback } from "react";
import lang from "translations";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { capitalize } from "services";
import { TrackEvent, mixpanel } from "mixpanel";
import { useFlags } from "launchdarkly-react-client-sdk";

const SettingNavPage = () => {
  const { history } = useRouter();
  const { venue } = useContext(VenueContext);
  const { hasAccessOnRoute } = useContext(StaffContext);
  const { integrationsSetting, chartOfAccounts, accountMapping } = useFlags();

  const { request, result } = useApi({
    api: getPabblySsoLink,
    params: { venueId: venue.venueId },
    handleOwnError: {
      badrequest: true,
    },
  });

  useMount(() => {
    request();

    mixpanel.track(TrackEvent.VisitedPage, {
      page: lang.settings,
    });
  });

  const customPabblyDomain = useCallback(() => {
    if (venue.subscriptionCustomerId && result && result.data && result.data.accessUrl) {
      return result.data.accessUrl.replace("payments.pabbly.com", "subscribe.pouchnation.com");
    }
    return null;
  }, [result, venue]);

  const pages = [
    {
      title: lang.venueSettings,
      items: [
        {
          title: lang.general,
          description: lang.yourGeneralSettings,
          icon: "gear",
          path: Path.SETTING_GENERAL_SETTING,
          fontSize: "30px",
        },
        // {
        //   title: lang.notifications,
        //   description: lang.manageNotifcations,
        //   icon: "notification",
        //   path: Path.SETTING_NOTIFICATION,
        //   fontSize: "30px",
        // },
        {
          title: lang.banTags,
          description: lang.restrictUseOfTags,
          icon: "cancel",
          path: Path.BAN_TAG,
          fontSize: "26px",
        },
        {
          title: lang.wristbandKeeping,
          description: lang.allowOrRestrictGuestWBKeeping,
          icon: "nfc-tag1",
          path: Path.WRISTBAND_KEEPING,
          fontSize: "22px",
        },
        {
          title: lang.receipts,
          description: lang.manageReceipts,
          icon: "receipt",
          path: Path.RECEIPT_SETTING,
          fontSize: "26px",
        },
        {
          title: lang.integrations,
          description: lang.integrationSettingDesc,
          icon: "integration",
          path: Path.INTEGRATION_SETTING,
          fontSize: "26px",
          featureFlag: integrationsSetting,
        },
      ],
    },
    {
      title: lang.paymentAndCreditSettings,
      items: [
        {
          title: lang.chartOfAccounts,
          description: lang.chartOfAccountsDescription,
          icon: "chart-of-accounts",
          path: Path.CHART_OF_ACCOUNTS,
          fontSize: "30px",
          featureFlag: chartOfAccounts,
        },
        {
          title: lang.accountMapping,
          description: lang.accountMappingDescription,
          icon: "account-mapping",
          path: Path.ACCOUNT_MAPPING,
          fontSize: "30px",
          featureFlag: accountMapping,
        },
        {
          title: lang.paymentMethods,
          description: lang.paymentMethodsYouAccept,
          icon: "credit-card",
          path: Path.PAYMENT_METHOD_SETTING,
          fontSize: "30px",
        },
        {
          title: lang.taxes,
          description: lang.manageYourVenueTax,
          icon: "taxes",
          path: Path.SETTING_TAX,
          fontSize: "30px",
        },
        {
          title: lang.credits,
          description: lang.allowPostpaidAndLimit,
          icon: "credits",
          path: Path.CREDIT_LIMIT_SETTING,
          fontSize: "30px",
        },
      ],
    },
    {
      title: lang.checkInSettings,
      items: [
        {
          title: lang.questions,
          description: lang.guestInformationYouCollect,
          icon: "questions",
          path: Path.QUESTION_SETTING,
          fontSize: "30px",
        },
      ],
    },
    {
      title: lang.accountSettings,
      items: [
        {
          title: lang.account,
          description: lang.editYourAccountPreferences,
          icon: "account-settings",
          path: Path.ACCOUNT_SETTING,
          fontSize: "30px",
        },
        {
          title: lang.subscription,
          description: lang.manageYourVenueSubscription,
          subDescription: (
            <div className="flex justify-left items-center">
              <Text
                color="text-black-light"
                size="text-base"
                className={classnames({
                  "mr-sm font-bold": venue.subscriptionPlanName,
                })}
              >
                {venue.subscriptionPlanName}
              </Text>
              <Pill
                type={
                  venue.subscriptionStatus === SubscriptionStatus.Active
                    ? PillType.Greenish
                    : PillType.Gray
                }
              >
                {capitalize(venue.subscriptionStatus)}
              </Pill>
            </div>
          ),
          icon: "plans",
          path: customPabblyDomain(),
          fontSize: "30px",
          disabled: result?.data ? false : true,
          hidden: Boolean(venue.subscriptionPlanName),
        },
      ],
    },
  ]
    .filter((panel) => {
      return panel.items.filter((item) => {
        return hasAccessOnRoute(item.path);
      }).length;
    })
    .map((panel) => {
      return {
        ...panel,
        items: panel.items.filter((item) => {
          return hasAccessOnRoute(item.path);
        }),
      };
    });
  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.settings}
        description={lang.manageImportantDetailsSettings}
        className="mb-md"
      />

      {pages.map((page) => {
        return (
          <div className="my-md" key={uuidv4()}>
            <Panel className="mt-md">
              <Title lg className="mb-md text-gray-700">
                {page.title}
              </Title>
              <div className="grid grid-cols-1 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                {page.items.map((item) => {
                  if (item.featureFlag !== undefined && !item.featureFlag) {
                    return null;
                  }
                  if (item.hidden !== false) {
                    return (
                      <div
                        key={uuidv4()}
                        className={classNames("flex md:p-lg", {
                          "opacity-50 cursor-not-allowed": item.disabled,
                          "hover:opacity-75 cursor-pointer": !item.disabled,
                        })}
                        onClick={() => {
                          mixpanel.track(TrackEvent.ClickedButton, {
                            button: item.title,
                          });

                          if (item.title !== lang.subscription) {
                            history.push(item.path);
                          } else {
                            if (item.path) {
                              window.open(item.path);
                            }
                          }
                        }}
                      >
                        <div className="w-12 flex flex-none">
                          <Icon
                            name={item.icon}
                            className={"text-pelorous"}
                            fontSize={item.fontSize}
                          />
                        </div>
                        <div className="flex-grow">
                          <Title className="text-gray-700 mb-sm" md>
                            {item.title}
                          </Title>
                          {item.subDescription && (
                            <div className="text-gray-700 mb-sm">{item.subDescription}</div>
                          )}
                          <Text size="text-sm" className="text-gray-700">
                            {item.description}
                          </Text>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Panel>
          </div>
        );
      })}
    </ModuleWrapper>
  );
};

export default SettingNavPage;
