import lang from "translations";

const columns = [
  {
    key: "id",
    text: lang.bookingNo,
    align: "left",
  },
  {
    key: "startTime",
    text: lang.arrivalDate,
  },
  {
    key: "endTime",
    text: lang.departure,
  },
  {
    key: "bookedBy",
    text: lang.bookedBy,
  },
  {
    key: "noOfNights",
    text: lang.numberOfNights,
    align: "right",
  },
  {
    key: "noOfGuests",
    text: lang.numberOfGuests,
    align: "right",
  },
];

export default columns;
