import { Line, Panel, Skeleton } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import { useRouter } from "hooks";
import { Path } from "paths";
import React from "react";
import lang from "translations";

const SupplyItemSummarySkeleton = () => {
  const { history } = useRouter();
  return (
    <ModuleWrapper width="full">
      <HeaderB
        returnText={lang.supplyItems}
        onClick={() => history.push(Path.INVENTORY_SUPPLY_ITEM)}
      />
      <Skeleton single />
      <Line></Line>
      <>
        <Panel>
          <div className="grid grid-flow-col grid-cols-5 grid-rows-1 px-lg pt-sm gap-4">
            <Skeleton single />
            <Skeleton single />
            <Skeleton single />
            <Skeleton single />
            <Skeleton single />
          </div>
          <div className="grid grid-flow-col grid-cols-5 grid-rows-1 px-lg pt-sm gap-4">
            <Skeleton single />
            <Skeleton single />
            <Skeleton single />
            <Skeleton single />
            <Skeleton single />
          </div>
        </Panel>
        <Panel>
          <Skeleton width="100%" active={true} />
        </Panel>
      </>
    </ModuleWrapper>
  );
};

export default SupplyItemSummarySkeleton;
