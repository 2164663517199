import React, { useCallback, useContext, useMemo } from "react";
import ProductForm from "../product-form/product-form";
import lang from "translations";
import { createProduct } from "apis/product.api";
import { useApi, useMount, useRouter } from "hooks";
import { mixpanel, TrackEvent } from "mixpanel";
import { AccountName } from "enums";
import { accountMappingsResponse } from "mappers/accounting.mapper";
import { getAccountMappings } from "apis";
import { VenueContext } from "contexts";

const CreateProductForm = () => {
  const { venue } = useContext(VenueContext);
  const { query } = useRouter();
  const { revenueAccountCodeId, cogsAccountCodeId } = query;

  const { request, loading } = useApi({
    api: createProduct,
    params: {
      revenueAccountCodeId,
      cogsAccountCodeId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,

          message: lang.populate(lang.nameAdded, [params.productName]),
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  const { request: accountMappingRequest, mappedData: defaultAccounts } = useApi({
    api: getAccountMappings,
    params: {
      businessId: venue.venueId,
    },
    isArray: true,
    mapper: accountMappingsResponse,
    handleOwnError: true,
  });

  useMount(async () => {
    await accountMappingRequest();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createProduct,
    });
  });

  const initialState = useMemo(() => {
    let obj = {};

    defaultAccounts?.forEach((account) => {
      if (account.itemType === AccountName.RevenueAccount) {
        obj = { ...obj, revenueAccountCodeId: { value: account.accountCodeId } };
      }
      if (account.itemType === AccountName.CostOfGoodsSold) {
        obj = { ...obj, cogsAccountCodeId: { value: account.accountCodeId } };
      }
    });
    return obj;
  }, [defaultAccounts]);

  return (
    <ProductForm
      title={lang.createNewProduct}
      submitting={loading}
      onSubmit={submitForm}
      initialState={initialState}
    />
  );
};

export default CreateProductForm;
