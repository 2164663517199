import React from "react";
import { Pie } from "react-chartjs-2";
import { SimpleTable } from "components/commons";

const chartColors = [
  "#fff8e1",
  "#ffecb3",
  "#ffe082",
  "#ffd54f",
  "#ffca28",
  "#ffc107",
  "#ffb300",
  "#ffa000",
  "#ff8f00",
  "#ff6f00",
];

const PieChart = ({ formatToolTipValue, data, labels, legendName, legendValue, className }) => {
  return (
    <div className={className}>
      <Pie
        data={{
          maintainAspectRatio: true,
          responsive: true,
          labels,
          datasets: [
            {
              data,
              backgroundColor: chartColors,
              hoverBackgroundColor: chartColors,
            },
          ],
        }}
        options={{
          legend: {
            display: false,
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          tooltips: {
            displayColors: false,
            bodyAlign: "center",
            titleAlign: "center",
            bodySpacing: 3,
            bodyFontSize: 15,
            titleFontSize: 12,
            xPadding: 12,
            yPadding: 12,
            callbacks: {
              title: (legend, { labels }) => {
                const index = legend[0].index;
                return labels[index];
              },
              label: ({ index }, { datasets }) => {
                const d = datasets[0].data[index];
                if (formatToolTipValue) {
                  return formatToolTipValue(d);
                }
                return d;
              },
            },
          },
        }}
      />
      <SimpleTable
        className="mt-lg"
        columns={[
          {
            text: legendName,
            key: "name",
            className: "py-sm",
            headerClassName: "text-left capitalize font-bold",
          },
          {
            text: legendValue,
            key: "value",
            className: "align-right text-right py-sm",
            headerClassName: "text-right capitalize font-bold",
          },
        ]}
        data={labels.map((l, i) => {
          const value = () => {
            if (formatToolTipValue) {
              return formatToolTipValue(data[i]);
            }
            return data[i];
          };
          return {
            name: (
              <div className="flex text-sm">
                <span
                  className="flex mr-sm text-left"
                  style={{
                    background: chartColors[i],
                    width: "20px",
                    height: "20px",
                    minWidth: "20px",
                  }}
                ></span>
                {l}
              </div>
            ),
            value: value(),
          };
        })}
      />
    </div>
  );
};

export default PieChart;
