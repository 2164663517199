const venueState = {
  currencyCode: "",
  currencyDecimals: "",
  currencySymbol: "",
  currencySymbolPosition: "",
  depositAmount: null,
  depositName: null,
  isRequired: null,
  keepWristbandAllowed: null,
  postPaidBillingLimit: null,
  status: "",
  taxPercentage: 0,
  topupCreditLimit: null,
  venueId: 0,
  venueName: "",
  downloading: false
};

export default venueState;
