import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { FragmentA } from "components/fragments";
import { SearchOutlined } from "@ant-design/icons";
import {
  Field,
  Panel,
  ActionButton,
  Toast,
  Form,
  InputCounter,
  MultipleSelect,
  Text,
  ButtonLink,
  Skeleton,
  Input,
  Button,
  CategoryProductsTable,
  Icon,
} from "components/commons";
import { StyleType } from "enums";
import { ModuleWrapper } from "components/fragments";
import { useApi, useForm, useModal, useRouter } from "hooks";
import initialFormState from "./printer.form-state";
import { VenueContext } from "contexts";
import { Divider } from "antd";
import { getLocations, searchProduct } from "apis";
import { mapObject, mapObjectsToSelect } from "services";
import {
  allLocationRequest,
  location,
  browseProductCategoryListRequest,
  browseProductCategoryListResponse,
} from "mappers";
import classnames from "classnames";
import styles from "./printer-form.module.scss";
import { BrowseProductModal } from "components/modals";
import { NoLocationModal } from "components/modals";
import { mixpanel, TrackEvent } from "mixpanel";

const PrinterForm = ({
  submit,
  title,
  submitting,
  initialState = undefined,
  loading,
  deletePrinterButton,
  linkedProductsMap,
  linkedProductsLoaded = true,
}) => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const [printableProducts, setPrintableProducts] = useState();
  const [requiredPrintableProducts, setRequiredPrintableProducts] = useState();
  const [requiredLocation, setRequiredLocation] = useState();
  const [browseProductDisabled, setBrowseProductDisabled] = useState(false);
  const [hasLocationsSelected, setHasLocationsSelected] = useState(false);

  const unsaveChangesModal = useModal();
  const browseProductModal = useModal();
  const noLocationModal = useModal();

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty, setPristine } =
    useForm({
      initialState: formState,
    });

  const {
    request: getLocationsRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: getLocations,
    isArray: true,
    mapper: location,
  });

  const {
    request: searchProductRequest,
    loading: loadingProduct = true,
    mappedData: mappedProducts,
  } = useApi({
    api: searchProduct,
    isArray: true,
    mapper: browseProductCategoryListResponse,
    paramsMapper: browseProductCategoryListRequest,
  });

  useEffect(() => {
    if (linkedProductsLoaded) {
      fetchLocations();
      fetchProducts([]);
    }
    // eslint-disable-next-line
  }, [linkedProductsLoaded]);

  useEffect(() => {
    if (linkedProductsMap) setPrintableProducts(linkedProductsMap);
  }, [linkedProductsMap]);

  useEffect(() => {
    if (loadingLocation === false) {
      if (locations.length === 0) {
        noLocationModal.show();
      }
    }
    // eslint-disable-next-line
  }, [locations]);

  const removeProductsThatAreNoLongerAvailable = useCallback(
    (mappedProducts, printableProducts) => {
      const formValues = getFormValues();
      if (mappedProducts && mappedProducts.length > 0) {
        formValues?.productSkuIds.forEach((productSkuId) => {
          var forRemoval = true;
          mappedProducts.forEach((product) => {
            if (product.productSkus) {
              product.productSkus.forEach((productSku) => {
                if (productSku.productSkuId === productSkuId) {
                  forRemoval = false;
                }
              });
            }
          });
          if (forRemoval && printableProducts) {
            printableProducts.forEach((productMap) => {
              productMap.forEach((value, key) => {
                if (value && value.indexOf(productSkuId) > -1) {
                  productMap.delete(key);
                }
              });
            });
            const newPrintableProducts = new Map(printableProducts);
            setPrintableProducts(newPrintableProducts);
            modifyField("productSkuIds", {
              value: getSelectedProductSkuIds(newPrintableProducts),
            });
            for (let product of printableProducts.values()) {
              product.size <= 0 && printableProducts.size <= 1
                ? setRequiredPrintableProducts(true)
                : setRequiredPrintableProducts(false);
            }
          }
        });
      } else {
        const newPrintableProducts = new Map();
        setPrintableProducts(newPrintableProducts);
        modifyField("productSkuIds", { value: getSelectedProductSkuIds(newPrintableProducts) });
        if (printableProducts) {
          for (let product of printableProducts.values()) {
            product.size <= 0 && printableProducts.size <= 1
              ? setRequiredPrintableProducts(true)
              : setRequiredPrintableProducts(false);
          }
        }
      }
    },
    [getFormValues, modifyField]
  );

  useEffect(() => {
    if (!loadingProduct && hasLocationsSelected && linkedProductsLoaded) {
      if (fields?.productSkuIds?.value.length > 0 && printableProducts) {
        removeProductsThatAreNoLongerAvailable(mappedProducts, printableProducts);
      }
    }
  }, [
    mappedProducts,
    removeProductsThatAreNoLongerAvailable,
    hasLocationsSelected,
    printableProducts,
    linkedProductsLoaded,
    loadingProduct,
    fields,
  ]);

  const fetchLocations = useCallback(
    (requestState) => {
      getLocationsRequest(
        mapObject({ ...requestState, venueId: venue.venueId }, allLocationRequest)
      );
    },
    [getLocationsRequest, venue]
  );

  const fetchProducts = useCallback(
    async (value) => {
      const res = await searchProductRequest({
        venueId: venue.venueId,
        locations: value,
        searchKey: "",
      });

      setBrowseProductDisabled(res.data && res.data.length === 0);
    },
    [searchProductRequest, venue]
  );

  const locationOptions = useMemo(() => {
    if (locations) {
      return mapObjectsToSelect(locations, { textKey: "name" });
    }
    return [];
  }, [locations]);

  const removePrintableProduct = useCallback(
    (productName) => {
      printableProducts.forEach((productMap) => {
        if (productMap.has(productName)) {
          productMap.delete(productName);
        }
      });
      const newPrintableProducts = new Map(printableProducts);
      setPrintableProducts(newPrintableProducts);
      modifyField("productSkuIds", { value: getSelectedProductSkuIds(newPrintableProducts) });
      for (let product of printableProducts.values()) {
        product.size <= 0
          ? setRequiredPrintableProducts(true)
          : setRequiredPrintableProducts(false);
      }
    },
    [printableProducts, modifyField]
  );

  const removePrintableCategory = useCallback(
    (categoryName) => {
      if (printableProducts.has(categoryName)) {
        printableProducts.delete(categoryName);
      }
      const newPrintableProducts = new Map(printableProducts);
      setPrintableProducts(newPrintableProducts);
      modifyField("productSkuIds", { value: getSelectedProductSkuIds(newPrintableProducts) });
      printableProducts.size <= 0
        ? setRequiredPrintableProducts(true)
        : setRequiredPrintableProducts(false);
    },
    [printableProducts, modifyField]
  );

  const goToList = useCallback(() => {
    history.push(Path.PRINTER);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const handleSubmit = useCallback(async () => {
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.savePrinterProfileForm,
        Page: lang.editPrinter,
      });

      const params = getFormValues();
      if (params.productSkuIds.length === 0) {
        setRequiredPrintableProducts(true);
        return;
      }

      if (params.locations.length === 0) {
        params.locations = locationOptions;
      }

      const res = await submit({ ...params, venueId });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.PRINTER);
    } catch ({ code, handleError }) {
      const err = {
        3070: () => {
          applyFieldErrors({
            name: lang.printerProfileNameAlreadyExists,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [submit, getFormValues, venueId, history, applyFieldErrors, locationOptions]);

  const validateSelectedProducts = () => {
    const formValues = getFormValues();
    if (formValues?.productSkuIds.length === 0) {
      setRequiredPrintableProducts(true);
    }
    if (formValues?.productSkuIds.length > 0) {
      setRequiredPrintableProducts(false);
    }
  };

  const submitFormValue = () => {
    validateSelectedProducts();
    if (!requiredPrintableProducts) {
      submitForm(handleSubmit);
    }
  };

  const handleEditLocations = () => {
    const win = window.open("/location", "_blank");
    win.focus();
  };

  const showBrowseProductCb = useCallback(() => {
    browseProductModal.show();
  }, [browseProductModal]);

  return (
    <ModuleWrapper width="medium">
      <BrowseProductModal
        {...browseProductModal}
        locations={fields.locations.value}
        preSelectedSkus={fields.productSkuIds.value}
        onAdd={(v) => {
          setPrintableProducts(v);
          setRequiredPrintableProducts(false);
          modifyField("productSkuIds", { value: getSelectedProductSkuIds(v) });
        }}
      />
      <NoLocationModal content={lang.toContinueCreatingProducts} {...noLocationModal} />
      <HeaderB title={title} returnText={lang.printers} onClick={leavePage} />
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue}>
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <Field className="mb-md" {...fields.name} noLabel>
              <InputCounter {...fields.name} onChange={modifyField} />
            </Field>
            <Field className="mb-md" {...fields.description} noLabel>
              <InputCounter {...fields.description} textarea onChange={modifyField} />
            </Field>
          </Panel>
        </FragmentA>
        <FragmentA title={lang.location} description={lang.printerLocationsDesc}>
          <Panel>
            <div>
              <div className="flex justify-between pb-md">
                <Text strong size="text-sm">
                  {lang.location}
                </Text>
                <ButtonLink
                  size="text-sm"
                  onClick={() => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      Button: lang.openWindow,
                      Page: lang.editPrinter,
                    });
                    handleEditLocations();
                  }}
                >
                  {lang.editLocations}{" "}
                  <Icon name="arrow-diagonal-right" className="text-blue text-xxs ml-xs" />
                </ButtonLink>
              </div>
              <Field className="mb-sm" {...fields.locations} noLabel>
                <MultipleSelect
                  {...fields.locations}
                  name="locations"
                  selectAllText={lang.allLocations}
                  loading={loadingLocation}
                  options={locationOptions}
                  onClick={() => {
                    setHasLocationsSelected(true);
                    fetchLocations();
                  }}
                  onChange={(name, obj) => {
                    setRequiredLocation(!obj.isAll && !obj.value);
                    let isDirty = dirty;
                    modifyField(name, { value: obj.value });
                    if (obj.isAll && !isDirty) {
                      setHasLocationsSelected(false);
                      setPristine();
                    }
                    if (obj.value !== null) {
                      fetchProducts(obj.value);
                    } else if (obj.value === null) {
                      setBrowseProductDisabled(true);
                      modifyField("productSkuIds", { value: [] });
                      modifyField(name, { value: [] });
                      setPrintableProducts(null);
                    }
                  }}
                  placeholder={
                    !locationOptions.length ? lang.noLocationAvailable : lang.selectLocation
                  }
                  error={requiredLocation}
                />
              </Field>
            </div>
          </Panel>
        </FragmentA>
        <FragmentA title={lang.printableItems} description={lang.printableItemsSelect}>
          {
            <Panel>
              {loading ? (
                <Skeleton width="100%" active />
              ) : (
                <div>
                  <Field className="mb-sm" {...fields.productSkuIds} noLabel>
                    <div
                      className="flex"
                      onClick={() => {
                        if (!browseProductDisabled) {
                          mixpanel.track(TrackEvent.ClickedButton, {
                            Button: lang.browseProducts,
                            Page: lang.editPrinter,
                          });
                          showBrowseProductCb();
                        }
                      }}
                    >
                      <Input
                        disabled={loadingProduct || browseProductDisabled}
                        className={classnames("rounded-r-none", styles.browseInput, {
                          [`${styles.browseInputError}`]: requiredPrintableProducts,
                        })}
                        placeholder={
                          browseProductDisabled
                            ? lang.noProductsAvailable
                            : lang.selectCategoriesOrProducts
                        }
                        iconPrefix={<SearchOutlined className="mr-sm" />}
                        readOnly
                      />
                      <Button
                        loading={loadingProduct}
                        disabled={loadingProduct || browseProductDisabled}
                        type={requiredPrintableProducts ? StyleType.Danger : StyleType.Secondary}
                        className={classnames(
                          "rounded-l-none bg-gradient-to-b from-white to-white-dark",
                          { [`${styles.browseButton}`]: !requiredPrintableProducts },
                          `${requiredPrintableProducts ? "border-red" : "border-white-darker"}`
                        )}
                      >
                        {lang.browseProducts}
                      </Button>
                    </div>
                  </Field>
                  <CategoryProductsTable
                    linkedProducts={printableProducts}
                    required={requiredPrintableProducts}
                    removeProduct={removePrintableProduct}
                    removeCategory={removePrintableCategory}
                  />
                </div>
              )}
            </Panel>
          }
        </FragmentA>
        <Divider />
        <ActionButton
          loading={submitting}
          primary={{
            onClick: () => {
              submitFormValue();
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
          danger={deletePrinterButton}
        />
      </Form>
    </ModuleWrapper>
  );
};

const getSelectedProductSkuIds = (v) => {
  const selectedProductSkusSet = new Set();

  v.forEach((productMap) => {
    productMap.forEach((productSkuIds) => {
      productSkuIds.forEach((pskuId) => {
        selectedProductSkusSet.add(pskuId);
      });
    });
  });

  return [...selectedProductSkusSet];
};

export default PrinterForm;
