import { Form, Toast, ActionButton } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import { Path } from "paths";
import React, { useMemo, useContext, useCallback } from "react";
import lang from "translations";
import initialFormState from "./staff-form-state";
import { useApi, useFilter, useForm, useModal, useMount, useRouter } from "hooks";
import { VenueContext } from "contexts";
import StaffFormFields from "./staff-form-fields";
import { searchPaymentMethod } from "apis/payment-method.api";
import { searchLocation } from "apis/location.api";
import { StaffRole } from "enums";
import { NoLocationModal } from "components/modals";
import { searchStaffProfile } from "apis/staff-profile.api";
import { mixpanel, TrackEvent } from "mixpanel";

const StaffForm = ({
  title,
  loading,
  formTitle,
  initialState = undefined,
  submit,
  submitting,
  emailBottomRender,
  isAwait,
  danger,
  isEdit,
  basicInfoRender,
  error,
}) => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const unsaveChangesModal = useModal();
  const noLocationModal = useModal();

  const requestStaffProfile = useApi({
    api: searchStaffProfile,
    isArray: true,
    mapper: {
      value: {
        key: "staffProfileId",
      },
      text: {
        key: "profileName",
      },
    },
  });

  const { filterState } = useFilter({
    searchKey: "",
    page: 1,
    pageSize: 20,
    venueId,
    orders: {
      "sp.staffProfileId": "asc",
    },
  });

  const {
    fields,
    modifyField,
    modifyForm,
    submitForm,
    getFormValues,
    applyFieldErrors,
    setPristine,
    dirty,
  } = useForm({
    initialState: formState,
  });

  const requestLocations = useApi({
    api: searchLocation,
    params: {
      venueId,
      page: 1,
      pageSize: null,
    },
    isArray: true,
    mapper: {
      text: {
        key: "locationName",
      },
      value: {
        key: "locationId",
      },
    },
  });

  const requestPaymentMethod = useApi({
    api: searchPaymentMethod,
    params: {
      venueId,
    },
    isArray: true,
    mapper: {
      text: {
        key: "paymentMethodName",
      },
      value: {
        key: "paymentMethodId",
      },
    },
  });

  const goToList = useCallback(() => {
    history.push(Path.STAFF);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    if (!params.role.length) {
      params.role = [
        {
          value: StaffRole.Manager,
        },
      ];
    }
    if (!params.paymentMethod?.length || !params.paymentMethod) {
      params.paymentMethod = requestPaymentMethod.mappedData;
    }
    if (!params.location?.length || !params.location) {
      params.location = requestLocations.mappedData;
    }
    try {
      mixpanel.track(TrackEvent.Clickedbutton, {
        Button: lang.saveStaffForm,
      });
      const res = await submit({ ...params, venueId });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.STAFF);
    } catch ({ code, handleError }) {
      const err = {
        3035: () => {
          applyFieldErrors({
            email: lang.accountAlreadyExists,
          });
        },
        3027: () => {
          applyFieldErrors({
            email: lang.accountAlreadyExists,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [
    submit,
    getFormValues,
    venueId,
    history,
    applyFieldErrors,
    requestLocations.mappedData,
    requestPaymentMethod.mappedData,
  ]);

  const submitFormCb = useCallback(() => {
    submitForm(handleSubmit);
  }, [handleSubmit, submitForm]);

  useMount(() => {
    requestLocations.request();
    requestPaymentMethod.request();
    requestStaffProfile.request(filterState);
  });

  return (
    <ModuleWrapper
      error={error}
      header={<HeaderB returnText={lang.staff} title={title} onClick={leavePage} />}
    >
      <NoLocationModal content={lang.toContinueCreatingStaff} {...noLocationModal} />
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormCb}>
        <StaffFormFields
          noLocationModal={noLocationModal}
          formTitle={formTitle}
          fields={fields}
          modifyField={modifyField}
          modifyForm={modifyForm}
          loading={loading}
          requestPaymentMethod={requestPaymentMethod}
          requestLocations={requestLocations}
          requestStaffProfile={requestStaffProfile}
          emailBottomRender={emailBottomRender}
          isAwait={isAwait}
          isEdit={isEdit}
          basicInfoRender={basicInfoRender}
          setPristine={setPristine}
          dirty={dirty}
        />
        <ActionButton
          showLine
          loading={submitting}
          danger={danger}
          primary={
            isAwait
              ? null
              : {
                  disabled: submitting,
                  onClick: () => {
                    submitFormCb();
                  },
                }
          }
          secondary={
            isAwait
              ? null
              : {
                  onClick: () => {
                    leavePage();
                  },
                  text: lang.cancel,
                }
          }
        />
      </Form>
    </ModuleWrapper>
  );
};

export default StaffForm;
