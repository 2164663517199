import { mapObjects } from "services";
import { product } from "mappers";
import { StocktakeStatus, TransactionType } from "enums";
import { dateTimeRequest } from "./common.mapper";

export const location = {
  name: {
    key: "locationName",
  },
  id: {
    key: "locationId",
  },
  noOfTables: {
    transform: ({ src }) => {
      return src.totalTable === 0 ? null : src.totalTable;
    },
  },
  onlineOrderTables: {
    key: "onlineOrderTables",
  },
  outletId: {
    key: "outletId"
  }
};

export const locationRequest = {
  locationName: {
    key: "name",
  },
  venueId: {
    key: "venueId",
  },
  locationId: {
    key: "id",
  },
  onlineOrderTables: {
    key: "onlineOrderTables",
  },
  outletId: {
    key: "outletId"
  }
};

export const locationProducts = {
  products: {
    transform: ({ self }) => {
      return mapObjects(self, product);
    },
  },
  sharedProducts: {
    transform: ({ self }) => {
      return mapObjects(self, product);
    },
  },
  productsToBeDeletedCount: {
    transform: ({ src }) => {
      return src.products?.length || 0;
    },
  },
  sharedProductsCount: {
    transform: ({ src }) => {
      return src.sharedProducts?.length || 0;
    },
  },
};

export const locationWithTransactionFilterRequest = {
  venueId: { key: "venueId" },
  transactionTypes: [],
  ...dateTimeRequest,
};

export const locationMovementFilterRequest = {
  ...locationWithTransactionFilterRequest,
  transactionTypes: [TransactionType.ISSUE, TransactionType.REDEEM, TransactionType.REMOVE],
};

export const locationWithTransactionFilterResponse = {
  id: { key: "locationId" },
  name: {
    transform: ({ src }) => {
      return src.locationName + (src.deleted ? " - deleted" : "");
    },
  },
};

export const allLocationRequest = {
  // page: 1,
  // pageSize: 50000,
  venueId: { key: "venueId" },
  orders: {
    [`l.locationName`]: "asc",
  },
};

export const locationWithStocktakeStatus = {
  name: {
    transform: ({ src }) => {
      return (
        src.locationName +
        (src.status === StocktakeStatus.InProgress || src.status === StocktakeStatus.ForApproval
          ? " (Ongoing Stocktake)"
          : "")
      );
    },
  },
  id: {
    key: "locationId",
  },
  disabled: {
    transform: ({ src }) => {
      if (src.status === StocktakeStatus.InProgress || src.status === StocktakeStatus.ForApproval) {
        return true;
      }
      return false;
    },
  },
};
