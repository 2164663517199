import React, { useContext, useState } from "react";
import { ActionButton, Modal, RadioGroup, Text } from "components";
import lang from "translations";
import { VenueContext } from "contexts";
import { useApi } from "hooks";
import { generateQRCodeLocation, generateQRCodePerLocation } from "apis/location.api";

const GenerateCodeModal = ({ id, noOfTables, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(true);
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const { request: requestGenerateQRLocation } = useApi({
    api: generateQRCodeLocation,
  });

  const { request: requestGenerateQRPerTable } = useApi({
    api: generateQRCodePerLocation,
  });

  return (
    <Modal
      title={lang.qrCodeForOnlineMenu}
      {...props}
      actionContent={
        <ActionButton
          className="px-md pb-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              props.close();
            },
          }}
          primary={{
            text: lang.generateAndDownload,
            onClick: async () => {
              if (selectedOption) {
                await requestGenerateQRLocation({ locationId: id, venueId: venueId });
              } else {
                await requestGenerateQRPerTable({ locationId: id, venueId: venueId });
              }
              setSelectedOption(true);
              props.close();
            },
          }}
        />
      }
    >
      <Text>{lang.generateQRDescription}</Text>
      {noOfTables > 0 && (
        <RadioGroup
          options={[
            { text: lang.locationQRCode, value: true },
            { text: lang.tableQRCode, value: false },
          ]}
          onChange={(value) => {
            setSelectedOption(value);
          }}
          value={selectedOption}
        />
      )}
    </Modal>
  );
};

export default GenerateCodeModal;
