import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const { firstName = "", lastName = "", email = "" } = initialState;
  return {
    firstName: {
      name: "firstName",
      value: firstName,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      placeholder: "John",
      label: lang.firstName,
    },
    lastName: {
      name: "lastName",
      value: lastName,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      placeholder: "Appleseed",
      label: lang.lastName,
    },
    email: {
      name: "email",
      value: email,
      type: Field.INPUT,
      validations: [Validation.required(), Validation.emailAddress()],
      required: true,
      placeholder: "sample@website.com",
      label: lang.emailAddress,
      maxLength: 75,
    },
  };
};

export default initialFormState;
