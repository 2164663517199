import { AccountType, Field } from "enums";
import { capitalize } from "services";
import { formatNumberToMoney } from "services/money.service";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    productLogo,
    productName,
    description,
    category = [],
    revenueAccountCodeId,
    cogsAccountCodeId,
    activePos = true,
    activeOnlineMenu = false,
    location = [],
    inventoryType,
    sku,
    generatedSku = true,
    hasTax,
    supplyCost = 0,
    markUp = "0.00",
    retailPrice = "0.00",
    tax,
    products,
  } = initialState;

  return {
    productLogo: {
      name: "productLogo",
      image: undefined,
      file: "",
      type: Field.ANY,
      value: productLogo,
      ...productLogo,
    },
    productName: {
      ...defaultFields,
      name: "productName",
      label: lang.productSetName,
      placeholder: lang.nameYourProductSet,
      validations: [Validation.required()],
      required: true,
      value: productName,
      ...productName,
    },
    description: {
      ...defaultFields,
      name: "description",
      label: lang.description,
      placeholder: lang.describeThisProductSet,
      maxLength: 255,
      value: description,
      ...description,
    },
    category: {
      name: "category",
      label: capitalize(lang.category),
      placeholder: lang.selectToIncludeProductSetInCategory,
      type: Field.ANY,
      selectAllText: lang.allCategories,
      isAll: false,
      value: category,
      emptyPlaceHolder: lang.noAvailableCategories,
      ...category,
    },
    revenueAccountCodeId: {
      name: "revenueAccountCodeId",
      label: lang.revenueAccount,
      value: revenueAccountCodeId,
      type: Field.ANY,
      placeholder: lang.revenueAccountPlaceholder,
      suggested: AccountType.Asset,
      ...revenueAccountCodeId,
    },
    cogsAccountCodeId: {
      name: "cogsAccountCodeId",
      label: lang.costOfGoodsSold,
      value: cogsAccountCodeId,
      type: Field.ANY,
      placeholder: lang.costOfGoodsSoldPlaceholder,
      suggested: AccountType.Asset,
      ...cogsAccountCodeId,
    },
    activePos: {
      name: "activePos",
      type: Field.CHECKBOX,
      label: lang.activateOnPos,
      value: activePos,
      ...activePos,
    },
    activeOnlineMenu: {
      name: "activeOnlineMenu",
      type: Field.CHECKBOX,
      label: lang.activateOnOnlineMenu,
      value: activeOnlineMenu,
      ...activeOnlineMenu,
    },
    location: {
      name: "location",
      label: lang.locations,
      placeholder: lang.chooseAtleast1Location,
      type: Field.ANY,
      selectAllText: lang.allLocations,
      value: location,
      emptyPlaceHolder: lang.noAvailableLocationsYet,
      isAll: false,
      validations: [Validation.requiredMultiDropdown(lang.pleaseSelectLoction)],
      required: true,
      ...location,
    },
    inventoryType: {
      name: "creationType",
      type: Field.ANY,
      value: "PRODUCT_SET",
      ...inventoryType,
    },
    generatedSku: {
      name: "generatedSku",
      type: Field.CHECKBOX,
      value: generatedSku,
      ...generatedSku,
    },
    sku: {
      name: "sku",
      ...defaultFields,
      required: false,
      validations: [Validation.minlength(4)],
      maxLength: 16,
      uppercase: true,
      value: sku,
      ...sku,
    },
    hasTax: {
      ...defaultFields,
      name: "hasTax",
      type: Field.CHECKBOX,
      value: hasTax,
      ...hasTax,
    },
    supplyCost: {
      ...defaultFields,
      value: formatNumberToMoney(supplyCost),
      name: "supplyCost",
      label: lang.supplyCost,
      placeholder: "0",
      right: true,
      disabled: true,
      ...supplyCost,
    },
    markUp: {
      ...defaultFields,
      name: "markUp",
      label: lang.markUp,
      right: true,
      value: markUp,
      validations: [Validation.maxValue(99999999.99, null, true)],
      ...markUp,
    },
    retailPrice: {
      ...defaultFields,
      value: formatNumberToMoney(retailPrice),
      name: "retailPrice",
      label: lang.retailPrice,
      right: true,
      validations: [
        Validation.maxValue(99999999.99, null, true),
        Validation.minValue(0, lang.retailPriceMustNotBeNegative),
      ],
      ...retailPrice,
    },
    tax: {
      ...defaultFields,
      name: "tax",
      value: tax,
      placeholder: lang.selectTax,
      ...tax,
    },
    products: {
      isFormArray: true,
      type: Field.ANY,
      value: [ProductField],
      name: "products",
      required: true,
      validations: [Validation.minArrayValues(2)],
      ...products,
    },
  };
};

const defaultFields = {
  value: "",
  type: Field.INPUT,
  maxLength: 75,
};

export const CreateProductField = ({
  productSkuId,
  itemName,
  supplyPrice,
  price,
  quantity,
  type,
  creationType,
  measurement,
}) => {
  return {
    ...ProductField,
    value: {
      ...ProductField.value,
      productSkuId: {
        ...ProductField.value.productSkuId,
        ...productSkuId,
      },
      itemName: {
        ...ProductField.value.itemName,
        ...itemName,
      },
      quantity: {
        ...ProductField.value.quantity,
        ...quantity,
      },
      supplyPrice: {
        ...ProductField.value.supplyPrice,
        ...supplyPrice,
      },
      price: {
        ...ProductField.value.price,
        ...price,
      },
      type: {
        ...type,
      },
      creationType: {
        ...creationType,
      },
      measurement: {
        ...measurement,
      },
    },
  };
};

export const ProductField = {
  type: Field.ANY,
  isFormGroup: true,
  value: {
    productSkuId: {
      name: "productSkuId",
    },
    itemName: {
      ...defaultFields,
      name: "itemName",
      required: true,
      validations: [Validation.required()],
      placeholder: "",
      value: null,
      type: Field.DROPDOWN,
      pristine: "true",
      error: false,
    },
    quantity: {
      name: "quantity",
      value: null,
      right: true,
      pristine: "true",
      required: true,
      type: Field.INPUT,
      validations: [Validation.greaterThanZero()],
    },
    supplyPrice: {
      ...defaultFields,
      name: "supplyPrice",
      value: null,
      disabled: true,
      right: true,
      pristine: "true",
    },
    price: {
      ...defaultFields,
      name: "price",
      value: null,
    },
    measurement: null,
  },
};

export default initialFormState;
