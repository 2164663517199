import React from "react";
import { PageHeader, Container, PageFooter } from "components/commons";
import classnames from "classnames";

const LayoutC = ({ children, padding, logoRedirectUrl, hasUserNav = true }) => {
  return (
    <div className={classnames("flex flex-col h-full bg-gray-lightest")}>
      <PageHeader hasUserNav={hasUserNav} logoRedirectUrl={logoRedirectUrl} notSubscribed={false} />
      <Container
        padding={padding}
        className={classnames("flex justify-center items-center flex-grow")}
      >
        {children}
      </Container>
      <PageFooter />
    </div>
  );
};

export default LayoutC;
