import { default as Slide1 } from './images/slide1.png';
import { default as Avatar1 } from './images/avatar1.png';
import { default as Slide2 } from './images/slide2.png';
import { default as Avatar2 } from './images/avatar2.png';
import { default as Slide3 } from './images/slide3.png';
import { default as Avatar3 } from './images/avatar3.png';

const SliderRightSideData = [
  {
    header: {
      image: Slide1,
      description: { place: 'Fat Coco Beach Club', country: 'Thailand' },
    },
    body: 'On behalf of A-ONE Hotels, I thank you, everyone, involved in the speedy implementation of PouchNATION’s system in Fat Coco. The result was very good and efficient.',
    highlight: 'A-ONE Hotels',
    footer: {
      avatar: Avatar1,
      name: 'A-One Management (Fat Coco)',
      description: '',
      place: 'Thailand',
    },
  },
  {
    header: {
      image: Slide2,
      description: { place: 'El Rio Hostel', country: 'Colombia' },
    },
    body: 'Extremely responsive team and very attentive customer service. The system itself has also cut out a large amount of loss from our sales in the bar and restaurant as it removes the space for human error almost completely. So far so good, really happy. Many thanks, Ben and the El Rio Hostel crew.',
    highlight: 'So far so good, really happy.',
    footer: {
      avatar: Avatar2,
      name: 'El Rio Hostel',
      description: '',
      place: 'Thailand',
    },
  },
  {
    header: {
      image: Slide3,
      description: { place: 'Siembra Hostels', country: 'Colombia' },
    },
    body: 'PouchNATION was one of the best business decisions we made when first opening our hostel. It makes the sales and inventory management process so easy, and our guests love it, because well, it’s super cool, high tech, and simple to use.',
    highlight: 'one of the best',
    footer: {
      avatar: Avatar3,
      name: 'Siembra Hostels',
      description: '',
      place: 'Minca, Colombia',
    },
  },
];

export default SliderRightSideData;
