import { Panel, Text, Title, Icon, ButtonLink, Skeleton, AlertMessage } from "components";
import React from "react";
import lang from "translations";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import classnames from "classnames";
import { AlertType } from "enums";

const Row = ({ label, children, custom, style = {}, isValueBreakdown = false }) => {
  const { fontSize, danger } = style;
  return (
    <div className={classnames("flex justify-between align-center items-center mb-xs")}>
      <Text className="text-gray" strong size="text-sm">
        {label}
      </Text>
      <div>
        {custom ? (
          children
        ) : (
          <Text
            className={classnames({
              "text-gray-400": isValueBreakdown,
              "text-gray-500": !isValueBreakdown,
            })}
            fontWeight="font-bold"
            size={fontSize}
            danger={danger}
          >
            {children}
          </Text>
        )}
      </div>
    </div>
  );
};

const ShiftSummary = ({ data = {}, loading }) => {
  const {
    totalSale,
    totalCreditsUsed,
    totalFreeCreditsUsed,
    totalPostpaidUsed,
    totalSaleCash,
    totalSaleCard,
    totalSaleOthers,
    totalTax,
    totalTopup,
    topUpMethodTotals = {},
    totalRefunded,
    totalTransactions,
    totalGuestsCheckedIn,
    totalGuestsCheckedOut,
    totalVoidedItems,
    totalVouchersIssued,
    totalVouchersRedeemed,
    totalVoidValue,
    totalVoidValuePouchPay,
    totalVoidValueCash,
    totalVoidValueCard,
    totalVoidValueOthers,
    totalDiscount,
    totalFreeCreditsIssued,
    totalFreeCreditsRemoved,
    totalIssuedTag,
    totalReturnedTag,
    totalClearedTag,
    shiftEnd,
    shiftStart,
    totalVouchersRemoved,
    totalVouchersRedeemedRetailValue,
    totalVouchersRedeemedSupplyValue,
    closeBalance,
    expectedCloseBalance,
    openBalance,
    hasDiscrepancy,
    discrepancy,
    notes,
    hasTransactions,
    /*
    newly added:
    // totalDefaultTax,
    // totalCustomTax,
    // totalNewGuest,
    // totalReturningGuest,
    // totalCheckOuts,
    // totalLostTag,
    // totalDefectiveTag,
    // totalOtherTag,
    // defaultTaxPercentage,
    // customTaxPercentage,
    */
    // defaultTaxPercentage = " (30%) ",
    // customTaxPercentage = " (3%) ",
  } = data?.summary || {};

  const { name } = data?.location || {};
  const divider = (
    <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
  );

  if (loading) {
    return (
      <Panel>
        <Skeleton />
      </Panel>
    );
  }

  return (
    <Panel>
      <div>
        {name && (
          <Title className="mb-md flex items-center" fontWeight="font-bold">
            <Icon name="vendor" className="text-gray mr-md" />
            {name || "-"}
          </Title>
        )}
        {shiftEnd ? (
          <>
            <p className="font-bold mb-sm">{lang.sales}</p>
            <Row label={lang.totalProductSales} style={{ fontSize: "font-md" }}>
              {totalSale ? totalSale : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>
            <div className="pl-lg">
              <Row label={lang.credits} isValueBreakdown={true}>
                {totalCreditsUsed ? totalCreditsUsed : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.freeCredits} isValueBreakdown={true}>
                {totalFreeCreditsUsed
                  ? totalFreeCreditsUsed
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.postpaidCreditsUsed} isValueBreakdown={true}>
                {totalPostpaidUsed ? totalPostpaidUsed : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.cash} isValueBreakdown={true}>
                {totalSaleCash ? totalSaleCash : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.creditDebit} isValueBreakdown={true}>
                {totalSaleCard ? totalSaleCard : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.others} isValueBreakdown={true}>
                {totalSaleOthers ? totalSaleOthers : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
            </div>

            {divider}

            <p className="font-bold mt-md mb-sm">{lang.taxes}</p>
            <Row label={lang.totalTax} style={{ fontSize: "font-md" }}>
              {totalTax ? totalTax : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>

            {/* <div className="pl-lg"> */}
            {/* <Row label={lang.defaultTax + defaultTaxPercentage} isValueBreakdown={true}> */}
            {/* temporary place holder 0 */}
            {/* {totalDefaultTax ? totalDefaultTax : formatNumberToMoneyWithCurrencySymbol(0)} */}
            {/* {formatNumberToMoneyWithCurrencySymbol(0)} */}
            {/* </Row> */}
            {/* <Row label={lang.customTax + customTaxPercentage} isValueBreakdown={true}> */}
            {/* temporary place holder 0 */}
            {/* {totalCustomTax ? totalCustomTax : formatNumberToMoneyWithCurrencySymbol(0)} */}
            {/* {formatNumberToMoneyWithCurrencySymbol(0)} */}
            {/* </Row> */}
            {/* <Row label={lang.customTax + customTaxPercentage} isValueBreakdown={true}> */}
            {/* temporary place holder 0 */}
            {/* {totalCustomTax ? totalCustomTax : formatNumberToMoneyWithCurrencySymbol(0)} */}
            {/* {formatNumberToMoneyWithCurrencySymbol(0)} */}
            {/* </Row> */}
            {/* </div> */}

            {divider}

            <p className="font-bold mt-md mb-sm">{lang.credits}</p>

            <Row label={lang.topupCredits} style={{ fontSize: "font-md" }}>
              {totalTopup ? totalTopup : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>

            <div className="pl-lg">
              <Row label={lang.cash} isValueBreakdown={true}>
                {topUpMethodTotals.cash
                  ? topUpMethodTotals.cash
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.debitCredit} isValueBreakdown={true}>
                {topUpMethodTotals.credit
                  ? topUpMethodTotals.credit
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.others} isValueBreakdown={true}>
                {topUpMethodTotals.other
                  ? topUpMethodTotals.other
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
            </div>
            <Row label={lang.refundedCredits} style={{ fontSize: "font-md" }}>
              {totalRefunded ? totalRefunded : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>

            {divider}

            <p className="font-bold mt-md mb-sm">{lang.transactions}</p>
            <Row label={lang.transactions} custom={true}>
              {hasTransactions ? (
                <ButtonLink
                  className="md:text-right"
                  prefix={false}
                  suffix
                  flex={false}
                  newTabPath={data.redirectUrl}
                >
                  {totalTransactions}{" "}
                  <Icon name="arrow-diagonal-right" className="text-blue text-xxs ml-xs" />
                </ButtonLink>
              ) : (
                totalTransactions
              )}
            </Row>
            <Row label={lang.voidedItems}>{totalVoidedItems ? totalVoidedItems : 0}</Row>
            {/* here */}
            <Row label={lang.voidedValue}>
              {totalVoidValue ? totalVoidValue : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>
            <div className="pl-lg">
              <Row label={lang.pouchPay} isValueBreakdown={true}>
                {totalVoidValuePouchPay
                  ? totalVoidValuePouchPay
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.cash} isValueBreakdown={true}>
                {totalVoidValueCash ? totalVoidValueCash : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.creditDebit} isValueBreakdown={true}>
                {totalVoidValueCard
                  ? totalVoidValueCard
                  : formatNumberToMoneyWithCurrencySymbol(500)}
              </Row>
              <Row label={lang.others} isValueBreakdown={true}>
                {totalVoidValueOthers
                  ? totalVoidValueOthers
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
            </div>
            <Row label={lang.discounts}>
              {totalDiscount ? totalDiscount : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>
            <Row label={lang.freeCreditsIssued}>
              {totalFreeCreditsIssued
                ? totalFreeCreditsIssued
                : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>
            <Row label={lang.freeCreditsRemoved}>
              {totalFreeCreditsRemoved
                ? totalFreeCreditsRemoved
                : formatNumberToMoneyWithCurrencySymbol(0)}
            </Row>
            <Row label={lang.vouchersIssued}>{totalVouchersIssued ? totalVouchersIssued : 0}</Row>
            <Row label={lang.vouchersRemoved}>
              {totalVouchersRemoved ? totalVouchersRemoved : 0}
            </Row>
            <Row label={lang.vouchersUsage}>
              {totalVouchersRedeemed ? totalVouchersRedeemed : 0}
            </Row>
            <div className="pl-md">
              <Row label={lang.voucherSupplyValue} isValueBreakdown={false}>
                {totalVouchersRedeemedSupplyValue
                  ? totalVouchersRedeemedSupplyValue
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
              <Row label={lang.voucherRetailValue} isValueBreakdown={false}>
                {totalVouchersRedeemedRetailValue
                  ? totalVouchersRedeemedRetailValue
                  : formatNumberToMoneyWithCurrencySymbol(0)}
              </Row>
            </div>

            {divider}

            <p className="font-bold mt-md mb-sm">{lang.guests}</p>
            <Row label={lang.checkIns}>{totalGuestsCheckedIn ? totalGuestsCheckedIn : 0}</Row>
            {/* <div className="pl-lg"> */}
            {/* <Row label={lang.newGuests} isValueBreakdown={true}> */}
            {/* temporary place holder 0 */}
            {/* {totalNewGuest ? totalNewGuest : 0} */}
            {/* {0} */}
            {/* </Row> */}
            {/* <Row label={lang.returningGuests} isValueBreakdown={true}> */}
            {/* totalReturnedTag was here before*/}
            {/* {totalReturnedTag ? totalReturnedTag : 0} */}

            {/* temporary place holder 0 */}
            {/* {totalReturningGuest ? totalReturningGuest : 0} */}
            {/* {0} */}
            {/* </Row> */}
            {/* </div> */}
            <Row label={lang.checkOuts}>
              {/* temporary place holder 0 */}
              {/* {totalCheckOuts ? totalCheckOuts : 0} */}
              {totalGuestsCheckedOut ? totalGuestsCheckedOut : 0}
            </Row>

            {divider}
            <p className="font-bold mt-md mb-sm">{lang.tags}</p>
            <Row label={lang.tagsIssued}>{totalIssuedTag ? totalIssuedTag : 0}</Row>
            <Row label={lang.returnedTags}>{totalReturnedTag ? totalReturnedTag : 0}</Row>
            <Row label={lang.clearedTags}>{totalClearedTag ? totalClearedTag : 0}</Row>
            {/* <Row label={lang.bannedTags}>{totalReturnedTag ? totalReturnedTag : 0}</Row> */}
            {/* <div className="pl-lg"> */}
            {/* <Row label={lang.lostTags} isValueBreakdown={true}> */}
            {/* temporary place holder 0 */}
            {/* {totalLostTag ? totalLostTag : 0} */}
            {/* {0} */}
            {/* </Row> */}
            {/* <Row label={lang.defectiveTags} isValueBreakdown={true}> */}
            {/* temporary place holder 0 */}
            {/* {totalDefectiveTag ? totalDefectiveTag : 0} */}
            {/* {0} */}
            {/* </Row> */}
            {/* <Row label={lang.others} isValueBreakdown={true}> */}
            {/* temporary place holder 0 */}
            {/* {totalOtherTag ? totalOtherTag : 0} */}
            {/* {0} */}
            {/* </Row> */}
            {/* </div> */}

            {divider}
            <p className="font-bold mt-md mb-sm">{lang.shift}</p>
            <Row label={lang.shiftStart}>{shiftStart}</Row>
            <Row label={lang.shiftEnd}>{shiftEnd}</Row>
            <Row label={lang.openingBalance}>{openBalance}</Row>
            <Row label={lang.expectedEndingBalance}>{expectedCloseBalance}</Row>
            <Row label={lang.actualClosingBalance}>{closeBalance}</Row>
            {hasDiscrepancy && (
              <Row
                label={lang.discrepancy}
                style={{
                  danger: true,
                }}
              >
                ({discrepancy})
              </Row>
            )}
            {notes && (
              <div className="mt-lg flex">
                <div className="mr-lg">
                  <Icon name="double-quote" className="text-gray-lighter" fontSize="40px" />
                </div>
                <div>
                  <Text className="text-gray mb-md" uppercase strong size="text-xs">
                    {lang.notes}
                  </Text>
                  <Text italic>{notes}</Text>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <p className="font-bold mt-md mb-sm">{lang.shift}</p>
            <Row label={lang.shiftStart}>{shiftStart}</Row>
            <Row label={lang.openingBalance}>{openBalance}</Row>
            <AlertMessage
              type={AlertType.Warning}
              banner
              className="mt-md"
              customMessage={
                <div className="flex gap-2 items-center py-xs">
                  <Icon name="info" className="text-md text-yellow" />
                  <Text>{lang.theShiftAndTransactionDetails}</Text>
                </div>
              }
            />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default ShiftSummary;
