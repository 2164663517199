import React from "react";
import { Checkbox, Text } from "..";
import classnames from "classnames";
import styles from "./field.module.scss";

const CheckboxField = ({ children, className, textSize = "text-md", ...props }) => {
  return (
    <div className={classnames("flex items-baseline", className, styles.fielde)}>
      <Checkbox {...props} />
      <Text className="ml-sm" size={textSize}>
        {children}
      </Text>
    </div>
  );
};

export default CheckboxField;
